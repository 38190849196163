import { StyledElementProps, Value } from "@udecode/plate";
import { TElement } from "@udecode/plate-core";
import { useApi } from "../../../../../hooks";
import { api } from "../../../../../services";

export interface TRoleElement extends TElement {
  id: number;
}

export const RoleElement = <V extends Value>(props: StyledElementProps<Value, TRoleElement>) => {
  const { attributes, children, element, nodeProps } = props;
  const role = useApi(() => api.role.getById(element.id));

  return (
    <div {...attributes} style={{ display: "inline-block" }}>
      <div
        contentEditable={false}
        style={{
          backgroundColor: element.backgroundColor ? (element.backgroundColor as string) : "",
          borderRadius: element.borderRadius ? (element.borderRadius as string) : "",
          padding: element.padding ? (element.padding as string) : "",
          display: "inline-block",
          pointerEvents: "none",
          userSelect: "none",
        }}
      >
        {children}
        {role.value?.name}
      </div>
    </div>
  );
};
