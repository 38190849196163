import React, { useEffect, useState } from "react";
import { IDradAndDropContainer } from "./BoardCollapse.interface";
import { DragDropContext, DragStart, DropResult } from "react-beautiful-dnd";
import { CollapseContext } from "../../../../../../contexts/communication/collapseContext";
import { useNotifier } from "../../../../../../hooks";
import IssueRequiredActionsDialog from "../../../../dialogs/issueRequiredActionsDialog/IssueRequiredActionsDialog";
import { BoardStatusDto, IssueDto } from "../../../../../../api";
import { nextStatusesToIds } from "./helpers";
import { api } from "../../../../../../services";
import { useTranslation } from "react-i18next";
import { ListIssues } from "../listIssues/ListIssues";

export const DradAndDropContainer = (props: IDradAndDropContainer) => {
  const { t } = useTranslation();
  const notifier = useNotifier();
  //const { communicationsStore } = useRootStore();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [issue, setIssue] = useState<IssueDto | undefined>(undefined);
  const [fromStatusId, setFromStatusId] = useState<number | undefined>(undefined);
  const [nextStatuses, setNextStatuses] = useState<BoardStatusDto[] | undefined>(undefined);
  const [toStatusId, setToStatusId] = useState<number | undefined>(undefined);

  const [isActiveTriggerMove, setIsActiveTriggerMove] = useState<boolean>(false);

  const [isOpenRequiredActionsDialog, setIsOpenRequiredActionsDialog] = useState<boolean>(false);

  const handleCloseRequiredActionsDialog = () => {
    setIsOpenRequiredActionsDialog(false);
    handleResetDragData();
  };

  const handleOpenRequiredActionsDialog = () => {
    setIsLoading(true);
    setIsOpenRequiredActionsDialog(true);
  };

  const handlerApiError = () => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });

  const onSwitchStatusWithoutDialog = async (boardStatusId: number) => {
    const r = await api.issueHistory.create({
      issueId: issue?.id,
      status: { boardStatusId },
    });
    if (r == null) handlerApiError();
    handleResetDragData();
  };

  const handleChangeIssueStatus = async () => {
    const countActions = handleGetCountMoveActions();
    if (countActions == null || !handleCheckAllowToMove()) return handlerApiError();
    if (countActions > 0) handleOpenRequiredActionsDialog();
    else {
      await onSwitchStatusWithoutDialog(toStatusId!);
      handleResetDragData();
    }
  };

  const handleInitDragData = async (data: DragStart) => {
    //const issue = communicationsStore.getIssue(Number(data.draggableId));
    const statusId = Number(data.source.droppableId);
    setFromStatusId(statusId);
    const issue = await api.issue.getById(Number(data.draggableId), {
      includeIndicators: true,
      includeNextStatuses: true,
      includeActions: true,
    });
    if (issue == null) return handlerApiError();
    setIssue(issue);
    setNextStatuses(issue?.calculated?.nextStatuses ?? undefined);
  };

  const handleCheckAllowToMove = () => nextStatuses?.some((item) => item.id === toStatusId);

  const handleGetCountMoveActions = (): number =>
    (nextStatuses?.find((item) => item.id === toStatusId)?.requiredActions ?? [])?.length;

  const handleResetDragData = () => {
    setFromStatusId(undefined);
    setToStatusId(undefined);
    setIssue(undefined);
    setNextStatuses(undefined);
  };

  const handleDragStart = (data: DragStart) => {
    handleInitDragData(data);
  };

  const handleDragEnd = (data: DropResult) => {
    if (!data.destination?.droppableId) {
      handleResetDragData();
      return handlerApiError();
    }
    if (Number(data.destination?.droppableId) === fromStatusId) return handleResetDragData();
    setToStatusId(Number(data.destination?.droppableId));
    setIsActiveTriggerMove(true);
  };

  //кейс когда медленный интернет и задача не успела загрузиться по id
  useEffect(() => {
    if (isActiveTriggerMove && issue && toStatusId) {
      setIsActiveTriggerMove(false);
      handleChangeIssueStatus();
    }
  }, [isActiveTriggerMove, issue, toStatusId]);

  return (
    <>
      {isOpenRequiredActionsDialog && issue && (
        <IssueRequiredActionsDialog
          open={isOpenRequiredActionsDialog}
          boardData={nextStatuses?.find((item) => item.id === toStatusId) ?? null}
          issueData={issue}
          onClose={handleCloseRequiredActionsDialog}
          //onRefreshData={() => {}}
        />
      )}
      <CollapseContext.Provider
        value={{
          draggedIssueId: issue ? issue.id : undefined,
          fromStatusId,
          toStatusId,
          possibilityStatuses: nextStatusesToIds(nextStatuses),
          isLoadingIssueMove: isLoading,
        }}
      >
        <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
          <div className="d-stack-row spacing-1">
            {props.board?.statuses?.map((status) => (
              <React.Fragment key={status.name}>
                <ListIssues
                  key={status.name}
                  isOpen={props.openBoardIds.includes(props.orgchartId)}
                  onChangeBoardColumnsState={props.onChangeBoardColumnsState}
                  orgchartId={props.orgchartId!}
                  boardStatus={status}
                />
              </React.Fragment>
            ))}
          </div>
        </DragDropContext>
      </CollapseContext.Provider>
    </>
  );
};
