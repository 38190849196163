import React from "react";
import { IUpdateNoteHeader } from "./UpdateNoteHeader.interface";
import { CancelButton, EditButton, SaveButton } from "../../components/ButtonIcons";
import { DeleteButton } from "../../../regulation/components/ButtonIcons/DeleteButton";
import { LocaleSwitcher } from "../../components/LocaleSwitcher/LocaleSwitcher";
import { PublishedSwitcher } from "../../components/PublishedSwitcher/PublishedSwitcher";

export const UpdateNoteHeader = (props: IUpdateNoteHeader) => {
  return (
    <div className="d-stack-row align-center justify-space-between full-width">
      <div className="d-stack-row align-center justify-start spacing-2">
        <LocaleSwitcher
          disabled={props.isEditable}
          currentLocale={props.currentLocale}
          onChangeLocale={props.onChangeLocale}
        />
      </div>
      <div className="d-stack-row align-center spacing-2">
        {props.commonPermission && (
          <>
            <PublishedSwitcher
              publishOption={props.publishOption}
              onChangePublishOption={props.onChangePublishOption}
            />
            {props.isEditable ? (
              <>
                <CancelButton onClick={props.onCancelEditable} />
                <SaveButton loading={props.isLoadingSaveBtn} disabled={props.disabledSaveBtn} onClick={props.onSave} />
              </>
            ) : (
              <EditButton onClick={props.isEditable ? props.onCancelEditable : props.onEnableEditable} />
            )}
            <DeleteButton loading={props.isLoadingDeleteBtn} onClick={props.onDelete} />
          </>
        )}
      </div>
    </div>
  );
};
