import React, { forwardRef } from "react";
import { IUpdateNoteCreateContent } from "./UpdateNoteCreateContent.interface";
import { EditableTypography } from "../../../../../elements/editableTypography/EditableTypography";
import { UpdateNoteEditor } from "../../components/UpdateNoteEditor/UpdateNoteEditor";
import { Button, Card } from "../../../../../uiKit";
import { useTranslation } from "react-i18next";
import { stringToContent } from "../../../../../../utils/textEditor";

export const UpdateNoteCreateContent = forwardRef((props: IUpdateNoteCreateContent, ref) => {
  const { t } = useTranslation();

  console.log(props.noteContent);

  return (
    <Card
      scrollStrategy="cardBody"
      size="default"
      title={
        <EditableTypography
          multiline
          placeholder={t("ui:placeholder.update_note_name")}
          value={props.noteName}
          disabled={false}
          onChange={props.onChangeNoteName}
        />
      }
    >
      <UpdateNoteEditor
        ref={ref}
        onChangeDraftUpdateNoteContent={props.onChangeNoteContent}
        draftUpdateNoteContent={stringToContent(props.noteContent)}
        isEditable
      />
      <div className="d-flex justify-end mt-3">
        <Button variant="filled" disabled={false} onClick={props.onCreate}>
          {t("ui:button.save")}
        </Button>
      </div>
    </Card>
  );
});
