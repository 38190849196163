import React from "react";
import { ITextFieldLinkifiedView } from "./TextFieldLinkified.interface";
import { TextLinkified } from "../textLinkified/textLinkified";

export const TextFieldLinkifiedView = ({
  renderValue,
  showTextField,
  placeholder,
}: ITextFieldLinkifiedView) => {
  return (
    <div className="TextFieldAlternativeFilled-replica" onClick={showTextField}>
      {placeholder && !renderValue ? (
        <div className="placeholder">{placeholder}</div>
      ) : (
        <TextLinkified text={renderValue} />
      )}
    </div>
  );
};
