import { forwardRef } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { ITextEditor } from "./TextEditor.interface";
import { TextEditorContainer } from "./TextEditorContainer";
import { ErrorFallback } from "./utils/ErrorFallback";

export const TextEditor = forwardRef((props: ITextEditor, ref) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <TextEditorContainer {...props} ref={ref} />
    </ErrorBoundary>
  );
});
