import React from "react";
import { ICommonCardsList } from "./ListCards.interface";
import "./ListCards.scss";
import { ListRegulations } from "./ListRegulations";
import { ListSections } from "./ListSections";


export const CommonListCards : React.FC<ICommonCardsList> = (props) => {
  return (
    <div className="regulation-grid-container">
      <ListSections
        isLoading={props.isLoading}
        paginationType={props.paginationType}
        sections={props.sections}
        loadNext={props.loadNextSections}
        isDone={props.isDoneSection} />
      <ListRegulations
        isLoading={props.isLoading}
        paginationType={props.paginationType}
        regulationContentType={props.regulationContentType}
        regulations={props.regulations}
        loadNext={props.loadNextRegulations}
        isDone={props.isDoneRegulation}
      />
    </div>
  );
};

