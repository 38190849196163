import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Collapse, CollapsePanel, Divider, Text } from "../../../../../../uiKit";
import { ISidebarCollapseElement } from "./SidebarCollapseElement.interface";
import "./SidebarCollapseElement.scss";
import ScrollTrigger from "../../../../../../service/scrollTrigger/ScrollTrigger";

export const SidebarCollapseElement = (props: ISidebarCollapseElement) => {
  const navigate = useNavigate();
  const { uri } = useParams();
  const handleRedirectToPlan = (id: number) => {
    navigate(`/dashboard/${id}`);
  };
  return (
    <Collapse
      className="mb-2"
      expandIconPosition="end"
      style={{ background: "var(--color-layout-container)", fontWeight: 700 }}
      bordered={false}
    >
      <CollapsePanel
        key={props.collapsePanelKey}
        header={<Text>{props.collapseName}</Text>}
        className="collapse__custom-panel"
      >
        {props.dashboards?.map((d) => (
          <React.Fragment key={d.id}>
            <Divider style={{ opacity: "0.5" }} />
            <div
              style={Number(uri) == d.id ? { color: "var(--color-primary-base)" } : undefined}
              className="pa-3 collapse__item"
              onClick={() => handleRedirectToPlan(d.id!)}
            >
              {d.name}
            </div>
          </React.Fragment>
        ))}
        <div>
          <ScrollTrigger
            fullWidth
            marginTop={!!props.dashboards?.length}
            disabled={props.isDone}
            hidden={props.isDone}
            onIntersection={props.loadNext}
          />
        </div>
      </CollapsePanel>
    </Collapse>
  );
};
