import { dashboardSectionAndRoutesKeys } from "../../../misc/constants/routesKeys";

export type StaticElementsType = {
  uri: dashboardSectionAndRoutesKeys;
  title: string;
};
export const staticElementsArr: StaticElementsType[] = [
  { uri: dashboardSectionAndRoutesKeys.myMetrics, title: "common:tab.dashboard.my_metrics" },
  { uri: dashboardSectionAndRoutesKeys.mySubordinates, title: "common:tab.dashboard.my_subordinates" },
  { uri: dashboardSectionAndRoutesKeys.fillOut, title: "common:tab.dashboard.fill_out" },
];
