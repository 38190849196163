import React, { useContext, useEffect, useState } from "react";
import OrgchartDrawerTabRecurringTasksView from "./OrgchartDrawerTabRecurringTasksView";
import { RecurringIssueDto, RoleDto } from "../../../../../../api";
import { useDidMountEffect, useNotifier, useRootStore } from "../../../../../../hooks";
import { useTranslation } from "react-i18next";
import { OrgchartContext } from "../../../../../../contexts/orgchartContext";
import { api } from "../../../../../../services";
import { usePagingWithController } from "../../../../../../hooks/usePaging";
import { TIssuesFilters } from "../../orgchartCommonDrawerTabs/orgchartCommonDrawerTabRecurringTasks/OrgchartCommonDrawerTabRecurringTasks";
import useDebounce from "../../../../../../hooks/useDebounce";
import { ExecutorTypes } from "../../../../../pages/driver/types/DriverTypes";
import { observer } from "mobx-react-lite";

export interface IOrgchartDrawerTabRecurringTasks {
  functionData: RoleDto;

  setIsDrawerClosable(value: boolean): void;
  refreshData(): void;
}

function OrgchartDrawerTabRecurringTasks(props: IOrgchartDrawerTabRecurringTasks) {
  const notifier = useNotifier();
  const { t } = useTranslation();
  const { orgchartStore } = useRootStore();
  const [isAddDialogOpen, setIsAddDialogOpen] = useState<boolean>(false);
  const orgchartContext = useContext(OrgchartContext);
  const [formMode, setFormMode] = useState<boolean>(false);
  const [isDeleteIssueDialogOpen, setIsDeleteIssueDialogOpen] = useState<boolean>(false);
  const [isIssueHistoryDialogOpen, setIsIssueHistoryDialogOpen] = useState<boolean>(false);
  const [issueToManageId, setIssueToManageId] = useState<number | null>(null);
  const [createOn, setCreateOn] = useState<"admins" | "executors" | "all" | undefined>(undefined);
  const [issueToManageData, setIssueToManageData] = useState<RecurringIssueDto | null>(null);

  const [issuesFilters, setIssueFilters] = useState<TIssuesFilters>({
    name: "",
    roleId: null,
    isPaused: null,
    executorConfigType: "",
    executorPositionType: null,
  });

  const [sortSettings, setSortSettings] = useState<{ by: string; order: string }>({
    by: "date_created",
    order: "desc",
  });

  const handleSetAddDialogOpen = (value: boolean, type: ExecutorTypes | null) => {
    setIsAddDialogOpen(value);
  };

  useEffect(() => {
    props.setIsDrawerClosable(!formMode);
  }, [formMode]);

  const issues = usePagingWithController(
    api.recurringIssue,
    {
      roleId: (props.functionData?.id as number) ?? undefined,
      name: issuesFilters.name,
      orgchartId: orgchartStore.getCurrentOrgchartId,
      // roleId: issuesFilters.roleId,
      executorConfigType: issuesFilters.executorConfigType.length > 0 ? issuesFilters.executorConfigType : null,
      isPaused: issuesFilters.isPaused /* || null*/,
      executorConfigRolePositionType: issuesFilters.executorPositionType,
    },
    { pageSize: 20, orderBy: sortSettings.by, order: sortSettings.order }
  );

  const debouncedFilters = useDebounce(issuesFilters, 500);

  useDidMountEffect(() => {
    if (props.functionData != null) {
      issues.restart();
    }
  }, []);

  useEffect(() => {
    if (props.functionData?.id == null) {
      issues.reset();
      return;
    }
    issues.restart();
  }, [props.functionData]);

  useEffect(() => {
    if (props.functionData == null) return;
    issues.reset();
    issues.restart();
  }, [debouncedFilters]);

  useEffect(() => {
    if (props.functionData == null) return;
    issues.reset();
    issues.restart();
  }, [sortSettings]);

  const handlePauseClick = async (issueId: number) => {};

  const handleHistoryWatchClick = async (issueId: number) => {
    setIssueToManageId(issueId);
    setIsIssueHistoryDialogOpen(true);
  };

  const handleEditClick = async (issue: RecurringIssueDto) => {
    setIssueToManageData({ ...issue });
    setFormMode(true);
  };

  const handleDeleteClick = async (issueId: number) => {
    setIssueToManageId(issueId);
    setIsDeleteIssueDialogOpen(true);
  };

  const handleIssueDelete = async () => {
    const r = await api.recurringIssue.del(issueToManageId as number);
    if (r != null) {
      setIsDeleteIssueDialogOpen(false);
      await issues.reset();
      await issues.restart();
    }
  };

  return (
    <OrgchartDrawerTabRecurringTasksView
      isAddDialogOpen={isAddDialogOpen}
      functionData={props.functionData}
      formMode={formMode}
      setFormMode={setFormMode}
      issues={issues}
      handleSetAddDialogOpen={handleSetAddDialogOpen}
      onCardPauseClick={handlePauseClick}
      onCardWatchHistoryClick={handleHistoryWatchClick}
      onCardEditClick={handleEditClick}
      onCardDeleteClick={handleDeleteClick}
      handleIssueDelete={handleIssueDelete}
      isDeleteIssueDialogOpen={isDeleteIssueDialogOpen}
      setIsDeleteIssueDialogOpen={setIsDeleteIssueDialogOpen}
      issueToManageId={issueToManageId}
      isIssueHistoryDialogOpen={isIssueHistoryDialogOpen}
      setIsIssueHistoryDialogOpen={setIsIssueHistoryDialogOpen}
      issueToManageData={issueToManageData}
      setIsDrawerClosable={props.setIsDrawerClosable}
      setIssueToManageData={setIssueToManageData}
      createOn={createOn}
      setCreateOn={setCreateOn}
      issueFilters={issuesFilters}
      setIssueFilters={setIssueFilters}
      sortSettings={sortSettings}
      setSortSettings={setSortSettings}
    />
  );
}

export default observer(OrgchartDrawerTabRecurringTasks);
