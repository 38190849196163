import {
  $applyNodeReplacement,
  EditorConfig,
  LexicalNode,
  NodeKey,
  SerializedTextNode,
  Spread,
  TextNode,
} from "lexical";
import { NodeType } from "../types/types";

export type SerializedCustomNode = Spread<
  {
    type: NodeType.Anchor;
    anchorId: string;
  },
  SerializedTextNode
>;

export class AnchorNode extends TextNode {
  __anchorId: string;

  constructor(anchorId: string, isEditable?: boolean, key?: NodeKey) {
    super(key ?? "");
    this.__anchorId = anchorId;
    this.__text = anchorId;
    this.__isEditable = isEditable;
  }

  static getType() {
    return NodeType.Anchor;
  }

  static clone(node: AnchorNode): AnchorNode {
    return new AnchorNode(node.__anchorId, node.__isEditable, node.__key);
  }

  createDOM(config: EditorConfig): HTMLElement {
    const dom = document.createElement("div");
    const inner = super.createDOM(config);
    dom.style.height = !this.__isEditable ? "1px" : "25px";
    dom.style.visibility = !this.__isEditable ? "hidden" : "visible";
    dom.style.background = "var(--color-text-weak)";
    dom.style.borderRadius = "var(--border-radius-sm)";
    dom.style.color = "#fff";
    dom.style.paddingLeft = "4px";
    dom.style.width = "100%";
    dom.id = this.__anchorId;
    dom.appendChild(inner);
    return dom;
  }

  static importJSON(serializedNode: SerializedCustomNode): AnchorNode {
    const url = window.location.href.split("/")[3];
    const node = $createAnchorNode(serializedNode.anchorId, url == "edit-content");
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);
    return node;
  }

  updateDOM(prevNode: TextNode, dom: HTMLElement, config: EditorConfig): boolean {
    const inner = dom.firstChild;
    if (inner === null) {
      return true;
    }
    super.updateDOM(prevNode, inner as HTMLElement, config);
    return false;
  }

  exportJSON(): SerializedCustomNode {
    return {
      ...super.exportJSON(),
      type: NodeType.Anchor,
      anchorId: this.__anchorId,
    };
  }
}
export function $createAnchorNode(anchorId: string, isEditable?: boolean): AnchorNode {
  const node = new AnchorNode(anchorId, isEditable);
  return $applyNodeReplacement(node);
}

export function $isAnchorNode(node: AnchorNode | LexicalNode | null | undefined): node is AnchorNode {
  return node instanceof AnchorNode;
}
