import React from "react";
import { SegmentedProps } from "antd";
import { SegmentedView } from "./SegmentedView";

interface ISegmented extends SegmentedProps {
  //
}

export const Segmented = (props: ISegmented) => {
  return <SegmentedView {...props} />;
};
