import React from "react";
import "./TimePickerRange.scss";
import { RangePicker } from "../timePicker/TimePickerView";
import { RangePickerTimeProps } from "antd/es/date-picker/generatePicker";
import { Dayjs } from "dayjs";
import clsx from "clsx";

type TTimePickerRangeView = {
  readOnly?: boolean;
} & RangePickerTimeProps<Dayjs>;

export function TimePickerRangeView({ className, readOnly, ...rest }: TTimePickerRangeView) {
  return (
    <RangePicker className={clsx("uiKit-timePickerRange", className ?? "", { "no-interact": readOnly })} {...rest} />
  );
}
