import React from "react";
import { CheckboxGroupProps } from "antd/lib/checkbox";
import { Checkbox } from "antd";
import "./CheckboxGroup.scss";
import clsx from "clsx";

interface ICheckboxGroupView extends CheckboxGroupProps {
  //
}

const { Group } = Checkbox;

export function CheckboxGroupView({ className, ...rest }: ICheckboxGroupView) {
  return <Group className={clsx("uiKit-checkboxGroup", className ?? "")} {...rest} />;
}
