import React from "react";
import { TextView } from "./TextView";
import { TextProps } from "antd/lib/typography/Text";

interface IText extends TextProps {
  size?: string;
  weight?: number | "normal" | "bold" | "lighter" | "bolder";
}

export function Text(props: IText) {
  return <TextView {...props} />;
}
