import React, { useRef, useState } from "react";
import SignInAsAnotherUserDialogView from "./SignInAsAnotherUserDialogView";
import { useApi, useNotifier } from "../../../../hooks";
import { api } from "../../../../services";
import { useTranslation } from "react-i18next";

interface ISignInAsAnotherUserDialog {
  open: boolean;

  onClose(): void;
  onAccept(): void;
}

function SignInAsAnotherUserDialog(props: ISignInAsAnotherUserDialog) {
  const notifier = useNotifier();
  const { t } = useTranslation();
  const [userId, setUserId] = useState(0);
  const allUsers = useApi(() => api.user.getAll({ ignoreContext: true, pageSize: 1000 }));
  const [isDone, setIsDone] = useState(false);
  const link = useRef("");

  const handleClose = () => {
    setUserId(0);
    setIsDone(false);
    link.current = "";
    props.onClose();
  };

  const handleAccept = async () => {
    // props.onAccept();
    const r = await api.adminCompany.signInAs(userId);
    if (r == null) {
      notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
      return;
    }
    setIsDone(true);
    link.current = window.origin + "/login?token=" + r.token;
    // link.current = "https://my.tonnus.io/login?token=" + r.token;
    await handleCopy(link.current);
  };

  const handleCopy = async (text: string) => {
    await navigator.clipboard
      .writeText(text)
      .then(() => notifier.show({ message: t("notifier:success.copy"), theme: "success" }))
      .catch(() => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" }));
  };

  const onCopyClick = async () => {
    await handleCopy(link.current);
  };

  const handleReset = () => {
    setUserId(0);
    setIsDone(false);
    link.current = "";
  };

  return (
    <SignInAsAnotherUserDialogView
      {...props}
      onClose={handleClose}
      onAccept={handleAccept}
      userId={userId}
      setUserId={setUserId}
      allUsers={allUsers.value?.items ?? []}
      isDone={isDone}
      onCopyClick={onCopyClick}
      onReset={handleReset}
    />
  );
}

export default SignInAsAnotherUserDialog;
