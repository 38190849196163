import React from "react";
import { useTranslation } from "react-i18next";
import { User2CompanyDto } from "../../../../api";
import SubheaderText from "../../../elements/subheaderText/SubheaderText";
import { Button, Dialog, DialogActions } from "../../../uiKit";

export interface ICompanyLeaveDialogView {
  open: boolean;
  companyData: User2CompanyDto;
  onLeave(): void;
  onClose(): void;
}

function CompanyLeaveDialogView(props: ICompanyLeaveDialogView) {
  const { t } = useTranslation();

  return (
    <Dialog title={t("ui:title.leave_company")} open={props.open} onClose={() => props.onClose()}>
      <SubheaderText
        text={t("text:leave_company.text1", {
          companyName:
            (props.companyData.company?.name?.trim() ?? "").length > 0
              ? props.companyData.company?.name
              : props.companyData.company?.nameFallback ?? "",
        })}
      />
      <DialogActions>
        <Button onClick={() => props.onClose()} variant="text">
          {t("ui:button.cancel")}
        </Button>
        <Button color="error" onClick={() => props.onLeave()} variant="filled" theme="error">
          {t("ui:button.leave_company")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CompanyLeaveDialogView;
