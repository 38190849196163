import { RegulationContentQuestionDto } from "../../../../api";

export enum answerKeys {
  singleAnswer = "single_answer",
  multipleAnswer = "multiple_answer",
}

export type answerKeysType = answerKeys.singleAnswer | answerKeys.multipleAnswer;

export interface ICounterRightAnswers {
    countRights :  number,
    arrIdsRights : number[]
}

export interface ISuccessfulCompletion {
    onCloseWitchReload ?: () => void;
}

export interface PassingRegulationQestion extends RegulationContentQuestionDto {
    answerKey : answerKeysType
}

export interface IPassingRegulationTest {
    open : boolean;
    onClose : () => void;
    onCloseWitchReload ?: () => void;
    regilationId : number;
    questions ?: RegulationContentQuestionDto[];
}
export interface IPassingRegulationTestView {
    open: boolean;
    isLoading : boolean;
    isSuccessCompletion ?: boolean;
    isFailedCompletion ?: boolean;
    questions : PassingRegulationQestion[];
    onClose: () => void;
    onSimplerolleUp : () => void;
    onCloseWitchReload ?: () => void;
    onCompleteTest : () => void;
    onTakeInitialState : () => void;
    onChangeAnswer : (questionId : number, answerId : number, value : boolean) => void;
    onSelectAnswer : (questionId : number, answerId : number) => void;
}
export interface IQuestion {
    answerKey : answerKeysType,
    question : PassingRegulationQestion
    onChangeAnswer : (questionId : number, answerId : number, value : boolean) => void;
    onSelectAnswer : (questionId : number, answerId : number) => void;
}