import React, { useState } from "react";
import "./TextFieldLinkified.scss";
import { TextFieldLinkifiedView } from "./TextFieldLinkifiedView";
import { ITextFieldLinkified } from "./TextFieldLinkified.interface";
import { TextArea } from "../../uiKit";

const TextFieldLinkifiedMemo = (props: ITextFieldLinkified) => {
  const [showTextField, setShowTextField] = useState<boolean>(false);

  const onBlurInput = () => {
    setShowTextField(false);
    props.onUpdate && props.onUpdate();
  };

  return (
    <>
      {showTextField ? (
        // eslint-disable-next-line react/jsx-no-undef
        <TextArea
          className="textarea-linkifield"
          onBlur={onBlurInput}
          autoComplete="off"
          autoSize={{ minRows: 1, maxRows: 30 }}
          // autoSize
          autoFocus
          value={props.value}
          onInput={(e: any) => props.setValue(e.target.value)}
        />
      ) : (
        <TextFieldLinkifiedView
          placeholder={props.placeholder}
          showTextField={
            props.readonly
              ? undefined
              : () => {
                  setShowTextField(true);
                }
          }
          renderValue={props.value}
        />
      )}
    </>
  );
};

export const TextFieldLinkified = React.memo(TextFieldLinkifiedMemo);
