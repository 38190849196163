import React from "react";
import SubheaderText from "../../../elements/subheaderText/SubheaderText";
import { useTranslation } from "react-i18next";
import { useRootStore } from "../../../../hooks";
import { observer } from "mobx-react-lite";
import { UserDto } from "../../../../api";
import AvatarUserSettings from "../../pages/settings/avatarUserSettings/AvatarUserSettings";
import { Button, Input, InputPhoneNumber, Select, SelectOption, Tooltip } from "../../../uiKit";
import { theme } from "antd";
import { removeSpecialCharactersFromString } from "../../../../helpers/stringFunctions";

export interface IEmployeeEditFormView {
  userId: number;
  currentUserId: number;
  employeeData: UserDto | null;
  checkEmailCode: string;
  firstLaunch: boolean;
  updater: any;
  canShowError: any;
  codeSendTimer: number;
  isUserTypeEditAvailable: boolean;
  isAccessTypeEditAvailable: boolean;

  handleAvatarChange(id: number): void;
  handleAvatarDelete(): void;
  handleConfirmEmail(): void;
  sendConfirmationCodeAgain(): void;
  setCheckEmailCode(value: string): void;
  setCanShowError(value: any): void;
  onSaveClick?: () => void;
}

function EmployeeEditFormView(props: IEmployeeEditFormView) {
  const { t } = useTranslation();
  const { helperStore } = useRootStore();
  const { useToken } = theme;
  const { token } = useToken();

  const timeZones = helperStore.getTimezones;
  const accessTypes = [
    { id: 0, value: 0, text: t("parse:full_access") },
    { id: 1, value: 1, text: t("parse:limited_access") },
  ];
  const parseRegex = require("regex-parser");

  function matchExact(r: RegExp, str: string) {
    const match = str?.match(r);
    return match && str === match[0];
  }

  return (
    <div className="d-stack-column spacing-3">
      {!props.firstLaunch && (
        <>
          <SubheaderText text={t("ui:subheader.avatar")} />
          <div className="mb-3 py-2" style={{ borderRadius: "8px", border: `1px solid ${token.colorBorder}` }}>
            <AvatarUserSettings
              imageUrl={props.updater.currentState?.avatar?.image?.url ?? ""}
              userId={props.updater.currentState?.id}
              color={props.updater.currentState?.color ?? undefined}
              text={[props.updater.currentState?.lastName, props.updater.currentState?.firstName]}
              onUploadSuccess={props.handleAvatarChange}
              onDeleteClick={props.handleAvatarDelete}
            />
          </div>
        </>
      )}

      <SubheaderText text={t("ui:subheader.last_name")} />
      <Tooltip title={t("parse:input_field_validation_only_letters")} trigger={["focus"]} placement="bottomLeft">
        <Input
          // disabled={!props.updater.currentState?.lastName && !props.firstLaunch}
          placeholder={t("ui:placeholder.last_name")}
          value={props.updater.currentState?.lastName ?? ""}
          // value={props.updater.currentState?.lastName ?? t("text:loading_data")}
          onInput={(event) => {
            props.updater.applyChanges({
              lastName: removeSpecialCharactersFromString((event.target as HTMLInputElement).value),
            });
            props.setCanShowError({ ...props.canShowError, lastName: true });
          }}
          // onPaste
          onBlur={props.updater.currentState?.lastName?.length > 0 ? props.updater.updatePartially : undefined}
          // error={!(props.updater.currentState?.lastName?.length > 0) && props.canShowError.lastName}
          // helperText={
          //   props.updater.currentState?.lastName?.length > 0 || !props.canShowError.lastName
          //     ? undefined
          //     : t("ui:helper.error_textfield")
          // }
        />
      </Tooltip>
      <SubheaderText text={t("ui:subheader.first_name")} />
      <Tooltip trigger={["focus"]} title={t("parse:input_field_validation_only_letters")} placement="bottomLeft">
        <Input
          // disabled={!props.updater.currentState?.firstName && !props.firstLaunch}
          placeholder={t("ui:placeholder.first_name")}
          value={props.updater.currentState?.firstName ?? ""}
          // onChange={() => props.setCanShowError({ ...props.canShowError, firstName: true })}
          // value={props.updater.currentState?.firstName ?? t("text:loading_data")}
          onInput={(event) => {
            props.updater.applyChanges({
              firstName: removeSpecialCharactersFromString((event.target as HTMLInputElement).value),
            });
            props.setCanShowError({ ...props.canShowError, firstName: true });
          }}
          onBlur={props.updater.currentState?.firstName?.length > 0 ? props.updater.updatePartially : undefined}
          // error={!(props.updater.currentState?.firstName?.length > 0) && props.canShowError.firstName}
          // helperText={
          //   props.updater.currentState?.firstName?.length > 0 || !props.canShowError.firstName
          //     ? undefined
          //     : t("ui:helper.error_textfield")
          // }
        />
      </Tooltip>
      <SubheaderText text={t("ui:subheader.middle_name")} />
      <Tooltip trigger={["focus"]} title={t("parse:input_field_validation_only_letters")} placement="bottomLeft">
        <Input
          // disabled={!props.updater.currentState?.middleName && !props.firstLaunch}
          placeholder={t("ui:placeholder.middle_name")}
          value={props.updater.currentState?.middleName ?? ""}
          // value={props.updater.currentState?.middleName ?? t("text:loading_data")}
          onInput={(event) =>
            props.updater.applyChanges({
              middleName: removeSpecialCharactersFromString((event.target as HTMLInputElement).value),
            })
          }
          onBlur={props.updater.updatePartially}
        />
      </Tooltip>

      {!props.firstLaunch && (
        <>
          <SubheaderText text={t("ui:subheader.email")} />
          <Input
            // disabled={!props.updater.currentState?.email && !props.firstLaunch}
            placeholder={t("ui:placeholder.email")}
            // helperText={props.updater?.isEmailConfirmed ? undefined : "Почта не подтверждена!"}
            value={props.updater.currentState?.email ?? ""}
            // value={props.updater.currentState?.email ?? t("text:loading_data")}
            onInput={(event) => props.updater.applyChanges({ email: (event.target as HTMLInputElement).value })}
            onBlur={props.updater.updatePartially}
          />

          {!props.employeeData?.isEmailConfirmed && props.userId == props.currentUserId && (
            <>
              <SubheaderText text={t("ui:subheader.email_confirmation")} />
              <div className="d-flex flex-column mb-3">
                <Input
                  value={props.checkEmailCode}
                  onInput={(event) => props.setCheckEmailCode((event.target as HTMLInputElement).value)}
                  placeholder={t("ui:placeholder.email_confirmation_code")}
                />
                <div className="d-stack spacing-2 mt-2">
                  <Button
                    onClick={() => props.handleConfirmEmail()}
                    disabled={props.checkEmailCode.length == 0}
                    variant="filled"
                    className="flex-grow-1"
                  >
                    {t("ui:button.check_code")}
                  </Button>
                  <Button
                    onClick={() => props.sendConfirmationCodeAgain()}
                    disabled={props.codeSendTimer != 0}
                    variant="outlined"
                    className="flex-grow-1"
                  >
                    {`${t("parse:send_code")}${props.codeSendTimer > 0 ? " (" + props.codeSendTimer + ")" : ""}`}
                  </Button>
                </div>
              </div>
            </>
          )}
        </>
      )}

      <SubheaderText text={t("ui:subheader.phone_number")} />
      <InputPhoneNumber
        defaultErrorMessage={t("ui:helper.error_textfield")}
        value={props.updater.currentState?.phoneNumber ?? ""}
        onChange={(value) => {
          props.updater.applyChanges({ phoneNumber: value });
          props.setCanShowError({ ...props.canShowError, phoneNumber: true });
        }}
        onBlur={props.updater.currentState?.phoneNumber?.length > 0 ? props.updater.updatePartially : undefined}
        isValid={(value, country) => {
          if (value?.trim().length == 0 && props.canShowError.phoneNumber) {
            return false;
          } else {
            return true;
          }
        }}
      />
      {/*<PhoneInputField*/}
      {/*  value={props.updater.currentState?.phoneNumber ?? ""}*/}
      {/*  onChange={(value) => {*/}
      {/*    props.updater.applyChanges({ phoneNumber: value });*/}
      {/*    props.setCanShowError({ ...props.canShowError, phoneNumber: true });*/}
      {/*  }}*/}
      {/*  onBlur={props.updater.currentState?.phoneNumber?.length > 0 ? props.updater.updatePartially : undefined}*/}
      {/*  isValid={(value, country) => {*/}
      {/*    if (value?.trim().length == 0 && props.canShowError.phoneNumber) {*/}
      {/*      return false;*/}
      {/*    } else {*/}
      {/*      return true;*/}
      {/*    }*/}
      {/*  }}*/}
      {/*  defaultErrorMessage={t("ui:helper.error_textfield")}*/}
      {/*/>*/}

      <SubheaderText text={t("ui:subheader.communication_type")} />
      <div className="d-stack spacing-2">
        <Select
          placeholder={t("ui:placeholder.preferred_communication_name")}
          value={props.updater.currentState?.contact?.key ?? null}
          onChange={(value) =>
            props.updater.applyChanges({
              contact: { ...props.updater.currentState?.contact, key: value },
            })
          }
          style={{ flexBasis: "50%" }}
          // sx={{
          //   mb:
          //     (props.updater.currentState?.contact?.value ?? "").trim().length > 0 &&
          //     helperStore.getUserContactType?.find((ct) => ct.key == props.updater.currentState?.contact?.key)?.regex !=
          //       null &&
          //     !matchExact(
          //       parseRegex(
          //         helperStore.getUserContactType?.find((ct) => ct.key == props.updater.currentState?.contact?.key)
          //           ?.regex
          //       ),
          //       props.updater.currentState?.contact?.value
          //     )
          //       ? "1.5em"
          //       : undefined,
          // }}
          onBlur={props.updater.updatePartially}
        >
          {helperStore.getUserContactType?.map((uc) => (
            <SelectOption value={uc.key as string} key={uc.key as string}>
              {uc.name}
            </SelectOption>
          ))}
        </Select>

        <Input
          placeholder={t("ui:placeholder.preferred_communication_value")}
          value={props.updater.currentState?.contact?.value?.trim() ?? ""}
          disabled={!props.updater.currentState?.contact?.key}
          onInput={(event) =>
            props.updater.applyChanges({
              contact: {
                ...props.updater.currentState?.contact,
                value: (event.target as HTMLInputElement).value?.trim(),
              },
            })
          }
          // helperText={
          //   (props.updater.currentState?.contact?.value ?? "").trim().length > 0 &&
          //   helperStore.getUserContactType?.find((ct) => ct.key == props.updater.currentState?.contact?.key)?.regex !=
          //     null &&
          //   !matchExact(
          //     parseRegex(
          //       helperStore.getUserContactType?.find((ct) => ct.key == props.updater.currentState?.contact?.key)?.regex
          //     ),
          //     props.updater.currentState?.contact?.value
          //   )
          //     ? "Неверный формат"
          //     : undefined
          // }
          // error={
          //   (props.updater.currentState?.contact?.value ?? "").trim().length > 0 &&
          //   helperStore.getUserContactType?.find((ct) => ct.key == props.updater.currentState?.contact?.key)?.regex !=
          //     null &&
          //   !matchExact(
          //     parseRegex(
          //       helperStore.getUserContactType?.find((ct) => ct.key == props.updater.currentState?.contact?.key)?.regex
          //     ),
          //     props.updater.currentState?.contact?.value
          //   )
          // }
          // TODO: Вернуть текст ошибки
          onBlur={props.updater.updatePartially}
          // onBlur={
          //   (!(
          //     helperStore.getUserContactType?.find((ct) => ct.key == props.updater.currentState?.contact?.key)?.regex !=
          //       null &&
          //     !matchExact(
          //       parseRegex(
          //         helperStore.getUserContactType?.find((ct) => ct.key == props.updater.currentState?.contact?.key)
          //           ?.regex
          //       ),
          //       props.updater.currentState?.contact?.value
          //     )
          //   ) ||
          //     (props.updater.currentState?.contact?.value as string)?.trim().length == 0) &&
          //   props.updater.updatePartially
          // }
        />
      </div>

      <SubheaderText text={t("ui:subheader.time_zone")} />
      <Select
        // disabled={!props.updater.currentState?.timeZoneFromUtc}
        placeholder={t("ui:placeholder.time_zone")}
        value={props.updater.currentState?.timeZoneFromUtc ?? 0}
        onChange={(value) => props.updater.applyChanges({ timeZoneFromUtc: value })}
        onBlur={props.updater.updatePartially}
        fullWidth
      >
        {timeZones.map((tz) => (
          <SelectOption key={tz.value} value={tz.value}>
            {tz.text}
          </SelectOption>
        ))}
      </Select>
      {props.isAccessTypeEditAvailable && (
        <>
          <SubheaderText text={t("parse:access")} />
          <Select
            readOnly={props.isUserTypeEditAvailable ? false : props.updater.currentState?.currentAccessType == 1}
            // readOnly={!props.isUserTypeEditAvailable && props.updater.currentState?.currentAccessType != 1}
            // disabled={!props.updater.currentState?.timeZoneFromUtc}
            placeholder={t("parse:access")}
            value={props.updater.currentState?.currentAccessType ?? 0}
            onChange={(value) => props.updater.applyChanges({ currentAccessType: value })}
            onBlur={props.updater.updatePartially}
            fullWidth
          >
            {accessTypes.map((aT) => (
              <SelectOption key={aT.value} value={aT.value}>
                {aT.text}
              </SelectOption>
            ))}
          </Select>
        </>
      )}

      {props.firstLaunch && (
        <div className="d-flex justify-end">
          <Button
            onClick={props.onSaveClick}
            disabled={
              !props.updater.currentState?.firstName?.trim()?.length ||
              !props.updater.currentState?.lastName?.trim()?.length ||
              !props.updater.currentState?.phoneNumber?.length
            }
            variant="filled"
          >
            {t("ui:button.save")}
          </Button>
        </div>
      )}
    </div>
  );
}

export default observer(EmployeeEditFormView);
