import getVideoId from "get-video-id";

export const urlValidation = (url: string) => {
  const regex = new RegExp(
    "^(http:\\/\\/www\\.|https:\\/\\/www\\.|http:\\/\\/|https:\\/\\/)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?$"
  );
  return regex.test(url);
};

export const currentUrl = (url: string) => {
  if (url != "") {
    return url.startsWith("https://")
      ? url.slice("https://".length)
      : url.startsWith("http://")
      ? url.slice("http://".length)
      : url;
  }
};

export const youtubeUrlValidation = (url: string) => {
  const regex = new RegExp(
    "^((?:https?:)?\\/\\/)?((?:www|m)\\.)?((?:youtube(-nocookie)?\\.com|youtu.be))(\\/(?:[\\w\\-]+\\?v=|embed\\/|v\\/)?)([\\w\\-]+)(\\S+)?$"
  );
  return regex.test(url);
};

export const prependHttp = (url: string, options: { https: boolean }) => {
  url = url.trim();

  if (/^\.*\/|^(?!localhost)\w+:/.test(url)) {
    return url;
  }

  return url.replace(/^(?!(?:\w+:)?\/\/)/, options.https ? "https://" : "http://");
};

export const parseUrl = (url: string): { id: string; url: string } | null => {
  const { id } = getVideoId(prependHttp(url, { https: false }));

  if (id != null) {
    return {
      id,
      url,
    };
  }

  return null;
};
