import React from "react";
import { MdTagFaces } from "@react-icons/all-files/md/MdTagFaces";
import { Icon, IconButton } from "../../../uiKit";
import { IAddReactionButton } from "../interfaces/Reactions.interface";

export const AddReactionButton: React.FC<IAddReactionButton> = ({ onClick, disabled }) => {
  return (
    <IconButton
      onClick={onClick}
      type="text"
      disabled={disabled}
      icon={<Icon component={() => <MdTagFaces style={{ fill: "var(--color-text-weaker)" }} fontSize={22} />} />}
    />
  );
};
