import React from "react";
import { CollapsibleContainerView } from "./CollapsibleContainerView";

interface ICollapsibleContainer {
  open?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  children?: React.ReactNode;
  className?: string;
}

export function CollapsibleContainer(props: ICollapsibleContainer) {
  const handleChange = () => {
    if (!!props.open) {
      props.onClose && props.onClose();
    } else {
      props.onOpen && props.onOpen();
    }
  };

  return (
    <CollapsibleContainerView
      isOpen={props.open ?? false}
      onChange={handleChange}
      children={props.children}
      className={props.className}
    />
  );
}
