import React from "react";
import "./RegulationCard.scss";
import { IRegulationCard } from "./regulationCard.interface";
import { FiPlus } from "@react-icons/all-files/fi/FiPlus";
import { FiMinus } from "@react-icons/all-files/fi/FiMinus";
import { Text } from "../../../../../uiKit";
import clsx from "clsx";


export const RegulationCard = ({ regulation, onClick,disabled,iconType } : IRegulationCard) => {

  const handleClick = () => {
    !disabled && onClick(regulation.id!)
  }

  const iconButtonStyles = clsx(
    "regulation-mini-card__button",
    {"regulation-mini-card__button__disabled" : !!disabled}
  )


  const handleGetIcon = () : JSX.Element | undefined => {
    switch (iconType)  {
      case "plus" :
        return  <FiPlus onClick={handleClick} size={17} className={iconButtonStyles} />
      case "minus" :
        return <FiMinus  onClick={handleClick} size={17} className={iconButtonStyles} />
      default : return undefined;
    }
  }

  return (
    <div className="regulation-mini-card">
      <Text
        className="regulation-mini-card__title">
        {regulation.text}
      </Text>
      {handleGetIcon()}
    </div>
  );
};

