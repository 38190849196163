import React from "react";
import PhoneInput, { PhoneInputProps } from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./PhoneInputField.scss";
import ru from "react-phone-input-2/lang/ru.json";
// import en from "react-phone-input-2/lang/es.json";
import { useTranslation } from "react-i18next";

function PhoneInputFieldView(props: PhoneInputProps) {
  const { i18n } = useTranslation();

  return (
    <PhoneInput
      containerClass="PhoneInput-container"
      inputClass="PhoneInput-input"
      buttonClass="PhoneInput-button"
      dropdownClass="PhoneInput-dropdown"
      localization={i18n.language.substr(0, 2) == "en" ? undefined : ru}
      {...props}
    />
  );
}

export default PhoneInputFieldView;
