import { NodeType } from "../misc/consts";

export const Leaf = ({ attributes, children, leaf }: any) => {
  const currentUrl = () => {
    return leaf.text.startsWith("https://")
      ? leaf.text.slice("https://".length)
      : leaf.text.startsWith("http://")
      ? leaf.text.slice("http://".length)
      : leaf.text;
  };
  if (leaf.decoration == NodeType.Link) {
    children = (
      <a
        style={{ cursor: "pointer" }}
        href={`https://${currentUrl()}`}
        target="_blank"
        rel="noreferrer"
        onClick={() => {
          window.open(`https://${currentUrl()}`, "_blank");
        }}
      >
        {children}
      </a>
    );
  }
  return (
    <span
      {...attributes}
      style={{
        backgroundColor: leaf.backgroundColor,
        color: leaf.color,
        margin: leaf.margin,
        borderRadius: leaf.borderRadius,
        padding: leaf.padding,
      }}
    >
      {children}
    </span>
  );
};
