import React from "react";
import "./PageHeader.scss";
import { Segmented, Text } from "../../../uiKit";
import { IPageHeaderTab } from "./PageHeader";
import { Link } from "react-router-dom";

interface IPageHeaderView {
  title?: string;
  children?: React.ReactNode;
  justify?: "start" | "end" | "space-between";
  tabs?: IPageHeaderTab[];
}

function PageHeaderView(props: IPageHeaderView) {
  return (
    <div id="page-header__wrapper" className="py-2 d-flex align-center">
      {props.tabs != null && props.tabs.length > 0 && (
        <Segmented
          className="page-tabs"
          options={props.tabs.map((t, i) => ({ label: <Link to={t.link} children={t.label} />, value: i }))}
        />
      )}
      {(props.title ?? "").length > 0 && (
        <Text size="20px" className="mr-2 mb-0">
          {props.title ?? ""}
        </Text>
      )}
      {/*)}*/}
      <div className="d-flex align-center flex-grow-1">{props.children}</div>
    </div>
  );
}

export default PageHeaderView;
