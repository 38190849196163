import React, { useEffect, useState } from "react";
import CompanyAddDialogView from "./CompanyAddDialogView";
import { api } from "../../../../services";
import { useNotifier, useRootStore } from "../../../../hooks";
import { useTranslation } from "react-i18next";
import { CompanyShortDto } from "../../../../api";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import { useRequestHandler } from "../../../../hooks/useRequestHandler";

export interface ICompanyAddDialog {
  open: boolean;

  onClose(): void;
  onAdd(): void;
}

function CompanyAddDialog(props: ICompanyAddDialog) {
  const notifier = useNotifier();
  const { t } = useTranslation();
  const { authStore } = useRootStore();
  const [inviteCode, setInviteCode] = useState<string>(
    authStore.getInviteCode != null && authStore.getInviteCode.length > 0 ? authStore.getInviteCode : ""
  );
  const [step, setStep] = useState<1 | 2>(1);
  const { onError } = useRequestHandler();
  const [companyData, setCompanyData] = useState<CompanyShortDto | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleClose = () => {
    props.onClose();
    setInviteCode("");
    setStep(1);
    setCompanyData(null);
  };

  useEffect(() => {
    if (authStore.getInviteCode != null && authStore.getInviteCode.length > 0) {
      handleCheckButtonCLick();
    }
  }, []);

  const handleCheckButtonCLick = async () => {
    if (inviteCode.length != 8) {
      return;
    }
    setIsLoading(true);
    const r = await api.auth.signUpWithCompanyInviteValidateCode(inviteCode);
    setIsLoading(false);
    if (!r) {
      notifier.show({ message: t("notifier:error.bad_invite_code"), theme: "error" });
      return;
    }
    if (toJS(authStore.getInitialInfo?.identity?.companies)?.some((u2c) => u2c.companyId == r.id)) {
      notifier.show({ message: t("parse:already_member"), theme: "error" });
      return;
    }
    setCompanyData(r);
    setStep(2);
  };

  const handleAddButtonClick = async () => {
    setIsLoading(true);
    const r = await api.auth.signUpWithCompanyInvite(
      {
        companyInviteKey: inviteCode,
      },
      async () => {
        notifier.show({
          message: t("notifier:success.company_add", {
            companyName: (companyData?.name ?? "").trim().length > 0 ? companyData?.name : companyData?.nameFallback,
          }),
          theme: "success",
        });
        props.onAdd();
        handleClose();
        await authStore.authorizeWithRefreshToken();
      },
      (e) => onError(e)
    );
    setIsLoading(false);
    console.log(r, "r");
    // @ts-ignore TODO: Create custom errors handler
    // notifier.showSuccessError(
    //   t("notifier:success.company_add", {
    //     companyName: (companyData?.name ?? "").trim().length > 0 ? companyData?.name : companyData?.nameFallback,
    //   }),
    //   t("notifier:error.company_add"),
    //   r != null
    // );

    // if (r == null) {
    //   return;
    // }
    // props.onAdd();
    // handleClose();
    // await authStore.authorizeWithRefreshToken();
  };

  return (
    <CompanyAddDialogView
      open={props.open}
      step={step}
      isLoading={isLoading}
      companyData={companyData}
      handleClose={handleClose}
      inviteCode={inviteCode}
      setInviteCode={setInviteCode}
      handleCheckButtonCLick={handleCheckButtonCLick}
      handleAddButtonClick={handleAddButtonClick}
    />
  );
}

export default observer(CompanyAddDialog);
