import React from "react";
import "./DatePickerRange.scss";
// import { RangePicker } from "../datePicker/DatePickerView";
import { Dayjs } from "dayjs";
import { RangePickerDateProps } from "antd/es/date-picker/generatePicker";
import { RangePicker } from "../datePicker/DatePickerView";
import clsx from "clsx";

type TDatePickerRangeView = {
  readOnly?: boolean;
} & RangePickerDateProps<Dayjs>;

export function DatePickerRangeView({ className, readOnly, ...rest }: TDatePickerRangeView) {
  return (
    <RangePicker
      className={clsx("uiKit-datePickerRange", className ?? "", {
        "no-interact": readOnly,
      })}
      {...rest}
    />
  );
}
