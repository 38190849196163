import React from "react";
import ZoomButtonView from "./ZoomButtonView";

export type TZoomButtonBehavior = "zoom" | "counter";

export interface IZoomButton {
  value: number;
  behavior?: TZoomButtonBehavior;
  step?: number;
  min?: number;
  max?: number;
  valueToReset?: number;
  buttonProps?: {
    text?: {
      afterValue?: string;
    };
  };
  onChange(value: number): void;
}

function ZoomButton(props: IZoomButton) {
  const min = props.min ?? (props.behavior == "counter" ? 1 : 0.5);
  const max = props.max ?? (props.behavior == "counter" ? 5 : 1.5);
  const step = props.step ?? (props.behavior == "counter" ? 1 : 0.1);

  const handleChange = (value: number) => {
    const newValue = props.value + value;
    props.onChange(newValue);
  };

  const handleReset = () => {
    props.onChange(props.valueToReset ?? 1);
  };

  return (
    <ZoomButtonView {...props} min={min} max={max} step={step} handleChange={handleChange} handleReset={handleReset} />
  );
}

export default ZoomButton;
