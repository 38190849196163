import React from "react";
import { Answer } from "./Answer";
import { IAnswersGenerator } from "./CreateTestsForm.interface";
import { AddAnswerButton } from "./AddAnswerButton";

export const AnswersGenerator: React.FC<IAnswersGenerator> = (props) => {
  const handleChangeAnswerRight = (newValue: boolean, id: number) =>
    props.onChangeAnswerRight(newValue, props.questionId, id);

  const handleChangeAnswerName = (newValue: string, id: number): void =>
    props.onChangeAnswerName(newValue, props.questionId, id);

  const handleDeleteAnswer = (id: number) => props.onDeleteAnswer(props.questionId, id);

  const addNewAnswerIsDisabled = () =>
    props.answers.length === 0 ? false : !props.answers[props.answers.length - 1]?.name?.trim().length;

  const handleAppendEmptyAnswer = () => props.onAppendEmptyAnswer(props.questionId);

  return (
    <div className="d-stack-column spacing-3 justify-start align-start" style={{ width: "100%" }}>
      {props.answers.map((answer) => (
        <Answer
          key={answer.id}
          id={answer.id!}
          checked={answer.isRight!}
          name={answer.name!}
          onChangeChecked={handleChangeAnswerRight}
          onChangeName={handleChangeAnswerName}
          onDelete={handleDeleteAnswer}
        />
      ))}
      <AddAnswerButton onClick={handleAppendEmptyAnswer} disabled={addNewAnswerIsDisabled() || props.disabled} />
    </div>
  );
};

