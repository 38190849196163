import React, { useState } from "react";
import { MetricSettingsDialogView } from "./MetricSettingsDialogView";
import { TChartSettings } from "../../pages/dashboards/components/Metric2Dashboard/MetricToDashboard";

interface IMetricSettings {
  open: boolean;
  title: string;
  onSuccess: () => void;
  onOpen: (isOpen: boolean) => void;
  chartSettings: TChartSettings;
  onChartSettingsChange: (value: TChartSettings) => void;
}

export const MetricSettingsDialog = (props: IMetricSettings) => {
  return (
    <MetricSettingsDialogView
      chartSettings={props.chartSettings}
      onChartSettingsChange={props.onChartSettingsChange}
      onSuccess={props.onSuccess}
      open={props.open}
      onOpen={props.onOpen}
      title={props.title}
    />
  );
};
