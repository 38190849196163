import React from "react";
import {IListUsersDialog} from "./ListUsersDialog.interface";
import { ListUsersDialogView } from "./ListUsersDialogView";

export const ListUsersDialog : React.FC<IListUsersDialog> = (props) => {
  return (
    <ListUsersDialogView {...props} />
  );
};

