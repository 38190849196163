import { AutocompleteModel, User2RoleDto, UserScheduleSubstituteDto } from "../../../../../api";
import SubheaderText from "../../../../elements/subheaderText/SubheaderText";
import React, { useEffect, useRef, useState } from "react";
import { useApi } from "../../../../../hooks";
import { api } from "../../../../../services";
import { useTranslation } from "react-i18next";
import { AutocompleteAsync } from "../../../../elements/autocompleteAsync/AutocompleteAsync";

interface IUserScheduleSubstituteConfigContainerProps {
  currentUser2Role: User2RoleDto;
  isMultiple: boolean;
  onChange: (v: UserScheduleSubstituteDto[]) => void;
}

type UserScheduleSubstituteDtoWithName = UserScheduleSubstituteDto & { name: string };

function UserScheduleSubstituteConfigContainer(props: IUserScheduleSubstituteConfigContainerProps) {
  const { t } = useTranslation();
  // used to know if it's initial render since useApi hook isn't finished yet and causes exception
  const isFirstRender = useRef(true);
  const [list, setList] = useState<UserScheduleSubstituteDtoWithName[]>([]);
  const roles = useApi(
    () =>
      api.role.autocomplete({
        pageSize: -1,
        orderBy: "name",
        order: "asc",
        userIds: [props.currentUser2Role.userId!],
      }),
    null,
    (v) => setListFromAutocompleteWithCondition(v!.items!)
  );

  // use isMultiple as condition set lists from mapped model
  const setListFromAutocompleteWithCondition = (v: AutocompleteModel[]): void =>
    setList(
      (props.isMultiple ? v : v.filter((x) => x.id == props.currentUser2Role.roleId)).map(
        (x) =>
          ({
            roleId: x.id,
            name: x.text,
            userId: list.find((y) => y.roleId == x.id)?.userId ?? null,
          } as UserScheduleSubstituteDtoWithName)
      )
    );

  useEffect(() => props.onChange(list), [list]);
  // apply effect on props.isMultiple change
  useEffect(() => {
    // set false on first run and return
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    setListFromAutocompleteWithCondition(roles.value!.items!);
  }, [props.isMultiple]);

  const handleListItemChange = (roleId: number, v: number | null): void =>
    setList(
      list.map((x) => {
        if (x.roleId == roleId) x.userId = v;
        return x;
      })
    );

  return (
    <div className="mb-3">
      <SubheaderText text={t("ui:text.substitute")} />

      {roles.isLoading
        ? t("text:loading_data") /* TODO: replace */
        : list.map((x, i) => (
            <div className={"d-stack align-center spacing-2 " + (i > 0 ? "mt-3" : "")} key={x.roleId}>
              <span
                style={{
                  flexShrink: 0,
                  width: "30%",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {x.name}
              </span>
              <div className="flex-grow-1" style={{ overflow: "hidden" }}>
                <AutocompleteAsync
                  className="full-width"
                  type="user"
                  allowClear
                  size="middle"
                  idsToFilter={[props.currentUser2Role.userId!]}
                  value={x.userId ?? undefined}
                  onChange={(value) => handleListItemChange(x.roleId!, value != null ? +value : null)}
                />
              </div>
            </div>
          ))}
    </div>
  );
}

export default UserScheduleSubstituteConfigContainer;
