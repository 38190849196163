import React from "react";
import { IBoardCollapseView } from "./BoardCollapse.interface";
import { Collapse, CollapsePanel } from "../../../../../uiKit";
import { findCountByOrgchartId, mapToArrNumbers } from "./helpers";
import { IoIosArrowDown } from "@react-icons/all-files/io/IoIosArrowDown";
import clsx from "clsx";
import { OrgchartCard } from "../../../../../elements/orgchartCard/OrgchartCard";
import { baseBoardStatusKeys } from "../../constants/keys";
import { DradAndDropContainer } from "./DradAndDropContainer";
import { CounterChip } from "../../../../../elements/counterChip/CounterChip";
import { ColorModeKeys } from "../../../../../elements/counterChip/misc/keys";
import { theme } from "antd";

export const BoardCollapseView = (props: IBoardCollapseView) => {
  const { useToken } = theme;
  const { token } = useToken();

  return (
    <Collapse
      bordered={false}
      className="board-collapse"
      activeKey={props.openBoardIds.map((item) => item.toString())}
      onChange={(keys) => {
        props.onChangeOpenBoards(mapToArrNumbers(keys));
      }}
      style={{ background: token.colorBgContainerDisabled }}
    >
      {props.boardsByOrgcharts?.map((orgchart) => (
        <CollapsePanel
          key={orgchart.id?.toString() ?? ""}
          className="issue-board_collapse"
          header={<OrgchartCard avatarSize={25} fontSize={14} orgchart={orgchart} />}
          showArrow={false}
          style={{ width: "100%" }}
          extra={
            <div className="d-stack-row align-center justify-space-between">
              <CounterChip
                colorMode={ColorModeKeys.faint}
                count={findCountByOrgchartId(props.boardInfo ?? [], baseBoardStatusKeys.pending, orgchart.id ?? 0) ?? 0}
              />
              <CounterChip
                className="ml-2"
                colorMode={ColorModeKeys.primary}
                count={findCountByOrgchartId(props.boardInfo ?? [], baseBoardStatusKeys.inWork, orgchart.id ?? 0) ?? 0}
              />
              <div
                className={clsx("ml-4", { "rotated-n180": props.openBoardIds.includes(orgchart.id ?? 0) })}
                style={{ transition: "all 0.3s" }}
              >
                <IoIosArrowDown fontSize={18} color="var(--color-text-weak)" />
              </div>
            </div>
          }
        >
          <DradAndDropContainer
            onChangeBoardColumnsState={props.onChangeBoardColumnsState}
            board={props.board}
            orgchartId={orgchart.id ?? 0}
            openBoardIds={props.openBoardIds}
          />
        </CollapsePanel>
      ))}
    </Collapse>
  );
};
