import { Value } from "@udecode/plate";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { getLengthContent } from "../../../../../utils/textEditor";
import SubheaderText from "../../../../elements/subheaderText/SubheaderText";
import { ToolbarType } from "../../../textEditorOld/misc/consts";
import { TextEditor } from "../../../textEditorOld/TextEditor";

interface IIssueRequiredActionsDialogTabAttachMessage {
  value: Value;
  onInput: (value: Value) => void;
}

export function IssueRequiredActionsDialogTabAttachMessage(props: IIssueRequiredActionsDialogTabAttachMessage) {
  const { t } = useTranslation();

  return (
    <div>
      <Form.Item
        className="my-0"
        extra={
          t("validation:min_char_number", { number: 1 }) +
          ". " +
          t("common:misc.entered_characters_number", { number: getLengthContent(props.value) })
        }
      >
        <SubheaderText text={t("ui:subheader.message")} />
        <TextEditor
          id="add-proof-editor"
          placeholder={t("ui:subheader.text")}
          value={props.value}
          onChange={props.onInput}
          toolbarType={ToolbarType.Hidden}
        />
      </Form.Item>
    </div>
  );
}
