import React, { forwardRef } from "react";
import "./CreateTestsForm.scss";
import { ICreateTestsForm } from "./CreateTestsForm.interface";
import { QuestionGenerator } from "./QuestionGenerator";


export const CreateTestsForm : React.FC<ICreateTestsForm> = forwardRef((props,ref) => {

  return (
    <div style={props.styles} className="create-tests-form">
      <QuestionGenerator
        ref={ref}
        regulationContentId={props.regulationContentId}
        noImmediatelyCreateQuestion={props.noImmediatelyCreateQuestion}
        // onSave={props.onSave}
        editableMode={props.editableMode}
        onClose={props.onClose}
        onSaveChanges={props.onSaveChanges}
        onChangeHasChanges={props.onChangeHasChanges}
        defaultQuestions={props.defaultQuestions}
      />
    </div>
  );
})