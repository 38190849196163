import React from "react";
import { FiChevronDown } from "@react-icons/all-files/fi/FiChevronDown";
import "./ExpandableContainer.scss";
import { CollapsibleContainer, Text } from "../../uiKit";

interface IExpandableContainerView {
  text: string;
  expanded: boolean;
  isLoading?: boolean;
  iconSize?: string;
  children?: React.ReactNode;
  onClick: () => void;
}

function ExpandableContainerView(props: IExpandableContainerView) {
  return (
    <div className="expandable-container__wrapper">
      <div
        onClick={() => props.onClick()}
        className="expandable-container__button d-flex justify-space-between align-center mb-1 mt-2"
      >
        <Text className="expandable-container__title" children={props.text} />
        <FiChevronDown
          style={{ transform: props.expanded ? "rotate(180deg)" : "rotate(0deg)" }}
          fontSize={props.iconSize ?? "small"}
        />
      </div>
      {props.isLoading && (
        <div style={{ width: "100%" }}>
          {/*<LinearProgress />*/}
          //TODO: check this moment
        </div>
      )}
      <CollapsibleContainer open={props.expanded} children={props.children} />
    </div>
  );
}

export default ExpandableContainerView;
