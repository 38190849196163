import { getPluginType, PlateEditor, Value } from "@udecode/plate-core";
import { ELEMENT_IFRAME } from "./createIFramePlugin";
import { TIFrameElement } from "./IFrameElement";

export interface CreateIFrameNodeOptions {
  src: string;
}

export const createIFrameNode = <V extends Value>(
  editor: PlateEditor<V>,
  { src }: CreateIFrameNodeOptions
): TIFrameElement => {
  const type = getPluginType(editor, ELEMENT_IFRAME);

  return {
    type,
    src,
    children: [{ text: "" }],
  };
};
