import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import { RootStoreContext } from "./contexts/rootStoreContext";
import { rootStore } from "./stores/rootStore";
import Interceptors from "./plugins/interceptors";

Interceptors.setup(rootStore);

ReactDOM.render(
  <React.StrictMode>
    <RootStoreContext.Provider value={rootStore}>
      {/*<LocalizationProvider locale={rootStore.appStore.getAppLocale == "ru" ? ru : en} dateAdapter={AdapterDateFns}>*/}
      <App />
      {/*</LocalizationProvider>*/}
    </RootStoreContext.Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
