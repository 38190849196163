import React from "react";
import { GuideParser } from "../../../../../services/guideParser";
import { getGuideContent } from "../helpers";
import { useRootStore } from "../../../../../hooks";
import { guideKeys } from "../../../../../stores/hintStore";
import ruContent from "../../../../../utils/guides1/communication/ru.json";
import { enCommunicationsGuideNodes, ukCommunicationsGuideNodes } from "../../../../../utils/guides";
import { TextEditor } from "../../../textEditorOld/TextEditor";

export const getContent = (localeKey: string | null): any | null => {
  switch (localeKey) {
    case "ru":
      return ruContent;
    case "en":
      return enCommunicationsGuideNodes;
    case "uk":
      return ukCommunicationsGuideNodes;
  }
};

export const CommunicationGuide = () => {
  const { appStore } = useRootStore();

  const guideParser = new GuideParser(getGuideContent(appStore.getAppLocale, guideKeys.communications));

  return appStore.getAppLocale == "en" ? (
    <div className="drawer-body">{guideParser.renderGuide()}</div>
  ) : (
    <TextEditor readOnly={true} id="guide-dasboard" onChange={() => {}} value={getContent(appStore.getAppLocale)} />
  );
};
