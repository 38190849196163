import React, { useState } from "react";
import { api } from "../../../../services";
import { useNotifier } from "../../../../hooks";
import { useTranslation } from "react-i18next";
import { IAppendDriverToCompanyGroupDialog } from "./AppendDriverToCompanyGroupDialog.interface";
import { AppendDriverToCompanyGroupViewDialog } from "./AppendDriverToCompanyGroupViewDialog";
import { UserShortDto } from "../../../../api";
import { driverTypeKeys, getAdminDrivers, getDrivers } from "../../pages/groupCompany/helpers";

export const AppendDriverToCompanyGroupDialog = (props: IAppendDriverToCompanyGroupDialog) => {
  const notifier = useNotifier();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [driverIds, setDriverIds] = useState<number[]>();

  const handleChangeDriverId = (ids?: number[]) => setDriverIds(ids);

  const handleError = () => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });

  const handleAppendDriver = () => {
    setIsLoading(true);
    api.companyGroup
      .edit(props.groupCompany.id!, {
        ...props.groupCompany,
        userTypes: [
          ...(props.groupCompany?.userTypes ?? []),
          ...driverIds?.map((item) => ({ user: { id: item! }, type: props.type }))!,
        ],
      })
      .then(() => {
        notifier.show({ message: t("notifier:success.add_employee"), theme: "success" });
        props.onReloadData();
        props.onClose();
      })
      .catch(handleError)
      .finally(() => setIsLoading(false));
  };

  const handleMapToId = (values: UserShortDto[]): number[] => values.map((item) => item.id!);

  const handleGetExcludedIds = (): number[] => {
    if (!props.groupCompany.userTypes) return [];
    switch (props.type) {
      case driverTypeKeys.admin:
        return handleMapToId(getAdminDrivers(props.groupCompany.userTypes));
      case driverTypeKeys.driver:
        return handleMapToId(getDrivers(props.groupCompany.userTypes));
      default:
        return [];
    }
  };

  return (
    <AppendDriverToCompanyGroupViewDialog
      type={props.type}
      open={props.open}
      isLoading={isLoading}
      driverIds={driverIds}
      onGetExcludedIds={handleGetExcludedIds}
      onChangeDriverIds={handleChangeDriverId}
      onAdd={handleAppendDriver}
      onClose={props.onClose}
    />
  );
};
