import React from "react";
import { Badge } from "antd";
import "./BadgeRibbon.scss";
import { RibbonProps } from "antd/es/badge/Ribbon";
import clsx from "clsx";

interface IBadgeRibbonView extends RibbonProps {
  //
}

const { Ribbon } = Badge;

export function BadgeRibbonView({ className, ...rest }: IBadgeRibbonView) {
  return <Ribbon className={clsx("uiKit-badgeRibbon", className ?? "")} {...rest} />;
}
