import { INSERT_TABLE_COMMAND } from "@lexical/table";
import { EditorThemeClasses, Klass, LexicalEditor, LexicalNode } from "lexical";
import * as React from "react";
import { createContext, memo, useCallback, useMemo, useState } from "react";
import { Button, Dialog, DialogActions, InputNumber } from "../../../../uiKit";
import { useTranslation } from "react-i18next";

export type CellContextShape = {
  cellEditorConfig: null | CellEditorConfig;
  cellEditorPlugins: null | JSX.Element | Array<JSX.Element>;
  set: (cellEditorConfig: null | CellEditorConfig, cellEditorPlugins: null | JSX.Element | Array<JSX.Element>) => void;
};

export type CellEditorConfig = Readonly<{
  namespace: string;
  nodes?: ReadonlyArray<Klass<LexicalNode>>;
  onError: (error: Error, editor: LexicalEditor) => void;
  readOnly?: boolean;
  theme?: EditorThemeClasses;
}>;

export const CellContext = createContext<CellContextShape>({
  cellEditorConfig: null,
  cellEditorPlugins: null,
  set: () => {
    // Empty
  },
});

export function TableContext({ children }: { children: JSX.Element }) {
  const [contextValue, setContextValue] = useState<{
    cellEditorConfig: null | CellEditorConfig;
    cellEditorPlugins: null | JSX.Element | Array<JSX.Element>;
  }>({
    cellEditorConfig: null,
    cellEditorPlugins: null,
  });
  return (
    <CellContext.Provider
      value={useMemo(
        () => ({
          cellEditorConfig: contextValue.cellEditorConfig,
          cellEditorPlugins: contextValue.cellEditorPlugins,
          set: (cellEditorConfig, cellEditorPlugins) => {
            setContextValue({ cellEditorConfig, cellEditorPlugins });
          },
        }),
        [contextValue.cellEditorConfig, contextValue.cellEditorPlugins]
      )}
    >
      {children}
    </CellContext.Provider>
  );
}

export const InsertTableDialog = memo(
  ({
    activeEditor,
    onClose,
    open,
  }: {
    activeEditor: LexicalEditor;
    onClose: () => void;
    open: boolean;
  }): JSX.Element => {
    const [rows, setRows] = useState<string>("5");
    const [columns, setColumns] = useState<string>("5");
    const { t } = useTranslation();

    const onClick = useCallback(() => {
      activeEditor.dispatchCommand(INSERT_TABLE_COMMAND, {
        columns,
        rows,
        includeHeaders: {
          rows: false,
          columns: false,
        },
      });

      onClose();
    }, [activeEditor, columns, onClose, rows]);

    return (
      <Dialog title={t("ui:title.create_table")} open={open} onClose={onClose} width={300} closable={false}>
        <div className="d-stack-column">
          <div className="d-stack-row align-center justify-space-between">
            <span className="mr-2">{`${t("ui:subheader.table_row")}:`}</span>
            <InputNumber style={{ width: 180 }} onChange={(v) => setRows(String(v))} value={rows} />
          </div>
          <div className="d-stack-row align-center justify-space-between mt-3">
            <span className="mr-2">{`${t("ui:subheader.table_col")}:`}</span>
            <InputNumber style={{ width: 180 }} onChange={(v) => setColumns(String(v))} value={columns} />
          </div>
        </div>
        <DialogActions>
          <Button onClick={onClick}>{t("ui:button.create")}</Button>
        </DialogActions>
      </Dialog>
    );
  }
);
