import { insertNodes, InsertNodesOptions, PlateEditor, Value } from "@udecode/plate-core";
import { TAnchorElement } from "./AnchorElement";
import { createAnchorNode, CreateAnchorNodeOptions } from "./createAnchorNode";

export const insertAnchor = <V extends Value>(
  editor: PlateEditor<V>,
  createAnchorNodeOptions: CreateAnchorNodeOptions,
  options?: InsertNodesOptions<V>
) => {
  insertNodes<TAnchorElement>(editor, [createAnchorNode(editor, createAnchorNodeOptions)], options as any);
};
