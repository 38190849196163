import React from "react";
import { FiCalendar } from "@react-icons/all-files/fi/FiCalendar";
import { Button, Icon } from "../../uiKit";
import { IPickPeriodButtonView } from "./PickPeriodButton.interface";
import { FiStopCircle } from "@react-icons/all-files/fi/FiStopCircle";

export const PickPeriodButtonView = (props: IPickPeriodButtonView) => {
  return (
    <Button
      icon={<Icon component={() => <FiCalendar />} />}
      onClick={props.onClick}
      variant="filled"
      className={props.className}
    >
      {props.text}
    </Button>
  );
};
