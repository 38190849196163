import React from "react";
import SubheaderTextView from "./SubheaderTextView";

export interface ISubheaderText {
  text: React.ReactNode;
  noBottomOffset?: boolean;
  className?: string;
}

function SubheaderText(props: ISubheaderText) {
  return <SubheaderTextView {...props} />;
}

export default SubheaderText;
