import React from "react";
import "./unauthorized.scss";
import { Layout, LayoutContent } from "../components/uiKit";

function UnauthorizedLayout({ children }: { children: React.ReactNode }) {
  return (
    <Layout>
      <LayoutContent className="app-content__wrapper__unauthorized">{children}</LayoutContent>
    </Layout>
  );
}

export default UnauthorizedLayout;
