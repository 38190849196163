import { UpdateNoteContentDto } from "../../../../../api";
import { contentToString, initialContent } from "../../../../../utils/textEditor";
import { LocalesKeys } from "../../../../../constants/localesKeys";

export interface IInitialUpdateNotes {
  ru: UpdateNoteContentDto;
  en: UpdateNoteContentDto;
  uk: UpdateNoteContentDto;
  zh: UpdateNoteContentDto;
}

export const initialNotes: IInitialUpdateNotes = {
  en: {
    name: "",
    content: contentToString(initialContent),
    cultureKey: LocalesKeys.en,
  },
  ru: {
    name: "",
    content: contentToString(initialContent),
    cultureKey: LocalesKeys.ru,
  },
  uk: {
    name: "",
    content: contentToString(initialContent),
    cultureKey: LocalesKeys.uk,
  },
  zh: {
    name: "",
    content: contentToString(initialContent),
    cultureKey: LocalesKeys.zh,
  },
};

export enum validationErrorKeys {
  noName = "NO_NAME",
  noContent = "NO_CONTENT",
  noAllNotes = "NO_ALL_CONTENTS",
}

export enum publishedSelectKeys {
  published = "PUBLISHED",
  notPublished = "NOT_PUBLISHED",
}
