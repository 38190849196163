import React from "react";
import { InputView } from "./InputView";
import { InputProps } from "antd";

export interface IInput extends InputProps {
  readOnly?: boolean;
}

export function Input(props: IInput) {
  return <InputView {...props} />;
}
