import React, { useState } from "react";
import { IssueRejectDialogView } from "./IssueRejectDialogView";
import { IIssueRejectDialog } from "./IssueRejectDialog.interface";

;

const IssueRejectDialogMemo = ({open, onRejectIssue, onClose}: IIssueRejectDialog) => {

    const [reason, setReason] = useState<string>("");

    const handleChangeReason = (newReason : string) => {
        setReason(newReason)
    }

    const onReject = async () => {
        if (reason.trim().length > 0) {
            onRejectIssue(reason);
            onCloseAndReset()
        }
    };

    const onCloseAndReset = () => {
        setReason("")
        onClose();
    }

    return (
        <IssueRejectDialogView
            open={open}
            onClose={onClose}
            onSuccess={onReject}
            reason={reason}
            setReason={handleChangeReason}
        />
    );
};

export const IssueRejectDialog = React.memo(IssueRejectDialogMemo);
