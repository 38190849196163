import { createPluginFactory, onKeyDownToggleElement } from "@udecode/plate";
import { AnchorElement } from "./AnchorElement";

export const ELEMENT_ANCHOR = "div";

export const createAnchorPlugin = createPluginFactory({
  key: ELEMENT_ANCHOR,
  isElement: true,
  isVoid: true,
  component: AnchorElement,
  handlers: { onKeyDown: onKeyDownToggleElement },
  then: (editor, { type }) => ({
    deserializeHtml: {
      rules: [
        {
          validNodeName: "div",
        },
      ],
      getNode: (el) => ({
        type,
        anchorId: el.getAttribute("id"),
      }),
    },
  }),
});
