import React from "react";
import "./ListCompanies.scss";
import { IListCompanies } from "./ListCompanies.interface";
import { useTranslation } from "react-i18next";
import { Button, Card, Empty } from "../../../../../uiKit";
import { ListCompaniesCard } from "../ListCompaniesCard/ListCompaniesCard";

export const ListCompanies = (props: IListCompanies) => {
  const { t } = useTranslation();

  return (
    <Card
      title={t("ui:title.companies")}
      className="flex-grow-1 flex-shrink-1 br-none"
      isShadowed={false}
      style={{ flexBasis: "50%" }}
      size="default"
      extra={
        <Button variant="filled" onClick={props.onOpenAddCompanyToGroupDialog}>
          {t("ui:button.add")}
        </Button>
      }
    >
      {!!props.companies?.length ? (
        <div className="d-stack-column spacing-2">
          {props.companies?.map((item) => (
            <ListCompaniesCard key={item.id} company={item} onButtonClick={props.onDeleteCompanyById} />
          ))}
        </div>
      ) : (
        <Empty />
      )}
    </Card>
  );
};
