import React from 'react';
import { IUpdateNoteCreateHeader } from "./UpdateNoteCreateHeader.interface";
import {LocaleSwitcher} from "../../components/LocaleSwitcher/LocaleSwitcher";

export const UpdateNoteCreateHeader = (props: IUpdateNoteCreateHeader) => {
    return (
        <div className="d-stack-row align-center justify-space-between full-width">
            <LocaleSwitcher
                currentLocale={props.currentLocale}
                onChangeLocale={props.onChangeLocale}
            />
        </div>
    );
};