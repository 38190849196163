import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "./IssueUpdateContainer.scss";
import { IChangeableExecutors, IIssueUpdateContainer } from "./IssueUpdateContainer.interface";
import { useTranslation } from "react-i18next";
import { useNotifier, useRootStore } from "../../../../../../hooks";
import { StaticFileDto } from "../../../../../../api";
import { api } from "../../../../../../services";
import { IssueActionConsts } from "../../../types/consts";
import { IssueContext } from "../../../../../../contexts/communication/issueContext";
import { CustomConfirmDialog } from "../../../../dialogs/customConfirmDialog/СustomConfirmDialog";
import { IssueWithdrawDialog } from "../../../../dialogs/issueWithdrawDialog/IssueWithdrawDialog";
import { IssueRejectDialog } from "../../../../dialogs/issueRejectDialog/IssueRejectDialog";
import { IssueExecutorChangeDialog } from "../../../../dialogs/issueExecutorChangeDialog/IssueExecutorChangeDialog";
import { IssueCopyDialog } from "../../../../dialogs/issueCopyDialog/issueCopyDialog";
import { IssueFormWrapper } from "../../forms/common/IssueFormWrapper";
import { IssueHistoryModule } from "../../modules/IssueHistoryModule/IssueHistoryModule";
import { IssueFormKeys } from "../../forms/common/IssueForm.interface";
import { IssueHeader } from "../../components/issueHeader/IssueHeader";
import { getDeleteIssueTitle, getDialogNotifications, getUpdateFormConfigByIssueType } from "../../helpers";
import { IIssueHistoryModuleRef } from "../../modules/IssueHistoryModule/IssueHistory.interface";

export const IssueUpdateContainer = (props: IIssueUpdateContainer) => {
  const { t } = useTranslation();
  const notifier = useNotifier();
  const { authStore, helperStore, issueInitDataStore } = useRootStore();

  const [forceUpdate, setForceUpdate] = useState<boolean>(false);
  const issueHistoryModuleRef = useRef<IIssueHistoryModuleRef | undefined | null>();

  const [isWithdrawIssueDialogOpen, setIsWithdrawIssueDialogOpen] = useState<boolean>(false);
  const [isCopyIssueDialogOpen, setIsCopyIssueDialogOpen] = useState<boolean>(false);
  const [attachmentsIssue, setAttachmentsIssue] = useState<StaticFileDto[]>([]);
  const [openDialogDeleteMessage, setOpenDialogDeleteMessage] = useState<boolean>(false);
  const [openIssueRejectDialog, setOpenIssueRejectDialog] = useState<boolean>(false);
  const [isExecutorChangeDialogOpened, setIsExecutorChangeDialogOpened] = useState<boolean>(false);
  const [changeableExecutors, setChangeableExecutors] = useState<IChangeableExecutors | undefined>(undefined);

  const handlerApiError = () => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });

  const handleExecutorChangeDialogSuccess = async (reason: string) => {
    //props.updater.applyChanges
    await props.updater.update();
    //setForceUpdate(true);
    setChangeableExecutors(undefined);
    const r = await api.issueHistory.create({
      issueId: props.issueId,
      comment: { text: reason },
    });
    if (r == null) return handlerApiError();
  };

  const handleExecutorChangeDialogClosed = () => {
    props.updater.applyChanges({
      executorUserId: changeableExecutors!.prevExecutorId,
      dateDeadline: props.updater.beforeState?.dateDeadline,
      dateWorkStart: props.updater.beforeState?.dateWorkStart,
    });
    setIsExecutorChangeDialogOpened(false);
    setChangeableExecutors(undefined);
  };

  const handleChangeExecutor = (prevExecutorId: number, nextExecutorId: number) => {
    if (nextExecutorId === props.updater?.currentState?.executorUserId) return;
    setIsExecutorChangeDialogOpened(true);
    setChangeableExecutors({ prevExecutorId, nextExecutorId });
    props.updater.applyChanges({ executorUserId: nextExecutorId });
  };

  const handleOpenIssueRejectDialog = () => {
    setOpenIssueRejectDialog(true);
  };
  const onCloseIssueRejectDialog = () => {
    setOpenIssueRejectDialog(false);
  };
  const onCloseWithdrawIssueDialog = useCallback(() => {
    setIsWithdrawIssueDialogOpen(false);
  }, []);
  const handleOpenWithdrawIssueDialog = useCallback(() => {
    setIsWithdrawIssueDialogOpen(true);
  }, []);
  const onCloseCopyIssueDialog = useCallback(() => {
    setIsCopyIssueDialogOpen(false);
  }, []);
  const handleOpenCopyIssueDialog = useCallback(() => {
    setIsCopyIssueDialogOpen(true);
  }, []);
  const handleToggleDeleteConfirmDialog = (open: boolean) => {
    setOpenDialogDeleteMessage(open);
  };

  const setAttachmentsIssueSync = useCallback(
    (files: StaticFileDto[]) => {
      const newAttachments = [
        ...(props.updater?.currentState?.attachments ?? []),
        ...files
          .filter((f) => !props.updater?.currentState?.attachments?.some((f2) => f2.fileId == f.id))
          .map((f, i) => ({
            id: 0,
            issueId: props.updater?.currentState?.id,
            fileId: f.id,
            file: f,
          })),
      ];
      props.updater.applyChanges({ attachments: [...newAttachments] });
      // setAttachmentsIssue(updater?.currentState?.attachments?.map(a => a.file)?.filter(x => x != null))
      setForceUpdate(true);
    },
    [props.updater?.currentState, props.updater.update, setForceUpdate]
  );

  const handleDeleteAttachmentsByFileId = useCallback(
    (fileId: number) => {
      props.updater.applyChanges({
        attachments: props.updater?.currentState?.attachments?.filter((item) => item.fileId != fileId) ?? [],
      });
      setForceUpdate(true);
    },
    [props.updater?.currentState, props.updater?.currentState?.attachments, props.updater.update, setForceUpdate]
  );

  const isExecutor = useMemo(
    () => authStore?.getInitialInfo?.identity?.id === props.updater?.currentState?.executorUserId,
    [authStore?.getInitialInfo?.identity?.id, props.updater?.currentState?.executorUserId]
  );

  const isContributor = useMemo(
    () =>
      authStore!.getInitialInfo?.identity?.id === props.updater?.currentState?.executorUserId ||
      authStore?.getInitialInfo?.identity?.id === props.updater?.currentState?.createdByUserId,
    [
      authStore?.getInitialInfo?.identity?.id,
      props.updater?.currentState?.createdByUserId,
      props.updater?.currentState?.executorUserId,
    ]
  );

  const subId = useMemo(() => props.updater.currentState?.subId, [props.updater.currentState?.subId]);

  const status = useMemo(
    () => props.updater.currentState?.currentStatus?.boardStatus?.name,
    [props.updater.currentState?.currentStatus?.boardStatus?.name]
  );

  const indicators = useMemo(
    () => props.updater.currentState?.calculated?.indicators,
    [props.updater.currentState?.calculated?.indicators]
  );

  const actions = useMemo(
    () => props.updater.currentState?.calculated?.actions,
    [props.updater.currentState?.calculated?.actions]
  );

  const customFields = useMemo(
    () => helperStore.getIssueCustomField.find((item) => item.type === props.issueType && !!item.items.length)?.items,
    [helperStore.getIssueCustomField]
  );

  const strictDeadline =
    !!props.updater.currentState?.fields?.length &&
    !!customFields?.length &&
    !!props.updater?.currentState?.fields?.find((item) => item.key === customFields[0].key)?.valueBool;

  const formConfig = useMemo(() => getUpdateFormConfigByIssueType(props.issueType), [props.issueType]);

  const formNotifications = useMemo(() => getDialogNotifications(props.issueType, t), [props.issueType]);

  const senderDeadlineExpired = props.updater?.currentState?.dateDeadline
    ? new Date() >= new Date(props.updater?.currentState?.dateDeadline)
    : undefined;

  const handleWithdrawIssue = useCallback(
    async (newExecutorId: number, deadline: Date | null) => {
      if (props.updater.currentState) {
        const newWithdrawIssue = props.updater.currentState;
        newWithdrawIssue.executorUserId = newExecutorId;
        newWithdrawIssue.dateDeadline = deadline && deadline.toJSON();
        const r = await api.issue.edit(props.updater.currentState.id!, newWithdrawIssue);
        if (r == null) return handlerApiError();
        else
          notifier.show({
            message: formNotifications.onWithdraw,
            theme: "success",
          });
      }
    },
    [props.updater.currentState]
  );
  const handleRejectIssue = useCallback(
    async (reason: string) => {
      const r = await api.issueHistory.create({
        issueId: props.issueId,
        comment: { text: reason },
        actions: [{ key: IssueActionConsts.reject }],
      });
      if (r == null) return handlerApiError();
      else
        notifier.show({
          message: formNotifications.onReject,
          theme: "success",
        });
    },
    [props.issueId]
  );
  const handleCopyIssue = useCallback(
    async (roleId, executorId, orgchartId) => {
      const copiedIssue = {
        ...props.updater?.currentState,
        executorUserId: executorId,
        roleId: roleId,
        orgchartId,
      };
      if (props.updater?.currentState?.proofRequirement) {
        copiedIssue.proofRequirement!.id = 0;
        copiedIssue.proofRequirement!.rules!.map((item) => {
          item.id = 0;
          return item;
        });
        copiedIssue.proofRequirementId = 0;
      }
      if (copiedIssue.fields && copiedIssue.fields.length > 0) {
        copiedIssue.fields = copiedIssue.fields.map((item) => ({ ...item, id: undefined }));
      }
      if (copiedIssue.attachments && copiedIssue.attachments.length) {
        copiedIssue.attachments = copiedIssue.attachments.map((item) => ({ ...item, id: 0 }));
      }
      const r = await api.issue.create(copiedIssue);
      onCloseCopyIssueDialog();
      if (r == null) return handlerApiError();
      if (r && r.id) {
        props.onRedirectToOtherIssue(r.id);
      }
      notifier.show({
        message: formNotifications.onCreate,
        theme: "success",
      });
    },
    [props.updater]
  );

  const onDeleteIssue = async () => {
    if (props.issueId) {
      const r = await api.issue.del(props.issueId);
      if (r == null) return handlerApiError();
      //notifier.show({message: t("notifier:error.issue_deleted"), theme: "success",});
      issueInitDataStore.getOnSidebarIssuesRestart && issueInitDataStore.getOnSidebarIssuesRestart();
      handleToggleDeleteConfirmDialog(false);
      issueInitDataStore.reset();
      props.onClose();
      notifier.show({
        message: formNotifications.onDelete,
        theme: "success",
      });
    }
  };

  const handleIssueUpdate = async () => {
    //
  };

  useEffect(() => {
    if (props.updater?.currentState?.attachments != null) {
      setAttachmentsIssue(props.updater?.currentState?.attachments.map((item) => item.file) as StaticFileDto[]);
    }
  }, [props.updater?.currentState?.attachments]);

  useEffect(() => {
    if (forceUpdate) {
      props.updater.update();
      setForceUpdate(false);
    }
  }, [forceUpdate]);

  return (
    <IssueContext.Provider
      value={{
        senderDeadlineExpired,
        strictDeadline,
        isContributor,
        isExecutor,
        issueType: props.issueType,
      }}
    >
      {openDialogDeleteMessage && (
        <CustomConfirmDialog
          open={openDialogDeleteMessage}
          onClose={() => handleToggleDeleteConfirmDialog(false)}
          onConfirm={onDeleteIssue}
          title={t(getDeleteIssueTitle(props.issueType))}
        />
      )}
      {isWithdrawIssueDialogOpen && (
        <IssueWithdrawDialog
          open={isWithdrawIssueDialogOpen}
          onClose={onCloseWithdrawIssueDialog}
          onWithdrawIssue={handleWithdrawIssue}
        />
      )}
      {openIssueRejectDialog && (
        <IssueRejectDialog
          open={openIssueRejectDialog}
          onClose={onCloseIssueRejectDialog}
          onRejectIssue={handleRejectIssue}
        />
      )}
      {isExecutorChangeDialogOpened && (
        <IssueExecutorChangeDialog
          open={isExecutorChangeDialogOpened}
          onClose={handleExecutorChangeDialogClosed}
          onSuccess={handleExecutorChangeDialogSuccess}
          updater={props.updater}
        />
      )}
      {isCopyIssueDialogOpen && (
        <IssueCopyDialog open={isCopyIssueDialogOpen} onClose={onCloseCopyIssueDialog} onCopyIssue={handleCopyIssue} />
      )}
      <IssueHeader
        subId={subId}
        status={status}
        indicators={indicators}
        actions={actions}
        title={props.title}
        onClose={props.onClose}
        onOpenCopyIssueDialog={handleOpenCopyIssueDialog}
        onOpenWithdrawIssueDialog={handleOpenWithdrawIssueDialog}
        onOpenIssueRejectDialog={handleOpenIssueRejectDialog}
        onToggleDeleteConfirmDialog={handleToggleDeleteConfirmDialog}
        updater={props.updater}
        onIssueReload={props.onIssueReload}
      />
      <div
        className="full-height full-width d-flex flex-nowrap"
        style={{ overflow: "hidden", maxHeight: "calc(90vh - 112px)" }}
      >
        <IssueFormWrapper
          issueType={props.issueType}
          attachments={attachmentsIssue}
          onChangeExecutor={handleChangeExecutor}
          customFields={customFields}
          setAttachmentsIssueSync={setAttachmentsIssueSync}
          onDeleteAttachmentsByFileId={handleDeleteAttachmentsByFileId}
          updater={props.updater}
          formType={IssueFormKeys.update}
        />

        <IssueHistoryModule
          issueId={props.issueId}
          ref={(ref) => (issueHistoryModuleRef.current = ref)}
          indicators={props.updater.currentState?.calculated?.indicators}
          allowedToSendCommentTypes={props.updater.currentState?.calculated?.allowedToSendCommentTypes}
          createdByUserId={props.updater?.currentState?.createdByUserId}
          executorUserId={props.updater?.currentState?.executorUserId}
          isExecutor={isExecutor}
        />
      </div>
    </IssueContext.Provider>
  );
};
