import React, { useContext, useEffect, useState } from "react";
import { OrgchartDrawerTabMetricsView } from "./OrgchartDrawerTabMetricsView";
import { useNotifier } from "../../../../../../hooks";
import { api } from "../../../../../../services";
import { useTranslation } from "react-i18next";
import { usePagingWithController } from "../../../../../../hooks/usePaging";
import { OrgchartContext } from "../../../../../../contexts/orgchartContext";
import { MetricDto } from "../../../../../../api";
import { MetricFilter } from "../../../../../../api/filters/metricFilter";

export const OrgchartDrawerTabMetrics = () => {
  const orgchartContext = useContext(OrgchartContext);
  const notifier = useNotifier();
  const { t } = useTranslation();
  const [metricId, setMetricId] = useState<number>(0);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

  const metrics = usePagingWithController<MetricDto, MetricFilter>(
    api.metric,
    {
      roleId: Number(orgchartContext?.blockId),
    },
    { pageSize: 20 }
  );

  const handleOpenDialog = (isOpen: boolean, id?: number, isEdit?: boolean, isDelete?: boolean) => {
    if (isDelete) {
      setOpenDeleteDialog(true);
      setMetricId(id!);
      return;
    }
    if (isEdit) {
      toggleEditMode(true);
      setMetricId(id!);
    }
    if (metrics.items.length === 0) {
      setMetricId(0);
    }
    setOpenDialog(isOpen);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const toggleEditMode = (isEdit: boolean) => {
    setEditMode(isEdit);
  };

  const handleDeleteMetric = async (id: number) => {
    setOpenDeleteDialog(false);
    const r = await api.metric.del(id);
    if (r) {
      notifier.show({
        message: t("notifier:success.metric_delete"),
        theme: "success",
      });
      await handleRestartLoad();
    } else {
      notifier.show({
        message: t("notifier:error.something_wrong"),
        theme: "error",
      });
    }
  };

  const handleRestartLoad = async () => {
    await metrics.restart(true);
  };

  useEffect(() => {
    metrics.restart();
  }, []);

  return (
    <OrgchartDrawerTabMetricsView
      metricId={metricId}
      onSuccess={handleRestartLoad}
      deleteMetric={handleDeleteMetric}
      openDialog={openDialog}
      handleOpenDialog={handleOpenDialog}
      toggleEditMode={toggleEditMode}
      editMode={editMode}
      openDeleteDialog={openDeleteDialog}
      closeDeleteDialog={handleCloseDeleteDialog}
      isLoading={metrics.info.isLoading}
      metrics={metrics.items}
      isDone={metrics.info.isDone}
      onLoadNext={metrics.loadNext}
    />
  );
};
