import React from "react";
import { useTranslation } from "react-i18next";
import SubheaderText from "../../../elements/subheaderText/SubheaderText";
import { CompanyShortDto } from "../../../../api";
import { Button, Dialog, DialogActions, Input, Text } from "../../../uiKit";

export interface ICompanyAddDialogView {
  open: boolean;
  inviteCode: string;
  step: 1 | 2;
  companyData: CompanyShortDto | null;
  isLoading: boolean;

  setInviteCode(string: string): void;
  handleCheckButtonCLick(): void;
  handleAddButtonClick(): void;
  handleClose(): void;
}

function CompanyAddDialogView(props: ICompanyAddDialogView) {
  const { t } = useTranslation();

  return (
    <Dialog
      title={props.step == 1 ? t("ui:title.add_company") : t("ui:title.company_invitation")}
      open={props.open}
      onClose={() => props.handleClose()}
    >
      {props.step == 2 && (
        <Text className="mb-4">
          {props.companyData?.name && props.companyData?.name.length > 0
            ? t("text:add_company.text1", { companyName: props.companyData?.name })
            : t("text:add_company.text2")}
        </Text>
      )}

      <SubheaderText text={t("ui:subheader.invite_code")} />
      <Input
        name="code"
        placeholder={t("ui:placeholder.invite_code")}
        value={props.inviteCode}
        disabled={props.step == 2}
        style={{ textTransform: "uppercase" }}
        onInput={(e: any) => props.setInviteCode(e.target.value)}
      />

      <DialogActions>
        <Button
          disabled={props.isLoading}
          color={props.step == 1 ? "primary" : "error"}
          onClick={() => props.handleClose()}
          variant="text"
        >
          {props.step == 1 ? t("ui:button.close") : t("ui:button.decline")}
        </Button>
        <Button
          disabled={props.step == 1 ? props.inviteCode.length != 8 : false}
          loading={props.isLoading}
          onClick={props.step == 1 ? () => props.handleCheckButtonCLick() : () => props.handleAddButtonClick()}
          variant="filled"
          color={props.step == 1 ? "primary" : "success"}
        >
          {props.step == 1 ? t("ui:button.check_code") : t("ui:button.accept")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CompanyAddDialogView;
