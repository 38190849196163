import React from "react";
import "./RadioButton.scss";
import { RadioButtonProps } from "antd/es/radio/radioButton";
import { Radio } from "antd";
import clsx from "clsx";

interface IRadioButtonView extends RadioButtonProps {
  //
}

const { Button } = Radio;

export function RadioButtonView({ className, ...rest }: IRadioButtonView) {
  return <Button className={clsx("uiKit-radioButton", className ?? "")} {...rest} />;
}
