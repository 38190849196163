import React from "react";
import { Tabs, TabsProps } from "antd";
import "./Tabs.scss";
import clsx from "clsx";

interface ITabsView extends TabsProps {
  //
}

export function TabsView({ className, ...rest }: ITabsView) {
  return <Tabs className={clsx("uiKit-tabs", className ?? "")} {...rest} />;
}
