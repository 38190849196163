import React from "react";
import { AddLinks } from "react-link-text";
import { ILinksOptions, ITextLinkified } from "./textLinkified.interface";

// TODO: Replace with react-linkify-it

const URLregexp =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

export const options: ILinksOptions = {
  component: (url, text, key) => (
    <a target="_blank" key={key} href={url}>
      {text}
    </a>
  ),
  //regex : URLregexp,
};

export const TextLinkifiedView = ({ text }: ITextLinkified) => {
  return <AddLinks options={options}>{text}</AddLinks>;
};
