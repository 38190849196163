import { ApiControllerCrud } from "../helpers";
import { AxiosInstance } from "axios";
import { OrgchartDto } from "../models/OrgchartDto";
import { RolesWithOrgchartDto } from "../models/RolesWithOrgchartDto";
import { OrgchartItemDto } from "../models/OrgchartItemDto";

export class OrgchartController extends ApiControllerCrud<OrgchartDto, {}> {
  constructor(cl: AxiosInstance, v: string = "v1") {
    super(cl, v, "orgchart");
  }

  public async getTree(orgchartId: number): Promise<OrgchartItemDto[] | null> {
    return await this.process(this.get(orgchartId + "/tree"));
  }

  public async getTreeWithRoles(orgchartId: number): Promise<RolesWithOrgchartDto | null> {
    return await this.process(this.get(orgchartId + "/tree/with-roles"));
  }

  public async applyTemplate(orgchartId: number, templateKey: string): Promise<boolean | null> {
    return await this.process(
      this.post(orgchartId + "/template/" + templateKey),
      () => true,
      () => false
    );
  }

  public async del(orgchartId: number): Promise<boolean | null> {
    return await this.process(
      this.delete(orgchartId + ""),
      () => true,
      () => false
    );
  }
}
