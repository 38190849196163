import React from "react";
import { Input, InputProps } from "antd";
import "./Input.scss";
import clsx from "clsx";

interface IInputView extends InputProps {
  readOnly?: boolean;
}

export function InputView({ className, readOnly, ...rest }: IInputView) {
  return (
    <Input
      size="large"
      className={clsx("uiKit-input", className ?? "", {
        "no-interact": readOnly,
      })}
      {...rest}
    />
  );
}
