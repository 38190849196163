import React, { useContext } from "react";
import "./BoardRelationSwitch.scss";
import { IBoardRelationSwitch, RelationsTypes } from "./BoardRelationSwitch.interface";
import { RelationsKeys } from "../../constants/keys";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { SegmentedLabeledOption } from "antd/lib/segmented";
import { Segmented } from "../../../../../uiKit";
import { BoardFilterContext } from "../../../../../../contexts/communication/boardFilterContext";

const BoardRelationSwitchMemo = (props: IBoardRelationSwitch): JSX.Element => {
  const { t } = useTranslation();

  const { userRelation } = useContext(BoardFilterContext);

  const handleChangeRelation = (relationKye: RelationsTypes) => {
    props.onChangeUserRelation(relationKye);
    /*handleResetFiltersBoard();
    communicationsStore.generateAssociatedIssues(authStore.getCurrentBoardId as number, relationKye);*/
  };
  const handleGetRelations = (): SegmentedLabeledOption[] => {
    const isLoading = props.boardColumnsState.some((item) => item.isLoading);
    return [
      {
        value: RelationsKeys.My as RelationsTypes,
        disabled: isLoading,
        label: t("ui:button.relation_my"),
      },
      {
        value: RelationsKeys.Controlled as RelationsTypes,
        disabled: isLoading,
        label: t("ui:button.relation_control"),
      },
      {
        value: RelationsKeys.Participated as RelationsTypes,
        disabled: isLoading,
        label: t("ui:button.relation_participate"),
      },
    ];
  };

  return (
    // @ts-ignore
    <Segmented options={handleGetRelations()} value={userRelation} onChange={handleChangeRelation} />
  );
};

export const BoardRelationSwitch = React.memo(observer(BoardRelationSwitchMemo));
