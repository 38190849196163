import React, { useEffect, useState } from "react";
import VideoDialogView from "./VideoDialogView";
import { LocalStorageHelpers, useNotifier, useRootStore } from "../../../../hooks";
import { observer } from "mobx-react-lite";
import { api } from "../../../../services";
import { useTranslation } from "react-i18next";

interface IVideoDialog {
  videoId: string;
  name: string;
  open?: boolean;
  trainingKey: string;
  onClose?: () => void;
  onWatchedClickObject: Object;
  hidden?: boolean;
  autoPlay?: boolean;
  noShowTitle?: boolean;
}

function VideoDialog(props: IVideoDialog) {
  const { appStore, authStore } = useRootStore();

  const [abilityToSave, setAbilityToSave] = useState<boolean>(false);
  const [videoId, setVideoId] = useState<string | undefined>(undefined);
  const isPassed = !!authStore?.getInitialInfo?.identity?.educationHistory?.find(
    (item) => item.key === props.trainingKey
  );
  const notifier = useNotifier();
  const { t } = useTranslation();

  const onSaveProgress = () => {
    LocalStorageHelpers.set("videoDialog." + props.name, true);
    appStore.setVideosState(props.onWatchedClickObject);
    try {
      props.trainingKey &&
        api.education
          .saveEducationProgress(props.trainingKey)
          .then((res) => {
            if (res.message) {
              notifier.show({ message: t("notifier:error.something_wrong"), theme: "error", timeout: 7 });
              return;
            }
            notifier.show({ message: t("notifier:success.success_training"), theme: "success", timeout: 10 });
            authStore.refreshInitialInfo();
          })
          .catch(() => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error", timeout: 7 }))
          .finally(() => props.onClose && props.onClose());
    } catch (error) {
      notifier.show({ message: t("notifier:error.something_wrong"), theme: "error", timeout: 7 });
    }
  };

  const handleGetVideoId = async () => {
    const r = await api.education.getEducationItems({ key: props.trainingKey });
    if (r == null) return notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
    const mbVideoId = r.find((item) => item.key == props.trainingKey)?.videoId;
    console.log(mbVideoId);
    mbVideoId && setVideoId(mbVideoId);
  };

  const handleLaterClick = () => {
    LocalStorageHelpers.set("videoDialog." + props.name, true);
    appStore.setVideosState(props.onWatchedClickObject);
    props.onClose && props.onClose();
  };

  const onAllowToSaveProgress = () => {
    setAbilityToSave(true);
    // let iFrame = document.getElementById("yt-video-component");
    // iFrame.parentNode.replaceChild(iFrame.cloneNode(), iFrame);
    // let iframe = document.getElementById("yt-video-component");
    // @ts-ignore
    // iframe.src = iframe.src;
  };

  useEffect(() => {
    (props.open ? true : !isPassed) && props.trainingKey && authStore.isAuthorized && handleGetVideoId();
  }, [props.trainingKey, isPassed]);

  return props.open && videoId ? (
    <VideoDialogView
      removeTitle={props.noShowTitle}
      onAllowToSaveProgress={onAllowToSaveProgress}
      videoId={videoId}
      onSaveProgress={onSaveProgress}
      autoPlay={props.autoPlay}
      abilityToSave={abilityToSave}
      handleLaterClick={handleLaterClick}
      isPassed={isPassed}
    />
  ) : isPassed ? (
    <div />
  ) : // @ts-ignore
  appStore?.getVideosState[props.name] === true || props.hidden ? (
    <div />
  ) : !videoId && isPassed ? null : (
    <VideoDialogView
      removeTitle={props.noShowTitle}
      onAllowToSaveProgress={onAllowToSaveProgress}
      videoId={videoId!}
      autoPlay={props.autoPlay}
      onSaveProgress={onSaveProgress}
      abilityToSave={abilityToSave}
      handleLaterClick={handleLaterClick}
      isPassed={isPassed}
    />
  );
}

export default observer(VideoDialog);
