import React from "react";
import { InputPasswordView } from "./InputPasswordView";
import { PasswordProps } from "antd/lib/input";

interface IInputPassword extends PasswordProps {
  //
}

export function InputPassword(props: IInputPassword) {
  return <InputPasswordView {...props} />;
}
