import React from "react";
import { FiLink } from "@react-icons/all-files/fi/FiLink";
import { Button, Divider, Text } from "../../../../uiKit";

export function UiTabButton() {
  return (
    <div className="my-3">
      <div className="d-flex align-center">
        <FiLink className="mr-1" color="var(--color-primary-strong)" />
        <a target="_blank" href="https://ant.design/components/button/">
          Документация AntD
        </a>
      </div>
      <Divider className="my-3" />
      <div>
        <Text style={{ fontSize: "16px" }}>Кастомные пропсы</Text>
        <div>
          <div className="d-stack-column spacing-1">
            <span>
              <span style={{ fontWeight: "bold" }} children="allowTextWrap" /> - Перенос текста в кнопке
            </span>
            <span>
              <span style={{ fontWeight: "bold" }} children="theme" /> - Тема кнопки ("error" | "success" | "warning" |
              "info")
            </span>
            <span>
              <span style={{ fontWeight: "bold" }} children="variant" /> - Оформление кнопки ("filled" | "outlined" |
              "text" | "link" | "default")
            </span>
          </div>
        </div>
        <Divider className="my-3" />
        <div className="uiKitPage-elements-group">
          <Button>Default</Button>
          <Button style={{ maxWidth: "200px" }} allowTextWrap>
            With text wrap: Lorem ipsum dolor.
          </Button>
          <Button theme="error">Theme: Error</Button>
          <Button theme="success">Theme: Success</Button>
          <Button theme="warning">Theme: Warning</Button>
          <Button theme="info">Theme: Info</Button>
          <Button variant="filled">Variant: Filled</Button>
          <Button variant="outlined">Variant: Outlined</Button>
          <Button variant="text">Variant: Text</Button>
          <Button variant="link">Variant: Link</Button>
          <Button variant="default">Variant: Default</Button>
          <Button variant="outlined" theme="error">
            Combined: Outlined Error
          </Button>
        </div>
      </div>
    </div>
  );
}
