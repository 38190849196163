import React from "react";
import "./SortButton.scss";
import { ISortMenu } from "./SortButton.interface";
import { useTranslation } from "react-i18next";
import { Dropdown, Icon, IconButton } from "../../uiKit";
import { MdSort } from "@react-icons/all-files/md/MdSort";

export const SortButton = (props: ISortMenu) => {
  const { t } = useTranslation();

  return (
    <Dropdown
      items={props.sortMenuValues.map((i) => ({
        ...i,
        text: i.text + (i.key == props.value.id ? " ✓" : ""),
        // icon: i.key == props.value.key ? <Icon component={() => <FiCheck color={"var(--color-primary-base)"} />} /> : null
      }))}
      children={<IconButton children={<Icon component={() => <MdSort color={"var(--color-primary-base)"} />} />} />}
    />
    // <DropdownMenu items={props.sortMenuValues} trigger={["click"]} placement="bottomLeft">
    //   {/*<div className="communication_sort-menu__container">*/}
    //   {/*  <MdSort size="20.5px" className="filter-communications-icon" />*/}
    //   {/*  <Text className="communication_sort-menu__text">*/}
    //   {/*    {!!t(props.value.text).length ? t(props.value.text) : "сортировка"}*/}
    //   {/*  </Text>*/}
    //   {/*</div>*/}
    // </DropdownMenu>
  );
};
