import React from "react";
import "../../../../../components/modules/pages/orgchart/orgchartDrawerTabs/orgchartDrawerTabMetrics/OrgchartDrawerTabMetrics.scss";
import { Card, Icon, IconButton, Text } from "../../../../uiKit";
import { FiEdit2 } from "@react-icons/all-files/fi/FiEdit2";
import { AiOutlineDelete } from "@react-icons/all-files/ai/AiOutlineDelete";
import { IMetricView } from "./Metric.interface";

export const MetricView = (props: IMetricView) => {
  return (
    <Card variant="secondary" isShadowed={false} className="mt-2">
      <div className="d-stack-row align-center justify-space-between orgchart-drawer-tab_metrics-group_card">
        <div className="">
          <Text className="orgchart-drawer-tab_metrics-group_card_name" children={props.name} />
        </div>
        <div className="d-stack-row">
          <IconButton
            // TODO: Check background
            style={{ background: "none" }}
            type="default"
            icon={<Icon component={() => <FiEdit2 />} />}
            onClick={() => props.handleOpenDialog(true, props.id, true)}
          />
          <IconButton
            style={{ background: "none" }}
            type="default"
            icon={<Icon component={() => <AiOutlineDelete />} />}
            onClick={() => props.handleOpenDialog(true, props.id, false, true)}
          />
        </div>
      </div>
    </Card>
  );
};
