import React from "react";
import { IUserBlock } from "./userBlock.interface";
import { Avatar, Card, Icon, IconButton, Link, Text, Tooltip } from "../../../../uiKit";
import { useDateHelpers, useRootStore } from "../../../../../hooks";
import { useTranslation } from "react-i18next";
import { removeAllOccurrencesExceptFirstOne } from "../../../../../helpers/stringFunctions";
import { parsePhoneNumber } from "awesome-phonenumber";
import { FiCopy, FiExternalLink, FiPhoneOutgoing } from "react-icons/all";
import dayjs from "dayjs";

export const UserBlock = (props: IUserBlock) => {
  const dateHelpers = useDateHelpers();
  const { helperStore } = useRootStore();
  const { t } = useTranslation();

  return (
    <Card loading={props.user == null} variant="secondary" isShadowed={false}>
      <div className="d-flex flex-nowrap">
        <Avatar
          text={[props.user?.lastName, props.user?.firstName]}
          size={52}
          color={props.user?.color}
          src={props.user?.avatar?.image?.url}
        />
        <div className="d-stack-column ml-3">
          <Text
            size="24px"
            weight="bold"
            children={(props.user?.name ?? "").trim().length > 0 ? props.user?.name : props.user?.nameFallback}
          />
          {(props.user?.timeZoneFromUtc?.toString() ?? "").trim().length > 0 && (
            <Text>{`${t("parse:time_zone")}: ${
              helperStore.getTimezones.find((tz) => tz.value == props.user?.timeZoneFromUtc)?.city
            }, ${dateHelpers.formatDateString(
              dayjs(new Date())
                .add(new Date().getTimezoneOffset() / 60, "hour")
                .add(helperStore.getTimezones.find((tz) => tz.value == props.user?.timeZoneFromUtc)?.value ?? 0, "hour")
                .toISOString(),
              { month: "short" }
            )}`}</Text>
          )}
          {(props.user?.email ?? "").trim().length > 0 && (
            <Link href={`mailto:${props.user?.email}`} children={props.user?.email} />
          )}
          {(props.user?.phoneNumber ?? "").trim().length > 0 && (
            <div className="d-stack spacing-2 flex-nowrap align-center">
              <Link
                style={{ lineHeight: 1 }}
                href={
                  "tel:" +
                  parsePhoneNumber(
                    removeAllOccurrencesExceptFirstOne("+" + ("+" + props.user?.phoneNumber ?? ""), "\\+")
                  ).number?.international
                }
                children={
                  parsePhoneNumber(
                    removeAllOccurrencesExceptFirstOne("+" + ("+" + props.user?.phoneNumber ?? ""), "\\+")
                  ).number?.international
                }
              />
              <Tooltip title={t("parse:copy_number") as string}>
                <IconButton
                  onClick={() =>
                    props.onPhoneCopy(
                      parsePhoneNumber(
                        removeAllOccurrencesExceptFirstOne("+" + ("+" + props.user?.phoneNumber ?? ""), "\\+")
                      ).number?.international ?? ""
                    )
                  }
                  size="small"
                  icon={<Icon component={() => <FiCopy />} />}
                />
              </Tooltip>
              <Tooltip title={t("parse:call") as string}>
                <IconButton
                  onClick={() =>
                    props.onPhoneCall(
                      parsePhoneNumber(
                        removeAllOccurrencesExceptFirstOne("+" + ("+" + props.user?.phoneNumber ?? ""), "\\+")
                      ).number?.international ?? ""
                    )
                  }
                  size="small"
                  icon={<Icon component={() => <FiPhoneOutgoing />} />}
                />
              </Tooltip>
            </div>
          )}
          {(props.user?.contact?.name ?? "").trim().length > 0 && (
            <div className="d-stack spacing-2 flex-nowrap align-center">
              {/*<Icon component={() => <FiSend />} />*/}
              <Text children={t("ui:subheader.communication_type") + ": "} />
              <Tooltip title={props.user?.contact?.value}>
                <Link
                  href={props.getContactLinkByType(props.user?.contact ?? null)}
                  children={props.user?.contact?.name}
                />
              </Tooltip>
              <Tooltip title={t("parse:call") as string}>
                <IconButton
                  onClick={() => props.onCopyLogin(undefined, props.user?.contact?.value ?? "")}
                  size="small"
                  icon={<Icon component={() => <FiCopy />} />}
                />
              </Tooltip>
            </div>
          )}
          <div className="d-stack spacing-2 mt-1">
            <Icon component={() => <FiExternalLink />} />
            <Link
              style={{ lineHeight: 1 }}
              onClick={props.onOpenExecutorAdminsDialog}
              children={t("parse:executor_admins")}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};
