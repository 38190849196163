import React, { useEffect, useRef, useState } from "react";
import IssueCustomFieldsFormView from "./IssueCustomFieldsFormView";
import { observer } from "mobx-react-lite";
import { IssueCustomFieldDto } from "../../../../api";
import { useRootStore } from "../../../../hooks";
import { api } from "../../../../services";
import { IssueType } from "../../../../constants/issueTypeKeys";
import { toJS } from "mobx";

interface IIssueCustomFields {
  value?: IssueCustomFieldDto[];
  type?: IssueType;
  fieldsArray?: IssueCustomFieldDto[];
  elementsState?: { key: string; disabled?: boolean; initialValue?: any }[];
  showHintIcons?: boolean;
  onChange?: (value: IssueCustomFieldDto[]) => void;
}

function IssueCustomFieldsForm(props: IIssueCustomFields) {
  const { helperStore } = useRootStore();

  const [data, setData] = useState<IssueCustomFieldDto[]>([]);
  const dataCopy = useRef<IssueCustomFieldDto[]>([]);
  const setDataSync = (v: IssueCustomFieldDto[]) => {
    dataCopy.current = v;
    setData(v);
    if (v.length == 0) return;
    props.onChange && props.onChange(v);
  };

  const generateFieldsArray = async () => {
    let fArr: IssueCustomFieldDto[] = [];

    if ((props.fieldsArray ?? []).length > 0) {
      fArr = (props.fieldsArray ?? []).map((f) => ({ ...f, id: 0 }));
      helperStore.setIssueCustomField({ type: props.type ?? null, items: fArr });
    } else {
      if (toJS(helperStore.getIssueCustomField).find((f) => (props.type == null ? f : f.type == props.type)) == null) {
        const r = await api.helper.customField(props.type ?? null);
        if (r == null) return;
        fArr = r;
        helperStore.setIssueCustomField({ type: props.type ?? null, items: fArr });
      } else {
        fArr = toJS(helperStore.getIssueCustomField).find((f) => f.type == props.type)?.items ?? [];
      }
    }

    setDataSync(
      (fArr ?? []).map((v) => {
        const initVal = props.elementsState?.find((el) => el.key == v.key)?.initialValue ?? undefined;
        return {
          ...v,
          id: 0,
          valueBool: initVal != null ? initVal : props.value?.find((va) => va.key == v.key)?.valueBool ?? false,
        };
      })
    );

    handlePropsValueChange();
  };

  useEffect(() => {
    generateFieldsArray();
  }, []);

  useEffect(() => {
    generateFieldsArray();
  }, [props.type]);

  useEffect(() => {
    handlePropsValueChange();
  }, [props.value]);

  const handlePropsValueChange = () => {
    if (props.value == null) {
      return;
    }

    if (arraysEqual(props.value.sort(), dataCopy.current.sort())) {
      return;
    }

    props.value.forEach((val) => {
      handleChange(val.key as string, val.valueBool, val.id ?? null);
    });
  };

  function arraysEqual(a: Array<IssueCustomFieldDto>, b: Array<IssueCustomFieldDto>) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; ++i) {
      const elementOfSecondArray = b.find((x) => x.key == a[i].key);
      if (elementOfSecondArray == null) return false;
      if (a[i].valueBool != elementOfSecondArray.valueBool) return false;
      if (a[i].id != elementOfSecondArray.id) return false;
    }

    return true;
  }

  const handleChange = (key: string, value: any, id: number | null) => {
    let nData = dataCopy.current;
    nData = nData.map((f) =>
      f.key == key
        ? {
            ...f,
            id: id ?? 0,
            valueBool: value,
          }
        : f
    );
    setDataSync(nData);
  };

  return (
    <IssueCustomFieldsFormView
      data={data}
      handleChange={handleChange}
      elementsState={props.elementsState}
      showHintIcons={props.showHintIcons}
    />
  );
}

export default observer(IssueCustomFieldsForm);
