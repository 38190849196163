import React, { useRef, useState } from "react";
import DeadlineSelectView from "./DeadlineSelectView";
import { RecurringIssueDateDeadlineConfigUntilDto } from "../../../api";

interface IDeadlineSelect {
  value: {
    dateDeadlineAfterTime: string | null;
    dateDeadlineUntil: RecurringIssueDateDeadlineConfigUntilDto | null;
  };
  clearable?: boolean;

  onChange?: (value: {
    dateDeadlineAfterTime: string | undefined;
    dateDeadlineUntil: RecurringIssueDateDeadlineConfigUntilDto | undefined;
  }) => void;
}

const getDeadlineFromString = (deadline: string): { type: number; value: number } => {
  let d2r = {
    type: 1,
    value: 12,
  };
  if ((deadline.match(/\./g) || []).length != 2) {
    return d2r;
  }
  const [months, days, time] = deadline?.split(".");
  const [hours, minutes] = time?.split(":");

  if (Number(months) != 0) {
    d2r = {
      type: 3,
      value: Number(months),
    };
  }

  if (Number(days) != 0) {
    d2r = {
      type: 2,
      value: Number(days),
    };
  }

  if (Number(hours) != 0) {
    d2r = {
      type: 1,
      value: Number(hours),
    };
  }

  if (Number(minutes) != 0) {
    d2r = {
      type: 0,
      value: Number(minutes),
    };
  }
  return d2r;
};

const generateArray = (
  from: number,
  to: number,
  numberLength?: number | null,
  multipleOf?: number
): (number | string)[] => {
  let numbersArray: (string | number)[] = Array.from({ length: to - from + 1 }, (_, i) => i + from - 1);
  if (multipleOf != null) {
    numbersArray = numbersArray.filter((val) => Number(val) % multipleOf == 0);
  }
  if (numberLength != null) {
    numbersArray = numbersArray.map((val) => ("0000" + (Number(val) + from)).slice(-numberLength));
  }
  return numbersArray;
};

function DeadlineSelect(props: IDeadlineSelect) {
  const [deadlineAfterValues, setDeadlineAfterValues] = useState<(number | string)[]>(generateArray(2, 24));
  const [deadlineAfterType, setDeadlineAfterType] = useState(
    props.value.dateDeadlineAfterTime != null && props.value.dateDeadlineAfterTime.length > 0
      ? getDeadlineFromString(props.value.dateDeadlineAfterTime).type
      : 1
  );
  const [deadlineAfterValue, setDeadlineAfterValue] = useState(
    props.value.dateDeadlineAfterTime != null && props.value.dateDeadlineAfterTime.length > 0
      ? getDeadlineFromString(props.value.dateDeadlineAfterTime).value
      : 12
  );

  const getInitialDeadlineType = () => {
    if (props.value.dateDeadlineAfterTime == null && props.value.dateDeadlineUntil == null) {
      return null;
    }
    return props.value.dateDeadlineUntil != null ? "until_day" : "after_time";
  };
  const [deadlineType, setDeadlineType] = useState<any>(getInitialDeadlineType());

  const deadlineAfterTypeCopy = useRef(
    props.value.dateDeadlineAfterTime != null && props.value.dateDeadlineAfterTime.length > 0
      ? getDeadlineFromString(props.value.dateDeadlineAfterTime).type
      : 1
  );
  const deadlineAfterValueCopy = useRef(
    props.value.dateDeadlineAfterTime != null && props.value.dateDeadlineAfterTime.length > 0
      ? getDeadlineFromString(props.value.dateDeadlineAfterTime).value
      : 12
  );

  const setDeadlineAfterTypeSync = (v: number) => {
    setDeadlineAfterType(v);
    deadlineAfterTypeCopy.current = v;
  };

  const setDeadlineAfterValueSync = (v: number) => {
    setDeadlineAfterValue(v);
    deadlineAfterValueCopy.current = v;
  };

  const onTypeChange = (type: string | null) => {
    if (type == null) {
      props.onChange &&
        props.onChange({
          dateDeadlineAfterTime: undefined,
          dateDeadlineUntil: undefined,
        });
      setDeadlineType(null);
    }
    if (type == "after_time") {
      setDeadlineAfterValueSync(12);
      setDeadlineAfterTypeSync(1);
      setDeadlineType("after_time");
      props.onChange && props.onChange({ dateDeadlineAfterTime: "0.0.12:00", dateDeadlineUntil: undefined });
    }

    if (type == "until_day") {
      setDeadlineType("until_day");
      props.onChange &&
        props.onChange({
          dateDeadlineAfterTime: undefined,
          dateDeadlineUntil: { timeDeadlineAt: "12:00", untilDayOfWeek: 1 },
        });
    }
  };

  const onDayChange = (value: number) => {
    if (props.value.dateDeadlineUntil != null) {
      props.onChange &&
        props.onChange({
          dateDeadlineAfterTime: undefined,
          dateDeadlineUntil: { ...props.value.dateDeadlineUntil, untilDayOfWeek: value },
        });
    }
  };

  const onTimeChange = (value: string) => {
    if (props.value.dateDeadlineUntil != null) {
      props.onChange &&
        props.onChange({
          dateDeadlineAfterTime: undefined,
          dateDeadlineUntil: { ...props.value.dateDeadlineUntil, timeDeadlineAt: value },
        });
    }
  };

  const generateDeadlineString = (value: number, type: number): string => {
    let str2return = "";

    switch (type) {
      case 0: // Minutes
        str2return = "0.0.00:" + ("0" + value).slice(-2);
        break;
      case 1: // Hours
        str2return = "0.0." + ("0" + value).slice(-2) + ":00";
        break;
      case 2: // Days
        str2return = "0." + value + ".00:00";
        break;
      case 3: // Months
        str2return = value + ".0.00:00";
        break;
    }

    return str2return;
  };
  //
  const onDeadlineAfterTypeChange = (type: number) => {
    switch (type) {
      case 0: // Minutes
        setDeadlineAfterValues(generateArray(2, 60));
        break;
      case 1: // Hours
        setDeadlineAfterValues(generateArray(2, 24));
        break;
      case 2: // Days
        setDeadlineAfterValues(generateArray(2, 31));
        break;
      case 3: // Months
        setDeadlineAfterValues(generateArray(2, 13));
        break;
    }
    setDeadlineAfterTypeSync(type);
    if (props.value.dateDeadlineAfterTime != null) {
      props.onChange &&
        props.onChange({
          dateDeadlineUntil: undefined,
          dateDeadlineAfterTime: generateDeadlineString(deadlineAfterValueCopy.current, type),
        });
    }
  };

  const onDeadlineAfterValueChange = (value: number) => {
    setDeadlineAfterValueSync(value);
    if (props.value.dateDeadlineAfterTime != null) {
      props.onChange &&
        props.onChange({
          dateDeadlineUntil: undefined,
          dateDeadlineAfterTime: generateDeadlineString(value, deadlineAfterTypeCopy.current),
        });
    }
  };

  const onClearButtonCLick = () => {
    onTypeChange(null);
  };

  return (
    <DeadlineSelectView
      value={props.value}
      clearable={props.clearable}
      onTypeChange={onTypeChange}
      onDayChange={onDayChange}
      onTimeChange={onTimeChange}
      deadlineAfterType={deadlineAfterType}
      deadlineAfterValues={deadlineAfterValues}
      deadlineAfterValue={deadlineAfterValue}
      onDeadlineAfterValueChange={onDeadlineAfterValueChange}
      onDeadlineAfterTypeChange={onDeadlineAfterTypeChange}
      deadlineType={deadlineType}
      onClearButtonCLick={onClearButtonCLick}
    />
  );
}

export default DeadlineSelect;
