import React from "react";
import "./ConnectionChecker.scss";

interface IConnectionCheckerView {
  //
}

export function ConnectionCheckerView(props: IConnectionCheckerView) {
  return null;
}
