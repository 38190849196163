import React from "react";
import { useTranslation } from "react-i18next";
import { useDateHelpers } from "../../../../hooks";
import { User2RoleDto, UserScheduleSubstituteDto } from "../../../../api";
import SubheaderText from "../../../elements/subheaderText/SubheaderText";
import UserScheduleSubstituteConfigContainer from "./components/UserScheduleSubstituteConfigContainer";
import "./ScheduleDaySetupDialog.scss";
import { UserInfo } from "../../../elements/userInfo/UserInfo";
import { Alert, Button, Checkbox, DatePicker, Dialog, DialogActions, Text, TimePickerRange } from "../../../uiKit";
import dayjs from "dayjs";

interface IScheduleDaySetupDialogView {
  open: boolean;
  type: "fullDay" | "weekend" | "vacation" | "sick" | null;
  time: any;
  user: User2RoleDto | null;
  dateFrom: Date | null;
  dateTo: Date | null;
  selectAllFunctions: boolean;
  date: any;
  substituteUserId: number | null;

  setDate: any;
  setSelectAllFunctions: (val: boolean) => void;
  setSubstituteUserId: (userId: number | null) => void;

  onSubstituteConfigChange: (v: UserScheduleSubstituteDto[]) => void;

  setTime: any;
  onApply: () => void;
  onClose: () => void;
}

const getDialogNameByType = (t: any, type: "fullDay" | "weekend" | "vacation" | "sick" | null) => {
  switch (type) {
    case "fullDay":
      return t("ui:title.mark_working_day");
      break;
    case "weekend":
      return t("ui:title.mark_weekend");
      break;
    case "vacation":
      return t("ui:title.mark_vacation");
      break;
    case "sick":
      return t("ui:title.mark_medical");
      break;
    case null:
      return "";
      break;
  }
};

function getDateByString(hhmmssString: string) {
  const [h, m, s] = hhmmssString.split(":");
  const d = new Date();
  let dateUTC = Date.UTC(
    d.getUTCFullYear(),
    d.getUTCMonth(),
    d.getUTCDate(),
    Number(h) + new Date().getTimezoneOffset() / 60,
    Number(m),
    Number(s)
  );
  return dayjs(dateUTC);
}

let disabledMinutes: number[] = [];
for (let i = 0; i < 60; i++) {
  if (i % 10 != 0 && i % 10 != 5) {
    disabledMinutes = [...disabledMinutes, i];
  }
}

function ScheduleDaySetupDialogView(props: IScheduleDaySetupDialogView) {
  const { t, i18n } = useTranslation();
  const dateHelpers = useDateHelpers();

  console.log(props.date);

  return (
    <Dialog title={getDialogNameByType(t, props.type)} open={props.open} destroyOnClose onClose={() => props.onClose()}>
      <div className="mb-3 d-flex align-center">
        <Text size="14px" className="mr-2">
          {t("ui:subheader.employee")}:
        </Text>
        <UserInfo user={props.user?.user} />
      </div>
      <SubheaderText text={t("ui:text.period")} />
      <div className="d-stack justify-space-between spacing-2 mb-3">
        {/*<DateRa*/}
        <DatePicker
          style={{ width: "48%" }}
          value={props.date.start != null ? dayjs(props.date.start) : null}
          onChange={(date) =>
            props.setDate({
              ...props.date,
              start: date == null ? new Date() : date?.toDate(),
            })
          }
          disablePast
        />
        <DatePicker
          value={props.date.end != null ? dayjs(props.date.end) : null}
          style={{ width: "48%" }}
          // showTime={false}
          onChange={(date) =>
            props.setDate({
              ...props.date,
              end: date == null ? new Date() : date?.toDate(),
            })
          }
          disablePast
        />
      </div>
      {props.type == "fullDay" && (
        <>
          <SubheaderText text={t("ui:text.beginning_and_end_of_shift")} />
          <TimePickerRange
            value={[getDateByString(props.time.start), getDateByString(props.time.end)]}
            onChange={(v) =>
              props.setTime({
                ...props.time,
                start: v?.[0]?.format("HH:mm:ss") ?? null,
                end: v?.[1]?.format("HH:mm:ss") ?? null,
              })
            }
            minuteStep={5}
            hideDisabledOptions
            className="full-width"
            allowClear={false}
            allowEmpty={[false, false]}
          />
        </>
      )}

      {(props.type == "vacation" || props.type == "sick") && (
        <UserScheduleSubstituteConfigContainer
          isMultiple={props.selectAllFunctions}
          currentUser2Role={props.user!}
          onChange={(v) => props.onSubstituteConfigChange(v)}
        />
      )}
      <Checkbox
        className="mt-2"
        checked={props.selectAllFunctions}
        onChange={(event) => props.setSelectAllFunctions(event.target.checked)}
      >
        {t("ui:text.apply_to_all_functions")}
      </Checkbox>
      <div className="d-stack-column spacing-1 mb-3">
        {props.time.start >= props.time.end && (
          <Alert type="error" className="mt-2" message={t("ui:text.start_day_exceeds_end")} />
        )}
        {props.date.start > props.date.end && (
          <Alert type="error" className="mt-2" message={t("ui:text.start_period_exceeds_end")} />
        )}
      </div>

      <DialogActions>
        <Button variant="text" onClick={() => props.onClose()}>
          {t("ui:button.cancel")}
        </Button>
        <Button
          disabled={props.time.start >= props.time.end || props.date.start > props.date.end}
          // disabled={props.substituteUserId == -1 && props.type != "partTime"}
          onClick={() => props.onApply()}
          variant="filled"
        >
          {t("ui:button.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ScheduleDaySetupDialogView;
