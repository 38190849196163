import { HubConnection, HubConnectionBuilder, HubConnectionState } from "@microsoft/signalr";
import { useRootStore } from "../../hooks";

export let issueHubConnection: HubConnection; // TODO: сюда инитить коннекшн

export function initIssueHubConnection(accessToken: string) {
  const newConnection = new HubConnectionBuilder()
    .withUrl(process.env.REACT_APP_API_BASE_URL + "api/v1/hub/issue", { accessTokenFactory: () => accessToken })
    .withAutomaticReconnect([0, 1000, 2000, 4000, 8000])
    .build();
  issueHubConnection = newConnection;
}

export function setCurrentBoardId(id: number) {
  issueHubConnection?.send("SetCurrentBoardId", id);
}

export function checkDisconnection(): boolean {
  return issueHubConnection?.state === HubConnectionState.Disconnected;
}

export const useIssueHub = () => {
  const { authStore, appStore } = useRootStore();

  async function start() {
    if (issueHubConnection != null && issueHubConnection?.state === HubConnectionState.Connected) return;

    const token = authStore.getAccessToken;
    if (token == null) throw new Error("AccessToken should not be null");
    initIssueHubConnection(token);
    await issueHubConnection?.start();
  }

  function stop() {
    issueHubConnection?.stop();
  }

  return {
    connection: issueHubConnection,
    checkDisconnection,
    start,
    stop,
    messages: {
      setCurrentBoardId,
    },
  };
};
