import { TextEditorView } from "./TextEditorView";
import TextEditorNodes from "./nodes/TextEditorNodes";
import TextEditorTheme from "./themes/TextEditorTheme";
import { InitialConfigType, LexicalComposer } from "@lexical/react/LexicalComposer";
import { TableContext } from "./plugins/TablePlugin/TablePlugin";
import { ITextEditor } from "./TextEditor.interface";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { emptyEditorJSON } from "../../../utils/textEditor";
import { SharedHistoryContext } from "./context/SharedHistoryContext";

export const TextEditor = memo((props: ITextEditor) => {
  // const { appStore } = useRootStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);

  // const locales = useApi(() => api.localization.getLocales(appStore.getAppLocale ?? "ru", "ui"));

  function onError(error: Error) {
    console.error(error);
  }

  const handleIsLoadingChange = useCallback((value: boolean) => {
    setIsLoading(value);
  }, []);

  const initialConfig: InitialConfigType = useMemo(() => {
    return {
      editorState: props.value,
      editable: !props.readOnly,
      namespace: "text-editor",
      theme: TextEditorTheme,
      onError,
      nodes: [...TextEditorNodes],
    };
  }, [props.readOnly, props.value]);

  useEffect(() => {
    if (props.anchorKey != "" && isFirstRender && props.value != emptyEditorJSON) {
      setTimeout(() => {
        const element = document.getElementById(props.anchorKey as string);
        element && element.scrollIntoView({ behavior: "smooth" });
        setIsFirstRender(false);
      }, 1500);
    }
  }, [isFirstRender, props.anchorKey, props.value]);

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <SharedHistoryContext>
        <TableContext>
          <TextEditorView
            onChange={props.onChange}
            value={props.value}
            placeholder={props.placeholder}
            height={props.height}
            isLoading={isLoading}
            isLoadingChange={handleIsLoadingChange}
            isHideToolbar={props.isHideToolbar}
          />
        </TableContext>
      </SharedHistoryContext>
    </LexicalComposer>
  );
});
