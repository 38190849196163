import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions, Input } from "../../../uiKit";
import { IDashboardCreateEditDialogView } from "./DashboardCreateEditDialog.interface";
import { Form } from "antd";
import SubheaderText from "../../../elements/subheaderText/SubheaderText";
import ExecutorSelect from "../../../elements/executorSelect/ExecutorSelect";
import { UserSelectionRule } from "../../../../api/types/userSelectionRule";
import { MetricToDashboardDialog } from "../metricToDashboardDialog/MetricToDashboardDialog";
import { MetricToDashboardDialogCard } from "../../pages/dashboards/components/MetricToDashboardDialogCard/MetricToDashboardDialogCard";
import { ColumnsSelect } from "../../pages/dashboards/components/ColumnsSelect/ColumnsSelect";
import { closestCorners, DndContext, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { restrictToParentElement, restrictToVerticalAxis } from "@dnd-kit/modifiers";

export const DashboardCreateEditDialogView = (props: IDashboardCreateEditDialogView) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const sensors = useSensors(
    useSensor(PointerSensor)
    // useSensor(KeyboardSensor, {
    //   coordinateGetter: sortableKeyboardCoordinates,
    // })
  );
  const handleSubmit = () => form.submit();

  const handleCreate = () => {
    props.createDashboard();
  };

  const handleEdit = () => {
    props.editDashboard();
  };

  // const converseDashboardConfigToSelectionRule = (
  //   value: DashboardUserSelectionRuleDto & { idx?: number }
  // ): UserSelectionRule & { idx?: number } => {
  //   const newValue: UserSelectionRule & { idx?: number } = {
  //     id: value?.id,
  //     roleId: value?.roleId,
  //     rolePositionId: value?.rolePositionId ?? undefined,
  //     rolePositionType: value?.rolePositionType as any,
  //     orgchartId: value?.orgchartId ?? undefined,
  //     isExcluded: value?.isExcluded as boolean,
  //     idx: value?.idx,
  //   };
  //   return newValue;
  // };
  //
  // const converseSelectionRuleToDashboardConfig = (
  //   value: UserSelectionRule & { idx?: number }
  // ): DashboardUserSelectionRuleDto & { idx?: number } => {
  //   const newValue: RecurringIssueExecutorConfigDto & { idx?: number } = {
  //     id: value?.id,
  //     roleId: value?.roleId,
  //     rolePositionId: value?.rolePositionId ?? undefined,
  //     rolePositionType: value?.rolePositionType as any,
  //     isExcluded: value?.isExcluded as boolean,
  //     orgchartId: value.orgchartId,
  //     idx: value?.idx,
  //   };
  //   return newValue;
  // };

  useEffect(() => {
    form.setFieldsValue({
      name: props.dashboard?.name,
    });
  }, [form, props.dashboard]);

  return (
    <>
      <MetricToDashboardDialog
        open={props.openMetricToDashboardDialog}
        toggleDialog={props.onOpenMetricToDashboardDialog}
        onSuccess={props.onChangeDashboard}
        dashboard={props.dashboard}
      />
      <Dialog
        destroyOnClose={true}
        title={props.editMode ? t("ui:title.edit_dashboard") : t("ui:title.create_dashboard")}
        open={props.open}
        onClose={props.onClose}
        // scrollStrategy="dialogBody"
      >
        <Form form={form} onFinish={props.editMode ? handleEdit : handleCreate} autoComplete="off">
          <SubheaderText text={t("ui:subheader.dashboard_name")} />
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: t("validation:field_required"),
              },
            ]}
          >
            <Input
              value={props.dashboard?.name ?? ""}
              onChange={(event) =>
                props.onChangeDashboard({
                  ...props.dashboard,
                  name: event.currentTarget.value,
                })
              }
              maxLength={30}
              placeholder={t("ui:placeholder.issue_name")}
            />
          </Form.Item>
          <SubheaderText text={t("ui:subheader.issue_description")} />
          <Form.Item
            rules={[
              {
                required: true,
                message: t("validation:field_required"),
              },
            ]}
          >
            <Input
              value={props.dashboard?.description!}
              onChange={(event) =>
                props.onChangeDashboard({ ...props.dashboard, description: event.currentTarget.value })
              }
              maxLength={30}
              placeholder={t("ui:placeholder.issue_description")}
            />
          </Form.Item>
          <SubheaderText text={t("ui:subheader.dashboard_column_count")} />
          <Form.Item
            rules={[
              {
                required: true,
                message: t("validation:field_required"),
              },
            ]}
          >
            <ColumnsSelect
              value={props.dashboard?.columnCount}
              onChange={(value) => props.onChangeDashboard({ ...props.dashboard, columnCount: value })}
              size="large"
            />
          </Form.Item>
          <SubheaderText text={t("ui:subheader.select_metrics")} />
          {/*<Form.Item>*/}
          {/*<AutocompleteAsync*/}
          {/*  type="metric"*/}
          {/*  mode="multiple"*/}
          {/*  // value={props.dashboard.metricSource2Users}*/}
          {/*  onChange={*/}
          {/*    (metrics: MetricSource2UserDto[]) => {}*/}
          {/*    // props.onChangeDashboard({ ...props.dashboard, metricSource2Users: metrics })*/}
          {/*  }*/}
          {/*/>*/}
          <div className="br-md metric-to-dashboard-dialog-card__wrapper">
            <DndContext
              sensors={sensors}
              collisionDetection={closestCorners}
              modifiers={[restrictToVerticalAxis, restrictToParentElement]}
              onDragEnd={props.handleMetricDragEnd}
            >
              {/*<React.Fragment key={m.metricId! * index}>*/}
              {/*  {index > 0 ? <Divider style={{ opacity: "0.5" }} /> : null}*/}
              {/*@ts-ignore - id*/}
              <SortableContext
                items={(props.dashboard?.metrics ?? []).map((m) => m.order) as number[]}
                strategy={verticalListSortingStrategy}
              >
                {props.dashboard?.metrics
                  // ?.sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
                  // ?.sort((a, b) => a.metric?.name?.localeCompare(b.metric?.name ?? "") as number)
                  ?.map((m, index) => (
                    <MetricToDashboardDialogCard
                      key={m.metricId! + "/" + m.order}
                      metric={m}
                      metrics={props.metrics ?? []}
                      deleteMetric={props.onDeleteMetric}
                    />
                  ))}
              </SortableContext>
            </DndContext>
          </div>
          <Button
            variant="default"
            className={`${props.dashboard?.metrics?.length ? "mt-2 mb-2" : "mb-2"}`}
            onClick={() => props.onOpenMetricToDashboardDialog(true)}
          >
            {t("ui:button.add")}
          </Button>
          {/*</Form.Item>*/}
          <Form.Item>
            {props.dashboard?.userSelectionRules && (
              <ExecutorSelect
                isWhoInNaming
                value={props.dashboard?.userSelectionRules as (UserSelectionRule & { idx?: number })[]}
                onChange={(role) =>
                  props.onChangeDashboard({
                    ...props.dashboard,
                    userSelectionRules: role,
                  })
                }
              />
            )}
          </Form.Item>
        </Form>
        <DialogActions>
          {props.editMode ? (
            <Button
              onClick={() => {
                props.onOpenDeleteDashboardDialogChange(true);
              }}
              variant="default"
            >
              {t("ui:button.delete_dashboard")}
            </Button>
          ) : (
            <Button onClick={props.onClose} variant="default">
              {t("ui:button.cancel")}
            </Button>
          )}
          <Button onClick={handleSubmit} variant="filled">
            {t("ui:button.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
