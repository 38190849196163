import React from "react";
import { IUserReactionCard } from "../interfaces/Reactions.interface";
import { UsersTooltip } from "./UsersTooltip";

export const UserReactionCard: React.FC<IUserReactionCard> = (props) => {
  return (
    <UsersTooltip users={props.reactionChip.userNames}>
      <div
        style={{
          backgroundColor: props.disabled ? "var(--color-layout-fill-quaternary)" : "var(--color-layout-fill-tertiary)",
          cursor: props.disabled ? "not-allowed" : "default",
        }}
        onClick={() => !props.disabled && props.onClickByReaction(props.reactionChip.emoji)}
        className="d-stack-row justify-space-between spacing-2 align-center user-reaction-chip ma-1"
      >
        <span className="reaction-symbol">{props.reactionChip.emoji}</span>
        <span>{props.reactionChip.usersCount}</span>
      </div>
    </UsersTooltip>
  );
};
