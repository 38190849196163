import React, { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { AudioRecorderView } from "./AudioRecorderView";
import { useAudioRecorder } from "../../../hooks";
// import { useAudioRecorder } from "../../../hooks";
// import { formatTimeToHHMMSSMS } from "../../../helpers/formatFunctions";

export type TAudioRecorderState = "paused" | "recording" | null;

interface IAudioRecorder {
  isVisibleWhenNotRecording?: boolean;

  onAudioReady?: (blob: Blob | null) => void;
  onStateChange?: (state: TAudioRecorderState) => void;
}

export interface IAudioRecorderRef {
  recordingStart: () => void;
  emitAudioSave: () => void;
  recordingReset: () => void;
  recordingStop: () => void;
}

export interface IAudioRecorderState {
  isRecording: boolean;
  isPaused: boolean;
  // recordMode: "touch" | "click" | null;
}

export const AudioRecorder = forwardRef((props: IAudioRecorder, ref: ForwardedRef<IAudioRecorderRef>) => {
  const {
    startRecording: recordingStart,
    stopRecording: recordingStop,
    pauseResumeRecording: recordingPauseResume,
    resetRecording: recordingReset,
    recordingBlob,
    isRecording,
    isPaused,
    recordingTime,
  } = useAudioRecorder();

  // @ts-ignore
  let audioContext = new (window.AudioContext || window.webkitAudioContext)();
  const [audioBuffer, setAudioBuffer] = useState<AudioBuffer | null>(null);

  const handleRecordStart = (viaClick: boolean = true) => {
    if (!isRecording) {
      recordingStart();
      return;
    }
    recordingPauseResume();
  };

  const handleRecordingPauseResume = () => {
    //
  };

  const handleRecordStop = (viaClick: boolean = true) => {
    recordingStop();
    // recordingPauseResume();
    // console.log(recordingBlob, "blob");
  };

  const handleRecordReset = () => {
    recordingStop();
    recordingReset();
  };

  const handleRecordSave = () => {
    // if (recordingBlob == null) {
    //   console.log("err, empty blob");
    //   return;
    // }
  };

  const getRecordAudioData = (blob: Blob | undefined | null) => {
    if (blob == null) return undefined;
    const url = URL.createObjectURL(blob);
    return url;
  };

  useImperativeHandle(ref, () => ({
    recordingStart: handleRecordStart,
    emitAudioSave: () => props.onAudioReady && props.onAudioReady(recordingBlob ?? null),
    recordingReset: handleRecordReset,
    recordingStop: handleRecordStop,
    // isRecording: false,
    // isPaused: false,
  }));

  useEffect(() => {
    let _state: TAudioRecorderState = null;
    if (!isRecording && !isPaused) _state = null;
    if (isRecording && !isPaused) _state = "recording";
    if (isRecording && isPaused) _state = "paused";
    props.onStateChange && props.onStateChange(_state);
    // props.onStateChange && props.onStateChange(isRecording ? "recording" : isPaused ? "paused" : null);
  }, [isRecording, isPaused]);

  useEffect(() => {
    // console.log(recordingBlob);
  }, [recordingBlob]);

  const convertBlobToAudioBuffer = async (blob: Blob | null): Promise<AudioBuffer | null> => {
    // @ts-ignore
    let audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      const arrayBuffer = fileReader.result as ArrayBuffer;
      // Convert array buffer into audio buffer
      audioContext.decodeAudioData(arrayBuffer, (audioBuffer) => {
        // Do something with audioBuffer
        return audioBuffer;
      });
    };
    // @ts-ignore
    return await blob?.arrayBuffer();
    // fileReader.readAsArrayBuffer(blob)
    // return null;
  };

  async function blobToAudioBuffer(audioContext: AudioContext, blob: Blob | null) {
    if (blob == null) return null;
    const arrayBuffer = await blob.arrayBuffer();
    return await audioContext.decodeAudioData(arrayBuffer);
  }

  useEffect(() => {
    const generateAudioBuffer = async () => {
      if (recordingBlob == null) {
        setAudioBuffer(null);
        return;
      }
      let _b = await blobToAudioBuffer(audioContext, recordingBlob);
      setAudioBuffer(_b);
    };
    generateAudioBuffer();
  }, [recordingBlob]);

  useEffect(() => {
    // if (recordingBlob == null) return;
    if (isPaused && props.onAudioReady) {
      console.log("saveiing");
      props.onAudioReady(recordingBlob ?? null);
    }
    // props.onAudioReady && props.onAudioReady(new File([recordingBlob], "test.mp3"));
  }, [recordingBlob, isPaused]);

  return (
    <AudioRecorderView
      state={{ isRecording: isRecording, isPaused: isPaused }}
      isVisibleWhenNotRecording={props.isVisibleWhenNotRecording}
      recordTimeFormatted={recordingTime}
      // recordTimeFormatted={formatTimeToHHMMSSMS(recordingTime, { msPerSecond: 10, milliseconds: true })}
      recordAudioBuffer={audioBuffer}
      // recordAudioBuffer={convertBlobToAudioBuffer(recordingBlob ?? null)}
      // recordAudioData={getRecordAudioData(recordingBlob)}
      onRecordStart={handleRecordStart}
      onRecordStop={handleRecordStop}
      onRecordReset={handleRecordReset}
      onRecordSave={handleRecordSave}
    />
  );
});
