import React, { useContext, useEffect, useMemo, useState } from "react";
import "./BoardCollapse.scss";
import { IBoardCollapse } from "./BoardCollapse.interface";
import { useNotifier, useRootStore } from "../../../../../../hooks";
import { BoardCollapseView } from "./BoardCollapseView";
import { BoardFilterContext } from "../../../../../../contexts/communication/boardFilterContext";
import { api } from "../../../../../../services";
import { baseBoardStatusKeys } from "../../constants/keys";
import { BoardInfo } from "../../../../../../api";
import { useTranslation } from "react-i18next";
import { regeneratorFilters } from "../../common/regeneratorFilters";
import { useIssueHub } from "../../../../../../signalr/hubs/issueHub";

export const BoardCollapse = (props: IBoardCollapse) => {
  const { authStore, orgchartStore } = useRootStore();
  const { t } = useTranslation();
  const notifier = useNotifier();
  const context = useContext(BoardFilterContext);

  const { connection, checkDisconnection } = useIssueHub();

  const [openBoards, setOpenBoards] = useState<number[]>([]);

  const [boardInfo, setBoardInfo] = useState<BoardInfo[] | null>(null);

  const filterGenerator = regeneratorFilters({ ...context });

  const handlerApiError = () => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });

  const boardsByOrgcharts = useMemo(() => authStore.getInitialInfo?.orgcharts, [authStore.getInitialInfo?.orgcharts]);

  const handleChangeOpenBoards = (openIds: number[]) => setOpenBoards(openIds);

  useEffect(() => {
    if (orgchartStore.getCurrentOrgchartId) {
      if (openBoards.includes(orgchartStore.getCurrentOrgchartId)) return;
      else setOpenBoards((prev) => [...prev, orgchartStore.getCurrentOrgchartId!]);
    }
  }, [orgchartStore.getCurrentOrgchartId]);

  const handleLoadCounters = async () => {
    //communicationsStore.resetOrgchartBoards();
    const commonFilter = filterGenerator.getReqFilters();
    const sortFilter = filterGenerator.getReqSortFilters();
    const filters = {
      boardId: context.boardId,
      boardStatusKey: baseBoardStatusKeys.pending,
      isActive: true,
      isArchived: false,
      pageSize: 1,
      ...(context.userRelation && { userRelation: context.userRelation }),
      ...commonFilter,
      ...sortFilter,
    };
    setBoardInfo(await api.issue.boardInfo(filters));
  };

  useEffect(() => {
    context.boardId && handleLoadCounters();
    //if (orgchartStore.getOrgchartsList.length) handleLoadShortBoardData();
  }, [
    orgchartStore.getOrgchartsList.length,
    context.userRelation,
    context.sortValue,
    context.boardId,
    context.senderId,
    context.hasViolation,
    context.executorId,
    context.flowType,
  ]);

  useEffect(() => {
    if (!connection) return;
    connection.on("IssueUpdate", handleLoadCounters);
  }, [connection]);

  useEffect(() => {
    checkDisconnection() &&
      notifier.show({
        message: t("notifier:error.real_time_disconnect"),
        theme: "error",
        timeout: 90000000,
      });
  }, [connection?.state]);

  return (
    <BoardCollapseView
      boardsByOrgcharts={boardsByOrgcharts}
      board={context.board}
      onChangeBoardColumnsState={props.onChangeBoardColumnsState}
      openBoardIds={openBoards}
      onChangeOpenBoards={handleChangeOpenBoards}
      //autocompleteIssues={{ inWorkIssues, pedingIssues }}
      boardInfo={boardInfo}
    />
  );
};
