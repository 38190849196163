import React, { useEffect, useState } from "react";
import CompanyEditFormScheduleFormView from "./CompanyEditFormScheduleFormView";
import { CompanyScheduleDto } from "../../../../../api";

export type ScheduleFormTypes = {
  scheduleIsSameOnWeekdays: boolean;
  schedule: CompanyScheduleDto[];
};

export interface ICompanyEditFormScheduleForm {
  scheduleData: ScheduleFormTypes;
  readOnly: boolean;
  dayStart?: 0 | 1;
  handleScheduleSave(data: CompanyScheduleDto[]): Promise<ScheduleFormTypes | null>;
}

function CompanyEditFormScheduleForm(props: ICompanyEditFormScheduleForm) {
  const [scheduleData, setScheduleData] = useState<ScheduleFormTypes>(props.scheduleData);
  const [sameDaysData, setSameDaysData] = useState({
    timeDayStart: "09:00:00",
    timeDayEnd: "18:00:00",
  });

  useEffect(() => {
    setScheduleData(props.scheduleData);
  }, [props.scheduleData]);

  useEffect(() => {
    if (scheduleData.scheduleIsSameOnWeekdays) {
      // const firstActiveDay =
      handleSetSameDaysData({
        timeDayStart: scheduleData.schedule?.find((day) => day.isActive)?.timeDayStart ?? "09:00:00",
        timeDayEnd: scheduleData.schedule?.find((day) => day.isActive)?.timeDayEnd ?? "18:00:00",
      });
      changeAllActiveDays();
    }
  }, [scheduleData.scheduleIsSameOnWeekdays]);

  useEffect(() => {
    if (scheduleData.scheduleIsSameOnWeekdays) {
      changeAllActiveDays();
    }
  }, [sameDaysData]);

  const handleScheduleSave = async () => {
    const newData = await props.handleScheduleSave(scheduleData.schedule);
    if (newData) {
      setScheduleData(newData);
    }
  };

  const handleSetSameDaysData = (data: { timeDayStart: string; timeDayEnd: string }) => {
    setSameDaysData({
      ...sameDaysData,
      ...data,
    });
  };

  const handleDayChange = (day: CompanyScheduleDto) => {
    replaceDayInSchedule(scheduleData.schedule.find((x) => x.id == day.id) ?? {}, day);
  };

  const replaceDayInSchedule = (currentDay: CompanyScheduleDto, newDay: CompanyScheduleDto) => {
    const index = scheduleData.schedule.indexOf(currentDay);
    if (index != -1) {
      let newSchedule = [...scheduleData.schedule];
      newSchedule[index] = { ...newDay };
      setScheduleData({
        ...scheduleData,
        schedule: newSchedule,
        scheduleIsSameOnWeekdays: checkAllActiveDaysAreEqual(newSchedule),
      });
    }
  };

  const checkAllActiveDaysAreEqual = (schedule: CompanyScheduleDto[]) => {
    return schedule
      .filter((s) => s.isActive)
      .every(function (v, i, a) {
        return i === 0 || (v.timeDayEnd === a[i - 1].timeDayEnd && v.timeDayStart === a[i - 1].timeDayStart);
      });
  };

  const changeAllActiveDays = () => {
    const newScheduleData = scheduleData.schedule.map((s) => {
      if (s.isActive) {
        return {
          ...s,
          timeDayStart: sameDaysData.timeDayStart,
          timeDayEnd: sameDaysData.timeDayEnd,
        };
      } else {
        return s;
      }
    });
    setScheduleData({
      scheduleIsSameOnWeekdays: scheduleData.scheduleIsSameOnWeekdays,
      schedule: newScheduleData,
    });
  };

  const sortDaysInSchedule = (data: CompanyScheduleDto[]): CompanyScheduleDto[] => {
    if (props.dayStart == null || data.length == 0) return data;
    if (props.dayStart == 1) {
      const [firstDay, ...restDays] = data;
      const nData = [...restDays, firstDay];
      return nData ?? [];
    } else {
      return data;
    }
  };

  return (
    <CompanyEditFormScheduleFormView
      scheduleData={scheduleData}
      sortDaysInSchedule={sortDaysInSchedule}
      setScheduleData={setScheduleData}
      sameDaysData={sameDaysData}
      handleDayChange={handleDayChange}
      setSameDaysData={handleSetSameDaysData}
      handleScheduleSave={handleScheduleSave}
      readOnly={props.readOnly}
      dayStart={props.dayStart}
    />
  );
}

export default CompanyEditFormScheduleForm;
