import React from "react";
import { IRegulationCommentCard } from "./RegulationHistory.interface";
import { RegulationHistoryDto, RegulationHistoryReactionDto } from "../../../../../../api";
import { api } from "../../../../../../services";
import { CommentCard } from "../../../../../elements/commentCard/CommentCard";
import { ReactionsStrategy } from "../../../../../elements/emojiPicker/strategies/ReactionsStrategy";

const RegulationHistoryCardMemo: React.FC<IRegulationCommentCard> = (props) => {
  const regulationReactionsStrategy = new ReactionsStrategy<RegulationHistoryDto>(
    props.historyId,
    api.regulationHistory,
    (x: RegulationHistoryReactionDto) => x.value as string,
    (x: RegulationHistoryReactionDto) => x?.user?.name ?? x?.user?.nameFallback ?? ""
  );

  return (
    <CommentCard
      creator={props.creator}
      index={props.index}
      historyId={props.historyId}
      reactions={props.reactions}
      reactionStrategy={regulationReactionsStrategy}
      commentContent={props.commentContent}
      commentAttachments={props.commentAttachments}
      dateCreated={props.dateCreated}
      creatorId={props.creatorId}
    />
  );
};

export const RegulationHistoryCard = React.memo(RegulationHistoryCardMemo, (x, y) => x?.historyId === y?.historyId);
