import React from "react";
import "./EducationCard.scss";
import { IEducationCardView } from "./EducationCard.interface";
import { useTranslation } from "react-i18next";
import { Indicator } from "../../../../../elements/indicator";
import { colorSchemeType } from "../../../../../../utils/colorScheme";
import { Alert, Card, CardMeta } from "../../../../../uiKit";

export const EducationCardView = (props: IEducationCardView) => {
  const { t } = useTranslation();

  const Media = (
    <div style={{ position: "relative" }}>
      <img
        alt="lesson"
        //src="https://vk.com/doc602233960_631381652?hash=f8975bb095647e0f4d&dl=563a2423663712ab84"
        src={`https://i.ytimg.com/vi/${props.educationItem.videoId}/maxresdefault.jpg`}
      />
      <div className="indicator-absolute">
        <Indicator name={props.indicator.text} colorSchemeKey={props.indicator.status as colorSchemeType} />
      </div>
    </div>
  );

  return (
    <Card
      hoverable
      cover={Media}
      onClick={() =>
        props.educationItem.isAvailable &&
        props.onOpenTrainingDialog({
          trainingKey: props.trainingKey,
          videoId: props.educationItem.videoId,
        })
      }
      variant="secondary"
      className={`education-card ${!props.educationItem.isAvailable && "education-card-disabled"}`}
    >
      <CardMeta
        title={props.educationItem.name}
        description={
          !props.educationItem.isAvailable ? (
            <Alert type="error" message={t("text:no_permissions")} />
          ) : (
            props.educationItem.description
          )
        }
      />
    </Card>
  );
};
