import React from "react";
import { Typography } from "antd";
import "./Paragraph.scss";
import { ParagraphProps } from "antd/lib/typography/Paragraph";
import clsx from "clsx";

interface IParagraphView extends ParagraphProps {
  size?: string;
  weight?: number | "normal" | "bold" | "lighter" | "bolder";
}

const { Paragraph } = Typography;

export function ParagraphView({ size, weight, className, style, ...rest }: IParagraphView) {
  return (
    <Paragraph
      className={clsx("uiKit-paragraph", className ?? "")}
      style={{ fontWeight: weight, fontSize: size, ...style }}
      {...rest}
    />
  );
}
