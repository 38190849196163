export enum sectionKeys {
  companies = 'admin.companies_management',
  driversSuperAdmin = 'driver.sa',
  driversAdmin = 'driver.admin',
  driver = 'driver',
  orgChart = 'orgchart',
  schedule = 'schedule',
  communications = 'communication',
  policies = 'regulation',
  dashboards = 'dashboard',
  reports = 'report',
  employees = 'employee',
  education = 'edu',
}