import React, { ChangeEvent, MouseEvent, useRef } from "react";
import "../Toolbar/Toolbar.scss";
import { FiImage } from "@react-icons/all-files/fi/FiImage";
import { api } from "../../../../../services";
import { insertImage, PlateEditor, ToolbarButton, usePlateEditorRef } from "@udecode/plate";

interface IImageToolbarButton {
  onLoadingChange: (isLoading: boolean) => void;
}

export const ImageToolbarButton = (props: IImageToolbarButton) => {
  const editor = usePlateEditorRef()!;
  const fileUploadRef = useRef<any>(null);

  const remoteImage = async (editor: PlateEditor, files: FileList) => {
    for (const file of files) {
      const [mime] = file.type.split("/");
      if (mime === "image") {
        props.onLoadingChange(true);
        const formData = new FormData();
        formData.append("files", file);
        const r = await api.staticFile.upload(formData, "image");
        if (r === null) {
          props.onLoadingChange(false);
          return;
        } else {
          insertImage(editor, r[0].url as string);
          props.onLoadingChange(false);
        }
      }
    }
  };

  const uploadImage = (event: ChangeEvent<HTMLInputElement>) => {
    remoteImage(editor, event.target.files as FileList);
    fileUploadRef.current.value = "";
  };

  const handleUploadImageMouseDown = (event: MouseEvent) => {
    event.preventDefault();
    fileUploadRef.current.click();
  };
  return (
    <>
      <ToolbarButton icon={<FiImage className="toolbar__buttons" />} onMouseDown={handleUploadImageMouseDown} />
      <input
        type="file"
        onChange={uploadImage}
        name="myImage"
        accept="image/*"
        ref={fileUploadRef}
        style={{ display: "none" }}
      />
    </>
  );
};
