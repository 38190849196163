import React from "react";
import "./UserCard.scss";
import {IUserCard} from "./UserCard.interface";
import { UserCardView } from "./UserCardView";

const UserCardMemo = (props: IUserCard) => {
  return (
    <UserCardView {...props} />
  );
};

export const UserCard = React.memo(UserCardMemo, (x,y) => x?.userInfo?.id === y?.userInfo?.id)

