import React, { useEffect, useRef, useState } from "react";
import PageHeader from "../../../../layouts/pageHeader/PageHeader";
import PageContent from "../../../../layouts/pageContent/PageContent";
import { RegulationContent } from "../../pages";
import { RegulationViewHeader } from "../../headers/regulationViewHeader/RegulationViewHeader";
import { IRegulation } from "./Regulation.interface";
import { useNotifier, useRootStore, useSequentialPromises } from "../../../../../../hooks";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AutocompleteModel,
  RegulationContentDto,
  RegulationContentQuestionDto,
  RegulationDto,
} from "../../../../../../api";
import {
  checkRegulationContentType,
  checkRegulationContentTypeIsDraft,
  findId,
} from "../../../../../../helpers/pathname";
import { handleCountQuestions } from "../../pages/helpers";
import { contentToString } from "../../../../../../utils/textEditor";
import { errorKeys } from "../../../../forms/createRegulatiomForm/consts";
import { resetIds, validateTest } from "../../../../forms/createTestsForm/helpers";
import { api } from "../../../../../../services";
import { regulationContentTypeKeys } from "../../misc/headerTools";
import { UserSelectionRule } from "../../../../../../api/types/userSelectionRule";
import { SettingUpAccessDialog } from "../../../../dialogs/settingUpAccessDialog/SettingUpAccessDialog";
import { keysRulesFor } from "../../../../dialogs/settingUpAccessDialog/SettingUpAccessDialog.interface";
import { PassingRegulationTestDialog } from "../../../../dialogs/passingRegulationTestDialog/PassingRegulationTestDialog";
import { FindRegulationSectionDialog } from "../../../../dialogs/findRegulationSectionDialog/FindRegulationSectionDialog";
import { CustomConfirmDialog } from "../../../../dialogs/customConfirmDialog/СustomConfirmDialog";
import { SelectApproverDialog } from "../../../../dialogs/selectApproverDialog/SelectApproverDialog";
import { Value } from "@udecode/plate";

export const Regulation: React.FC<IRegulation> = (props) => {
  const { breadcrumbsStore, authStore } = useRootStore();

  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const notifier = useNotifier();

  const location = useLocation();

  const testRef = useRef<{ test: RegulationContentQuestionDto[]; hasChanges: boolean }>({
    test: [],
    hasChanges: false,
  });

  const [regulation, setRegulation] = useState<RegulationDto>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingRejectBtn, setIsLoadingRejectBtn] = useState<boolean>(false);
  const [isLoadingApproveBtn, setIsLoadingApproveBtn] = useState<boolean>(false);
  const [isLoadingSubmitApproval, setIsLoadingSubmitApproval] = useState<boolean>(false);
  const [isLoadingAddToSectionBtn, setIsLoadingAddToSectionBtn] = useState<boolean>(false);
  const [isLoadingSaveChangesBtn, setIsLoadingSaveChangesBtn] = useState<boolean>(false);
  const [isLoadingDeleteBtn, setIsLoadingDeleteBtn] = useState<boolean>(false);
  const [isLoadingStudyBtn, setIsLoadingStudyBtn] = useState<boolean>(false);

  const [isOpenAccessSettingsDialog, setIsOpenAccessSettingsDialog] = useState<boolean>(false);
  const [isOpenFindSectionDialog, setIsOpenFindSectionDialog] = useState<boolean>(false);
  const [isOpenTestPassDialog, setIsOpenTestPassDialog] = useState<boolean>(false);
  const [isOpenSelectApproverDialog, setIsOpenSelectApproverDialog] = useState<boolean>(false);
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState<boolean>(false);

  const [currentContent, setCurrentContent] = useState<RegulationContentDto>();
  const [draftContent, setDraftContent] = useState<RegulationContentDto>();

  const [hasDraftChanges, setHasDraftChanges] = useState<boolean>(false);
  const [hasDraftTestChanges, setHasDraftTestChanges] = useState<boolean>(false);

  const [isForceReload, setIsForceReload] = useState<boolean>(false);

  const [editableTest, setEditableTest] = useState<RegulationContentQuestionDto[]>([]);

  const [selectionSection, setSelectionSection] = useState<AutocompleteModel | undefined>(undefined);

  const [title, setTitle] = useState<string>("");

  const [isEditable, setIsEditable] = useState<boolean>(false);

  const isDraft = checkRegulationContentTypeIsDraft(location.search);
  const regulationContentType = checkRegulationContentType(location.search);
  // const isCreator : boolean = regulation?.createdByUser?.id == authStore?.getInitialInfo?.identity?.id;

  // const testIsPassed: boolean = isPassedTest(regulation, authStore?.getInitialInfo?.identity?.id);
  const testIsPassed: boolean = !!regulation?.isStudied;
  const countQuestions: number = handleCountQuestions(regulation?.activeContent ?? { questions: [] });

  const promisesListRef = useRef(useSequentialPromises());

  // const isCreator: boolean = (authStore?.getInitialInfo?.identity?.id === regulation?.createdByUserId);

  const handleCloseAccessSettingsDialog = () => setIsOpenAccessSettingsDialog(false);
  const handleOpenAccessSettingsDialog = () => setIsOpenAccessSettingsDialog(true);

  const handleCloseFindSectionDialog = () => setIsOpenFindSectionDialog(false);
  const handleOpenFindSectionDialog = () => setIsOpenFindSectionDialog(true);

  const handleOpenSelectApproverDialog = () => setIsOpenSelectApproverDialog(true);
  const handleCloseSelectApproverDialog = () => setIsOpenSelectApproverDialog(false);

  const handleOpenDeleteRegulationDialog = () => {
    setIsOpenConfirmDialog(true);
    promisesListRef.current.add(handleDeleteRegulation);
  };

  const handleCancelConfirm = async () => {
    setIsOpenConfirmDialog(false);
    promisesListRef.current.reset();
  };

  const handleSuccessConfirm = async () => {
    setIsOpenConfirmDialog(false);
    await promisesListRef.current.run();
  };

  const handlePasteContentType = () => `contentType=${regulationContentType}`;

  const handleApiError = () => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });

  const handleSetHasDraftTestChanges = (value: boolean) => setHasDraftTestChanges(value);

  const handleCloseTestPassDialog = () => {
    navigate(`/policy/all/${regulation?.id}/?${handlePasteContentType()}`);
    setIsOpenTestPassDialog(false);
  };
  const handleCloseAndReloadTestPassDialog = async () => {
    setIsOpenTestPassDialog(false);
    navigate(`/policy/all/${regulation?.id}/?${handlePasteContentType()}`);
    await handleLoadRegulation(2);
  };

  const handleOpenTestPassDialog = () => {
    setIsOpenTestPassDialog(true);
    navigate(`/policy/all/${regulation?.id}/study/?${handlePasteContentType()}`, {
      // @ts-ignore
      state: { ...location?.state },
    });
  };

  const handleCancelEditable = () => {
    handleChangeEditable(false);
    setHasDraftChanges(false);
    setHasDraftTestChanges(false);
  };

  const handleChangeDraftContent = (newContent: Value, withoutUpdateChangesFlag?: boolean) => {
    setDraftContent((prev) => ({ ...prev, content: contentToString(newContent) }));
    if (contentToString(newContent) === (regulation?.draftContent?.content ?? "")) return setHasDraftChanges(false);
    !hasDraftChanges && !withoutUpdateChangesFlag && setHasDraftChanges(true);
  };

  const handleCatchOpenPassTestDialog = (
    thisTestIsPassed: boolean | undefined,
    thisCountQuestions: number | undefined,
    regulationId: number
  ): boolean => {
    if (thisTestIsPassed ?? testIsPassed) {
      notifier.show({ message: t("notifier:error.test_is_passed"), theme: "error" });
      navigate(`/policy/all/${regulationId}/?${handlePasteContentType()}`);
      return false;
    }
    if (!(thisCountQuestions ?? countQuestions)) {
      notifier.show({ message: t("notifier:error.regulation_no_test"), theme: "error" });
      navigate(`/policy/all/${regulationId}/?${handlePasteContentType()}`);
      return false;
    }
    return true;
    if (testIsPassed) {
      notifier.show({ message: t("notifier:error.test_is_passed"), theme: "error" });
      navigate(`/policy/all/${regulationId}/${handlePasteContentType()}`);
      return false;
    }
  };

  // const handleRedirectToPassTest = (regulationId ?: number) => {
  //   setIsOpenTestPassDialog(true);
  //   navigate(
  //     `/policy/all/${regulationId ?? regulation?.id}/study/?${handlePasteContentType()}`,
  //     // @ts-ignore
  //     { state: { ...location.state } }
  //   );
  // };

  const handleChangeEditable = (newValue: boolean) => {
    setIsEditable(newValue);
    if (!newValue) {
      setDraftContent(regulation?.draftContent ?? currentContent);
      setTitle(regulation?.name ?? "");
    }
  };
  const handleChangeTitle = (newValue: string, withoutUpdateChangesFlag?: boolean) => {
    setTitle(newValue);
    !hasDraftChanges && !withoutUpdateChangesFlag && setHasDraftChanges(true);
  };

  const validateErrorHandler = (errorMessage: string) => {
    setIsLoading(false);
    switch (errorMessage) {
      case errorKeys.emptyTitle:
        notifier.show({ message: t("validation:enter_regulation_name"), theme: "error" });
        break;
      case errorKeys.noAnswers:
        notifier.show({ message: t("validation:no_answers"), theme: "error" });
        break;
      case errorKeys.noRightsAnswer:
        notifier.show({ message: t("validation:no_rights_answer"), theme: "error" });
        break;
      case errorKeys.noNameAnswer:
        notifier.show({ message: t("validation:no_name_answer"), theme: "error" });
        break;
      case errorKeys.noNameQuestion:
        notifier.show({ message: t("validation:no_name_question"), theme: "error" });
        break;
    }
  };

  const handleSaveDraftChanges = async () => {
    if (hasDraftTestChanges) {
      try {
        validateTest(testRef.current.test);
      } catch (error: any) {
        validateErrorHandler(error.message as string);
        return;
      }
    }
    if (hasDraftChanges || hasDraftTestChanges) {
      setIsLoadingSaveChangesBtn(true);
      await api.regulation
        .edit(regulation!.id!, {
          ...regulation,
          name: title,
          draftContent: {
            ...regulation?.draftContent,
            content: draftContent!.content,
            questions: resetIds(testRef.current.test),
          },
        })
        .then((res) => {
          if (res) {
            setRegulation(res);
            handleInitialization(res);
            notifier.show({ message: t("notifier:success.draft_was_saved"), theme: "success" });
            setIsEditable(false);
            setDraftContent(res.draftContent);
            testRef.current = { test: res.draftContent?.questions ?? [], hasChanges: false };
          }
        })
        .catch(handleApiError)
        .finally(() => setIsLoadingSaveChangesBtn(false));
    }
  };

  const handleAddRegulationToSection = (section: AutocompleteModel | undefined) => {
    if (section?.id) {
      setIsLoadingAddToSectionBtn(true);
      api.regulation
        .addToSection(section.id, regulation!.id!)
        .then(() => notifier.show({ message: t("notifier:success.regulation_add_to_section"), theme: "success" }))
        .catch(handleApiError)
        .finally(() => setIsLoadingAddToSectionBtn(false));
    }
  };

  const handleEditTest = () => {
    setIsLoadingSubmitApproval(true);
    api.regulation
      .edit(regulation?.id!, {
        ...regulation,
        draftContent: {
          ...regulation?.draftContent,
          questions: testRef.current.test ?? [],
        },
      })
      .then((res) => {
        setIsEditable(false);
        res && setRegulation(res);
        notifier.show({ message: t("notifier:success.test_successful_edited"), theme: "success" });
        res && setRegulation(res);
      })
      .catch(handleApiError)
      .finally(() => setIsLoadingSubmitApproval(false));
  };

  //TODO: DS check this moment
  const handleSubmittingForApproval = async () => {
    await api.regulation
      .publish(regulation?.id!)
      .then(() => notifier.show({ message: t("notifier:success.submitted_for_approval"), theme: "success" }))
      .catch(handleApiError);
  };

  const handleAppointApprover = async (approverId: number, resetTestResults: boolean, urgency?: string) => {
    await api.regulation
      .edit(regulation?.id!, {
        ...regulation,
        ...(urgency && { timeToStudy: urgency }),
        draftContent: {
          ...regulation?.draftContent,
          userPublisherId: approverId,
          resetTestResultsOnPublish: resetTestResults,
        },
      })
      .then(() => notifier.show({ message: t("notifier:success.submitted_for_approval"), theme: "success" }))
      .catch(handleApiError);
  };

  const handleRequestPublication = async (approverId: number, resetTestResults: boolean, urgency?: string) => {
    handleCloseSelectApproverDialog();
    setIsLoadingSubmitApproval(true);
    await handleAppointApprover(approverId, resetTestResults, urgency);
    setIsForceReload(true);
    setIsLoadingSubmitApproval(false);
  };

  const handlePublish = async () => {
    setIsLoadingApproveBtn(true);
    await handleSaveDraftChanges();
    api.regulation
      .publish(regulation?.id!, true)
      .then((res) => {
        navigate(`/policy/all/${regulation!.id}/?contentType=${regulationContentTypeKeys.approved}`);
        notifier.show({ message: t("notifier:success.regulation_is_approved"), theme: "success" });
        isEditable && setIsEditable(false);
        setIsForceReload(true);
      })
      .catch(handleApiError)
      .finally(() => setIsLoadingApproveBtn(false));
  };

  const handleReject = () => {
    setIsLoadingRejectBtn(true);
    api.regulation
      .reject(regulation?.id!)
      .then(() => {
        navigate(`/policy/all/${regulation!.id}/?contentType=${regulationContentTypeKeys.draft}`);
        notifier.show({ message: t("notifier:success.regulation_is_rejected"), theme: "success" });
        setIsForceReload(true);
      })
      .catch(handleApiError)
      .finally(() => setIsLoadingRejectBtn(false));
  };

  const handleDeleteRegulation = async () => {
    setIsLoadingDeleteBtn(true);
    api.regulation
      .del(regulation?.id!)
      .then(() => {
        navigate(`/policy`);
        notifier.show({ message: t("notifier:success.delete_regulation"), theme: "success" });
      })
      .catch(handleApiError)
      .finally(() => setIsLoadingDeleteBtn(false));
  };

  const handleInitialization = (resRegulation: RegulationDto) => {
    handleChangeTitle(resRegulation.name!, true);
    breadcrumbsStore.setLastCrumb(resRegulation.name ?? "");
    setRegulation(resRegulation);
    switch (regulationContentType) {
      case regulationContentTypeKeys.draft: {
        setCurrentContent(resRegulation?.draftContent);
        setDraftContent(resRegulation?.draftContent);
        setEditableTest(resRegulation?.draftContent?.questions ?? []);
        break;
      }
      case regulationContentTypeKeys.approved: {
        setCurrentContent(resRegulation?.activeContent);
        setDraftContent(resRegulation?.draftContent ?? resRegulation?.activeContent);
        setEditableTest(
          (resRegulation?.draftContent?.questions
            ? resRegulation?.draftContent?.questions
            : resRegulation?.activeContent?.questions) ?? []
        );
        break;
      }
      case regulationContentTypeKeys.unknown: {
        setCurrentContent(resRegulation?.activeContent ?? resRegulation?.draftContent);
        setDraftContent(resRegulation?.draftContent ?? resRegulation?.activeContent);
        setEditableTest(resRegulation?.draftContent?.questions ?? resRegulation?.activeContent?.questions ?? []);
        break;
      }
      case null: {
        setCurrentContent(resRegulation?.activeContent ?? resRegulation?.draftContent);
        setDraftContent(resRegulation?.draftContent ?? resRegulation?.activeContent);
        setEditableTest(resRegulation?.draftContent?.questions ?? resRegulation?.activeContent?.questions ?? []);
      }
    }
  };

  const handleFetchRegulation = async (id: number) => {
    setIsLoading(true);
    const awaitedRegulation = await api.regulation
      .getById(id)
      .then((res) => {
        res && handleInitialization(res);
        return res;
      })
      .catch((error) => {
        handleApiError();
        navigate("/policy");
      })
      .finally(() => setIsLoading(false));
    return awaitedRegulation;
  };

  const handleLoadRegulation = async (byForceId?: number) => {
    if (byForceId) {
      const id = findId(pathname, byForceId);
      setIsForceReload(false);
      await handleFetchRegulation(id!);
      return;
    }
    const id = findId(pathname, props.isTestPass ? 2 : 1);
    if (id && !isForceReload) await handleFetchRegulation(id);
    else navigate("/policy");
  };

  const handleStudyRegulation = () => {
    setIsLoadingStudyBtn(true);
    api.regulation
      .study(regulation?.id!)
      .then(() => {
        setIsForceReload(true);
        notifier.show({ message: t("notifier:success.policy_studied_successfully"), theme: "success" });
      })
      .catch(handleApiError)
      .finally(() => {
        setIsLoadingStudyBtn(false);
      });
  };

  const handleSaveAccessSettings = async (rules: UserSelectionRule[]): Promise<boolean> => {
    if (regulation) {
      await api.regulation
        .edit(regulation.id!, { ...regulation, userSelectionRules: [...rules] })
        .then((res) => {
          if (res) {
            setRegulation(res);
            handleCloseAccessSettingsDialog();
          }
        })
        .catch(() => {
          handleApiError();
          return false;
        });
      return true;
    } else {
      notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
      return false;
    }
  };

  // load regulation for instantly open test dialog witch regulation questions
  useEffect(() => {
    const loadTestWithRegulationId = async () => {
      const id = findId(pathname, 2);
      const currentRegulation = regulation ?? (await handleFetchRegulation(id!));
      if (currentRegulation) {
        const testIsPassed: boolean = !!currentRegulation?.isStudied;
        const countQuestions: number = handleCountQuestions(
          currentRegulation?.activeContent ?? currentRegulation.draftContent ?? undefined
        );
        const permissionToPassTest = handleCatchOpenPassTestDialog(testIsPassed, countQuestions, currentRegulation.id!);
        permissionToPassTest && setIsOpenTestPassDialog(true);
      }
    };
    if (props.isTestPass) loadTestWithRegulationId();
  }, [props.isTestPass]);

  useEffect(() => {
    handleLoadRegulation();
    return () => breadcrumbsStore.resetLastCrumb();
  }, [testIsPassed]);

  useEffect(() => {
    // при редиректе на некст регламент
    const currentRegulationId = findId(pathname, 1);
    if (currentRegulationId && regulation && regulation.id !== currentRegulationId) {
      setIsForceReload(true);
    }
  }, [pathname]);

  useEffect(() => {
    isForceReload && handleLoadRegulation(1);
  }, [isForceReload]);

  return (
    <>
      {isOpenAccessSettingsDialog && (
        <SettingUpAccessDialog
          keyRuleTo={keysRulesFor.regulation}
          onSave={handleSaveAccessSettings}
          isOpen={isOpenAccessSettingsDialog}
          currentRules={(regulation?.userSelectionRules as UserSelectionRule[]) ?? []}
          onClose={handleCloseAccessSettingsDialog}
        />
      )}
      {isOpenTestPassDialog && (
        <PassingRegulationTestDialog
          open={isOpenTestPassDialog}
          onCloseWitchReload={handleCloseAndReloadTestPassDialog}
          onClose={handleCloseTestPassDialog}
          regilationId={regulation?.id!}
          questions={regulation?.activeContent?.questions ?? []}
        />
      )}
      {isOpenFindSectionDialog && (
        <FindRegulationSectionDialog
          currentRegulationId={regulation?.id}
          open={isOpenFindSectionDialog}
          onSaveSection={handleAddRegulationToSection}
          onClose={handleCloseFindSectionDialog}
        />
      )}
      {isOpenSelectApproverDialog && (
        <SelectApproverDialog
          withCheckbox
          withUrgency
          open={isOpenSelectApproverDialog}
          onClose={handleCloseSelectApproverDialog}
          onSuccess={handleRequestPublication}
        />
      )}
      {isOpenConfirmDialog && (
        <CustomConfirmDialog
          open={isOpenConfirmDialog}
          onConfirm={handleSuccessConfirm}
          title={t("ui:title.confirm_deletion")}
          onClose={handleCancelConfirm}
        />
      )}
      <PageHeader>
        <RegulationViewHeader
          onSetHasDraftTestChanges={handleSetHasDraftTestChanges}
          isLoadingDeleteBtn={isLoadingDeleteBtn}
          onOpenDeleteRegulationDialog={handleOpenDeleteRegulationDialog}
          isEditable={isEditable}
          hasActiveContent={!!regulation?.activeContent}
          isLoadingSaveChangesBtn={isLoadingSaveChangesBtn}
          hasDraftChanges={hasDraftChanges || hasDraftTestChanges}
          allowedActions={regulation?.allowedActions}
          isLoadingRejectBtn={isLoadingRejectBtn}
          isLoadingApproveBtn={isLoadingApproveBtn}
          isLoadingSubmitApproval={isLoadingSubmitApproval}
          isLoadingAddToSectionBtn={isLoadingAddToSectionBtn}
          onCancelEditable={handleCancelEditable}
          onSaveDraftChanges={handleSaveDraftChanges}
          onOpenAccessSettingsDialog={handleOpenAccessSettingsDialog}
          onOpenSelectApproverDialog={handleOpenSelectApproverDialog}
          onOpenFindSectionDialog={handleOpenFindSectionDialog}
          onChangeEditable={handleChangeEditable}
          onPublish={handlePublish}
          onReject={handleReject}
        />
      </PageHeader>
      <PageContent>
        <RegulationContent
          isLoading={isLoading}
          isTestPass={props.isTestPass}
          isEditable={isEditable}
          isLoadingStudyBtn={isLoadingStudyBtn}
          draftTitle={title}
          currentContent={currentContent}
          editableTest={editableTest}
          testIsPassed={testIsPassed}
          isDraft={isDraft}
          countQuestions={countQuestions}
          testRef={testRef}
          regulation={regulation}
          allowedActions={regulation?.allowedActions}
          onChangeDraftContent={handleChangeDraftContent}
          onSetHasDraftTestChanges={handleSetHasDraftTestChanges}
          onChangeTitle={handleChangeTitle}
          draftContent={draftContent}
          onStudyRegulation={handleStudyRegulation}
          onOpenTestPassDialog={handleOpenTestPassDialog}
          onOpenSelectApproverDialog={handleOpenSelectApproverDialog}
        />
      </PageContent>
    </>
  );
};
