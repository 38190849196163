import React from "react";
import { ITelegramBotFieldsControll } from "./TelegramBotFieldsControll.interface";
import SubheaderText from "../../../../../elements/subheaderText/SubheaderText";
import { Input, Tooltip } from "../../../../../uiKit";
import { FiHelpCircle } from "@react-icons/all-files/fi/FiHelpCircle";
import { Form } from "antd";
import { messengerConfigFieldKeys } from "../../../../pages/groupCompany/constants/keys";
import PhoneInputField from "../../../../../elements/phoneInputField/PhoneInputField";
import { useTranslation } from "react-i18next";

export const TelegramBotFieldsControll = (props: ITelegramBotFieldsControll) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="d-stack-row justify-start align-start spacing-2">
        <SubheaderText text="API_ID" />
        <Tooltip
          title={
            <a
              style={{ color: "var(--color-white)", textDecoration: "underline" }}
              href="https://my.telegram.org"
              target="_blank"
              rel="noreferrer"
            >
              API
            </a>
          }
          placement="top"
        >
          <FiHelpCircle className="guide-icon" />
        </Tooltip>
      </div>
      <Form.Item
        className="form-item__mb3"
        initialValue={props.messengerConfig.api_id}
        name="api_id"
        rules={[{ required: props.isAddBot, message: t("validation:field_required") }]}
      >
        <Input
          autoComplete="off"
          placeholder={t("ui:placeholder.group_company_name")}
          value={props.messengerConfig.api_id}
          // @ts-ignore
          onChange={props.onChangeMessengerConfig(messengerConfigFieldKeys.api_id)}
        />
      </Form.Item>
      <div className="d-stack-row justify-start align-start spacing-2">
        <SubheaderText text="API_HASH" />
        <Tooltip
          title={
            <a
              style={{ color: "var(--color-white)", textDecoration: "underline" }}
              href="https://my.telegram.org"
              target="_blank"
              rel="noreferrer"
            >
              API
            </a>
          }
          placement="top"
        >
          <FiHelpCircle className="guide-icon" />
        </Tooltip>
      </div>
      <Form.Item
        className="form-item__mb3"
        // label={t("ui:label.name")}
        name="api_hash"
        initialValue={props.messengerConfig.api_hash}
        rules={[{ required: props.isAddBot, message: t("validation:field_required") }]}
      >
        <Input
          autoComplete="off"
          placeholder={t("ui:placeholder.group_company_name")}
          value={props.messengerConfig.api_hash}
          // @ts-ignore
          onChange={props.onChangeMessengerConfig(messengerConfigFieldKeys.api_hash)}
        />
      </Form.Item>
      <SubheaderText text={t("ui:subheader.phone_number")} />
      <PhoneInputField
        value={props.messengerConfig.phone_number ?? ""}
        // @ts-ignore
        onChange={props.onChangeMessengerConfig(messengerConfigFieldKeys.phone_number)}
        isValid={(value, country) => {
          if (props.isAddBot && value?.trim().length == 0) return false;
          else return true;
        }}
        defaultErrorMessage={t("ui:helper.error_textfield")}
      />
      <div className="mt-3" />
      <SubheaderText text={t("ui:subheader.first_name")} />
      <Form.Item
        className="form-item__mb3"
        initialValue={props.messengerConfig.first_name}
        name="first_name"
        rules={[{ required: props.isAddBot, message: t("validation:field_required") }]}
      >
        <Input
          autoComplete="off"
          placeholder={t("ui:placeholder.group_company_name")}
          value={props.messengerConfig.first_name}
          // @ts-ignore
          onChange={props.onChangeMessengerConfig(messengerConfigFieldKeys.first_name)}
        />
      </Form.Item>
      <SubheaderText text={t("ui:subheader.last_name")} />
      <Form.Item
        className="form-item__mb3"
        initialValue={props.messengerConfig.last_name}
        // label={t("ui:label.name")}
        name="last_name"
        rules={[{ required: props.isAddBot, message: t("validation:field_required") }]}
      >
        <Input
          autoComplete="off"
          placeholder={t("ui:placeholder.group_company_name")}
          value={props.messengerConfig.last_name}
          // @ts-ignore
          onChange={props.onChangeMessengerConfig(messengerConfigFieldKeys.last_name)}
        />
      </Form.Item>
    </>
  );
};
