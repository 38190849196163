import { useStopwatch } from "./useStopwatch";
import { useEffect, useRef, useState } from "react";
import { convertSecondsToObject } from "../helpers/dateFunctions";
import { addLeadingZeroToString } from "../helpers/stringFunctions";

declare const navigator: any;

export interface IAudioRecorder {
  startRecording: () => void;
  stopRecording: () => void;
  pauseResumeRecording: () => void;
  resetRecording: () => void;
  recordingBlob?: Blob | null;
  isRecording: boolean;
  isPaused: boolean;
  recordingTime: string;
}

interface IAudioRecorderState {
  isPaused: boolean;
  isRecording: boolean;
}

/**
 * @returns Controls for the recording. Details of returned controls are given below
 *
 * @details `startRecording`: Calling this method would result in the recording to start. Sets `isRecording` to true
 * @details `stopRecording`: This results in a recording in progress being stopped and the resulting audio being present in `recordingBlob`. Sets `isRecording` to false
 * @details `pauseResumeRecording`: Calling this method would pause the recording if it is currently running or resume if it is paused. Toggles the value `isPaused`
 * @details `recordingBlob`: This is the recording blob that is created after `stopRecording` has been called
 * @details `isRecording`: A boolean value that represents whether a recording is currently in progress
 * @details `isPaused`: A boolean value that represents whether a recording in progress is paused
 * @details `recordingTime`: Number of seconds that the recording has gone on. This is updated every second
 */
export const useAudioRecorder = (): IAudioRecorder => {
  const audioType = "audio/*";
  // const audioType = "audio/mp4";
  const {
    start: timerStart,
    stop: timerStop,
    reset: timerReset,
    time: timerTime,
  } = useStopwatch({ useFractionalPart: true });

  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>();
  const [recordingStream, setRecordingStream] = useState<MediaStream>();
  const [recordingChunks, setRecordingChunks] = useState<BlobPart[]>([]);
  const [recordingBlob, setRecordingBlob] = useState<Blob | null>(null);
  const [timerInterval, setTimerInterval] = useState<NodeJS.Timer>();
  const [recordingTime, setRecordingTime] = useState(0);
  const [recordingAudioBuffer, setRecordingAudioBuffer] = useState<AudioBuffer | null>(null);
  const [state, setState] = useState<IAudioRecorderState>({
    isPaused: false,
    isRecording: false,
  });
  const recordingChunksCopy = useRef<BlobPart[]>([]);

  const setRecordingChunksSync = (v: BlobPart[]) => {
    recordingChunksCopy.current = v;
    setRecordingChunks(recordingChunksCopy.current);
  };

  const _startTimer: () => void = () => {
    if (timerInterval != null) return;
    const interval = setInterval(() => {
      setRecordingTime((time) => time + 1);
    }, 1000);
    setTimerInterval(interval);
  };

  const _stopTimer: () => void = () => {
    console.log("stop timer");
    timerInterval != null && clearInterval(timerInterval);
    setTimerInterval(undefined);
  };

  const startRecording = async () => {
    if (state.isRecording) return;
    navigator.getUserMedia =
      navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;

    if (navigator.mediaDevices) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream: MediaStream) => {
          const recorder = new MediaRecorder(stream, {
            audioBitsPerSecond: 256000,
            bitsPerSecond: 2628000,
            // mimeType: "audio/mp4",
          });
          // recorder.stream.getTracks().forEach((t) => t.stop());
          // setRecordingStream(stream);
          setMediaRecorder(recorder);

          recorder.ondataavailable = function (event) {
            if (event.data && event.data.size > 0) {
              console.log("data added to blob");
              setRecordingChunksSync([...recordingChunksCopy.current, event.data]);
            }
          };
          recorder.onstart = function () {
            // timerStart();
            console.log("start timer");
            setState((s) => ({ ...s, isPaused: false, isRecording: true }));
          };
          recorder.onpause = function () {
            console.log("stop timer");
            // timerStop();
            setState((s) => ({ ...s, isPaused: true }));
          };
          recorder.onresume = function () {
            console.log("start timer 2");
            // timerStart();
            // _startTimer();
            setState((s) => ({ ...s, isPaused: false, isRecording: true }));
          };
          recorder.onstop = function (e) {
            console.log("stop timer 2");
            // timerReset();
            mediaRecorder?.stream.getTracks().forEach((t) => t.stop());
            setState((s) => ({ ...s, isPaused: false, isRecording: false }));
          };
          recorder.start(500);
          _startTimer();
        })
        .catch((e: any) => {
          // TODO: Handle error
          console.log("Error while recording #1", e);
        });
    } else {
      console.log("Error while recording #2");
    }
  };

  const stopRecording = () => {
    mediaRecorder?.stream.getTracks().forEach((t) => t.stop());
    mediaRecorder?.stop();
    _stopTimer();
    setRecordingTime(0);
    setRecordingChunks([]);
    setRecordingBlob(null);
    // timerReset();
  };

  const pauseResumeRecording = () => {
    if (!state.isRecording) return;
    if (state.isPaused) {
      mediaRecorder?.resume();
      _startTimer();
    } else {
      mediaRecorder?.pause();
      _stopTimer();
    }
  };

  const resetRecording = () => {
    mediaRecorder?.stream.getTracks().forEach((t) => t.stop());
    mediaRecorder?.stop();
    _stopTimer();
    setRecordingChunks([]);
    setRecordingTime(0);
  };

  useEffect(() => {
    if (recordingChunks == null) {
      setRecordingBlob(null);
      return;
    }
    // setRecordingBlob(new Blob([...recordingChunks], { type: "audio/mp4" }));
    setRecordingBlob(new Blob([...recordingChunks], { type: audioType }));
  }, [recordingChunks]);

  return {
    startRecording,
    stopRecording,
    pauseResumeRecording: pauseResumeRecording,
    resetRecording: resetRecording,
    recordingBlob: recordingBlob,
    isRecording: state.isRecording,
    isPaused: state.isPaused,
    recordingTime: `${
      addLeadingZeroToString((convertSecondsToObject(recordingTime).totalMinutes ?? 0).toString(), 2) ?? "00"
    }:${addLeadingZeroToString((convertSecondsToObject(recordingTime).seconds ?? 0).toString(), 2) ?? "00"}`,
    // isRecording: mediaRecorder?.state == "recording",
    // isPaused: mediaRecorder?.state == "paused",
  };
};
