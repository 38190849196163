import React from "react";
import { DashboardChartView, TDashboardChartViewChartsData } from "./DashboardChartView";
import { removeDuplicatesFromArrayByKey } from "../../../../../helpers/arrayFunctions";
import { MetricDividerDto, MetricReadDto } from "../../../../../api";

export type TDashboardChartDataItem = {
  id: number;
  dateISO: string;
  label: string;
  charts: {
    quota: number | null;
    line: { id: number; name: string; color?: string; isReadOnly?: boolean; value: number | null }[];
  };
};

interface IDashboardChart {
  data: TDashboardChartDataItem[];
  metricId: number;
  graphId?: number;
  metricData: MetricReadDto;
  yDividers: MetricDividerDto[];
  isReadOnly?: boolean;
  isReversed?: boolean;
  isLegendEnabled?: boolean;
  isColored?: boolean;
  viewSettings?: {
    min: number | null;
    max: number | null;
  };
  // isSegmentColorHighlightingEnabled?: boolean;

  onChange?: (itemId: number) => void;
  // onChange?: (value: TDashboardChartDataItem) => void;
}

export function DashboardChart(props: IDashboardChart) {
  const mapPropsChartsDataToViewChartsData = (data: TDashboardChartDataItem[]): TDashboardChartViewChartsData => {
    if (props.data.length == 0) {
      return {
        lineCharts: [],
        chartItemsIds: [],
        quotaChart: [],
      };
    }
    const allLineCharts: { id: number; name: string; color?: string }[] = removeDuplicatesFromArrayByKey(
      data.map((d) => d.charts.line).flat(),
      "id"
    );
    let lineChartsTemp: {
      id: number;
      name: string;
      color?: string;
      isReadOnly?: boolean;
      data: { itemId: number; date: string; label: string; value: number | null }[];
    }[] = allLineCharts.map((c) => ({ id: c.id, name: c.name, color: c.color, data: [] }));
    data.forEach((d) => {
      d.charts.line.forEach((l) => {
        //
        let chartTemp = lineChartsTemp.find((c) => c.id == l.id) as {
          id: number;
          name: string;
          color?: string;
          isReadOnly?: boolean;
          data: { itemId: number; date: string; label: string; value: number | null }[];
        };
        chartTemp = {
          ...chartTemp,
          data: [...chartTemp.data, { itemId: d.id, date: d.dateISO, label: d.label, value: l.value }],
        };
        lineChartsTemp = lineChartsTemp.map((t) => (t.id == chartTemp.id ? chartTemp : t));
      });
    });

    return {
      chartItemsIds: props.data.map((d) => d.id),
      lineCharts: lineChartsTemp,
      quotaChart: data.map((d) => ({ itemId: d.id, date: d.dateISO, label: d.label, value: d.charts.quota })),
    };
  };

  const handleChange = async (itemId: number) => {
    props.onChange && props.onChange(itemId);
  };

  return (
    <DashboardChartView
      data={props.data}
      metricData={props.metricData}
      metricId={props.metricId}
      graphId={props.graphId}
      yDividers={props.yDividers}
      chartsData={mapPropsChartsDataToViewChartsData(props.data ?? [])}
      isReversed={props.isReversed}
      isReadOnly={props.isReadOnly}
      isColored={props.isColored}
      isLegendEnabled={props.isLegendEnabled}
      viewSettings={props.viewSettings}
      onChange={handleChange}
    />
  );
}
