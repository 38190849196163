// import { css } from "@emotion/css/dist/emotion-css.cjs";
import { css } from "@emotion/css";
import { Avatar } from "../../../uiKit";
import React from "react";
import { useApi } from "../../../../hooks";
import { api } from "../../../../services";

export interface IOrgchartComponent {
  id: number;
  color: string;
}

export const OrgchartComponent = (props: IOrgchartComponent) => {
  const orgchart = useApi(() => api.orgchart.getById(props.id));

  return (
    <span
      className={css`
        margin: 4px 0 4px 0;
        padding: 4px;
        vertical-align: middle;
        border-radius: var(--border-radius-sm);
        display: inline-block;
      `}
      style={{ pointerEvents: "none", userSelect: "none", backgroundColor: props.color }}
      contentEditable={false}
    >
      <div className="d-stack-row align-center">
        <div>
          <Avatar
            size={23}
            src={orgchart.value?.image?.url}
            text={orgchart.value?.name}
            color={orgchart.value?.colorHex}
          />
        </div>
        <div className="ml-2">{orgchart.value?.name}</div>
      </div>
    </span>
  );
};
