import "./RegulationsStats.scss";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { UserWithRegulationStatsDto } from "../../../../../../api";
import { Table } from "../../../../../uiKit";

enum tableValueKeys {
  employees = "employees",
  total = "total",
  studied = "studied",
  to_study = "toStudy",
  studied_percent = "studied_percent"
}

type tableValueType =
  tableValueKeys.employees
  | tableValueKeys.total
  | tableValueKeys.studied
  | tableValueKeys.to_study
  | tableValueKeys.studied_percent;

export interface RegulationColumnTableType {
  title: string,
  dataIndex: tableValueType,
  key: string,
}

export interface RegulationDataSourceTableType {
  key: number,
  userId: number,
  userName: string,
  employees: string,
  total: number,
  studied: number,
  toStudy: number,
  studied_percent: string;
}


export interface IRegulationsStats {
  statsItems: UserWithRegulationStatsDto[];
  onOpenStatsByUserDialog: (id: number, userName: string) => void;
  currentPage : number;
  loading : boolean;
  totalItems : number;
  onLoadPage : (page : number) => void;
}

const handleGetPageSizeOptions = (totalItems : number) : number[] | undefined => {
  if(totalItems < 10) {
    return undefined;
  }
  else if (totalItems > 10 && totalItems < 30) {
    return [10,20];
  }
  else if (totalItems > 30) {
    return [10,20,30];
  }
};

export const RegulationsStats = ( props : IRegulationsStats) => {

  const { t } = useTranslation();

  const columns: RegulationColumnTableType[] = [
    {
      title: t("ui:table.employees"),
      dataIndex: tableValueKeys.employees,
      key: "employees"
    },
    {
      title: t("ui:table.total"),
      dataIndex: tableValueKeys.total,
      key: "total"
    },
    {
      title: t("ui:table.studied"),
      dataIndex: tableValueKeys.studied,
      key: "studied"
    },
    {
      title: t("ui:table.to_study"),
      dataIndex: tableValueKeys.to_study,
      key: "to_study"
    },
    {
      title: t("ui:table.studied_percent"),
      dataIndex: tableValueKeys.studied_percent,
      key: "studied_percent"
    }
  ];

  const tableDataSource: RegulationDataSourceTableType[] = (props.statsItems && props.statsItems.length > 0) ? props.statsItems.map((stat, index) => (
    {
      key: index,
      userId: stat.id ?? 0,
      userName: stat.name ?? "",
      employees: stat.name ?? "",
      total: stat?.regulationStats?.overall ?? 0,
      studied: stat?.regulationStats?.studied ?? 0,
      toStudy: stat?.regulationStats?.toStudy ?? 0,
      studied_percent: `${Math.round(stat?.regulationStats?.studiedPercent ?? 0) as number}%`
    }
  )) : [];

  const handleRowClick = (data: RegulationDataSourceTableType, rowIndex ?: number): React.HTMLAttributes<any> | React.TdHTMLAttributes<any> => (
    {
      onClick: (event: any) => props.onOpenStatsByUserDialog(data.userId, data.userName)
    }
  );

  const pageSizeOptions = useMemo(() => handleGetPageSizeOptions(props.totalItems), [props.totalItems]);

  console.log(props.totalItems);

  return (
    <Table
      loading={props.loading}
      columns={columns}
      pagination={{current : props.currentPage, total : props.totalItems,pageSizeOptions : pageSizeOptions}}
      dataSource={tableDataSource}
      onRow={handleRowClick}
      onChange={(pagingOptions) => props.onLoadPage(pagingOptions.current!)} />
  );
};

