import React, { ReactNode } from "react";
import DeleteMetricOrDashboardDialogView from "./DeleteMetricOrDashboardDialogView";

interface IDashboardDeleteDialog {
  open: boolean;
  title: string;
  description?: ReactNode;
  onDelete(): void;
  onOpen(isOpen: boolean): void;
}

const DeleteMetricOrDashboardDialog = (props: IDashboardDeleteDialog) => {
  return (
    <DeleteMetricOrDashboardDialogView
      description={props.description}
      onDelete={props.onDelete}
      open={props.open}
      onOpen={props.onOpen}
      title={props.title}
    />
  );
};

export default DeleteMetricOrDashboardDialog;
