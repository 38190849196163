import React, { useState } from "react";
import { useRootStore } from "../../../hooks";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import PageHeader from "../../modules/layouts/pageHeader/PageHeader";
import PageContent from "../../modules/layouts/pageContent/PageContent";
import CompanyEditForm from "../../modules/forms/companyEditForm/CompanyEditForm";

function SettingsPage() {
  const { t } = useTranslation();
  const { authStore } = useRootStore();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [text, setText] = useState<string>("true");

  return (
    <>
      <PageHeader title={t("common:page_title.settings_company")} />
      <PageContent isLoading={isLoading} tabs={authStore.getInitialInfo?.settingsTabs} isFilled>
        <div style={{ maxWidth: "444px", width: "100%" }}>
          <CompanyEditForm setLoading={setIsLoading} />
        </div>
      </PageContent>
    </>
  );
}

export default observer(SettingsPage);
