import React from "react";
import "../../components/ButtonIcons/RegulationButtonIcons.scss";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../../uiKit";
import { defineAllow } from "../../containers/regulation/helpers";
import { allowsRegulationKeys } from "../../misc/consts";
import { IRegulationViewHeader } from "./RegulationViewHeader.interface";
import { CancelButton } from "../../components/ButtonIcons/CancelButton";
import { SaveButton } from "../../components/ButtonIcons/SaveButton";
import { EditButton } from "../../components/ButtonIcons/EditButton";
import { AddToSection } from "../../components/ButtonIcons/AddToSection";
import { AccessSettingsButton } from "../../components/ButtonIcons/AccessSettingsButton";
import { DeleteButton } from "../../components/ButtonIcons/DeleteButton";
import { Breadcrumbs } from "../../../../../elements/breadcrumbs/Breadcrumbs";
import { breadcrumbsKeys } from "../../../../../elements/breadcrumbs/Breadcrumbs.interface";

export const RegulationViewHeader: React.FC<IRegulationViewHeader> = (props) => {
  const { t } = useTranslation();

  return (
    <div
      style={{ width: "100%", minHeight: "40px" }}
      className="px-6 full-width d-stack-row align-center justify-space-between"
    >
      <Breadcrumbs type={breadcrumbsKeys.regulation} />
      <div className="d-stack-row align-center justify-start spacing-4">
        {defineAllow(allowsRegulationKeys.reject, props.allowedActions) && (
          <Button variant="filled" loading={props.isLoadingRejectBtn} onClick={props.onReject}>
            {t("ui:button.reject")}
          </Button>
        )}
        {defineAllow(allowsRegulationKeys.publish, props.allowedActions) && (
          <Button variant="filled" loading={props.isLoadingApproveBtn} onClick={props.onPublish}>
            {t("ui:button.publish")}
          </Button>
        )}
        {defineAllow(allowsRegulationKeys.requestPublication, props.allowedActions) && (
          <Button loading={props.isLoadingSubmitApproval} variant="filled" onClick={props.onOpenSelectApproverDialog}>
            {t("ui:button.request_publication")}
          </Button>
        )}
        {defineAllow(allowsRegulationKeys.edit, props.allowedActions) && (
          <>
            {props.isEditable ? (
              <>
                <CancelButton onClick={props.onCancelEditable} />
                <SaveButton
                  loading={props.isLoadingSaveChangesBtn}
                  disabled={!props.hasDraftChanges}
                  onClick={props.onSaveDraftChanges}
                />
              </>
            ) : (
              <EditButton
                onClick={props.isEditable ? () => props.onChangeEditable(false) : () => props.onChangeEditable(true)}
              />
            )}
          </>
        )}
        {defineAllow(allowsRegulationKeys.edit, props.allowedActions) && props.hasActiveContent && (
          <AddToSection loading={props.isLoadingAddToSectionBtn} onClick={props.onOpenFindSectionDialog} />
        )}
        {defineAllow(allowsRegulationKeys.edit, props.allowedActions) && (
          <AccessSettingsButton onClick={props.onOpenAccessSettingsDialog} />
        )}
        {defineAllow(allowsRegulationKeys.delete, props.allowedActions) && (
          <DeleteButton loading={props.isLoadingDeleteBtn} onClick={props.onOpenDeleteRegulationDialog} />
        )}
      </div>
    </div>
  );
};
