import React, { useRef, useState } from "react";
import { DatePickerView } from "./DatePickerView";
import { PickerDateProps } from "antd/lib/date-picker/generatePicker";
import dayjs, { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";
import { Button } from "../../../general/button/Button";
import clsx from "clsx";

type TDatePicker = {
  // firstDayOfWeek?: number;
  disableFuture?: boolean;
  disableToday?: boolean;
  disablePast?: boolean;
  minDate?: Dayjs | null;
  maxDate?: Dayjs | null;
  readOnly?: boolean;
} & PickerDateProps<Dayjs>;

export function DatePicker({
  value,
  dropdownClassName,
  onChange,
  onBlur,
  onSelect,
  disabledDate,
  disableFuture,
  disableToday,
  disablePast,
  minDate,
  maxDate,
  readOnly,
  ...rest
}: TDatePicker) {
  const { t } = useTranslation();
  const currentValue = useRef<{
    value: Dayjs | undefined | null;
    valueString: string;
  }>({ value: null, valueString: "" });
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOpenChange = (val: boolean) => {
    if (!isOpen && val === true) {
      currentValue.current = {
        ...currentValue.current,
        value: value,
      };
      setIsOpen(val);
      rest.onOpenChange && rest.onOpenChange(true);
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    onBlur && onBlur(e);
    if (!isOpen) return;
    onChange && onChange(currentValue.current?.value ?? null, currentValue.current.valueString);
    setIsOpen(false);
    rest.onOpenChange && rest.onOpenChange(false);
    currentValue.current = {
      ...currentValue.current,
      value: null,
      valueString: "",
    };
  };

  const handleSelect = (val: Dayjs | null) => {
    currentValue.current = {
      ...currentValue.current,
      value: val,
      valueString: val?.format("L") ?? "",
    };
    onSelect && val != null && onSelect(val);
  };

  const handleChange = (val: Dayjs | null, dateString: string) => {
    currentValue.current = {
      ...currentValue.current,
      value: val,
      valueString: dateString,
    };
  };

  const handleCancel = () => {
    setIsOpen(false);
    rest.onOpenChange && rest.onOpenChange(false);
    currentValue.current = {
      ...currentValue.current,
      value: null,
      valueString: "",
    };
  };

  const checkIsDateDisabled = (date: Dayjs) => {
    if (disabledDate != null) return disabledDate(date);
    // disablePast, disableFuture, minDate, maxDate
    if (minDate == null && maxDate == null) {
      if (disablePast && disableFuture) return disableToday ? true : !date.isToday();
      if (disablePast && !disableToday) return date.isBefore(dayjs(), "date");
      if (disablePast && disableToday) return date.isSameOrBefore(dayjs(), "date");
      if (disableFuture && !disableToday) return date.isAfter(dayjs(), "date");
      if (disableFuture && disableToday) return date.isSameOrAfter(dayjs(), "date");
      if (!disablePast && !disableFuture && disableToday) return date.isToday();
    }
    if (minDate != null && maxDate != null) return !date.isBetween(minDate, maxDate, "day", "[]");
    if (minDate != null) return date.isBefore(minDate, "date");
    if (maxDate != null) return date.isAfter(minDate, "date");
    return false;
  };

  const getFormat = (val: Dayjs | null, showTime: any) => {
    if (val == null) return "";
    let format = "";
    if (showTime != null) {
      // HH:mm = LT, HH:mm:ss = LTS
      format =
        typeof showTime === "boolean" ? "L HH:mm:ss" : showTime?.format != null ? "L " + showTime.format : "L HH:mm:ss";
    } else {
      format = "L";
    }
    return val.format(format);
  };

  return rest.showTime != null ? (
    <DatePickerView
      {...rest}
      defaultPickerValue={value ?? undefined}
      dropdownClassName={clsx("uiKit-datePicker__dropdown", dropdownClassName ?? "", {
        __showTimeActive: !!rest.showTime,
      })}
      open={isOpen}
      onBlur={handleBlur}
      onOpenChange={handleOpenChange}
      onChange={onChange}
      // onChange={handleChange}
      value={value}
      disabledDate={checkIsDateDisabled}
      onSelect={handleSelect}
      renderExtraFooter={(panel) =>
        rest.showTime != null ? (
          <div className="px-2">
            <Button
              // disabled={currentValue.current == null}
              onClick={handleCancel}
              size="small"
              children={t("ui:button.cancel")}
            />
          </div>
        ) : null
      }
      format={(value) => getFormat(value, rest.showTime)}
    />
  ) : (
    <DatePickerView
      {...rest}
      dropdownClassName={dropdownClassName}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      disabledDate={checkIsDateDisabled}
      format={(value) => getFormat(value, rest.showTime)}
    />
  );
}
