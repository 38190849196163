import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { PlanDto } from "../../../../../../../api";
import { useApi, useRootStore } from "../../../../../../../hooks";
import useDebounce from "../../../../../../../hooks/useDebounce";
import { usePagingWithController } from "../../../../../../../hooks/usePaging";
import { api } from "../../../../../../../services";
import PageContent from "../../../../../layouts/pageContent/PageContent";
import { PlanningPageAdminCardsContent } from "../../../contents/planningPageAdminCardsContent/PlanningPageAdminCardsContent";
import { PlanningPageMenu } from "../../../contents/planningPageMenu/PlanningPageMenu";
import { IPlanningPageAdminWorkPlans } from "./PlanningPageAdminWorkPlans.interface";

export const PlanningPageAdminWorkPlans = (props: IPlanningPageAdminWorkPlans) => {
  const url = useLocation();
  const { authStore } = useRootStore();
  const userId = authStore.getInitialInfo?.identity?.id;
  const weekReportStart = authStore.getInitialInfo?.identity?.companies?.find(
    (u2c) => u2c.companyId == authStore.getInitialInfo?.identity?.currentCompanyId
  )?.company?.weekReportStart;
  const [searchedValue, setSearchedValue] = useState<string>("");
  const [filterKey, setFilterKey] = useState<number | undefined>(undefined);
  const [currentPlanId, setCurrentPlanId] = useState<number | undefined>(undefined);
  const searchDebounce = useDebounce(searchedValue, 400);

  const plansShort = usePagingWithController(
    api.planShort,
    { userId: userId, orderBy: "date_created", orderByType: "desc" },
    { pageSize: 10 }
  );

  const currentPlan = useApi<PlanDto | null>(
    () => api.plan.getById(currentPlanId!, { userId: userId, attachCanBeMigratedFromPlanId: true }),
    null,
    () => {}
  );

  const workPlans = usePagingWithController(
    api.plan,
    {
      userRelation: "subordinate",
      userName: searchedValue,
      status: filterKey,
      date: currentPlan.value?.dateFrom,
    },
    { pageSize: 10 }
  );

  const plansForApproval = usePagingWithController(
    api.plan,
    { forApproval: true, userName: searchedValue, status: filterKey },
    { pageSize: 10 }
  );

  const handleFilterKeyChange = (key: number) => {
    if (key == 0) {
      setFilterKey(undefined);
    } else {
      setFilterKey(key);
    }
  };

  const handlePlanSearch = (value: string) => {
    setSearchedValue(value);
  };

  const handlePlanIdChange = (planId: number) => {
    setCurrentPlanId(planId);
  };

  useEffect(() => {
    url.pathname == "/planning/work-plans" && plansShort.restart();
  }, [url.pathname]);

  useEffect(() => {
    if (plansShort.items.length) {
      setCurrentPlanId(plansShort.items.find((i) => i.isCurrent)?.id);
    }
  }, [plansShort.items]);

  useEffect(() => {
    currentPlan.fetch();
  }, [currentPlanId]);

  useEffect(() => {
    currentPlan.value && url.pathname == "/planning/work-plans" && workPlans.restart(true);
  }, [currentPlan.value, url.pathname, searchDebounce, filterKey]);

  useEffect(() => {
    url.pathname == "/planning/require-approval" && plansForApproval.restart(true);
  }, [url.pathname, searchDebounce, filterKey]);

  return (
    <>
      <PlanningPageMenu
        plans={url.pathname == "/planning/work-plans" ? workPlans.items : plansForApproval.items}
        onFilterKeyChange={handleFilterKeyChange}
        onPlanSearch={handlePlanSearch}
        searchValue={searchedValue}
        onPlanIdChange={handlePlanIdChange}
        currentPlanId={currentPlanId}
        weekReportStart={weekReportStart}
        plansShort={plansShort.items}
        userId={userId}
      />
      <PageContent
        isLoading={
          (plansForApproval.info.isLoading || workPlans.info.isLoading) &&
          (plansForApproval.items.length == 0 || workPlans.items.length == 0)
        }
      >
        {(plansForApproval.info.isLoading || workPlans.info.isLoading) &&
        (plansForApproval.items.length == 0 || workPlans.items.length == 0) ? null : (
          <PlanningPageAdminCardsContent
            isLoading={
              url.pathname == "/planning/work-plans" ? workPlans.info.isLoading : plansForApproval.info.isLoading
            }
            plans={url.pathname == "/planning/work-plans" ? workPlans.items : plansForApproval.items}
            isDone={url.pathname == "/planning/work-plans" ? workPlans.info.isDone : plansForApproval.info.isDone}
            loadNext={url.pathname == "/planning/work-plans" ? workPlans.loadNext : plansForApproval.loadNext}
          />
        )}
      </PageContent>
    </>
  );
};
