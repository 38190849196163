import React from "react";
import { FiPlus } from "@react-icons/all-files/fi/FiPlus";
import { IAddAnswerButton } from "./CreateTestsForm.interface";
import { Icon, IconButton } from "../../../uiKit";

export const AddAnswerButton: React.FC<IAddAnswerButton> = (props) => {
  return (
    <IconButton
      style={{ marginBottom: "16px", padding: "1px !important" }}
      className="create-tests-form__add-answer-btn"
      onClick={props.onClick}
      disabled={props.disabled}
      icon={<Icon component={() => <FiPlus fontSize="medium" />} />}
    />
  );
};
