import { AiOutlinePaperClip } from "@react-icons/all-files/ai/AiOutlinePaperClip";
import { useTranslation } from "react-i18next";
import FileUploader from "../../../../../service/fileUploader/FileUploader";
import { Button, Icon } from "../../../../../uiKit";
import { ToolbarType } from "../../../../textEditorOld/misc/consts";
import { TextEditor } from "../../../../textEditorOld/TextEditor";
import { IPlanHistoryEditor } from "./PlanHistoryModule.interface";
import React from "react";

export const PlanHistoryEditor = (props: IPlanHistoryEditor) => {
  const { t } = useTranslation();
  return (
    <>
      <TextEditor
        id="history-editor"
        placeholder={t("ui:placeholder.write_comment")}
        value={props.historyContent}
        ref={props.editorRef}
        onChange={props.onChangeCommentContent}
        toolbarType={ToolbarType.Hidden}
        height="100px"
      />
      <div className="d-stack-row justify-space-between align-center mb-2">
        <Button
          onClick={props.onCreateHistory}
          disabled={props.getIsDisabled()}
          style={{ marginTop: "8px" }}
          loading={props.isSendingLoading || props.isLoading}
          className="button-icon_17"
          variant="filled"
        >
          {t("ui:button.send")}
        </Button>
        <Button
          onClick={() => (props.uploadFilesInputRef?.current as any)?.click()}
          icon={<Icon component={() => <AiOutlinePaperClip />} />}
          style={{ fontSize: "14px", fontWeight: "14px" }}
          size="small"
          type="text"
        >
          {t("ui:button.attach")}
        </Button>
      </div>
      <FileUploader
        maxSizeMb={40}
        smallSize
        relatedInputId="comment-area-ctrlv-planning"
        value={props.attachments}
        onUpload={(data) => props.setAttachments(data)}
        uploadFilesInputRef={props.uploadFilesInputRef}
        filesContainerMaxHeight="35vh"
        onAnyItemDelete={props.onDeleteAttachmentById}
        onUploadStatusChange={props.setIsFileLoaderUploadingSync}
        hideInfo
      />
    </>
  );
};
