import React from "react";
import { useTranslation } from "react-i18next";
import "./PageContentLoader.scss";

function PageContentLoader() {
  const { t } = useTranslation();

  return (
    <div className="page-content-loader__wrapper d-stack-column justify-center align-center">
      <div className="ldsRollerAlt">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}

export default PageContentLoader;
