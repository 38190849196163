import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions } from "../../../uiKit";
import { ToolbarType } from "../../textEditorOld/misc/consts";
import { TextEditor } from "../../textEditorOld/TextEditor";
import { IPlanningRejectDialogView } from "./PlanningRejectDialog.interface";

export const PlanningRejectDialogView = forwardRef((props: IPlanningRejectDialogView, ref) => {
  const { t } = useTranslation();

  return (
    <Dialog title={t("ui:title.reject_plan")} open={props.isOpen} onClose={() => props.onOpeningChange(false)}>
      <TextEditor
        id="plan-reject-editor"
        placeholder={t("ui:placeholder.enter_a_comment")}
        value={props.reason}
        onChange={props.onReasonChange}
        ref={ref}
        toolbarType={ToolbarType.Hidden}
        height="100px"
      />
      <DialogActions>
        <Button onClick={props.onSuccess} type="primary" variant="text" loading={props.isLoadingButton}>
          {t("ui:button.decline")}
        </Button>
      </DialogActions>
    </Dialog>
  );
});
