import React from "react";
import ScheduleDayCardView from "./ScheduleDayCardView";
import { createSelectable, TSelectableItemProps } from "react-selectable-fast";
import { User2RoleDto } from "../../../../../api";

interface IScheduleDayCard {
  disabled: boolean;
  isSelectedByClick: boolean;
  text: string;
  type: number;
  timeZoneOffset: number | null;
  companyTimeZoneOffset: number | null;
  cardDate: Date;
  cardUser2Role: User2RoleDto;

  onContextMenu: (event: React.MouseEvent) => void;
}

function ScheduleDayCard(props: IScheduleDayCard & TSelectableItemProps) {
  return (
    <ScheduleDayCardView
      selectableRef={props.selectableRef}
      isSelected={props.isSelected}
      isSelecting={props.isSelecting}
      disabled={props.disabled}
      onContextMenu={props.onContextMenu}
      text={props.text}
      isSelectedByClick={props.isSelectedByClick}
      type={props.type}
      companyTimeZoneOffset={props.companyTimeZoneOffset}
      timeZoneOffset={props.timeZoneOffset}
      cardDate={props.cardDate}
    />
  );
}

export default createSelectable(ScheduleDayCard);
