import React from "react";
import { User2CompanyDto, UserContactDto, UserDto } from "../../../../api";
import { useTranslation } from "react-i18next";
import { useDateHelpers, useNotifier, useRootStore } from "../../../../hooks";
import { observer } from "mobx-react-lite";
import {
  Avatar,
  Button,
  Card,
  Dialog,
  DialogActions,
  Empty,
  Icon,
  IconButton,
  Link,
  Spin,
  Text,
  Tooltip,
} from "../../../uiKit";
import { parsePhoneNumber } from "awesome-phonenumber";
import { removeAllOccurrencesExceptFirstOne } from "../../../../helpers/stringFunctions";
import { FiAtSign, FiPhone, FiPhoneOutgoing } from "react-icons/all";
import dayjs from "dayjs";

interface IDriverExecutorAdminsDialogView {
  open: boolean;
  companies: User2CompanyDto[] | null;
  adminsArray: UserDto[];
  isLoading: boolean;

  onPhoneCopyClick: (phone: string) => void;
  onPhoneCallClick: (phone: string) => void;
  onEmailCopyClick: (email: string) => void;
  onClose(): void;
}

function DriverExecutorAdminsDialogView(props: IDriverExecutorAdminsDialogView) {
  const { t } = useTranslation();
  const { helperStore } = useRootStore();
  const dateHelpers = useDateHelpers();
  const notifier = useNotifier();

  const getContactLinkByType = (contact: UserContactDto | null) => {
    return contact?.deepLink ?? "/#";
    // let str2return = "";
    // switch (contact?.key) {
    //   case "t": // Telegram
    //     str2return = "https://t.me/" + contact.value;
    //     break;
    //   case "wa": // WhatsApp
    //     str2return = "https://wa.me/" + contact.value?.replace(/^\D+/g, "");
    //     break;
    //   case "v": // Viber
    //     str2return = "viber://chat?number=" + contact.value?.replace(/^\D+/g, "");
    //     break;
    // }
    // return str2return;
  };

  const copyLogin = async (event: any, login: string) => {
    // event.preventDefault();
    await navigator.clipboard
      .writeText(login)
      .then(() => notifier.show({ message: t("notifier:success.copy"), theme: "success" }))
      .catch(() => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" }));
  };
  //open={props.open}
  return (
    <Dialog title={t("parse:executor_admins")} open={props.open} onClose={props.onClose} scrollStrategy="dialogBody">
      {props.isLoading ? (
        <div className="d-flex justify-center align-center my-8">
          <Spin style={{ lineHeight: 1 }} size="large" />
        </div>
      ) : props.companies == null || props.companies?.length == 0 ? (
        <Empty className="my-4" />
      ) : (
        <div className="d-stack-column spacing-2">
          {props.companies?.map((u2c, index) => (
            <Card key={u2c.id} isShadowed={false} variant="secondary">
              <div className="d-stack spacing-3">
                <Avatar
                  src={props.adminsArray?.find((a) => a.id == u2c?.nearestAdminUserId)?.avatar?.image?.url}
                  color={props.adminsArray?.find((a) => a.id == u2c?.nearestAdminUserId)?.color}
                  text={[
                    props.adminsArray?.find((a) => a.id == u2c?.nearestAdminUserId)?.lastName,
                    props.adminsArray?.find((a) => a.id == u2c?.nearestAdminUserId)?.firstName,
                  ]}
                />
                <div className="flex-grow-1 d-stack-column">
                  <Text
                    weight={500}
                    children={
                      (props.adminsArray?.find((a) => a.id == u2c?.nearestAdminUserId)?.name ?? "").trim().length > 0
                        ? props.adminsArray?.find((a) => a.id == u2c?.nearestAdminUserId)?.name
                        : props.adminsArray?.find((a) => a.id == u2c?.nearestAdminUserId)?.nameFallback
                    }
                  />
                  <Text children={`${t("parse:company")}: ${u2c?.company?.name}`} />
                  <Text
                    children={`${t("parse:timezone")}: ${
                      helperStore.getTimezones.find(
                        (tz) =>
                          tz.value == props.adminsArray?.find((a) => a.id == u2c?.nearestAdminUserId)?.timeZoneFromUtc
                      )?.city
                    }, ${dateHelpers.formatDateString(
                      dayjs(new Date())
                        .add(new Date().getTimezoneOffset() / 60, "hour")
                        .add(
                          helperStore.getTimezones.find(
                            (tz) =>
                              tz.value ==
                              props.adminsArray?.find((a) => a.id == u2c?.nearestAdminUserId)?.timeZoneFromUtc
                          )?.value ?? 0,
                          "hour"
                        )
                        .toISOString(),
                      { month: "short" }
                    )}`}
                  />
                  <Text
                    children={`${t("parse:phone_number")}:
                      ${
                        (props.adminsArray?.find((a) => a.id == u2c?.nearestAdminUserId)?.phoneNumber ?? "").trim()
                          .length > 0
                          ? parsePhoneNumber(
                              removeAllOccurrencesExceptFirstOne(
                                "+" +
                                  ("+" + props.adminsArray?.find((a) => a.id == u2c?.nearestAdminUserId)?.phoneNumber ??
                                    ""),
                                "\\+"
                              )
                            ).number?.international
                          : t("parse:not_specified")
                      }`}
                  />
                  {(props.adminsArray?.find((a) => a.id == u2c?.nearestAdminUserId)?.contact?.name ?? "").trim()
                    .length > 0 && (
                    <div className="d-stack spacing-2 flex-nowrap align-center">
                      <Text children={t("ui:subheader.communication_type") + ": "} />
                      <Tooltip title={props.adminsArray?.find((a) => a.id == u2c?.nearestAdminUserId)?.contact?.value}>
                        <Link
                          href={getContactLinkByType(
                            props.adminsArray?.find((a) => a.id == u2c?.nearestAdminUserId)?.contact ?? null
                          )}
                          children={props.adminsArray?.find((a) => a.id == u2c?.nearestAdminUserId)?.contact?.name}
                        />
                      </Tooltip>
                    </div>
                  )}
                  {(props.adminsArray?.find((a) => a.id == u2c?.nearestAdminUserId)?.email ?? "").trim().length > 0 && (
                    <Link
                      href={`mailto:${props.adminsArray?.find((a) => a.id == u2c?.nearestAdminUserId)?.email}`}
                      children={props.adminsArray?.find((a) => a.id == u2c?.nearestAdminUserId)?.email}
                    />
                  )}
                </div>
                <div className="d-stack-column spacing-2">
                  {(props.adminsArray?.find((a) => a.id == u2c?.nearestAdminUserId)?.email ?? "").trim().length > 0 && (
                    <Tooltip placement="left" title={t("parse:copy_email") as string}>
                      <IconButton
                        size="small"
                        icon={<Icon component={() => <FiAtSign />} />}
                        onClick={() =>
                          props.onEmailCopyClick(
                            props.adminsArray?.find((a) => a.id == u2c?.nearestAdminUserId)?.email ?? ""
                          )
                        }
                      />
                    </Tooltip>
                  )}
                  {(props.adminsArray?.find((a) => a.id == u2c?.nearestAdminUserId)?.phoneNumber ?? "").trim().length >
                    0 && (
                    <Tooltip placement="left" title={t("parse:copy_number") as string}>
                      <IconButton
                        size="small"
                        icon={<Icon component={() => <FiPhone />} />}
                        onClick={() =>
                          props.onPhoneCopyClick(
                            props.adminsArray?.find((a) => a.id == u2c?.nearestAdminUserId)?.phoneNumber ?? ""
                          )
                        }
                      />
                    </Tooltip>
                  )}
                  {(props.adminsArray?.find((a) => a.id == u2c?.nearestAdminUserId)?.phoneNumber ?? "").trim().length >
                    0 && (
                    <Tooltip placement="left" title={t("parse:call") as string}>
                      <IconButton
                        size="small"
                        icon={<Icon component={() => <FiPhoneOutgoing />} />}
                        onClick={() =>
                          props.onPhoneCallClick(
                            props.adminsArray?.find((a) => a.id == u2c?.nearestAdminUserId)?.phoneNumber ?? ""
                          )
                        }
                      />
                    </Tooltip>
                  )}
                </div>
              </div>
            </Card>
          ))}
        </div>
      )}
      <DialogActions>
        <Button variant="filled" onClick={props.onClose}>
          {t("ui:button.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default observer(DriverExecutorAdminsDialogView);
