import React, { useRef } from "react";
import "./OrgchartDrawerTabMetrics.scss";
import { Button, Card, Text } from "../../../../../uiKit";
import { MetricCreateEditDialog } from "../../../../dialogs/metricCreateEditDialog/MetricCreateEditDialog";
import DeleteMetricOrDashboardDialog from "../../../../dialogs/deleteMetricOrDashboardDialog/DeleteMetricOrDashboardDialog";
import ScrollTrigger from "../../../../../service/scrollTrigger/ScrollTrigger";
import { useTranslation } from "react-i18next";
import { Metric } from "../../../metrics/Metric/Metric";
import { IOrgchartDrawerTabMetricsView } from "./OrgchartDrawerTabMetrics.interface";

export const OrgchartDrawerTabMetricsView = (props: IOrgchartDrawerTabMetricsView) => {
  const { t } = useTranslation();
  const resetRef = useRef<any>();

  const openCreateDialog = () => {
    resetRef.current.reset();
    props.handleOpenDialog(true);
  };

  return (
    <>
      <MetricCreateEditDialog
        ref={resetRef}
        open={props.openDialog}
        toggleDialog={props.handleOpenDialog}
        editMode={props.editMode}
        onSuccess={props.onSuccess}
        metricId={props.metricId}
        toggleEditMode={props.toggleEditMode}
        metrics={props.metrics}
      />
      <DeleteMetricOrDashboardDialog
        open={props.openDeleteDialog}
        onOpen={props.closeDeleteDialog}
        onDelete={() => props.deleteMetric(props.metricId)}
        title={t("ui:title.delete_metric")}
        description={""}
      />
      {props.metrics.length || props.isLoading ? (
        <div>
          <Button onClick={openCreateDialog} variant="filled" className="mb-2">
            {t("ui:button.create")}
          </Button>
          {props.metrics.map((m) => {
            return (
              <Metric id={m.id!} key={m.id} name={m.nameFormatted || ""} handleOpenDialog={props.handleOpenDialog} />
            );
          })}
          <ScrollTrigger
            fullWidth
            marginTop={!!props.metrics.length}
            disabled={props.isDone}
            hidden={props.isDone}
            onIntersection={props.onLoadNext}
          />
        </div>
      ) : (
        <Card variant="secondary" isShadowed={false} className="orgchart-drawer-tab_metrics-group_card">
          <div className="pa-4 d-stack-row justify-space-between align-center">
            <Text size="16px" children={t("ui:title.create_first_metric")} />
            <Button onClick={openCreateDialog} variant="filled" children={t("ui:button.create")} />
          </div>
        </Card>
      )}
    </>
  );
};
