import React from "react";
import { IUsersTooltip } from "../interfaces/Reactions.interface";
import { Tooltip } from "antd";

export const UsersTooltip: React.FC<IUsersTooltip> = (props) => {
  const getContent = (): JSX.Element => {
    const getPrefix = (index: number) => {
      if (props.users.length === 1 || !props.users.length) return null;
      else if (props.users.length && index !== props.users.length - 1) return ",";
    };

    return (
      <div className="d-stack-column spacing-1 justify-center align-start">
        {props.users.map((name, index) => (
          <span key={index}>
            {name} {getPrefix(index)}
          </span>
        ))}
      </div>
    );
  };

  return <Tooltip title={getContent()}>{props.children}</Tooltip>;
};

