import React, { forwardRef, useMemo } from "react";
import { Button, Dialog, DialogActions, Spin } from "../../../uiKit";
import { IIssueCommonViewDialog, IssueFormKeys, IssueFormType } from "./IssueCommonDialog.interface";
import { useTranslation } from "react-i18next";
import { getDialogConfig } from "./helpers";
import { CenterPageContentLayout } from "../../layouts/centerPageContentLayout/CenterPageContentLayout";
import { IssueCreateContainer } from "../../forms/issueCreateEditForms/containers/issueCreateContainer/IssueCreateContainer";
import { IssueUpdateContainer } from "../../forms/issueCreateEditForms/containers/issueUpdateContainer/IssueUpdateContainer";
import { IssueTypeKeys } from "../../../../constants/issueTypeKeys";

const guideIcon = (issueType: IssueTypeKeys, formType: IssueFormType) => {
  return null;
};

export const IssueCommonViewDialog = forwardRef((props: IIssueCommonViewDialog, ref) => {
  const { t } = useTranslation();
  const dialogConfig = getDialogConfig(t, props.formType, props.issueType);
  const isCreateMod = useMemo(() => props.formType === IssueFormKeys.create, [props.formType]);

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      scrollStrategy="dialogBody"
      closable={isCreateMod}
      width={dialogConfig.dialogWidth}
      bodyStyle={{
        overflow: !isCreateMod ? "hidden" : "auto",
        // paddingTop: isCreateMod ? 24 : 0,
        maxHeight: "90vh",
      }}
      className="issue-common-dialog"
      title={
        isCreateMod ? (
          <div className="d-flex align-center">
            {dialogConfig.title} <span className="px-1" /> {guideIcon(props.issueType, props.formType)}
          </div>
        ) : undefined
      }
      //bodyStyle={{ overflow: `${isCreateMod ? "auto" : "hidden"}`, paddingTop: isCreateMod ? 24 : 20 }}
    >
      {props.isLoading ? (
        <div style={{ minHeight: 600, display: "flex", justifyContent: "center", alignItems: "center" }}>
          <CenterPageContentLayout horizontal>
            <Spin size="large" />
          </CenterPageContentLayout>
        </div>
      ) : (
        <>
          {props.formType === IssueFormKeys.create && props.updater.currentState && (
            <IssueCreateContainer
              updater={props.updater}
              onCreate={props.onCreate}
              onIssueReload={props.onIssueReload}
              isLoading={props.isLoading}
              customFields={props.customFields}
              issueType={props.issueType}
              ref={ref}
            />
          )}
          {props.formType === IssueFormKeys.update && (
            <IssueUpdateContainer
              isLoading={props.isLoading}
              title={dialogConfig.title}
              issueId={props.issueId!}
              issueType={props.issueType}
              updater={props.updater}
              onClose={props.onClose}
              onIssueReload={props.onIssueReload}
              onRedirectToOtherIssue={props.onReopen!}
            />
          )}
        </>
      )}
      {props.formType === IssueFormKeys.create && (
        <DialogActions>
          <>
            <Button onClick={props.onClose} type="text">
              {t("ui:button.cancel")}
            </Button>
            <Button loading={props.createBtnIsLoading} onClick={props.onClickCreate} type="primary">
              {"creteBtnText" in dialogConfig ? dialogConfig.creteBtnText : ""}
            </Button>
          </>
        </DialogActions>
      )}
    </Dialog>
  );
});
