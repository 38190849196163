import React from "react";
import { StyledElementProps, Value } from "@udecode/plate";
import { TElement } from "@udecode/plate-core";

export interface TAnchorElement extends TElement {
  anchorId?: string;
}

export const AnchorElement = <V extends Value>(props: StyledElementProps<Value, TAnchorElement>) => {
  const { attributes, children, element, nodeProps } = props;
  return (
    <div
      {...attributes}
      {...nodeProps}
      className="pa-1 align-center d-stack-row justify-start"
      id={element.anchorId}
      style={
        nodeProps?.readOnly
          ? {
              height: "1px",
            }
          : {
              backgroundColor: "var(--color-text-weak)",
              color: "#fff",
              height: "25px",
              width: "100%",
              borderRadius: "var(--border-radius-sm)",
            }
      }
      contentEditable={false}
    >
      {!nodeProps?.readOnly && element.anchorId}
      {children}
    </div>
  );
};
