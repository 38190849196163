import { FallbackProps } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { Button, Text } from "../../../uiKit";

export function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
  const { t } = useTranslation();

  function refreshPage() {
    window.location.reload();
  }
  return (
    <>
      <Text>{t("text:editor_callstack_error")}</Text>
      {/* <pre>{error.message}</pre> */}
      <Button onClick={refreshPage} style={{ cursor: "pointer" }}>
        {t("ui:button.ok")}
      </Button>
    </>
  );
}
