import React from "react";
import "./Pages.scss";
import "./RegulationHeader.scss"
import {IRegulationRouter} from "./RegulationRouter.interface";
import { breadcrumbKeys } from "../misc/breadcrumbsKeys";
import {RegulationUserStats,CreateRegulation,CreateSection,Regulation,Section,MainRegulation} from "../containers"
import Error404Page from "../../../../pages/error404/Error404Page";

export const RegulationRouter : React.FC<IRegulationRouter> = (props) => {
  const handleGetPageByBreadcrumbKey = (): JSX.Element => {
    switch (props.routeToken!.key) {
      case breadcrumbKeys.createRegulation:
        return <CreateRegulation />;
      case breadcrumbKeys.createSection:
        return <CreateSection />;
      case breadcrumbKeys.sectionView:
        return <Section />;
      case breadcrumbKeys.regulationView:
        return <Regulation />;
      case breadcrumbKeys.regulationTest:
        return <Regulation isTestPass />;
      case breadcrumbKeys.statsOverall:
        return <RegulationUserStats />;
      case breadcrumbKeys.statsUser:
        return <RegulationUserStats userStats />;
      default:
        return <Error404Page />;
    }
  };

  return (
    <>
      {props.routeToken && !props.routeToken.isMainPage
        ? handleGetPageByBreadcrumbKey()
        : !props.noTabs && <MainRegulation tabs={props.tabs}  />}
    </>
  );
};

