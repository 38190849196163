import React, { useRef } from "react";
import "./PageContent.scss";
import { MenuItemDto } from "../../../../api";
import { observer } from "mobx-react-lite";
import PageTabs from "../settingsPageTabs/pageTabs";
import clsx from "clsx";
import { Card, Spin } from "../../../uiKit";
import { useScrollContainer } from "../../../../hooks";
import PageMap from "../../../service/pageMap/PageMap";

class AppPageTabType {}

interface IPageContentPosition {
  maxWidth?: string;
  position?: "start" | "center" | "end";
}

interface IPageContentMap {
  elements?: string[];
}

interface IPageContent {
  className?: string;
  isFilled?: boolean;
  tabs?: MenuItemDto[] | AppPageTabType[] | null;
  zoom?: number;
  isZoomable?: boolean;
  isDraggable?: boolean;
  zoomProperty?: "transform" | "zoom";
  isLoading?: boolean;
  isContentCentered?: boolean;
  disablePadding?: boolean;
  useTranslationInTabs?: boolean;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  contentPosition?: IPageContentPosition;
  pageMap?: IPageContentMap;
}
// Add pageMap

function PageContent(props: IPageContent) {
  const dragRef = useRef<HTMLDivElement | null>(null);
  // @ts-ignore
  const scrollContainer = useScrollContainer();

  const getNormalizedNumber = (n: number) => Number(((Number(n) ?? 1) * 100).toFixed(1));

  return (
    <>
      {props.tabs && props.tabs.length > 0 && (
        <PageTabs useTranslation={!!props.useTranslationInTabs} tabs={props.tabs} />
      )}
      {props.pageMap != null && window.location?.hostname == "localhost" && <PageMap />}
      <Card
        bodyStyle={{
          position: "relative",
          height: "100%",
          overflow: "hidden",
          padding: 0,
          borderRadius: !props.isFilled ? 0 : undefined,
        }}
        className={clsx("page-content-sheet_main__wrapper", "flex-grow-1", { __transparent: !props.isFilled })}
        style={{
          maxWidth: props.contentPosition?.maxWidth,
          width: "100%",
          marginLeft:
            props.contentPosition?.position == "center" ||
            props.contentPosition?.position == "end" ||
            props.contentPosition?.position == null
              ? "auto"
              : undefined,
          marginRight:
            props.contentPosition?.position == "center" ||
            props.contentPosition?.position == "start" ||
            props.contentPosition?.position == null
              ? "auto"
              : undefined,
        }}
      >
        <Spin
          className="page-content-sheet_main__loader__content"
          wrapperClassName="page-content-sheet_main__loader__wrapper"
          spinning={props.isLoading == true}
          size="large"
          // tip={`Loading\ndata`}
        >
          <div
            ref={props.isDraggable ? scrollContainer.ref : undefined}
            style={props.style}
            className={clsx(
              "page-content-sheet_main__content",
              props.className ?? "",
              props.zoom != null
                ? `__scale-${props.zoomProperty == "zoom" ? "z" : "t"}-${getNormalizedNumber(Number(props.zoom))}`
                : "",
              {
                __centered: props.isContentCentered,
              }
            )}
          >
            {props.children}
          </div>
        </Spin>
      </Card>
    </>
  );
}

export default observer(PageContent);
