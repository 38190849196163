import React, { useContext, useEffect, useState } from "react";
import OrgchartFunctionDrawerView from "./OrgchartFunctionDrawerView";
import { RoleDto } from "../../../../../api";
import { useTranslation } from "react-i18next";
import { AppTabType } from "../../../../../utils/appLinksAndTabs";
import { useApi, useRootStore } from "../../../../../hooks";
import { api } from "../../../../../services";
import OrgchartDrawerTabRecurringTasks from "../orgchartDrawerTabs/orgchartDrawerTabRecurringTasks/OrgchartDrawerTabRecurringTasks";
import OrgchartDrawerTabEmployee from "../orgchartDrawerTabs/orgchartDrawerTabEmployee/OrgchartDrawerTabEmployee";
import OrgchartDrawerTabPermissions from "../orgchartDrawerTabs/orgchartDrawerTabPermissions/OrgchartDrawerTabPermissions";
import { OrgchartContext } from "../../../../../contexts/orgchartContext";
import { OrgchartDrawerTabMetrics } from "../orgchartDrawerTabs/orgchartDrawerTabMetrics/OrgchartDrawerTabMetrics";
import { sectionKeys } from "../../../../../constants/sectionKeys";
import { OrgchartDrawerTabRegulations } from "../orgchartDrawerTabs/orgchartDrawerTabRegulations/OrgchartDrawerTabRegulations";
import { useUpdateWithController } from "../../../../../hooks/useUpdate";

export interface IOrgchartFunctionDrawer {
  roleId: number;
  allRoles: RoleDto[];
  open: boolean;

  onClose(): void;
}

export interface IOrgChartDrawerTabs extends AppTabType {
  requireToCheckAllowKey?: sectionKeys;
  count?: number;
}

const OrgchartDrawerTabRecurringTasksFunc = (props: any) => {
  return <OrgchartDrawerTabRecurringTasks {...props} />;
};

const OrgchartDrawerTabEmployeeFunc = (props: any) => {
  return <OrgchartDrawerTabEmployee {...props} />;
};

const OrgchartDrawerTabPermissionsFunc = (props: any) => {
  return <OrgchartDrawerTabPermissions {...props} />;
};

const OrgchartDrawerTabMetricsFunc = (props: any) => {
  return <OrgchartDrawerTabMetrics {...props} />;
};

const OrgchartDrawerTabRegulationsFunc = (props: any) => {
  return <OrgchartDrawerTabRegulations {...props} />;
};

function OrgchartFunctionDrawer(props: IOrgchartFunctionDrawer) {
  const { t } = useTranslation();
  const { authStore, orgchartStore } = useRootStore();
  const orgchartContext = useContext(OrgchartContext);
  const [currentTabId, setCurrentTabId] = useState(0);
  const [isDrawerClosable, setIsDrawerClosable] = useState(true);
  const [isDrawerCloseDialogOpen, setIsDrawerCloseDialogOpen] = useState(false);
  const [changeSubfunctionsColors, setChangeSubfunctionsColors] = useState(true);

  const reccuringTask = useApi(() =>
    api.recurringIssue.getAll({
      roleId: (props.roleId as number) ?? undefined,
      orgchartId: orgchartStore.getCurrentOrgchartId,
    })
  );

  const drawerTabs: IOrgChartDrawerTabs[] = [
    {
      id: 0,
      name: t("common:tab.orgchart.recurring_tasks"),
      element: OrgchartDrawerTabRecurringTasksFunc,
      requireToCheckAllowKey: sectionKeys.communications,
      count: reccuringTask.value?.totalItems,
    },
    {
      id: 1,
      name: t("common:tab.orgchart.employee"),
      element: OrgchartDrawerTabEmployeeFunc,
    },
    {
      id: 2,
      name: t("common:tab.orgchart.permissions"),
      element: OrgchartDrawerTabPermissionsFunc,
    },
    {
      id: 3,
      name: t("common:tab.orgchart.metrics"),
      element: OrgchartDrawerTabMetricsFunc,
      requireToCheckAllowKey: sectionKeys.dashboards,
    },
    {
      id: 4,
      name: t("common:tab.orgchart.regulations"),
      element: OrgchartDrawerTabRegulationsFunc,
      requireToCheckAllowKey: sectionKeys.policies,
    },
  ];

  const handleFilterAllowsTabs = (drawerTabs: IOrgChartDrawerTabs[]): IOrgChartDrawerTabs[] => {
    const checkAllow = (allowKey: sectionKeys): boolean =>
      !!authStore.getInitialInfo?.menuItems?.find((item) => item.key === allowKey);
    const filteredTabs = drawerTabs?.filter(
      (item) => !item.requireToCheckAllowKey || checkAllow(item.requireToCheckAllowKey)
    );
    if (!filteredTabs.some((fT) => fT.id == currentTabId)) {
      setCurrentTabId(filteredTabs?.[0]?.id);
    }
    return filteredTabs;
  };

  const functionData = useApi(
    () => api.role.getById(props.roleId, { includeClaims: true }),
    () => {},
    (x) => updater.setInitialState(x!)
  );

  const updater = useUpdateWithController(
    api.role,
    () => {
      orgchartContext?.refreshState();
      functionData.fetch();
    },
    { includeClaims: true }
  );
  const initDrawerLoading = async () => {
    await functionData.fetch();
  };

  const initDrawerReset = async () => {
    setTimeout(() => {
      setCurrentTabId(0);
      setIsDrawerClosable(true);
      functionData.set(null);
      updater.setInitialState(undefined as any);
    }, 300);
  };

  const onTabChange = async (id: number) => {
    setCurrentTabId(id);
    // await functionData.fetch()
  };

  useEffect(() => {
    if (props.open) {
      updater.setInitialState((props.allRoles?.find((r) => r.id == props.roleId) ?? undefined) as any);
      initDrawerLoading();
    } else {
      initDrawerReset();
    }
  }, [props.open]);

  const handleFunctionColorSave = async (color: string) => {
    // updater.update()
    const r = await api.role.edit(props.roleId, {
      ...updater.currentState,
      colorHex: color,
      applyColorToChildren: changeSubfunctionsColors,
    });
    if (r == null) return;
    updater.setInitialState(r);
    orgchartContext?.refreshState();
    setChangeSubfunctionsColors(true);
    // functionData.set({});
  };

  const handleFunctionChildrenDirectionSave = async (direction: "h" | "v") => {
    const r = await api.role.editPartially(
      props.roleId,
      { ...updater.currentState, childrenAreHorizontal: direction == "h" },
      { ...updater.currentState }
    );
    if (r == null) return;
    // TODO: Rewrite
    // updater.setInitialState(r);
    orgchartContext?.refreshState();
    await functionData.fetch();
  };

  return (
    <OrgchartFunctionDrawerView
      {...props}
      tabs={handleFilterAllowsTabs(drawerTabs)}
      currentTabId={currentTabId}
      onTabChange={onTabChange}
      updater={updater}
      functionData={functionData.value as RoleDto}
      refreshData={functionData.fetch}
      setFunctionData={functionData.set}
      isDrawerClosable={isDrawerClosable}
      setIsDrawerClosable={setIsDrawerClosable}
      isDrawerCloseDialogOpen={isDrawerCloseDialogOpen}
      setIsDrawerCloseDialogOpen={setIsDrawerCloseDialogOpen}
      handleFunctionColorSave={handleFunctionColorSave}
      handleFunctionChildrenDirectionSave={handleFunctionChildrenDirectionSave}
      changeSubfunctionsColors={changeSubfunctionsColors}
      setChangeSubfunctionsColors={setChangeSubfunctionsColors}
    />
  );
}

export default OrgchartFunctionDrawer;
