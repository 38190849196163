import React from "react";
import "./SubheaderText.scss";
import { ISubheaderText } from "./SubheaderText";

function SubheaderTextView(props: ISubheaderText) {
  return (
    <span
      style={{ marginBottom: !!props.noBottomOffset ? "0" : "8px" }}
      className={"subheader-text " + (props.className ?? "")}
    >
      {props.text}
    </span>
  );
}

export default SubheaderTextView;
