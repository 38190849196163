import React from "react";
import { useTranslation } from "react-i18next";
import { EditableTypography } from "../../../../../elements/editableTypography/EditableTypography";
import { UserCard } from "../../../../../elements/userCard/UserCard";
import {IRegulationContentHeader} from "./RegulationContentHeader.interface";
import { useDateHelpers } from "../../../../../../hooks";

export const RegulationContentHeader: React.FC<IRegulationContentHeader> = (props) => {
  const { t } = useTranslation();

  const { formatDateString } = useDateHelpers();

  const dateCreated = formatDateString(new Date(props.dateCreated!).toString(), {
    hour: undefined,
    minute: undefined,
  });

  return (
    <>
      <EditableTypography multiline big value={props.isEditable ? props.draftTitle : props.currentTitle} disabled={!props.isEditable} onChange={props.onChangeTitle} />
      <div className="d-stack-row spacing-4 justify-space-between align-center mt-2">
        <UserCard
          userInfo={props.createdByUser}
          avatarSize={35}
          additionalInfo={`${t("parse:created_in")} ${dateCreated}`}
          boldName
        />
      </div>
    </>
  );
};
