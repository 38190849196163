import React from "react";
import { useTranslation } from "react-i18next";
import { RiUserSettingsLine } from "@react-icons/all-files/ri/RiUserSettingsLine";
import { EditableTypography } from "../../../elements/editableTypography/EditableTypography";
import { ICreateRegulatiomFormView } from "./CreateRegulatiomForm.interface";
import { CreateTestsForm } from "../createTestsForm/CreateTestsForm";
import { TextEditor } from "../../textEditorOld/TextEditor";
import { Button, Card, Icon } from "../../../uiKit";

export const CreateRegulationFormView: React.FC<ICreateRegulatiomFormView> = (props, ref) => {
  const { t } = useTranslation();

  return (
    <div className="create-regulation-form">
      <div
        className="d-stack-row justify-end align-center"
        style={{ paddingLeft: "24px", paddingRight: "0px", marginBottom: 3 }}
      >
        <Button onClick={props.onOpenAccessSettingsDialog} icon={<Icon component={() => <RiUserSettingsLine />} />}>
          {t("ui:button.access_setting")}
        </Button>
      </div>
      <EditableTypography
        value={props.regulationTitle}
        onChange={props.onChangeRegulationTitle}
        error={props.titleError}
        placeholder={t("ui:placeholder.regulation_name1")}
      />

      <Card className="mt-2" bordered bodyStyle={{ padding: 0 }}>
        <TextEditor
          placeholder={t("ui:placeholder.start_write_regulation")}
          value={props.regulationContent}
          onChange={props.onChangeRegulationContent}
        />
        {/*<RichTextEditor*/}
        {/*  placeholder={t("ui:placeholder.start_write_regulation")}*/}
        {/*  value={props.regulationContent}*/}
        {/*  onChange={props.onChangeRegulationContent}*/}
        {/*/>*/}
      </Card>
      <div className="d-stack-row spacing-3 justify-space-between align-start" style={{ marginTop: "8px" }}>
        <div style={{ width: "80%" }}>
          {props.isOpenCreatingTest && (
            <CreateTestsForm
              regulationContentId={0}
              ref={props.testRef}
              onClose={props.onCloseTest}
              // onSave={props.onSaveTest}
            />
          )}
          {!props.isOpenCreatingTest && (
            <Button variant="filled" onClick={props.isOpenCreatingTest ? props.onCloseTest : props.onOpenTest}>
              {props.test.length ? t("ui:button.edit_test") : t("ui:button.add_testing")}
            </Button>
          )}
        </div>
        <div className="d-stack-row justify-end align-center spacing-2">
          <Button
            onClick={props.onCancel}
            variant="filled"
            className="button-icon_16"
            // endIcon={<FiUpload />}
          >
            {t("ui:button.cancel")}
          </Button>
          <Button
            loading={props.isLoading}
            onClick={() => props.onSave()}
            variant="filled"
            className="button-icon_16"
            // endIcon={<FiUpload />}
          >
            {t("ui:button.save")}
          </Button>
        </div>
        {/*<Button*/}
        {/*  loading={props.isLoading}*/}
        {/*  onClick={() => props.onSave()}*/}
        {/*  variant="filled"*/}
        {/*  className="button-icon_16"*/}
        {/*  // endIcon={<FiUpload />}*/}
        {/*>*/}
        {/*  {t("ui:button.save")}*/}
        {/*</Button>*/}
      </div>
    </div>
  );
};
