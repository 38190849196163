import { StyledElementProps, Value } from "@udecode/plate";
import { TElement } from "@udecode/plate-core";

export interface TIFrameElement extends TElement {
  src?: string;
}

export const IFrameElement = <V extends Value>(props: StyledElementProps<Value, TIFrameElement>) => {
  const { attributes, children, element, nodeProps } = props;

  return (
    <iframe
      title="video"
      allowFullScreen={true}
      width={500}
      height={300}
      frameBorder={0}
      marginHeight={0}
      src={element.src}
      className="d-flex align-center justify-center"
      style={{ margin: "0 auto" }}
      {...attributes}
      contentEditable={false}
    >
      {children}
    </iframe>
  );
};
