import React from "react";
import "./FilterButton.scss";
import { IFilterMenu } from "./FilterButton.interface";
import { observer } from "mobx-react-lite";
import { BsFilter } from "@react-icons/all-files/bs/BsFilter";
import { Icon, IconButton, Popover } from "../../uiKit";

const FilterButtonObserver = (props: IFilterMenu) => {
  return (
    <>
      <Popover content={props.dropdownContent} placement="bottom" arrowPointAtCenter={false} trigger="click">
        <IconButton children={<Icon component={() => <BsFilter color={"var(--color-primary-base)"} />} />} />
        {/*<Button*/}
        {/*  className="filter-communications-button"*/}
        {/*  variant="default"*/}
        {/*  icon={<BsFilter style={{ margin: "0 auto" }} size="20px" className="filter-communications-icon" />}*/}
        {/*/>*/}
      </Popover>
    </>
  );
};

export const FilterButton = React.memo(observer(FilterButtonObserver));
