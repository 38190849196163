import React, { useEffect, useRef, useState } from "react";
import { AutocompleteAsyncView } from "./AutocompleteAsyncView";
import { IApiControllerGet } from "../../../api/interfaces/iApiControllerGet";
import { usePagingWithController } from "../../../hooks/usePaging";
import { api } from "../../../services";
import { SelectProps } from "antd";
import useDebounce from "../../../hooks/useDebounce";
import { AutocompleteModel, UserAvatarDto } from "../../../api";
import { useDateHelpers, useNotifier, useRootStore } from "../../../hooks";
import { areObjectsEqual, cloneObject, removeDuplicatesFromArrayByKey } from "../../../helpers/arrayFunctions";
import { useTranslation } from "react-i18next";
import { getUserShortFullName } from "../../../helpers/stringFunctions";
import dayjs from "dayjs";
import { formatDateToDateString } from "../../../helpers/formatFunctions";
import { observer } from "mobx-react-lite";

export type TAutocompleteAsync =
  | "user"
  | "company"
  | "adminCompany"
  | "role"
  | "rolePosition"
  | "metricSource2User"
  | "metricSource"
  | "metric"
  | "plan";

interface IAutocompleteAsync extends SelectProps {
  type: TAutocompleteAsync;
  requestOptions?: { [key: string]: any };
  searchParamKey?: string;
  additionalItems?: (AutocompleteModel & {
    avatar?: UserAvatarDto;
    color?: string;
    avatarText?: string | null | (string | undefined | null)[];
    disabled?: boolean;
  })[];
  idsToFilter?: number[] | string[];
  minOptionsCount?: number;
}

function AutocompleteAsyncObserved(props: IAutocompleteAsync) {
  const notifier = useNotifier();
  const { authStore, devStore } = useRootStore();
  const companyUiType = authStore.getCurrentCompanyUiType;
  const { t } = useTranslation();
  const dateHelpers = useDateHelpers();
  const [searchString, setSearchString] = useState<string>("");
  const [requestOptions, setRequestOptions] = useState<{ [key: string]: any }>({});
  const debouncedSearchString = useDebounce(searchString, 400);
  // const promisesList = useSequentialPromises();
  const [additionalState, setAdditionalState] = useState(props.additionalItems ?? []);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [items, setItems] = useState<
    (AutocompleteModel & {
      avatar?: UserAvatarDto;
      color?: string;
      isCurrent?: boolean;
      avatarText?: string | null | (string | undefined | null)[];
      disabled?: boolean;
    })[]
  >(props.additionalItems ?? []);
  const itemsCopy = useRef<
    (AutocompleteModel & {
      avatar?: UserAvatarDto;
      color?: string;
      isCurrent?: boolean;
      avatarText?: string | null | (string | undefined | null)[];
      disabled?: boolean;
    })[]
  >(props.additionalItems ?? []);
  const [value, setValue] = useState([]);

  const setItemsSync = (
    v: (AutocompleteModel & {
      avatar?: UserAvatarDto;
      color?: string;
      isCurrent?: boolean;
      avatarText?: string | null | (string | undefined | null)[];
      disabled?: boolean;
    })[]
  ) => {
    setItems(v);
    itemsCopy.current = v;
  };

  const getAutocompleteControllerByType = (): IApiControllerGet<any, {}> => {
    switch (props.type) {
      case "user":
        return api.userShort;
      case "company":
        return api.company;
      case "adminCompany":
        return api.adminCompany;
      case "role":
        return api.role;
      case "rolePosition":
        return api.rolePosition;
      case "metric":
        return api.metric;
      case "metricSource2User":
        return api.metricSource2User;
      case "metricSource":
        return api.metricSource;
      case "plan":
        return api.planShort;
      default:
        throw Error("No suitable autocomplete type was found");
    }
  };

  // ) as AutocompleteModel[]

  const autocompletePaging = usePagingWithController(
    getAutocompleteControllerByType(),
    { ...props.requestOptions, name: searchString },
    { pageSize: props.type == "user" || props.type == "plan" ? 20 : 1000 },
    undefined,
    undefined,
    undefined,
    props.type != "user" && props.type != "plan" && props.type != "metricSource" && props.type != "rolePosition"
  );
  // TODO: Проверять тип value и возвращать такой же

  const getUserName = (lN: string, mN: string, fN: string, id: number) => {
    const n = getUserShortFullName(lN, fN, mN).trim();
    return n.length == 0 || (lN ?? "").trim().length == 0 || (fN ?? "").trim().length == 0
      ? t("ui:misc.new_user") + " #" + id
      : n;
  };

  useEffect(() => {
    setItemsSync(
      getUniqueElementsById(
        [
          ...autocompletePaging.items.map((i) => {
            // return
            const generateText = () => {
              switch (props.type) {
                case "user":
                  return getUserName(i.lastName, i.middleName, i.firstName, i.id as number);
                case "plan":
                  return `${formatDateToDateString(
                    dayjs(i.dateFrom)
                      .add(new Date().getTimezoneOffset() / 60, "hour")
                      .add(
                        authStore.getInitialInfo?.identity?.companies?.find(
                          (c) => c.companyId == authStore.getInitialInfo?.identity?.currentCompanyId
                        )?.company?.timeZoneFromUtc ?? 0,
                        "hour"
                      )
                      .toDate()
                  )} - ${formatDateToDateString(
                    dayjs(i.dateTo)
                      .add(new Date().getTimezoneOffset() / 60, "hour")
                      .add(-1, "minute")
                      .add(
                        authStore.getInitialInfo?.identity?.companies?.find(
                          (c) => c.companyId == authStore.getInitialInfo?.identity?.currentCompanyId
                        )?.company?.timeZoneFromUtc ?? 0,
                        "hour"
                      )
                      .toDate()
                  )}`;
                case "metricSource":
                  return `${i.metric.name} - ${i.metric.rolePosition.role.name}`;
                case "rolePosition":
                  // return companyUiType == 2 ? `${i.roleName}` : `${i.roleName} - ${i.name}`;
                  return `${i.roleName} - ${i.name}`;
                default:
                  return i.text;
              }
            };
            const generateAvatarText = () => {
              if (props.type == "user") return [i.lastName, i.firstName];
              return i.name ?? i.text;
            };
            return {
              id: i.id,
              text: generateText(),
              avatar: i.avatar ?? undefined,
              avatarText: generateAvatarText(),
              color: i.color ?? undefined,
              isCurrent: i.isCurrent ?? undefined,
              companyIntervalId: i.companyIntervalId ?? undefined,
              disabled: i.disabled ?? false,
            };
          }),
          ...(props.additionalItems ?? []),
        ].filter((x) => !(props.idsToFilter ?? []).some((y) => y == x.id))
      )
    );
  }, [autocompletePaging.items, props.idsToFilter]);

  const getUniqueElementsById = (
    objects: (AutocompleteModel & {
      avatar?: UserAvatarDto;
      color?: string;
      isCurrent?: boolean;
      avatarText?: string | null | (string | undefined | null)[];
      disabled?: boolean;
    })[]
  ) =>
    objects.filter((value, index, self) => {
      return self.findIndex((v) => v.id === value.id) === index;
    });

  useEffect(() => {
    loadLostData();
  }, [props.value]);

  const loadLostData = async () => {
    const itemsToLoad = [props.value]
      .flat()
      .filter((x) => !itemsCopy.current.includes(x))
      .filter((y) => y != null);
    // const itemsToLoad = itemsCopy.current
    //   .filter(
    //     (item) =>
    //       ![props.value]
    //         .flat()
    //         .map((v) => v.id)
    //         .filter((v) => v != null)
    //         .includes(item.id)
    //   )
    //   .map((v) => v.id);
    setIsLoading(true);
    for await (const id of itemsToLoad) {
      if (itemsCopy.current.some((i) => i.id == id)) {
        break;
      }
      const r = await getAutocompleteControllerByType().getById(Number(id));
      if (r == null) {
        if (props.type != "plan" && props.type != "metricSource" && props.type != "metricSource2User") {
          notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
        }
        break;
      }
      const generateAvatarText = () => {
        if (props.type == "user") return [r.lastName, r.firstName];
        return r.name ?? r.text;
      };
      setItemsSync(
        getUniqueElementsById([
          ...itemsCopy.current,
          {
            id: r.id,
            text: props.type == "user" ? getUserName(r.lastName, r.middleName, r.firstName, r.id as number) : r.text,
            avatarText: generateAvatarText(),
            avatar: r.avatar ?? undefined,
            color: r.color ?? undefined,
            disabled: r.disabled ?? false,
          },
        ])
      );
    }
    setIsLoading(false);
  };

  // const loadInfo = async () => {
  //   //
  // }

  useEffect(() => {
    autocompletePaging.reset();
    autocompletePaging.restart();
  }, [debouncedSearchString, requestOptions]);

  useEffect(() => {
    const clonePropsReqOpts = cloneObject(props.requestOptions);
    // без этой штуки этот юзэффект вызвается рекурсивно с утечкой памяти, потому что в objectsIsEqual изменяется props.requestOptions
    if (!areObjectsEqual(requestOptions, clonePropsReqOpts)) setRequestOptions(props.requestOptions ?? {});
  }, [props.requestOptions]);

  useEffect(() => {
    // TODO: Добавить подгрузку отсутсвующих айтемов
    // autocompletePaging.reset();
    setItemsSync(getUniqueElementsById([...itemsCopy.current, ...(props.additionalItems ?? [])]));
  }, [props.additionalItems]);

  const handleSearch = (str: string) => {
    setSearchString(str);
  };

  const handleChange = (value: any) => {
    props.onChange && props.onChange(value, itemsCopy.current?.find((i) => i.id == value) as any);
  };

  return (
    <AutocompleteAsyncView
      {...props}
      value={isLoading ? undefined : props.value}
      placeholder={isLoading ? t("text:loading_data") : props.placeholder ?? t("ui:placeholder.click_to_select")}
      isLoading={isLoading}
      onLoadMore={() => autocompletePaging.loadNext()}
      onSearch={handleSearch}
      onChange={handleChange}
      pagingInfo={autocompletePaging.info}
      items={removeDuplicatesFromArrayByKey(getUniqueElementsById(items), "id").map((i) =>
        devStore.getIsEntitiesIdsVisibleInAutocompleteAsync ? { ...i, text: `[${i.id}] ${i.text}` } : i
      )}
    />
  );
}

export const AutocompleteAsync = observer(AutocompleteAsyncObserved);
