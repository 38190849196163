import {
  DriverReport,
  IssueReportItem2UserDto,
  IssueReportItemDto,
  IssueShortDto,
  UserShortDto,
} from "../../../../api";

export enum reportsTypesTypes {
  violations = "reportViolations",
  customRule = "reportCustomRule",
}

export type reportsType = reportsTypesTypes.violations | reportsTypesTypes.customRule;

export enum driverReportsNames {
  writesCount = "common:driver_card.writes_count",
  callsCount = "common:driver_card.calls_count",
  fixedViolations = "common:driver_card.fixed_violations",
  processAvgTimeInMinutes = "common:driver_card.process_avg_time",
  overallTimeSavedInHours = "common:driver_card.overall_time_saved",
}

export enum unitOfMeasurementConst {
  times = "common:unit_of_measurement.times",
  minutes = "common:unit_of_measurement.minutes",
  hours = "common:unit_of_measurement.hours",
}

export interface IReportsDriver {
  data?: DriverReport;
}

export interface IReport {
  reportsType: reportsType;
  reports: IssueReportItemDto[];
  users?: UserShortDto[] | null;
  selectDate: { from: string; to: string };
}

export interface IReportsGroup {
  reportsType: reportsType;
  reports: IssueReportItemDto;
  users?: UserShortDto[] | null;
  selectDate: { from: string; to: string };
}

export interface IReportUserCollapse {
  reportsType: reportsType;
  user?: UserShortDto;
  userDate?: IssueReportItem2UserDto;
}

export interface IReportIssueCard {
  reportsType: reportsType;
  issueShort: IssueShortDto;
}