import React from "react";
import { TitleView } from "./TitleView";
import { TitleProps } from "antd/lib/typography/Title";

interface ITitle extends TitleProps {
  size?: string;
  weight?: number | "normal" | "bold" | "lighter" | "bolder";
}

export function Title(props: ITitle) {
  return <TitleView {...props} />;
}
