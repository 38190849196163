import React, { useEffect, useRef, useState } from "react";
import "./UserSelectionDialog.scss";
import { api } from "../../../../services";
import { IUserSelectionDialog, positionsKeys, PositionsType } from "./UserSelectionDialog.interface";
import { UserSelectionDialogView } from "./UserSelectionDialogView";
import { AutocompleteModel, RoleDto, RolePositionDto } from "../../../../api";
import { UserSelectionRule } from "../../../../api/types/userSelectionRule";
import { usePagingWithController } from "../../../../hooks/usePaging";
import useDebounce from "../../../../hooks/useDebounce";
import { RadioChangeEvent } from "antd/lib/radio/interface";
import { useRootStore } from "../../../../hooks";

export const UserSelectionDialog: React.FC<IUserSelectionDialog> = (props) => {
  const rolesRef = useRef<RoleDto[]>([]);
  const { orgchartStore, authStore } = useRootStore();

  const [searchRoleValue, setSearchRoleValue] = useState<string>("");
  const [activeRoleId, setActiveRoleId] = useState<number | null>(null);
  const [currentPositions, setCurrentPositions] = useState<RolePositionDto[] | undefined>(undefined);
  const [rolePositionId, setRolePositionId] = useState<PositionsType>(null);
  const [orgchartId, setOrgchartId] = useState<number | null>(null);
  const [forceReload, setForceReload] = useState<boolean>(false);
  const [rolesAutocomplete, setRolesAutocomplete] = useState<AutocompleteModel[]>([]);

  const roles = usePagingWithController(
    api.role,
    { orgchartId, name: searchRoleValue },
    {
      pageSize: 20,
    },
    undefined
  );

  const debounce = useDebounce(searchRoleValue, 400);

  const handleChangeSearchRoleValue = (newValue: string) => setSearchRoleValue(newValue);

  const handleFindCurrentPositions = (activeRoleId?: number | null): RolePositionDto[] | null | undefined =>
    roles.items.find((item) => item.id === activeRoleId)?.positions;

  const loadRolesViaAutocomplete = async () => {
    const r = await api.role.autocomplete({
      orgchartId,
      name: searchRoleValue,
      pageSize: -1,
    });
    if (r == null || r.items == null) {
      return;
    }
    setRolesAutocomplete([...r.items]);
  };

  const handleChangeRolePositionId = (event: RadioChangeEvent) => {
    if (Number(event.target.value) === 0) return setRolePositionId(null);
    if (typeof (event.target as HTMLInputElement)?.value === "string")
      return setRolePositionId(event.target.value as PositionsType);
    setRolePositionId(Number((event.target as HTMLInputElement).value) as PositionsType);
  };

  const handleSelectActiveRole = (id: number | null) => {
    setActiveRoleId(id);
    setRolePositionId(null);
  };

  const handleChangeOrgchartId = (id: string | number) => setOrgchartId(isNaN(+id) ? null : +id);

  const handleReload = () => setForceReload(true);

  const handleSave = () => {
    const userSelectionRuleObj: UserSelectionRule = {
      isExcluded: !!props.isExcludedType,
      roleId: activeRoleId,
      orgchartId: orgchartId ?? undefined,
    };
    if (rolePositionId === positionsKeys.allManagers || rolePositionId === positionsKeys.allPerformers) {
      if (rolePositionId === positionsKeys.allManagers) userSelectionRuleObj.rolePositionType = 1;
      if (rolePositionId === positionsKeys.allPerformers) userSelectionRuleObj.rolePositionType = 2;
    } else {
      if (rolePositionId == null) {
        // @ts-ignore
        userSelectionRuleObj.rolePositionId = rolePositionId;
      } else {
        userSelectionRuleObj.rolePositionId = Number(rolePositionId);
      }
    }
    if (!activeRoleId && !currentPositions?.length) {
      // userSelectionRuleObj.rolePositionId = undefined;
      // userSelectionRuleObj.rolePositionType = undefined;
    }
    console.log(
      userSelectionRuleObj,
      authStore.getCurrentCompanyUiType == 2,
      rolesAutocomplete.find((item) => item.id == userSelectionRuleObj.roleId),
      roles.items.find((item) => item.id == userSelectionRuleObj.roleId)
    );
    props.onSave(
      userSelectionRuleObj,
      authStore.getCurrentCompanyUiType == 2
        ? rolesAutocomplete.find((item) => item.id == userSelectionRuleObj.roleId)
        : roles.items.find((item) => item.id == userSelectionRuleObj.roleId)
    );
    props.onClose();
  };

  useEffect(() => {
    if (!props.open) {
      setTimeout(() => {
        setCurrentPositions(undefined);
        setActiveRoleId(null);
        setSearchRoleValue("");
        setRolePositionId(null);
        rolesRef.current = [];
      }, 150);
    }
  }, [props.open]);

  useEffect(() => {
    const currentRolePositions = handleFindCurrentPositions(activeRoleId);
    setCurrentPositions(currentRolePositions ?? undefined);
  }, [activeRoleId]);

  useEffect(() => {
    orgchartStore.getCurrentOrgchartId && setOrgchartId(orgchartStore.getCurrentOrgchartId);
  }, [orgchartStore.getCurrentOrgchartId]);

  useEffect(() => {
    setActiveRoleId(null);
    setRolePositionId(null);
    handleReload();
  }, [orgchartId]);

  useEffect(() => {
    if (forceReload || debounce) {
      if (authStore.getCurrentCompanyUiType != 2) {
        roles.restart(true);
        setForceReload(false);
      } else {
        loadRolesViaAutocomplete();
      }
    }
  }, [forceReload, debounce]);

  useEffect(() => {
    authStore.getCurrentCompanyUiType == 2 && loadRolesViaAutocomplete();
  }, []);

  return (
    <UserSelectionDialogView
      rolesIsDone={roles.info.isDone}
      orgchartId={orgchartId}
      onChangeOrgchartId={handleChangeOrgchartId}
      searchRoleValue={searchRoleValue}
      onChangeSearchRoleValue={handleChangeSearchRoleValue}
      roles={roles.items}
      rolesAutocomplete={rolesAutocomplete}
      loadNextRoles={roles.loadNext}
      orgchartsForBlockAllAll={props.orgchartsForBlockAllAll}
      disabledRules={props.disabledRules}
      open={props.open}
      onClose={props.onClose}
      onSelectActiveRole={handleSelectActiveRole}
      onChangeRolePositionId={handleChangeRolePositionId}
      rolePositionId={rolePositionId}
      activeRoleId={activeRoleId}
      currentPositions={currentPositions}
      onSave={handleSave}
      isLoading={false}
    />
  );
};
// notifier.show({ message: t("notifier:error.role_no_select"), theme: "error" });
