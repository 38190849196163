import React from "react";
import "./ListStaff.scss";
import { IListStaff } from "./ListStaff.interface";
import { Button, Card, Empty, Icon, IconButton, Text } from "../../../../../uiKit";
import { useTranslation } from "react-i18next";
import { driverType, driverTypeKeys, getAdminDrivers, getDrivers, getUserTypeIdByUserId } from "../../helpers";
import { UserInfo } from "../../../../../elements/userInfo/UserInfo";
import { FiTrash2 } from "react-icons/all";

export const ListStaff = (props: IListStaff) => {
  const { t } = useTranslation();

  const drivers = getDrivers(props.staff);
  const adminDrivers = getAdminDrivers(props.staff);

  const handleAddDriver = () => props.onOpenAddEmployeeDialog(driverTypeKeys.driver);
  const handleAddDriverAdmin = () => props.onOpenAddEmployeeDialog(driverTypeKeys.admin);

  const handleClick = (id: number, employeeType: driverType) =>
    props.onDeleteEmployee(getUserTypeIdByUserId(id, employeeType, props.staff) ?? 0);

  return (
    <Card
      title={t("common:page_title.employees")}
      className="flex-grow-1 flex-shrink-1 br-none"
      isShadowed={false}
      style={{ flexBasis: "50%" }}
      size="default"
    >
      <div>
        <div className="d-flex justify-space-between align-center mb-4">
          <Text weight={500} children={t("ui:text.driver_administrators")} />
          <Button variant="filled" onClick={handleAddDriverAdmin}>
            {t("ui:button.add")}
          </Button>
        </div>
        <div>
          {adminDrivers?.length ? (
            <div className="d-stack-column spacing-2">
              {adminDrivers?.map((item) => (
                <Card key={item.id} variant="secondary" isShadowed={false}>
                  <div className="d-stack-row align-center justify-space-between">
                    <UserInfo avatarSize={30} nameStyle={{ fontSize: "16px" }} user={item} />
                    <IconButton
                      className="list-companies-card__delete-icon"
                      onClick={() => handleClick(item.id!, driverTypeKeys.admin)}
                      icon={<Icon component={() => <FiTrash2 />} />}
                    />
                  </div>
                </Card>
              ))}
            </div>
          ) : (
            <Card isShadowed={false} variant="secondary">
              <Empty className="my-2" />
            </Card>
          )}
        </div>
      </div>

      <div className="mt-6">
        <div className="d-flex justify-space-between align-center mb-4">
          <Text weight={500} children={t("ui:text.drivers")} />
          <Button variant="filled" onClick={handleAddDriver}>
            {t("ui:button.add")}
          </Button>
        </div>
        <div>
          {drivers?.length ? (
            <div className="d-stack-column spacing-2">
              {drivers?.map((item) => (
                <Card key={item.id} variant="secondary" isShadowed={false}>
                  <div className="d-stack-row align-center justify-space-between">
                    <UserInfo avatarSize={30} nameStyle={{ fontSize: "16px" }} user={item} />
                    <IconButton
                      className="list-companies-card__delete-icon"
                      onClick={() => handleClick(item.id!, driverTypeKeys.driver)}
                      icon={<Icon component={() => <FiTrash2 />} />}
                    />
                  </div>
                </Card>
              ))}
            </div>
          ) : (
            <Card isShadowed={false} variant="secondary">
              <Empty className="my-2" />
            </Card>
          )}
        </div>
      </div>
    </Card>
  );
};
