import React from "react";
import "./ListCompaniesCard.scss";
import { IListCompaniesCard } from "./ListCompaniesCard.interface";
import { useTranslation } from "react-i18next";
import { Card, Icon, IconButton, Text } from "../../../../../uiKit";
import { FiTrash2 } from "react-icons/all";

export const ListCompaniesCard = (props: IListCompaniesCard) => {
  const { t } = useTranslation();
  const handleClick = () => props.onButtonClick(props.company.id!);

  return (
    <Card variant="secondary" isShadowed={false}>
      <div className="d-flex align-center justify-space-between">
        <Text
          weight="bold"
          children={
            (props?.company?.name ?? "").trim().length > 0 ? props?.company?.name : props?.company?.nameFallback
          }
        />
        <IconButton
          className="list-companies-card__delete-icon ml-2"
          onClick={handleClick}
          icon={<Icon component={() => <FiTrash2 />} />}
        />
      </div>
    </Card>
  );
};
