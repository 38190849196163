import React, { createRef, useEffect, useState } from "react";
import { AudioPlayerView } from "./AudioPlayerView";
import { getDisplayTimeBySeconds } from "./utils";

type OnSeek = (audio: HTMLAudioElement, time: number) => Promise<void>;
export type TAudioPlayerAudioPreloadAttribute = "auto" | "metadata" | "none";
export type MAIN_LAYOUT = "stacked" | "stacked-reverse" | "horizontal" | "horizontal-reverse";
export type TIME_FORMAT = "auto" | "mm:ss" | "hh:mm:ss";
export type TAudioPlayerType = "voice-message" | "audio";

interface MSEPropsObject {
  onSeek: OnSeek;
  onEncrypted?: (e: unknown) => void;
  srcDuration: number;
}

interface IAudioPlayer {
  type?: TAudioPlayerType;
  src?: string;

  crossOrigin?: string;
  mediaGroup?: string;
  preload?: TAudioPlayerAudioPreloadAttribute;
  mse?: MSEPropsObject;

  autoPlay?: boolean;
  autoPlayAfterSrcChange?: boolean;
  loop?: boolean;
  muted?: boolean;
  volume?: number;

  onPlay?: (e: Event) => void;
  onPause?: (e: Event) => void;
  onPlaying?: (e: Event) => void;
  onEnded?: (e: Event) => void;

  onError?: (e: Event) => void;
  onPlayError?: (err: Error) => void;
  // listenInterval?: number;
  // progressJumpStep?: number;
  // progressJumpSteps?: {
  //   backward?: number;
  //   forward?: number;
  // };
  // volumeJumpStep?: number;
  // hasDefaultKeyBindings?: boolean;
  // onAbort?: (e: Event) => void;
  // onCanPlay?: (e: Event) => void;
  // onCanPlayThrough?: (e: Event) => void;
  // onSeeking?: (e: Event) => void;
  // onSeeked?: (e: Event) => void;
  // onStalled?: (e: Event) => void;
  // onSuspend?: (e: Event) => void;
  // onLoadStart?: (e: Event) => void;
  // onLoadedMetaData?: (e: Event) => void;
  // onLoadedData?: (e: Event) => void;
  // onWaiting?: (e: Event) => void;
  // onEmptied?: (e: Event) => void;
  // onListen?: (e: Event) => void;
  // onVolumeChange?: (e: Event) => void;
  // onClickPrevious?: (e: React.SyntheticEvent) => void;
  // onClickNext?: (e: React.SyntheticEvent) => void;
  // onChangeCurrentTimeError?: () => void;
  // progressUpdateInterval?: number;
  // defaultCurrentTime?: ReactNode;
  // defaultDuration?: ReactNode;
  // showJumpControls?: boolean;
  // showSkipControls?: boolean;
  // showDownloadProgress?: boolean;
  // showFilledProgress?: boolean;
  // showFilledVolume?: boolean;
  // timeFormat?: TIME_FORMAT;
  // header?: ReactNode;
  // footer?: ReactNode;
  // layout?: MAIN_LAYOUT;
  // children?: ReactNode;
  // style?: CSSProperties;
}

export interface IAudioPlayerState {
  currentTime: string;
  duration: string;
  progress: number;
  playbackRate: number;
  isPaused: boolean;
  isPlaying: boolean;
  isStopped: boolean;
}

export function AudioPlayer(props: IAudioPlayer) {
  const [state, setState] = useState<IAudioPlayerState>({
    currentTime: "00:00",
    duration: "",
    progress: 0,
    playbackRate: 1,
    isPaused: false,
    isPlaying: false,
    isStopped: true,
  });
  const audioRef = createRef<HTMLAudioElement>();

  const togglePlay = (e: React.SyntheticEvent): void => {
    e.stopPropagation();
    const audio = audioRef.current;
    if (audio == null) return;
    if ((audio.paused || audio.ended) && audio.src) {
      playAudioPromise();
    } else if (!audio.paused) {
      audio.pause();
    }
  };

  const playAudioPromise = (): void => {
    const playPromise = audioRef.current?.play();
    if (playPromise) {
      playPromise.then(null).catch((err) => {
        props.onPlayError && props.onPlayError(new Error(err));
      });
    }
  };

  const isPlaying = (): boolean => {
    const audio = audioRef.current;
    if (!audio) return false;

    return !audio.paused && !audio.ended;
  };

  const handlePlay = (e: Event): void => {
    setState((s) => ({ ...s, isPaused: false, isPlaying: true, isStopped: false }));
    props.onPlay && props.onPlay(e);
  };

  const handlePause = (e: Event): void => {
    setState((s) => ({ ...s, isPaused: true, isPlaying: false }));
    props.onPause && props.onPause(e);
  };

  const handleAudioDurationChange = (e: Event): void => {
    const audio = e.target as HTMLAudioElement;
    if (audio.duration == Infinity) {
      audio.currentTime = 1e101;
      audio.addEventListener("timeupdate", getAudioDuration);
    }
    setState((s) => ({
      ...s,
      progress: 0,
      duration: getDisplayTimeBySeconds(audio.duration, audio.duration, "mm:ss").toString(),
    }));
  };

  const getAudioDuration = (e: Event) => {
    const audio = e.target as HTMLAudioElement;
    audio.currentTime = 0;
    audio.removeEventListener("timeupdate", getAudioDuration);
  };

  const handleAudioCurrentTimeChange = (e: Event): void => {
    const audio = e.target as HTMLAudioElement;
    setState((s) => ({
      ...s,
      progress: Math.round(audio.currentTime / (audio.duration / 100)),
      currentTime: getDisplayTimeBySeconds(audio.currentTime ?? 0, audio.currentTime ?? 0, "mm:ss").toString(),
    }));
  };

  const handleProgressChange = (progress: number) => {
    const audio = audioRef.current;
    if (!audio) return;
    audio.currentTime = (audio.duration / 100) * progress;
  };

  const handleError = (event: ErrorEvent) => {
    props.onError && props.onError(event);
  };

  useEffect(() => {
    //
  }, [props.src, props.autoPlayAfterSrcChange]);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio == null) return;

    audio.playbackRate = state.playbackRate;
  }, [state.playbackRate]);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio == null) return;

    audio.playbackRate = state.playbackRate;
    if (props.muted) {
      audio.volume = 0;
    }

    audio.addEventListener("error", handleError);
    audio.addEventListener("pause", handlePause);
    audio.addEventListener("play", handlePlay);
    audio.addEventListener("timeupdate", handleAudioCurrentTimeChange);
    audio.addEventListener("loadedmetadata", handleAudioDurationChange);

    return () => {
      audio.removeEventListener("error", handleError);
      audio.removeEventListener("pause", handlePause);
      audio.removeEventListener("play", handlePlay);
      audio.removeEventListener("timeupdate", handleAudioCurrentTimeChange);
      audio.removeEventListener("loadedmetadata", handleAudioDurationChange);
    };
  }, []);

  return (
    <AudioPlayerView
      state={state}
      audioRef={audioRef}
      type={props.type ?? "audio"}
      src={props.src}
      setState={setState}
      onPlayPauseClick={togglePlay}
      onProgressChange={handleProgressChange}
    />
  );
}
