import { FiBell } from "@react-icons/all-files/fi/FiBell";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import Visibility from "visibilityjs";
import { clearInterval as wtClearInterval, setInterval as wtSetInterval } from "worker-timers";
import { NotificationDto } from "../../../api";
import notificationSound from "../../../assets/sounds/notification-old.mp3";
import { StringContentType } from "../../../enums/stringContentType";
import { getStringContentType, removeEmojiFromString } from "../../../helpers/stringFunctions";
import { useDateHelpers, useNotifier, useRootStore, useSound } from "../../../hooks";
import { usePagingWithController } from "../../../hooks/usePaging";
import { api } from "../../../services";
import { useNotificationHub } from "../../../signalr/hubs/notificationHub";
import { ToolbarType } from "../../modules/textEditorOld/misc/consts";
import { TextEditor } from "../../modules/textEditorOld/TextEditor";
import { NotificationsCenterView } from "./NotificationsCenterView";

// import notificationSound from "../../../assets/sounds/notification.wav";

interface INotificationsCenter {
  //
}

export type TNotificationsCenterTabs = "all" | "needActions" | "inObservation";

export function NotificationsCenterToExport(props: INotificationsCenter) {
  const notificationHub = useNotificationHub();
  const notifier = useNotifier();
  const navigate = useNavigate();
  const dateHelpers = useDateHelpers();
  // const nSound = require("../../../assets/sounds/notification.wav");
  const [playNotificationSound] = useSound(notificationSound, { volume: 0.4, interrupt: true });
  const { t } = useTranslation();
  const { authStore, appStore } = useRootStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const playNotificationSoundRef = useRef<any>(null);
  const titleBlinkIntervalRef = useRef<any>(null);

  const documentTitles = useRef({
    default: document.title,
    hasNotifications: t("parse:new_notification"),
  });

  useEffect(() => {
    playNotificationSoundRef.current = playNotificationSound;
  }, [playNotificationSound]);

  const [isBadgeVisible, setIsBadgeVisible] = useState<boolean>(false);
  const [isFaviconAndTitleBlinking, setIsFaviconAndTitleBlinking] = useState<boolean>(false);
  const [onlyShowUnread, setOnlyShowUnread] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<TNotificationsCenterTabs>("all");
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState({
    all: 0,
    needActions: 0,
    inObservation: 0,
  });

  const visibilityChangeListener = Visibility.change(function (e, state) {
    if (!Visibility.hidden()) {
      setIsFaviconAndTitleBlinking(false);
    }
  });

  const notificationsAll = usePagingWithController<NotificationDto, any>(
    api.notification,
    { isRead: onlyShowUnread ? false : null, thenOrderBy: "date_created", thenOrder: "desc" },
    { orderBy: "is_read", order: "asc", pageSize: 25 }
  );

  const notificationsNeedActions = usePagingWithController<NotificationDto, any>(
    api.notification,
    { baseType: 1, thenOrderBy: "date_created", thenOrder: "desc" },
    { orderBy: "is_read", order: "asc", pageSize: 25 }
  );

  const notificationsInObservation = usePagingWithController<NotificationDto, any>(
    api.notification,
    { baseType: 2, thenOrderBy: "date_created", thenOrder: "desc" },
    { orderBy: "is_read", order: "asc", pageSize: 25 }
  );

  const refreshAllTabs = async () => {
    resetAllTabs();
    await updateUnreadNotificationsLength(["all", "needActions", "inObservation"]);
    await notificationsAll.restart();
    await notificationsNeedActions.restart();
    await notificationsInObservation.restart();
    await updateNotificationsCenterBadgeStatus();
  };

  const resetAllTabs = () => {
    notificationsAll.reset();
    notificationsNeedActions.reset();
    notificationsInObservation.reset();
  };

  const getUnreadMessagesCount = async (): Promise<number> => {
    const r = await api.notification.autocomplete({ isRead: false });
    if (r == null || r.items == null) return 0;
    return r.items.length;
  };

  const updateNotificationsCenterBadgeStatus = async () => {
    const unreadMessagesCount = await getUnreadMessagesCount();
    setIsBadgeVisible(unreadMessagesCount > 0);
  };

  const handleLoadMore = async (key: TNotificationsCenterTabs) => {
    if (key == "all") await notificationsAll.loadNext();
    if (key == "needActions") await notificationsNeedActions.loadNext();
    if (key == "inObservation") await notificationsInObservation.loadNext();
  };

  const updateUnreadNotificationsLength = async (key: TNotificationsCenterTabs[]): Promise<void> => {
    const newValue = {
      all: unreadNotificationsCount.all,
      needActions: unreadNotificationsCount.needActions,
      inObservation: unreadNotificationsCount.inObservation,
    };
    if (key.some((k) => k == "all")) {
      const r = await api.notification.autocomplete({ isRead: false });
      newValue.all = r?.totalItems ?? 0;
    }
    if (key.some((k) => k == "needActions")) {
      const r = await api.notification.autocomplete({ isRead: false, baseType: 1 });
      newValue.needActions = r?.totalItems ?? 0;
    }
    if (key.some((k) => k == "inObservation")) {
      const r = await api.notification.autocomplete({ isRead: false, baseType: 2 });
      newValue.inObservation = r?.totalItems ?? 0;
    }
    setUnreadNotificationsCount({ ...newValue });
  };

  const handleOpen = async () => {
    setIsFaviconAndTitleBlinking(false);
    setIsVisible(true);
    await refreshAllTabs();
    // await Promise.all([refreshAllTabs(), updateUnreadNotificationsLength(["all", "needActions", "inObservation"])]);
    // await updateUnreadNotificationsLength(["all", "needActions", "inObservation"]);
  };

  useEffect(() => {
    // isVisible && updateUnreadNotificationsLength(["all", "needActions", "inObservation"]);
  }, [isVisible]);

  const handleClose = async () => {
    setIsVisible(false);
    setOnlyShowUnread(false);
    resetAllTabs();
    setUnreadNotificationsCount({ all: 0, inObservation: 0, needActions: 0 });
    setTimeout(() => {
      setCurrentTab("all");
    }, 250);
  };

  const handleCardClick = async (cardData: NotificationDto) => {
    // setIsVisible(false);
    await handleClose();
    notifier.close((cardData.id as number).toString());
    const r = await api.notification.markAsRead(cardData.id as number);
    switch (cardData.additionalParams?.entity) {
      case "issue":
        // navigate("/communication/" + (cardData.additionalParams.id ?? ""));
        setSearchParams({
          issueId: (cardData.additionalParams.id as number)?.toString(),
          // issueType: "task",
        });
        break;
      case "regulation":
        navigate("/policy/all/" + (cardData.additionalParams.id ?? ""));
        break;
      case "section":
        navigate("/policy/topics/" + (cardData.additionalParams.id ?? ""));
        break;
      default:
        break;
    }
    await updateNotificationsCenterBadgeStatus();
  };

  const handleCardIsReadStatusChange = async (
    id: number,
    currentIsReadStatus: boolean,
    tabKey: TNotificationsCenterTabs
  ) => {
    if (tabKey == "all") {
      currentIsReadStatus ? await api.notification.markAsUnread(id) : await api.notification.markAsRead(id);
      await updateUnreadNotificationsLength(["all", "needActions", "inObservation"]);
      // await notificationsAll.reset();
      // await notificationsAll.restart();
    }
    if (tabKey == "needActions") {
      currentIsReadStatus ? await api.notification.markAsUnread(id) : await api.notification.markAsRead(id);
      await updateUnreadNotificationsLength(["all", "needActions", "inObservation"]);
      // await notificationsNeedActions.reset();
      // await notificationsNeedActions.restart();
    }
    if (tabKey == "inObservation") {
      currentIsReadStatus ? await api.notification.markAsUnread(id) : await api.notification.markAsRead(id);
      await updateUnreadNotificationsLength(["all", "needActions", "inObservation"]);
      // await notificationsInObservation.reset();
      // await notificationsInObservation.restart();
    }
    await updateNotificationsCenterBadgeStatus();
  };

  const handleReadAllClick = async () => {
    // Add "Read all" handler
    const r = await api.notification.markAllAsRead();
    if (r == null) {
      notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
      return;
    }
    await refreshAllTabs();
  };

  const getNotificationDataById = async (notificationId: number): Promise<NotificationDto | null> => {
    const r = await api.notification.getById(notificationId);
    return r ?? null;
  };

  const getNotificationDescription = (notificationData: NotificationDto) => {
    if (notificationData.contentDto?.description == null) return null;
    if (getStringContentType(notificationData.contentDto?.description) == StringContentType.textEditorContent) {
      return (
        <div>
          <TextEditor
            id={`te-notification-${String(notificationData.id)}`}
            readOnly
            toolbarType={ToolbarType.Hidden}
            value={JSON.parse(notificationData.contentDto.description)}
          />
          <div className="mt-2">
            <span
              children={
                (notificationData.sender?.nameShort ?? "") +
                " • " +
                dateHelpers.formatDateString(notificationData.dateCreated as string)
              }
            />
            <span
              children={
                (notificationData.company?.name?.trim() ?? "").length > 0
                  ? notificationData.company?.name
                  : notificationData.company?.nameFallback
              }
            />
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <span children={notificationData.contentDto?.description} />
          <div />
          <span
            children={
              (notificationData.sender?.nameShort ?? "") +
              " • " +
              dateHelpers.formatDateString(notificationData.dateCreated as string)
            }
          />
          <div />
          <span
            children={
              (notificationData.company?.name?.trim() ?? "").length > 0
                ? notificationData.company?.name
                : notificationData.company?.nameFallback
            }
          />
        </div>
      );
    }
  };

  const playNotificationSoundWrapper = () => {
    if (appStore.getAudioSettingsState.notifications == "inactiveOnly") {
      if (Visibility.isSupported() && !Visibility.hidden()) {
        return;
      }
    }
    if (appStore.getAudioSettingsState.notifications == "off") {
      return;
    }
    playNotificationSoundRef.current && playNotificationSoundRef.current();
  };

  const blinkFaviconAndTitleWrapper = () => {
    if (Visibility.isSupported() && Visibility.hidden()) {
      setIsFaviconAndTitleBlinking(true);
    }
  };

  const showNotificationNotifier = async (notificationId: number) => {
    const notificationData = await getNotificationDataById(notificationId);
    if (notificationData == null) return;
    const isNotificationForCurrentCompany = notificationData.company?.id == authStore.getCurrentCompanyId;
    // TODO: Вставить плейсхолдеры вместо <?? "">
    playNotificationSoundWrapper();
    blinkFaviconAndTitleWrapper();
    notifier.show({
      // message: [notificationData.sender?.nameShort?.trim() ?? "", notificationData.contentDto?.name?.trim() ?? ""].join(
      //   ": "
      // ),
      message: removeEmojiFromString(notificationData.contentDto?.name ?? ""),
      description: getNotificationDescription(notificationData),
      theme: "info",
      key: notificationId.toString(),
      icon: (
        <div
          style={{ width: "24px", height: "24px" }}
          // className="outside-progress-10s"
          children={<FiBell color="var(--color-primary-base)" />}
        />
      ),
      timeout: Visibility.isSupported() && Visibility.hidden() ? 0 : 10,
      button: isNotificationForCurrentCompany
        ? {
            text: t("parse:open"),
            onClick: () => handleCardClick(notificationData),
          }
        : undefined,
    });
  };

  useEffect(() => {
    return () => {
      if (typeof visibilityChangeListener === "number") {
        Visibility.unbind(visibilityChangeListener);
      }
    };
  }, []);

  useEffect(() => {
    notificationsAll.reset();
    notificationsAll.restart();
  }, [onlyShowUnread]);

  useEffect(() => {
    updateNotificationsCenterBadgeStatus();
  }, []);

  useEffect(() => {
    try {
      notificationHub.start().then(() => {
        //
      });
    } catch (err) {
      notifier.show({
        message: t("notifier:error.real_time_connect_fail"),
        theme: "error",
        timeout: 90000000000,
      });
    }
    return () => {
      notificationHub.stop();
    };
  }, []);

  useEffect(() => {
    notificationHub.checkDisconnection() &&
      notifier.show({
        message: t("notifier:error.real_time_disconnect"),
        theme: "error",
        timeout: 90000000000,
      });
  }, [notificationHub.connection?.state]);

  useEffect(() => {
    if (notificationHub.connection) {
      notificationHub.connection.on("notification", async (message) => {
        await showNotificationNotifier(message);
        await updateNotificationsCenterBadgeStatus();
        await handleClose();
      });
    }
  }, [notificationHub.connection]);

  useEffect(() => {
    if (isFaviconAndTitleBlinking) {
      // Turn on
      titleBlinkIntervalRef.current = wtSetInterval(() => {
        document.title =
          document.title == documentTitles.current.default
            ? documentTitles.current.hasNotifications
            : documentTitles.current.default;
      }, 1500);
    } else {
      // Turn off
      document.title = documentTitles.current.default;
      titleBlinkIntervalRef.current != null && wtClearInterval(titleBlinkIntervalRef.current);
    }
  }, [isFaviconAndTitleBlinking]);

  return (
    <NotificationsCenterView
      notificationData={{
        all: {
          items: notificationsAll.items,
          itemsLength: unreadNotificationsCount.all,
          isLoading: notificationsAll.info.isLoading,
          isDone: notificationsAll.info.isDone,
        },
        needActions: {
          items: notificationsNeedActions.items,
          itemsLength: unreadNotificationsCount.needActions,
          isLoading: notificationsNeedActions.info.isLoading,
          isDone: notificationsNeedActions.info.isDone,
        },
        inObservation: {
          items: notificationsInObservation.items,
          itemsLength: unreadNotificationsCount.inObservation,
          isLoading: notificationsInObservation.info.isLoading,
          isDone: notificationsInObservation.info.isDone,
        },
      }}
      isBadgeVisible={isBadgeVisible}
      currentTab={currentTab}
      onlyShowUnread={onlyShowUnread}
      isVisible={isVisible}
      onCardIsReadStatusChange={handleCardIsReadStatusChange}
      onCardClick={handleCardClick}
      setIsVisible={setIsVisible}
      onLoadMore={handleLoadMore}
      setCurrentTab={setCurrentTab}
      onReadAllClick={handleReadAllClick}
      setOnlyShowUnread={setOnlyShowUnread}
      onOpen={handleOpen}
      onClose={handleClose}
    />
  );
}

export const NotificationsCenter = observer(NotificationsCenterToExport);
