import React from "react";
import "./IssueOptions.scss";
import { IIssueOptions } from "./IssueOptions.interface";
import { IssueActionConsts } from "../../../types/consts";
import { DropdownMenu } from "../../../../../service/dropdownMenu/dropdownMenu";


export const IssueOptions = (props: IIssueOptions): JSX.Element => {

  const actionsHandler = (key: IssueActionConsts): () => void => {
    switch (key) {
      case IssueActionConsts.delete : {
        return () => props.toggleDeleteConfirm(true);
      }
      case IssueActionConsts.withdraw : {
        return () => props.onOpenWithdrawIssueDialog();

      }
      case IssueActionConsts.copy : {
        return props.onOpenCopyIssueDialog;
      }
      case IssueActionConsts.reject : {
        return () => props.onOpenIssueRejectDialog();
      }
      default : {
        return () => {
        };
      }
    }
  };

  const popupItems = (props.actions ?? []).map(item => ({
    id: item.id!,
    text: item.name!,
    action: actionsHandler(item.key as IssueActionConsts)
  }));

  return (
    <DropdownMenu items={popupItems} />

  );
};