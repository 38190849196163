import React from "react";
import { ListView } from "./ListView";
import { ListProps } from "antd";

interface IList extends ListProps<any> {
  //
}

export function List(props: IList) {
  return <ListView {...props} />;
}
