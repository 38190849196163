import { Alert, AlertProps } from "antd";
import React from "react";
import "./Alert.scss";
import clsx from "clsx";

interface IAlertView extends AlertProps {
  //
}

export function AlertView({ className, ...rest }: IAlertView) {
  return <Alert className={clsx("uiKit-alert", className ?? "")} {...rest} />;
}
