import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions } from "../../../uiKit";

interface IDeleteRecurringIssueDialogView {
  open: boolean;

  onDelete(): void;
  onClose(): void;
}

function DeleteRecurringIssueDialogView(props: IDeleteRecurringIssueDialogView) {
  const { t } = useTranslation();

  return (
    <Dialog title={t("ui:title.recurring_issue_delete")} open={props.open} onClose={() => props.onClose()}>
      <DialogActions>
        <Button onClick={() => props.onClose()} variant="text">
          {t("ui:button.cancel")}
        </Button>
        <Button theme="error" onClick={() => props.onDelete()} variant="filled">
          {t("ui:button.delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteRecurringIssueDialogView;
