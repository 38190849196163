import React from "react";
import "./FunctionPositionCard.scss";
import { RoleDto, RolePositionDto, User2RoleDto } from "../../../../../api";
import { FiTrash2 } from "@react-icons/all-files/fi/FiTrash2";
import { useTranslation } from "react-i18next";
import { useRootStore } from "../../../../../hooks";
import { UserInfo } from "../../../../elements/userInfo/UserInfo";
import { Card, IconButton, Input, Tooltip } from "../../../../uiKit";
import { AutocompleteAsync } from "../../../../elements/autocompleteAsync/AutocompleteAsync";

interface IFunctionPositionCardView {
  data: RolePositionDto;
  functionData: RoleDto;
  readOnly: boolean;
  isLoading: boolean;
  positionName: string;
  positionAR: string;

  handleUserAddOnPosition: (positionId: number, userId: number) => void;
  handleUserDeleteFromPosition: (positionId: number, userId: number) => void;
  setPositionName: (str: string) => void;
  setPositionAR: (str: string) => void;
  handleDataSave: () => void;
  handlePositionDelete: (positionId: number) => void;
}

function FunctionPositionCardView(props: IFunctionPositionCardView) {
  const { t } = useTranslation();
  const { helperStore, authStore } = useRootStore();

  return (
    <Card
      className="function-position-card__wrapper"
      variant={authStore.getCurrentCompanyUiType == 2 ? "transparent" : "secondary"}
      bodyStyle={{ padding: authStore.getCurrentCompanyUiType == 2 ? 0 : undefined }}
      isShadowed={false}
    >
      <div className="function-position-card__header d-flex flex-nowrap align-center">
        <Input
          size="small"
          style={{
            fontWeight: "bold",
            fontSize: "14px",
          }}
          status={props.positionName.length == 0 ? "error" : undefined}
          bordered={props.positionName.length == 0}
          disabled={props.readOnly}
          value={props.positionName}
          placeholder={t("parse:enter_name") as string}
          onInput={(event: any) => props.setPositionName(event.target.value)}
          onBlur={() => props.handleDataSave()}
        />
        <Tooltip
          title={
            (props.functionData.users ?? []).filter((u2r: User2RoleDto) => u2r.positionId == props.data.id).length > 0
              ? t("parse:position_delete_block")
              : undefined
          }
          placement="left"
        >
          {props.data.parentPositionId != null && !props.readOnly && (
            <IconButton
              onClick={() => props.handlePositionDelete(props.data.id as number)}
              disabled={
                props.isLoading ||
                (props.functionData.users ?? []).filter((u2r: User2RoleDto) => u2r.positionId == props.data.id).length >
                  0
              }
              className="__button-delete"
            >
              <FiTrash2 size={16} />
            </IconButton>
          )}
        </Tooltip>
      </div>
      <div
        className={`function-position-card__users my-${
          authStore.getCurrentCompanyUiType == 2 ? "0" : "2"
        } d-flex flex-nowrap flex-column`}
      >
        {(props.functionData.users ?? []).filter((u2r: User2RoleDto) => u2r.positionId == props.data.id).length > 0 && (
          <div className="d-stack-column">
            {(props.functionData.users ?? [])
              .filter((u2r: User2RoleDto) => u2r.positionId == props.data.id)
              .map((u2r: User2RoleDto) => (
                <div className="d-flex flex-nowrap ml-2 my-1" key={u2r.id}>
                  <UserInfo key={u2r.id} user={u2r.user} />
                  {!props.readOnly &&
                    !(
                      props.functionData.type == "main" &&
                      (props.functionData.users ?? []).filter((u2r: User2RoleDto) => u2r.positionId == props.data.id)
                        .length < 2 &&
                      props.data.parentPositionId == null
                    ) && (
                      <IconButton
                        disabled={props.isLoading}
                        onClick={() =>
                          props.handleUserDeleteFromPosition(props.data.id as number, u2r.userId as number)
                        }
                        className="__button-delete ml-auto"
                      >
                        <FiTrash2 size={16} />
                      </IconButton>
                    )}
                </div>
              ))}
          </div>
        )}
        {!props.readOnly && (
          // <Button sx={{ alignSelf: "flex-start" }} startIcon={<FiPlus />} size="small">
          //   Добавить сотрудника
          // </Button>
          //TODO : YS check this moment value={undefined}
          //TODO : YS idsToFilter={
          //     ((props.functionData.users ?? [])
          //       .filter((u: User2RoleDto) => u.positionId == props.data.id)
          //       .map((u2r: User2RoleDto) => u2r.userId)/           //       .filter((n: any) => n != null) ?? []) as number[]
          //   }
          //   formControlClassName="__add-user-form-control"
          //formControlClassName="__add-user-form-control"
          <AutocompleteAsync
            type="user"
            bordered={false}
            value={null}
            // value={undefined}
            idsToFilter={
              ((props.functionData.users ?? [])
                .filter((u: User2RoleDto) => u.positionId == props.data.id)
                .map((u2r: User2RoleDto) => u2r.userId)
                .filter((n: any) => n != null) ?? []) as number[]
            }
            autoClearSearchValue
            placeholder={t("parse:add_employee_full")}
            onChange={(value) => props.handleUserAddOnPosition(props.data.id as number, +value)}
            disabled={props.isLoading}
          />
        )}
      </div>
      {authStore.getCurrentCompanyUiType != 2 && (
        <div
          className="function-position-card__ar"
          // sx={{ display: "flex", flexWrap: "nowrap", flexDirection: "column" }}
        >
          <Input
            bordered={false}
            size="small"
            style={{
              fontSize: "14px",
              color: "var(--color-dark-weak)",
              lineHeight: "1.25em",
            }}
            disabled={props.readOnly}
            value={props.positionAR}
            placeholder={t("ui:placeholder.valuable_final_product")}
            onInput={(event: any) => props.setPositionAR(event.target.value)}
            onBlur={() => props.handleDataSave()}
          />
        </div>
      )}
    </Card>
  );
}

export default FunctionPositionCardView;
