import React, { useEffect, useRef } from "react";
import ScrollTriggerView from "./ScrollTriggerView";

interface IScrollTrigger {
  options?: IntersectionObserverInit;
  disabled?: boolean;
  hidden?: boolean;
  marginTop?: boolean;
  debugMode?: boolean;
  fullWidth?: boolean;
  onClick?: () => void;
  onIntersection: () => void;
}

function ScrollTrigger(props: IScrollTrigger) {
  const observableFunction = useRef<Function | null>(null);
  const triggerRef = useRef<Element>();
  const isDisabled = useRef<boolean>(!!props.disabled);
  const observerOptionsInit: IntersectionObserverInit = {
    root: null,
    threshold: 0,
  };

  const observer = new IntersectionObserver((e) => {
    handleIntersect(e[0]);
  }, props.options ?? observerOptionsInit);

  const handleIntersect = (entry: IntersectionObserverEntry) => {
    if (entry.isIntersecting && !isDisabled.current) {
      if (typeof props.onIntersection === "function") {
        // props.onIntersection();
        observableFunction.current && !props.hidden && observableFunction.current();
      }
    }
  };

  const handleClick = () => {
    if (typeof props.onClick === "function") {
      props.onClick();
    }
  };

  useEffect(() => {
    observer.observe(triggerRef.current as Element);
  }, []);

  useEffect(() => {
    observableFunction.current = props.onIntersection;
  }, [props.onIntersection]);

  useEffect(() => {
    isDisabled.current = !!props.disabled;
  }, [props.disabled]);

  return (
    <ScrollTriggerView
      fullWidth={props.fullWidth}
      triggerRef={triggerRef}
      onClick={handleClick}
      marginTop={props.marginTop}
      disabled={props.disabled}
      hidden={props.hidden}
      debugMode={props.debugMode}
    />
  );
}

export default ScrollTrigger;
