import React, { ReactNode } from "react";
import { RoleDto } from "../../../../../api";
import "./OrgchartFunctionDrawer.scss";
import { useTranslation } from "react-i18next";
import { AppTabType } from "../../../../../utils/appLinksAndTabs";
import { IOrgChartDrawerTabs, IOrgchartFunctionDrawer } from "./OrgchartFunctionDrawer";
import DrawerCloseConfirmationDialog from "../../../dialogs/drawerCloseConfirmationDialog/DrawerCloseConfirmationDialog";
import { useClaims, useRootStore } from "../../../../../hooks";
import { guideKeys } from "../../../../../stores/hintStore";
import { permissionKeys } from "../../../../../utils/permissions";
import { CirclePicker } from "react-color";
import { observer } from "mobx-react-lite";
import { Drawer, Input, Popover, Segmented, Switch, TabPane, Tabs, TextArea, Tooltip } from "../../../../uiKit";
import { BiDotsHorizontal } from "@react-icons/all-files/bi/BiDotsHorizontal";
import { BiDotsVertical } from "@react-icons/all-files/bi/BiDotsVertical";
import { OpeningGuideIcon } from "../../../../elements/openingGuideIcon/OpeningGuideIcon";

const handleGetTabName = (tab: IOrgChartDrawerTabs): string | ReactNode => {
  const getGuideContentKey = (): JSX.Element | null => {
    switch (tab.id) {
      case 0:
        return <OpeningGuideIcon ml={8} contentKey={guideKeys.recurringIssues} />;
      case 1:
        return <OpeningGuideIcon ml={8} contentKey={guideKeys.inviteEmployee} />;
      case 2:
        return <OpeningGuideIcon ml={8} contentKey={guideKeys.permissions} />;
      case 3:
        return <OpeningGuideIcon ml={8} contentKey={guideKeys.dashboard} anchorId="1anchor" />;
      case 4:
        return null;
      default:
        return null;
    }
  };

  return (
    <div className="d-stack-row align-center px-2">
      {tab.name}
      {getGuideContentKey()}
    </div>
  );
};

interface IOrgchartFunctionDrawerView extends IOrgchartFunctionDrawer {
  tabs: AppTabType[];
  currentTabId: number;
  functionData: RoleDto | null;
  updater: any;
  isDrawerCloseDialogOpen: boolean;
  isDrawerClosable: boolean;
  changeSubfunctionsColors: boolean;

  setChangeSubfunctionsColors: (value: boolean) => void;

  handleFunctionChildrenDirectionSave(direction: "h" | "v"): void;

  handleFunctionColorSave(color: string): void;

  setFunctionData(data: RoleDto): void;

  setIsDrawerClosable(value: boolean): void;

  setIsDrawerCloseDialogOpen(value: boolean): void;

  refreshData(): void;

  onTabChange(tabId: number): void;
}

function OrgchartFunctionDrawerView(props: IOrgchartFunctionDrawerView) {
  const { t } = useTranslation();
  const { hintStore, helperStore, authStore, orgchartStore } = useRootStore();
  const claims = useClaims();

  const onIconClick = (tabId: number, event: any) => {
    event.stopPropagation();
    if (tabId === 0) hintStore.onOpenHint(guideKeys.recurringIssues);
    if (tabId === 2) hintStore.onOpenHint(guideKeys.permissions);
  };

  return (
    <>
      <DrawerCloseConfirmationDialog
        open={props.isDrawerCloseDialogOpen}
        onClose={() => props.setIsDrawerCloseDialogOpen(false)}
        onAccept={() => {
          props.setIsDrawerClosable(true);
          props.onClose();
        }}
      />
      <Drawer
        closable={true}
        title={
          <div className="orgchart-function-drawer_header" style={{ maxWidth: "90%" }}>
            {claims.has(permissionKeys.orgchart.role.edit) ? (
              <div className="d-flex align-center">
                <Popover
                  className="orgchart-function-drawer_tooltip"
                  placement="bottomLeft"
                  content={
                    props.updater?.currentState != null && (
                      <div className="d-stack-column spacing-2">
                        <CirclePicker
                          circleSpacing={8}
                          circleSize={24}
                          className="orgchart-function-tooltip_color-picker"
                          color={props.updater?.currentState?.colorHex ?? undefined}
                          colors={helperStore.getColors}
                          // onChange={(color) => {
                          //   props.updater.applyChanges({ colorHex: color.hex });
                          // }}
                          onChangeComplete={(color) => {
                            props.handleFunctionColorSave(color.hex);
                          }}
                        />
                        <Switch
                          label={t("parse:change_subfunctions_colors")}
                          onChange={(value) => props.setChangeSubfunctionsColors(value)}
                          checked={props.changeSubfunctionsColors}
                        />
                      </div>
                    )
                  }
                >
                  <div
                    style={{
                      width: "20px",
                      height: "20px",
                      borderRadius: "100%",
                      flexShrink: 0,
                      background: props.updater?.currentState?.colorHex,
                      // boxShadow: "var(--shadow-middle-bottom)",
                      border: "1px solid var(--color-primary-base)",
                    }}
                  />
                </Popover>
                <Tooltip title={t("ui:text.child_functions_direction") as string}>
                  <Segmented
                    className="ml-2"
                    value={props.functionData?.childrenAreHorizontal ? "h" : "v"}
                    disabled={
                      props.functionData == null ||
                      (props.functionData.parentRoleId == null &&
                        authStore.getInitialInfo?.identity?.companies?.find(
                          (u2c) => u2c.companyId == authStore.getInitialInfo?.identity?.currentCompanyId
                        )?.company?.orgchartType == "c")
                    }
                    onChange={(value) => {
                      props.handleFunctionChildrenDirectionSave(value as any);
                    }}
                    options={[
                      { label: <BiDotsVertical />, value: "v" },
                      { label: <BiDotsHorizontal />, value: "h" },
                    ]}
                  />
                </Tooltip>

                <Input
                  bordered={false}
                  size="small"
                  className="ml-2"
                  value={props.updater?.currentState?.name ?? ""}
                  onInput={(event) => props.updater.applyChanges({ name: (event.target as HTMLInputElement).value })}
                  onBlur={() =>
                    (props.updater?.currentState?.name ?? "").trim().length > 0
                      ? props.updater.updatePartially()
                      : props.updater.applyChanges({ name: props.updater.beforeState?.name ?? "" })
                  }
                />
              </div>
            ) : (
              <div className="d-flex align-center">
                <div
                  className="mr-1 flex-shrink-0"
                  style={{
                    width: "20px",
                    height: "20px",
                    borderRadius: "100%",
                    background: props.updater?.currentState?.colorHex,
                  }}
                />
                <span className="__function-name">
                  {props.updater?.currentState?.name}
                  {/*{props.allRoles?.find((role: RoleDto) => role.id == props.roleId)?.name ?? ""}*/}
                </span>
              </div>
            )}
            {claims.has(permissionKeys.orgchart.role.edit) ? (
              <span className="__function-vfp">
                {orgchartStore.getCurrentOrgchartResultName + ": "}
                {/*<span className="__function-vfp-text">*/}
                <TextArea
                  bordered={false}
                  size="small"
                  autoSize
                  style={{ minHeight: "unset" }}
                  className="ml-1"
                  value={props.updater?.currentState?.awaitedResult ?? ""}
                  onInput={(event) =>
                    props.updater.applyChanges({ awaitedResult: (event.target as HTMLInputElement).value })
                  }
                  placeholder={t("ui:placeholder.valuable_final_product")}
                  onBlur={props.updater.updatePartially}
                />
                {/*{props.allRoles?.find((role: RoleDto) => role.id == props.roleId)?.awaitedResult ?? ""}*/}
                {/*</span>*/}
              </span>
            ) : (
              <span className="__function-vfp">
                {orgchartStore.getCurrentOrgchartResultName + ": "}
                <span className="__function-vfp-text">
                  {props.updater?.currentState?.awaitedResult}
                  {/*{props.allRoles?.find((role: RoleDto) => role.id == props.roleId)?.awaitedResult ?? ""}*/}
                </span>
              </span>
            )}
            <Tabs
              className="orgchart-function__tabs"
              activeKey={String(props.currentTabId)}
              destroyInactiveTabPane
              onChange={(activeKey) => props.onTabChange(Number(activeKey))}
              tabBarStyle={{ margin: 0 }}
            >
              {props.tabs.map((tab: IOrgChartDrawerTabs) => (
                <TabPane tab={handleGetTabName(tab)} key={String(tab.id)} destroyInactiveTabPane />
              ))}
            </Tabs>
          </div>
        }
        open={props.open}
        className="orgchart-function-drawer_main"
        contentWrapperStyle={{
          maxWidth: "1110px",
          width: "100%",
        }}
        headerStyle={{ paddingBottom: 0, alignItems: "flex-start" }}
        destroyOnClose
        // variant="temporary"
        // keepMounted={false}
        onClose={() => (props.isDrawerClosable ? props.onClose() : props.setIsDrawerCloseDialogOpen(true))}
      >
        {/*<div className="mt-0 mb-2" style={{ borderBottom: 1, borderColor: "var(--color-layout-divider)" }}>*/}
        <Tabs
          activeKey={String(props.currentTabId)}
          destroyInactiveTabPane
          onChange={(activeKey) => props.onTabChange(Number(activeKey))}
          tabBarStyle={{ display: "none" }}
        >
          {props.tabs.map((tab: IOrgChartDrawerTabs) => (
            <TabPane tab={handleGetTabName(tab)} key={String(tab.id)} destroyInactiveTabPane>
              <div>
                {tab.element({
                  functionData: props.functionData,
                  refreshData: props.refreshData,
                  setFunctionData: props.setFunctionData,
                  setIsDrawerClosable: props.setIsDrawerClosable,
                })}
              </div>
            </TabPane>
          ))}
        </Tabs>
      </Drawer>
    </>
  );
}

export default observer(OrgchartFunctionDrawerView);
