import React from "react";
import "./CommentCard.scss";
import { UserCard } from "../userCard/UserCard";
import { TextEditor } from "../../modules/textEditorOld/TextEditor";
import { stringToContent } from "../../../utils/textEditor";
import FileUploader from "../../service/fileUploader/FileUploader";
import { ICommentCardView } from "./CommentCard.interface";
import { Reactions } from "../emojiPicker/Reactions";

export const CommentCardView = <T,>(props: ICommentCardView<T>) => {
  return (
    <div className="comment-card" key={props.historyId}>
      <div className="comment-card__header">
        {props.creator && (
          <UserCard userInfo={props.creator} boldName avatarSize={40} additionalInfo={`${props.dateSented}`} />
        )}
        <Reactions historyId={props.historyId} reactions={props.reactions} strategy={props.strategy} />
      </div>
      <div className="comment-card__content">
        <TextEditor
          id={String(props.historyId - props.index)}
          readOnly
          value={stringToContent(props.commentContent!)}
        />
      </div>
      {props.commentAttachments && (
        <div className="comment-card__attachments">
          <FileUploader
            noDragAndDrop
            value={props?.commentAttachments.map((item) => item.file!)}
            hideInfo
            smallSize
            noDelete
          />
        </div>
      )}
    </div>
  );
};
