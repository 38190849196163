import React, { useEffect, useState } from "react";
import "./CreateRegulationSectionForm.scss";
import { CreateRegulationSectionFormView } from "./CreateRegulationSectionFormView";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AutocompleteModel, Section2RegulationDto } from "../../../../api";
import { useNotifier, useRootStore } from "../../../../hooks";
import { api } from "../../../../services";
import { SettingUpAccessDialog } from "../../dialogs/settingUpAccessDialog/SettingUpAccessDialog";
import { UserSelectionRule } from "../../../../api/types/userSelectionRule";
import { observer } from "mobx-react-lite";
import useDebounce from "../../../../hooks/useDebounce";
import { ICreateRegulationSectionForm } from "./CreateRegulationSectionForm.interface";
import { keysRulesFor } from "../../dialogs/settingUpAccessDialog/SettingUpAccessDialog.interface";

const CreateRegulationSectionFormObserver: React.FC<ICreateRegulationSectionForm> = (props) => {
  const notifier = useNotifier();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { regulationStore } = useRootStore();

  const [initialFetchIsFinish, setInitialFetchIsFinish] = useState<boolean>(false);

  const [isRequestLoading, setIsRequestLoading] = useState<boolean>(false);
  const [isOpenAccessSettingsDialog, setIsOpenAccessSettingsDialog] = useState<boolean>(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);

  const [newRegulations, setNewRegulations] = useState<AutocompleteModel[]>([]);

  const [userSelectionRules, setUserSelectionRules] = useState<UserSelectionRule[]>();

  const [activeSectionIdFilter, setActiveSectionIdFilter] = useState<number | undefined>(undefined);
  const [regulationSearchValue, setRegulationSearchValue] = useState<string>("");
  const [titleSection, setTitleSection] = useState<string>("");

  const debouncedSearchValue = useDebounce(regulationSearchValue, 400);

  const commonRegulations = regulationStore.getAutocompleteRegulations;
  const sections = regulationStore.getAutocompleteSections;
  const regulationsIsUploaded = regulationStore.getRegulationAutocompletePagingOptions.pageIsLast;

  const handleOpenDialog = () => setIsOpenAccessSettingsDialog(true);
  const handleCloseDialog = () => setIsOpenAccessSettingsDialog(false);

  const handleError = () => {
    notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
  };

  const handleRedirectToCreateRegulation = () => navigate("/regulation/create-regulation");

  const handleChangeActiveSectionFilter = (newValue: number) => setActiveSectionIdFilter(newValue);

  const handleChangeRegulationSearchValue = (newValue: string) => setRegulationSearchValue(newValue);
  const handleChangeTitleSection = (newValue: string) => {
    setTitleSection(newValue);
    setIsChanged(true);
  };

  const unmountClean = () => {
    setRegulationSearchValue("");
    regulationStore.setRegulationsAutoComplete([]);
    regulationStore.resetRegulationAutocompleteOptions();
  };

  const handleCancel = () => (props.toEdit?.isEdit ? props.toEdit?.onCancel() : setNewRegulations([]));

  const handleAppendNewRegulation = (id: number) => {
    const currentRegulation = commonRegulations.find((item) => item.id === id);
    currentRegulation && setNewRegulations((prev) => [...prev, currentRegulation]);
    setIsChanged(true);
  };

  const handleRemoveNewRegulation = (id: number) => {
    setNewRegulations((prev) => prev.filter((item) => item.id !== id));
    setIsChanged(true);
  };

  const handleSaveAccessSettings = async (rules: UserSelectionRule[]): Promise<boolean> => {
    setUserSelectionRules([...rules]);
    return true;
  };

  const handleLoadNextRegulations = () => regulationStore.fetchNextPageRegulationAutocomplete();

  const handleMapAutocompleteRegulations = (regulations: AutocompleteModel[]): Section2RegulationDto[] =>
    regulations.map((item) => ({
      id: 0,
      sectionId: props.toEdit?.section?.id,
      regulationId: item.id,
      regulation: { id: item.id, name: item.text },
    }));

  const handleSave = () => {
    setIsRequestLoading(true);
    if (titleSection.trim().length === 0) {
      setIsRequestLoading(false);
      return notifier.show({ message: t("notifier:error.empty_section_name"), theme: "error" });
    }
    if (!userSelectionRules || userSelectionRules?.length === 0) {
      setIsRequestLoading(false);
      return notifier.show({ message: t("notifier:error.section_cannot_be_inaccessible"), theme: "error" });
    }

    if (props.toEdit?.isEdit) {
      api.section
        .edit(props.toEdit.section?.id!, {
          ...props!.toEdit?.section!,
          regulations: handleMapAutocompleteRegulations(newRegulations),
          name: titleSection,
        })
        .then(() => {
          notifier.show({ message: t("notifier:success.section_edited"), theme: "success" });
          props!.toEdit?.onCancel();
        })
        .catch(() => {
          notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
        })
        .finally(() => setIsRequestLoading(false));
    } else {
      const generatedSection = {
        name: titleSection,
        description: "",
        regulations: newRegulations.map((item) => ({ regulationId: item.id, name: item.text })),
        userSelectionRules,
      };
      api.section
        .create(generatedSection)
        .then(() => {
          notifier.show({ message: t("notifier:success.section_created"), theme: "success" });
          navigate("/policy");
        })
        .catch(() => {
          notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
        })
        .finally(() => setIsRequestLoading(false));
    }
  };

  useEffect(() => {
    if (initialFetchIsFinish) {
      regulationStore.setAutocompleteRegulationFilters({
        sectionId: activeSectionIdFilter,
        hasActiveContent: true,
      });
      regulationStore.initialFetchRegulationAutocomplete(true);
    }
  }, [activeSectionIdFilter]);

  useEffect(() => {
    if (initialFetchIsFinish) {
      regulationStore.setAutocompleteRegulationFilters({
        name: regulationSearchValue.length > 0 ? regulationSearchValue : undefined,
      });
      regulationStore.initialFetchRegulationAutocomplete(true);
    }
  }, [debouncedSearchValue]);

  useEffect(() => {
    regulationStore.setAutocompleteRegulationFilters({ hasActiveContent: true });
    regulationStore.setAutocompleteSectionFilters({ pageSize: 50 });
    regulationStore.setOnError(handleError);

    if (props.toEdit?.isEdit && props.toEdit.currentRegulations) {
      setNewRegulations(props.toEdit.currentRegulations);
      setTitleSection(props.toEdit.sectionTitle ?? "");
      setUserSelectionRules(props.toEdit.userSelectionRules);
    }

    const promises: Promise<void>[] = [
      regulationStore.initialFetchRegulationAutocomplete(),
      regulationStore.initialFetchSectionAutocomplete(),
    ];

    Promise.all(promises)
      .then((results) => {
        setInitialFetchIsFinish(true);
      })
      .catch((err) => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" }));

    return unmountClean;
  }, []);

  return (
    <>
      {isOpenAccessSettingsDialog && (
        <SettingUpAccessDialog
          keyRuleTo={keysRulesFor.section}
          currentRules={userSelectionRules}
          onSave={handleSaveAccessSettings}
          isOpen={isOpenAccessSettingsDialog}
          onClose={handleCloseDialog}
        />
      )}
      <CreateRegulationSectionFormView
        isChanged={isChanged}
        isEdit={!!props.toEdit?.isEdit}
        isLoading={regulationStore.getAutocompleteLoading}
        isRequestLoading={isRequestLoading}
        regulationsIsUploaded={regulationsIsUploaded}
        activeSectionIdFilter={activeSectionIdFilter}
        titleSection={titleSection}
        sections={sections}
        commonRegulations={commonRegulations}
        newRegulations={newRegulations}
        regulationSearchValue={regulationSearchValue}
        onChangeRegulationSearchValue={handleChangeRegulationSearchValue}
        onChangeTitleSection={handleChangeTitleSection}
        onAppendNewRegulation={handleAppendNewRegulation}
        onRemoveNewRegulation={handleRemoveNewRegulation}
        onRedirectToCreateSection={handleRedirectToCreateRegulation}
        onResetNewRegulations={handleCancel}
        onChangeActiveSectionFilter={handleChangeActiveSectionFilter}
        onSave={handleSave}
        onLoadNextRegulations={handleLoadNextRegulations}
        onOpenDialog={handleOpenDialog}
      />
    </>
  );
};

export const CreateRegulationSectionForm = observer(CreateRegulationSectionFormObserver);
