import React, { useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Alert, DatePicker } from "../../../../uiKit";
import SubheaderText from "../../../../elements/subheaderText/SubheaderText";
import { IssueContext } from "../../../../../contexts/communication/issueContext";
import { getNearestDateMultipleOf5 } from "../../../../../helpers/dateСomparison";
import dayjs from "dayjs";

interface IIssueRequiredActionsDialogTabSetDeadlineStrict {
  value: Date | null;
  isStrict?: boolean;
  maxDeadline?: Date;
  defaultValue?: Date;
  expired?: boolean;
  onChange: (value: Date) => void;
  dateDeadline?: Date;
}

export function IssueRequiredActionsDialogTabSetDeadlineStrict(props: IIssueRequiredActionsDialogTabSetDeadlineStrict) {
  const { t } = useTranslation();

  const { senderDeadlineExpired } = useContext(IssueContext);

  const dateWorkStartIsExpired: boolean = useMemo(
    () => (!props.dateDeadline || !props.value ? false : props.value > props.dateDeadline),
    [props.value]
  );

  useEffect(() => {
    if (!props.value) {
      props.onChange(
        props.defaultValue && props.defaultValue! > new Date()
          ? props.defaultValue
          : getNearestDateMultipleOf5(new Date())
      );
    }
  }, []);

  return (
    <div className="d-stack-column spacing-3">
      {dateWorkStartIsExpired && <Alert type="warning" message={t("text:date_work_start_expired")} />}
      <div className="">
        <SubheaderText text={t("ui:subheader.executor_deadline")} />
        <DatePicker
          style={{ width: "100%" }}
          size="large"
          // value={props.value ?? props.defaultValue ?? dayjs(getNearestDateMultipleOf5(new Date()))}
          value={
            props.value != null
              ? dayjs(props.value)
              : props.defaultValue != null
              ? dayjs(props.defaultValue)
              : dayjs(getNearestDateMultipleOf5(new Date()))
          }
          onChange={(deadline) => deadline && props.onChange(deadline?.toDate() ?? null)}
          allowClear={false}
          // readOnly={props.isStrict}
          showTime={{ format: "HH:mm" }}
          disablePast
        />
      </div>
    </div>
  );
}
