import React from "react";
import { IOrgchartDrawerTabRegulations } from "./OrgchartDrawerTabRegulations.interface";
import { Spin } from "../../../../../uiKit";
import { regulationContentTypeKeys } from "../../../regulation/misc/headerTools";
import { paginationTypeKeys } from "../../../regulation/components/ListCards/ListCards.interface";
import { CommonListCards } from "../../../regulation/components/ListCards/CommonListCards";

export const OrgchartDrawerTabRegulationsView = (props: IOrgchartDrawerTabRegulations) => {
  const isCommonIsLoading = props.isLoadingRegulations || props.isLoadingSections;

  return (
    <div className="regulation-list d-stack-column justify-start align-center spacing-2 full-height">
      {isCommonIsLoading ? (
        <div className="full-height mt-20">
          <Spin size="large" />
        </div>
      ) : (
        <CommonListCards
          isLoading={isCommonIsLoading}
          paginationType={paginationTypeKeys.byButton}
          regulationContentType={regulationContentTypeKeys.approved}
          regulations={props.regulations ?? []}
          loadNextRegulations={props.onLoadNextRegulations}
          isDoneRegulation={props.isDoneRegulations}
          sections={props.sections ?? []}
          loadNextSections={props.onLoadNextSections}
          isDoneSection={props.isDoneSections}
        />
      )}
    </div>
  );
};
// {(props?.regulations?.length ?? 0) > 0 ?
//   props?.regulations?.map((item, index) => (
//     <div key={item.id} style={{ width: "100%" }}>
//       <cardRegulation
//         isRound
//         bordered
//         isSecondary
//         regulationContentType={regulationContentTypeKeys.approved}
//         key={item.id} regulation={item} />
//       {/*{index + 1 !== regulations.length && <Divider style={{ margin: "0px" }} />}*/}
//     </div>
//   ))
//   :
//   <div className="full-height mt-20">
//     {isCommonIsLoading ? <Spin size="large"/> : <EmptyBlock />}
//   </div>
// }
