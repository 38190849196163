import { useTranslation } from "react-i18next";
import { Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { splitNumberWithSeparator } from "../../../../../helpers/numberFunctions";
import { Card, Shape, Text, Tooltip as UITooltip } from "../../../../uiKit";
import { TDashboardChartDataItem } from "./DashboardChart";
import "./DashboardChart.scss";
import React, { useState } from "react";
import { MetricDividerDto, MetricReadDto } from "../../../../../api";

export type TDashboardChartViewChartsData = {
  chartItemsIds: number[];
  lineCharts: {
    id: number;
    name: string;
    color?: string;
    data: { itemId: number; date: string; label: string; value: number | null }[];
  }[];
  quotaChart: { itemId: number; date: string; label: string; value: number | null }[];
};

interface IDashboardChartView {
  data: TDashboardChartDataItem[];
  metricId: number;
  graphId?: number;
  metricData: MetricReadDto;
  chartsData: TDashboardChartViewChartsData;
  yDividers: MetricDividerDto[];
  // { [key: string | number]: any }
  isReadOnly?: boolean;
  isReversed?: boolean;
  isLegendEnabled?: boolean;
  isColored?: boolean;
  viewSettings?: {
    min: number | null;
    max: number | null;
  };

  onChange: (itemId: number) => void;
}

const COLORS = {
  lineChart: {
    increase: "#fd5a44",
    decrease: "#00b894",
    normal: ["var(--color-primary-base)", "var(--color-error-weaker)", "var(--color-success-weaker)"],
  },
  quota: {
    normal: "var(--color-layout-fill-tertiary)",
    // normal: "var(--color-layout-divider)",
  },
};

const CustomizedYTick = (props: any, yDividers: MetricDividerDto[]) => {
  const { x, y, payload } = props;
  return (
    <text x={x} y={y} fill="#666" fontSize="10px">
      <UITooltip title={splitNumberWithSeparator(payload.value)}>
        <tspan x={x} dy="0.355em" textAnchor="end">
          {yDividers.find((d) => d.value == props.payload?.value)?.label ?? ""}
          {/*{isNumberInScientificNotation(Number(payload.value)) ? "NaN" : abbreviateNumber(Number(payload.value))}*/}
        </tspan>
      </UITooltip>
    </text>
  );
};

const CustomizedXTick = (props: any) => {
  const { x, y, payload, orientation } = props;
  // const formatDateToString = (value: string): string[] => {
  //   const d = formatDateToDateString(convertDateToUTC0(new Date(value)));
  //   return [d.substring(0, d.length - 5), d.substring(d.length - 4, d.length)];
  // };
  const formatLabelToDatesArray = (value: string): [string, string] => {
    const dates = value.match(/\d+/g);
    if (dates == null || dates.length < 3) return [value, ""];
    let divider = value.replace(/\d+/g, "")?.[0] ?? "";
    divider = divider.trim().length == 0 ? "." : divider;
    return [dates[0] + divider + dates[1], dates[2]];
  };

  return (
    <text x={x} y={y} fill="#666" fontSize="10px">
      {typeof payload.value === "string" ? (
        <>
          <tspan x={x} dy={orientation == "top" ? "-1em" : "1em"} textAnchor="middle">
            {formatLabelToDatesArray(payload.value)[0]}
          </tspan>
          <tspan fontSize="10px" opacity={0.7} x={x} dy={orientation == "top" ? "1em" : "1.1em"} textAnchor="middle">
            {formatLabelToDatesArray(payload.value)[1]}
          </tspan>
        </>
      ) : (
        <tspan x={x} dy="0.355em" textAnchor="middle">
          {payload.value}
        </tspan>
      )}
    </text>
  );
};

const CustomizedTooltipCursor = (props: any) => {
  const { x, y, width, height, points, top, bottom } = props;
  return (
    <>
      <line
        x1={points?.[0]?.x}
        x2={points?.[1]?.x}
        y1={points?.[0]?.y}
        y2={points?.[1]?.y}
        stroke="var(--color-layout-divider)"
      />
      {/*<Rectangle*/}
      {/*  fill="var(--color-layout-divider)"*/}
      {/*  stroke="transparent"*/}
      {/*  x={points?.[0]?.x ?? undefined}*/}
      {/*  y={top}*/}
      {/*  width={1}*/}
      {/*  height={height}*/}
      {/*  children={<text children="123" />}*/}
      {/*/>*/}
      <svg
        x={points?.[0]?.x - 12}
        y={points?.[0]?.y + height / 2 - 12}
        // y={(top + height + bottom) / 2 - 12}
        // y={"50%"}
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <ellipse fill="rgb(0, 128, 103)" cx="12" cy="12" rx="12" ry="12" />
        <rect fill="rgb(255, 255, 255)" x="3.192" y="9.426" width="16.536" height="4.681" rx="45.5" ry="45.5" />
        <rect
          fill="rgb(255, 255, 255)"
          x="7.472"
          y="6.444"
          width="16.535"
          height="4.68"
          rx="45.5"
          ry="45.5"
          transform="matrix(0, -1, 1, 0, 2.514317, 27.1945)"
        />
        <ellipse fill="rgb(0, 128, 103)" cx="12" cy="12" rx="12" ry="12" />
        <rect
          fill="rgb(255, 255, 255)"
          transform="matrix(0, -1, 1, 0, 5.8305, 27.7395)"
          x="7.472"
          y="4.526"
          width="16.535"
          height="3.287"
          rx="1.6"
          ry="1.6"
        />
        <rect fill="rgb(255, 255, 255)" x="3.733" y="10.356" width="16.535" height="3.287" rx="1.6" ry="1.6" />
      </svg>
    </>
  );
};

const CustomizedTooltipContent = (props: any) => {
  const { t } = useTranslation();
  if (props.active && props.payload && props.payload.length) {
    return (
      <Card
        title={props.label}
        className="dashboard-chart__tooltip"
        bodyStyle={{ padding: "8px 12px", maxWidth: props.viewBox?.width ?? undefined }}
      >
        <div className="d-stack-column spacing-1">
          {props.chartsData.lineCharts.map(
            (lC: {
              id: number;
              name: string;
              color?: string;
              data: { itemId: number; date: Date; value: number | null }[];
            }) =>
              props.payload.find((p: any) => p.dataKey == lC.id)?.value != null ? (
                <div key={lC.id} className="d-stack-row spacing-2 align-center">
                  <Shape
                    size={14}
                    type="circle"
                    backgroundColor={lC?.color ?? props.payload.find((p: any) => p.dataKey == lC.id)?.color}
                  />
                  <Text style={{ lineHeight: "1.25em" }} weight="bold" children={`${lC.name}:`} />
                  <Text
                    style={{ whiteSpace: "nowrap", lineHeight: "1.25em" }}
                    weight={500}
                    children={props.payload.find((p: any) => p.dataKey == lC.id)?.value}
                  />
                </div>
              ) : null
          )}
        </div>
        {props.payload.find((p: any) => p.dataKey == "quota") != null && (
          <div className="d-stack-row spacing-1 align-center mt-1">
            <Text style={{ lineHeight: "1.25em" }} weight="bold" children={`${t("parse:quantitative_plan")}:`} />
            <Text
              style={{ whiteSpace: "nowrap", lineHeight: "1.25em" }}
              weight={500}
              children={props.payload.find((p: any) => p.dataKey == "quota")?.value}
            />
          </div>
        )}
      </Card>
    );
  }
  return null;
};

const CustomizedLegend = (props: any, onHoverStateChange: (id: number | null) => void) => {
  return (
    <div className="d-stack-column spacing-0 full-width mt-2">
      {/*onMouseLeave={() => onHoverStateChange(null)}*/}
      {props.payload
        .filter((p: any) => p.dataKey != "quota")
        .map((p: any) => (
          <UITooltip key={p.dataKey} title={p.value} placement="topLeft">
            <div key={p.dataKey} className="d-stack spacing-1 align-center">
              <Shape backgroundColor={p.color} size={10} type="circle" />
              <Text
                style={{
                  whiteSpace: "nowrap",
                  color: p.color,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  lineHeight: "1em",
                  padding: "2px 0",
                  // lineHeight: "16px",
                  verticalAlign: "middle",
                }}
                size="12px"
                children={p.value}
                // onMouseEnter={() => onHoverStateChange(p.dataKey)}
                // onMouseLeave={() => onHoverStateChange(null)}
              />
            </div>
          </UITooltip>
        ))}
    </div>
  );
};

const CustomizedActiveDot = (props: any) => {
  const { cx, cy } = props;

  return (
    // y={cy == null ? "calc(50% - 12px)" : cy - 12}
    <svg x={cx - 12} y={"40%"} viewBox="0 0 24 24" width="24" height="24">
      <ellipse fill="rgb(0, 128, 103)" cx="12" cy="12" rx="12" ry="12" />
      <rect fill="rgb(255, 255, 255)" x="3.192" y="9.426" width="16.536" height="4.681" rx="45.5" ry="45.5" />
      <rect
        fill="rgb(255, 255, 255)"
        x="7.472"
        y="6.444"
        width="16.535"
        height="4.68"
        rx="45.5"
        ry="45.5"
        transform="matrix(0, -1, 1, 0, 2.514317, 27.1945)"
      />
      <ellipse fill="rgb(0, 128, 103)" cx="12" cy="12" rx="12" ry="12" />
      <rect
        fill="rgb(255, 255, 255)"
        transform="matrix(0, -1, 1, 0, 5.8305, 27.7395)"
        x="7.472"
        y="4.526"
        width="16.535"
        height="3.287"
        rx="1.6"
        ry="1.6"
      />
      <rect fill="rgb(255, 255, 255)" x="3.733" y="10.356" width="16.535" height="3.287" rx="1.6" ry="1.6" />
    </svg>
  );
};

export function DashboardChartView(props: IDashboardChartView) {
  const [hoveredLineId, setHoveredLineId] = useState<number | null>(null);

  const handleChartClick = (args: any) => {
    if (args == null || props.isReadOnly) return;
    props.onChange(props.data.find((d) => d.label == args.activeLabel)?.id as number);
  };

  // @ts-ignore
  const getDomain = (min, max): [number | string, number | string] => {
    if (min == null && max == null) {
      return ["dataMin", "dataMax"];
      // return [dataMin == null ? "auto" : dataMin - dataMin / 100, dataMax == null ? "auto" : dataMax - dataMax / 100];
    }
    if (min == max) {
      return ["dataMin", "dataMax"];
      // return ["auto", "auto"];
    }
    // props.chartsData.quotaChart.some((q) => q.value != null) ? 0
    return [min ?? "dataMin", max ?? "dataMax"];
  };

  const generateChartData = () => {
    return props.chartsData.chartItemsIds.map((itemId) => {
      if (props.data.length == 0) {
        return {
          lineCharts: [],
          chartItemsIds: [],
          quotaChart: [],
        };
      }
      const lineObj = props.data
        .find((d) => d.id == itemId)
        ?.charts.line.map((c) => c.id)
        .reduce(
          (ac, a) => ({
            ...ac,
            [a]: props.chartsData.lineCharts.find((c) => c.id == a)?.data.find((d) => d.itemId == itemId)?.value,
          }),
          {}
        );
      return {
        date: props.data.find((d) => d.id == itemId)?.dateISO,
        label: props.data.find((d) => d.id == itemId)?.label,
        // TODO: Return undefined or !null!
        quota: props.chartsData.quotaChart.find((q) => q.itemId == itemId)?.value ?? undefined,
        ...lineObj,
      };
    });
  };

  // const generateGradientData = () => {
  //   const COLORS = {
  //     up: "green",
  //     down: "red",
  //     neutral: "blue",
  //   };
  //   const p = 100 / (cD.length - 1);
  //   let resArray: string[] = [];
  //
  //   cD.forEach((d, i) => {
  //     let c: any = null;
  //     if (i + 1 == cD.length) return;
  //     if (cD[i + 1].line == d.line) c = COLORS.neutral;
  //     if (cD[i + 1].line > d?.line) c = COLORS.up;
  //     if (cD[i + 1].line < d?.line) c = COLORS.down;
  //     if (cD[i] == null || cD[i + 1] == null) c = COLORS.neutral;
  //     resArray = [...resArray, c].filter((v) => v != null);
  //   });
  //
  //   return (
  //     <>
  //       {resArray.map((r, i) => {
  //         return (
  //           <>
  //             <stop offset={`${p * i}%`} stopColor={resArray[i]} />
  //             <stop offset={`${p * (i + 1)}%`} stopColor={resArray[i]} />
  //           </>
  //         );
  //       })}
  //     </>
  //   );
  // };
  return (
    <ResponsiveContainer className="dashboard-chart__wrapper" width="100%" aspect={16 / 10} debounce={20}>
      <ComposedChart
        id={`chart-rc-${props.metricId}${props.graphId != null ? "-" + props.graphId : ""}`}
        margin={{
          top: 6,
          right: 12,
          left: 0,
          bottom: 0,
        }}
        data={generateChartData()}
        onClick={handleChartClick}
        // onMouseLeave={() => setHoveredLineId(null)}
      >
        <CartesianGrid strokeDasharray="3 3" vertical={false} style={{ stroke: "var(--color-layout-divider)" }} />
        <XAxis
          orientation={props.isReversed ? "top" : "bottom"}
          dataKey="label"
          interval={0}
          // angle={10}
          // minTickGap={10}
          // dy={2}
          // interval="preserveStartEnd"
          tick={CustomizedXTick}
          axisLine={{ stroke: "var(--color-layout-divider)" }}
          tickLine={{ stroke: "var(--color-layout-divider)" }}
          // tick={{ fontSize: "10px" }}
        />
        <YAxis
          width={40}
          axisLine={{ stroke: "var(--color-layout-divider)" }}
          tickLine={{ stroke: "var(--color-layout-divider)" }}
          reversed={props.isReversed}
          allowDataOverflow={
            (props.viewSettings?.min != null || props.viewSettings?.max != null) &&
            !(props.viewSettings?.min == props.viewSettings?.max)
          }
          // allowDecimals={false}
          tickCount={props?.yDividers?.length ?? 0}
          // type="number"
          minTickGap={0}
          tick={(_props) => CustomizedYTick(_props, props.yDividers ?? [])}
          ticks={(props.yDividers?.map((d) => d.value!) ?? []) as number[]}
          // interval="equidistantPreserveStart"
          //@ts-ignore
          domain={getDomain(props.viewSettings?.min ?? null, props.viewSettings?.max ?? null)}
        />
        <Legend
          verticalAlign="bottom"
          // align="left"
          className="dashboard-chart__legend"
          // wrapperStyle={{ paddingTop: "4px" }}
          iconSize={12}
          // layout="vertical"
          content={(_props) => CustomizedLegend(_props, (id) => setHoveredLineId(id))}
        />
        <Bar legendType="none" isAnimationActive={false} dataKey="quota" fill={COLORS.quota.normal} />
        {props.chartsData.lineCharts.map((lC, i) => (
          <Line
            isAnimationActive={false}
            key={lC.id}
            dataKey={lC.id}
            stroke={lC.color ?? COLORS.lineChart.normal[i]}
            name={lC.name}
            // strokeOpacity={i % 2 == 0 ? 0.2 : 1}
            // strokeOpacity={lC.id != hoveredLineId && hoveredLineId != null ? 0.3 : 1}
            dot={{ fill: "var(--color-layout-container)" }}
            // activeDot={props.isReadOnly ? undefined : CustomizedActiveDot}
            // activeDot={CustomizedActiveDot}
            // activeDot={props.isReadOnly || i > 0 ? undefined : CustomizedActiveDot}
          />
        ))}
        <Tooltip
          cursor={props.isReadOnly ? { stroke: "var(--color-layout-divider)" } : <CustomizedTooltipCursor />}
          // cursor={{ stroke: "var(--color-layout-divider)" }}
          wrapperStyle={{ outline: "none", border: "none", zIndex: 1 }}
          contentStyle={{ border: "none", borderRadius: "8px" }}
          // offset={{}}
          // offset={-120}
          animationDuration={1000}
          allowEscapeViewBox={{ x: false, y: true }}
          content={({ ...args }) =>
            CustomizedTooltipContent({ ...args, data: props.data, chartsData: props.chartsData })
          }
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}
