import React from "react";
import { IFiltersContainer } from "./FiltersContainer.interface";
import { FiltersContainerView } from "./FiltersContainerView";
import { IPeriod } from "../../../../elements/pickPeriodButton/PickPeriodButton.interface";

export const FiltersContainer = (props: IFiltersContainer) => {
  const handleChangePhone = (value: string) => props.onChange((prev) => ({ ...prev, phoneNumber: value }));
  const handleChangeName = (value: string) => props.onChange((prev) => ({ ...prev, userName: value }));
  const handleChangePeriod = (value: IPeriod | undefined) => props.onChange((prev) => ({ ...prev, period: value }));

  const handleRefresh = () => {
    props.onRefresh();
  };

  return (
    <FiltersContainerView
      isLoading={props.isLoading}
      config={props.config}
      params={props.params}
      onChangePhone={handleChangePhone}
      onChangeName={handleChangeName}
      onChangePeriod={handleChangePeriod}
      onRefresh={handleRefresh}
    />
  );
};
