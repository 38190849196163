import React from "react";
import { LayoutSiderView } from "./LayoutSiderView";
import { SiderProps } from "antd";

interface ILayoutSider extends SiderProps {
  //
}

export function LayoutSider(props: ILayoutSider) {
  return <LayoutSiderView {...props} />;
}
