import React, { useEffect, useState } from "react";
import PageHeader from "../../../../layouts/pageHeader/PageHeader";
import PageContent from "../../../../layouts/pageContent/PageContent";
import { SectionContent } from "../../pages";
import { useNotifier, useRootStore } from "../../../../../../hooks";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { SectionDto, UserShortDto } from "../../../../../../api";
import { regulationContentTypeKeys } from "../../misc/headerTools";
import { handleGetAllRegulationsIdFromSection, handleGetNoLearntRegulationsIdFromSection } from "../../pages/helpers";
import { UserSelectionRule } from "../../../../../../api/types/userSelectionRule";
import { api } from "../../../../../../services";
import { findId } from "../../../../../../helpers/pathname";
import { SettingUpAccessDialog } from "../../../../dialogs/settingUpAccessDialog/SettingUpAccessDialog";
import { keysRulesFor } from "../../../../dialogs/settingUpAccessDialog/SettingUpAccessDialog.interface";
import { mapSectionUserRulesToCustomRules } from "../../../../../../helpers/mapSectionUserRulesToCustomRules";
import { CreateRegulationSectionForm } from "../../../../forms/createRegulationSectionForm/CreateRegulationSectionForm";
import { SectionViewHeader } from "../../headers/sectionViewHeader/SectionViewHeader";
import { CustomConfirmDialog } from "../../../../dialogs/customConfirmDialog/СustomConfirmDialog";

export const Section = () => {
  const { breadcrumbsStore, authStore } = useRootStore();

  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const notifier = useNotifier();

  const [section, setSection] = useState<SectionDto>();
  const [creator, setCreator] = useState<UserShortDto>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isOpenAccessSettingsDialog, setIsOpenAccessSettingsDialog] = useState<boolean>(false);
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState<boolean>(false);

  const [isEditable, setIsEditable] = useState<boolean>(false);

  const handleRedirectToRegulation = (regulationId: number) => {
    navigate(`/policy/all/${regulationId}/?contentType=${regulationContentTypeKeys.approved}`, {
      state: {
        fromSectionId: section?.id,
        noLearntRegulationsIds: handleGetNoLearntRegulationsIdFromSection(section),
        allRegulationsIds: handleGetAllRegulationsIdFromSection(section),
      },
    });
  };

  const handleSwitchEditable = () => setIsEditable(!isEditable);

  const handleOpenDialog = () => setIsOpenAccessSettingsDialog(true);
  const handleCloseDialog = () => setIsOpenAccessSettingsDialog(false);
  const handleOpenConfirmDialog = () => setIsOpenConfirmDialog(true);
  const handleCloseConfirmDialog = () => setIsOpenConfirmDialog(false);

  const handlerApiError = () => {
    notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
    navigate("/policy");
  };

  const handlePutAccessSettings = async (rules: UserSelectionRule[]): Promise<boolean> => {
    if (section) {
      await api.section.edit(section.id!, { ...section, userSelectionRules: [...rules] });
      handleLoadSection();
      return true;
    }
    return false;
  };

  const handleLoadSection = (loadСreator = false) => {
    setIsLoading(true);
    const id = findId(pathname);
    if (id)
      api.section
        .getById(id)
        .then((res) => {
          if (res) {
            setSection(res);
            setCreator(res.createdByUser);
            breadcrumbsStore.setLastCrumb(res.name ?? "");
          }
        })
        .catch(handlerApiError)
        .finally(() => setIsLoading(false));
    else navigate("/policy");
  };

  const handleDeleteSection = () => {
    api.section
      .del(section?.id!)
      .then(() => {
        notifier.show({ message: t("notifier:success.delete_section"), theme: "success" });
        navigate("/policy/topics");
      })
      .catch(handlerApiError);
  };

  const handleOffEditable = () => {
    handleLoadSection();
    setIsEditable(false);
  };

  useEffect(() => {
    handleLoadSection(true);
    return () => breadcrumbsStore.resetLastCrumb();
  }, []);

  return (
    <>
      {isOpenAccessSettingsDialog && (
        <SettingUpAccessDialog
          keyRuleTo={keysRulesFor.section}
          onSave={handlePutAccessSettings}
          currentRules={
            (section?.userSelectionRules && mapSectionUserRulesToCustomRules(section?.userSelectionRules)) ?? undefined
          }
          isOpen={isOpenAccessSettingsDialog}
          onClose={handleCloseDialog}
        />
      )}
      {isOpenConfirmDialog && (
        <CustomConfirmDialog
          open={isOpenConfirmDialog}
          onConfirm={handleDeleteSection}
          title={t("ui:title.confirm_deletion")}
          onClose={handleCloseConfirmDialog}
        />
      )}
      <PageHeader>
        <SectionViewHeader
          isEditable={isEditable}
          permissionToEdit={authStore.getInitialInfo?.identity?.id === section?.createdByUserId}
          onOpenDialog={handleOpenDialog}
          onDeleteSection={handleOpenConfirmDialog}
          onSwitchEditable={handleSwitchEditable}
        />
      </PageHeader>
      <PageContent isLoading={isLoading} contentPosition={{ maxWidth: "1200px" }}>
        {isEditable ? (
          section && (
            <CreateRegulationSectionForm
              toEdit={{
                isEdit: isEditable,
                sectionTitle: section.name! ?? "",
                onCancel: handleOffEditable,
                section: section ?? undefined,
                userSelectionRules: (section.userSelectionRules as UserSelectionRule[]) ?? [],
                currentRegulations:
                  section.regulations?.map((item) => ({
                    id: item.regulationId,
                    text: item.regulation?.name ?? "",
                  })) ?? [],
              }}
            />
          )
        ) : (
          <SectionContent
            isEditable={isEditable}
            section={section}
            creator={creator}
            onOffEditable={handleOffEditable}
            onRedirectToRegulation={handleRedirectToRegulation}
          />
        )}
      </PageContent>
    </>
  );
};
