import React, { createRef, useEffect, useRef, useState } from "react";
import PageHeader from "../../modules/layouts/pageHeader/PageHeader";
import PageContent from "../../modules/layouts/pageContent/PageContent";
import { LocalStorageHelpers, useClaims, useNotifier, useRootStore } from "../../../hooks";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { api } from "../../../services";
import OrgchartFunctionCreateEditDialog from "../../modules/dialogs/orgchartFunctionCreateEditDialog/OrgchartFunctionCreateEditDialog";
import { IOrgchartContext, OrgchartContext } from "../../../contexts/orgchartContext";
import OrgchartFunctionDrawer from "../../modules/pages/orgchart/orgchartFunctionDrawer/OrgchartFunctionDrawer";
import OrgchartFunctionMoveDialog from "../../modules/dialogs/orgchartFunctionMoveDialog/OrgchartFunctionMoveDialog";
import OrgchartFunctionDeleteDialog from "../../modules/dialogs/orgchartFunctionDeleteDialog/OrgchartFunctionDeleteDialog";
import ZoomButton from "../../elements/zoomButton/ZoomButton";
import OrgchartSortDialog from "../../modules/dialogs/orgchartFunctionSortDialog/OrgchartSortDialog";
import { useParams } from "react-router-dom";
import VideoDialog from "../../modules/dialogs/videoDialog/VideoDialog";
import { permissionKeys } from "../../../utils/permissions";
import "./OrgchartPage.scss";
import OrgchartCommonDrawer from "../../modules/pages/orgchart/orgchartCommonDrawer/OrgchartCommonDrawer";
import { OrgchartDto, OrgchartItemDto, RoleDto, RolesWithOrgchartDto } from "../../../api";
import { CustomConfirmDialog } from "../../modules/dialogs/customConfirmDialog/СustomConfirmDialog";
import { DropdownMenu } from "../../service/dropdownMenu/dropdownMenu";
import { PopupMenuItemTypes } from "../../service/dropdownMenu/dropdownMenu.interface";
import { Button, TagCheckableGroup, Text } from "../../uiKit";
import { OrgchartSwitcher } from "../../elements/orgchartSwitcher/OrgchartSwitcher";
import OrgchartTypeSelectForm from "../../modules/pages/orgchart/orgchartTypeSelectForm/OrgchartTypeSelectForm";
import { OpeningGuideIcon } from "../../elements/openingGuideIcon/OpeningGuideIcon";
import { guideKeys } from "../../../stores/hintStore";
import OrgchartFunctionCardHubbard from "../../modules/pages/orgchart/orgchartFunctionCardHubbard/OrgchartFunctionCardHubbard";

function OrgchartPage() {
  const { authStore, helperStore, orgchartStore } = useRootStore();
  const { roleId } = useParams();
  const notifier = useNotifier();
  const { t } = useTranslation();
  const [orgchartId, setOrgchartId] = useState(orgchartStore.getCurrentOrgchartId);
  const [orgchartData, setOrgchartData] = useState<RolesWithOrgchartDto | null>(null);
  const [orgchartDataToEdit, setOrgchartDataToEdit] = useState<OrgchartDto | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const switcherRef = createRef<any>();
  // let Sticky = require("../../../scripts/sticky.js");
  const [drawType, setDrawType] = useState<"d" | "c">((orgchartStore.getCurrentOrgchart?.type as "d" | "c") ?? "d");
  const orgchartFilterTags = [
    { text: t("parse:employee_filter"), value: "e" },
    { text: orgchartStore.getCurrentOrgchartResultName, value: "ar" },
  ];
  const [orgchartFilters, setOrgchartFilters] = useState<any[]>(
    () => LocalStorageHelpers.get("orgchartFilters") ?? ["e", "ar"]
  );
  const [orgchartMenuItems, setOrgchartMenuItems] = useState<PopupMenuItemTypes[]>([
    { id: 1, text: t("common:misc.all_recurring_tasks"), action: () => handleAllTasksShow() },
    { id: 2, text: t("common:misc.print_orgchart"), action: () => handleOrgchartPrint() },
  ]);
  const [isDeleteOrgchartDialogOpen, setIsDeleteOrgchartDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    const dT = orgchartStore.getCurrentOrgchart?.type;
    if (dT != drawType && dT != null) {
      setDrawType(dT as "d" | "c");
    }
  }, [authStore.getInitialInfo, orgchartStore.getOrgchartsList, orgchartStore.getCurrentOrgchart]);

  useEffect(() => {
    LocalStorageHelpers.set("orgchartFilters", orgchartFilters);
  }, [orgchartFilters]);

  const claims = useClaims();
  const currentCompanyId = authStore.getCurrentCompanyId;
  // const orgchartData = useApi(() => api.orgchart.getTreeWithRoles(orgchartId as number));

  const fetchOrgchartData = async (oId?: number) => {
    oId != null && orgchartStore.setCurrentOrgchartId(oId);
    if (orgchartStore.getCurrentOrgchartId == null || orgchartStore.getCurrentOrgchart?.isEmpty) {
      setIsLoading(false);
      setOrgchartData(null);
      return;
    }
    !isLoading && setIsLoading(true);
    const r = await api.orgchart.getTreeWithRoles(orgchartStore.getCurrentOrgchartId);
    setIsLoading(false);
    setOrgchartData(r);
    if (r == null) {
      return;
    }
  };

  const handleOrgchartCreate = () => {
    switcherRef && switcherRef.current && switcherRef.current.openCreateDialog();
  };

  const openCreateDialog = useRef<any>(null);

  useEffect(() => {
    let menu: PopupMenuItemTypes[] = [...orgchartMenuItems];

    if (claims.has(permissionKeys.orgchart.edit) && !menu.some((m) => m.id == 0)) {
      menu = [
        {
          id: 0,
          text: t("parse:edit_orgchart"),
          action: () => {
            setOrgchartDataToEdit(orgchartStore.getCurrentOrgchart as OrgchartDto);
            openCreateDialog.current();
          },
        },
        ...menu,
      ];
    }

    if (claims.has(permissionKeys.orgchart.delete) && !menu.some((m) => m.id == 3)) {
      menu = [
        ...menu,
        { id: 3, text: t("common:misc.delete_orgchart"), action: () => setIsDeleteOrgchartDialogOpen(true) },
      ];
    }

    if (!claims.has(permissionKeys.orgchart.edit)) {
      menu = menu.filter((m) => m.id != 0);
    }

    if (!claims.has(permissionKeys.orgchart.delete)) {
      menu = menu.filter((m) => m.id != 3);
    }

    // if (
    //   authStore.getInitialInfo?.identity?.roles?.some((u2r: User2RoleDto) => u2r.roleId == orgchartData?.root?.roleId)
    // ) {
    //   let menu: PopupMenuItemTypes[] = [];
    //   if (orgchartMenuItems.find((oI) => oI.id == 3) == null && claims.has(permissionKeys.orgchart.delete)) {
    //     menu = [
    //       ...menu,
    //       { id: 3, text: t("common:misc.delete_orgchart"), action: () => setIsDeleteOrgchartDialogOpen(true) },
    //     ];
    //   }
    //   if (orgchartMenuItems.find((oI) => oI.id == 2) == null && claims.has(permissionKeys.orgchart.edit)) {
    //     menu = [
    //       ...menu,
    //       {
    //         id: 2,
    //         text: t("parse:edit_orgchart"),
    //         action: () => {
    //           setOrgchartDataToEdit(orgchartStore.getCurrentOrgchart as OrgchartDto);
    //           openCreateDialog.current();
    //           // switcherRef && switcherRef.current && switcherRef.current.openCreateDialog();
    //           // handleOrgchartCreate();
    //         },
    //       },
    //     ];
    //   }
    //   setOrgchartMenuItems([...orgchartMenuItems, ...menu]);
    // } else {
    //   setOrgchartMenuItems(orgchartMenuItems?.filter((oI) => oI.id != 3 && oI.id != 2));
    // }
    setOrgchartMenuItems(menu);
  }, [authStore.getInitialInfo, orgchartData]);

  const handleRefresh = async () => {
    await fetchOrgchartData();
  };
  // TODO: Проверить отрисовку линий

  useEffect(() => {
    if (switcherRef.current == null || switcherRef.current.openCreateDialog == null) return;
    openCreateDialog.current = switcherRef.current.openCreateDialog;
  }, [switcherRef]);

  const changeState = (state: IOrgchartContext) => {
    setOrgchartContextState({ ...orgchartContextInitState, ...state });
  };

  const resetState = () => {
    setOrgchartContextState({ ...orgchartContextInitState });
  };

  const orgchartContextInitState: IOrgchartContext = {
    openedDialog: null,
    blockId: null,
    updateState: changeState,
    resetState: resetState,
    refreshState: handleRefresh,
  };

  const handleOrgchartDelete = async () => {
    setIsLoading(true);
    const r = await api.orgchart.del(orgchartId as number);
    await authStore.refreshInitialInfo();
    setIsDeleteOrgchartDialogOpen(false);
    await fetchOrgchartData();
    setIsLoading(false);
  };

  const handleOrgchartTypeSelect = async (type: any) => {
    if (orgchartId == null) return;
    const r = await api.orgchart.applyTemplate(orgchartId, type);
    if (r == null || !r) {
      notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
      return;
    }
    console.log((authStore.getInitialInfo?.orgcharts ?? []).length);
    if (
      (authStore.getInitialInfo?.orgcharts ?? []).length == 0 ||
      (authStore.getInitialInfo?.orgcharts ?? []).length == 1
    ) {
      await authStore.authorizeWithRefreshToken();
    }
    await authStore.refreshInitialInfo();
    // orgchartStore.setCurrentOrgchartId(orgchartStore.getCurrentOrgchartId);
    await fetchOrgchartData(orgchartStore.getCurrentOrgchartId ?? undefined);
  };

  const handleOrgchartPrint = () => {
    window.print();
  };

  // const sticky = new Sticky(".orgchart-card__content__wrapper");

  const [orgchartContextState, setOrgchartContextState] = useState<IOrgchartContext>(orgchartContextInitState);
  const zoomInitValue: number =
    typeof LocalStorageHelpers.get("orgchartZoom") == "number" ? LocalStorageHelpers.get("orgchartZoom") ?? 1 : 1;
  const [zoom, setZoom] = useState(zoomInitValue);

  useEffect(() => {
    if (roleId != null && roleId.length > 0) {
      setTimeout(() => {
        setOrgchartContextState({
          ...orgchartContextInitState,
          ...{ blockId: Number(roleId), openedDialog: "openFunction" },
        });
      }, 500);
    }
  }, []);

  useEffect(() => {
    if (orgchartContextState.openedDialog == "openFunction") {
      changeURL("/orgchart/" + (orgchartContextState.blockId ?? ""));
    } else if (orgchartContextState.openedDialog == null) {
      changeURL("/orgchart");
    }
  }, [orgchartContextState.blockId]);

  useEffect(() => {
    LocalStorageHelpers.set("orgchartZoom", zoom);
  }, [zoom]);

  const changeURL = (text: string) => {
    // encodeURIComponent(text)
    window.history.pushState({}, "", text);
  };

  const handleAllTasksShow = () => {
    setOrgchartContextState({
      ...orgchartContextState,
      openedDialog: "openDrawer/recurringTasks",
    });
  };

  const getRoleDataById = (roleId: number): RoleDto | null => orgchartData?.roles?.find((r) => r.id == roleId) ?? null;

  const getSortedChildren = (childrenRoles: OrgchartItemDto[]): OrgchartItemDto[] => {
    return [
      ...childrenRoles.sort(
        (a, b) => (getRoleDataById(a.roleId as number)?.order ?? 0) - (getRoleDataById(b.roleId as number)?.order ?? 0)
      ),
    ];
  };

  useEffect(() => {
    setOrgchartId(orgchartStore.getCurrentOrgchartId);
  }, [orgchartStore.getCurrentOrgchartId]);

  useEffect(() => {
    if (orgchartId == null && isLoading) {
      setIsLoading(false);
      return;
    }
    fetchOrgchartData();
  }, [orgchartId]);

  return (
    // @ts-ignore
    <OrgchartContext.Provider value={orgchartContextState}>
      <VideoDialog
        trainingKey="ed.o.s"
        videoId="QxXyP2Eol0A"
        name="orgchartSettings"
        onWatchedClickObject={{ orgchartSettings: true }}
        hidden={
          !claims.hasOneOf([
            permissionKeys.orgchart.role.add,
            permissionKeys.orgchart.role.delete,
            permissionKeys.orgchart.role.sort,
            permissionKeys.orgchart.role.edit,
            permissionKeys.orgchart.role.move,
          ])
        }
      />
      <CustomConfirmDialog
        open={isDeleteOrgchartDialogOpen}
        onClose={() => {
          setIsDeleteOrgchartDialogOpen(false);
        }}
        loading={isLoading}
        onConfirm={() => handleOrgchartDelete()}
        title={t("parse:delete_orgchart_dialog.title")}
        subTitle={t("parse:delete_orgchart_dialog.text")}
      />
      {/* TODO: Normal dialog */}
      <VideoDialog
        videoId="kF6346ToAzw"
        trainingKey="ed.o.i"
        name="orgchart"
        onWatchedClickObject={{ orgchart: true }}
        hidden={authStore.getInitialInfo?.identity?.name?.trim().length == 0}
      />
      {/*authStore.getInitialInfo?.identity?.companies?.find(*/}
      {/*(u2c: User2CompanyDto) => u2c.companyId == authStore.getInitialInfo?.identity?.currentCompanyId*/}
      {/*)?.company?.orgchartType == null*/}
      <PageHeader>
        <OrgchartSwitcher
          ref={switcherRef}
          drawType="textWithMenu"
          createEditDialogValue={orgchartDataToEdit ?? undefined}
          onCreate={(oId) => fetchOrgchartData(oId)}
          onSave={() => {
            setOrgchartDataToEdit(null);
            authStore.refreshInitialInfo().then(() => fetchOrgchartData());
          }}
          onCreateEditDialogClose={() => {
            setOrgchartDataToEdit(null);
          }}
        />
        <OpeningGuideIcon ml={8} contentKey={guideKeys.multiaccount} />
        {!orgchartStore.getCurrentOrgchart?.isEmpty && orgchartId != null && (
          <div className="ml-auto d-stack spacing-2">
            <TagCheckableGroup items={orgchartFilterTags} value={orgchartFilters} onChange={setOrgchartFilters} />
            <ZoomButton value={zoom} onChange={setZoom} step={0.1} min={0.1} max={1.2} />
            <DropdownMenu items={orgchartMenuItems.sort((a, b) => a.id - b.id)} />
          </div>
        )}
      </PageHeader>

      <PageContent
        isLoading={isLoading}
        isDraggable
        isFilled={orgchartStore.getCurrentOrgchart != null && orgchartStore.getCurrentOrgchart?.isEmpty != true}
        isZoomable
        isContentCentered={
          orgchartStore.getCurrentOrgchart == null || orgchartStore.getCurrentOrgchart?.isEmpty == true
        }
        zoomProperty="transform"
        zoom={orgchartStore.getCurrentOrgchart?.isEmpty ? 1 : zoom}
        pageMap={{}}
      >
        {/*COMMON DRAWER*/}
        <OrgchartCommonDrawer
          open={orgchartContextState.openedDialog?.includes("openDrawer") == true}
          onClose={() => {
            setOrgchartContextState({ ...orgchartContextState, openedDialog: null });
            setTimeout(() => {
              resetState();
            }, 300);
          }}
        />
        {/*FUNCTION DRAWER*/}
        <OrgchartFunctionDrawer
          open={orgchartContextState.openedDialog == "openFunction"}
          allRoles={orgchartData?.roles ?? []}
          roleId={orgchartContextState.blockId as number}
          onClose={() => {
            setOrgchartContextState({ ...orgchartContextState, openedDialog: null });
            setTimeout(() => {
              resetState();
            }, 300);
          }}
        />
        {/*ADD DIALOG*/}
        {orgchartContextState.openedDialog == "add" && (
          <OrgchartFunctionCreateEditDialog
            allRoles={orgchartData?.roles ?? []}
            functionId={orgchartContextState.blockId as number}
            onClose={() => resetState()}
            parentColor={orgchartData?.roles?.find((r) => r.id == orgchartContextState.blockId)?.colorHex ?? undefined}
            onSave={() => {
              handleRefresh();
              resetState();
            }}
          />
        )}
        {/*EDIT DIALOG*/}
        {orgchartContextState.openedDialog == "edit" && (
          <OrgchartFunctionCreateEditDialog
            allRoles={orgchartData?.roles ?? []}
            roleId={orgchartContextState.blockId as number}
            onClose={() => resetState()}
            onSave={() => {
              handleRefresh();
              resetState();
            }}
          />
        )}
        {/*MOVE DIALOG*/}
        {orgchartContextState.openedDialog == "move" && (
          <OrgchartFunctionMoveDialog
            allRoles={orgchartData?.roles ?? []}
            roleId={orgchartContextState.blockId as number}
            onClose={() => resetState()}
            onSave={() => {
              handleRefresh();
              resetState();
            }}
          />
        )}
        {/*SORT DIALOG*/}
        {orgchartContextState.openedDialog == "sort" && (
          <OrgchartSortDialog
            allRoles={orgchartData?.roles ?? []}
            roleId={orgchartContextState.blockId as number}
            onClose={() => resetState()}
            onSave={() => {
              handleRefresh();
              resetState();
            }}
          />
        )}
        {/*DELETE DIALOG*/}
        {orgchartContextState.openedDialog == "delete" && (
          <OrgchartFunctionDeleteDialog
            allRoles={orgchartData?.roles}
            roleId={orgchartContextState.blockId}
            onClose={() => resetState()}
            onDelete={() => {
              handleRefresh();
              resetState();
            }}
          />
        )}
        {/* MAIN CONTENT */}
        {/* MAIN CONTENT */}
        {/* MAIN CONTENT */}
        {!isLoading && orgchartStore.getOrgchartsList.length == 0 && (
          <div className="d-stack-column spacing-8">
            <Text
              // type="secondary"
              weight="bold"
              size="32px"
              style={{ opacity: "0.8", whiteSpace: "pre", textAlign: "center" }}
              children={t("parse:no_orgchart")}
            />
            {claims.has(permissionKeys.orgchart.add) && (
              <Button onClick={handleOrgchartCreate} size="large" children={t("parse:create_orgchart")} />
            )}
          </div>
        )}
        {!isLoading && orgchartStore.getCurrentOrgchart?.isEmpty == true && (
          <OrgchartTypeSelectForm onCreate={handleOrgchartTypeSelect} />
        )}
        {!isLoading && orgchartStore.getCurrentOrgchart?.isEmpty == false && (
          <div id="orgchart__wrapper" className={`d-inline-flex`}>
            {drawType == "d" && (
              <OrgchartFunctionCardHubbard
                orgchartDrawType="d"
                zoom={zoom}
                roles={orgchartData?.roles?.sort((a: any, b: any) => a.order - b.order) ?? []}
                blockData={orgchartData?.root ?? null}
                blockRole={orgchartData?.root?.roleId != null ? getRoleDataById(orgchartData?.root?.roleId) : null}
                params={{
                  isRootRole: false,
                  isVertical: false,
                  isRootRoleChild: false,
                  isLastChild: false,
                  hasParent: false,
                  blockId: orgchartData?.root?.roleId,
                }}
                visibilityFilters={{
                  result: orgchartFilters.includes("ar"),
                  employee: orgchartFilters.includes("e"),
                }}
              />
            )}
            {drawType == "c" && (
              <>
                <OrgchartFunctionCardHubbard
                  orgchartDrawType="c"
                  zoom={zoom}
                  roles={orgchartData?.roles?.sort((a: any, b: any) => a.order - b.order) ?? []}
                  blockData={orgchartData?.root ?? null}
                  blockRole={orgchartData?.root?.roleId != null ? getRoleDataById(orgchartData?.root?.roleId) : null}
                  params={{
                    isRootRole: true,
                    isVertical: false,
                    isRootRoleChild: false,
                    isLastChild: false,
                    hasParent: false,
                    blockId: orgchartData?.root?.roleId,
                  }}
                  visibilityFilters={{
                    result: orgchartFilters.includes("ar"),
                    employee: orgchartFilters.includes("e"),
                  }}
                />
                <OrgchartFunctionCardHubbard
                  orgchartDrawType="c"
                  zoom={zoom}
                  roles={orgchartData?.roles?.sort((a: any, b: any) => a.order - b.order) ?? []}
                  blockData={getSortedChildren(orgchartData?.root?.children ?? [])?.[1] ?? null}
                  blockRole={
                    (getSortedChildren(orgchartData?.root?.children ?? [])?.[1]?.roleId != null
                      ? getRoleDataById(getSortedChildren(orgchartData?.root?.children ?? [])?.[1]?.roleId as number)
                      : null) as any
                  }
                  params={{
                    isRootRole: false,
                    isVertical: false,
                    isRootRoleChild: true,
                    isLastChild: false,
                    hasParent: false,
                    blockId: getSortedChildren(orgchartData?.root?.children ?? [])?.[1]?.roleId,
                  }}
                  visibilityFilters={{
                    result: orgchartFilters.includes("ar"),
                    employee: orgchartFilters.includes("e"),
                  }}
                />
              </>
            )}
          </div>
        )}
      </PageContent>
    </OrgchartContext.Provider>
  );
}

export default observer(OrgchartPage);
