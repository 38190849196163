import React from "react";
import { Badge, BadgeProps } from "antd";
import "./Badge.scss";
import clsx from "clsx";

interface IBadgeView extends BadgeProps {
  blink?: boolean;
}

export function BadgeView({ className, blink, ...rest }: IBadgeView) {
  return <Badge className={clsx("uiKit-badge", className ?? "", { __blink: blink })} {...rest} />;
}
