import React, { useRef, useState } from "react";
import { EditContentView } from "./EditContentView";
import { emptyEditorJSON } from "../../../utils/textEditor";
import { InitialEditorStateType } from "@lexical/react/LexicalComposer";

const EditContent = () => {
  const [content, setContent] = useState<InitialEditorStateType>(emptyEditorJSON);
  const [uploadFileName, setUploadFileName] = useState<string | undefined>(undefined);
  const resetRef = useRef<{ reset: () => void; clearValue: () => void; syncValue: () => void }>();

  const handleResetEditor = () => {
    resetRef.current?.reset();
  };

  const handleResetValue = () => {
    resetRef.current?.clearValue();
  };

  const handleSyncChildren = () => {
    resetRef.current?.syncValue();
  };

  // const handleDownloadJsonFile = () => {
  //   const fileName = uploadFileName;
  //   const data = new Blob([JSON.stringify(content)], { type: "text/json" });
  //   const jsonURL = window.URL.createObjectURL(data);
  //   const link = document.createElement("a");
  //   link.href = jsonURL;
  //   link.setAttribute("download", fileName ? fileName : "content.json");
  //   document.body.appendChild(link);
  //   link.click();
  //   link.parentNode?.removeChild(link);
  // };
  //
  // const handleUploadJsonFile = (uploadedFile: File | undefined) => {
  //   setUploadFileName(uploadedFile?.name);
  //   const fileReader = new FileReader();
  //   fileReader.onloadend = async () => {
  //     if (typeof fileReader.result === "string") {
  //       setContent(JSON.parse(fileReader.result));
  //       handleResetEditor();
  //       handleResetValue();
  //     }
  //   };
  //   if (uploadedFile !== undefined) fileReader.readAsText(uploadedFile);
  // };

  const handleUploadContentToServer = async () => {
    // const r = await api.helper.editorContent(content);
    // if (r) {
    //   setContent(r);
    //   handleSyncChildren();
    // }
  };

  const handleContentChange = (value: InitialEditorStateType) => {
    setContent(value);
  };

  return (
    <EditContentView
      ref={resetRef}
      content={content}
      onContentChange={handleContentChange}
      // onDownloadJsonFile={handleDownloadJsonFile}
      // onUploadJsonFile={handleUploadJsonFile}
      onUploadContentToServer={handleUploadContentToServer}
      setUploadFileName={setUploadFileName}
    />
  );
};

export default EditContent;
