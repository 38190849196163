import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { RegulationDto } from "../../../../api";
import { useNotifier } from "../../../../hooks";
import { api } from "../../../../services";
import {IRegulationStatsDialog} from "./RegulationStatsDialog.interface";
import { RegulationStatsDialogView } from "./RegulationStatsDialogView";

export enum policyTabKeys {
  overall = "0",
  studied = "1",
  to_study = "2",
}

export const RegulationStatsDialog = ({ open, onClose, userName }: IRegulationStatsDialog) => {

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const notifier = useNotifier();
  const { t } = useTranslation();

  const pageRef = useRef(1);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activeTabKey, setActiveTabKey] = useState<policyTabKeys>(policyTabKeys.overall);
  const [regulations, setRegulations] = useState<RegulationDto[]>([]);
  const [isDone, setIsDone] = useState<boolean>(false);

  const pathnamesArr = (pathname.split("/").filter(item => item.length > 0));
  const userId = Number(pathnamesArr[pathnamesArr.length - 1]);

  const handleGetIsStudied = (): undefined | boolean => {
    switch (activeTabKey) {
      case policyTabKeys.overall :
        return undefined;
      case policyTabKeys.studied :
        return true;
      case policyTabKeys.to_study :
        return false;
    }
  };

  const handleChangeActiveTab = (newValue: string) => setActiveTabKey(newValue as policyTabKeys);

  const handlePageIncrement = () => pageRef.current += 1;
  const handlePageReset = () => pageRef.current = 1;
  const handleResetIsDone = () => setIsDone(false);
  const handleResetRegulations = () => setRegulations([]);

  const handleLoad = (page ?: number) => {
    api.regulation.getAll({
      withContextForUserId: userId,
      isStudied: handleGetIsStudied(),
      page: pageRef.current
    }).then(res => {
      if(res?.items) {
        setRegulations(prev => [...prev,...res.items]);
        res?.items.length < 10 && setIsDone(true);
      }
    })
      .catch(() => {
        notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
        navigate("/regulation");
      })
      .finally(() => setIsLoading(false));
  };

  const handleLoadNext = () => {
    handlePageIncrement();
    handleLoad();
  };


  useEffect(() => {
    setIsLoading(true);
    handleResetRegulations();
    handleResetIsDone();
    handlePageReset();
    if (userId) handleLoad();
    else navigate("/regulation/stats");
  }, [activeTabKey]);


  return (
    <RegulationStatsDialogView
      open={open}
      userName={userName}
      isDone={isDone}
      isLoading={isLoading}
      onClose={onClose}
      activeTabKey={activeTabKey}
      regulations={regulations}
      onLoadNext={handleLoadNext}
      onChangeActiveTab={handleChangeActiveTab}
    />
  );
};