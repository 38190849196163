import React, { useEffect, useState } from "react";
import { IPeriod, IPickPeriodButton } from "./PickPeriodButton.interface";
import { useTranslation } from "react-i18next";
import { useDateHelpers } from "../../../hooks";
import AdminDriverPeriodSelectDialog from "../../modules/dialogs/adminDriverPeriodSelectDialog/AdminDriverPeriodSelectDialog";
import { PickPeriodButtonView } from "./PickPeriodButtonView";
import dayjs from "dayjs";

export const PickPeriodButton = (props: IPickPeriodButton) => {
  const { t } = useTranslation();
  const dateHelpers = useDateHelpers();

  const [valuePrev, setValuePrev] = useState<IPeriod>();
  const [valueNext, setValueNext] = useState<IPeriod>();
  const [valueButtonText, setValueButtonText] = useState<string>("");
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);

  const handleCloseDialog = () => {
    setValuePrev(props.period);
    setValueNext(props.period);
    setIsOpenDialog(false);
  };

  const handleOpenDialog = () => setIsOpenDialog(true);

  const handleSaveValue = () => {
    if (!!valueNext || props.allowNull) {
      setValuePrev(valueNext);
      props.onChangePeriod(valueNext);
    }
    setIsOpenDialog(false);
  };

  const handlerPeriodChange = () => {
    setValuePrev(props.period);
    setValueNext(props.period);
  };

  useEffect(() => {
    if (!valueNext) return setValueButtonText(t("common:misc.time_picker.select_date").toString());
    const currentYear = new Date().getFullYear();
    const fromYear = new Date(valueNext.from).getFullYear();
    const toYear = new Date(valueNext.from).getFullYear();
    // Если выбран один день
    if (dayjs(valueNext.from).isSame(dayjs(valueNext.to), "day")) {
      setValueButtonText(
        dateHelpers.formatDateString(valueNext.from, { month: "short", hour: undefined, minute: undefined })
      );
    } else {
      setValueButtonText(
        dateHelpers.formatDateString(valueNext.from, {
          hour: undefined,
          minute: undefined,
          showYearWhenIsCurrent: fromYear != currentYear || toYear != currentYear,
          month: "short",
        }) +
          " - " +
          dateHelpers.formatDateString(valueNext.to, {
            hour: undefined,
            minute: undefined,
            showYearWhenIsCurrent: fromYear != currentYear || toYear != currentYear,
            month: "short",
          })
      );
    }
  }, [valueNext]);

  useEffect(handlerPeriodChange, [props.period]);

  return (
    <>
      <AdminDriverPeriodSelectDialog
        value={valueNext}
        open={isOpenDialog}
        onClose={handleCloseDialog}
        allowNull={props.allowNull}
        onChange={(value: IPeriod | undefined) => setValueNext(value)}
        onSave={handleSaveValue}
        disableFuture
      />
      <PickPeriodButtonView text={valueButtonText} onClick={handleOpenDialog} className={props.className} />
    </>
  );
};
