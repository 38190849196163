import React from "react";
import { Layout, LayoutProps } from "antd";
import "./Layout.scss";
import clsx from "clsx";

interface ILayoutView extends LayoutProps {
  //
}

export function LayoutView({ className, ...rest }: ILayoutView) {
  return <Layout className={clsx("uiKit-layout", className ?? "")} {...rest} />;
}
