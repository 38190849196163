import React from "react";
import { CreateRegulationForm } from "../../../forms/createRegulatiomForm/CreateRegulatiomForm";
import { CenterPageContentLayout } from "../../../layouts/centerPageContentLayout/CenterPageContentLayout";

export const CreateRegulationContent = () => {
  return (
    <CenterPageContentLayout >
      <CreateRegulationForm />
    </CenterPageContentLayout>
  );
};

