import React, { useEffect, useRef, useState } from "react";
import ScheduleDaysRowView from "./ScheduleDaysRowView";
import { SelectableGroup } from "react-selectable-fast";

interface IScheduleDaysRow {
  children: React.ReactNode;
  cardsSelectionResetTrigger: number;
  scale: number;

  handleSelectionFinish: (items: any) => void;
}

function ScheduleDaysRow(props: IScheduleDaysRow) {
  const [sT, setST] = useState<number>(props.cardsSelectionResetTrigger);
  const selectableGroupRef = useRef<SelectableGroup | null>(null);

  const resetSelection = () => {
    selectableGroupRef.current?.clearSelection();
  };

  useEffect(() => {
    if (sT != props.cardsSelectionResetTrigger) {
      resetSelection();
      setST(props.cardsSelectionResetTrigger);
    }
  }, [props.cardsSelectionResetTrigger]);

  return <ScheduleDaysRowView selectableGroupRef={selectableGroupRef} {...props} />;
}

export default ScheduleDaysRow;
