import React, { useContext } from "react";
import { IDropdownContentElement, IFilterDropdownContent } from "./FilterDropdownContent.interface";
import { useTranslation } from "react-i18next";
import SubheaderText from "../../../../../elements/subheaderText/SubheaderText";
import { RelationsKeys } from "../../constants/keys";
import { Segmented, Switch } from "../../../../../uiKit";
import { BoardFilterContext } from "../../../../../../contexts/communication/boardFilterContext";
import { AutocompleteAsync } from "../../../../../elements/autocompleteAsync/AutocompleteAsync";
import { SegmentedLabeledOption } from "antd/lib/segmented";

const FilterDropdownContentMemo = (props: IFilterDropdownContent) => {
  const { userRelation, senderId, executorId, hasViolation, flowType } = useContext(BoardFilterContext);
  const { t } = useTranslation();

  const executorSelect = (
    <>
      <SubheaderText text={t("ui:subheader.executor")} />
      <div className="d-stack-row justify-start align-center spacing-1 mb-2">
        <div
          style={{
            maxWidth: "240px !important",
            width: "240px !important",
          }}
        >
          <AutocompleteAsync
            style={{ minWidth: 270 }}
            dropdownStyle={{ zIndex: 1300 }}
            type="user"
            allowClear
            placeholder={t("ui:placeholder.click_to_select")}
            value={executorId}
            onChange={props.onChangeExecutorId}
          />
        </div>
      </div>
    </>
  );

  const senderSelect = (
    <>
      <SubheaderText text={t("ui:subheader.issue_sender")} />
      <div className="d-stack-row justify-start align-center spacing-1">
        <div
          style={{
            maxWidth: "240px !important",
            width: "240px !important",
          }}
        >
          <AutocompleteAsync
            style={{ minWidth: 270 }}
            dropdownStyle={{ zIndex: 1300 }}
            type="user"
            allowClear
            placeholder={t("ui:placeholder.click_to_select")}
            value={senderId}
            onChange={props.onChangeSenderId}
          />
        </div>
      </div>
    </>
  );

  const hasViolationSwitch = (
    <>
      <Switch
        className="mt-3 mb-2"
        label={t("ui:label.issues_with_violations").toString()}
        checked={hasViolation}
        onChange={props.onChangeHasViolation}
      />
    </>
  );

  const flowTypeSegment: SegmentedLabeledOption[] = [
    {
      value: 0,
      disabled: false,
      label: t("parse:no"),
    },
    {
      // @ts-ignore
      value: 3,
      disabled: false,
      // label: `<${t("parse:emptiness")}>`,
      label: t("parse:by_default"),
    },
    {
      value: 1,
      disabled: false,
      label: t("parse:yes"),
    },
  ];

  const onVerificationSegment = (
    <>
      <SubheaderText className="mt-1" text={t("ui:label.on_verification")} />
      {/* @ts-ignore*/}
      <Segmented options={flowTypeSegment} value={flowType} onChange={props.onChangeFlowTypeFilter} />
    </>
  );

  const onVerificationSwitch = (
    <>
      <Switch
        label={t("ui:label.on_verification").toString()}
        checked={!!flowType}
        //onChange={props.onChangeFlowTypeFilter}
      />
    </>
  );

  const handleVerifyToRenderElement = (elementId: number): boolean =>
    elements.some((element) => element.id == elementId && element.allowedRelationsToRender.includes(userRelation));

  const elements: IDropdownContentElement[] = [
    {
      id: 1,
      name: "executor_select",
      allowedRelationsToRender: [RelationsKeys.Participated, RelationsKeys.Controlled, RelationsKeys.Participated],
      component: <React.Fragment key={1}>{executorSelect}</React.Fragment>,
    },
    {
      id: 2,
      name: "sender_select",
      allowedRelationsToRender: [RelationsKeys.My, RelationsKeys.Participated],
      component: <React.Fragment key={2}>{senderSelect}</React.Fragment>,
    },
    {
      id: 3,
      name: "has_violation_switch",
      allowedRelationsToRender: [RelationsKeys.My, RelationsKeys.Controlled, RelationsKeys.Participated],
      component: <React.Fragment key={3}>{hasViolationSwitch}</React.Fragment>,
    },
    {
      id: 4,
      name: "on_verification_segment",
      allowedRelationsToRender: [RelationsKeys.My, RelationsKeys.Controlled, RelationsKeys.Participated],
      component: <React.Fragment key={4}>{onVerificationSegment}</React.Fragment>,
    },
  ];

  return (
    <div className="pa-2" style={{ width: "320px !important" }}>
      {elements.filter((item) => handleVerifyToRenderElement(item.id)).map((item) => item.component)}
    </div>
  );
};

export const FilterDropdownContent = React.memo(FilterDropdownContentMemo);
