import React, { useContext, useEffect, useState } from "react";
import { IssuesArchiveView } from "./IssuesArchiveView";
import { IIssuesArchive } from "./IssuesArchive.interface";
import { useNotifier, useRootStore } from "../../../../hooks";
import { useTranslation } from "react-i18next";
import { usePagingWithController } from "../../../../hooks/usePaging";
import { api } from "../../../../services";
import useDebounce from "../../../../hooks/useDebounce";
import { BoardFilterContext } from "../../../../contexts/communication/boardFilterContext";

export const IssuesArchive = (props: IIssuesArchive) => {
  const { t } = useTranslation();
  const { authStore } = useRootStore();
  const notifier = useNotifier();
  const context = useContext(BoardFilterContext);

  const [issueName, setIssueName] = useState<string | undefined>(undefined);
  const [orgchartId, setOrgchartId] = useState<number | undefined>(undefined);
  const [executorId, setExecutorId] = useState<number | undefined>();
  const [senderId, setSenderId] = useState<number | undefined>(undefined);
  const [isActiveTriggerToreload, setIsActiveTriggerToreload] = useState<boolean>(false);

  const handleChangeIssueName = (name: string) => setIssueName(name);
  const handleChangeOrgchartId = (id: number) => {
    setOrgchartId(id);
    setIsActiveTriggerToreload(true);
  };

  const handleChangeExecutorId = (id: number) => {
    setExecutorId(id);
    setIsActiveTriggerToreload(true);
  };

  const handleChangeSenderId = (id: number) => {
    setSenderId(id);
    setIsActiveTriggerToreload(true);
  };

  const handleApiError = () => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });

  const debounceTrigger = useDebounce(issueName, 500);

  const issues = usePagingWithController(
    api.issueShort,
    {
      boardId: context.boardId ?? null,
      isArchived: true,
      orgchartId,
      search: issueName,
      executorUserId: executorId,
      createdByUserId: senderId,
      userRelation: context.userRelation,
    },
    { pageSize: 10 },
    undefined,
    handleApiError
    //handlerSuccessRequestEnd
  );

  const resetFilters = () => {
    setExecutorId(undefined);
    setIssueName(undefined);
    setOrgchartId(undefined);
    setSenderId(undefined);
  };

  useEffect(() => {
    if (props.isOpen) {
      resetFilters();
      setIsActiveTriggerToreload(true);
    }
  }, [props.isOpen]);

  useEffect(() => {
    issues.restart(true);
  }, [debounceTrigger]);

  useEffect(() => {
    if (isActiveTriggerToreload) {
      issues.restart(true);
      setIsActiveTriggerToreload(false);
    }
  }, [isActiveTriggerToreload]);

  return (
    <IssuesArchiveView
      name={issueName}
      senderId={senderId}
      executorId={executorId}
      onChangeSenderId={handleChangeSenderId}
      orgchartId={orgchartId}
      onChangeOrgchartId={handleChangeOrgchartId}
      onChangeName={handleChangeIssueName}
      onChangeExecutorId={handleChangeExecutorId}
      issues={issues.items}
      isDone={issues.info.isDone}
      loadNext={issues.loadNext}
      {...props}
    />
  );
};
