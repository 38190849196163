import {
  createAlignPlugin,
  createAutoformatPlugin,
  createBlockquotePlugin,
  createBoldPlugin,
  createComboboxPlugin,
  createDeserializeCsvPlugin,
  createDeserializeDocxPlugin,
  createDeserializeHtmlPlugin,
  createDeserializeMdPlugin,
  createExitBreakPlugin,
  createFontBackgroundColorPlugin,
  createFontColorPlugin,
  createFontFamilyPlugin,
  createFontSizePlugin,
  createHeadingPlugin,
  createHighlightPlugin,
  createImagePlugin,
  createIndentListPlugin,
  createItalicPlugin,
  createLineHeightPlugin,
  createLinkPlugin,
  createListPlugin,
  createMediaEmbedPlugin,
  createMentionPlugin,
  createNormalizeTypesPlugin,
  createParagraphPlugin,
  createPlateUI,
  createPlugins,
  createResetNodePlugin,
  createSelectOnBackspacePlugin,
  createSoftBreakPlugin,
  createStrikethroughPlugin,
  createTablePlugin,
  createTextIndentPlugin,
  createTrailingBlockPlugin,
  createUnderlinePlugin,
  ELEMENT_LINK,
  MentionElementProps,
  Value,
} from "@udecode/plate";
import { createJuicePlugin } from "@udecode/plate-juice";
import { createAnchorPlugin } from "../components/Anchor/createAnchorPlugin";
import { createIFramePlugin, ELEMENT_IFRAME } from "../components/IFrame/createIFramePlugin";
import { IFrameElement } from "../components/IFrame/IFrameElement";
import { LinkElement } from "../components/Link/LinkElement";
import { createOrgchartCardPlugin } from "../components/OrgchartCard/createOrgchartCardPlugin";
import { createRolePlugin } from "../components/RoleIdHandler/createRolePlugin";
import { Config } from "./Config";

export const usePlugins = (
  readOnly: boolean,
  mentionPlugin: {
    key: string;
    component: <V extends Value>(props: MentionElementProps<V>) => JSX.Element;
    props: () => {
      renderLabel: (element: any) => JSX.Element;
    };
    options: {};
  }
) => {
  const components = createPlateUI({
    [ELEMENT_LINK]: LinkElement,
    [ELEMENT_IFRAME]: IFrameElement,
  });

  return createPlugins(
    [
      createIFramePlugin(),
      createParagraphPlugin(),
      createBlockquotePlugin(),
      createHeadingPlugin(),
      createImagePlugin(),
      createMediaEmbedPlugin(),
      createLinkPlugin(),
      createListPlugin(),
      createTablePlugin(),
      createStrikethroughPlugin(),
      createAlignPlugin(Config.align),
      createLineHeightPlugin(Config.lineHeight),
      createBoldPlugin(),
      createItalicPlugin(),
      createUnderlinePlugin(),
      createFontBackgroundColorPlugin(),
      createFontFamilyPlugin(),
      createFontColorPlugin(),
      createFontSizePlugin(),
      createHighlightPlugin(),
      createIndentListPlugin(),
      createTextIndentPlugin(),
      createResetNodePlugin(Config.resetBlockType),
      createSoftBreakPlugin(Config.softBreak),
      createExitBreakPlugin(Config.exitBreak),
      createNormalizeTypesPlugin(Config.forceLayout),
      createTrailingBlockPlugin(Config.trailingBlock),
      createSelectOnBackspacePlugin(Config.selectOnBackspace),
      createComboboxPlugin(),
      createRolePlugin(),
      createOrgchartCardPlugin({
        props: () => {
          return { nodeProps: { readOnly: readOnly } };
        },
      }),
      createMentionPlugin(mentionPlugin),
      createAnchorPlugin({
        props: () => {
          return { nodeProps: { readOnly: readOnly } };
        },
      }),
      createDeserializeMdPlugin(),
      createDeserializeCsvPlugin(),
      createDeserializeDocxPlugin(),
      createDeserializeHtmlPlugin(),
      createJuicePlugin(),
      createAutoformatPlugin(),
    ],
    { components }
  );
};
