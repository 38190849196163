import React from "react";
import "./CustomConfirmDialog.scss";
import { ICustomConfirmDialog } from "./CustomConfirmDialog.interface";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions, Text } from "../../../uiKit";
import clsx from "clsx";

export const CustomConfirmDialog: React.FC<ICustomConfirmDialog> = (props) => {
  const { t } = useTranslation();

  const dialogStyles = clsx("custom-confirm-dialog", { "custom-confirm-dialog_no-content": !props.subTitle });

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      title={props.title ?? t("ui:title.confirm")}
      className={dialogStyles}
      bodyStyle={{ display: !props.subTitle ? "none" : "" }}
    >
      {props.subTitle && <Text>{props.subTitle} </Text>}
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t("ui:button.cancel")}
        </Button>
        <Button variant="filled" loading={props.loading} onClick={props.onConfirm}>
          {t("ui:button.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
