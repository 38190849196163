import React from "react";
import { useTranslation } from "react-i18next";
import { Drawer, Empty, IconButton } from "../../../uiKit";
import { IIssuesArchiveView } from "./IssuesArchive.interface";
import { FiX } from "@react-icons/all-files/fi/FiX";
import { SearchInputField } from "../../../elements/searchInputField/SearchInputField";
import { OrgchartSwitcher } from "../../../elements/orgchartSwitcher/OrgchartSwitcher";
import { CardIssue } from "../../pages/communications/components/cardIssue/CardIssue";
import ScrollTrigger from "../../../service/scrollTrigger/ScrollTrigger";
import SubheaderText from "../../../elements/subheaderText/SubheaderText";
import { AutocompleteAsync } from "../../../elements/autocompleteAsync/AutocompleteAsync";
import { useRootStore } from "../../../../hooks";

export const IssuesArchiveView = (props: IIssuesArchiveView) => {
  const { t } = useTranslation();
  const { authStore } = useRootStore();

  return (
    <Drawer
      width={400}
      title={t("ui:title.archvive_of_issues")}
      closable={false}
      extra={
        <IconButton size="large" onClick={props.onClose}>
          <FiX />
        </IconButton>
      }
      placement="right"
      open={props.isOpen}
      onClose={props.onClose}
    >
      <div className="d-stack-row align-center justify-space-between spacing-2 mb-2">
        <div style={{ width: "48%" }}>
          <SubheaderText text={t("ui:subheader.issue_name")} />
          <SearchInputField
            value={props.name ?? ""}
            setValue={props.onChangeName}
            placeholder={t("ui:placeholder.issue_name")}
          />
        </div>
        <div style={{ width: "48%" }}>
          <SubheaderText text={t("ui:subheader.orgchart")} />
          <OrgchartSwitcher
            value={props.orgchartId}
            onChange={(value) => props.onChangeOrgchartId(value as number)}
            selectProps={{
              allowClear: true,
              style: { width: "100%" },
              placeholder: t("ui:placeholder.click_to_select"),
            }}
            preventDefault
            drawType="select"
          />
        </div>
      </div>
      <div className="d-stack-row align-center justify-space-between spacing-2 mb-4">
        <div style={{ width: "48%" }}>
          <SubheaderText text={t("ui:subheader.issue_sender")} />
          <AutocompleteAsync
            className="mb-1 full-width"
            type="user"
            size="large"
            allowClear
            additionalItems={[
              {
                id: authStore.getInitialInfo?.identity?.id,
                text: authStore.getInitialInfo?.identity?.nameFallback,
              },
            ]}
            placeholder={t("ui:placeholder.click_to_select")}
            value={props.senderId}
            onChange={props.onChangeSenderId}
          />
        </div>
        <div style={{ width: "48%" }}>
          <SubheaderText text={t("ui:subheader.issue_executor")} />
          <AutocompleteAsync
            className="mb-1 full-width"
            type="user"
            size="large"
            allowClear
            additionalItems={[
              {
                id: authStore.getInitialInfo?.identity?.id,
                text: authStore.getInitialInfo?.identity?.nameFallback,
              },
            ]}
            placeholder={t("ui:placeholder.click_to_select")}
            value={props.executorId}
            onChange={props.onChangeExecutorId}
          />
        </div>
      </div>
      {props.issues?.map((issue, index) => (
        <CardIssue noDrag key={issue.id} issue={issue} />
      ))}
      {props.isDone && !props.issues?.length && <Empty style={{ height: "80%" }} />}
      <div style={{ width: "100%" }}>
        <ScrollTrigger
          disabled={props.isDone}
          onIntersection={props.loadNext}
          marginTop={(props.issues ?? []).length > 0}
          hidden={props.isDone}
        />
      </div>
    </Drawer>
  );
};
