import React from "react";
import { ListItemMetaProps } from "antd/es/list";
import { List } from "antd";
import "./ListItemMeta.scss";
import clsx from "clsx";

interface IListItemMetaView extends ListItemMetaProps {
  //
}

const { Item } = List;
const { Meta } = Item;

export function ListItemMetaView({ className, ...rest }: IListItemMetaView) {
  return <Meta className={clsx("uiKit-listItemMeta", className ?? "")} {...rest} />;
}
