import React from "react";
import "./ListCards.scss";
import { useTranslation } from "react-i18next";
import { IListSectionCards, paginationTypeKeys } from "./ListCards.interface";
import { Button, Card, Divider, Empty } from "../../../../../uiKit";
import ScrollTrigger from "../../../../../service/scrollTrigger/ScrollTrigger";
import { CardSection } from "../CardSection/CardSection";

export const ListSections = ({ sections, isDone, loadNext, paginationType, isLoading }: IListSectionCards) => {
  const { t } = useTranslation();

  return (
    <Card title={t("ui:title.topics")} size="default" bodyStyle={{ padding: 0 }} style={{ overflow: "hidden" }}>
      {isDone && sections.length === 0 ? (
        <Empty className="my-4" />
      ) : (
        <div>
          {sections.map((item, index) => (
            <React.Fragment key={item.id}>
              {index > 0 && <Divider />}
              <CardSection section={item} />
            </React.Fragment>
          ))}
          {sections.length !== 0 && (
            <>
              {paginationType === paginationTypeKeys.byScroll && (
                <ScrollTrigger
                  fullWidth
                  disabled={isDone}
                  onIntersection={loadNext ?? function () {}}
                  marginTop={sections.length > 0}
                  hidden={isDone}
                />
              )}
              {paginationType === paginationTypeKeys.byButton && !isDone && (
                <Button
                  variant="text"
                  style={{ marginTop: "16px", marginBottom: "8px" }}
                  size="large"
                  loading={isLoading}
                  onClick={loadNext}
                >
                  {t("parse:more")}
                </Button>
              )}
            </>
          )}
        </div>
      )}
    </Card>
  );
};
