import React from "react";
import "./AudioPlayer.scss";
import { Card } from "../card/Card";
import { Icon } from "../icon/Icon";
import { FiPause, FiPlay } from "react-icons/all";
import { IconButton } from "../general/iconButton/IconButton";
import { IAudioPlayerState, TAudioPlayerAudioPreloadAttribute, TAudioPlayerType } from "./AudioPlayer";
import { Text } from "../general/typography";
import { WaveForm } from "../waveForm/WaveForm";
import { Button } from "../general/button/Button";
import { Tooltip } from "../dataDisplay/tooltip/Tooltip";
import { Dropdown } from "../navigation/dropdown/Dropdown";

interface IAudioPlayerView {
  state: IAudioPlayerState;
  audioRef: React.RefObject<HTMLAudioElement>;

  type: TAudioPlayerType;
  src?: string;

  crossOrigin?: string;
  mediaGroup?: string;
  preload?: TAudioPlayerAudioPreloadAttribute;

  autoPlay?: boolean;
  autoPlayAfterSrcChange?: boolean;
  loop?: boolean;
  muted?: boolean;
  volume?: number;

  setState: (state: IAudioPlayerState) => void;
  onPlayPauseClick: (e: React.SyntheticEvent) => void;
  onProgressChange: (progress: number) => void;
}

export function AudioPlayerView(props: IAudioPlayerView) {
  return (
    <Card isShadowed={false} variant="secondary">
      <div className="d-stack spacing-3 align-center">
        <IconButton
          onClick={props.onPlayPauseClick}
          disabled={(props.src ?? "").trim().length == 0}
          type="default"
          shape="circle"
          children={
            <Icon component={() => (!props.state.isPaused && !props.state.isStopped ? <FiPause /> : <FiPlay />)} />
          }
        />
        <audio
          ref={props.audioRef}
          src={props.src}
          controls={false}
          loop={props.loop}
          autoPlay={props.autoPlay}
          preload={props.preload}
          crossOrigin={props.crossOrigin as any}
          mediaGroup={props.mediaGroup}
        />
        <div className="flex-grow-1">
          {props.src != null && props.src.trim().length > 0 && (
            <WaveForm
              audioSrc={props.src}
              progress={props.state.progress}
              height={30}
              onSeek={props.onProgressChange}
            />
          )}
        </div>
        <Tooltip title={`${props.state.currentTime} / ${props.state.duration}`}>
          <Text
            className="flex-shrink-0 d-inline-block"
            style={{
              textAlign: "end",
              fontVariantNumeric: "tabular-nums",
              cursor: "default",
              // width: `${Math.ceil((props.state.currentTime.length + props.state.duration.length + 3) / 1.8)}em`,
            }}
            size="12px"
            type="secondary"
            children={`${props.state.currentTime}`}
          />
        </Tooltip>
        <Dropdown
          trigger={["hover"]}
          placement="bottomRight"
          mouseEnterDelay={1}
          items={[0.5, 1, 1.5, 2].map((v) => ({
            key: v,
            text: `x${v}`,
            disabled: props.state.playbackRate == v,
            onClick: () => props.setState({ ...props.state, playbackRate: v }),
          }))}
        >
          <Button
            size="small"
            type="default"
            children={"×" + props.state.playbackRate}
            onClick={() => props.setState({ ...props.state, playbackRate: props.state.playbackRate != 1 ? 1 : 1.5 })}
            style={{ boxShadow: "none", border: "none", fontSize: "12px" }}
          />
        </Dropdown>
        {/*<Select*/}
        {/*  size="small"*/}
        {/*  value={props.state.playbackRate}*/}
        {/*  onChange={(val) => props.setState({ ...props.state, playbackRate: val })}*/}
        {/*  items={[*/}
        {/*    { id: 0, text: "x0.5", value: 0.5 },*/}
        {/*    { id: 1, text: "x1", value: 1 },*/}
        {/*    { id: 2, text: "x1.5", value: 1.5 },*/}
        {/*    { id: 3, text: "x2", value: 2 },*/}
        {/*  ]}*/}
        {/*/>*/}
      </div>
    </Card>
  );
}
