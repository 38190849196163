import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { EditableTypography } from "../../../elements/editableTypography/EditableTypography";
import { AnswersGenerator } from "./AnswersGenerator";
import { IQuestion } from "./CreateTestsForm.interface";
import { FiX } from "@react-icons/all-files/fi/FiX";
import { IconButton } from "../../../uiKit";

export const Question: React.FC<IQuestion> = (props) => {

  const { t } = useTranslation();

  const [isFocus, setIsFocus] = useState<boolean>(false);

  const handleChangeIsFocus = (focus: boolean) => setIsFocus(focus);

  return (
    <>
      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
        <EditableTypography
          placeholder={t("ui:placeholder.come_up_question")}
          value={props.name ?? ""}
          onChange={(newValue) => props.onChangeQuestionName(newValue, props.questionId!)}
          onChangeFocus={handleChangeIsFocus}
        />
        {!isFocus && <IconButton className="list-companies-card__delete-icon ml-1" onClick={() => props.onDeleteQuestion(props.questionId!)}>
          <FiX style={{ width: "15px", height: "15px" }} />
        </IconButton>}
        {/*{!isFocus && <Button*/}
        {/*  variant="text"*/}
        {/*  shape="circle"*/}
        {/*  className="badge-being-deleted badge-being-deleted__remove-icon"*/}
        {/*  onClick={() => props.onDeleteQuestion(props.questionId!)} aria-label="delete" size="small">*/}
        {/*  <FiX />*/}
        {/*</Button>}*/}
      </div>
      <AnswersGenerator
        questionId={props.questionId}
        // onAttachAnswers={props.onAttachAnswers}
        disabled={!props.name.trim().length}
        answers={props.answers ?? []}
        onAppendEmptyAnswer={props.onAppendEmptyAnswer}
        onChangeAnswerRight={props.onChangeAnswerRight}
        onChangeAnswerName={props.onChangeAnswerName}
        onDeleteAnswer={props.onDeleteAnswer}
      />
    </>
  );
};

