import React from "react";
import { SwitchView } from "./SwitchView";
import { FormItemProps, SwitchProps } from "antd";

interface ISwitch extends SwitchProps {
  theme?: "error" | "success" | "warning" | "info" | "default";
  label?: string | JSX.Element | null;
  formItemProps?: FormItemProps;
  useAsPureComponent?: boolean;
  readOnly?: boolean;
}

export function Switch(props: ISwitch) {
  return <SwitchView {...props} />;
}
