import React, { useEffect, useState } from "react";
import { TagCheckableGroupView } from "./TagCheckableGroupView";
import { areArraysEqual } from "../../../../../helpers/arrayFunctions";

export type TTagCheckableGroupItem = {
  text: string;
  value: string | number;
  icon?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
};

interface ITagCheckableGroup {
  className?: string;
  value: (string | number)[];
  items: TTagCheckableGroupItem[];
  onChange: (value: (string | number)[]) => void;
}

export function TagCheckableGroup(props: ITagCheckableGroup) {
  const [values, setValues] = useState<(string | number)[]>([]);

  const handleChange = (value: string | number) => {
    if (values.find((v) => v == value) != null) {
      const newValue = values.filter((v) => v != value);
      setValues(newValue);
      props.onChange(newValue);
    } else {
      const newValue = [...values, value];
      setValues(newValue);
      props.onChange(newValue);
    }
  };

  useEffect(() => {
    if (areArraysEqual(props.value, values)) return;
    setValues(props.value);
  }, [props.value]);

  useEffect(() => {
    // if (areTwoArraysEqual(props.value, values)) return;
    // props.onChange(values);
  }, [values]);

  return (
    <TagCheckableGroupView value={values} onChange={handleChange} items={props.items} className={props.className} />
  );
}
