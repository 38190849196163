import type { Klass, LexicalNode } from "lexical";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { HashtagNode } from "@lexical/hashtag";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { MarkNode } from "@lexical/mark";
import { OverflowNode } from "@lexical/overflow";
import { HorizontalRuleNode } from "@lexical/react/LexicalHorizontalRuleNode";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { EmojiNode } from "./EmojiNode";
import { ImageNode } from "./ImageNode/ImageNode";
import { MentionNode } from "./MentionNode";
import { TableNode as NewTableNode } from "./TableNode/TableNode";
import { YouTubeNode } from "./YouTubeNode";
import { CustomNode } from "./CustomNode";
import { AnchorNode } from "./AnchorNode";
import { OrgchartNode } from "./OrgchartNode";

const TextEditorNodes: ReadonlyArray<
  | Klass<LexicalNode>
  | {
      replace: Klass<LexicalNode>;
      with: <
        T extends {
          new (...args: any): any;
        }
      >(
        node: InstanceType<T>
      ) => LexicalNode;
    }
> = [
  HeadingNode,
  ListNode,
  ListItemNode,
  QuoteNode,
  CodeNode,
  NewTableNode,
  TableNode,
  TableCellNode,
  TableRowNode,
  HashtagNode,
  CodeHighlightNode,
  AutoLinkNode,
  LinkNode,
  OverflowNode,
  ImageNode,
  MentionNode,
  EmojiNode,
  HorizontalRuleNode,
  YouTubeNode,
  MarkNode,
  CustomNode,
  AnchorNode,
  OrgchartNode,
];

export default TextEditorNodes;
