import React from "react";
import { observer } from "mobx-react-lite";
import { CenterPageContentLayout } from "../../../layouts/centerPageContentLayout/CenterPageContentLayout";
import { CreateRegulationSectionForm } from "../../../forms/createRegulationSectionForm/CreateRegulationSectionForm";
import { UserSelectionRule } from "../../../../../api/types/userSelectionRule";
import { ISectionContent } from "./Pages.interface";
import { Card, Empty } from "../../../../uiKit";
import { useRootStore } from "../../../../../hooks";
import { RegulationDto } from "../../../../../api";
import { SectionContentHeaderCreator } from "../components/SectionContentHeader/SectionContentHeaderCreator";
import { regulationContentTypeKeys } from "../misc/headerTools";
import { CardRegulation } from "../components/CardRegulation/CardRegulation";

const SectionContentObserver: React.FC<ISectionContent> = (props) => {
  const { authStore } = useRootStore();

  const handleGetIsToStudy = (regulation?: RegulationDto): boolean => {
    return !regulation?.usersWhoStudied?.find((user) => user.userId === authStore.getInitialInfo?.identity?.id);
  };

  return (
    <>
      {props.isEditable ? (
        props.section && (
          <CreateRegulationSectionForm
            toEdit={{
              isEdit: props.isEditable,
              sectionTitle: props.section.name! ?? "",
              onCancel: props.onOffEditable,
              section: props.section ?? undefined,
              userSelectionRules: (props.section.userSelectionRules as UserSelectionRule[]) ?? [],
              currentRegulations:
                props.section.regulations?.map((item) => ({
                  id: item.regulationId,
                  text: item.regulation?.name ?? "",
                })) ?? [],
            }}
          />
        )
      ) : (
        <CenterPageContentLayout>
          <Card title={props.section?.name} size="default" scrollStrategy="cardBody">
            <SectionContentHeaderCreator creator={props.creator} dateCreated={props.section?.dateCreated} />
            {(props.section?.regulations?.length ?? []) > 0 && (
              <div className="d-stack-column spacing-2 mt-2">
                {props.section?.regulations?.map((item, index) => (
                  <CardRegulation
                    isRound
                    toLearnIndicator={handleGetIsToStudy(item?.regulation)}
                    regulationContentType={regulationContentTypeKeys.approved}
                    key={item.regulationId}
                    onClick={props.onRedirectToRegulation}
                    regulation={item.regulation!}
                  />
                ))}
              </div>
            )}
            {(props.section?.regulations ?? []).length == 0 && <Empty className="mt-4" />}
          </Card>
        </CenterPageContentLayout>
      )}
    </>
  );
};

export const SectionContent = observer(SectionContentObserver);
