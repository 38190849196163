import React from "react";
import "./Popover.scss";
import Popover, { PopoverProps } from "antd/lib/popover";
import clsx from "clsx";
import { theme } from "antd";

interface IPopoverView extends PopoverProps {
  //
}

export function PopoverView({ className, color, ...rest }: IPopoverView) {
  const { useToken } = theme;
  const { token } = useToken();

  return (
    <Popover
      overlayClassName={clsx("uiKit-popover", className ?? "")}
      color={color ?? token.colorBgContainer}
      {...rest}
    />
  );
}
