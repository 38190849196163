import React from "react";
import { Avatar, AvatarProps } from "antd";
import "./Avatar.scss";
import { TAvatar } from "./Avatar";
import { TeamOutlined, UserOutlined } from "@ant-design/icons";
import clsx from "clsx";
import { invertColor } from "../../../helpers/colorFunctions";

// import { FiUser } from "@react-icons/all-files/fi/FiUser";

interface IAvatarView extends AvatarProps {
  isOutlined?: boolean;
  type?: TAvatar;
  color?: string | null;
  text?: string | null | (string | undefined | null)[];
  useFullText?: boolean;
}

const getAvatarIconByType = (
  type: TAvatar | undefined,
  text?: string | null | (string | undefined | null)[]
): React.ReactNode => {
  if (typeof text === "string" && (text ?? "").trim().length > 0) return undefined;
  if (Array.isArray(text) && text.filter((c) => c != null && c.trim().length > 0).length > 0) return undefined;
  if (type == "user") return <UserOutlined />;
  // if (type == "user") return <Icon component={() => <UserOutlined />} />;
  if (type == "company") return <TeamOutlined />;
  return <UserOutlined />;
};

export function AvatarView({
  className,
  isOutlined,
  useFullText,
  type,
  color,
  style,
  text,
  icon,
  children,
  ...rest
}: IAvatarView) {
  const getAvatarColor = (col: string | undefined): string | undefined => {
    if (col != null && typeof col === "string" && col.length == 7) return invertColor(col);
    return undefined;
  };

  const getAvatarText = (t: string | null | undefined | (string | undefined | null)[], c: React.ReactNode) => {
    // (text ?? "").trim().length > 0 ? (useFullText == true ? text : text?.charAt(0)) : children
    if (typeof text === "string" && (text ?? "").trim().length > 0)
      return useFullText == true ? text?.trim() : [...(text?.trim() ?? "")]?.[0];
    // return useFullText == true ? text?.trim() : text?.trim()?.charAt(0);
    if (Array.isArray(text) && text.filter((c) => c != null && c.trim().length > 0).length > 0)
      return useFullText
        ? text.join(" ")
        : text
            .filter((c) => c != null && c.trim().length > 0)
            .map((t) => [...(t?.trim() ?? "")]?.[0])
            .join("");
    return c;
  };

  return (
    <Avatar
      className={clsx("uiKit-avatar", "no-select", className ?? "", { __outlined: isOutlined })}
      icon={getAvatarIconByType(type, text)}
      style={{ ...style, background: color ?? style?.background, color: getAvatarColor(color ?? style?.color) }}
      children={getAvatarText(text, children)}
      {...rest}
      // gap={8}
    />
  );
}
