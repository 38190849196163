import {
  $getSelection,
  $isRangeSelection,
  $isRootOrShadowRoot,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  LexicalEditor,
  SELECTION_CHANGE_COMMAND,
} from "lexical";
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import { $isListNode, ListNode } from "@lexical/list";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $isHeadingNode } from "@lexical/rich-text";
import { $getSelectionStyleValueForProperty, $patchStyleText } from "@lexical/selection";
import { $findMatchingParent, $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import * as React from "react";
import { ChangeEvent, memo, MouseEvent, useCallback, useEffect, useRef, useState } from "react";
import { getSelectedNode } from "../../utils/getSelectedNode";
import { alignFormatType, blockTypeToBlockName } from "./FormatSelect/FormatSelect.interface";
import { ToolbarPluginView } from "./ToolbarPluginView";
import { api } from "../../../../../services";
import { INSERT_IMAGE_COMMAND } from "../ImagesPlugin/ImagesPlugin";
import { INSERT_ANCHOR_COMMAND } from "../AnchorPlugin/AnchorPlugin";
import { useNotifier } from "../../../../../hooks";
import { useTranslation } from "react-i18next";

export interface IToolbarPlugin {
  isLoadingChange?: (value: boolean) => void;
}

export const ToolbarPlugin = memo((props: IToolbarPlugin) => {
  const [editor] = useLexicalComposerContext();
  const notifier = useNotifier();
  const { t } = useTranslation();

  const [activeEditor, setActiveEditor] = useState<LexicalEditor>(editor);
  const [blockType, setBlockType] = useState<keyof typeof blockTypeToBlockName>("paragraph");
  const [alignType, setAlignType] = useState<keyof typeof alignFormatType>("left");
  // const [selectedElementKey, setSelectedElementKey] = useState<NodeKey | null>(null);
  // const [fontSize, setFontSize] = useState<string>("15px");
  const [fontColor, setFontColor] = useState<string>("#000");
  const [bgColor, setBgColor] = useState<string>("#fff");
  // const [fontFamily, setFontFamily] = useState<string>("Arial");
  const [isLink, setIsLink] = useState<boolean>(false);
  const [isBold, setIsBold] = useState<boolean>(false);
  const [isItalic, setIsItalic] = useState<boolean>(false);
  const [isUnderline, setIsUnderline] = useState<boolean>(false);
  // const [isStrikethrough, setIsStrikethrough] = useState(false);
  // const [isSubscript, setIsSubscript] = useState(false);
  // const [isSuperscript, setIsSuperscript] = useState(false);
  const [isCode, setIsCode] = useState<boolean>(false);
  const [canUndo, setCanUndo] = useState<boolean>(false);
  const [canRedo, setCanRedo] = useState<boolean>(false);
  // const [codeLanguage, setCodeLanguage] = useState<string>("");
  const [isEditable, setIsEditable] = useState(() => editor.isEditable());
  const [linkUrl, setLinkUrl] = useState<string>("");
  const [videoUrl, setVideoUrl] = useState<string>("");
  const [isOpenLinkPopup, setIsOpenLinkPopup] = useState<boolean>(false);
  const [isOpenYoutubePopup, setIsOpenYoutubePopup] = useState<boolean>(false);
  const [isActiveLinkButton, setIsActiveLinkButton] = useState<boolean>(false);
  const [isOpenTableDialog, setIsOpenTableDialog] = useState<boolean>(false);

  const fileUploadRef = useRef<any>(null);

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      let element =
        anchorNode.getKey() === "root"
          ? anchorNode
          : $findMatchingParent(anchorNode, (e) => {
              const parent = e.getParent();
              return parent !== null && $isRootOrShadowRoot(parent);
            });

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow();
      }

      const elementKey = element.getKey();
      const elementDOM = activeEditor.getElementByKey(elementKey);

      // Update text format
      setIsBold(selection.hasFormat("bold"));
      setIsItalic(selection.hasFormat("italic"));
      setIsUnderline(selection.hasFormat("underline"));
      // setIsStrikethrough(selection.hasFormat("strikethrough"));
      // setIsSubscript(selection.hasFormat("subscript"));
      // setIsSuperscript(selection.hasFormat("superscript"));
      setIsCode(selection.hasFormat("code"));

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
        $isLinkNode(parent) ? setLinkUrl(parent.getURL()) : setLinkUrl(node.getURL());
      } else {
        setIsLink(false);
        setLinkUrl("");
      }

      if (elementDOM !== null) {
        // setSelectedElementKey(elementKey);
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType<ListNode>(anchorNode, ListNode);
          const type = parentList ? parentList.getListType() : element.getListType();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element) ? element.getTag() : element.getType();
          if (type in blockTypeToBlockName) {
            setBlockType(type as keyof typeof blockTypeToBlockName);
          }
          // if ($isCodeNode(element)) {
          //   const language = element.getLanguage() as keyof typeof CODE_LANGUAGE_MAP;
          //   // setCodeLanguage(language ? CODE_LANGUAGE_MAP[language] || language : "");
          //   return;
          // }
        }
      }
      const alignByNum: { [key: number]: string } = {
        0: "left",
        1: "left",
        2: "center",
        3: "right",
        4: "justify",
      };

      const align = element.getFormat();

      if (align in alignByNum) {
        setAlignType(alignByNum[align] as keyof typeof alignFormatType);
      }

      // Handle buttons
      // setFontSize($getSelectionStyleValueForProperty(selection, "font-size", "15px"));
      setIsActiveLinkButton(Boolean(node.__text));
      setFontColor($getSelectionStyleValueForProperty(selection, "color", "#000"));
      setBgColor($getSelectionStyleValueForProperty(selection, "background-color", "#fff"));
      // setFontFamily($getSelectionStyleValueForProperty(selection, "font-family", "Arial"));
    }
  }, [activeEditor]);

  const applyStyleText = useCallback(
    (styles: Record<string, string>) => {
      activeEditor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          $patchStyleText(selection, styles);
        }
      });
    },
    [activeEditor]
  );

  const onFontColorSelect = useCallback(
    (value: string) => {
      applyStyleText({ color: value });
    },
    [applyStyleText]
  );

  const onBgColorSelect = useCallback(
    (value: string) => {
      applyStyleText({ "background-color": value });
    },
    [applyStyleText]
  );

  const insertLink = useCallback(() => {
    if (!isLink) {
      setIsOpenLinkPopup(true);
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, isLink]);

  const handleUploadRefClick = useCallback((event: MouseEvent) => {
    event.preventDefault();
    fileUploadRef.current.click();
  }, []);

  const remoteImage = useCallback(
    async (files: FileList) => {
      for (const file of files) {
        const [mime] = file.type.split("/");
        if (mime === "image") {
          props.isLoadingChange?.(true);
          const formData = new FormData();
          formData.append("files", file);
          const r = await api.staticFile.upload(formData, "image");
          if (r === null) {
            props.isLoadingChange?.(false);
            return;
          } else {
            activeEditor.dispatchCommand(INSERT_IMAGE_COMMAND, { src: r[0].url as string, altText: "" });
            props.isLoadingChange?.(false);
          }
        }
      }
    },
    [activeEditor, props]
  );

  const handleUploadImage = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      await remoteImage(event.target.files as FileList);
      fileUploadRef.current.value = "";
    },
    [remoteImage]
  );

  const handleCreateAnchor = useCallback(
    (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      const anchorId = window.prompt(t("ui:placeholder.create_anchor"));
      if (!anchorId?.length || !anchorId?.match(/^[0-9a-zA-Z]+$/)) {
        return notifier.show({
          message: t("notifier:error.anchor_validation"),
          theme: "error",
        });
      }
      if (anchorId?.length && anchorId?.match(/^[0-9a-zA-Z]+$/)) {
        activeEditor.dispatchCommand(INSERT_ANCHOR_COMMAND, anchorId);
      }
    },
    [activeEditor, notifier, t]
  );

  const handleLinkUrlChange = useCallback((url: string) => {
    setLinkUrl(url);
  }, []);

  const handleIsOpenLinkPopupChange = useCallback((value: boolean) => {
    setIsOpenLinkPopup(value);
  }, []);

  const handleIsOpenTableDialogChange = useCallback((value: boolean) => {
    setIsOpenTableDialog(value);
  }, []);

  const handleIsOpenYoutubePopupChange = useCallback((value: boolean) => {
    setIsOpenYoutubePopup(value);
  }, []);

  const handleVideoUrlChange = useCallback((url: string) => {
    setVideoUrl(url);
  }, []);

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_payload, newEditor) => {
        updateToolbar();
        setActiveEditor(newEditor);
        return false;
      },
      COMMAND_PRIORITY_CRITICAL
    );
  }, [editor, updateToolbar]);

  useEffect(() => {
    return mergeRegister(
      editor.registerEditableListener((editable) => {
        setIsEditable(editable);
      }),
      activeEditor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      activeEditor.registerCommand<boolean>(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        COMMAND_PRIORITY_CRITICAL
      ),
      activeEditor.registerCommand<boolean>(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        COMMAND_PRIORITY_CRITICAL
      )
    );
  }, [activeEditor, editor, updateToolbar]);

  return (
    <ToolbarPluginView
      ref={fileUploadRef}
      activeEditor={activeEditor}
      isLink={isLink}
      editor={editor}
      alignType={alignType}
      activeLinkButton={isActiveLinkButton}
      isEditable={isEditable}
      blockType={blockType}
      bgColor={bgColor}
      onBgColorSelect={onBgColorSelect}
      canRedo={canRedo}
      canUndo={canUndo}
      fontColor={fontColor}
      insertLink={insertLink}
      isBold={isBold}
      isCode={isCode}
      isItalic={isItalic}
      isUnderline={isUnderline}
      linkUrl={linkUrl}
      onFontColorSelect={onFontColorSelect}
      openLinkPopup={isOpenLinkPopup}
      setLinkUrl={handleLinkUrlChange}
      setOpenLinkPopup={handleIsOpenLinkPopupChange}
      isOpenTableDialog={isOpenTableDialog}
      setIsOpenTableDialog={handleIsOpenTableDialogChange}
      openYoutubePopup={isOpenYoutubePopup}
      setIsOpenYoutubePopup={handleIsOpenYoutubePopupChange}
      videoUrl={videoUrl}
      setVideoUrl={handleVideoUrlChange}
      onUploadImage={handleUploadImage}
      onUploadRefClick={handleUploadRefClick}
      onCreateAnchor={handleCreateAnchor}
    />
  );
});
