import React from "react";
import "./ScrollTrigger.scss";
import { useTranslation } from "react-i18next";
import { Spin, Text } from "../../uiKit";
import { theme } from "antd";

interface IScrollTriggerView {
  triggerRef: any;
  marginTop?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  debugMode?: boolean;
  fullWidth?: boolean;
  onClick: () => void;
}

function ScrollTriggerView(props: IScrollTriggerView) {
  const { t } = useTranslation();
  const { useToken } = theme;
  const { token } = useToken();

  return (
    <div
      style={{
        borderRadius: token.borderRadiusLG,
        width: props.fullWidth ? "100%" : undefined,
        overflow: "hidden",
        background: token.colorBgContainerDisabled,
        border: `1px solid ${token.colorBorderSecondary}`,
        display: props.hidden ? "none" : undefined,
        boxSizing: "border-box",
      }}
      // onClick={() => (!props.disabled ? props.onClick() : undefined)}
      ref={props.triggerRef}
      className={`d-flex align-center justify-center py-2 mt-${props.marginTop ? 2 : 0}`}
    >
      <Spin style={{ lineHeight: 1 }} size="small" spinning />
      {/*<div ref={props.triggerRef} className="scroll-trigger__trigger" />*/}
      {/*<LinearProgress sx={{ position: "absolute", top: 0, left: 0, right: 0 }} variant="indeterminate" />*/}
      <Text style={{ opacity: 0.8 }} className="ml-3" children={t("text:loading_data")} />
    </div>
  );
}

export default ScrollTriggerView;
