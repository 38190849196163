import React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

interface IDragPreloader {
  boardStatus?: string | null;
  isCanBeMove?: boolean;
  isLoading?: boolean;
}

export const DragPreloader = (props: IDragPreloader) => {
  const { t } = useTranslation();
  const styles = clsx(
    "todo-list__pre-drag-loader",
    { "todo-list__pre-drag-loader__allow": props.isCanBeMove },
    { "todo-list__pre-drag-loader__disabled": !props.isCanBeMove }
  );

  return (
    <div className={styles}>
      <span>
        {props.isCanBeMove ? t("parse:drop_allow") : t("parse:drop_disabled")} "
        <span children={props.boardStatus} />"
      </span>
    </div>
  );
};
