import React, { useEffect, useState } from "react";
import "./UserSelectionChip.scss";
import { UserSelectionChipView } from "./UserSelectionChipView";
import { useNotifier, useRootStore } from "../../../hooks";
import { api } from "../../../services";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { OrgchartDto } from "../../../api";

export interface IUserSelectionChip {
  id: number;
  orgchartId?: number;
  text1?: string;
  divider?: string;
  text2?: string;
  style?: React.CSSProperties;
  onRemove: (id: number) => void;
}

const UserSelectionChipObserved = (props: IUserSelectionChip) => {
  const notifier = useNotifier();
  const { orgchartStore, authStore } = useRootStore();
  const { t } = useTranslation();
  const [orgchart, setOrgchart] = useState<OrgchartDto | null>(null);

  const handlerApiError = () => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });

  const getOrgchartById = async (orgchartId: number | null) => {
    if (orgchartId == null) return null;
    const o = orgchartStore.getOrgchartsList.find((or) => or.id == orgchartId);
    if (o != null) {
      setOrgchart(o);
      return o;
    }
    const r = await api.orgchart.getById(orgchartId);
    if (r == null) {
      handlerApiError();
      return null;
    } else {
      setOrgchart(r);
      return r;
    }
  };

  const isAll =
    (props.text1 == t("ui:misc.radio_all") && props.text2 == t("ui:misc.radio_all")) ||
    authStore.getCurrentCompanyUiType == 2;

  useEffect(() => {
    props.orgchartId && getOrgchartById(props.orgchartId ?? null);
  }, [props.orgchartId]);

  return <UserSelectionChipView {...props} isAll={isAll} orgchart={orgchart} />;
};

export const UserSelectionChip = observer(UserSelectionChipObserved);
