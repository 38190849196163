import React, { useEffect, useState } from "react";
import { IMainRegulation } from "./Pages.interface";
import { observer } from "mobx-react-lite";
import { useLocation } from "react-router-dom";
import { useNotifier, useRootStore } from "../../../../../hooks";
import { useRouterHandler } from "../../../../pages/regulation/hooks/useRouterHandler";
import { cardKeys, cardKeysType, POLICIES_TAB_KEYS, tabKeysType } from "../misc/consts";
import { CommonListCards } from "../components/ListCards/CommonListCards";
import { useTranslation } from "react-i18next";
import { paginationTypeKeys } from "../components/ListCards/ListCards.interface";
import { ListRegulations } from "../components/ListCards/ListRegulations";
import { ListSections } from "../components/ListCards/ListSections";

const MainRegulationContentObserver = ({ regulationContentType }: IMainRegulation) => {
  const { regulationStore, authStore } = useRootStore();
  const { getTabKeys } = useRouterHandler();
  const { pathname } = useLocation();
  //const navigate = useNavigate();
  const notifier = useNotifier();
  const { t } = useTranslation();

  const currentUserId = authStore?.getInitialInfo?.identity?.id;

  const [cardKey, setCardKey] = useState<cardKeysType>();
  const [tabKey, setTabKey] = useState<tabKeysType>();

  const handleError = () => {
    notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
  };

  const switchTabHandler = () => {
    regulationStore.resetRegulationAndSection();
    switch (tabKey) {
      case POLICIES_TAB_KEYS.toLearn: {
        regulationStore.setRegulationsFilters({
          withContextForUserId: currentUserId,
          isStudied: false,
          toStudy: true,
          pageSize: 5,
        });
        regulationStore.setSectionsFilters({ withContextForUserId: currentUserId, pageSize: 5, toStudy: true });
        regulationStore.initialFetchRegulations();
        regulationStore.initialFetchSections();
        break;
      }
      case POLICIES_TAB_KEYS.forApproval: {
        regulationStore.setRegulationsFilters({ forApproval: true });
        regulationStore.initialFetchRegulations();
        break;
      }
      case POLICIES_TAB_KEYS.my: {
        regulationStore.setRegulationsFilters({ createdByUserId: currentUserId });
        regulationStore.initialFetchRegulations();
        break;
      }
      case POLICIES_TAB_KEYS.all: {
        regulationStore.setRegulationsFilters({ hasActiveContent: true });
        regulationStore.initialFetchRegulations();
        break;
      }
      case POLICIES_TAB_KEYS.undone: {
        regulationStore.setRegulationsFilters({ hasDraftContent: true });
        regulationStore.initialFetchRegulations();
        break;
      }
      case POLICIES_TAB_KEYS.topics: {
        regulationStore.initialFetchSections();
        break;
      }
    }
  };

  useEffect(() => {
    regulationStore.setOnError(handleError);
    return () => regulationStore.resetRegulationAndSection();
  }, []);

  useEffect(() => {
    switchTabHandler();
  }, [tabKey]);

  useEffect(() => {
    const currentTabKeys = getTabKeys();
    if (currentTabKeys) {
      setCardKey(currentTabKeys?.cardKey ?? undefined);
      setTabKey(currentTabKeys?.tabKey ?? undefined);
    }
  }, [pathname]);

  return (
    <>
      {cardKey === cardKeys.multiple ? (
        <CommonListCards
          isLoading={regulationStore.getLoading}
          paginationType={paginationTypeKeys.byButton}
          regulationContentType={regulationContentType}
          regulations={regulationStore.getRegulations}
          loadNextRegulations={regulationStore.fetchNextPageRegulations.bind(regulationStore)}
          isDoneRegulation={regulationStore.getRegulationPagingOptions.pageIsLast}
          sections={regulationStore.getSections}
          loadNextSections={regulationStore.fetchNextPageSections.bind(regulationStore)}
          isDoneSection={regulationStore.getSectionPagingOptions.pageIsLast}
        />
      ) : (
        <>
          {cardKey === cardKeys.regulation && (
            <ListRegulations
              isLoading={regulationStore.getLoading}
              paginationType={paginationTypeKeys.byScroll}
              regulationContentType={regulationContentType}
              loadNext={regulationStore.fetchNextPageRegulations.bind(regulationStore)}
              regulations={regulationStore.getRegulations}
              isDone={regulationStore.getRegulationPagingOptions.pageIsLast}
            />
          )}
          {cardKey === cardKeys.section && (
            <ListSections
              isLoading={regulationStore.getLoading}
              paginationType={paginationTypeKeys.byScroll}
              loadNext={regulationStore.fetchNextPageSections.bind(regulationStore)}
              sections={regulationStore.getSections}
              isDone={regulationStore.getSectionPagingOptions.pageIsLast}
            />
          )}
        </>
      )}
    </>
  );
};

export const MainRegulationContent = observer(MainRegulationContentObserver);
