import getVideoId from "get-video-id";

const prependHttp = (url: string, options: { https: boolean }) => {
  url = url.trim();

  if (/^\.*\/|^(?!localhost)\w+:/.test(url)) {
    return url;
  }

  return url.replace(/^(?!(?:\w+:)?\/\/)/, options.https ? "https://" : "http://");
};

export function getVideoSrc(src: string) {
  const { id, service } = getVideoId(prependHttp(src, { https: false }));

  switch (service) {
    case "youtube":
      return `https://www.youtube.com/embed/${id}`;
    case "vimeo":
      return `https://player.vimeo.com/video/${id}`;
    default:
      return src;
  }
}
