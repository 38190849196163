import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  DEPRECATED_$isGridSelection,
  ElementFormatType,
  FORMAT_ELEMENT_COMMAND,
} from "lexical";
import { INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND, REMOVE_LIST_COMMAND } from "@lexical/list";
import { $createHeadingNode, $createQuoteNode, HeadingTagType } from "@lexical/rich-text";
import { $setBlocksType_experimental } from "@lexical/selection";
import * as React from "react";
import { memo, useCallback, useMemo } from "react";
import { alignFormatType, blockTypeToBlockName, IFormatSelect } from "./FormatSelect.interface";
import { useTranslation } from "react-i18next";
import { ISelectItem } from "../../../../../uiKit/select/SelectView";
import { Icon, Select } from "../../../../../uiKit";
import { ReactComponent as H1Svg } from "../../../images/icons/h1.svg";
import { ReactComponent as H2Svg } from "../../../images/icons/h2.svg";
import { ReactComponent as H3Svg } from "../../../images/icons/h3.svg";
import {
  BsChatSquareQuote,
  BsTextParagraph,
  FiAlignCenter,
  FiAlignJustify,
  FiAlignLeft,
  FiAlignRight,
  MdOutlineFormatListBulleted,
  MdOutlineFormatListNumbered,
} from "react-icons/all";

export const FormatSelect = memo(
  ({ blockType, alignType, editor, disabled, formatType, activeEditor }: IFormatSelect) => {
    const { t } = useTranslation();

    const blockSelectItems: ISelectItem[] = useMemo(
      () => [
        {
          id: 0,
          value: blockTypeToBlockName.paragraph,
          text: t("common:text_editor_toolbar.select.normal"),
          icon: <BsTextParagraph className="mr-1" />,
        },
        {
          id: 1,
          value: blockTypeToBlockName.h1,
          text: t("common:text_editor_toolbar.select.h1"),
          icon: <span className="d-inline-flex align-center mr-1" children={<H1Svg />} />,
        },
        {
          id: 2,
          value: blockTypeToBlockName.h2,
          text: t("common:text_editor_toolbar.select.h2"),
          icon: <span className="d-inline-flex align-center mr-1" children={<H2Svg />} />,
        },
        {
          id: 3,
          value: blockTypeToBlockName.h3,
          text: t("common:text_editor_toolbar.select.h3"),
          icon: <span className="d-inline-flex align-center mr-1" children={<H3Svg />} />,
        },
        {
          id: 4,
          value: blockTypeToBlockName.bullet,
          text: t("common:text_editor_toolbar.select.bulleted_list"),
          icon: <MdOutlineFormatListBulleted className="mr-1" />,
        },
        {
          id: 5,
          value: blockTypeToBlockName.number,
          text: t("common:text_editor_toolbar.select.numbered_list"),
          icon: <MdOutlineFormatListNumbered className="mr-1" />,
        },
        {
          id: 6,
          value: blockTypeToBlockName.quote,
          text: t("common:text_editor_toolbar.select.quote"),
          icon: <BsChatSquareQuote className="mr-1" />,
        },
      ],
      [t]
    );

    const alignSelectItems: ISelectItem[] = useMemo(
      () => [
        {
          id: 0,
          value: alignFormatType.left,
          text: t("common:text_editor_toolbar.select.align_left"),
          icon: <Icon children={<FiAlignLeft />} />,
        },
        {
          id: 1,
          value: alignFormatType.center,
          text: t("common:text_editor_toolbar.select.align_center"),
          icon: <Icon children={<FiAlignCenter />} />,
        },
        {
          id: 2,
          value: alignFormatType.right,
          text: t("common:text_editor_toolbar.select.align_right"),
          icon: <Icon children={<FiAlignRight />} />,
        },
        {
          id: 3,
          value: alignFormatType.justify,
          text: t("common:text_editor_toolbar.select.align_justify"),
          icon: <Icon children={<FiAlignJustify />} />,
        },
      ],
      [t]
    );

    const formatParagraph = useCallback(() => {
      if (blockType !== blockTypeToBlockName.paragraph) {
        editor.update(() => {
          const selection = $getSelection();
          if ($isRangeSelection(selection) || DEPRECATED_$isGridSelection(selection))
            $setBlocksType_experimental(selection, () => $createParagraphNode());
        });
      }
    }, [blockType, editor]);

    const formatHeading = useCallback(
      (headingSize: HeadingTagType) => {
        if (blockType !== headingSize) {
          editor.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection) || DEPRECATED_$isGridSelection(selection)) {
              $setBlocksType_experimental(selection, () => $createHeadingNode(headingSize));
            }
          });
        }
      },
      [blockType, editor]
    );

    const formatBulletList = useCallback(() => {
      if (blockType !== blockTypeToBlockName.bullet) {
        editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
      } else {
        editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
      }
    }, [blockType, editor]);

    const formatNumberedList = useCallback(() => {
      if (blockType !== blockTypeToBlockName.number) {
        editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
      } else {
        editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
      }
    }, [blockType, editor]);

    const formatQuote = useCallback(() => {
      if (blockType !== blockTypeToBlockName.quote) {
        editor.update(() => {
          const selection = $getSelection();
          if ($isRangeSelection(selection) || DEPRECATED_$isGridSelection(selection)) {
            $setBlocksType_experimental(selection, () => $createQuoteNode());
          }
        });
      }
    }, [blockType, editor]);

    const handleBlockTypeChange = useCallback(
      (blockType: string) => {
        switch (blockType) {
          case blockTypeToBlockName.paragraph:
            return formatParagraph();
          case blockTypeToBlockName.h1:
          case blockTypeToBlockName.h2:
          case blockTypeToBlockName.h3:
            return formatHeading(blockType as HeadingTagType);
          case blockTypeToBlockName.bullet:
            return formatBulletList();
          case blockTypeToBlockName.number:
            return formatNumberedList();
          case blockTypeToBlockName.quote:
            return formatQuote();
          default:
            return formatParagraph();
        }
      },
      [formatBulletList, formatHeading, formatNumberedList, formatParagraph, formatQuote]
    );

    const handleAlignTypeChange = useCallback(
      (alignType: ElementFormatType) => {
        return activeEditor?.dispatchCommand(FORMAT_ELEMENT_COMMAND, alignType);
      },
      [activeEditor]
    );

    return (
      <Select
        className="mr-1 ml-1"
        style={formatType == "align" ? { width: 180 } : { width: 230 }}
        size="middle"
        value={formatType == "align" ? alignType : blockType}
        onChange={formatType == "align" ? handleAlignTypeChange : handleBlockTypeChange}
        items={formatType == "align" ? alignSelectItems : blockSelectItems}
        disabled={disabled}
      />
    );
  }
);
