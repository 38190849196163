import React, { useEffect, useLayoutEffect } from "react";
import { useDetectAdBlock, useNotifier, useRootStore } from "../hooks";
import { observer } from "mobx-react-lite";
import { useLocation, useNavigate } from "react-router-dom";
import { removeTrailingSlash } from "../helpers/pathname";
import TopAppBar from "../components/modules/layouts/topAppBar/TopAppBar";
import LeftAppDrawer from "../components/modules/layouts/leftAppDrawer/LeftAppDrawer";
import { Alert, Layout, LayoutContent } from "../components/uiKit";
import CompanySettingsOnFirstLoginDialog from "../components/modules/dialogs/companySettingsOnFirstLoginDialog/CompanySettingsOnFirstLoginDialog";
import EmployeeSettingsOnFirstLoginDialog from "../components/modules/dialogs/employeeSettingsOnFirstLoginDialog/EmployeeSettingsOnFirstLoginDialog";
import VideoDialog from "../components/modules/dialogs/videoDialog/VideoDialog";
import { IssueUpdateWrapper } from "../components/service/issueUpdateWraper/IssueUpdateWrapper";
import { CommonDialogWrapperByQP } from "../components/service/commonDialogWrapperByQP/CommonDialogWrapperByQP";
import { DriverRTCCallsWrapper } from "../components/service/driverRTCCallsWrapper/DriverRTCCallsWrapper";
import { useTranslation } from "react-i18next";

interface IAuthorizedLayout {
  children: React.ReactNode;
  pageKey?: string;
}

function AuthorizedLayout(props: IAuthorizedLayout) {
  const { t } = useTranslation();
  const { authStore, appStore } = useRootStore();
  const location = useLocation();
  const navigate = useNavigate();
  const notifier = useNotifier();
  const isAdBlockDetected = useDetectAdBlock();

  useEffect(() => {
    if (
      authStore.getInviteCode != null &&
      authStore.getInviteCode.length > 0 &&
      !removeTrailingSlash(window.location.pathname).includes("settings/profile")
    ) {
      navigate("/settings/profile");
    }
  }, []);
  useEffect(() => {
    console.log("rendered");
  });

  useLayoutEffect(() => {
    // isAdBlockDetected && notifier.show({ theme: "error", message: "Обнаружен блокировщик рекламы" });
  }, [isAdBlockDetected]);

  return (
    <>
      <TopAppBar />
      <CommonDialogWrapperByQP />
      <IssueUpdateWrapper />
      <CompanySettingsOnFirstLoginDialog />
      <EmployeeSettingsOnFirstLoginDialog />
      {authStore.isAuthorized && (
        <>
          <VideoDialog
            trainingKey="ed.b.s"
            videoId="Yj8EToAaW7M"
            name="profile"
            onWatchedClickObject={{ profile: true }}
            hidden={
              authStore.getInitialInfo?.identity?.name != null &&
              authStore.getInitialInfo?.identity?.name.trim()?.length > 0
            }
          />
          <VideoDialog
            trainingKey="ed.b.o"
            videoId="RlJJBT7KWp0"
            name="overview"
            onWatchedClickObject={{ overview: true }}
            hidden={
              authStore.getInitialInfo?.identity?.name != null &&
              authStore.getInitialInfo?.identity?.name.trim()?.length > 0
            }
          />
          <VideoDialog
            trainingKey="ed.b.i"
            videoId="bWGJ1RNI6_A"
            name="start"
            onWatchedClickObject={{ start: true }}
            hidden={
              authStore.getInitialInfo?.identity?.name != null &&
              authStore.getInitialInfo?.identity?.name.trim()?.length > 0
            }
          />
        </>
      )}
      <Layout style={{ marginTop: "56px" }}>
        <LeftAppDrawer />
        <DriverRTCCallsWrapper>
          <LayoutContent className="app-content__wrapper">
            {authStore?.getInitialInfo?.menuItems?.find((i) => i.key == props.pageKey)?.versionType != null &&
              (authStore?.getInitialInfo?.menuItems?.find((i) => i.key == props.pageKey)?.versionType == "alpha" ||
                authStore?.getInitialInfo?.menuItems?.find((i) => i.key == props.pageKey)?.versionType == "beta") && (
                <Alert type="warning" className="py-1 px-2" message={t("parse:section_under_construction")} />
              )}
            {appStore.getCanDrawMainLayout ? props.children : <div />}
          </LayoutContent>
        </DriverRTCCallsWrapper>
      </Layout>
      {/*<LayoutFooter className="px-4 py-2" style={{ background: "var(--color-primary-weak)" }}>*/}
      {/*  <div className="d-flex justify-space-between align-center ml-auto mr-auto" style={{ maxWidth: "768px" }}>*/}
      {/*    <div className="flex-grow-1" />*/}
      {/*    <NotificationsCenter />*/}
      {/*  </div>*/}
      {/*  /!*  <Text children="We have an App!" />*!/*/}
      {/*  /!*  <Button*!/*/}
      {/*  /!*    onClick={() => window.open("intent://my.tonnus.io/#Intent;scheme=https;package=io.tonnus;end")}*!/*/}
      {/*  /!*    children={"Open the App"}*!/*/}
      {/*  /!*  />*!/*/}
      {/*  /!*</div>*!/*/}
      {/*</LayoutFooter>*/}
    </>
  );
}

export default observer(AuthorizedLayout);
