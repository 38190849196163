import React from "react";
import { FloatButton, FloatButtonProps } from "antd";
import clsx from "clsx";

interface IButtonFloat extends FloatButtonProps {
  //
}

export function ButtonFloat({ className, ...rest }: IButtonFloat) {
  return <FloatButton className={clsx("uiKit-buttonFloat", className ?? "")} {...rest} />;
}
