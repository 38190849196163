import { ModalProps } from "antd";
import React, { ReactNode, useEffect, useState } from "react";
import { DialogView } from "./DialogView";

export type TDialogScrollStrategy = "dialogBody" | "documentBody" | undefined;

interface IDialog extends ModalProps {
  open?: boolean;
  onClose?: (e: React.MouseEvent<HTMLElement>) => void;
  scrollStrategy?: TDialogScrollStrategy;
  closeConfirmationDialog?: {
    title?: string;
    body?: React.ReactNode;
    cancelButton?: string;
    closeButton?: string;
  };
}

export function Dialog(props: IDialog) {
  const [footer, setFooter] = useState<ReactNode | null>(null);
  // TODO: Добавить подтверждение закрытия (модалку)

  useEffect(() => {
    let dialogActions = null;

    React.Children.map(props.children, (child) => {
      if (React.isValidElement(child) && typeof child.type !== "string") {
        // @ts-ignore
        if (child.type.displayName === "DialogActions" || child.displayName == "DialogActions") {
          dialogActions = <div className="d-flex justify-end pt-2">{child.props.children}</div>;
        }
      }
    });
    setFooter(dialogActions);
  }, [props.children, props.open]);

  return <DialogView open={props.open} onCancel={props.onClose} footer={footer} {...props} />;
}
