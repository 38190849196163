import React from "react";
import { List } from "antd";
import { ListItemProps } from "antd/es/list";
import "./ListItem.scss";
import clsx from "clsx";

interface IListItemView extends ListItemProps {
  //
}

const { Item } = List;

export function ListItemView({ className, ...rest }: IListItemView) {
  return <Item className={clsx("uiKit-listItem", className ?? "")} {...rest} />;
}
