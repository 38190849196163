import React from "react";
import CloseTaskWithoutProofConfirmationDialogView from "./CloseTaskWithoutProofConfirmationDialogView";

interface ICloseTaskWithoutProofConfirmationDialog {
  open: boolean;

  onApply: () => void;
  onClose: () => void;
}

function CloseTaskWithoutProofConfirmationDialog(props: ICloseTaskWithoutProofConfirmationDialog) {
  return (
    <CloseTaskWithoutProofConfirmationDialogView open={props.open} onClose={props.onClose} onApply={props.onApply} />
  );
}

export default CloseTaskWithoutProofConfirmationDialog;
