import React from "react";
import "./PageMap.scss";

interface IPageMapView {
  //
}

function PageMapView(props: IPageMapView) {
  return <canvas id="pageMapCanvas" />;
}

export default PageMapView;
