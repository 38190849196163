import React from "react";
import { IEducationSectionView } from "./EducationSection.interface";
import { EducationCard } from "../edicationCard/EducationCard";
import "./EducationSection.scss";

export const EducationSectionView = (props: IEducationSectionView) => {
  return (
    <div>
      <h1 className="education-section-title">{props.educationItem.name}</h1>
      <div className="education-section-container">
        {props.educationItem.children?.map((item) => (
          <React.Fragment key={item.key}>
            <EducationCard
              educationItem={item}
              trainingKey={item.key}
              onOpenTrainingDialog={props.onClick}
              indicator={props.onGetProgress(item?.videoId)}
              onSuccessfulCompletion={props.onSuccessFullCompletion}
            />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
