import React from "react";
import "./MessengerCard.scss";
import { IMessengerCard } from "./MessengerCard.interface";
import { Button, Card } from "../../../../../uiKit";
import { useTranslation } from "react-i18next";

export const MessengerCard = (props: IMessengerCard) => {
  const { t } = useTranslation();

  const handlegetMessengerName = (): string => {
    switch (props.type) {
      case 1:
        return "Telegram";
      case 2:
        return "WhatsApp";
      default:
        return "the messenger has no type";
    }
  };

  const handleClick = () => props.onClick(props.type!);
  //const handleClick = () => props.onClick(1);

  return (
    <Card bodyStyle={{ padding: 0 }} style={{ borderRadius: "4px" }} hoverable bordered>
      <div className="messenger-card">
        <h2 className="messenger-card__bot-name">{handlegetMessengerName()}</h2>
        <div className="d-stack-row spacing-4 justify-space-between align-center">
          {!props.isActivated && (
            <Button variant="filled" onClick={handleClick}>
              {t("ui:button.activate_bot")}
            </Button>
          )}
          <span style={{ color: `var(${props.isActivated ? "--color-success" : "--color-error"})` }}>
            {props.isActivated ? t("parse:is_activated") : t("parse:is_no_activated")}
          </span>
        </div>
      </div>
    </Card>
  );
};
