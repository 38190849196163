import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IssueDto, PlanDto } from "../../../../../../api";
import { useNotifier, useRootStore } from "../../../../../../hooks";
import { api } from "../../../../../../services";
import { blockedFieldsKeys } from "../../../../forms/types/consts";
import { IPlanningPageMainContent } from "./PlanningPageMainContent.interface";
import "./PlanningPageMainContent.scss";
import { PlanningPageMainContentView } from "./PlanningPageMainContentView";

export const PlanningPageMainContent = forwardRef((props: IPlanningPageMainContent, ref) => {
  const { t } = useTranslation();
  const notifier = useNotifier();
  const { issueInitDataStore, authStore } = useRootStore();
  const [isOpenPlanRejectDialog, setIsOpenPlanRejectDialog] = useState<boolean>(false);
  const [isOpenApproverDialog, setIsOpenApproverDialog] = useState<boolean>(false);
  const [isLoadingButton, setIsLoadingButton] = useState<boolean>(false);
  const weekReportStart = authStore.getInitialInfo?.identity?.companies?.find(
    (u2c) => u2c.companyId == authStore.getInitialInfo?.identity?.currentCompanyId
  )?.company?.weekReportStart;
  const restartPlanHistory = useRef<{ restart: () => void }>();

  const handleIsOpenRejectDialogChange = (value: boolean) => {
    setIsOpenPlanRejectDialog(value);
  };

  const handlePlanStatusEdit = async (plan: PlanDto, message: string) => {
    setIsLoadingButton(true);
    const r = await api.plan.edit(props.plan?.id!, plan);
    if (r) {
      notifier.show({
        message: message,
        theme: "success",
      });
      props.fetchApprovalPlans && props.fetchApprovalPlans();
      props.fetchPlans && props.fetchPlans();
      restartPlanHistory.current?.restart();
    } else {
      notifier.show({
        message: t("notifier:error.something_wrong"),
        theme: "error",
      });
    }
    setIsLoadingButton(false);
  };

  const handleIssueEdit = async (issueId: number, issue: IssueDto) => {
    const r = await api.issue.edit(issueId, issue);
    if (r) {
      props.issuesRestart && props.issuesRestart(true);
      props.fetchPlans && props.fetchPlans();
    } else {
      notifier.show({
        message: t("notifier:error.something_wrong"),
        theme: "error",
      });
    }
  };

  const handleMigrateIssuesToPlan = async () => {
    await api.plan.migrateIssues(props.plan?.canBeMigratedFromPlanId!, props.plan?.id!);
    notifier.show({
      message: t("notifier:success.issue_migrated"),
      theme: "success",
    });
    props.fetchPlans && props.fetchPlans();
    props.issuesFromCommunicationsRestart && props.issuesFromCommunicationsRestart();
    props.issuesRestart && props.issuesRestart(true);
  };

  const handleIssueToPlanCreate = async (issues: (IssueDto | null)[]) => {
    const i = issues[0]?.id;
    if (!i) return;
    const r = await api.plan.edit(props.plan?.id!, {
      ...props.plan,
      issues: [...(props.plan?.issues ?? []), { issueId: i }],
    });
    if (!r) {
      return notifier.show({
        message: t("notifier:error.something_wrong"),
        theme: "error",
      });
    }
    props.issuesFromCommunicationsRestart && props.issuesFromCommunicationsRestart();
    props.fetchPlans && props.fetchPlans();
    props.issuesRestart && props.issuesRestart(true);
  };

  const handleIssueCreate = () => {
    issueInitDataStore.setOnCreate = handleIssueToPlanCreate;
    issueInitDataStore.setBlockedFields = [blockedFieldsKeys.executorUserId];
    issueInitDataStore.setIssueData({ executorUserId: props.plan?.userId, executorUser: props.plan?.user });
    props.onOpenCreateIssueDialog && props.onOpenCreateIssueDialog();
  };

  const handleIsOpenApproverDialogChange = (value: boolean) => {
    setIsOpenApproverDialog(value);
  };

  const handleApproverSuccess = (approverId: number) => {
    handlePlanStatusEdit(
      { ...props.plan, status: 2, userPublisherId: approverId },
      t("notifier:success.plan_to_approval")
    );
    setIsOpenApproverDialog(false);
  };

  // const disabledDate = (current: any) => {
  //   return current && current.toDate() > getWeekStartAndEndByDate(dayjs().add(1, "week"), weekReportStart)[1];
  // };
  //
  // const customWeekStartEndFormat = () => {
  //   const date = props.date && getWeekStartAndEndByDate(props.date, weekReportStart);
  //   return `${dayjs(date && date[0]).format("DD MMM")} - ${dayjs(date && date[1]).format("DD MMM")}`;
  // };

  const handleIssuesRestart = () => {
    props.fetchPlans && props.fetchPlans();
    props.issuesFromCommunicationsRestart && props.issuesFromCommunicationsRestart();
    props.issuesRestart && props.issuesRestart(true);
  };

  useImperativeHandle(ref, () => ({
    restart: restartPlanHistory.current?.restart,
  }));

  return (
    <PlanningPageMainContentView
      ref={restartPlanHistory}
      onPlanStatusEdit={handlePlanStatusEdit}
      onApproverSuccess={handleApproverSuccess}
      onOpenCreateIssueDialog={props.onOpenCreateIssueDialog}
      isOpen={isOpenPlanRejectDialog}
      onIsOpenChange={handleIsOpenRejectDialogChange}
      plan={props.plan}
      issues={props.issues}
      scheduleIssues={props.scheduleIssues}
      onLoadRestart={handleIssuesRestart}
      isLoadingButton={isLoadingButton}
      onIssueEdit={handleIssueEdit}
      onIssueToPlanCreate={handleIssueCreate}
      onMigrateIssuesToPlan={handleMigrateIssuesToPlan}
      approvalPlan={props.approvalPlan}
      onPlanIdChange={props.onPlanIdChange}
      isLoading={props.isLoading}
      isOpenApproverDialog={isOpenApproverDialog}
      isOpenApproverDialogChange={handleIsOpenApproverDialogChange}
      isHideSidebar={props.isHideSidebar}
      onHidingSidebarChange={props.onHidingSidebarChange}
      isLoadingIssue={props.isLoadingIssue}
      weekReportStart={weekReportStart}
      issuesRestart={props.issuesRestart}
      userId={props.userId}
      plansShort={props.plansShort}
      currentPlanId={props.currentPlanId}
    />
  );
});
