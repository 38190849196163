import React from "react";
import { IPlanHistoryListCards } from "./PlanHistoryModule.interface";
import ScrollTrigger from "../../../../../service/scrollTrigger/ScrollTrigger";
import { PlanHistoryCard } from "./PlanHistoryCard";

export const PlanHistoryListCards = (props: IPlanHistoryListCards) => {
  return (
    <div className="plan-history__list spacing-4 d-stack-column justify-start align-center">
      {props.items.map((item, index) => (
        <>
          <PlanHistoryCard
            key={item.id}
            historyId={item.id!}
            index={index}
            dateCreated={item.dateCreated!}
            reactions={item.reactions ?? []}
            creatorId={item.createdByUserId!}
            creator={item.createdByUser!}
            commentContent={item.comment?.content ?? ""}
            commentAttachments={item.comment?.attachments ?? []}
          />
        </>
      ))}
      {props.items.length !== 0 && (
        <ScrollTrigger
          fullWidth
          disabled={props.isDone}
          onIntersection={props.onLoadNext}
          marginTop={props.items.length > 0}
          hidden={props.isDone}
        />
      )}
    </div>
  );
};
