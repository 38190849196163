import React from "react";
import { Dialog } from "../../../../../uiKit";
import { FullCalendar } from "../../../../fullCalendar/FullCalendar";
import "./IssuesCalendarDialog.scss";
import { CalendarOptions } from "@fullcalendar/common";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";

interface IIssuesCalendarDialog {
  open: boolean;
  calendarProps?: CalendarOptions;
  weekStartDay?: number;
  executorUserId?: number;

  onTaskCreateClick?: () => void;
  onClose?: () => void;
}

const modifyInitialDate = (d: Date) => {
  const currentTimeZoneOffset = new Date().getTimezoneOffset() / -60;
  const tzModifier = currentTimeZoneOffset < 0 ? -1 : 1;

  return dayjs(d)
    .set("hour", 23)
    .set("minute", 59)
    .add(currentTimeZoneOffset * tzModifier, "hour")
    .add(-1, "day")
    .add(5 * tzModifier, "minute")
    .toDate();
};

export function IssuesCalendarDialog(props: IIssuesCalendarDialog) {
  dayjs.extend(weekday);

  return (
    <Dialog className="full-calendar__dialog" open={props.open} destroyOnClose={true} onClose={props.onClose}>
      <FullCalendar
        initialDate={
          props.calendarProps?.initialDate != null
            ? modifyInitialDate(new Date(props.calendarProps?.initialDate as string))
            : undefined
        }
        weekStartDay={
          props.calendarProps?.initialDate != null
            ? dayjs(modifyInitialDate(new Date(props.calendarProps?.initialDate as string))).day()
            : undefined
        }
        firstDay={
          props.calendarProps?.initialDate != null
            ? dayjs(modifyInitialDate(new Date(props.calendarProps?.initialDate as string))).day()
            : undefined
        }
        // weekStartDay={props.weekStartDay}
        // firstDay={props.weekStartDay}
        onTaskCreateClick={props.onTaskCreateClick}
        executorUserId={props.executorUserId}
      />
    </Dialog>
  );
}
