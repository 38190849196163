import React from "react";
import { CompanyForAdminDto } from "../../../../../api";
import CompanyMainCardView from "./CompanyMainCardView";
import { useTranslation } from "react-i18next";
import { useDateHelpers, useNotifier, useRootStore } from "../../../../../hooks";
import { DropdownMenu } from "../../../../service/dropdownMenu/dropdownMenu";
import { PopupMenuItemTypes } from "../../../../service/dropdownMenu/dropdownMenu.interface";

export interface ICompanyMainCard {
  data: CompanyForAdminDto;
  marginBottom?: boolean;
  marginTop?: boolean;
  expandedCardId?: number;

  onBoardResetClick(boardId: number): void;
  handleCardClick(id: number): void;
  handlePauseClick(): void;
  handleSettingsClick(id: number): void;
  onDeleteClick(id: number): void;
  onEmployeeStatsClick(id: number): void;
}

export interface ICompanyColumn {
  title: string;
  dataIndex: string;
  key: string;
  render?: (param1: any, model: ITableDataSource) => void;
}

export interface ITableDataSource {
  key: number;
  ID: number;
  name: string;
  last_update: string;
  number_updates: number;
  actions: (boardId: number) => void;
}

function CompanyMainCard(props: ICompanyMainCard) {
  const { t } = useTranslation();
  const notifier = useNotifier();
  const { authStore } = useRootStore();
  const dateHelpers = useDateHelpers();

  const handleCodeCopy = async () => {
    // if (!(props.data?.inviteForOwner?.code?.length > 0)) {
    //   notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
    //   return;
    // }
    await navigator.clipboard
      .writeText(props.data?.inviteForOwner?.code ?? "")
      .then(() => notifier.show({ message: t("notifier:success.copy"), theme: "success" }))
      .catch(() => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" }));
  };

  const handleLinkCopy = async () => {
    // if (!(props.data?.inviteForOwner?.url?.length > 0)) {
    //   notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
    //   return;
    // }
    await navigator.clipboard
      .writeText(props.data?.inviteForOwner?.url ?? "")
      .then(() => notifier.show({ message: t("notifier:success.copy"), theme: "success" }))
      .catch(() => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" }));
  };

  const handleCompanyDelete = () => {
    props.onDeleteClick(props.data.id as number);
  };

  const handleBoardReset = (boardId: number) => {
    props.onBoardResetClick(boardId);
  };

  const handleCompanyPause = () => {
    props.handlePauseClick();
  };

  const handleCompanySettings = () => {
    props.handleSettingsClick(props.data.id as number);
  };

  const handleCompanyEmployeeStats = () => {
    props.onEmployeeStatsClick(props.data.id as number);
  };

  const handleCompanySwitch = async () => {
    const r = await authStore.reauthorizeWithNewCompanyId(props.data.id as number);
    if (r) {
      window.location.reload();
    }
  };

  const menuItems: PopupMenuItemTypes[] = [
    {
      id: 0,
      disabled: props.data.userOwnerId != null,
      text: t("common:menu.company_card.copy_code"),
      action: handleCodeCopy,
    },
    {
      id: 1,
      disabled: props.data.userOwnerId != null,
      text: t("common:menu.company_card.copy_link"),
      action: handleLinkCopy,
    },
    {
      id: 2,
      text: t("common:menu.company_card.statistics"),
      action: handleCompanyEmployeeStats,
    },
    {
      id: 3,
      disabled:
        props.data.id == authStore.getCurrentCompanyId ||
        props.data.userOwnerId != authStore.getInitialInfo?.identity?.id,
      text: t("common:menu.company_card.switch"),
      action: handleCompanySwitch,
    },
    {
      id: 4,
      text: t("common:menu.company_card.pause"),
      action: handleCompanyPause,
    },
    { id: 5, disabled: false, text: t("common:menu.company_card.settings"), action: handleCompanySettings },
    { id: 6, disabled: false, text: t("common:menu.company_card.delete"), action: handleCompanyDelete },
  ];

  const tableColumns: ICompanyColumn[] = [
    {
      title: t("ui:caption.boards_table.id"),
      dataIndex: "ID",
      key: "ID",
    },
    {
      title: t("ui:caption.boards_table.name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("ui:caption.boards_table.last_update"),
      dataIndex: "last_update",
      key: "last_update",
    },
    {
      title: t("ui:caption.boards_table.number_updates"),
      dataIndex: "number_updates",
      key: "number_updates",
    },
    {
      title: t("ui:caption.boards_table.actions"),
      dataIndex: "actions",
      key: "actions",
      render: (_, model) => (
        <div style={{ float: "right" }}>
          <DropdownMenu
            items={[
              {
                id: 0,
                text: t("common:menu.manage_companies.board_reset"),
                action: () => handleBoardReset(model.ID),
              },
            ]}
          />
        </div>
      ),
    },
  ];

  const tableDataSource: ITableDataSource[] | undefined = props.data.boards?.map((board) => ({
    key: board.id!,
    ID: board.id!,
    name: board.name!,
    last_update: (board.dateUpdated && dateHelpers.formatDateString(board.dateUpdated, { month: "short" })) ?? "",
    number_updates: board.schemeResetTimes!,
    actions: handleBoardReset,
  }));

  return (
    <CompanyMainCardView
      {...props}
      tableColumns={tableColumns}
      tableDataSource={tableDataSource}
      onDeleteClick={() => props.onDeleteClick(props.data.id as number)}
      handleBoardReset={handleBoardReset}
      handleCardClick={props.handleCardClick}
      menuItems={menuItems}
    />
  );
}

export default CompanyMainCard;
