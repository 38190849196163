import "./TextEditor.scss";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HashtagPlugin } from "@lexical/react/LexicalHashtagPlugin";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { HorizontalRulePlugin } from "@lexical/react/LexicalHorizontalRulePlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin";
import { TablePlugin } from "@lexical/react/LexicalTablePlugin";
import * as React from "react";
import { memo, useCallback, useState } from "react";
import { useSharedHistoryContext } from "./context/SharedHistoryContext";
import AutoEmbedPlugin from "./plugins/AutoEmbedPlugin/AutoEmbedPlugin";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin/AutoLinkPlugin";
import ClickableLinkPlugin from "./plugins/ClickableLinkPlugin/ClickableLinkPlugin";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin/CodeHighlightPlugin";
import DragDropPaste from "./plugins/DragDropPastePlugin/DragDropPastePlugin";
import FloatingTextFormatToolbarPlugin from "./plugins/FloatingTextFormatToolbarPlugin/FloatingTextFormatToolbarPlugin";
import ImagesPlugin from "./plugins/ImagesPlugin/ImagesPlugin";
import LinkPlugin from "./plugins/LinkPlugin/LinkPlugin";
import MarkdownShortcutPlugin from "./plugins/MarkdownShortcutPlugin/MarkdownShortcutPlugin";
import MentionsPlugin from "./plugins/MentionsPlugin/MentionsPlugin";
import TabFocusPlugin from "./plugins/TabFocusPlugin/TabFocusPlugin";
import TableCellActionMenuPlugin from "./plugins/TablePlugin/TableActionMenuPlugin/TableActionMenuPlugin";
import TableCellResizer from "./plugins/TablePlugin/TableCellResizer/TableCellResizer";
import YouTubePlugin from "./plugins/YouTubePlugin/YouTubePlugin";
import ContentEditable from "./ui/ContentEditable";
import { ITextEditorView } from "./TextEditor.interface";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { Spin, Text } from "../../uiKit";
import { ToolbarPlugin } from "./plugins/ToolbarPlugin/ToolbarPlugin";
import DraggableBlockPlugin from "./plugins/DraggableBlockPlugin/DraggableBlockPlugin";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import AnchorPlugin from "./plugins/AnchorPlugin/AnchorPlugin";
import ActionsPlugin from "./plugins/ActionPlugin/ActionPlugin";
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { EditorState } from "lexical";

export const TextEditorView = memo((props: ITextEditorView) => {
  const { historyState } = useSharedHistoryContext();
  const [editor] = useLexicalComposerContext();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const editMode = editor.isEditable();

  const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLDivElement | null>(null);
  // const [isSmallWidthViewport, setIsSmallWidthViewport] = useState<boolean>(false);
  const placeholder = (
    <Text className="text-editor-placeholder">
      {props.placeholder ? props.placeholder : t("ui:placeholder.text_editor_placeholder")}
    </Text>
  );

  const onRef = useCallback((_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  }, []);

  const handleValueChange = useCallback(
    (value: EditorState) => {
      props.onChange?.(JSON.stringify(value));
    },
    [props]
  );

  // useEffect(() => {
  //   const updateViewPortWidth = () => {
  //     const isNextSmallWidthViewport = CAN_USE_DOM && window.matchMedia("(max-width: 1025px)").matches;
  //
  //     if (isNextSmallWidthViewport !== isSmallWidthViewport) {
  //       setIsSmallWidthViewport(isNextSmallWidthViewport);
  //     }
  //   };
  //
  //   window.addEventListener("resize", updateViewPortWidth);
  //
  //   return () => {
  //     window.removeEventListener("resize", updateViewPortWidth);
  //   };
  // }, [isSmallWidthViewport]);

  const contentEditable = (
    <Spin spinning={props.isLoading}>
      <div className="editor-scroller">
        <div className="editor" ref={onRef}>
          <ContentEditable height={props.height} />
        </div>
      </div>
    </Spin>
  );

  const renderPlugins = () => {
    return editMode ? (
      <>
        <HistoryPlugin externalHistoryState={historyState} />
        <RichTextPlugin
          contentEditable={contentEditable}
          placeholder={placeholder}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <MarkdownShortcutPlugin />
        <CodeHighlightPlugin />
        <ListPlugin />
        <TablePlugin />
        <TableCellResizer />
        <ImagesPlugin />
        <LinkPlugin />
        <YouTubePlugin />
        <AnchorPlugin />
        <ClickableLinkPlugin />
        <HorizontalRulePlugin />
        <TabFocusPlugin />
        <TabIndentationPlugin />
        {floatingAnchorElem && (
          <>
            <DraggableBlockPlugin anchorElem={floatingAnchorElem} />
            <TableCellActionMenuPlugin anchorElem={floatingAnchorElem} />
            <FloatingTextFormatToolbarPlugin anchorElem={floatingAnchorElem} />
          </>
        )}
        {pathname == "/edit-content" && <ActionsPlugin />}
      </>
    ) : (
      <>
        <PlainTextPlugin
          contentEditable={<ContentEditable />}
          placeholder={placeholder}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <HistoryPlugin externalHistoryState={historyState} />
      </>
    );
  };

  return (
    <div className="editor-shell">
      {editMode && !props.isHideToolbar && <ToolbarPlugin isLoadingChange={props.isLoadingChange} />}
      <div className={`editor-container ${editMode ? "" : "plain-text"}`}>
        <OnChangePlugin onChange={handleValueChange} />
        <DragDropPaste />
        <AutoFocusPlugin />
        <ClearEditorPlugin />
        <AutoEmbedPlugin />
        <MentionsPlugin />
        <HashtagPlugin />
        <AutoLinkPlugin />
        {renderPlugins()}
      </div>
    </div>
  );
});
