import React, { useState } from "react";
import OrgchartDrawerTabPermissionsView from "./OrgchartDrawerTabPermissionsView";
import { useNotifier, useRootStore } from "../../../../../../hooks";
import { RoleDto } from "../../../../../../api";
import { observer } from "mobx-react-lite";
import { api } from "../../../../../../services";
import { useTranslation } from "react-i18next";

export interface IOrgchartDrawerTabPermissions {
  functionData: RoleDto;
  setFunctionData(data: RoleDto): void;
}

function OrgchartDrawerTabPermissions(props: IOrgchartDrawerTabPermissions) {
  const { authStore, helperStore } = useRootStore();
  const notifier = useNotifier();
  const { t } = useTranslation();
  const [fData, setFData] = useState<RoleDto>(props.functionData);

  const allPermissions = helperStore.getPermissions;

  const handleSwitchChange = async (value: boolean, id: number, type: string) => {
    let dataToSend: RoleDto = { ...fData };
    if (value) {
      dataToSend = {
        ...dataToSend,
        claims: [...(dataToSend.claims ?? []), { id: 0, roleId: props.functionData.id, type: type }],
      };
    } else {
      dataToSend = {
        ...dataToSend,
        claims: [...(dataToSend.claims ?? []).filter((claim) => claim.type != type)],
      };
    }
    setFData({ ...dataToSend });

    const r = await api.role.edit(fData.id as number, dataToSend, { includeClaims: true });
    if (r == null) {
      notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
      setFData(props.functionData);
      return;
    } else {
      setFData(r);
      if (props.setFunctionData) {
        props.setFunctionData(r);
      }
      await updateCurrentUserInitialInfoIfNeed();
    }
  };

  const updateCurrentUserInitialInfoIfNeed = async () => {
    if (props.functionData.users?.some((user) => user.userId == authStore.getInitialInfo?.identity?.id)) {
      await authStore.refreshInitialInfo();
    }
  };

  return (
    <OrgchartDrawerTabPermissionsView
      functionData={fData}
      handleSwitchChange={handleSwitchChange}
      allPermissions={allPermissions}
    />
  );
}

export default observer(OrgchartDrawerTabPermissions);
