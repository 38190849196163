import React from "react";
import { Divider } from "antd";
import { useTranslation } from "react-i18next";
import { historySortKeys } from "../../misc/consts";
import { IRegulationHistoryHeader } from "./RegulationHistory.interface";
import { Select, Text } from "../../../../../uiKit";
import { ISelectItem } from "../../../../../uiKit/select/SelectView";

const RegulationHistoryHeaderMemo: React.FC<IRegulationHistoryHeader> = (props) => {
  const { t } = useTranslation();

  const selectOptions: ISelectItem[] = [
    {
      value: historySortKeys.onesFirst,
      text: t("common:misc.sort_items.ones_first"),
    },
    {
      value: historySortKeys.oldOnes,
      text: t("common:misc.sort_items.old_ones"),
    },
  ];

  return (
    <>
      <Divider style={{ margin: 0 }} />
      <div className="d-flex justify-space-between align-center mt-4 mb-2">
        <Text weight={500} children={t("parse:history_log")} />
        <Select value={props.sortValue} onChange={props.onChangeSortValue} items={selectOptions} />
      </div>
    </>
  );
};

export const RegulationHistoryHeader = React.memo(RegulationHistoryHeaderMemo);
