import React from "react";
import "./UpvotyWidgetView.scss";

interface IUpvotyWidgetViewView {
  //
}

function UpvotyWidgetViewView(props: IUpvotyWidgetViewView) {
  return <div className="upvoty-widget__wrapper" data-upvoty />;
}

export default UpvotyWidgetViewView;
