import { css } from "@emotion/css";
import { comboboxSelectors, getComboboxStoreById } from "@udecode/plate";
import {
  comboboxActions,
  useActiveComboboxStore,
  useComboboxControls,
  useComboboxSelectors,
} from "@udecode/plate-combobox";
import { useEditorState, useEventEditorSelectors } from "@udecode/plate-core";
import { PortalBody } from "@udecode/plate-styled-components";
import { getRangeBoundingClientRect, usePopperPosition, virtualReference } from "@udecode/plate-ui-popper";
import React, { useCallback, useEffect } from "react";
import { UserInfo } from "../../../../elements/userInfo/UserInfo";
import ScrollTrigger from "../../../../service/scrollTrigger/ScrollTrigger";

const ComboboxContent = (props: any) => {
  const { component: Component, items, portalElement, isDone, loadNext } = props;

  const targetRange = useComboboxSelectors.targetRange();
  const filteredItems = useComboboxSelectors.filteredItems();
  const highlightedIndex = useComboboxSelectors.highlightedIndex();
  const popperContainer = useComboboxSelectors.popperContainer?.();
  const popperOptions = useComboboxSelectors.popperOptions?.();
  const editor = useEditorState();
  const combobox = useComboboxControls();
  const activeComboboxStore = useActiveComboboxStore()!;
  const text = useComboboxSelectors.text() ?? "";
  const storeItems = useComboboxSelectors.items();
  const filter = activeComboboxStore.use.filter?.();
  // @ts-ignore
  const sort = activeComboboxStore.use.sort?.();
  const maxSuggestions = activeComboboxStore.use.maxSuggestions?.() ?? storeItems.length;
  const popperRef = React.useRef<any>(null);
  // Update items
  useEffect(() => {
    items && comboboxActions.items(items);
  }, [items]);
  // Filter items
  useEffect(() => {
    comboboxActions.filteredItems(items);
  }, [filter, sort, storeItems, maxSuggestions, text, items]);

  // Get target range rect
  const getBoundingClientRect = useCallback(
    () => getRangeBoundingClientRect(editor, targetRange) ?? virtualReference,
    [editor, targetRange]
  );

  // Update popper position
  const { styles: popperStyles, attributes } = usePopperPosition({
    popperElement: popperRef.current,
    popperContainer,
    popperOptions,
    placement: "bottom-start",
    getBoundingClientRect,
    offset: [0, 4],
  });
  const menuProps = combobox ? combobox.getMenuProps({}, { suppressRefError: true }) : { ref: null };

  return (
    <PortalBody element={portalElement}>
      <div
        {...menuProps}
        ref={popperRef}
        style={popperStyles.popper}
        className={css`
          background-color: white;
          border-radius: 4px;
          box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
          overflow-y: auto;
          max-height: 300px;
          z-index: 9000;
        `}
        {...attributes.popper}
      >
        {Component ? Component({ store: activeComboboxStore }) : null}

        {filteredItems.map((item: any, index) => {
          const highlighted: boolean = index === highlightedIndex;

          return (
            <div
              key={item.data.id}
              className={css`
                align-items: center;
                margin: 4px;
                display: flex;
                cursor: pointer;
                background: ${highlighted ? "var(--color-layout-fill-base)" : ""};
                height: 36px;
                padding: 4px;

                &:hover {
                  background: var(--color-layout-fill-base);
                }
              `}
              {...combobox.getItemProps({
                item,
                index,
              })}
              onMouseDown={(e) => {
                e.preventDefault();
                const onSelectItem = getComboboxStoreById(comboboxSelectors.activeId())?.get.onSelectItem();
                onSelectItem?.(editor, item);
              }}
            >
              <UserInfo user={item.data} isTooltipDisabled />
            </div>
          );
        })}
        <ScrollTrigger
          onIntersection={loadNext}
          hidden={isDone}
          disabled={isDone}
          marginTop={filteredItems?.length > 0}
        />
      </div>
    </PortalBody>
  );
};

/**
 * Register the combobox id, trigger, onSelectItem
 * Renders the combobox if active.
 */
export const Combobox = ({
  id,
  trigger,
  searchPattern,
  onSelectItem,
  controlled,
  maxSuggestions,
  filter,
  // @ts-ignore
  sort,
  ...props
}: any) => {
  const editor = useEditorState();
  const focusedEditorId = useEventEditorSelectors.focus?.();
  const combobox = useComboboxControls();
  const activeId = useComboboxSelectors.activeId();

  useEffect(() => {
    comboboxActions.setComboboxById({
      id,
      trigger,
      searchPattern,
      controlled,
      onSelectItem,
      maxSuggestions,
      filter,
      // @ts-ignore
      sort,
    });
  }, [id, trigger, searchPattern, controlled, onSelectItem, maxSuggestions, filter, sort]);

  if (!combobox || !editor.selection || focusedEditorId !== editor.id || activeId !== id) {
    comboboxActions.reset();
    return null;
  }

  return <ComboboxContent {...props} />;
};
