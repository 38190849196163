import React, { forwardRef } from "react";
import { IShowUpdateNoteViewDialog } from "./ShowUpdateNoteDialog.interface";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions } from "../../../uiKit";
import { initialContent, stringToContent } from "../../../../utils/textEditor";
import { UpdateNoteEditor } from "../../pages/update-note/components/UpdateNoteEditor/UpdateNoteEditor";
import "./ShowUpdateNoteDialog.scss";

export const ShowUpdateNoteViewDialog = forwardRef((props: IShowUpdateNoteViewDialog, ref) => {
  const { t } = useTranslation();

  const hasNext = props.noCheckedNotes.length - 1 > props.currentIndexNote;

  return props.currentNote?.content ? (
    <Dialog
      title={props.currentNote.name}
      // title={t(`ui:title.${hasNext ? "update_notes" : "update_note"}`)}
      open={props.isOpen}
      onClose={props.onClose}
      className="update-notes-dialog__wrapper"
      scrollStrategy="dialogBody"
    >
      {/*<h2>{props.currentNote.name}</h2>*/}
      <UpdateNoteEditor
        ref={ref}
        onChangeDraftUpdateNoteContent={() => {}}
        draftUpdateNoteContent={
          props.currentNote?.content ? stringToContent(props.currentNote.content) : initialContent
        }
        isEditable={false}
      />
      <DialogActions>
        <Button onClick={props.onClose} variant="text">
          {t("ui:button.later")}
        </Button>
        <Button loading={props.isLoadingCheckNextBtn} onClick={props.onCheck} type="primary" variant="text">
          {t(`ui:button.${hasNext ? "next" : "ok"}`)}
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
});
