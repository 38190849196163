import React from "react";
import { LayoutView } from "./LayoutView";
import { LayoutProps } from "antd";

interface ILayout extends LayoutProps {
  //
}

export function Layout(props: ILayout) {
  return <LayoutView {...props} />;
}
