import React from "react";
import { useParams } from "react-router-dom";
import PageHeader from "../../modules/layouts/pageHeader/PageHeader";
import PageContent from "../../modules/layouts/pageContent/PageContent";
import { useTranslation } from "react-i18next";
import EmployeeEditForm from "../../modules/forms/employeeEditForm/EmployeeEditForm";

function EmployeeEditPage() {
  const { id } = useParams();
  const { t } = useTranslation();

  return (
    <>
      <PageHeader title={t("common:page_title.employee_edit")} />
      <PageContent isFilled>
        <div style={{ width: 450 }}>
          <EmployeeEditForm userId={Number(id)} />
        </div>
      </PageContent>
    </>
  );
}

export default EmployeeEditPage;
