import React from "react";
import "./MetricToDashboardDialogCard.scss";
import { IMetricToDashboardDialogCard } from "./MetricToDashboardDialogCard.interface";
import { Icon, IconButton } from "../../../../../uiKit";
import { FiX } from "@react-icons/all-files/fi/FiX";
import { useSortable } from "@dnd-kit/sortable";
import { FiMenu } from "react-icons/all";
import { CSS } from "@dnd-kit/utilities";

export const MetricToDashboardDialogCard = (props: IMetricToDashboardDialogCard) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: props.metric.order as number,
    animateLayoutChanges: () => false,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const handleGetMetricSourceUsers = () => {
    return props.metrics
      ?.find((m) => m.id == props.metric?.metricId)
      ?.sources?.map((s) => {
        return s.source2Users?.map((s2u, index) =>
          s2u.user2Role ? <span key={s2u.id}>{`${index == 0 ? "" : `, `}${s2u.user2Role?.user?.name}`}</span> : null
        );
      });
  };

  return (
    <div
      className={`metric-to-dashboard-dialog-card__container d-stack-row spacing-2 align-center py-1 px-2 br-md`}
      ref={setNodeRef}
      style={style}
    >
      <IconButton
        {...attributes}
        {...listeners}
        type="link"
        className="flex-shrink-0"
        style={{ cursor: "grab" }}
        children={<Icon component={() => <FiMenu />} />}
      />
      <div className="metric-to-dashboard-dialog-card__content flex-grow-1 d-stack-column">
        <span children={props.metric.metric?.name ?? ""} />
        <div>
          {props.metric.metricSource2UserId ? (
            <span>{props.metric.metricSource2User?.user2Role?.user?.name}</span>
          ) : (
            handleGetMetricSourceUsers()
          )}
        </div>
      </div>
      <IconButton
        className="flex-shrink-0"
        // type="link"
        children={<Icon component={() => <FiX />} />}
        onClick={() =>
          props.deleteMetric(
            props.metric.metricSource2UserId ? props.metric.metricSource2UserId! : props.metric.metricId!
          )
        }
      />
    </div>
  );
};
