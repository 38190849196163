import { css } from "@emotion/css";
import type { DOMConversionMap, Spread } from "lexical";
import {
  $applyNodeReplacement,
  DecoratorNode,
  DOMConversionOutput,
  type DOMExportOutput,
  type EditorConfig,
  ElementFormatType,
  LexicalEditor,
  type LexicalNode,
  type NodeKey,
  SerializedLexicalNode,
} from "lexical";
import { UserInfo } from "../../../elements/userInfo/UserInfo";

export type SerializedMentionNode = Spread<
  {
    id: number;
    type: "mention";
    subType: string;
    version: 1;
  },
  SerializedLexicalNode
>;

function convertMentionElement(domNode: HTMLElement): null | DOMConversionOutput {
  const id = domNode.getAttribute("id");
  if (id) {
    const node = $createMentionNode(Number(id), "");
    return { node };
  }
  return null;
}

export class MentionNode extends DecoratorNode<JSX.Element> {
  __id: number;
  __subType: string;

  static getType(): string {
    return "mention";
  }

  static clone(node: MentionNode): MentionNode {
    return new MentionNode(node.__id, node.__subType, node.__format, node.__key);
  }
  static importJSON(serializedNode: SerializedMentionNode): MentionNode {
    return $createMentionNode(serializedNode.id, serializedNode.subType ?? "");
  }

  exportJSON(): SerializedMentionNode {
    return {
      id: this.__id,
      type: "mention",
      subType: this.__subType,
      version: 1,
    };
  }

  constructor(id: number, subType: string, format?: ElementFormatType, key?: NodeKey) {
    super(key);
    this.__id = id;
    this.__subType = subType;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement("span");
    element.setAttribute("id", String(this.__id));
    // const text = document.createTextNode(this.getTextContent());
    // element.append(text);
    return { element };
  }

  createDOM() {
    const elem = document.createElement("span");
    elem.style.display = "inline-block";
    return elem;
  }

  static importDOM(): DOMConversionMap | null {
    return {
      span: (domNode: HTMLElement) => {
        if (!domNode.hasAttribute("id")) {
          return null;
        }
        return {
          conversion: convertMentionElement,
          priority: 1,
        };
      },
    };
  }

  updateDOM(): false {
    return false;
  }

  isToken(): true {
    return true;
  }

  getTextContent(_includeInert?: boolean | undefined, _includeDirectionless?: false | undefined): string {
    return "";
  }

  getStyle(subType: string) {
    switch (subType) {
      case "success":
        return "var(--editor-color-success)";
      case "error":
        return "var(--editor-color-error)";
      default:
        return "var(--color-layout-background)";
    }
  }

  decorate(editor: LexicalEditor, config: EditorConfig): JSX.Element {
    return (
      <span
        className={css`
          margin: 4px 0 4px 0;
          padding: 4px;
          vertical-align: middle;
          border-radius: var(--border-radius-sm);
          display: inline-block;
          //pointer-events: none;
        `}
        style={{ backgroundColor: this.getStyle(this.__subType ?? "") }}
      >
        <UserInfo userIdToFetch={this.__id} requestOptions={{ ignoreContext: true }} />
      </span>
    );
  }
}

export function $createMentionNode(id: number, subType: string): MentionNode {
  return $applyNodeReplacement(new MentionNode(id, subType));
}

export function $isMentionNode(node: LexicalNode | null | undefined): node is MentionNode {
  return node instanceof MentionNode;
}
