import React from "react";
import "./CollapsibleContainer.scss";
import { Collapse } from "antd";
import clsx from "clsx";

interface ICollapsibleContainerView {
  isOpen: boolean;
  onChange: () => void;
  children?: React.ReactNode;
  className?: string;
}

export function CollapsibleContainerView(props: ICollapsibleContainerView) {
  return (
    <Collapse
      className={clsx("uiKit-collapsibleContainer", props.className ?? "")}
      activeKey={props.isOpen ? "1" : undefined}
    >
      <Collapse.Panel className={props.className} key="1" header={<div />} children={props.children} />
    </Collapse>
  );
}
