import React, { useState } from "react";
import { Divider, Input, Select } from "../../../../uiKit";
import SubheaderText from "../../../../elements/subheaderText/SubheaderText";

export function UiTabInput() {
  const [elSize, setElSize] = useState<"small" | "middle" | "large">("middle");
  const antLink: string = "https://ant.design/components/input/";

  const elProps: { name: string; description: string; type: string }[] = [
    { name: "readOnly", description: "read-only", type: "boolean" },
  ];

  return (
    <div className="">
      <Divider className="mt-2 mb-5" />
      <div>
        <div className="d-flex justify-space-between">
          <div className="d-stack-column spacing-1">
            <SubheaderText text={"Кастомные пропсы"} />
            {elProps.map((p, i) => (
              <div key={i}>
                <b children={p.name} /> - <span children={p.description} /> <i children={`[${p.type}]`} />
              </div>
            ))}
          </div>
          <div className="d-stack-column spacing-1">
            <SubheaderText text={"Размер элементов"} />
            <Select
              value={elSize}
              onChange={setElSize}
              size="middle"
              items={[
                { id: 0, text: "Small", value: "small" },
                { id: 1, text: "Middle", value: "middle" },
                { id: 2, text: "Large", value: "large" },
              ]}
            />
          </div>
        </div>
        <Divider className="my-5" />
        <div className="d-stack-column spacing-2 uiKitPage-page-container">
          <Input size={elSize} placeholder="default" />
          <Input size={elSize} readOnly placeholder="readOnly" />
        </div>
      </div>
    </div>
  );
}
