import React, { useEffect, useState } from "react";
import PageMapView from "./PageMapView";
// @ts-ignore
import pagemap from "pagemap";

interface IPageMap {
  //
}

function PageMap(props: IPageMap) {
  const [isMapShowing, setIsMapShowing] = useState(false);

  useEffect(() => {
    if (!isMapShowing) {
      pagemap(document.querySelector("#pageMapCanvas"), {
        viewport: document.getElementsByClassName("page-content-sheet_main__content")[0],
        // viewport: document.querySelector("#pageContent"),
        styles: {
          ".orgchart-card": "rgba(0, 0, 0, 0.2)",
          // "h1,a": "rgba(0, 0, 0, 0.10)",
          // "h2,h3,h4": "rgba(0, 0, 0, 0.08)",
        },
        back: "rgba(0, 0, 0, 0.1)",
        view: "rgba(0, 0, 0, 0.15)",
        drag: "rgba(0, 0, 0, 0.25)",
        interval: 100,
      });
      setIsMapShowing(true);
    }
  }, []);

  return <PageMapView />;
}

export default PageMap;
