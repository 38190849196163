import React, { forwardRef, MouseEvent, useImperativeHandle, useRef } from "react";
import { useTranslation } from "react-i18next";
import { TextEditor } from "../../modules/textEditor/TextEditor";
import { InitialEditorStateType } from "@lexical/react/LexicalComposer";

interface IEditContentView {
  content: InitialEditorStateType;
  onContentChange: (value: InitialEditorStateType) => void;
  // onUploadJsonFile: (uploadedFile: File | undefined) => void;
  // onDownloadJsonFile: () => void;
  setUploadFileName: (value: string | undefined) => void;
  onUploadContentToServer: () => void;
}

export const EditContentView = forwardRef((props: IEditContentView, ref: any) => {
  const contentRef = useRef<{ clear: () => void; reset: () => void; syncValue: () => void }>();
  const fileUploadRef = useRef<any>(null);
  const { t } = useTranslation();

  const clearContent = () => {
    contentRef.current?.clear();
    props.setUploadFileName(undefined);
  };

  const clearRefValue = () => {
    fileUploadRef.current.value = "";
  };

  useImperativeHandle(ref, () => ({
    reset: contentRef.current?.reset,
    clearValue: clearRefValue,
    syncValue: contentRef.current?.syncValue,
  }));

  const handleUploadJsonMouseDown = (event: MouseEvent) => {
    event.preventDefault();
    fileUploadRef.current?.click();
  };

  return (
    <TextEditor
      value={props.content}
      anchorKey={"solution"}
      onChange={props.onContentChange}
      height="80vh"
      placeholder={t("ui:placeholder.upload_the_json_file")}
    />
  );
});
