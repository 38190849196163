import { RelationsTypes } from "../components/boardRelationSwitch/BoardRelationSwitch.interface";
import { sortKeys, sortKeyType } from "../../../../pages/communication/misc/consts";
import { RelationsKeys } from "../constants/keys";

interface IRegeneratorFilters {
  userRelation: RelationsTypes;
  sortValue: sortKeyType;
  boardId?: number | null;
  senderId?: number | null;
  hasViolation: boolean;
  executorId?: number | null;
  flowType?: number;
}

export const regeneratorFilters = (filters: IRegeneratorFilters) => ({
  getReqFilters: () => {
    if (filters.userRelation === RelationsKeys.My) {
      return {
        hasViolation: filters.hasViolation,
        createdByUserId: filters.senderId ?? null,
        flowType: filters.flowType == 3 ? undefined : filters.flowType,
      };
    }
    if (filters.userRelation === RelationsKeys.Controlled) {
      return {
        hasViolation: filters.hasViolation,
        executorUserId: filters.executorId ?? null,
        flowType: filters.flowType == 3 ? undefined : filters.flowType,
      };
    }
    if (filters.userRelation === RelationsKeys.Participated) {
      return {
        hasViolation: filters.hasViolation,
        executorUserId: filters.executorId ?? null,
        createdByUserId: filters.senderId ?? null,
        flowType: filters.flowType == 3 ? undefined : filters.flowType,
      };
    }
    return {};
  },
  getReqSortFilters: () => {
    switch (filters.sortValue) {
      case sortKeys.date:
        return {
          order: "desc",
          orderBy: sortKeys.date,
        };
      case sortKeys.date_reverse:
        return {
          order: "asc",
          orderBy: sortKeys.date,
        };
      case sortKeys.date_deadline:
        return {
          order: "asc",
          orderBy: sortKeys.date_deadline,
        };
      case sortKeys.date_deadline_is_null:
        return {
          order: "asc",
          orderBy: sortKeys.date_deadline_is_null,
        };
      default:
        return {
          order: "asc",
          orderBy: sortKeys.date,
        };
    }
  },
});
