import React from "react";
import { StyledElementProps, Value } from "@udecode/plate";
import { TElement } from "@udecode/plate-core";
import { Avatar } from "../../../../uiKit";
import { css } from "@emotion/css";
import { useApi } from "../../../../../hooks";
import { api } from "../../../../../services";

export interface TOrgchartCardElement extends TElement {
  id: number;
}

export const OrgchartCardElement = <V extends Value>(props: StyledElementProps<Value, TOrgchartCardElement>) => {
  const { attributes, children, element, nodeProps } = props;
  const orgchart = useApi(() => api.orgchart.getById(element.id));

  return (
    <div {...attributes} style={{ display: "inline-block" }}>
      <div
        className={css`
          margin: 8px 0 8px 0;
          padding: 8px;
          vertical-align: middle;
          border-radius: 4px;
          background-color: ${element.backgroundColor ? `${element.backgroundColor}` : "#eee"};
          display: inline-block;
        `}
        style={{ pointerEvents: "none", userSelect: "none" }}
        contentEditable={false}
      >
        {children}
        <div className="d-stack-row align-center justify-center">
          <div>
            <Avatar
              size={20}
              src={orgchart.value?.image?.url}
              text={orgchart.value?.name}
              color={orgchart.value?.colorHex}
            />
          </div>
          <div className="ml-2">{orgchart.value?.name}</div>
        </div>
      </div>
    </div>
  );
};
