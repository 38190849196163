import React, { useEffect, useMemo, useState } from "react";
import PageHeader from "../../modules/layouts/pageHeader/PageHeader";
import PageContent from "../../modules/layouts/pageContent/PageContent";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { LocalStorageHelpers, useLocalStorage, useRootStore } from "../../../hooks";
import { BoardRelationSwitch } from "../../modules/pages/communications/components/boardRelationSwitch/BoardRelationSwitch";
import { CreateIssueButton } from "../../modules/pages/communications/components/createIssueButton/CreateIssueButton";
import { sortKeys, sortKeyType, sortValues } from "./misc/consts";
import VideoDialog from "../../modules/dialogs/videoDialog/VideoDialog";
import ZoomButton from "../../elements/zoomButton/ZoomButton";
import { RelationsTypes } from "../../modules/pages/communications/components/boardRelationSwitch/BoardRelationSwitch.interface";
import { RelationsKeys } from "../../modules/pages/communications/constants/keys";
import { BoardFilterContext, IBoardColumnsState } from "../../../contexts/communication/boardFilterContext";
import { BoardCollapse } from "../../modules/pages/communications/components/boardCollapse/BoardCollapse";
import { SortButton } from "../../elements/sortButton/SortButton";
import { FilterButton } from "../../elements/filterButton/FilterButton";
import { FilterDropdownContent } from "../../modules/pages/communications/components/filterDropdownContent/FilterDropdownContent";
import { ArchiveButton } from "../../modules/pages/communications/components/archiveButton/ArchiveButton";
import { valueType } from "../../elements/sortButton/SortButton.interface";
import { IDropdownItem } from "../../uiKit/navigation/dropdown/Dropdown";

function CommunicationPageViewObserver() {
  const { t } = useTranslation();
  const { authStore, boardStore } = useRootStore();

  const zoomInitValue: number =
    typeof LocalStorageHelpers.get("communicationTableZoom") == "number"
      ? LocalStorageHelpers.get("communicationTableZoom") ?? 1
      : 1;

  const sortMenuValues: IDropdownItem[] = [
    { key: 1, text: t("common:misc.sort_items.ones_first"), onClick: () => handleChangeSortOptions(sortKeys.date) },
    {
      key: 2,
      text: t("common:misc.sort_items.old_ones"),
      onClick: () => handleChangeSortOptions(sortKeys.date_reverse),
    },
    {
      key: 3,
      text: t("common:misc.sort_items.close_deadline"),
      onClick: () => handleChangeSortOptions(sortKeys.date_deadline),
    },
  ];

  const [userRelationFilter, setUserRelationFilter] = useState<RelationsTypes>(RelationsKeys.My);
  const [sortAndFilter, setSortAndFilter] = useLocalStorage<
    {
      key: RelationsTypes;
      companyId?: number | null;
      sortValue?: sortKeyType;
      filterState?: { senderId?: number; executorId?: number; hasViolation?: boolean; flowType?: number };
    }[]
  >("sortAndFilterBackup", []);
  const [boardColumnsState, setBoardColumnsState] = useState<IBoardColumnsState[]>([]);
  const [zoom, setZoom] = useState(zoomInitValue);

  const board = boardStore.getBoard;

  const handleChangeUserRelation = (relation: RelationsTypes) => {
    setUserRelationFilter(relation);
  };
  const handleChangeSenderIdFilter = (id?: number) => {
    if (sortAndFilter.some((v) => v.key == userRelationFilter && v.companyId == authStore.getCurrentCompanyId)) {
      setSortAndFilter([
        ...sortAndFilter.map((v) =>
          v.key == userRelationFilter && v.companyId == authStore.getCurrentCompanyId
            ? { ...v, filterState: { ...(v.filterState ?? {}), senderId: id } }
            : v
        ),
      ]);
    } else {
      setSortAndFilter([
        ...sortAndFilter,
        { key: userRelationFilter, companyId: authStore.getCurrentCompanyId, filterState: { senderId: id } },
      ]);
    }
  };
  const handleChangeExecutorIdFilter = (id?: number) => {
    if (sortAndFilter.some((v) => v.key == userRelationFilter && v.companyId == authStore.getCurrentCompanyId)) {
      setSortAndFilter([
        ...sortAndFilter.map((v) =>
          v.key == userRelationFilter && v.companyId == authStore.getCurrentCompanyId
            ? { ...v, filterState: { ...(v.filterState ?? {}), executorId: id } }
            : v
        ),
      ]);
    } else {
      setSortAndFilter([
        ...sortAndFilter,
        { key: userRelationFilter, companyId: authStore.getCurrentCompanyId, filterState: { executorId: id } },
      ]);
    }
  };
  const handleChangeHasViolationFilter = (value: boolean) => {
    if (sortAndFilter.some((v) => v.key == userRelationFilter && v.companyId == authStore.getCurrentCompanyId)) {
      setSortAndFilter([
        ...sortAndFilter.map((v) =>
          v.key == userRelationFilter && v.companyId == authStore.getCurrentCompanyId
            ? { ...v, filterState: { ...(v.filterState ?? {}), hasViolation: value } }
            : v
        ),
      ]);
    } else {
      setSortAndFilter([
        ...sortAndFilter,
        { key: userRelationFilter, companyId: authStore.getCurrentCompanyId, filterState: { hasViolation: value } },
      ]);
    }
  };
  const handleChangeFlowTypeFilter = (value: number) => {
    if (sortAndFilter.some((v) => v.key == userRelationFilter && v.companyId == authStore.getCurrentCompanyId)) {
      setSortAndFilter([
        ...sortAndFilter.map((v) =>
          v.key == userRelationFilter ? { ...v, filterState: { ...(v.filterState ?? {}), flowType: value } } : v
        ),
      ]);
    } else {
      setSortAndFilter([
        ...sortAndFilter,
        { key: userRelationFilter, companyId: authStore.getCurrentCompanyId, filterState: { flowType: value } },
      ]);
    }
  };
  const handleResetFilters = () => {
    handleChangeSenderIdFilter(undefined);
    handleChangeExecutorIdFilter(undefined);
    handleChangeHasViolationFilter(false);
  };
  const handleChangeBoardColumnsState = (newValue: IBoardColumnsState) =>
    setBoardColumnsState((prev) =>
      prev.some((item) => item.boardColumnId == newValue.boardColumnId)
        ? [...prev].map((item) => (item.boardColumnId == newValue.boardColumnId ? newValue : item))
        : [...prev, newValue]
    );

  const handleChangeSortOptions = (sortKey: sortKeyType) => {
    if (sortAndFilter.some((v) => v.key == userRelationFilter && v.companyId == authStore.getCurrentCompanyId)) {
      setSortAndFilter([
        ...sortAndFilter.map((v) =>
          v.key == userRelationFilter && v.companyId == authStore.getCurrentCompanyId ? { ...v, sortValue: sortKey } : v
        ),
      ]);
    } else {
      setSortAndFilter([
        ...sortAndFilter,
        { key: userRelationFilter, companyId: authStore.getCurrentCompanyId, sortValue: sortKey },
      ]);
    }
  };

  const isLoading = useMemo(() => boardColumnsState.some((item) => item.isLoading), [boardColumnsState]);

  useEffect(() => {
    LocalStorageHelpers.set("communicationTableZoom", zoom);
  }, [zoom]);

  useEffect(() => {
    if (authStore.getCurrentBoardId) {
      boardStore.setBoardId(authStore.getCurrentBoardId);
      boardStore.fetchBoard();
    }
  }, [authStore.getCurrentBoardId]);

  return (
    <BoardFilterContext.Provider
      value={{
        userRelation: userRelationFilter,
        boardId: authStore.getCurrentBoardId,
        sortValue:
          sortAndFilter.find((v) => v.key == userRelationFilter && v.companyId == authStore.getCurrentCompanyId)
            ?.sortValue ?? sortKeys.date,
        hasViolation:
          sortAndFilter.find((v) => v.key == userRelationFilter && v.companyId == authStore.getCurrentCompanyId)
            ?.filterState?.hasViolation ?? false,
        senderId:
          sortAndFilter.find((v) => v.key == userRelationFilter && v.companyId == authStore.getCurrentCompanyId)
            ?.filterState?.senderId ?? undefined,
        executorId:
          sortAndFilter.find((v) => v.key == userRelationFilter && v.companyId == authStore.getCurrentCompanyId)
            ?.filterState?.executorId ?? undefined,
        flowType:
          sortAndFilter.find((v) => v.key == userRelationFilter && v.companyId == authStore.getCurrentCompanyId)
            ?.filterState?.flowType ?? 3,
        board,
        boardColumnsState,
        onChangeUserRelation: handleChangeUserRelation,
      }}
    >
      <VideoDialog
        videoId="PmNNuhI6ElU"
        name="communication"
        trainingKey="ed.c.i"
        onWatchedClickObject={{ communication: true }}
        hidden={false}
      />
      <PageHeader>
        <div className="d-stack-row justify-space-between align-center mb-2 spacing-0 full-width">
          <div className="d-stack-row align-center justify-start">
            <BoardRelationSwitch
              boardColumnsState={boardColumnsState}
              onChangeUserRelation={handleChangeUserRelation}
            />
          </div>
          <div style={{ display: "flex" }}>
            <div className="d-stack-row align-center justify-space-between spacing-2">
              <SortButton
                value={
                  sortValues.find(
                    (item) =>
                      item.key ===
                      sortAndFilter.find(
                        (v) => v.key == userRelationFilter && v.companyId == authStore.getCurrentCompanyId
                      )?.sortValue
                  ) ?? (sortValues.find((v) => v.id == 1) as valueType)
                }
                sortMenuValues={sortMenuValues}
              />
              <FilterButton
                dropdownContent={
                  <FilterDropdownContent
                    onChangeFlowTypeFilter={handleChangeFlowTypeFilter}
                    onChangeSenderId={handleChangeSenderIdFilter}
                    onChangeExecutorId={handleChangeExecutorIdFilter}
                    onChangeHasViolation={handleChangeHasViolationFilter}
                  />
                }
              />
              <ZoomButton value={zoom} onChange={setZoom} step={0.1} min={0.7} max={1.2} />
              <ArchiveButton />
              <CreateIssueButton />
            </div>
          </div>
        </div>
      </PageHeader>
      <PageContent isLoading={isLoading} isZoomable zoom={zoom} zoomProperty="zoom">
        <BoardCollapse onChangeBoardColumnsState={handleChangeBoardColumnsState} />
      </PageContent>
    </BoardFilterContext.Provider>
  );
}

export default observer(CommunicationPageViewObserver);
