import React from "react";
import { CounterChipView } from "./CounterChipView";
import { CountChipColorType, ICounterChip } from "./CounterChip.interface";
import { getColorChip, getCount } from "./misc/helpers";

export const CounterChip = (props: ICounterChip) => {
  const color: CountChipColorType = getColorChip(props.colorMode);
  const count: number | string | undefined = getCount(props.count, props.isShortMode);

  return <CounterChipView {...props} color={color} count={count} />;
};
