import { useState } from "react";
import { InitialEditorStateType } from "@lexical/react/LexicalComposer";
import { TextEditor } from "../../../../modules/textEditor/TextEditor";

export const UiTabTextEditor = () => {
  const [content, setContent] = useState<InitialEditorStateType>(
    JSON.stringify({
      root: {
        children: [
          {
            children: [
              {
                detail: 0,
                format: 0,
                mode: "normal",
                style: "",
                text: "Обычный",
                type: "text",
                version: 1,
              },
            ],
            direction: "ltr",
            format: "",
            indent: 0,
            type: "paragraph",
            version: 1,
          },
          {
            children: [
              {
                detail: 0,
                format: 0,
                mode: "normal",
                style: "",
                text: "Заголовок 1",
                type: "text",
                version: 1,
              },
            ],
            direction: "ltr",
            format: "",
            indent: 0,
            type: "heading",
            version: 1,
            tag: "h1",
          },
          {
            children: [
              {
                detail: 0,
                format: 0,
                mode: "normal",
                style: "",
                text: "Заголовок 2",
                type: "text",
                version: 1,
              },
            ],
            direction: "ltr",
            format: "",
            indent: 0,
            type: "heading",
            version: 1,
            tag: "h2",
          },
          {
            children: [
              {
                detail: 0,
                format: 0,
                mode: "normal",
                style: "",
                text: "Заголовок 3 ",
                type: "text",
                version: 1,
              },
            ],
            direction: "ltr",
            format: "",
            indent: 0,
            type: "heading",
            version: 1,
            tag: "h3",
          },
          {
            children: [
              {
                children: [
                  {
                    detail: 0,
                    format: 0,
                    mode: "normal",
                    style: "",
                    text: "Маркированный",
                    type: "text",
                    version: 1,
                  },
                ],
                direction: "ltr",
                format: "",
                indent: 0,
                type: "listitem",
                version: 1,
                value: 1,
              },
            ],
            direction: "ltr",
            format: "",
            indent: 0,
            type: "list",
            version: 1,
            listType: "bullet",
            start: 1,
            tag: "ul",
          },
          {
            children: [
              {
                children: [
                  {
                    detail: 0,
                    format: 0,
                    mode: "normal",
                    style: "",
                    text: "Нумерованный",
                    type: "text",
                    version: 1,
                  },
                ],
                direction: "ltr",
                format: "",
                indent: 0,
                type: "listitem",
                version: 1,
                value: 1,
              },
            ],
            direction: "ltr",
            format: "",
            indent: 0,
            type: "list",
            version: 1,
            listType: "number",
            start: 1,
            tag: "ol",
          },
          {
            children: [
              {
                detail: 0,
                format: 0,
                mode: "normal",
                style: "",
                text: "Цитата",
                type: "text",
                version: 1,
              },
            ],
            direction: "ltr",
            format: "",
            indent: 0,
            type: "quote",
            version: 1,
          },
          {
            children: [
              {
                detail: 0,
                format: 1,
                mode: "normal",
                style: "",
                text: "Жирный ",
                type: "text",
                version: 1,
              },
              {
                detail: 0,
                format: 2,
                mode: "normal",
                style: "",
                text: "Курсив ",
                type: "text",
                version: 1,
              },
              {
                detail: 0,
                format: 8,
                mode: "normal",
                style: "",
                text: "Подчеркнутый ",
                type: "text",
                version: 1,
              },
              {
                detail: 0,
                format: 16,
                mode: "normal",
                style: "",
                text: "Код",
                type: "text",
                version: 1,
              },
              {
                children: [
                  {
                    detail: 0,
                    format: 0,
                    mode: "normal",
                    style: "",
                    text: " ",
                    type: "text",
                    version: 1,
                  },
                ],
                direction: null,
                format: "",
                indent: 0,
                type: "link",
                version: 1,
                rel: "noopener",
                target: null,
                url: "https://google.com",
              },
              {
                children: [
                  {
                    detail: 0,
                    format: 0,
                    mode: "normal",
                    style: "",
                    text: "ссылка",
                    type: "text",
                    version: 1,
                  },
                ],
                direction: "ltr",
                format: "",
                indent: 0,
                type: "link",
                version: 1,
                rel: "noopener",
                target: null,
                url: "https://google.com",
              },
              {
                detail: 0,
                format: 0,
                mode: "normal",
                style: "",
                text: " ",
                type: "text",
                version: 1,
              },
            ],
            direction: "ltr",
            format: "",
            indent: 0,
            type: "paragraph",
            version: 1,
          },
          {
            children: [
              {
                detail: 0,
                format: 0,
                mode: "normal",
                style: "color: #ca7f03;",
                text: "Цвет текста ",
                type: "text",
                version: 1,
              },
              {
                detail: 0,
                format: 0,
                mode: "normal",
                style: "color: #000000;background-color: #509e01;",
                text: "Бэкграунд",
                type: "text",
                version: 1,
              },
            ],
            direction: "ltr",
            format: "",
            indent: 0,
            type: "paragraph",
            version: 1,
          },
          {
            children: [],
            direction: null,
            format: "",
            indent: 0,
            type: "paragraph",
            version: 1,
          },
          {
            type: "horizontalrule",
            version: 1,
          },
          {
            children: [
              {
                altText: "",
                caption: {
                  editorState: {
                    root: {
                      children: [],
                      direction: null,
                      format: "",
                      indent: 0,
                      type: "root",
                      version: 1,
                    },
                  },
                },
                height: 0,
                maxWidth: 500,
                showCaption: false,
                src: "https://api.beta.tonnus.io/static/1/IMGWorldsofAdventureAdmissionTicketinDubai-Klook(20).jpg",
                type: "image",
                version: 1,
                width: 0,
              },
            ],
            direction: null,
            format: "",
            indent: 0,
            type: "paragraph",
            version: 1,
          },
          {
            children: [],
            direction: null,
            format: "",
            indent: 0,
            type: "paragraph",
            version: 1,
          },
          {
            children: [
              {
                children: [
                  {
                    children: [
                      {
                        children: [],
                        direction: null,
                        format: "",
                        indent: 0,
                        type: "paragraph",
                        version: 1,
                      },
                    ],
                    direction: null,
                    format: "",
                    indent: 0,
                    type: "tablecell",
                    version: 1,
                    headerState: 0,
                  },
                  {
                    children: [
                      {
                        children: [],
                        direction: null,
                        format: "",
                        indent: 0,
                        type: "paragraph",
                        version: 1,
                      },
                    ],
                    direction: null,
                    format: "",
                    indent: 0,
                    type: "tablecell",
                    version: 1,
                    headerState: 0,
                  },
                  {
                    children: [
                      {
                        children: [],
                        direction: null,
                        format: "",
                        indent: 0,
                        type: "paragraph",
                        version: 1,
                      },
                    ],
                    direction: null,
                    format: "",
                    indent: 0,
                    type: "tablecell",
                    version: 1,
                    headerState: 0,
                  },
                  {
                    children: [
                      {
                        children: [],
                        direction: null,
                        format: "",
                        indent: 0,
                        type: "paragraph",
                        version: 1,
                      },
                    ],
                    direction: null,
                    format: "",
                    indent: 0,
                    type: "tablecell",
                    version: 1,
                    headerState: 0,
                  },
                  {
                    children: [
                      {
                        children: [],
                        direction: null,
                        format: "",
                        indent: 0,
                        type: "paragraph",
                        version: 1,
                      },
                    ],
                    direction: null,
                    format: "",
                    indent: 0,
                    type: "tablecell",
                    version: 1,
                    headerState: 0,
                  },
                ],
                direction: null,
                format: "",
                indent: 0,
                type: "tablerow",
                version: 1,
              },
              {
                children: [
                  {
                    children: [
                      {
                        children: [],
                        direction: null,
                        format: "",
                        indent: 0,
                        type: "paragraph",
                        version: 1,
                      },
                    ],
                    direction: null,
                    format: "",
                    indent: 0,
                    type: "tablecell",
                    version: 1,
                    headerState: 0,
                  },
                  {
                    children: [
                      {
                        children: [],
                        direction: null,
                        format: "",
                        indent: 0,
                        type: "paragraph",
                        version: 1,
                      },
                    ],
                    direction: null,
                    format: "",
                    indent: 0,
                    type: "tablecell",
                    version: 1,
                    headerState: 0,
                  },
                  {
                    children: [
                      {
                        children: [],
                        direction: null,
                        format: "",
                        indent: 0,
                        type: "paragraph",
                        version: 1,
                      },
                    ],
                    direction: null,
                    format: "",
                    indent: 0,
                    type: "tablecell",
                    version: 1,
                    headerState: 0,
                  },
                  {
                    children: [
                      {
                        children: [],
                        direction: null,
                        format: "",
                        indent: 0,
                        type: "paragraph",
                        version: 1,
                      },
                    ],
                    direction: null,
                    format: "",
                    indent: 0,
                    type: "tablecell",
                    version: 1,
                    headerState: 0,
                  },
                  {
                    children: [
                      {
                        children: [],
                        direction: null,
                        format: "",
                        indent: 0,
                        type: "paragraph",
                        version: 1,
                      },
                    ],
                    direction: null,
                    format: "",
                    indent: 0,
                    type: "tablecell",
                    version: 1,
                    headerState: 0,
                  },
                ],
                direction: null,
                format: "",
                indent: 0,
                type: "tablerow",
                version: 1,
              },
              {
                children: [
                  {
                    children: [
                      {
                        children: [],
                        direction: null,
                        format: "",
                        indent: 0,
                        type: "paragraph",
                        version: 1,
                      },
                    ],
                    direction: null,
                    format: "",
                    indent: 0,
                    type: "tablecell",
                    version: 1,
                    headerState: 0,
                  },
                  {
                    children: [
                      {
                        children: [],
                        direction: null,
                        format: "",
                        indent: 0,
                        type: "paragraph",
                        version: 1,
                      },
                    ],
                    direction: null,
                    format: "",
                    indent: 0,
                    type: "tablecell",
                    version: 1,
                    headerState: 0,
                  },
                  {
                    children: [
                      {
                        children: [],
                        direction: null,
                        format: "",
                        indent: 0,
                        type: "paragraph",
                        version: 1,
                      },
                    ],
                    direction: null,
                    format: "",
                    indent: 0,
                    type: "tablecell",
                    version: 1,
                    headerState: 0,
                  },
                  {
                    children: [
                      {
                        children: [],
                        direction: null,
                        format: "",
                        indent: 0,
                        type: "paragraph",
                        version: 1,
                      },
                    ],
                    direction: null,
                    format: "",
                    indent: 0,
                    type: "tablecell",
                    version: 1,
                    headerState: 0,
                  },
                  {
                    children: [
                      {
                        children: [],
                        direction: null,
                        format: "",
                        indent: 0,
                        type: "paragraph",
                        version: 1,
                      },
                    ],
                    direction: null,
                    format: "",
                    indent: 0,
                    type: "tablecell",
                    version: 1,
                    headerState: 0,
                  },
                ],
                direction: null,
                format: "",
                indent: 0,
                type: "tablerow",
                version: 1,
              },
              {
                children: [
                  {
                    children: [
                      {
                        children: [],
                        direction: null,
                        format: "",
                        indent: 0,
                        type: "paragraph",
                        version: 1,
                      },
                    ],
                    direction: null,
                    format: "",
                    indent: 0,
                    type: "tablecell",
                    version: 1,
                    headerState: 0,
                  },
                  {
                    children: [
                      {
                        children: [],
                        direction: null,
                        format: "",
                        indent: 0,
                        type: "paragraph",
                        version: 1,
                      },
                    ],
                    direction: null,
                    format: "",
                    indent: 0,
                    type: "tablecell",
                    version: 1,
                    headerState: 0,
                  },
                  {
                    children: [
                      {
                        children: [],
                        direction: null,
                        format: "",
                        indent: 0,
                        type: "paragraph",
                        version: 1,
                      },
                    ],
                    direction: null,
                    format: "",
                    indent: 0,
                    type: "tablecell",
                    version: 1,
                    headerState: 0,
                  },
                  {
                    children: [
                      {
                        children: [],
                        direction: null,
                        format: "",
                        indent: 0,
                        type: "paragraph",
                        version: 1,
                      },
                    ],
                    direction: null,
                    format: "",
                    indent: 0,
                    type: "tablecell",
                    version: 1,
                    headerState: 0,
                  },
                  {
                    children: [
                      {
                        children: [],
                        direction: null,
                        format: "",
                        indent: 0,
                        type: "paragraph",
                        version: 1,
                      },
                    ],
                    direction: null,
                    format: "",
                    indent: 0,
                    type: "tablecell",
                    version: 1,
                    headerState: 0,
                  },
                ],
                direction: null,
                format: "",
                indent: 0,
                type: "tablerow",
                version: 1,
              },
              {
                children: [
                  {
                    children: [
                      {
                        children: [],
                        direction: null,
                        format: "",
                        indent: 0,
                        type: "paragraph",
                        version: 1,
                      },
                    ],
                    direction: null,
                    format: "",
                    indent: 0,
                    type: "tablecell",
                    version: 1,
                    headerState: 0,
                  },
                  {
                    children: [
                      {
                        children: [],
                        direction: null,
                        format: "",
                        indent: 0,
                        type: "paragraph",
                        version: 1,
                      },
                    ],
                    direction: null,
                    format: "",
                    indent: 0,
                    type: "tablecell",
                    version: 1,
                    headerState: 0,
                  },
                  {
                    children: [
                      {
                        children: [],
                        direction: null,
                        format: "",
                        indent: 0,
                        type: "paragraph",
                        version: 1,
                      },
                    ],
                    direction: null,
                    format: "",
                    indent: 0,
                    type: "tablecell",
                    version: 1,
                    headerState: 0,
                  },
                  {
                    children: [
                      {
                        children: [],
                        direction: null,
                        format: "",
                        indent: 0,
                        type: "paragraph",
                        version: 1,
                      },
                    ],
                    direction: null,
                    format: "",
                    indent: 0,
                    type: "tablecell",
                    version: 1,
                    headerState: 0,
                  },
                  {
                    children: [
                      {
                        children: [],
                        direction: null,
                        format: "",
                        indent: 0,
                        type: "paragraph",
                        version: 1,
                      },
                    ],
                    direction: null,
                    format: "",
                    indent: 0,
                    type: "tablecell",
                    version: 1,
                    headerState: 0,
                  },
                ],
                direction: null,
                format: "",
                indent: 0,
                type: "tablerow",
                version: 1,
              },
            ],
            direction: null,
            format: "",
            indent: 0,
            type: "table",
            version: 1,
          },
          {
            children: [],
            direction: null,
            format: "",
            indent: 0,
            type: "paragraph",
            version: 1,
          },
          {
            format: "",
            type: "youtube",
            version: 1,
            videoID: "dDeWWQWMM-Y",
          },
          {
            children: [],
            direction: null,
            format: "",
            indent: 0,
            type: "paragraph",
            version: 1,
          },
        ],
        direction: "ltr",
        format: "",
        indent: 0,
        type: "root",
        version: 1,
      },
    })
  );

  return <TextEditor value={content} onChange={setContent} />;
};
