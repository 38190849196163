import React from "react";
import { useTranslation } from "react-i18next";
import { Icon, IconButton } from "../../uiKit";
import { IButton } from "../../uiKit/general/button/Button";
import "./CarrotQuestButton.scss";
import { FiHelpCircle } from "react-icons/all";

interface ICarrotQuestButtonView {
  buttonProps?: IButton;

  onClick: () => void;
}

function CarrotQuestButtonView(props: ICarrotQuestButtonView) {
  const { t } = useTranslation();

  return (
    <IconButton
      onClick={props.onClick}
      children={t("ui:button.support")}
      icon={<Icon component={() => <FiHelpCircle opacity={0.7} fontSize={18} />} />}
      {...props.buttonProps}
    />
  );
}

export default CarrotQuestButtonView;
