import React, { useEffect, useState } from "react";
import PageContent from "../../modules/layouts/pageContent/PageContent";
import PageHeader from "../../modules/layouts/pageHeader/PageHeader";
import { useTranslation } from "react-i18next";
import "./FeedbackPage.scss";
import UpvotyWidget from "../../service/upvotyWidget/UpvotyWidget";

const FeedbackPage = () => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  function checkIframeLoaded() {
    const iframe = document.getElementById("upvoty-iframe");
    // const iframe = document.getElementsByClassName("upvoty-widget__wrapper")?.[0];
    if (iframe != null) {
      iframe.classList.add("__hidden");
      iframe.onload = function () {
        iframe.classList.remove("__hidden");
        setIsLoading(false);
      };
      return;
    }
    setTimeout(() => checkIframeLoaded(), 50);
  }

  useEffect(() => {
    checkIframeLoaded();
    return () => {
      //
      //
    };
  }, []);

  return (
    <>
      <PageHeader title={t("common:page_title.feedback")}></PageHeader>
      <PageContent isLoading={isLoading} isFilled>
        <UpvotyWidget />
      </PageContent>
    </>
  );
};
export default FeedbackPage;
