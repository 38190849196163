import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Plan2IssueDto } from "../../../../../../api";
import { useNotifier } from "../../../../../../hooks";
import { api } from "../../../../../../services";
import { IPlanningPageSubordinatesSidebar } from "./PlanningPageMyPlanSidebar.interface";
import "./PlanningPageMyPlanSidebar.scss";
import { PlanningPageMyPlanSidebarView } from "./PlanningPageMyPlanSidebarView";

export const PlanningPageMyPlanSidebar = (props: IPlanningPageSubordinatesSidebar) => {
  const { t } = useTranslation();
  const notifier = useNotifier();
  const [loadingTaskId, setLoadingTaskId] = useState<number | undefined>(undefined);

  const handleAddIssueToPlan = async (issue: Plan2IssueDto) => {
    setLoadingTaskId(issue.issueId);
    const handleGetArray = () => {
      const currentIssue: Plan2IssueDto | undefined = props.plan?.issues?.find((i) => i.issueId == issue.issueId);
      if (currentIssue && currentIssue.isDeleted == true) {
        return props.plan?.issues?.map((i) => ({
          ...i,
          isDeleted: i.issueId == issue.issueId ? false : i.isDeleted,
        }));
      } else {
        return [...(props.plan?.issues ?? []), issue];
      }
    };
    const r = await api.plan.edit(props.plan?.id!, {
      ...props.plan,
      issues: handleGetArray(),
    });
    if (r) {
      notifier.show({
        message: t("notifier:success.issue_added"),
        theme: "success",
      });
      handleLoadRestart();
    } else {
      notifier.show({
        message: t("notifier:error.something_wrong"),
        theme: "error",
      });
    }
  };

  const handleLoadRestart = () => {
    props.fetchPlans && props.fetchPlans();
    props.issuesFromCommunicationsRestart && props.issuesFromCommunicationsRestart();
    props.issuesRestart && props.issuesRestart(true);
  };

  useEffect(() => {
    !props.isLoading && setLoadingTaskId(undefined);
  }, [props.isLoading]);

  return (
    <PlanningPageMyPlanSidebarView
      plan={props.plan}
      issues={props.issues}
      loadingTaskId={loadingTaskId}
      onAddIssue={handleAddIssueToPlan}
      onHidingSidebarChange={props.onHidingSidebarChange}
      isHideSidebar={props.isHideSidebar}
      issuesFromCommunicationsRestart={props.issuesFromCommunicationsRestart}
    />
  );
};
