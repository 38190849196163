import React from "react";
import { TextAreaProps } from "antd/es/input";
import { Input } from "antd";
import "./TextArea.scss";
import clsx from "clsx";

interface ITextAreaView extends TextAreaProps {
  readOnly?: boolean;
}

const { TextArea } = Input;

export function TextAreaView({ className, readOnly, ...rest }: ITextAreaView) {
  return (
    <TextArea
      size="large"
      autoSize
      className={clsx("uiKit-textArea", className ?? "", {
        "no-interact": readOnly,
        "__placeholder-trimmed":
          (typeof rest.autoSize === "boolean" && rest.autoSize == true) ||
          rest.autoSize == null ||
          (typeof rest.autoSize !== "boolean" && rest.autoSize?.minRows === 1),
      })}
      {...rest}
    />
  );
}
