import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { LexicalTypeaheadMenuPlugin } from "@lexical/react/LexicalTypeaheadMenuPlugin";
import { TextNode } from "lexical";
import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import * as ReactDOM from "react-dom";
import { $createMentionNode } from "../../nodes/MentionNode";
import { UserInfo } from "../../../../elements/userInfo/UserInfo";
import { usePagingWithController } from "../../../../../hooks/usePaging";
import { api } from "../../../../../services";
import useDebounce from "../../../../../hooks/useDebounce";
import ScrollTrigger from "../../../../service/scrollTrigger/ScrollTrigger";
import { Popover } from "../../../../uiKit";
import { css } from "@emotion/css";
import { getPossibleQueryMatch, MentionTypeaheadOption } from "./utils/helpers";

export default function NewMentionsPlugin(): JSX.Element | null {
  const [editor] = useLexicalComposerContext();

  const [queryString, setQueryString] = useState<string | null>(null);

  const users = usePagingWithController(api.userShort, { name: queryString }, { pageSize: 10 });

  const searchDebounce = useDebounce(queryString != "" && queryString != null ? queryString : null, 400);

  const options = useMemo(() => users.items.map((user) => new MentionTypeaheadOption(user.name ?? "", user)), [users]);

  const onSelectOption = useCallback(
    (selectedOption: MentionTypeaheadOption, nodeToReplace: TextNode | null, closeMenu: () => void) => {
      editor.update(() => {
        const mentionNode = $createMentionNode(selectedOption.id, "");
        if (nodeToReplace) {
          nodeToReplace.replace(mentionNode);
        }
        closeMenu();
      });
    },
    [editor]
  );

  const checkForMentionMatch = useCallback((text: string) => {
    const mentionMatch = getPossibleQueryMatch(text);
    return mentionMatch ? mentionMatch : null;
  }, []);

  useEffect(() => {
    users.restart();
  }, [searchDebounce]);

  useEffect(() => {
    users.restart(true);
  }, []);

  return (
    <LexicalTypeaheadMenuPlugin<MentionTypeaheadOption>
      onQueryChange={setQueryString}
      onSelectOption={onSelectOption}
      triggerFn={checkForMentionMatch}
      options={options}
      menuRenderFn={(anchorElementRef, { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex }) => {
        return anchorElementRef.current
          ? ReactDOM.createPortal(
              <div className="typeahead-popover mentions-menu">
                <Popover
                  overlayInnerStyle={{
                    maxHeight: 300,
                    overflow: "auto",
                    padding: "4px 4px 4px 8px",
                    width: "max-content",
                  }}
                  placement={"bottomLeft"}
                  open={!!anchorElementRef.current}
                  content={
                    <>
                      {options.map((option, i: number) => (
                        <div
                          key={option.id}
                          tabIndex={-1}
                          ref={option.setRefElement}
                          className={css`
                            align-items: center;
                            display: flex;
                            cursor: pointer;
                            background: ${selectedIndex === i ? "var(--color-layout-fill-base)" : ""};
                            height: 36px;
                            border-radius: var(--border-radius-sm);
                            padding: 4px;
                          `}
                          onClick={() => {
                            setHighlightedIndex(i);
                            selectOptionAndCleanUp(option);
                          }}
                          onMouseEnter={() => {
                            setHighlightedIndex(i);
                          }}
                        >
                          <UserInfo user={option.user} isTooltipDisabled />
                        </div>
                      ))}
                      <ScrollTrigger
                        onIntersection={users.loadNext}
                        hidden={users.info.isDone}
                        disabled={users.info.isDone}
                        marginTop={options?.length > 0}
                      />
                    </>
                  }
                />
              </div>,
              anchorElementRef.current
            )
          : null;
      }}
    />
  );
}
