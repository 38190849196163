import React from "react";
import "./EmptyBlock.scss";
import { Empty } from "antd";
import { useTranslation } from "react-i18next";
import { IEmptyBlock } from "./EmptyBlock.interface";

export const EmptyBlock = (props: IEmptyBlock) => {
  const { t } = useTranslation();

  return (
    <div style={props.style} className="empty-block">
      <Empty
        image={props.image}
        imageStyle={props.imageStyle}
        description={
          <div className="ant-empty-description" style={{ color: "var(--color-gray-base)" }}>
            {props.description ?? t("text:no_data")}
          </div>
        }
      />
    </div>
  );
};
