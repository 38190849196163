import React from "react";
import "./Breadcrumbs.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { breadcrumbsKeys, IBreadcrumbs } from "./Breadcrumbs.interface";
import { BreadcrumbsView } from "./BreadcrumbsView";
import { observer } from "mobx-react-lite";
import { useRootStore } from "../../../hooks";
import { useTranslation } from "react-i18next";
import {
  ITranslateToken,
  planningTranslateTokens,
  projectTranslateTokens,
  regulationTranslateTokens,
} from "../../../utils/breadcrumbs";

const BreadcrumbsObserver = (props: IBreadcrumbs) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { breadcrumbsStore } = useRootStore();
  const { pathname } = useLocation();

  const handleRedirect = (url: string) => navigate(url);

  const handleGetTranslateTokens = (): ITranslateToken[] => {
    switch (props.type) {
      case breadcrumbsKeys.regulation:
        return regulationTranslateTokens;
      case breadcrumbsKeys.planning:
        return planningTranslateTokens;
      case breadcrumbsKeys.project:
        return projectTranslateTokens;
    }
  };

  const handleGetStepName = (item: string) => {
    const stepTranslate = handleGetTranslateTokens().find(
      (translateItem) => translateItem.nameKey === item
    )?.translateKey;
    console.log(stepTranslate);
    return stepTranslate ? t(stepTranslate) : Number(item) ? breadcrumbsStore.getLastCrumb ?? item : item;
  };

  const getRoutePath = (): string[] => pathname.split("/").filter((item) => item.length > 0);

  return (
    <BreadcrumbsView
      onRedirect={handleRedirect}
      onGetStepName={handleGetStepName}
      pathNames={getRoutePath()}
      lastCrumb={breadcrumbsStore.getLastCrumb}
    />
  );
};

export const Breadcrumbs = observer(BreadcrumbsObserver);
