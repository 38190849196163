import React from "react";
import { Spin, SpinProps } from "antd";
import "./Spin.scss";
import clsx from "clsx";

interface ISpinView extends SpinProps {
  //
}

export function SpinView({ className, ...rest }: ISpinView) {
  return <Spin className={clsx("uiKit-spin", className ?? "")} {...rest} />;
}
