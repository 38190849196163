import React, { useContext } from "react";
import { IListIssuesView } from "./ListIssues.interface";
import { Droppable } from "react-beautiful-dnd";
import clsx from "clsx";
import { CounterChip } from "../../../../../elements/counterChip/CounterChip";
import { ColorModeKeys } from "../../../../../elements/counterChip/misc/keys";
import { CollapseContext } from "../../../../../../contexts/communication/collapseContext";
import { DragPreloader } from "./components/dragPreloader/DragPreloader";
import { NoDataBlock } from "./components/noDataBlock/NoDataBlock";
import { Text } from "../../../../../uiKit";
import { CardIssue } from "../cardIssue/CardIssue";

export const ListIssuesView = (props: IListIssuesView) => {
  const { fromStatusId, possibilityStatuses } = useContext(CollapseContext);
  const isEmpty = !props.isLoading && !props.issues.length;
  const isDisabledMove = possibilityStatuses
    ? fromStatusId === props.boardStatus.id || ![...possibilityStatuses]?.includes(props.boardStatus.id ?? 0)
    : true;

  const columnStyles = clsx("todo-list", "px-2");

  return (
    <div className={columnStyles}>
      <div className="d-stack-column justify-center align-center" style={{ padding: "0px 16px" }}>
        <div className="d-flex d-stack-row align-center mb-2 mt-2" style={{ width: "100%" }}>
          <Text style={{ fontWeight: 800 }}>{props.boardStatus.name}</Text>
          {!!props.issues.length && props.boardStatus.order !== 5 && (
            <CounterChip className="ml-2" colorMode={ColorModeKeys.primary} count={props.totalItems} />
          )}
        </div>
      </div>
      <Droppable
        key={props.boardStatus.id}
        isDropDisabled={possibilityStatuses && isDisabledMove}
        droppableId={props.boardStatus.id!.toString()}
      >
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {isEmpty && <NoDataBlock />}
            {!!fromStatusId && fromStatusId !== props.boardStatus.id && (
              <DragPreloader
                isLoading={!!possibilityStatuses}
                isCanBeMove={!isDisabledMove}
                boardStatus={props.boardStatus?.name}
              />
            )}
            {props.issues?.map((issue, index) => (
              <CardIssue index={index} key={issue.id} issue={issue} />
            ))}
          </div>
        )}
      </Droppable>
    </div>
  );
};
