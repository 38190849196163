import { NodeType } from "../../misc/consts";
import { findUrlsInText } from "../../utils/findUrlsInText";

export const linkDecorator = ([node, path]: any) => {
  const nodeText = node.text;

  if (!nodeText) return [];

  const urls = findUrlsInText(nodeText);
  return urls.map(([url, index]) => {
    return {
      anchor: {
        path,
        offset: index,
      },
      focus: {
        path,
        //@ts-ignore
        offset: index + url.length,
      },
      decoration: NodeType.Link,
    };
  });
};
