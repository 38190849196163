import {
  BalloonToolbar,
  getPluginType,
  MARK_BOLD,
  MARK_ITALIC,
  MARK_UNDERLINE,
  MarkToolbarButton,
  usePlateEditorRef,
} from "@udecode/plate";
import { FiBold } from "@react-icons/all-files/fi/FiBold";
import { FiItalic } from "@react-icons/all-files/fi/FiItalic";
import { FiUnderline } from "@react-icons/all-files/fi/FiUnderline";
import React from "react";

interface IMarkBalloonToolbar {
  // selectedText: string;
}

export const MarkBalloonToolbar = (props: IMarkBalloonToolbar) => {
  const editor = usePlateEditorRef();
  // const { appStore } = useRootStore();
  //
  const arrow = false;
  const theme = "light";
  // const theme: EmojiPickerThemeType = useMemo(() => {
  //   switch (appStore.getAppTheme) {
  //     case 0:
  //       return "light";
  //     case 1:
  //       return "dark";
  //     default:
  //       return "light";
  //   }
  // }, [appStore.getAppTheme]);

  return (
    <BalloonToolbar
      popperOptions={{
        placement: "top",
      }}
      theme={theme}
      arrow={arrow}
    >
      <MarkToolbarButton
        type={editor! && getPluginType(editor, MARK_BOLD)}
        icon={<FiBold stroke="var(--color-text-weak)" />}
      />
      <MarkToolbarButton
        type={editor! && getPluginType(editor, MARK_ITALIC)}
        icon={<FiItalic stroke="var(--color-text-weak)" />}
      />
      <MarkToolbarButton
        type={editor! && getPluginType(editor, MARK_UNDERLINE)}
        icon={<FiUnderline stroke="var(--color-text-weak)" />}
      />
      {/*{props.selectedText != "" && <LinkMarkBalloonToolbarButton selectedText={props.selectedText} />}*/}
    </BalloonToolbar>
  );
};
