import { insertNodes, InsertNodesOptions, PlateEditor, Value } from "@udecode/plate-core";
import { TIFrameElement } from "./IFrameElement";
import { createIFrameNode, CreateIFrameNodeOptions } from "./createIFrameNode";

export const insertIFrame = <V extends Value>(
  editor: PlateEditor<V>,
  createIFrameNodeOptions: CreateIFrameNodeOptions,
  options?: InsertNodesOptions<V>
) => {
  insertNodes<TIFrameElement>(editor, [createIFrameNode(editor, createIFrameNodeOptions)], options as any);
};

//связать этот компонент с insertIFrame по примеру якоря
