import "./ColorPicker.scss";

import * as React from "react";
import { memo, useCallback, useMemo, useState } from "react";
import { HEIGHT, WIDTH } from "./misc/consts";
import { Color, Position, transformColor } from "./utils/colorFunctions";
import { ColorPickerView } from "./ColorPickerView";
import { IColorPicker } from "./ColorPicker.interface";

export const ColorPicker = memo(({ color, onChange, disabled = false, icon }: IColorPicker): JSX.Element => {
  const [selfColor, setSelfColor] = useState(transformColor("hex", color));

  const saturationPosition = useMemo(
    () => ({
      x: (selfColor.hsv.s / 100) * WIDTH,
      y: ((100 - selfColor.hsv.v) / 100) * HEIGHT,
    }),
    [selfColor.hsv.s, selfColor.hsv.v]
  );

  const huePosition = useMemo(
    () => ({
      x: (selfColor.hsv.h / 360) * WIDTH,
    }),
    [selfColor.hsv]
  );

  const onSetHex = (hex: string) => {
    // setInputColor(hex);
    if (/^#[0-9A-Fa-f]{6}$/i.test(hex)) {
      const newColor = transformColor("hex", hex);
      setSelfColor(newColor);
    }
  };

  const onMoveSaturation = useCallback(
    ({ x, y }: Position) => {
      const newHsv = {
        ...selfColor.hsv,
        s: (x / WIDTH) * 100,
        v: 100 - (y / HEIGHT) * 100,
      };
      const newColor = transformColor("hsv", newHsv);
      setSelfColor(newColor);
      onChange?.(newColor.hex);
      // setInputColor(newColor.hex);
    },
    [onChange, selfColor.hsv]
  );

  const onMoveHue = useCallback(
    ({ x }: Position) => {
      const newHsv = { ...selfColor.hsv, h: (x / WIDTH) * 360 };
      const newColor = transformColor("hsv", newHsv);

      setSelfColor(newColor);
      onChange?.(newColor.hex);
      // setInputColor(newColor.hex);
    },
    [onChange, selfColor.hsv]
  );

  const handleChangeSelfColor = useCallback(
    (color: Color) => {
      setSelfColor(color);
      onChange?.(color.hex);
    },
    [onChange]
  );

  // useEffect(() => {
  //   // Check if the dropdown is actually active
  //   onChange?.(selfColor.hex);
  //   // setInputColor(selfColor.hex);
  // }, [selfColor, onChange]);

  // useEffect(() => {
  //   if (color === undefined) return;
  //   const newColor = transformColor("hex", color);
  //   setSelfColor(newColor);
  //   setInputColor(newColor.hex);
  // }, [color]);
  // console.log(color);
  return (
    <ColorPickerView
      color={color}
      selfColor={selfColor}
      setSelfColor={handleChangeSelfColor}
      huePosition={huePosition}
      onMoveHue={onMoveHue}
      onMoveSaturation={onMoveSaturation}
      saturationPosition={saturationPosition}
      icon={icon}
      disabled={disabled}
      onChange={onChange}
    />
  );
});
