import React from "react";
import { observer } from "mobx-react-lite";
import { LocalStorageHelpers, useRootStore } from "../../../../hooks";
import CompanySettingsOnFirstLoginDialogView from "./CompanySettingsOnFirstLoginDialogView";
import { toJS } from "mobx";
import { User2CompanyDto } from "../../../../api";

function CompanySettingsOnFirstLoginDialog() {
  const { authStore, appStore } = useRootStore();
  const lsKey = toJS(appStore.getLsKeys.isTutorialWatched) ?? "isTutorialWatched";

  const handleSave = () => {
    LocalStorageHelpers.set(lsKey, Boolean(false));
    window.location.reload();
  };

  return authStore.getInitialInfo?.identity?.name?.trim().length != 0 &&
    authStore.getInitialInfo?.identity?.companies
      ?.find((c: User2CompanyDto) => c.companyId == authStore.getInitialInfo?.identity?.currentCompanyId)
      ?.company?.name?.trim()?.length == 0 ? (
    <CompanySettingsOnFirstLoginDialogView handleSave={handleSave} />
  ) : null;
}

export default observer(CompanySettingsOnFirstLoginDialog);
