import React from "react";
import { SelectOptionView } from "../selectOption/SelectOptionView";

interface ISelectOption {
  className?: string;
  disabled?: boolean;
  title?: string;
  value?: string | number;
  children: React.ReactNode;
}

export function SelectOption(props: ISelectOption) {
  return <SelectOptionView {...props} />;
}

SelectOption.displayName = "Select.Option";
