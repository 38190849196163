import { ApiControllerGet } from "../helpers";
import { AxiosInstance } from "axios";
import { NotificationDto } from "../models/NotificationDto";
import { NotificationFilter } from "../filters/notificationFilter";

export class NotificationController extends ApiControllerGet<NotificationDto, NotificationFilter> {
  constructor(cl: AxiosInstance, v: string = "v1") {
    super(cl, v, "notification");
  }

  public async markAsRead(id: number): Promise<boolean | null> {
    return await this.process(
      this.get(id + "/proceed"),
      (x) => true,
      (x) => false
    );
  }

  public async markAsUnread(id: number): Promise<boolean | null> {
    return await this.process(
      this.get(id + "/proceed?isRead=false"),
      (x) => true,
      (x) => false
    );
  }

  public async markAllAsRead(): Promise<boolean | null> {
    return await this.process(
      this.get("proceed"),
      (x) => true,
      (x) => false
    );
  }
}
