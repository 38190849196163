import React from "react";
import { IconButton, Tooltip } from "../../../../../uiKit";
import { FiSave } from "@react-icons/all-files/fi/FiSave";
import { IRegulationIcons } from "./RegulationIcons.interface";
import { useTranslation } from "react-i18next";

export const SaveButton: React.FC<IRegulationIcons>  = (props) => {

  const { t } = useTranslation();

  return (
    <Tooltip title={t("ui:tooltip.save")}>
      <IconButton loading={props.loading} className="regulation-button-icons__save" disabled={props.disabled} onClick={props.onClick}>
        <FiSave size={22} />
      </IconButton>
    </Tooltip>
  );
};

