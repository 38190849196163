import React, { useEffect, useState } from "react";
import "./PlanningTaskCards.scss";
import { IPlanningTaskCards } from "./PlanningTaskCards.interface";
import { IssueDto } from "../../../../../../api";
import { PlanningTaskCardsView } from "./PlanningTaskCardsView";
import { IdGenerator } from "../../../../../../helpers/idGenerator";
import { groupingByRoleId } from "../../helpers/groupingArrayByProperty";

export const PlanningTaskCards = (props: IPlanningTaskCards) => {
  const [groupedArr, setGroupedArr] = useState<IssueDto[][]>([]);
  const groupIdGenerator = new IdGenerator(1);

  const handleIncrementGroupId = () => groupIdGenerator.incrementId();

  useEffect(() => {
    setGroupedArr(groupingByRoleId(props.issues));
  }, [props.issues]);

  return (
    <PlanningTaskCardsView
      groupedArr={groupedArr}
      issues={props.issues}
      onAddIssue={props.onAddIssue}
      onIncrementGroupId={handleIncrementGroupId}
      loadingTaskId={props.loadingTaskId}
      plan={props.plan}
      issuesFromCommunicationsRestart={props.issuesFromCommunicationsRestart}
    />
  );
};
