import { HiOutlinePlus } from "@react-icons/all-files/hi/HiOutlinePlus";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IssueQueryKeys, IssueTypeKeys } from "../../../../../../../constants/issueTypeKeys";
import { getObjectFromTimeSpan } from "../../../../../../../helpers/dateFunctions";
import { removeLeadingZerosFromString } from "../../../../../../../helpers/stringFunctions";
import { useDateHelpers, useRootStore } from "../../../../../../../hooks";
import { Card, Divider, IconButton, Text } from "../../../../../../uiKit";
import { blockedFieldsKeys } from "../../../../../forms/types/consts";
import { IPlanningTaskCard } from "./PlanningTaskCard.interface";
import "./PlanningTaskCard.scss";

export const PlanningTaskCard = (props: IPlanningTaskCard) => {
  const { issueInitDataStore } = useRootStore();
  const { formatDateString } = useDateHelpers();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleOpenIssue = () => {
    navigate({
      search: `?${IssueQueryKeys.issueId}=${props?.issue?.id}`,
    });
    issueInitDataStore.setOnChangeDefiniteField = props.issuesFromCommunicationsRestart;
    issueInitDataStore.setBlockedFields = [blockedFieldsKeys.executorUserId];
  };

  return (
    <>
      <Card bodyStyle={{ padding: 0 }} className="planning-task-card__card mb-3">
        <div className="d-stack-row justify-space-between">
          <div className="d-stack-column ma-3">
            <Text onClick={handleOpenIssue} weight="bold" className="planning-task-card__title">
              {props.issue.name}
            </Text>
            <Text className="planning-task-card__description">{props.issue.awaitedResult}</Text>
          </div>
          <div className="mr-1 mt-2">
            <IconButton
              disabled={props.plan?.status == 2 || !props.plan}
              loading={props.loadingTaskId == props.issue.id}
              onClick={() =>
                props.onAddIssue({
                  issueId: props.issue.id,
                })
              }
              icon={<HiOutlinePlus size={20} />}
            />
          </div>
        </div>
        {props.issue.dateDeadline || props.issue.timePlan ? (
          <>
            <Divider style={{ opacity: "0.5" }} />
            <div className="d-stack-row justify-space-between align-center mx-3 my-2">
              <Text className="planning-task-card__createdAt">
                {props.issue.dateDeadline && formatDateString(props.issue.dateDeadline, { month: "short" })}
              </Text>
              <Text className="planning-task-card__hours">
                {props.issue.timePlan
                  ? `${removeLeadingZerosFromString(getObjectFromTimeSpan(props.issue.timePlan).hours!)}${t(
                      "common:planning.hour"
                    )}`
                  : null}
              </Text>
            </div>
          </>
        ) : null}
      </Card>
    </>
  );
};
