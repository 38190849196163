import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./SandboxPage.scss";
import { useNavigate } from "react-router-dom";
import { useAudioRecorder, useHash, useIsMobile, useNotifier, useTheme, useWindowSize } from "../../../hooks";
import { DriverRTCCallsContext } from "../../../contexts/driverRTCCallsContext";
// @ts-ignore
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import PageHeader from "../../modules/layouts/pageHeader/PageHeader";
import { theme } from "antd";
import { ThemeSwitcher } from "../../modules/pages/settings/themeSwitcher/ThemeSwitcher";
import PageContent from "../../modules/layouts/pageContent/PageContent";
import {
  AudioPlayer,
  AudioRecorder,
  Button,
  Card,
  DatePicker,
  DatePickerRange,
  Divider,
  InputLinkified,
  Shape,
  Text,
} from "../../uiKit";
import SubheaderText from "../../elements/subheaderText/SubheaderText";
import { addAlphaToHex, invertColor } from "../../../helpers/colorFunctions";
import { InputPhoneNumber } from "../../uiKit/inputPhoneNumberNew/InputPhoneNumber";
import { FaintColorScheme } from "../../../utils/colorScheme";
import LanguageSwitcher from "../../modules/pages/settings/languageSwitcher/LanguageSwitcher";
import { Dayjs } from "dayjs";

const MicVisualizer = ({ fps }: { fps: number }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const audioContextRef = useRef<AudioContext | null>(null);
  const analyzerRef = useRef<AnalyserNode | null>(null);
  const requestRef = useRef<number | null>(null);
  const width = 300;
  const height = 100;

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas == null) return;
    canvas.width = width;
    canvas.height = height;
    // @ts-ignore
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const analyzer = audioContext.createAnalyser();
    analyzer.fftSize = 256;
    audioContextRef.current = audioContext;
    analyzerRef.current = analyzer;

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        analyzerRef.current?.disconnect();
        const source = audioContext.createMediaStreamSource(stream);
        source.connect(analyzer);
        requestRef.current = requestAnimationFrame(renderFrame);
      })
      .catch((error) => console.error(error));

    return () => {
      cancelAnimationFrame(requestRef.current!);
      audioContextRef.current?.close();
    };
  }, []);

  const renderFrame = () => {
    const canvas = canvasRef.current;
    const audioContext = audioContextRef.current;
    const analyzer = analyzerRef.current;
    const ctx = canvas?.getContext("2d");
    if (!canvas || !audioContext || !analyzer || !ctx) return;
    const bufferLength = analyzer.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);
    analyzer.getByteFrequencyData(dataArray);
    ctx.clearRect(0, 0, width, height);
    ctx.fillStyle = "rgb(0, 128, 255)";
    const barWidth = 4;
    let x = 0;
    for (let i = 0; i < bufferLength; i++) {
      const barHeight = (dataArray[i] / 255) * height;
      ctx.beginPath();
      ctx.arc(x + barWidth / 2, height - barHeight / 2, barWidth / 2, 0, 2 * Math.PI);
      ctx.fill();
      x += barWidth + 1;
    }
    requestRef.current = requestAnimationFrame(renderFrame);
  };

  return <canvas ref={canvasRef} />;
};

function SandboxPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const notifier = useNotifier();
  const driverRTCCallsContext = useContext(DriverRTCCallsContext);

  // window.onerror = function (error) {
  //   // do something clever here
  //   alert(error); // do NOT do this for real!
  // };
  // console.log(getObjectFromTimeSpan("10.12:12:12"));

  // Gradient Data
  let gD: any = [];
  let cD: any = [];

  // const generateGradientData = () => {
  //   const COLORS = {
  //     up: "green",
  //     down: "red",
  //     neutral: "blue",
  //   };
  //   const p = 100 / (cD.length - 1);
  //   let resArray: string[] = [];
  //
  //   cD.forEach((d, i) => {
  //     let c: any = null;
  //     if (i + 1 == cD.length) return;
  //     if (cD[i + 1].line == d.line) c = COLORS.neutral;
  //     // @ts-ignore
  //     if (cD[i + 1].line > d?.line) c = COLORS.up;
  //     // @ts-ignore
  //     if (cD[i + 1].line < d?.line) c = COLORS.down;
  //     if (cD[i] == null || cD[i + 1] == null) c = COLORS.neutral;
  //     resArray = [...resArray, c].filter((v) => v != null);
  //   });
  //
  //   return (
  //     <>
  //       {resArray.map((r, i) => {
  //         return (
  //           <>
  //             <stop offset={`${p * i}%`} stopColor={resArray[i]} />
  //             <stop offset={`${p * (i + 1)}%`} stopColor={resArray[i]} />
  //           </>
  //         );
  //       })}
  //     </>
  //   );
  // };

  const windowSize = useWindowSize();

  const colors: string[] = [
    "#DCE2F0",
    "#ffc6c6",
    "#ffe2c6",
    "#fff7c6",
    "#ceffc6",
    "#d0f4f1",
    "#c6d7ff",
    "#dec6ff",
    "#ffc6df",
    "#ffffff",
    "#000000",
    "#f1f1f1",
    "#1f1f1f",
    "#c6ffe3",
    "#e2ffc6",
    "#b3d8ff",
    "#468499",
    "#f1f100",
    "#1f1f00",
    "#0f10f1",
    "#1f0e2a",
    "#ff11ff",
  ];

  const wordArray = [
    "T",
    "O",
    "N",
    "N",
    "U",
    "S",
    "T",
    "O",
    "N",
    "N",
    "U",
    "S",
    "T",
    "O",
    "N",
    "N",
    "U",
    "S",
    "T",
    "O",
    "N",
    "N",
    "U",
    "S",
  ];

  const { useToken } = theme;
  // const { token } = useTheme();
  const { height, width } = useWindowSize();
  const { token } = useToken();
  const isMobile = useIsMobile();
  const { color } = useTheme();
  const { hashFn } = useHash();
  const { startRecording, stopRecording, pauseResumeRecording, recordingBlob, recordingTime, isPaused } =
    useAudioRecorder();
  const [v, setV] = useState<Dayjs | null>(null);
  // For automatic deploy test v3
  // const { RangePicker } = DatePicker;
  const [isRecording, setIsRecording] = useState(false);

  const [val, setVal] = useState<string>("");
  return (
    <>
      <PageHeader title={t("common:page_title.sandbox") + " / YS"}>
        <div className="flex-grow-1" />
        <ThemeSwitcher displayMode="button" />
      </PageHeader>
      <PageContent>
        <Card style={{ maxWidth: "300px" }} className="mb-4" title="TextArea test">
          {/*<TextArea placeholder="Some long" autoSize={true} />*/}
        </Card>
        <Card style={{ maxWidth: "600px" }} className="mb-4" title="Date Picker test">
          <div className="d-stack-column spacing-3">
            {/*@ts-ignore*/}
            <DatePicker
              value={v}
              onChange={(v, str) => {
                {
                  setV(v);
                  console.log(str);
                }
              }}
            />
            <DatePicker value={v} onChange={setV} showTime={{ format: "HH:mm" }} />
            {v?.toISOString() ?? "no v"}
          </div>
        </Card>
        <Card style={{ maxWidth: "600px" }} className="mb-4" title="Audio Recorder test">
          <div className="d-stack-column spacing-2">
            <div className="d-stack spacing-2">
              <Button onClick={startRecording}>Start</Button>
              <Button onClick={pauseResumeRecording}>{isPaused ? "Resume" : "Pause"}</Button>
              <Button onClick={stopRecording}>Stop</Button>
            </div>
            <div className="d-stack justify-space-between">
              <span children={recordingBlob?.size ?? "no blob size"} />
              <span children={recordingTime} />
            </div>
          </div>
        </Card>
        <Card style={{ maxWidth: "600px" }} className="mb-4" title="Audio Recorder test">
          <AudioRecorder />
        </Card>
        <Card style={{ maxWidth: "600px" }} className="mb-4" title="Audio Player test">
          {/*<AudioPlayer type="voice-message" src="https://dl.espressif.com/dl/audio/ff-16b-2c-44100hz.mp4" />*/}
          <AudioPlayer type="voice-message" src="https://rcapi.tonnus.io/static/1/ChrisWebby-TwistAgain(LaLaLa).mp3" />
          {/*<AudioPlayer type="voice-message" src="https://api.beta.tonnus.io/static/1/file_example_MP3_700KB.mp3" />*/}
        </Card>

        <Card className="mb-4" title="Input phone test">
          <InputPhoneNumber />
        </Card>
        {/*<Card className="mb-4" title="Audio visualizer test">*/}
        {/*<MicVisualizer fps={1} />*/}
        {/*</Card>*/}
        <Card className="mb-4" title="Plurals test">
          <div className="d-stack-column">
            <Text children={t("parse:plurals_test", { count: Number(v) })} />
            <div />
            <Text children={t("parse:plurals_test_two.", { count: Number(v) })} />
            <Divider className="my-3" />
            <div className="d-stack-column spacing-2">
              <LanguageSwitcher />
              <div>
                <SubheaderText text={"Количество"} />
                {/*<InputNumber className="full-width" value={v} onChange={setV} />*/}
              </div>
            </div>
            <Divider className="my-3" />
          </div>
        </Card>
        <Card className="mb-4">{/*<RangePicker />*/}</Card>
        <Card className="mb-4">
          <DatePickerRange />
        </Card>
        <Card className="mb-4">
          <div
            style={{
              backgroundColor: FaintColorScheme.find((c) => c.key == "faint")?.backgroundColor,
              color: FaintColorScheme.find((c) => c.key == "faint")?.color,
            }}
          >
            TEST
          </div>
        </Card>
        <Card className="mb-4">
          <InputLinkified />
        </Card>
        <Card className="mb-4">
          <div className="d-stack stack-divided spacing-2">
            <Shape size={48} backgroundColor={"var(--color-primary-base)"} />
            <Shape size={48} backgroundColor={"var(--color-primary-base)"} />
            <Shape size={48} backgroundColor={"var(--color-primary-base)"} />
          </div>
          <div className="mt-4 d-stack-column stack-divided spacing-1">
            <Shape size={48} backgroundColor={"var(--color-primary-base)"} />
            <Shape size={48} backgroundColor={"var(--color-primary-base)"} />
            <Shape size={48} backgroundColor={"var(--color-primary-base)"} />
          </div>
        </Card>
        <Card className="mb-4">
          <Shape
            size={32}
            backgroundColor={"var(--color-primary-weaker)"}
            style={{ color: invertColor("var(--color-primary-weaker)") }}
            children={invertColor("var(--color-primary-weaker)")}
          />
        </Card>
        <Card title="Theme Colors">
          <div className="d-stack-column spacing-3">
            {Object.keys(color).map((c) => {
              return (
                <div className="d-stack-column">
                  <SubheaderText text={c.charAt(0).toUpperCase() + c.slice(1)} />
                  <div className="d-stack spacing-2">
                    {/*@ts-ignore*/}
                    {Object.keys(color[c]).map((k) => {
                      {
                        // @ts-ignore
                        return typeof color[c][k] === "string" ? (
                          <Shape
                            size={60}
                            // @ts-ignore
                            backgroundColor={color[c][k]}
                            children={k}
                            className="d-flex align-center justify-center br-md"
                            style={{
                              // @ts-ignore
                              color: addAlphaToHex(invertColor(color[c][k]), 0.6),
                              wordBreak: "break-word",
                              textAlign: "center",
                              lineHeight: "1.1em",
                              boxShadow: "var(--shadow-down-md)",
                            }}
                          />
                        ) : (
                          <div className="d-stack-column">
                            <SubheaderText
                              text={
                                c.charAt(0).toUpperCase() + c.slice(1) + " -> " + k.charAt(0).toUpperCase() + k.slice(1)
                              }
                            />
                            <div className="d-stack spacing-2">
                              {/*@ts-ignore*/}
                              {Object.keys(color[c][k]).map((y) => (
                                <Shape
                                  size={60}
                                  // @ts-ignore
                                  backgroundColor={color[c][k][y]}
                                  children={y}
                                  className="d-flex align-center justify-center br-md"
                                  style={{
                                    // @ts-ignore
                                    color: addAlphaToHex(invertColor(color[c][k][y]), 0.6),
                                    wordBreak: "break-word",
                                    textAlign: "center",
                                    lineHeight: "1.1em",
                                    boxShadow: "var(--shadow-down-md)",
                                  }}
                                />
                              ))}
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </Card>
        {/*<DatePickerRange />*/}
      </PageContent>
    </>
  );
}

export default SandboxPage;
