import React from "react";
import smileMind from "../../../../assets/images/smiles/mind.png";
import { useTranslation } from "react-i18next";
import { Text } from "../../../uiKit";

export const FailedCompletion = () => {
  const { t } = useTranslation();

  return (
    <div className="">
      <div className="d-stack justify-space-around align-center spacing-2 passing-regulation-test__result-block">
        <div className="passing-regulation-test__result-block-paper">
          <img src={smileMind} alt="smile mind" />
        </div>
        <Text className="passing-regulation-test__result-block-title">{t("parse:failed_completion_test")}</Text>
      </div>
    </div>
  );
};
