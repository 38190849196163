import React from "react";
import "./CustomChip.scss";
import { hexToRgb, invertColor } from "../../../helpers/colorFunctions";
import { useRootStore } from "../../../hooks";

export interface IChip {
  color?: string | null;
  label?: string | number | null;
  className?: string;
  isActive?: boolean;
  onClick?: () => void;
}

export const CustomChip = (props: IChip) => {
  const { appStore } = useRootStore();
  // const style: React.CSSProperties = {
  //   background: props.filled ? "var(--color-primary-base)" : props.transparent ? "initial" : "",
  //   maxWidth: "100%",
  //   // border: `1px solid ${props.filled ? "var(--color-primary-base)" : props.borderColor ?? "black"}`,
  //   // color: props.filled ? "var(--color-text-contrast)" : "unset",
  //   cursor: props.onClick ? "pointer" : "default",
  // };

  const getChipColor = (hex: string | null | undefined, type: "background" | "border", isActive: boolean): string => {
    if (hex == null || hex.trim().length == 0) {
      return "var(--color-layout-fill-base)";
    }
    if (appStore.getAppTheme == 1) {
      const rgb = hexToRgb(hex);
      if (rgb == null) return hex;
      return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${type == "border" ? (isActive ? 1 : 0.5) : isActive ? 0.8 : 0.05})`;
    } else {
      const rgb = hexToRgb(hex);
      if (rgb == null) return hex;
      return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${type == "border" ? (isActive ? 1 : 0.8) : isActive ? 1 : 0.25})`;
    }
  };

  return (
    <div
      title={props.label != null ? String(props.label) : undefined}
      onClick={props.onClick}
      className={"custom-chip br-md py-1 px-3 " + props.className}
      style={{
        border: `1px solid ${getChipColor(props.color, "border", props.isActive ?? false)}`,
        backgroundColor: getChipColor(props.color, "background", props.isActive ?? false),
        opacity: props.isActive ? 1 : undefined,
        color: props.isActive ? invertColor(props.color as string) : undefined,
        cursor: "pointer",
      }}
    >
      <span style={{ maxWidth: "100%", fontWeight: "500" }}>{props.label}</span>
    </div>
  );
};
