import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions } from "../../../uiKit";
import { IMetricToDashboardDialogView } from "./MetricToDashboardDialog.interface";
import { Form } from "antd";
import SubheaderText from "../../../elements/subheaderText/SubheaderText";
import { AutocompleteAsync } from "../../../elements/autocompleteAsync/AutocompleteAsync";

export const MetricToDashboardDialogView = (props: IMetricToDashboardDialogView) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const handleSubmit = () => {
    form.submit();
    props.onClose();
  };

  const handleFinish = () => {
    props.addMetricsToDashboard();
  };

  return (
    <Dialog destroyOnClose title={t("ui:title.add_metric")} open={props.open} onClose={props.onClose}>
      <Form form={form} onFinish={handleFinish} autoComplete="off">
        <SubheaderText text={t("ui:subheader.select_metrics")} />
        <Form.Item>
          <AutocompleteAsync
            type="metric"
            idsToFilter={
              props.dashboard?.metrics?.map((m) => (m.metricSource2UserId == null ? m.metricId : undefined)) as number[]
            }
            requestOptions={{ notInDashboardId: props.dashboardId ?? undefined }}
            onChange={(id: number, m: any) => {
              props.onSelectedMetricChange({ id: m.id, text: m.text });
            }}
          />
        </Form.Item>
        <SubheaderText text={t("ui:subheader.metric_employee")} />
        <Form.Item>
          <AutocompleteAsync
            disabled={(props.metric?.isOneCanvas || !props.selectedMetric?.id) ?? false}
            type="metricSource2User"
            defaultValue={0}
            idsToFilter={props.dashboard?.metrics?.map((m) => m.metricSource2UserId) as number[]}
            additionalItems={[{ id: 0, text: t("ui:select.all_employees") }]}
            requestOptions={{ metricId: props.selectedMetric?.id, notInDashboardId: props.dashboardId ?? undefined }}
            onChange={(id: number, s2u: any) =>
              props.onSelectedMetricSource2UserChange(
                id == 0 ? { id: null, text: null } : { id: s2u.id, text: s2u.text }
              )
            }
          />
        </Form.Item>
      </Form>
      <DialogActions>
        <Button onClick={props.onClose} variant="text">
          {t("ui:button.cancel")}
        </Button>
        <Button disabled={!props.selectedMetric?.id} onClick={handleSubmit} variant="filled">
          {t("ui:button.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
