import React from "react";
import { TabPaneView } from "./TabPaneView";
import { TabPaneProps } from "antd";

interface ITabPane extends TabPaneProps {
  //
}

export function TabPane(props: ITabPane) {
  return <TabPaneView {...props} />;
}
