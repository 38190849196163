import React from "react";
import { LayoutHeaderView } from "./LayoutHeaderView";

interface ILayoutHeader {
  className?: string;
  children?: React.ReactNode;
}

export function LayoutHeader(props: ILayoutHeader) {
  return <LayoutHeaderView {...props} />;
}
