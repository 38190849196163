import React, { forwardRef, useContext, useMemo, useState } from "react";
import { IIssueFormView } from "../common/IssueForm.interface";
import { useTranslation } from "react-i18next";
import { Form } from "antd";
import SubheaderText from "../../../../../elements/subheaderText/SubheaderText";
import { Button, DatePicker, Icon, TextArea, TimePicker } from "../../../../../uiKit";
import { blockedFieldsKeys } from "../../../types/consts";
import { TextFieldLinkified } from "../../../../../elements/textFieldLinkified/TextFieldLinkified";
import { AiOutlinePaperClip } from "@react-icons/all-files/ai/AiOutlinePaperClip";
import FileUploader from "../../../../../service/fileUploader/FileUploader";
import { AutocompleteAsync } from "../../../../../elements/autocompleteAsync/AutocompleteAsync";
import { useRootStore } from "../../../../../../hooks";
import { IssueProofRequirementDto } from "../../../../../../api";
import IssueProofForm from "../../../issueProofForm/IssueProofForm";
import { IssueContext } from "../../../../../../contexts/communication/issueContext";
import IssueCustomFieldsForm from "../../../issueCustomFieldsForm/IssueCustomFieldsForm";
import { OrgchartSwitcher } from "../../../../../elements/orgchartSwitcher/OrgchartSwitcher";
import { getObjectFromTimeSpan } from "../../../../../../helpers/dateFunctions";
import { WarningsKeys } from "../../utils/keys/warningsKeys";
import dayjs from "dayjs";

export const TaskFormView = forwardRef((props: IIssueFormView, ref) => {
  const { orgchartStore } = useRootStore();
  const [isValidationErrorExecutor, setIsValidationErrorExecutor] = useState(false);
  const { t } = useTranslation();

  const { issueType } = useContext(IssueContext);

  const defaultOrgchartId = useMemo(
    () => props.updater?.currentState?.orgchartId,
    [props.updater?.currentState?.orgchartId]
  );

  const handleFinish = () => !isValidationErrorExecutor && props.onCreate && props.onCreate();

  return (
    <Form
      name="task"
      className="flex-grow-1"
      style={{ overflow: "auto", maxWidth: props.isUpdateForm ? 547 : "100%" }}
      form={props.form}
      onFinish={handleFinish}
      autoComplete="off"
    >
      <SubheaderText text={t("ui:subheader.issue_name")} />
      <Form.Item
        className="mb-3 full-width flex-grow-1"
        name="name"
        initialValue={props.updater.currentState?.name}
        rules={[
          {
            required: true,
            message: t("validation:field_required"),
          },
        ]}
      >
        <TextArea
          autoSize
          className="mb-1 full-width"
          placeholder={t("ui:placeholder.issue_name")}
          value={props.updater.currentState?.name ?? undefined}
          onChange={(event) => {
            !props.isUpdateForm
              ? props.updater.applyChanges({ name: event.target.value })
              : event.target.value.trim()?.length > 0 && props.updater.applyChanges({ name: event.target.value });
          }}
          readOnly={props.onCheckToAvailability(blockedFieldsKeys.name)}
          onBlur={props.onUpdate}
        />
      </Form.Item>
      <SubheaderText text={t("ui:subheader.issue_description")} />
      <TextFieldLinkified
        value={props.updater.currentState?.description ?? ""}
        setValue={(newDescription) => props.updater.applyChanges({ description: newDescription })}
        placeholder={t("ui:placeholder.issue_description")}
        onUpdate={props.onUpdate}
        readonly={props.onCheckToAvailability(blockedFieldsKeys.description)}
      />
      <div className="d-stack-row justify-space-between align-center mb-2">
        <SubheaderText
          noBottomOffset
          text={`${t("ui:subheader.attachments")} ${`(${props.attachments?.length ?? 0})`}`}
        />
        <Button
          /*@ts-ignore*/
          onClick={() => props.uploadFilesInputRef?.current?.click()}
          icon={<Icon component={() => <AiOutlinePaperClip />} />}
          style={{ fontSize: "12px", fontWeight: "14px" }}
          size="small"
          type="text"
          disabled={props.onCheckToAvailability(blockedFieldsKeys.attachments)}
        >
          {t("ui:button.attach")}
        </Button>
      </div>
      <FileUploader
        maxSizeMb={40}
        // accept="image/*"
        // readonly
        noDragAndDrop
        noDelete={props.onCheckToAvailability(blockedFieldsKeys.attachments)}
        relatedInputId="issue-attachments-area-ctrlv-communications"
        value={props.attachments}
        // value={(updater.currentState?.attachments?.map((x) => x.file) as StaticFileDto[]) ?? undefined}
        onUpload={(data) => (props.isFileLoaderUploadingCopy.current ? undefined : props.setAttachmentsIssueSync(data))}
        onValueItemDelete={props.onDeleteAttachmentsByFileId}
        onAnyItemDelete={props.onDeleteAttachmentsByFileId}
        uploadFilesInputRef={props.uploadFilesInputRef}
        filesContainerMaxHeight="35vh"
        onUploadStatusChange={props.setIsFileLoaderUploadingSync}
        hideInfo
      />
      {/*{props.attachments && props.attachments.length === 0 && (*/}
      {/*  <Input autoComplete="off" name="empty" value={t("text:no_attachments")} />*/}
      {/*)}*/}
      <div className="mt-3" />
      {/*<SubheaderText text={helperStore.getCompanyGlossaryItem("r")} />*/}
      <SubheaderText
        text={orgchartStore.getOrgchartResultNameByOrgchartId(props.updater.currentState?.orgchartId ?? 0)}
      />
      <TextArea
        autoSize
        // style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
        className="mb-3"
        readOnly={props.onCheckToAvailability(blockedFieldsKeys.awaitedResult)}
        autoComplete="off"
        placeholder={`${t("ui:placeholder.enter")} ${orgchartStore.getOrgchartResultNameByOrgchartId(
          props.updater.currentState?.orgchartId ?? 0
        )}`}
        value={props.updater?.currentState?.awaitedResult ?? ""}
        onBlur={props.onUpdate}
        onInput={(event: any) => props.updater.applyChanges({ awaitedResult: event.target.value })}
      />
      <IssueProofForm
        readOnly={props.onCheckToAvailability(blockedFieldsKeys.proofRequirement)}
        data={props.updater.currentState?.proofRequirement ?? null}
        onChange={(data: IssueProofRequirementDto) =>
          props.updater.applyChanges({
            proofRequirement: data,
            proofRequirementId: data == null ? null : props.updater.currentState?.proofRequirementId,
          })
        }
        onBlur={props.onUpdate}
      />
      {orgchartStore.getOrgchartsList.length > 1 && (
        <>
          <SubheaderText className="mt-3" text={t("ui:subheader.issue_orgachart_company")} />
          <Form.Item
            className="full-width"
            name="orgchartId"
            initialValue={props.updater?.currentState?.orgchartId}
            rules={[
              {
                required: true,
                message: t("validation:field_required"),
              },
            ]}
          >
            <OrgchartSwitcher
              value={props.updater?.currentState?.orgchartId ?? undefined}
              onChange={(value) => props.updater.applyChanges({ orgchartId: +value, roleId: undefined })}
              selectProps={{
                style: { width: "100%", marginBottom: 4 },
                placeholder: t("ui:placeholder.click_to_select"),
                defaultValue: { defaultOrgchartId },
                onDropdownVisibleChange: (visible) => !visible && props.onAsyncUpdate(),
                disabled: props.onCheckToAvailability(blockedFieldsKeys.orgchart),
              }}
              drawType="select"
            />
          </Form.Item>
        </>
      )}
      <div className="mt-3 d-stack-row justify-space-between align-start">
        <div style={{ width: "48%" }}>
          <SubheaderText text={t("ui:subheader.issue_executor")} />
          <Form.Item
            className="full-width"
            validateStatus={isValidationErrorExecutor ? "error" : undefined}
            help={isValidationErrorExecutor ? t("validation:field_required") : undefined}
            rules={[{ required: true, message: t("validation:field_required") }]}
          >
            <AutocompleteAsync
              className="mb-1 full-width"
              dropdownStyle={{ zIndex: 1202 }}
              requestOptions={{ orderBy: "issues_created" }}
              type="user"
              size="large"
              mode={!props.isUpdateForm ? "multiple" : undefined}
              disabled={props.onCheckToAvailability(blockedFieldsKeys.executorUserId)}
              minOptionsCount={!props.isUpdateForm ? 1 : undefined}
              additionalItems={[
                {
                  id: props.updater?.currentState?.executorUserId,
                  text:
                    props.updater?.currentState?.executorUser?.nameShort ??
                    props.updater?.currentState?.executorUser?.nameFallback,
                  avatarText: [
                    props.updater?.currentState?.executorUser?.lastName,
                    props.updater?.currentState?.executorUser?.firstName,
                  ],
                  avatar: props.updater?.currentState?.executorUser?.avatar,
                  color: props.updater?.currentState?.executorUser?.color ?? "",
                },
              ]}
              placeholder={t("ui:placeholder.click_to_select")}
              value={props.isUpdateForm ? props.updater?.currentState?.executorUserId ?? undefined : props.executorIds}
              onChange={
                (value) => {
                  if (props.isUpdateForm) {
                    props.onChangeExecutor!(props.updater?.currentState?.executorUser?.id!, value);
                  } else {
                    if ((value ?? []).length == 0) setIsValidationErrorExecutor(true);
                    else setIsValidationErrorExecutor(false);
                    if ((value ?? []).length > 1 && props.updater?.currentState?.roleId)
                      props.updater.applyChanges({ roleId: undefined });
                    props.onChangeExecutorIds && props.onChangeExecutorIds(value);
                  }
                }

                // props.updater.applyChanges({ executorUserId: value })
              }
            />
          </Form.Item>
        </div>
        <div style={{ width: "48%" }}>
          <SubheaderText text={t("ui:subheader.issue_role")} />
          <AutocompleteAsync
            className="mb-3 full-width"
            dropdownStyle={{ zIndex: 1202, maxWidth: 259.3 }}
            type="role"
            size="large"
            placeholder={t("ui:placeholder.click_to_select")}
            allowClear={true}
            disabled={
              props.onCheckToAvailability(blockedFieldsKeys.roleId) ||
              (props.isUpdateForm
                ? !props.updater?.currentState?.executorUserId
                : (props.executorIds ?? []).length > 1 || !props.executorIds?.length)
            }
            additionalItems={
              props.updater.currentState?.role && [
                {
                  id: props.updater.currentState?.role?.id,
                  text: props.updater.currentState?.role?.name,
                },
              ]
            }
            requestOptions={{
              orgchartId: props.updater.currentState?.orgchartId,
              userIds: props.isUpdateForm
                ? props.updater?.currentState?.executorUserId
                  ? [props.updater?.currentState?.executorUserId]
                  : undefined
                : (props.executorIds ?? []).length > 0
                ? props.executorIds
                : undefined,
            }}
            onChange={(id) => {
              props.updater.applyChanges({ roleId: id });
              if (!id) return props.onAsyncUpdate();
            }}
            value={props.updater?.currentState?.roleId ?? undefined}
            onDropdownVisibleChange={(visible) => !visible && props.onAsyncUpdate()}
          />
        </div>
      </div>

      <div className="d-stack-row justify-space-between align-start ">
        <div style={{ width: "48%" }}>
          <SubheaderText text={t("ui:subheader.executor_deadline")} />
          <DatePicker
            disabled={props.onCheckToAvailability(blockedFieldsKeys.dateWorkStart)}
            className="mb-3 full-width"
            value={
              props.updater?.currentState?.dateWorkStart ? dayjs(props.updater?.currentState?.dateWorkStart) : null
            }
            onChange={(value) =>
              props.onChangeExecutorDeadline!({
                prevValue: props.updater.currentState?.dateWorkStart,
                nextValue: value?.toDate() ?? null,
                warningKey: WarningsKeys.executorDeadline,
              })
            }
            allowClear
            size="large"
            showTime={{ format: "HH:mm" }}
            disablePast
          />
        </div>
        <div style={{ width: "48%" }}>
          <SubheaderText text={t("ui:subheader.deadline")} />
          <DatePicker
            disabled={props.onCheckToAvailability(blockedFieldsKeys.dateDeadline)}
            className="mb-3 full-width"
            value={props.updater?.currentState?.dateDeadline ? dayjs(props.updater?.currentState?.dateDeadline) : null}
            onChange={(value) => props.onPickerChange("dateDeadline", value?.toDate() ?? null)}
            //onChange={(value) => props.updater.applyChanges({ dateDeadline: value })}
            allowClear
            size="large"
            showTime={{ format: "HH:mm" }}
            disablePast
            // maxDate={
            //   props.updater?.currentState?.calculated?.dateWorkStartTo
            //     ? new Date(props.updater?.currentState?.calculated?.dateWorkStartTo)
            //     : undefined
            // }
            //onBlur={props.onAsyncUpdate}
          />
        </div>
      </div>
      <div className="d-stack-row align-center justify-space-between mb-3">
        <div style={{ width: "48%" }}>
          <SubheaderText text={t("ui:planning_table.plan")} />
          <TimePicker
            disabled={props.onCheckToAvailability(blockedFieldsKeys.timePlan)}
            size="large"
            className="full-width"
            //onBlur={props.onAsyncUpdate}
            value={
              props.updater.currentState?.timePlan
                ? getObjectFromTimeSpan(props.updater.currentState?.timePlan).dayjsObject
                : undefined
            }
            onChange={(value) => props.onPickerChange("timePlan", value?.toDate() ?? null, true)}
            // onChange={(date) =>
            //   props.updater.applyChanges({ timePlan: date ? generateTimeSpan({ dateObject: date }) : undefined })
            // }
          />
        </div>
        <div style={{ width: "48%" }}>
          <SubheaderText text={t("ui:planning_table.fact")} />
          <TimePicker
            disabled={props.onCheckToAvailability(blockedFieldsKeys.timeFact) || !props.isUpdateForm}
            size="large"
            className="full-width"
            //onBlur={props.onAsyncUpdate}
            value={
              props.updater.currentState?.timeFact
                ? getObjectFromTimeSpan(props.updater.currentState?.timeFact).dayjsObject
                : undefined
            }
            onChange={(value) => props.onPickerChange("timeFact", value?.toDate() ?? null, true)}
            // onChange={(date) =>
            //   props.updater.applyChanges({ timeFact: date ? generateTimeSpan({ dateObject: date }) : undefined })
            // }
          />
        </div>
      </div>
      <SubheaderText text={t("ui:subheader.issue_sender")} />
      <AutocompleteAsync
        className="mb-1 full-width"
        dropdownStyle={{ zIndex: 1202 }}
        requestOptions={{ orderBy: "issues_created" }}
        type="user"
        size="large"
        allowClear={false}
        disabled={props.onCheckToAvailability(blockedFieldsKeys.createdByUserId)}
        additionalItems={[
          {
            id: props.updater?.currentState?.executorUserId,
            text:
              props.updater?.currentState?.createdByUser?.nameShort ??
              props.updater?.currentState?.createdByUser?.nameFallback,
            avatarText: [
              props.updater?.currentState?.createdByUser?.lastName,
              props.updater?.currentState?.createdByUser?.firstName,
            ],
            avatar: props.updater?.currentState?.executorUser?.avatar,
            color: props.updater?.currentState?.executorUser?.color ?? "",
          },
        ]}
        placeholder={t("ui:placeholder.click_to_select")}
        value={props.updater?.currentState?.createdByUserId ?? undefined}
        onChange={(value) => props.updater.applyChanges({ createdByUserId: +value })}
        onDropdownVisibleChange={(visible) => !visible && props.onAsyncUpdate()}
      />
      {/*<Form.Item
        className="full-width"
        name="orgchartId"
        initialValue={props.updater?.currentState?.orgchartId}
        rules={[
          {
            required: true,
            message: t("validation:field_required"),
          },
        ]}
      >
        <Select
          defaultValue={defaultOrgchartId}
          onDropdownVisibleChange={(visible) => !visible && props.onAsyncUpdate()}
          dropdownStyle={{ zIndex: 1202 }}
          value={props.updater?.currentState?.orgchartId?.toString() ?? undefined}
          disabled={props.onCheckToAvailability(blockedFieldsKeys.orgchart)}
          placeholder={t("ui:placeholder.click_to_select")}
          onChange={(value) => props.updater.applyChanges({ orgchartId: +value })}
          style={{ width: "100%" }}
        >
          {authStore.getInitialInfo?.orgcharts?.map((orgchart) => (
            <SelectOption value={(orgchart.id ?? 0).toString()}>{orgchart.name}</SelectOption>
          ))}
        </Select>
      </Form.Item>*/}
      <SubheaderText className="mt-3" text={t("ui:subheader.issue_participants")} />
      <AutocompleteAsync
        style={{ minWidth: "100%", maxWidth: 570 }}
        dropdownStyle={{ zIndex: 1202 }}
        className="mb-3 full-width"
        requestOptions={{ orderBy: "issues_created" }}
        type="user"
        placeholder={t("ui:placeholder.click_to_select")}
        mode="multiple"
        size="large"
        onDropdownVisibleChange={(visible) => !visible && props.onAsyncUpdate()}
        disabled={props.onCheckToAvailability(blockedFieldsKeys.participants)}
        // TODO: Check (TSUP-158)
        // additionalItems={[
        //   {
        //     id: props.updater?.currentState?.executorUserId,
        //     text:
        //       props.updater?.currentState?.executorUser?.nameShort ??
        //       props.updater?.currentState?.executorUser?.nameFallback,
        //     avatarText: [
        //       props.updater?.currentState?.createdByUser?.lastName,
        //       props.updater?.currentState?.createdByUser?.firstName,
        //     ],
        //     avatar: props.updater?.currentState?.executorUser?.avatar,
        //     color: props.updater?.currentState?.executorUser?.color ?? "",
        //   },
        //   ...(props.updater?.currentState?.participants?.map((item) => ({
        //     id: item.userId,
        //     text: item.user?.name,
        //     avatar: item.user?.avatar,
        //     color: item.user?.color ?? "",
        //   })) ?? []),
        // ]}
        //
        value={props.updater?.currentState?.participants?.map((item) => item.userId) ?? []}
        onChange={(arrParticipants: number[]) =>
          props.isUpdateForm
            ? props.setParticipantsWithUpdater!(arrParticipants)
            : props.updater.applyChanges({
                participants: arrParticipants.map((id) => ({
                  userId: Number(id),
                  user: { id },
                })),
              })
        }
      />
      {props.updater?.currentState?.fields && props.updater?.currentState?.fields.length > 0 && (
        <>
          <SubheaderText text={t("ui:subheader.issue_advanced_settings")} />
          <IssueCustomFieldsForm
            type={issueType ?? undefined}
            fieldsArray={props.updater.currentState.fields ?? props.customFields}
            value={props.updater?.currentState?.fields}
            onChange={(fields) => {
              props.updater.applyChanges({ fields: fields });
              props.isUpdateForm && props.customFieldsIsChanged(fields) && props.onAsyncUpdate();
            }}
            elementsState={[
              {
                key: blockedFieldsKeys.isStrictDeadline,
                disabled: props.onCheckToAvailability(blockedFieldsKeys.isStrictDeadline),
              },
              {
                key: blockedFieldsKeys.isManagerApprovalRequired,
                disabled: props.onCheckToAvailability(blockedFieldsKeys.isManagerApprovalRequired),
              },
            ]}
          />
        </>
      )}
    </Form>
  );
});
