import { FiChevronLeft } from "@react-icons/all-files/fi/FiChevronLeft";
import { Value } from "@udecode/plate";
import React, { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PageContent from "../../modules/layouts/pageContent/PageContent";
import PageHeader from "../../modules/layouts/pageHeader/PageHeader";
import { IconButton } from "../../uiKit";
import "./SandboxPage.scss";
import { TextEditor } from "../../modules/textEditor/TextEditor";
import { InitialEditorStateType } from "@lexical/react/LexicalComposer";
import { emptyEditorJSON } from "../../../utils/textEditor";

function SandboxPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const [content, setContent] = useState<Value>(initialContent);

  const [content, setContent] = useState<Value>([
    //Текст
    {
      type: "text",
      children: [
        { type: "unchanged", text: "Неизменённый текст" },
        { type: "deleted", text: "Удалённый текст" },
        { type: "added", text: "Добавленный текст" },
      ],
    },
    {
      type: "name",
      children: [{ type: "deleted", text: "" }, { text: " > " }, { type: "added", text: "" }],
    },
    //Ссылки
    {
      type: "link",
      children: [
        {
          type: "a",
          url: "https://api.tonnus.io/static/1/000825144(1).pdf",
          children: [{ type: "deleted", text: "Ссылка" }],
        },
        { text: " > " },
        {
          type: "a",
          url: "",
          children: [{ type: "added", text: "Ссылка" }],
        },
      ],
    },
    //Даты
    {
      type: "date",
      children: [
        { type: "deleted", text: "2022-12-20T03:00:00Z" },
        { text: " > " },
        { type: "added", text: "2022-12-22T05:00:00Z" },
      ],
    },
    //План и факт
    {
      type: "time",
      children: [{ type: "deleted", text: "05:11:00" }, { text: " > " }, { type: "added", text: "07:18:00" }],
    },
    //Свитчи
    {
      type: "switch",
      children: [{ type: "deleted", text: "Включено" }, { text: " > " }, { type: "added", text: "Выключено" }],
    },
    //Тег пользователя
    {
      type: "user",
      children: [
        { type: "@", id: 2, children: [{ type: "deleted", text: "" }] },
        { text: " > " },
        { type: "@", id: 1, children: [{ type: "added", text: "" }] },
      ],
    },
    //Оргструктура
    {
      type: "orgchart",
      children: [
        {
          type: "#",
          id: 2,
          children: [
            {
              type: "deleted",
              text: "",
            },
          ],
        },
        { text: " > " },
        {
          type: "#",
          id: 2,
          children: [
            {
              type: "added",
              text: "",
            },
          ],
        },
      ],
    },
    //Функции
    {
      type: "role",
      children: [
        {
          type: "roleNested",
          id: 2,
          children: [
            {
              type: "deleted",
              text: "",
            },
          ],
        },
        { text: " > " },
        {
          type: "roleNested",
          id: 2,
          children: [
            {
              type: "added",
              text: "",
            },
          ],
        },
      ],
    },
  ]);

  const contentRef = useRef<{ clear: () => void }>();

  const stateValue = {
    root: {
      children: [
        {
          children: [
            {
              altText: "",
              height: 0,
              maxWidth: 500,
              showCaption: false,
              src: "https://fly.prilogy.ru/static/1/IMGWorldsofAdventureAdmissionTicketinDubai-Klook(2).jpg",
              type: "image",
              version: 1,
              width: 0,
              resizable: false,
            },
          ],
          direction: "ltr",
          format: "",
          indent: 0,
          type: "paragraph",
          version: 1,
        },
        {
          format: "",
          type: "youtube",
          version: 1,
          videoID: "T-3smtJxAC8",
        },
        {
          children: [
            {
              detail: 0,
              format: 0,
              mode: "normal",
              style: "",
              text: "2022-12-20T03:00:00Z",
              dataType: "date",
              dataValue: "2022-12-20T03:00:00Z",
              subType: "error",
              type: "custom",
              version: 1,
            },
            {
              detail: 0,
              format: 0,
              mode: "normal",
              style: "",
              text: ">",
              dataType: "",
              dataValue: ">",
              subType: "",
              type: "custom",
              version: 1,
            },
            {
              detail: 0,
              format: 0,
              mode: "normal",
              style: "",
              text: "2022-12-22T10:00:00Z",
              dataType: "date",
              dataValue: "2022-12-22T10:00:00Z",
              subType: "success",
              type: "custom",
              version: 1,
            },
          ],
          direction: "ltr",
          format: "",
          indent: 0,
          type: "paragraph",
          version: 1,
        },
        {
          children: [
            {
              detail: 0,
              format: 0,
              mode: "normal",
              style: "",
              text: "05:11:00",
              dataType: "time",
              dataValue: "05:11:00",
              subType: "error",
              type: "custom",
              version: 1,
            },
            {
              detail: 0,
              format: 0,
              mode: "normal",
              style: "",
              text: ">",
              dataType: "",
              dataValue: ">",
              subType: "",
              type: "custom",
              version: 1,
            },
            {
              detail: 0,
              format: 0,
              mode: "normal",
              style: "",
              text: "07:18:00",
              dataType: "time",
              dataValue: "07:18:00",
              subType: "success",
              type: "custom",
              version: 1,
            },
          ],
          direction: "ltr",
          format: "",
          indent: 0,
          type: "paragraph",
          version: 1,
        },
        {
          children: [
            {
              id: 3012,
              subType: "error",
              type: "mention",
              version: 1,
            },
            {
              detail: 0,
              format: 0,
              mode: "normal",
              style: "",
              text: ">",
              dataType: "",
              dataValue: ">",
              subType: "",
              type: "custom",
              version: 1,
            },
            {
              id: 2835,
              subType: "success",
              type: "mention",
              version: 1,
            },
          ],
          direction: "ltr",
          format: "",
          indent: 0,
          type: "paragraph",
          version: 1,
        },
        {
          children: [
            {
              id: 2,
              subType: "error",
              type: "orgchart",
              version: 1,
            },
            {
              detail: 0,
              format: 0,
              mode: "normal",
              style: "",
              text: ">",
              dataType: "",
              dataValue: ">",
              subType: "",
              type: "custom",
              version: 1,
            },
            {
              id: 404,
              subType: "success",
              type: "orgchart",
              version: 1,
            },
          ],
          direction: "ltr",
          format: "",
          indent: 0,
          type: "paragraph",
          version: 1,
        },
        {
          children: [
            {
              detail: 0,
              format: 0,
              mode: "normal",
              style: "",
              text: "Выключен",
              dataType: "switch",
              dataValue: "Выключен",
              subType: "error",
              type: "custom",
              version: 1,
            },
            {
              detail: 0,
              format: 0,
              mode: "normal",
              style: "",
              text: ">",
              dataType: "",
              dataValue: ">",
              subType: "",
              type: "custom",
              version: 1,
            },
            {
              detail: 0,
              format: 0,
              mode: "normal",
              style: "",
              text: "Включен",
              dataType: "switch",
              dataValue: "Включен",
              subType: "success",
              type: "custom",
              version: 1,
            },
          ],
          direction: "ltr",
          format: "",
          indent: 0,
          type: "paragraph",
          version: 1,
        },
      ],
      direction: "ltr",
      format: "",
      indent: 0,
      type: "root",
      version: 1,
    },
  };

  const readOnlyState = {
    root: {
      children: [
        {
          children: [
            {
              detail: 0,
              format: 0,
              mode: "normal",
              style: "",
              text: "Обычный",
              type: "text",
              version: 1,
            },
          ],
          direction: "ltr",
          format: "",
          indent: 0,
          type: "paragraph",
          version: 1,
        },
        {
          children: [
            {
              detail: 0,
              format: 0,
              mode: "normal",
              style: "",
              text: "Заголовок 1",
              type: "text",
              version: 1,
            },
          ],
          direction: "ltr",
          format: "",
          indent: 0,
          type: "heading",
          version: 1,
          tag: "h1",
        },
        {
          children: [
            {
              detail: 0,
              format: 0,
              mode: "normal",
              style: "",
              text: "Заголовок 2",
              type: "text",
              version: 1,
            },
          ],
          direction: "ltr",
          format: "",
          indent: 0,
          type: "heading",
          version: 1,
          tag: "h2",
        },
        {
          children: [
            {
              detail: 0,
              format: 0,
              mode: "normal",
              style: "",
              text: "Заголовок 3 ",
              type: "text",
              version: 1,
            },
          ],
          direction: "ltr",
          format: "",
          indent: 0,
          type: "heading",
          version: 1,
          tag: "h3",
        },
        {
          children: [
            {
              children: [
                {
                  detail: 0,
                  format: 0,
                  mode: "normal",
                  style: "",
                  text: "Маркированный",
                  type: "text",
                  version: 1,
                },
              ],
              direction: "ltr",
              format: "",
              indent: 0,
              type: "listitem",
              version: 1,
              value: 1,
            },
          ],
          direction: "ltr",
          format: "",
          indent: 0,
          type: "list",
          version: 1,
          listType: "bullet",
          start: 1,
          tag: "ul",
        },
        {
          children: [
            {
              children: [
                {
                  detail: 0,
                  format: 0,
                  mode: "normal",
                  style: "",
                  text: "Нумерованный",
                  type: "text",
                  version: 1,
                },
              ],
              direction: "ltr",
              format: "",
              indent: 0,
              type: "listitem",
              version: 1,
              value: 1,
            },
          ],
          direction: "ltr",
          format: "",
          indent: 0,
          type: "list",
          version: 1,
          listType: "number",
          start: 1,
          tag: "ol",
        },
        {
          children: [
            {
              detail: 0,
              format: 0,
              mode: "normal",
              style: "",
              text: "Цитата",
              type: "text",
              version: 1,
            },
          ],
          direction: "ltr",
          format: "",
          indent: 0,
          type: "quote",
          version: 1,
        },
        {
          children: [
            {
              detail: 0,
              format: 1,
              mode: "normal",
              style: "",
              text: "Жирный ",
              type: "text",
              version: 1,
            },
            {
              detail: 0,
              format: 2,
              mode: "normal",
              style: "",
              text: "Курсив ",
              type: "text",
              version: 1,
            },
            {
              detail: 0,
              format: 8,
              mode: "normal",
              style: "",
              text: "Подчеркнутый ",
              type: "text",
              version: 1,
            },
            {
              detail: 0,
              format: 16,
              mode: "normal",
              style: "",
              text: "Код",
              type: "text",
              version: 1,
            },
            {
              children: [
                {
                  detail: 0,
                  format: 0,
                  mode: "normal",
                  style: "",
                  text: " ",
                  type: "text",
                  version: 1,
                },
              ],
              direction: null,
              format: "",
              indent: 0,
              type: "link",
              version: 1,
              rel: "noopener",
              target: null,
              url: "https://google.com",
            },
            {
              children: [
                {
                  detail: 0,
                  format: 0,
                  mode: "normal",
                  style: "",
                  text: "ссылка",
                  type: "text",
                  version: 1,
                },
              ],
              direction: "ltr",
              format: "",
              indent: 0,
              type: "link",
              version: 1,
              rel: "noopener",
              target: null,
              url: "https://google.com",
            },
            {
              detail: 0,
              format: 0,
              mode: "normal",
              style: "",
              text: " ",
              type: "text",
              version: 1,
            },
          ],
          direction: "ltr",
          format: "",
          indent: 0,
          type: "paragraph",
          version: 1,
        },
        {
          children: [
            {
              detail: 0,
              format: 0,
              mode: "normal",
              style: "color: #ca7f03;",
              text: "Цвет текста ",
              type: "text",
              version: 1,
            },
            {
              detail: 0,
              format: 0,
              mode: "normal",
              style: "color: #000000;background-color: #509e01;",
              text: "Бэкграунд",
              type: "text",
              version: 1,
            },
          ],
          direction: "ltr",
          format: "",
          indent: 0,
          type: "paragraph",
          version: 1,
        },
        {
          children: [],
          direction: null,
          format: "",
          indent: 0,
          type: "paragraph",
          version: 1,
        },
        {
          type: "horizontalrule",
          version: 1,
        },
        {
          children: [
            {
              altText: "",
              caption: {
                editorState: {
                  root: {
                    children: [],
                    direction: null,
                    format: "",
                    indent: 0,
                    type: "root",
                    version: 1,
                  },
                },
              },
              height: 0,
              maxWidth: 500,
              showCaption: false,
              src: "https://api.beta.tonnus.io/static/1/IMGWorldsofAdventureAdmissionTicketinDubai-Klook(20).jpg",
              type: "image",
              version: 1,
              width: 0,
            },
          ],
          direction: null,
          format: "",
          indent: 0,
          type: "paragraph",
          version: 1,
        },
        {
          children: [],
          direction: null,
          format: "",
          indent: 0,
          type: "paragraph",
          version: 1,
        },
        {
          children: [
            {
              children: [
                {
                  children: [
                    {
                      children: [],
                      direction: null,
                      format: "",
                      indent: 0,
                      type: "paragraph",
                      version: 1,
                    },
                  ],
                  direction: null,
                  format: "",
                  indent: 0,
                  type: "tablecell",
                  version: 1,
                  headerState: 0,
                },
                {
                  children: [
                    {
                      children: [],
                      direction: null,
                      format: "",
                      indent: 0,
                      type: "paragraph",
                      version: 1,
                    },
                  ],
                  direction: null,
                  format: "",
                  indent: 0,
                  type: "tablecell",
                  version: 1,
                  headerState: 0,
                },
                {
                  children: [
                    {
                      children: [],
                      direction: null,
                      format: "",
                      indent: 0,
                      type: "paragraph",
                      version: 1,
                    },
                  ],
                  direction: null,
                  format: "",
                  indent: 0,
                  type: "tablecell",
                  version: 1,
                  headerState: 0,
                },
                {
                  children: [
                    {
                      children: [],
                      direction: null,
                      format: "",
                      indent: 0,
                      type: "paragraph",
                      version: 1,
                    },
                  ],
                  direction: null,
                  format: "",
                  indent: 0,
                  type: "tablecell",
                  version: 1,
                  headerState: 0,
                },
                {
                  children: [
                    {
                      children: [],
                      direction: null,
                      format: "",
                      indent: 0,
                      type: "paragraph",
                      version: 1,
                    },
                  ],
                  direction: null,
                  format: "",
                  indent: 0,
                  type: "tablecell",
                  version: 1,
                  headerState: 0,
                },
              ],
              direction: null,
              format: "",
              indent: 0,
              type: "tablerow",
              version: 1,
            },
            {
              children: [
                {
                  children: [
                    {
                      children: [],
                      direction: null,
                      format: "",
                      indent: 0,
                      type: "paragraph",
                      version: 1,
                    },
                  ],
                  direction: null,
                  format: "",
                  indent: 0,
                  type: "tablecell",
                  version: 1,
                  headerState: 0,
                },
                {
                  children: [
                    {
                      children: [],
                      direction: null,
                      format: "",
                      indent: 0,
                      type: "paragraph",
                      version: 1,
                    },
                  ],
                  direction: null,
                  format: "",
                  indent: 0,
                  type: "tablecell",
                  version: 1,
                  headerState: 0,
                },
                {
                  children: [
                    {
                      children: [],
                      direction: null,
                      format: "",
                      indent: 0,
                      type: "paragraph",
                      version: 1,
                    },
                  ],
                  direction: null,
                  format: "",
                  indent: 0,
                  type: "tablecell",
                  version: 1,
                  headerState: 0,
                },
                {
                  children: [
                    {
                      children: [],
                      direction: null,
                      format: "",
                      indent: 0,
                      type: "paragraph",
                      version: 1,
                    },
                  ],
                  direction: null,
                  format: "",
                  indent: 0,
                  type: "tablecell",
                  version: 1,
                  headerState: 0,
                },
                {
                  children: [
                    {
                      children: [],
                      direction: null,
                      format: "",
                      indent: 0,
                      type: "paragraph",
                      version: 1,
                    },
                  ],
                  direction: null,
                  format: "",
                  indent: 0,
                  type: "tablecell",
                  version: 1,
                  headerState: 0,
                },
              ],
              direction: null,
              format: "",
              indent: 0,
              type: "tablerow",
              version: 1,
            },
            {
              children: [
                {
                  children: [
                    {
                      children: [],
                      direction: null,
                      format: "",
                      indent: 0,
                      type: "paragraph",
                      version: 1,
                    },
                  ],
                  direction: null,
                  format: "",
                  indent: 0,
                  type: "tablecell",
                  version: 1,
                  headerState: 0,
                },
                {
                  children: [
                    {
                      children: [],
                      direction: null,
                      format: "",
                      indent: 0,
                      type: "paragraph",
                      version: 1,
                    },
                  ],
                  direction: null,
                  format: "",
                  indent: 0,
                  type: "tablecell",
                  version: 1,
                  headerState: 0,
                },
                {
                  children: [
                    {
                      children: [],
                      direction: null,
                      format: "",
                      indent: 0,
                      type: "paragraph",
                      version: 1,
                    },
                  ],
                  direction: null,
                  format: "",
                  indent: 0,
                  type: "tablecell",
                  version: 1,
                  headerState: 0,
                },
                {
                  children: [
                    {
                      children: [],
                      direction: null,
                      format: "",
                      indent: 0,
                      type: "paragraph",
                      version: 1,
                    },
                  ],
                  direction: null,
                  format: "",
                  indent: 0,
                  type: "tablecell",
                  version: 1,
                  headerState: 0,
                },
                {
                  children: [
                    {
                      children: [],
                      direction: null,
                      format: "",
                      indent: 0,
                      type: "paragraph",
                      version: 1,
                    },
                  ],
                  direction: null,
                  format: "",
                  indent: 0,
                  type: "tablecell",
                  version: 1,
                  headerState: 0,
                },
              ],
              direction: null,
              format: "",
              indent: 0,
              type: "tablerow",
              version: 1,
            },
            {
              children: [
                {
                  children: [
                    {
                      children: [],
                      direction: null,
                      format: "",
                      indent: 0,
                      type: "paragraph",
                      version: 1,
                    },
                  ],
                  direction: null,
                  format: "",
                  indent: 0,
                  type: "tablecell",
                  version: 1,
                  headerState: 0,
                },
                {
                  children: [
                    {
                      children: [],
                      direction: null,
                      format: "",
                      indent: 0,
                      type: "paragraph",
                      version: 1,
                    },
                  ],
                  direction: null,
                  format: "",
                  indent: 0,
                  type: "tablecell",
                  version: 1,
                  headerState: 0,
                },
                {
                  children: [
                    {
                      children: [],
                      direction: null,
                      format: "",
                      indent: 0,
                      type: "paragraph",
                      version: 1,
                    },
                  ],
                  direction: null,
                  format: "",
                  indent: 0,
                  type: "tablecell",
                  version: 1,
                  headerState: 0,
                },
                {
                  children: [
                    {
                      children: [],
                      direction: null,
                      format: "",
                      indent: 0,
                      type: "paragraph",
                      version: 1,
                    },
                  ],
                  direction: null,
                  format: "",
                  indent: 0,
                  type: "tablecell",
                  version: 1,
                  headerState: 0,
                },
                {
                  children: [
                    {
                      children: [],
                      direction: null,
                      format: "",
                      indent: 0,
                      type: "paragraph",
                      version: 1,
                    },
                  ],
                  direction: null,
                  format: "",
                  indent: 0,
                  type: "tablecell",
                  version: 1,
                  headerState: 0,
                },
              ],
              direction: null,
              format: "",
              indent: 0,
              type: "tablerow",
              version: 1,
            },
            {
              children: [
                {
                  children: [
                    {
                      children: [],
                      direction: null,
                      format: "",
                      indent: 0,
                      type: "paragraph",
                      version: 1,
                    },
                  ],
                  direction: null,
                  format: "",
                  indent: 0,
                  type: "tablecell",
                  version: 1,
                  headerState: 0,
                },
                {
                  children: [
                    {
                      children: [],
                      direction: null,
                      format: "",
                      indent: 0,
                      type: "paragraph",
                      version: 1,
                    },
                  ],
                  direction: null,
                  format: "",
                  indent: 0,
                  type: "tablecell",
                  version: 1,
                  headerState: 0,
                },
                {
                  children: [
                    {
                      children: [],
                      direction: null,
                      format: "",
                      indent: 0,
                      type: "paragraph",
                      version: 1,
                    },
                  ],
                  direction: null,
                  format: "",
                  indent: 0,
                  type: "tablecell",
                  version: 1,
                  headerState: 0,
                },
                {
                  children: [
                    {
                      children: [],
                      direction: null,
                      format: "",
                      indent: 0,
                      type: "paragraph",
                      version: 1,
                    },
                  ],
                  direction: null,
                  format: "",
                  indent: 0,
                  type: "tablecell",
                  version: 1,
                  headerState: 0,
                },
                {
                  children: [
                    {
                      children: [],
                      direction: null,
                      format: "",
                      indent: 0,
                      type: "paragraph",
                      version: 1,
                    },
                  ],
                  direction: null,
                  format: "",
                  indent: 0,
                  type: "tablecell",
                  version: 1,
                  headerState: 0,
                },
              ],
              direction: null,
              format: "",
              indent: 0,
              type: "tablerow",
              version: 1,
            },
          ],
          direction: null,
          format: "",
          indent: 0,
          type: "table",
          version: 1,
        },
        {
          children: [],
          direction: null,
          format: "",
          indent: 0,
          type: "paragraph",
          version: 1,
        },
        {
          format: "",
          type: "youtube",
          version: 1,
          videoID: "dDeWWQWMM-Y",
        },
        {
          children: [],
          direction: null,
          format: "",
          indent: 0,
          type: "paragraph",
          version: 1,
        },
      ],
      direction: "ltr",
      format: "",
      indent: 0,
      type: "root",
      version: 1,
    },
  };

  // const [value, setValue] = useState<InitialEditorStateType>(JSON.stringify(stateValue));
  const [value, setValue] = useState<InitialEditorStateType>(emptyEditorJSON);
  const [readonlyValue, setReadonlyValue] = useState<InitialEditorStateType>(JSON.stringify(readOnlyState));
  // const [value, setValue] = useState<InitialEditorStateType>(emptyEditorJSON);

  const clearContent = () => {
    contentRef.current?.clear();
  };

  const handleChangeContent = (value: Value) => {
    setContent(value);
  };

  const handleChangeState = useCallback((value: InitialEditorStateType) => {
    setValue(value);
  }, []);

  return (
    <>
      <PageHeader title={t("common:page_title.sandbox") + " / AY"}>
        <IconButton onClick={() => navigate("/sandbox")} size="small" className="ml-auto">
          <FiChevronLeft />
        </IconButton>
      </PageHeader>
      <PageContent isFilled>
        {/*<TextEditor*/}
        {/*  id="default-toolbar"*/}
        {/*  value={content}*/}
        {/*  onChange={handleChangeContent}*/}
        {/*  ref={contentRef}*/}
        {/*  placeholder="Default toolbar"*/}
        {/*  // readOnly*/}
        {/*/>*/}
        <TextEditor value={value} onChange={handleChangeState} />
        <div className="mt-4" />
        <TextEditor value={readonlyValue} readOnly />
        {/*<TextEditor value={value} onChange={setValue} readOnly />*/}
      </PageContent>
    </>
  );
}

export default SandboxPage;
