import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./IssueSwitchStatus.scss";
import { IssueSwitchStatusView } from "./IssueSwitchStatusView";
import { IIssueSwitchStatus } from "./IssueSwitchStatus.interface";
import { useTranslation } from "react-i18next";
import { useNotifier, useRootStore } from "../../../hooks";
import { BoardStatusDto } from "../../../api";
import { api } from "../../../services";
import IssueRequiredActionsDialog from "../../modules/dialogs/issueRequiredActionsDialog/IssueRequiredActionsDialog";

const IssueSwitchStatusMemo = (props: IIssueSwitchStatus) => {
  const { issueInitDataStore } = useRootStore();
  const { t } = useTranslation();
  const notifier = useNotifier();
  const [nextStatus, setNextStatus] = useState<BoardStatusDto | null>(null);
  const [isOpenRequireActionsDialog, setIsOpenRequireActionsDialog] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const nextStatuses = useMemo(
    () => props.issueData?.calculated?.nextStatuses,
    [props.issueData?.calculated?.nextStatuses]
  );

  const currentStatus = useMemo(
    () => props.issueData?.currentStatus?.boardStatus,
    [props.issueData?.currentStatus?.boardStatus]
  );

  const handleCloseDialog = useCallback(() => {
    setIsOpenRequireActionsDialog(false);
    setIsLoading(false);
  }, []);

  const onCreateIssueHistoryAction = useCallback(
    async (actionId: number) => {
      const r = await api.issueHistory.create({
        issueId: props.issueData!.id,
        status: {
          boardStatusId: actionId,
        },
      });
      if (r == null) notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
      else props.onRefreshData && props.onRefreshData();
      issueInitDataStore.getOnStatusChange && issueInitDataStore.getOnStatusChange();

      //setButtonsLoading(false);
    },
    [issueInitDataStore, notifier, props, t]
  );

  const handleSelectNextStatus = useCallback(
    async (statusId: number) => {
      const currentNextStatus = nextStatuses?.find((item) => item.id == statusId);
      const isOnApproval = props.indicators?.find((item) => item.id == 9);
      if (currentNextStatus?.requiredActions && currentNextStatus.requiredActions?.length > 0 && !isOnApproval) {
        setIsLoading(true);
        setNextStatus(currentNextStatus);
        setIsOpenRequireActionsDialog(true);
      } else {
        setIsLoading(true);
        currentNextStatus?.id && (await onCreateIssueHistoryAction(currentNextStatus.id));
      }
    },
    [nextStatuses, onCreateIssueHistoryAction, props.indicators]
  );

  useEffect(() => {
    if (nextStatuses != null) setIsLoading(false);
  }, [nextStatuses]);

  useEffect(() => {
    !isOpenRequireActionsDialog && handleCloseDialog();
  }, [handleCloseDialog, isOpenRequireActionsDialog]);

  return (
    <>
      {isOpenRequireActionsDialog && (
        <IssueRequiredActionsDialog
          open={isOpenRequireActionsDialog}
          boardData={nextStatus}
          issueData={props.issueData!}
          onClose={handleCloseDialog}
          onRefreshData={props.onRefreshData}
        />
      )}
      <IssueSwitchStatusView
        currentStatus={currentStatus}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        onSelectNextStatus={handleSelectNextStatus}
        nextStatuses={nextStatuses}
        isDisabled={props.isDisabled}
      />
    </>
  );
};

export const IssueSwitchStatus = React.memo(
  IssueSwitchStatusMemo,
  (prev, next) =>
    prev.issueData?.currentStatus?.id == next.issueData?.currentStatus?.id &&
    prev.issueData?.calculated?.nextStatuses === next.issueData?.calculated?.nextStatuses
);
