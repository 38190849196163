import { LexicalEditor } from "lexical";

export interface IFormatSelect {
  blockType?: keyof typeof blockTypeToBlockName;
  alignType?: keyof typeof alignFormatType;
  editor: LexicalEditor;
  disabled?: boolean;
  formatType: "block" | "align";
  activeEditor?: LexicalEditor;
}

export const blockTypeToBlockName = {
  bullet: "bullet",
  check: "check",
  code: "code",
  h1: "h1",
  h2: "h2",
  h3: "h3",
  number: "number",
  paragraph: "paragraph",
  quote: "quote",
};

export const alignFormatType = {
  left: "left",
  right: "right",
  center: "center",
  justify: "justify",
};
