import React, { useEffect, useState } from "react";
import "./CreateCompanyGroupDialog.scss";
import { ICreateCompanyGroupDialog, IMessengerConfig } from "./CreateCompanyGroupDialog.interface";
import { CreateCompanyGroupDialogView } from "./CreateCompanyGroupDialogView";
import { api } from "../../../../services";
import { useNotifier, useRootStore } from "../../../../hooks";
import { useTranslation } from "react-i18next";
import { driverTypeKeys, getAdminDrivers, getDrivers } from "../../pages/groupCompany/helpers";
import { LocalesKeys } from "../../../../constants/localesKeys";
import { messengerConfigFieldKeys, messengerKeys } from "../../pages/groupCompany/constants/keys";
import { CompanyGroupDto } from "../../../../api";

export const CreateCompanyGroupDialog = (props: ICreateCompanyGroupDialog) => {
  const notifier = useNotifier();
  const { t } = useTranslation();
  const { appStore } = useRootStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [groupName, setGroupName] = useState<string>("");
  const [driversId, setDriversId] = useState<number[]>([]);
  const [adminDriversId, setAdminDriversId] = useState<number[]>([]);
  const [groupLocale, setGroupLocale] = useState<LocalesKeys>(appStore.getAppLocale as LocalesKeys);
  const [isAddTelegramBot, setIsAddTelegramBot] = useState<boolean>(false);
  const [messengerConfig, setMessengerConfig] = useState<IMessengerConfig>({ last_name: "Tonnus", phone_number: "+" });

  const handleError = () => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });

  const clean = () => {
    setGroupName("");
    setDriversId([]);
    setAdminDriversId([]);
  };

  const handleGetGroupCompanyDto = (): CompanyGroupDto => {
    const config = { ...messengerConfig };
    config.phone_number = "+" + config.phone_number;
    return {
      name: groupName,
      companies: adminDriversId.map((id) => ({ id })),
      cultureKey: groupLocale,
      ...((isAddTelegramBot || props.telegramFormOnly) && {
        messengers: [
          {
            configs: JSON.stringify(config),
            type: props.telegramFormOnly ? 1 : 0,
          },
        ],
      }),
      userTypes: [
        ...driversId.map((id) => ({ user: { id }, type: driverTypeKeys.driver })),
        ...adminDriversId.map((id) => ({ user: { id }, type: driverTypeKeys.admin })),
      ],
    };
  };

  const handleSaveGroupCompany = async () => {
    setIsLoading(true);
    const r =
      props.telegramFormOnly && props.companyGroup?.id
        ? await api.companyGroup.edit(props.companyGroup?.id, handleGetGroupCompanyDto())
        : await api.companyGroup.create(handleGetGroupCompanyDto());
    setIsLoading(false);
    if (r == null) return handleError();
    notifier.show(
      t(`notifier:success.${props.telegramFormOnly ? "group_companies_updated" : "group_companies_created"}`, "success")
    );
    props.onSuccess && props.onSuccess();
    props.onClose();
  };

  const handleChangeMessengerConfig = (valueKey: messengerConfigFieldKeys) => (event: any) =>
    setMessengerConfig((prev) => {
      switch (valueKey) {
        case messengerConfigFieldKeys.api_id:
          return { ...prev, api_id: event.target.value as string };
        case messengerConfigFieldKeys.api_hash:
          return { ...prev, api_hash: event.target.value as string };
        case messengerConfigFieldKeys.first_name:
          return { ...prev, first_name: event.target.value as string };
        case messengerConfigFieldKeys.last_name:
          return { ...prev, last_name: event.target.value as string };
        case messengerConfigFieldKeys.phone_number:
          return { ...prev, phone_number: event as string };
        default:
          return prev;
      }
    });

  const handleChangeGroupName = (event: any) => setGroupName(event.target.value as string);
  const handleChangeAdminDrivers = (newIDs: number[]) => setAdminDriversId(newIDs);
  const handleChangeDrivers = (newIDs: number[]) => setDriversId(newIDs);
  const handleChangeLocale = (locale: LocalesKeys) => setGroupLocale(locale);
  const handleChangeIsAddTelegramBot = (value: boolean) => setIsAddTelegramBot(value);

  useEffect(() => {
    if (props.telegramFormOnly && props.companyGroup) {
      setGroupName(props.companyGroup.name ?? "");
      const drivers = getDrivers(props.companyGroup.userTypes ?? []).map((item) => item.id!);
      const adminDrivers = getAdminDrivers(props.companyGroup.userTypes ?? []).map((item) => item.id!);
      handleChangeDrivers(drivers);
      handleChangeAdminDrivers(adminDrivers);
      handleChangeLocale(props.companyGroup.cultureKey as LocalesKeys);
      const hasTelegram: boolean = !!props.companyGroup?.messengers?.some(
        (item) => item.type == messengerKeys.telegram
      );
      handleChangeIsAddTelegramBot(hasTelegram);
      const telegramMessenger = props.companyGroup!.messengers!.find((item) => item.type == messengerKeys.telegram)!;
      hasTelegram && setMessengerConfig(JSON.parse(telegramMessenger.configs!));
    }
    return clean;
  }, [props.telegramFormOnly, props.companyGroup]);

  return (
    <CreateCompanyGroupDialogView
      onClose={props.onClose}
      open={props.open}
      telegramFormOnly={props.telegramFormOnly}
      isAddBot={isAddTelegramBot}
      messengerConfig={messengerConfig}
      groupLocale={groupLocale}
      isLoading={isLoading}
      driversId={driversId}
      adminDriversId={adminDriversId}
      groupName={groupName}
      onChangeIsAddTelegramBot={handleChangeIsAddTelegramBot}
      onChangeMessengerConfig={handleChangeMessengerConfig}
      onChangeLocale={handleChangeLocale}
      onChangeGroupName={handleChangeGroupName}
      onChangeAdminDriversId={handleChangeAdminDrivers}
      onChangeDriversId={handleChangeDrivers}
      onCreate={handleSaveGroupCompany}
    />
  );
};
