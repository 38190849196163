import { TIME_FORMAT } from "./AudioPlayer";
import { addLeadingZeroToString } from "../../../helpers/stringFunctions";

export const getDisplayTimeBySeconds = (seconds: number, totalSeconds: number, timeFormat: TIME_FORMAT): string => {
  if (!isFinite(seconds)) {
    return "";
  }

  const min = Math.floor(seconds / 60);
  const minStr = addLeadingZeroToString(min, 2);
  const secStr = addLeadingZeroToString(Math.floor(seconds % 60), 2);
  const minStrForHour = addLeadingZeroToString(Math.floor(min % 60), 2);
  const hourStr = Math.floor(min / 60);

  const mmSs = `${minStr}:${secStr}`;
  const hhMmSs = `${hourStr}:${minStrForHour}:${secStr}`;

  if (timeFormat === "auto") {
    if (totalSeconds >= 3600) {
      return hhMmSs;
    } else {
      return mmSs;
    }
  } else if (timeFormat === "mm:ss") {
    return mmSs;
  } else if (timeFormat === "hh:mm:ss") {
    return hhMmSs;
  }

  return "";
};
