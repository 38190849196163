import React from "react";
import { IOpeningGuideIcon } from "./OpeningGuideIcon.interface";
import { useRootStore } from "../../../hooks";
import { OpeningGuideIconView } from "./OpeningGuideIconView";

export const OpeningGuideIcon = (props: IOpeningGuideIcon) => {
  const { hintStore } = useRootStore();

  const handleOpenGuideDrawer = () => {
    hintStore.onOpenHint(props.contentKey, props.anchorId);
  };

  return <OpeningGuideIconView onClick={handleOpenGuideDrawer} mr={props.mr} ml={props.ml} mt={props.mt} />;
};
