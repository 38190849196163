import React, { useState } from "react";
import { ArchiveButtonView } from "./ArchiveButtonView";
import { IssuesArchive } from "../../../../drawers/issuesArchive/IssuesArchive";

export const ArchiveButton = () => {
  const [isOpenArchive, setIsOpenArchive] = useState<boolean>(false);

  const handleClick = () => setIsOpenArchive(!isOpenArchive);

  const handleCloseArchive = () => setIsOpenArchive(false);

  return (
    <>
      <IssuesArchive onClose={handleCloseArchive} isOpen={isOpenArchive} />
      <ArchiveButtonView onClick={handleClick} />
    </>
  );
};
