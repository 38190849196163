import React, { useEffect, useState } from "react";
import "./SettingUpAccessDialog.scss";
import { ISettingUpAccessDialog, keysRulesFor } from "./SettingUpAccessDialog.interface";
import { Button, Dialog, DialogActions, Text } from "../../../uiKit";
import { useTranslation } from "react-i18next";
import ExecutorSelect from "../../../elements/executorSelect/ExecutorSelect";
import { UserSelectionRule } from "../../../../api/types/userSelectionRule";
import { useRootStore } from "../../../../hooks";
import { handleAddRuleBtnIsDisabled } from "./helpers";
import { areArraysEqual } from "../../../../helpers/arrayFunctions";

export const SettingUpAccessDialog: React.FC<ISettingUpAccessDialog> = (props) => {
  const { t } = useTranslation();
  const { orgchartStore } = useRootStore();

  const [value, setValue] = useState<UserSelectionRule[]>(props.currentRules ?? []);

  const handleGetTitle = () => {
    if (props.keyRuleTo === keysRulesFor.section) return t("ui:title.access_to_section");
    if (props.keyRuleTo === keysRulesFor.regulation) return t("ui:title.access_to_regulation");
  };

  useEffect(() => {
    // setValue([...value, ...(props.currentRules ?? [])]);
  }, [props.currentRules]);

  return (
    <Dialog title={handleGetTitle()} open={props.isOpen} onClose={props.onClose}>
      <ExecutorSelect
        value={value}
        onChange={setValue}
        buttonProps={{
          include: {
            variant: "filled",
            disabled: handleAddRuleBtnIsDisabled(
              value.filter((item) => !item.isExcluded),
              orgchartStore.getOrgchartsList
            ),
          },
          exclude: {
            variant: "filled",
            disabled: !!value
              .filter((item) => item.isExcluded)
              .find((item) => item.roleId == null && !item.rolePositionType),
          },
        }}
        customSubheaders={{
          include: (
            <Text size="18px" weight={500}>
              {props.keyRuleTo === keysRulesFor.section && t("ui:title.linked_roles_to_section")}
              {props.keyRuleTo === keysRulesFor.regulation && t("ui:title.linked_roles_to_regulation")}
            </Text>
          ),
          exclude: (
            <Text size="18px" weight={500}>
              {t("ui:title.expect")}
            </Text>
          ),
        }}
      />

      <DialogActions>
        <Button onClick={props.onClose} variant="text">
          {t("ui:button.cancel")}
        </Button>
        <Button
          // loading={props.isLoading}
          disabled={areArraysEqual(props.currentRules ?? [], value)}
          // disabled={props.isLoading || !props.hasChanges}
          onClick={() => props.onSave(value)}
          variant="filled"
        >
          {t("ui:button.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
