import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions, InputNumber } from "../../../uiKit";
import { TChartSettings } from "../../pages/dashboards/components/Metric2Dashboard/MetricToDashboard";

interface IMetricSettingsView {
  open: boolean;
  title?: string;
  onSuccess: () => void;
  onOpen(isOpen: boolean): void;
  chartSettings: TChartSettings;
  onChartSettingsChange: (value: TChartSettings) => void;
}

export const MetricSettingsDialogView = (props: IMetricSettingsView) => {
  const { t } = useTranslation();

  return (
    <Dialog width={450} open={props.open} onClose={() => props.onOpen(false)} title={props.title}>
      <div>
        <span>{t("ui:subheader.metric_settings")}</span>
        <div className="d-stack-row spacing-2 mt-2">
          <InputNumber
            value={props.chartSettings.min ?? undefined}
            onChange={(v) =>
              props.onChartSettingsChange({
                ...props.chartSettings,
                min: v == null ? null : Number(v),
              })
            }
            type="number"
            className="full-width"
            placeholder={t("ui:placeholder.min")}
          />
          <InputNumber
            value={props.chartSettings.max ?? undefined}
            onChange={(v) =>
              props.onChartSettingsChange({
                ...props.chartSettings,
                max: v == null ? null : Number(v),
              })
            }
            type="number"
            className="full-width"
            placeholder={t("ui:placeholder.max")}
          />
        </div>
      </div>
      <DialogActions>
        <Button onClick={() => props.onOpen(false)} variant="text">
          {t("ui:button.cancel")}
        </Button>
        <Button type="primary" onClick={() => props.onSuccess()} variant="filled">
          {t("ui:button.apply")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
