import React from "react";
import { SpinView } from "./SpinView";
import { SpinProps } from "antd";

interface ISpin extends SpinProps {
  //
}

export function Spin(props: ISpin) {
  // TODO: style={{ lineHeight: 1 }}
  return <SpinView {...props} />;
}
