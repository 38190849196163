import React from "react";
import { IListEducationSectionsView } from "./ListEducationSections.interface";
import { EducationSection } from "../educationSection/EducationSection";

export const ListEducationSectionsView = (props: IListEducationSectionsView) => {
  return (
    <div className="d-stack-column justify-start align-start ma-1 spacing-6">
      {props.items.map((item, index) => (
        <React.Fragment key={item.id}>
          <EducationSection key={item.key} educationItem={item} onClick={props.onClick} />
        </React.Fragment>
      ))}
    </div>
  );
};
