import React from "react";
import TopAppBarMenuView from "./TopAppBarMenuView";
import { UserDto } from "../../../../api";
import { LocalStorageHelpers, useRootStore } from "../../../../hooks";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { FiCpu, FiLogOut, FiSettings, FiUser } from "react-icons/all";
import { Icon } from "../../../uiKit";

export interface ITopAppBarMenu {
  userData: UserDto | null;
  menuItems: any[];
  isMenuOpen: boolean;
  menuAnchorEl: any;

  handleMenuButtonClick(event: any): void;
  handleMenuClose(): void;
}

function TopAppBarMenu() {
  const { t } = useTranslation();
  const { authStore } = useRootStore();
  const navigate = useNavigate();

  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(menuAnchorEl);

  const handleMenuButtonClick = (event: any) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleGoToCanny = () => {
    // const win = window.open("https://tonnus.canny.io/", "_blank");
    // win?.focus();
    handleMenuClose();
    navigate("/feedback");
  };

  const handleGoToProfile = async () => {
    handleMenuClose();
    navigate("/employee/" + authStore.getInitialInfo?.identity?.id);
  };

  const handleGoToSettings = async () => {
    handleMenuClose();
    navigate("/settings/profile");
  };

  const handleGoToSandbox = async () => {
    handleMenuClose();
    const link = ((LocalStorageHelpers.get("sandboxLink") as string) ?? "").trim();
    navigate(link.length > 0 ? link : "/sandbox");
  };

  const handleLogout = async () => {
    handleMenuClose();
    await authStore.unauthorize();
    navigate("/login");
  };

  let menuItems = [
    {
      id: 1,
      title: t("common:menu.top_bar_profile.profile_page"),
      action: handleGoToProfile,
      icon: <Icon component={() => <FiUser fontSize={16} stroke="var(--color-text-weakest)" />} />,
    },
    // { id: 4, title: t("common:menu.top_bar_profile.feedback"), action: handleGoToCanny },
    {
      id: 2,
      title: t("common:menu.top_bar_profile.profile_settings"),
      action: handleGoToSettings,
      icon: <Icon component={() => <FiSettings fontSize={16} stroke="var(--color-text-weakest)" />} />,
    },
    {
      id: 3,
      title: t("common:menu.top_bar_profile.logout"),
      action: handleLogout,
      icon: <Icon component={() => <FiLogOut fontSize={16} stroke="var(--color-text-weakest)" />} />,
    },
  ];

  if (window.location?.hostname == "localhost" || window.location?.hostname == "beta.tonnus.io") {
    menuItems = [
      {
        id: 0,
        title: t("common:page_title.sandbox"),
        action: handleGoToSandbox,
        icon: <Icon component={() => <FiCpu fontSize={16} stroke="var(--color-success-weaker)" />} />,
      },
      ...menuItems,
    ];
  }

  return (
    <TopAppBarMenuView
      menuItems={menuItems}
      userData={authStore.getInitialInfo?.identity ?? null}
      isMenuOpen={isMenuOpen}
      menuAnchorEl={menuAnchorEl}
      handleMenuButtonClick={handleMenuButtonClick}
      handleMenuClose={handleMenuClose}
    />
  );
}

export default observer(TopAppBarMenu);
