import React from "react";
import DeleteRecurringIssueDialogView from "./DeleteRecurringIssueDialogView";

interface IDeleteRecurringIssueDialog {
  open: boolean;

  onDelete(): void;
  onClose(): void;
}

function DeleteRecurringIssueDialog(props: IDeleteRecurringIssueDialog) {
  return <DeleteRecurringIssueDialogView {...props} />;
}

export default DeleteRecurringIssueDialog;
