import React from "react";
import { LayoutContentView } from "./LayoutContentView";

interface ILayoutContent {
  className?: string;
  children?: React.ReactNode;
}

export function LayoutContent(props: ILayoutContent) {
  return <LayoutContentView {...props} />;
}
