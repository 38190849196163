import { ToolbarButton } from "@udecode/plate";
import React, { FC } from "react";
import { BaseOperation } from "slate";

interface IUndoRedoButton {
  value: BaseOperation[][] | undefined;
  setValue: (() => void) | undefined;
  icon: React.ReactNode;
}

export const UndoRedoButton: FC<IUndoRedoButton> = (props) => {
  return <ToolbarButton icon={props.icon} active={props.value?.length !== 0} onMouseDown={props.setValue} />;
};
