import { createPluginFactory, onKeyDownToggleElement } from "@udecode/plate";
import { IFrameElement } from "./IFrameElement";

export const ELEMENT_IFRAME = "iframe";

export const createIFramePlugin = createPluginFactory({
  key: ELEMENT_IFRAME,
  isElement: true,
  isVoid: true,
  component: IFrameElement,
  handlers: { onKeyDown: onKeyDownToggleElement },
  then: (editor, { type }) => ({
    deserializeHtml: {
      rules: [
        {
          validNodeName: "iframe",
        },
      ],
      getNode: (el) => ({
        type,
        src: el.getAttribute("src"),
      }),
    },
  }),
});
