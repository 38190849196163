import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions } from "../../../uiKit";

interface IDrawerCloseConfirmationDialog {
  open: boolean;

  onClose: () => void;
  onAccept: () => void;
}

function DrawerCloseConfirmationDialog(props: IDrawerCloseConfirmationDialog) {
  const { t } = useTranslation();

  return (
    <Dialog title={t("ui:caption.do_not_save_recurring_task")} open={props.open} onClose={() => props.onClose()}>
      <DialogActions>
        <Button onClick={() => props.onClose()}> {t("ui:button.cancel")}</Button>
        <Button
          onClick={() => {
            props.onAccept();
            props.onClose();
          }}
          theme="error"
        >
          {t("ui:button.dont_save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DrawerCloseConfirmationDialog;
