import React from "react";
import "./TopAppBar.scss";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { Divider, Icon, IconButton, LayoutHeader } from "../../../uiKit";
import CompanySwitcher from "../companySwitcher/CompanySwitcher";
import TopAppBarMenu from "../topAppBarMenu/TopAppBarMenu";
import { GlobalSearch } from "./components/globalSearch/GlobalSearch";
import { FiSettings } from "@react-icons/all-files/fi/FiSettings";
import { NotificationsCenter } from "../../../service/notificationsCenter/NotificationsCenter";
import { FiCpu } from "@react-icons/all-files/fi/FiCpu";
import { FiDribbble } from "@react-icons/all-files/fi/FiDribbble";
import { ThemeSwitcher } from "../../pages/settings/themeSwitcher/ThemeSwitcher";
import { useIsMobile, useRootStore } from "../../../../hooks";
import { FaCat, FiMenu, FiX } from "react-icons/all";

function TopAppBarView() {
  const { appStore } = useRootStore();
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const onRedirectToSettings = () => navigate("/settings");
  /*const onRedirectToSandbox = () => {
    const link = ((LocalStorageHelpers.get("sandboxLink") as string) ?? "").trim();
    navigate(link.length > 0 ? link : "/sandbox");
  };*/

  return (
    <LayoutHeader className="top-app-bar">
      {isMobile && (
        <IconButton
          className="flex-shrink-0 ml-2 mr-2"
          icon={
            appStore.getLeftDrawerState == "__collapsed" ? (
              <Icon component={() => <FiMenu opacity={0.7} fontSize={20} />} />
            ) : (
              <Icon component={() => <FiX opacity={0.7} fontSize={20} />} />
            )
          }
          onClick={() =>
            appStore.setDrawerBehavior(
              appStore.getLeftDrawerState == "__collapsed" ? "alwaysExpanded" : "alwaysCollapsed"
            )
          }
        />
      )}
      <CompanySwitcher />
      <GlobalSearch />
      <div className="flex-grow-1" />
      {(window.location?.hostname == "localhost" || window.location?.hostname == "beta.tonnus.io") && (
        <div className="d-stack spacing-3 mr-3">
          <IconButton className="flex-shrink-0" onClick={() => navigate("/service/dev")}>
            <FaCat opacity={0.7} fontSize={20} color="darkKhaki" />
          </IconButton>
          <IconButton className="flex-shrink-0" onClick={() => navigate("/service/ui")}>
            <FiDribbble opacity={0.7} fontSize={20} color="mediumVioletRed" />
          </IconButton>
          <IconButton className="flex-shrink-0" onClick={() => navigate("/sandbox")}>
            <FiCpu opacity={0.7} fontSize={20} color="green" />
          </IconButton>
        </div>
      )}
      <div className="d-stack spacing-3 mr-3">
        <ThemeSwitcher displayMode="button" />
        <IconButton className="flex-shrink-0" onClick={onRedirectToSettings}>
          <FiSettings opacity={0.7} fontSize={20} />
        </IconButton>
        <NotificationsCenter />
      </div>
      <div style={{ height: "36px" }} className="mx-3">
        <Divider isVertical />
      </div>
      <TopAppBarMenu />
    </LayoutHeader>
  );
}

export default observer(TopAppBarView);
