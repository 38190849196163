import React, { useEffect, useRef, useState } from "react";
import { DriverRTCCallsContext, IDriverRTCCallsContext } from "../../../contexts/driverRTCCallsContext";
import { removeMultipleSpacesFromString } from "../../../helpers/stringFunctions";
import { useLocation } from "react-router-dom";
import "./DriverRTCCallsWrapper.scss";
import { api } from "../../../services";
import { useNotifier, useRootStore } from "../../../hooks";
import { observer } from "mobx-react-lite";

function DriverRTCCallsWrapperObserved({ children }: { children: React.ReactNode }) {
  const notifier = useNotifier();
  const { appStore, authStore } = useRootStore();
  const location = useLocation();

  const [isCallWidgetVisible, setIsCallWidgetVisible] = useState<boolean>(false);
  const isWidgetActivated = useRef<boolean>(false);
  // location.pathname

  const handleDriverRTCCallsContextStateReset = () =>
    setDriverRTCCallsContextState({ ...driverRTCCallsContextInitialState });

  const handleDriverRTCCallsContextStateUpdate = (state: IDriverRTCCallsContext) =>
    setDriverRTCCallsContextState({ ...driverRTCCallsContextInitialState, ...state });

  const makeCall = (phoneNumber: string) => {
    const buttonCallBtnDownElement = document.getElementsByClassName("zdrm-webphone-call-btn")[0] as HTMLDivElement;
    if (buttonCallBtnDownElement.getElementsByClassName("zdrm-webphone-call-btn-down").length != 0) {
      notifier.show({ message: "В данный момент уже осуществляется звонок", theme: "error" });
      return;
    }
    const normalizedPhoneNumber =
      "+" +
      removeMultipleSpacesFromString(phoneNumber.trim())
        .replace(" ", "")
        .replace(/[^0-9]/g, "");

    if (normalizedPhoneNumber.trim().length == 0) {
      return;
    }

    const phoneInput = document.getElementById("zdrm-webphone-phonenumber-input") as HTMLInputElement;
    phoneInput != null && (phoneInput.value = normalizedPhoneNumber);

    const callButton = document.getElementsByClassName("zdrm-webphone-call-btn")[0] as HTMLDivElement;
    callButton != null && callButton.click && callButton.click();
  };

  const driverRTCCallsContextInitialState: IDriverRTCCallsContext = {
    requestPhoneNumber: null,
    isDialing: false,

    updateState: handleDriverRTCCallsContextStateUpdate,
    resetState: handleDriverRTCCallsContextStateReset,
    makeCall: makeCall,
  };

  const initWidget = async () => {
    if (isWidgetActivated.current) return;
    if (
      !(
        window.location?.hostname == "localhost" ||
        window.location?.hostname == "beta.tonnus.io" ||
        window.location?.hostname == "rc.tonnus.io"
      )
    )
      return;
    isWidgetActivated.current = true;
    const internalNumbers = await api.telephony.getInternalNumbers();
    if (internalNumbers == null) return;
    const webRTCKey = await api.telephony.getWebRTCKey(internalNumbers.numbers[0]);
    if (webRTCKey == null) return;
    const Z_NUMBER = internalNumbers.pbx_id + "-" + internalNumbers.numbers[0];
    const Z_KEY = webRTCKey.key;

    // @ts-ignore
    zadarmaWidgetFn(
      Z_KEY,
      Z_NUMBER,
      "square" /*square|rounded*/,
      appStore.getAppLocale ?? "en" /*ru, en, es, fr, de, pl, uk*/,
      true,
      "{left:10px;top:5px;}"
    );

    // setTimeout(() => setWidgetDraggable(), 3000);

    // @ts-ignore
    console.log(zdrmWPhI, zdrmWebrtcPhone, zadarmaWidgetFn);
  };

  const dragWidgetState = useRef({
    x: 0,
    y: 0,
    mousedown: false,
  });

  const setWidgetDraggable = () => {
    const dragElement = document.getElementsByClassName("zdrm-webphone-numpad")[0] as HTMLDivElement;
    const draggableElement = document.getElementsByClassName("zdrm-fixed")[0] as HTMLDivElement;
    console.log(dragElement, draggableElement);

    window.addEventListener("mousemove", function (e) {
      dragWidgetState.current = {
        ...dragWidgetState.current,
        x: e.x,
        y: e.y,
      };
      if (dragWidgetState.current.mousedown) {
        draggableElement.style.cssText = "left:" + dragWidgetState.current.x + "px";
        draggableElement.style.cssText = "bottom:" + dragWidgetState.current.y + "px";
        // draggableElement.style.cssText = "bottom:" (dragWidgetState.current.y-75) "px";
      }
    });

    dragElement.addEventListener("mousedown", function (e) {
      dragWidgetState.current = {
        ...dragWidgetState.current,
        mousedown: true,
      };

      draggableElement.style.cssText = "left:" + dragWidgetState.current.x + "px";
      draggableElement.style.cssText = "bottom:" + dragWidgetState.current.y + "px";
    });

    dragElement.addEventListener("mouseup", function () {
      dragWidgetState.current = {
        ...dragWidgetState.current,
        mousedown: false,
      };
    });
  };

  const watchForCalls = () => {
    //
  };

  useEffect(() => {
    // if (authStore.getInitialInfo?.identity.)
    if (
      authStore.getInitialInfo?.menuItems?.some((i) => i.key == "driver") &&
      !(window.location.hostname == "localhost")
    ) {
      initWidget();
    }
  }, [authStore]);

  useEffect(() => {
    if (location.pathname == "/driver") setIsCallWidgetVisible(true);
    else setIsCallWidgetVisible(false);
  }, [location]);

  // useEffect(() => {
  //   window.onerror = function (msg, url, lineNo, columnNo, error) {
  //     console.log(msg, url, lineNo, columnNo, error);
  //     return false;
  //   };
  // }, []);

  const [driverRTCCallsContextState, setDriverRTCCallsContextState] = useState<IDriverRTCCallsContext | null>(
    driverRTCCallsContextInitialState
  );

  return <DriverRTCCallsContext.Provider value={driverRTCCallsContextState} children={children} />;
}

export const DriverRTCCallsWrapper = observer(DriverRTCCallsWrapperObserved);
