import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
// import { IoIosAdd } from "@react-icons/all-files/io/IoIosAdd";
import { ITransferRegulationsList } from "./CreateRegulationSectionForm.interface";
import { useNavigate } from "react-router-dom";
import { Button, Card, Divider, Empty, Select, Text } from "../../../uiKit";
import { AutocompleteModel } from "../../../../api";
import { ISelectItem } from "../../../uiKit/select/SelectView";
import { RegulationCard } from "./elements/regulationCard/RegulationCard";
import ScrollTrigger from "../../../service/scrollTrigger/ScrollTrigger";
import { SearchInputField } from "../../../elements/searchInputField/SearchInputField";

const generateSelectOption = (items: AutocompleteModel[]): ISelectItem[] =>
  items.map((item) => ({
    text: item?.text?.length ? item.text : "no name",
    value: item.id!,
  }));

export const TransferRegulationsList = (props: ITransferRegulationsList) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isIncludeInNewSection = (id: number): boolean => !!props.newRegulations.find((item) => item.id === id);

  const showFilterOptions: boolean =
    props.commonRegulations.length > 0 || !!props.activeSectionIdFilter || props.regulationSearchValue.length > 0;

  const handleRedirectToCreateRegulation = () => navigate("/policy/create-policy");

  const sectionSelectOptions: ISelectItem[] = useMemo(() => generateSelectOption(props.sections), [props.sections]);

  return (
    <Card
      size="default"
      title={t("ui:title.policies")}
      scrollStrategy="cardBody"
      bodyStyle={{ display: "flex", padding: 0 }}
    >
      <Card
        isShadowed={false}
        loading={props.isLoading}
        className="flex-grow-1 flex-shrink-1"
        style={{ flexBasis: "50%" }}
      >
        {/*<Empty className="my-4" />*/}
        {props.newRegulations.length > 0 ? (
          <div className="d-stack-column spacing-2">
            {props.newRegulations.map((item) => (
              <RegulationCard
                regulation={item}
                onClick={props.onRemoveNewRegulation}
                disabled={false}
                iconType="minus"
                key={item.id}
              />
            ))}
          </div>
        ) : (
          <div className="d-flex flex-column justify-center full-height" style={{ minHeight: "320px" }}>
            <Text
              size="16px"
              weight="bold"
              style={{ textAlign: "center" }}
              type="secondary"
              children={t("ui:label.add_first_regulation")}
            />
          </div>
        )}
      </Card>
      {/*<Divider style={{ height: "calc(100% - 16px)", alignSelf: "center" }} isVertical />*/}
      <Divider style={{ height: "auto" }} isVertical />
      <Card isShadowed={false} className="flex-grow-1 flex-shrink-1" style={{ flexBasis: "50%" }}>
        {showFilterOptions && (
          <div className="mb-2 d-stack-column spacing-2">
            <SearchInputField
              value={props.regulationSearchValue}
              setValue={props.onChangeRegulationSearchValue}
              // variant="standard"
              backgroundInherit
              placeholder={t("ui:placeholder.regulation_name")}
              styles={{ width: "100%" }}
            />
            <Select
              allowClear
              style={{ width: "100%" }}
              items={sectionSelectOptions}
              value={props.activeSectionIdFilter}
              onChange={props.onChangeActiveSectionFilter}
              placeholder={t("ui:placeholder.select_section")}
            />
          </div>
        )}
        <div>
          {props.commonRegulations.length > 0 ? (
            <div className="d-stack-column spacing-2">
              {props.commonRegulations.map((item) => (
                <RegulationCard
                  regulation={item}
                  onClick={props.onAppendNewRegulation}
                  disabled={isIncludeInNewSection(item.id!)}
                  iconType="plus"
                  key={item.id}
                />
              ))}
              {!props.regulationsIsUploaded && (
                <ScrollTrigger
                  fullWidth
                  disabled={props.regulationsIsUploaded}
                  onIntersection={props.onLoadNextRegulations}
                  marginTop={props.commonRegulations.length > 0}
                  hidden={props.regulationsIsUploaded}
                />
              )}
            </div>
          ) : props.regulationSearchValue.length === 0 ? (
            <div className="d-flex flex-column justify-center align-center full-height" style={{ minHeight: "320px" }}>
              <Button
                // icon={<IoIosAdd />}
                size="large"
                variant="filled"
                onClick={handleRedirectToCreateRegulation}
              >
                {t("ui:button.create_first_policy")}
              </Button>
            </div>
          ) : (
            <div
              className="full-width full-height d-stack-column justify-center align-center"
              style={{ minHeight: "320px" }}
            >
              <Empty />
            </div>
          )}
        </div>
      </Card>
    </Card>
  );
};
