import React from "react";
import "./Drawer.scss";
import { Drawer, DrawerProps } from "antd";
import clsx from "clsx";
import { FiX } from "@react-icons/all-files/fi/FiX";
import { IconButton } from "../general/iconButton/IconButton";

interface IDrawerView extends DrawerProps {
  //
}

export function DrawerView({ closable, extra, className, ...rest }: IDrawerView) {
  return (
    <Drawer
      headerStyle={{ display: !rest.title ? "none" : "flex" }}
      bodyStyle={{ padding: "16px" }}
      extra={
        <div className="d-flex flex-nowrap align-center">
          {extra}
          {closable && (
            <IconButton className="ml-2" onClick={rest.onClose}>
              <FiX size="24px" />
            </IconButton>
          )}
        </div>
      }
      className={clsx("uiKit-drawer", className ?? "")}
      closable={false}
      // open={open}
      {...rest}
    />
  );
}
