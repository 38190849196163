import * as React from "react";
import { ChangeEvent, forwardRef } from "react";

type Props = Readonly<{
  "data-test-id"?: string;
  accept?: string;
  label: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}>;

export const FileInput = forwardRef(
  ({ accept, label, onChange, "data-test-id": dataTestId }: Props, ref: any): JSX.Element => {
    return (
      <div>
        <input
          ref={ref}
          type="file"
          accept={accept}
          onChange={onChange}
          data-test-id={dataTestId}
          style={{ display: "none" }}
        />
      </div>
    );
  }
);
