import React, { useRef, useState } from "react";
import { TimePickerView } from "./TimePickerView";
import { PickerTimeProps } from "antd/es/date-picker/generatePicker";
import { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";
import { Button } from "../../../general/button/Button";

type TTimePicker = {
  readOnly?: boolean;
} & Omit<PickerTimeProps<Dayjs>, "picker">;

export function TimePicker({ value, onChange, onBlur, onSelect, ...rest }: TTimePicker) {
  const { t } = useTranslation();

  const currentValue = useRef<{
    value: Dayjs | undefined | null;
    valueString: string;
  }>({ value: null, valueString: "" });
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOpenChange = (val: boolean) => {
    if (!isOpen && val === true) {
      currentValue.current = {
        ...currentValue.current,
        value: value,
      };
      setIsOpen(val);
      rest.onOpenChange && rest.onOpenChange(true);
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    onBlur && onBlur(e);
    if (!isOpen) return;
    onChange && onChange(currentValue.current?.value ?? null, currentValue.current.valueString);
    setIsOpen(false);
    rest.onOpenChange && rest.onOpenChange(false);
    currentValue.current = {
      ...currentValue.current,
      value: null,
      valueString: "",
    };
  };

  const handleSelect = (val: Dayjs | null) => {
    currentValue.current = {
      ...currentValue.current,
      value: val,
      valueString: val?.format(typeof rest.format === "string" ? rest.format : "HH:mm") ?? "",
    };
    onSelect && val != null && onSelect(val);
  };

  const handleChange = (val: Dayjs | null, timeString: string) => {
    currentValue.current = {
      ...currentValue.current,
      value: val,
      valueString: timeString,
    };
  };

  const handleCancel = () => {
    setIsOpen(false);
    rest.onOpenChange && rest.onOpenChange(false);
    currentValue.current = {
      ...currentValue.current,
      value: null,
      valueString: "",
    };
  };

  return (
    <TimePickerView
      format="HH:mm"
      showNow={false}
      {...rest}
      picker="time"
      defaultPickerValue={value ?? undefined}
      open={isOpen}
      value={value}
      onOpenChange={handleOpenChange}
      onChange={onChange}
      onBlur={handleBlur}
      onSelect={handleSelect}
      renderExtraFooter={() => (
        <div className="px-2">
          <Button size="small" className="full-width" onClick={handleCancel} children={t("ui:button.cancel")} />
        </div>
      )}
    />
  );
}
