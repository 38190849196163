import React from "react";
import { IFindRegulationSectionDialogView } from "./FindRegulationSectionDialog.interface";
import { useTranslation } from "react-i18next";
import { SearchInputField } from "../../../elements/searchInputField/SearchInputField";
import ScrollTrigger from "../../../service/scrollTrigger/ScrollTrigger";
import { Button, Card, Dialog, DialogActions } from "../../../uiKit";
import { EmptyBlock } from "../../layouts/emptyBlock/EmptyBlock";

export const FindRegulationSectionDialogView = ({
  open,
  onClose,
  sectionName,
  onChangeSectionName,
  onSelectSection,
  sections,
  isLoading,
  loadNextSections,
  isDone,
}: IFindRegulationSectionDialogView) => {
  const { t } = useTranslation();

  const handleChangeSectionName = (event: any) => onChangeSectionName(event.target.value as string);

  return (
    <Dialog
      width={400}
      bodyStyle={{ padding: 16, paddingTop: "16px" }}
      title={t("ui:title.select_section")}
      open={open}
      onClose={onClose}
    >
      <div className="d-stack-column justify-start align-start spacing-4">
        <div className="px-2 full-width">
          <SearchInputField
            styles={{ marginTop: "4px" }}
            value={sectionName ?? ""}
            placeholder={t("ui:placeholder.section_name")}
            setValue={onChangeSectionName}
          />
        </div>
        <div
          className="d-stack-column justify-start align-start spacing-2 pr-2 pl-2"
          style={{ width: "100%", maxHeight: "500px", overflowY: "auto", position: "relative" }}
        >
          {sections.length > 0 ? (
            <>
              {sections?.map((item) => (
                <Card
                  key={item.id}
                  onClick={() => onSelectSection(item)}
                  style={{ width: "100%", borderRadius: "5px" }}
                  hoverable
                  bordered
                >
                  <h4 style={{ margin: 0 }}>
                    {item && item.text && item.text.length > 0 ? item.text : t("common:misc.no_name")}
                  </h4>
                </Card>
              ))}
              {!isLoading && (
                <ScrollTrigger
                  disabled={isDone}
                  onIntersection={loadNextSections}
                  marginTop={sections.length > 0}
                  hidden={isDone}
                />
              )}
            </>
          ) : (
            <EmptyBlock />
          )}
        </div>
      </div>
      <DialogActions>
        <Button onClick={onClose} variant="text">
          {t("ui:button.cancel")}
        </Button>
        {/*<Button onClick={() => onSelectSection(undefined)} variant="text">*/}
        {/*  {t("ui:button.reset")}*/}
        {/*</Button>*/}
      </DialogActions>
    </Dialog>
  );
};
