import React, { ReactNode, useEffect, useRef, useState } from "react";
import "./MetricToDashboard.scss";
import { UpdateMetricDialog } from "../../../../dialogs/updateMetricDialog/UpdateMetricDialog";
import { api } from "../../../../../../services";
import { LocalStorageHelpers, useNotifier } from "../../../../../../hooks";
import { useTranslation } from "react-i18next";
import { Card, Dropdown, Icon, IconButton, Popover, Text, Tooltip } from "../../../../../uiKit";
import DeleteMetricOrDashboardDialog from "../../../../dialogs/deleteMetricOrDashboardDialog/DeleteMetricOrDashboardDialog";
import { MetricSettingsDialog } from "../../../../dialogs/metricSettingsDialog/MetricSettingsDialog";
import { DashboardChart, TDashboardChartDataItem } from "../../dashboardChart/DashboardChart";
import { MetricDividerDto, MetricGraphPeriodDto, MetricReadDto, MetricValueDto } from "../../../../../../api";
import { useParams } from "react-router-dom";
import { TChartSettings } from "./MetricToDashboard";
import { dashboardSectionAndRoutesKeys, dashboardSections } from "../../misc/constants/routesKeys";
import { getDashboardSection } from "../../misc/helpers/urlFunctions";
import { FiHelpCircle } from "@react-icons/all-files/fi/FiHelpCircle";
import { FiMoreVertical } from "@react-icons/all-files/fi/FiMoreVertical";
import { MetricDataDialog } from "../../../../dialogs/metricDataDialog/MetricDataDialog";
import { removeDuplicatesFromArrayByKey } from "../../../../../../helpers/arrayFunctions";
import { FiSave } from "react-icons/all";

interface IMetricToDashboardView {
  // metric?: MetricSource2UserForDashboardDto;
  // metricSource2UserId?: number;
  metric: MetricReadDto;
  graphId?: number;
  deleteMetric: (id: number) => void;
  chartData: TDashboardChartDataItem[];
  handleRestartLoad?: () => void;
  onDropdownKeyChange: (key: string) => void;
  openDeleteMetricDialog: boolean;
  onOpenDeleteMetricDialogChange: (isOpen: boolean) => void;
  openMetricSettingsDialog: boolean;
  onOpenMetricSettingsDialogChange: (isOpen: boolean) => void;
  openMetricDataDialog: boolean;
  onOpenMetricDataDialogChange: (isOpen: boolean) => void;
  popoverContent: ReactNode;
  chartSettings: TChartSettings;
  onChartSettingsChange: (value: TChartSettings) => void;
  onChartDataChange: (value: TDashboardChartDataItem[]) => void;
  onSuccess: () => void;
}

export const MetricToDashboardView = (props: IMetricToDashboardView) => {
  const notifier = useNotifier();
  const { t } = useTranslation();
  const { uri } = useParams();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [chartData, setChartData] = useState<TDashboardChartDataItem[]>(props.chartData ?? []);
  const [yDividers, setYDividers] = useState<MetricDividerDto[]>(
    props.metric.renderType == 1
      ? props.metric?.yDividers ?? []
      : props.metric?.graphs?.find((g) => g.id == props.graphId)?.yDividers ?? []
  );
  const [currentMetricDataToUpdate, setCurrentMetricDataToUpdate] = useState<TDashboardChartDataItem | null>(null);
  const [metricChangedFromDataDialogState, setMetricChangedFromDataDialogState] = useState([]);

  const handleUpdateMetricData = async () => {
    // Type: 1 - value, 2 - quota
    setIsOpen(false);
    // if (currentMetricDataToUpdate) setMetricData([...metricData, currentMetricDataToUpdate]);
    if (currentMetricDataToUpdate == null) return;
    setIsLoading(true);
    let arr2send: MetricValueDto[] = [];
    if (props.metric.renderType === 0 || !(props.metric.renderType === 1 && (props.metric.graphs ?? []).length > 1)) {
      arr2send = [
        ...arr2send,
        {
          metricSource2UserId: currentMetricDataToUpdate.charts.line[0]?.id as number,
          value: currentMetricDataToUpdate.charts.quota,
          date: currentMetricDataToUpdate.dateISO,
          type: 2,
        },
      ];
    }
    arr2send = [
      ...arr2send,
      ...currentMetricDataToUpdate.charts.line.map((l) => ({
        metricSource2UserId: l.id,
        value: l.value,
        date: currentMetricDataToUpdate.dateISO,
        type: 1,
      })),
    ];
    const r = await api.metric.updateValueOrQuota(arr2send);
    if (r == null) {
      setIsLoading(false);
      notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
      return;
    }
    setChartData(chartData.map((i) => (i.id == currentMetricDataToUpdate.id ? currentMetricDataToUpdate : i)));
    await handleUpdateYDividers();
    setIsLoading(false);
  };

  const handleUpdateYDividers = async () => {
    const r = await api.metricRead.getById(props.metric?.id!);
    if (r == null) return;
    setYDividers(
      r.renderType == 1 ? r?.yDividers ?? [] : r?.graphs?.find((g) => g.id == props.graphId)?.yDividers ?? []
    );
  };

  const generateDataToChangeByItemId = (itemId: number): TDashboardChartDataItem | null => {
    const el = chartData.find((d) => d.id == itemId);
    return el != null ? { ...el } : null;
  };

  const handleGetDropdownItems = () => {
    return [
      { key: "settings", text: t("common:menu.metric_settings_dropdown.settings") },
      { key: "data", text: t("common:menu.metric_settings_dropdown.data") },
      { key: "delete", text: t("common:menu.metric_settings_dropdown.delete") },
    ].filter((el) =>
      dashboardSections.includes(getDashboardSection(uri) as dashboardSectionAndRoutesKeys) ? el.key != "delete" : true
    );
  };

  const generateUpdatedChartData = (metric: MetricReadDto): TDashboardChartDataItem[] => {
    const graphPeriodsArray: MetricGraphPeriodDto[] = removeDuplicatesFromArrayByKey(
      (metric.graphs?.map((g) => g.periods)?.flat() ?? []) as MetricGraphPeriodDto[],
      "label"
    )
      .filter((g: MetricGraphPeriodDto) => g.date != null)
      .sort(function (a: MetricGraphPeriodDto, b: MetricGraphPeriodDto) {
        return a.date! < b.date! ? -1 : a.date! > b.date! ? 1 : 0;
      });

    return graphPeriodsArray.map((period) => {
      return {
        id: Number(new Date(period.date!).getTime()),
        // id: Number(new Date(p.date!).getDate() + new Date(p.date!).getMonth() + new Date(p.date!).getFullYear()),
        dateISO: period.date as string,
        label: period.label as string,
        charts: {
          quota:
            props.graphId != null
              ? metric.graphs?.find((g) => g.id == props.graphId)?.periods?.find((_p) => _p.label == period.label)
                  ?.quota?.quota ?? null
              : null,
          line:
            props.graphId != null
              ? [
                  {
                    id: metric.graphs?.find((g) => g.id == props.graphId)?.metricSource2UserId!,
                    name: metric.graphs?.find((g) => g.id == props.graphId)?.name ?? "",
                    value:
                      metric.graphs?.find((g) => g.id == props.graphId)?.periods?.find((_p) => _p.label == period.label)
                        ?.value?.value ?? null,
                  },
                ]
              : props.metric.graphs?.map((g) => ({
                  id: g.metricSource2UserId!,
                  name: g.name ?? "",
                  value: g.periods?.find((_p) => _p.label == period.label)?.value?.value ?? null,
                })) ?? [],
        },
      };
    });
  };

  const handleMetricChangeFromDataDialog = async (updatedMetric: MetricReadDto) => {
    if (updatedMetric == null) return;
    const r = await api.metricRead.getById(updatedMetric.id!);
    if (r == null) {
      return;
    }
    setIsLoading(true);
    setYDividers(
      r.renderType == 1 ? r?.yDividers ?? [] : r?.graphs?.find((g) => g.id == props.graphId)?.yDividers ?? []
    );
    const newChartData = generateUpdatedChartData(r);
    setChartData(newChartData);
    props.onChartDataChange(newChartData);
    setIsLoading(false);
  };

  const isChartAvailableToRestart = useRef(true);

  const handleReloadAfterDataChange = () => {
    isChartAvailableToRestart.current = true;
    props.handleRestartLoad && props.handleRestartLoad();
  };

  useEffect(() => {
    if (isChartAvailableToRestart.current) {
      setChartData(props.chartData);
    }
  }, [props.chartData]);

  useEffect(() => {
    setYDividers(
      props.metric.renderType == 1
        ? props.metric?.yDividers ?? []
        : props.metric?.graphs?.find((g) => g.id == props.graphId)?.yDividers ?? []
    );
  }, [props.metric]);

  return (
    <>
      <UpdateMetricDialog
        onUpdateClick={handleUpdateMetricData}
        onToggleDialogVisibility={setIsOpen}
        open={isOpen}
        onChangeCurrentMetricData={setCurrentMetricDataToUpdate}
        value={currentMetricDataToUpdate}
        metricData={{
          name: props?.metric?.name ?? null,
          date: currentMetricDataToUpdate?.label ?? null,
          isQuotaAvailable:
            props.metric.renderType === 0 || !(props.metric.renderType === 1 && (props.metric.graphs ?? []).length > 1),
        }}
      />
      <DeleteMetricOrDashboardDialog
        open={props.openDeleteMetricDialog}
        onOpen={props.onOpenDeleteMetricDialogChange}
        onDelete={() => {
          // props.deleteMetric(props.metric?.renderType === 1 ? props.metric?.id! : props.graphId!);
          props.deleteMetric(
            props.metric?.renderType === 1
              ? props.metric?.id!
              : props.metric.graphs?.find((g) => g.id == props.graphId)?.metricSource2UserId!
          );
          LocalStorageHelpers.set(
            String(
              props.metric?.renderType === 1
                ? `${props.metric?.id}/${props.metric?.graphs?.[0]?.id}`
                : `${props.metric?.id}/${props.graphId}`
            ),
            null
          );
          props.onOpenDeleteMetricDialogChange(false);
        }}
        title={`${t("ui:title.delete_metric_dialog_title")} "${props.metric?.name}"`}
        description={t("ui:text.delete_metric_dialog_warning")}
      />
      <MetricSettingsDialog
        open={props.openMetricSettingsDialog}
        onOpen={props.onOpenMetricSettingsDialogChange}
        onSuccess={props.onSuccess}
        title={t("ui:title.metric_settings")}
        chartSettings={props.chartSettings}
        onChartSettingsChange={props.onChartSettingsChange}
      />
      <MetricDataDialog
        isOpen={props.openMetricDataDialog}
        metric={props.metric}
        graphId={props.graphId}
        onOpen={props.onOpenMetricDataDialogChange}
        onSave={(updatedMetric) => {
          // handleReloadAfterDataChange();
          handleMetricChangeFromDataDialog(updatedMetric);
          props.onOpenMetricDataDialogChange(false);
        }}
      />
      {/*<Spin spinning={true}>*/}
      <Card
        className="metric-to-dashboard"
        // bodyStyle={{ padding: 0 }}
      >
        <div className="metric-to-dashboard__container__header d-flex flex-nowrap align-center">
          <Tooltip title={props.metric.nameFormatted}>
            {/*<Tooltip title={props?.metric?.user2Role?.user?.name}>*/}
            <Text className="metric-to-dashboard__container__title">
              {props.metric.nameFormatted}
              {/*{truncateString(getMetricNameWithPrefix(props.metric, t("ui:title.metric_prefix")) ?? "", 50, "end")}*/}
            </Text>
          </Tooltip>
          <Popover
            content={props.popoverContent}
            placement="bottom"
            arrowPointAtCenter={false}
            trigger="click"
            // overlayInnerStyle={{ padding: "16px" }}
          >
            <IconButton
              size="small"
              className="pa-0 ml-1"
              shape="circle"
              type="link"
              icon={<Icon component={() => <FiHelpCircle />} />}
            />
          </Popover>
          {isLoading && false && (
            <Icon
              className="ml-auto blink"
              style={{ position: "absolute", left: "12px", bottom: "12px" }}
              component={() => <FiSave stroke="var(--color-success-base)" />}
            />
          )}
          <Dropdown
            items={[
              ...handleGetDropdownItems().map((i) => ({
                key: i.key,
                text: i.text,
                onClick: () => props.onDropdownKeyChange(i.key),
              })),
            ]}
            trigger={["click"]}
            overlayClassName="orgchart_switcher-overlay"
          >
            <IconButton
              size="small"
              className="pa-0 ml-auto"
              shape="circle"
              type="link"
              icon={<Icon component={() => <FiMoreVertical />} />}
            />
          </Dropdown>
        </div>
        <div className="metric-to-dashboard__container__content mt-1">
          {!!props.metric && (
            <DashboardChart
              data={chartData}
              metricData={props.metric}
              yDividers={yDividers}
              metricId={props.metric.id as number}
              graphId={props.graphId}
              isReversed={props.metric?.isReversed}
              isReadOnly={
                props.graphId != null
                  ? props.metric?.graphs?.find((c) => c.id == props.graphId)?.canBeEdited != true
                  : props.metric.graphs?.some((c) => c.canBeEdited == false)
                // : props.metric.graphs?.every((c) => c.canBeEdited == false)
              }
              onChange={(itemId: number) => {
                isChartAvailableToRestart.current = false;
                setCurrentMetricDataToUpdate(generateDataToChangeByItemId(itemId));
                setIsOpen(true);
              }}
              viewSettings={props.chartSettings}
            />
          )}
        </div>
      </Card>
      {/*</Spin>*/}
    </>
  );
};
