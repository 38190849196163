import React from "react";
import "./Table.scss";
import { Table, TableProps } from "antd";
import clsx from "clsx";

interface ITableView extends TableProps<any> {
  //
}

export function TableView({ className, ...rest }: ITableView) {
  return <Table className={clsx("uiKit-table", className ?? "")} {...rest} />;
}
