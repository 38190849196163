import React from "react";
import "./OrgchartTypeSelectForm.scss";
import templateDefault from "../../../../../assets/images/orgchart-template-default.png";
import templateClassic from "../../../../../assets/images/orgchart-template-classic.png";
import { useTranslation } from "react-i18next";
import { Button, Card, Text } from "../../../../uiKit";
import { useRootStore } from "../../../../../hooks";
import { observer } from "mobx-react-lite";

interface IOrgchartTypeSelectFormView {
  selectedOrgchartType: "d" | "c" | null;
  handleSave: () => void;
  setSelectedOrgchartType: (type: "d" | "c" | null) => void;
}

function OrgchartTypeSelectFormView(props: IOrgchartTypeSelectFormView) {
  const { t } = useTranslation();
  const { authStore } = useRootStore();

  return (
    <div className="orgchart-type-select-form">
      <div className="__header">
        <span className="__title">{t("parse:orgchart1")}</span>
        {(authStore.getInitialInfo?.orgcharts?.filter((o) => !o.isEmpty) ?? []).length == 0 && (
          <span className="__subtitle">{t("parse:orgchart2")}</span>
        )}
      </div>
      <div className="__content d-stack flex-nowrap align-center justify-center spacing-4 my-12">
        <Card
          onClick={() => props.setSelectedOrgchartType("d")}
          className={`orgchart-type-card ${props.selectedOrgchartType == "d" ? "__selected" : ""}`}
          clickable
        >
          <div className="orgchart-type-card__inner d-stack-column spacing-3 align-center justify-center">
            <img draggable="false" src={templateDefault} />
            <Text children={t("parse:orgchart_type.default")} />
          </div>
        </Card>
        <Card
          onClick={() => props.setSelectedOrgchartType("c")}
          className={`orgchart-type-card ${props.selectedOrgchartType == "c" ? "__selected" : ""}`}
          clickable
        >
          <div className="orgchart-type-card__inner d-stack-column spacing-3 align-center justify-center">
            <img draggable="false" src={templateClassic} />
            <Text children={t("parse:orgchart_type.classic")} />
          </div>
        </Card>
      </div>
      <div className="__actions">
        <Button
          size="large"
          onClick={() => props.handleSave()}
          disabled={props.selectedOrgchartType == null}
          variant="filled"
        >
          {t("ui:button.create")}
        </Button>
      </div>
    </div>
  );
}

export default observer(OrgchartTypeSelectFormView);
