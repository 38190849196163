import React from "react";
import { IListUsersDialog } from "./ListUsersDialog.interface";
import { EmptyBlock } from "../../layouts/emptyBlock/EmptyBlock";
import { Button, Dialog, DialogActions } from "../../../uiKit";
import { useTranslation } from "react-i18next";
import { UserInfo } from "../../../elements/userInfo/UserInfo";

export const ListUsersDialogView: React.FC<IListUsersDialog> = (props) => {
  const { t } = useTranslation();

  return (
    <Dialog open={props.open} onClose={props.onClose} title={props.title}>
      {props.users?.length === 0 ? (
        <EmptyBlock />
      ) : (
        <div className="d-stack-column spacing-4 justify-center align-start">
          {props.users?.map((user) => (
            <UserInfo nameStyle={{ fontWeight: "bold" }} avatarSize={25} user={user} />
          ))}
        </div>
      )}
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t("ui:button.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
