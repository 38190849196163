import { AxiosRequestConfig } from "axios";
import { RootStore } from "../stores/rootStore";
import { client } from "./client";

interface IInterceptorsParams {
  accessToken?: string;
  companyId?: number;
  appLocale?: string;
}

export default {
  setup: (store: RootStore, params?: IInterceptorsParams) => {
    client.interceptors.request.use((config: AxiosRequestConfig) => {
      if (config && config.headers) {
        // SET ACCESS TOKEN HEADER
        if (params?.accessToken != null) {
          config.headers.Authorization = "Bearer " + params.accessToken;
        } else if (store.authStore.getAccessToken) {
          config.headers.Authorization = "Bearer " + store.authStore.getAccessToken;
        }

        // SET COMPANY ID HEADER
        if (params?.companyId != null) {
          config.headers.CurrentCompanyId = params.companyId.toString();
        } else if (store.authStore.getCurrentCompanyId) {
          config.headers.CurrentCompanyId = store.authStore.getCurrentCompanyId.toString();
        }

        // SET APP LOCALE HEADER
        if (params?.appLocale != null) {
          config.headers.Culture = params.appLocale.toString();
        } else if (store.appStore.getAppLocale) {
          config.headers.Culture = store.appStore.getAppLocale.toString();
        }
      }
      return config;
    });
  },
};
