import React, { useState } from "react";
import WatchUserCardDialogView from "./WatchUserCardDialogView";
import { useApi } from "../../../../hooks";
import { api } from "../../../../services";

interface IWatchUserCardDialogView {
  open: boolean;

  onClose(): void;
  onAccept(): void;
}

function WatchUserCardDialog(props: IWatchUserCardDialogView) {
  const [userId, setUserId] = useState<number | undefined>(undefined);
  const allUsers = useApi(() => api.user.getAll({ ignoreContext: true, pageSize: 1000 }));

  const handleClose = () => {
    setUserId(0);
    props.onClose();
  };

  const handleAccept = async () => {
    openInNewTab("/driver/user/" + userId);
    handleClose();
  };

  const openInNewTab = (url: string): void => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <WatchUserCardDialogView
      {...props}
      onClose={handleClose}
      onAccept={handleAccept}
      userId={userId}
      setUserId={setUserId}
      allUsers={allUsers.value?.items ?? []}
    />
  );
}

export default WatchUserCardDialog;
