import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { RegulationContentQuestionAnswerDto, RegulationContentQuestionDto } from "../../../../api";
import { IdGenerator } from "../../../../helpers/idGenerator";
import { Question } from "./Question";
import { IQuestionGenerator } from "./CreateTestsForm.interface";
import {
  onChangeAnswerName,
  onChangeAnswersIsRight,
  onChangeQuestionName,
  onDeleteAnswersByQestionId,
  onDeleteQestion,
  onGetAnswersByQestionId,
  onReplaceAnswersByQestionId,
  resetIds,
} from "./helpers";
import { Button } from "../../../uiKit";

const questionIdQuestionGenerator = new IdGenerator(1);
const answerIdAnswerGenerator = new IdGenerator(1);

export const QuestionGenerator: React.FC<IQuestionGenerator> = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const [questions, setQuestions] = useState<RegulationContentQuestionDto[] | undefined>(undefined);

  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const handleMarkChanges = () => !hasChanges && setHasChanges(true);

  const handleSaveChanges = () => {
    props.onSaveChanges && props.onSaveChanges(resetIds(questions ?? []));
  };

  const handleResetQuestions = () => setQuestions([]);

  const handleIncrementQuestionId = () => questionIdQuestionGenerator.incrementId();
  const handleIncrementAnswerId = () => answerIdAnswerGenerator.incrementId();

  const isDisabledAddButton = (): boolean => {
    if (!((questions ?? []).length > 0)) return false;
    else return !(questions ?? [])[(questions ?? []).length - 1]!.answers?.length;
  };

  const handleDeleteQuestion = (id: number) => {
    setQuestions((prev) => onDeleteQestion(prev ?? [], id));
    handleMarkChanges();
  };

  const handleCreateQuestion = () => {
    const emptyQuestion: RegulationContentQuestionDto = {
      id: questionIdQuestionGenerator.getId,
      // name: `${questions.length + 1}.`,
      name: "",
      regulationContentId: props.regulationContentId,
      answers: [],
    };
    handleIncrementQuestionId();
    setQuestions((prev) => [...(prev ?? []), emptyQuestion]);
    handleMarkChanges();
  };

  const handleChangeQuestionName = (newValue: string, id: number) => {
    setQuestions((prev) => onChangeQuestionName(prev ?? [], newValue, id));
    handleMarkChanges();
  };

  const handleAppendAnswer = (questionId: number, newAnswer: RegulationContentQuestionAnswerDto) => {
    const currentAnswers = onGetAnswersByQestionId(questionId, questions ?? []);
    const newQuestion = onReplaceAnswersByQestionId(questionId, questions ?? [], [...currentAnswers, newAnswer]);
    setQuestions(newQuestion);
    handleMarkChanges();
  };

  const handleDeleteAnswer = (questionId: number, answerId: number) => {
    // const currentAnswers = onGetAnswersByQuestionId(questionId,questions);
    const newQuestion = onDeleteAnswersByQestionId(questionId, questions ?? [], answerId);
    setQuestions(newQuestion);
    handleMarkChanges();
  };

  const handleChangeAnswerName = (newValue: string, questionId: number, answerId: number) => {
    const currentAnswers = onGetAnswersByQestionId(questionId, questions ?? []);
    const newAnswers = onChangeAnswerName(currentAnswers, newValue, answerId);
    const newQuestion = onReplaceAnswersByQestionId(questionId, questions ?? [], newAnswers);
    setQuestions(newQuestion);
    handleMarkChanges();
  };

  const handleChangeAnswerRight = (newValue: boolean, questionId: number, answerId: number) => {
    const currentAnswers = onGetAnswersByQestionId(questionId, questions ?? []);
    const newAnswers = onChangeAnswersIsRight(currentAnswers, newValue, answerId);
    const newQuestion = onReplaceAnswersByQestionId(questionId, questions ?? [], newAnswers);
    setQuestions(newQuestion);
    handleMarkChanges();
  };

  const handleAppendEmptyAnswer = (questionId: number) => {
    const emptyAnswer: RegulationContentQuestionAnswerDto = {
      id: answerIdAnswerGenerator.getId,
      name: "",
      questionId: questionId,
      isRight: false,
    };
    handleIncrementAnswerId();
    handleAppendAnswer(questionId, emptyAnswer);
    handleMarkChanges();
  };

  useImperativeHandle(ref, () => ({
    test: questions ?? [],
    hasChanges: hasChanges,
  }));

  useEffect(() => {
    if (props?.defaultQuestions && props?.defaultQuestions?.length > 0) return setQuestions(props.defaultQuestions);
    if(!props.noImmediatelyCreateQuestion) handleCreateQuestion();
  }, []);

  useEffect(() => {
    props.onChangeHasChanges && hasChanges && props.onChangeHasChanges(true);
  }, [hasChanges]);

  useEffect(() => {
    if (questions?.length === 0) {
      hasChanges && setHasChanges(false);
      props.onClose();
    }
  }, [questions]);

  return (
    <div style={{ width: "100%" }}>
      <div className="d-stack-column spacing-2 justify-start align-start">
        {(questions ?? []).map((question) => (
          <>
            <Question
              key={question.id!}
              questionId={question.id!}
              name={question.name ?? ""}
              answers={question?.answers ?? []}
              onChangeQuestionName={handleChangeQuestionName}
              onDeleteQuestion={handleDeleteQuestion}
              onAppendEmptyAnswer={handleAppendEmptyAnswer}
              onChangeAnswerRight={handleChangeAnswerRight}
              onChangeAnswerName={handleChangeAnswerName}
              onDeleteAnswer={handleDeleteAnswer}
            />
          </>
        ))}
      </div>
      <div className="d-stack-row spacing-2 justify-start align-center" style={{ marginTop: 1 }} >
        <Button variant="filled" disabled={isDisabledAddButton()} onClick={handleCreateQuestion}>
          {t("ui:button.add_question")}
        </Button>
      </div>
    </div>
  );
});
