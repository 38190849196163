import React, {forwardRef} from "react";
import {IActivateMessengerBotDialog} from "./ActivateMessengerBotDialog.interface";
import {ActivateMessengerBotViewDialog} from "./ActivateMessengerBotViewDialog";
import {api} from "../../../../services";
import {useNotifier} from "../../../../hooks";
import {useTranslation} from "react-i18next";

export const ActivateMessengerBotDialog = forwardRef((props: IActivateMessengerBotDialog,ref) => {

    const { t } = useTranslation();
    const notifier = useNotifier();

    const handleApiError = () => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });

    const handleVerificationCode = async (code: number | string) => {
        const r = await api.companyGroup.verificationCode(code);
        if(r == null) handleApiError();
        else props.onClose();
    }

    return (
        <ActivateMessengerBotViewDialog
            ref={ref}
            isOpen={props.isOpen}
            onClose={props.onClose}
            onVerificationCode={handleVerificationCode}
            onActivateBot={props.onActivateBot}
            withTimer={props.withTimer}
            timerValueMinute={props.timerValueMinute}
            codeIsNumberOnly={props.codeIsNumberOnly}
            defaultCodeLength={props.defaultCodeLength}
        />
    );
});

