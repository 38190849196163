import React from "react";
import "./OrgchartSortDialog.scss";
import { useTranslation } from "react-i18next";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FiMove } from "@react-icons/all-files/fi/FiMove";
import { RoleDto } from "../../../../api";
import { Button, Card, Dialog, DialogActions, Divider, Icon, Text } from "../../../uiKit";
import { FiStar } from "react-icons/all";

export interface IOrgchartSortDialogView {
  allRoles: RoleDto[];
  rolesState: (RoleDto & { idx: number })[];
  roleId: number;

  onDragEnd(result: any): void;
  onSave(): void;
  onClose(): void;
}

function CurrentFunctionCard({ role }: { role?: RoleDto }) {
  return (
    <>
      <Card key="main-role" className="list-role-draggable-card__disabled" variant="secondary" isShadowed={false}>
        <div className="d-stack spacing-3">
          <div>
            <Icon component={() => <FiStar size={20} color="var(--color-primary-base)" />} />
          </div>
          <div className="d-stack-column spacing-1">
            <Text weight="bold" children={role?.name} />
            <Text weight={500} type="secondary" children={role?.awaitedResult} />
          </div>
        </div>
      </Card>
      <Divider className="my-2" />
    </>
  );
}

function OrgchartSortDialogView(props: IOrgchartSortDialogView) {
  const { t } = useTranslation();

  return (
    <Dialog title={t("ui:title.function_sort")} open={true} onClose={() => props.onClose()}>
      <DragDropContext onDragEnd={props.onDragEnd}>
        <div>
          <CurrentFunctionCard role={props.allRoles?.find((role) => role.id == props.roleId)} />
          <Droppable droppableId="droppable-list">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {props.rolesState
                  .filter((rS) => rS != null)
                  .map((rS, index) => (
                    <Draggable key={rS.id} draggableId={(rS.id as number).toString()} index={index}>
                      {(provided, snapshot) => (
                        <div
                          className={"mt-2"}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{ ...provided.draggableProps.style }}
                        >
                          <Card
                            key="main-role"
                            className="list-role-draggable-card"
                            variant="secondary"
                            isShadowed={false}
                          >
                            <div className="d-stack spacing-3">
                              <div>
                                <Icon component={() => <FiMove size={20} color="var(--color-primary-base)" />} />
                              </div>
                              <div className="d-stack-column spacing-1">
                                <Text weight="bold" children={rS?.name} />
                                <Text weight={500} type="secondary" children={rS?.awaitedResult} />
                              </div>
                            </div>
                          </Card>
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
      <DialogActions>
        <Button onClick={() => props.onClose()} variant="text">
          {t("ui:button.cancel")}
        </Button>
        <Button onClick={() => props.onSave()} variant="filled">
          {t("ui:button.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default OrgchartSortDialogView;
