import React from "react";
import { SelectableGroup } from "react-selectable-fast";

interface IScheduleDaysRowView {
  children: React.ReactNode;
  selectableGroupRef: any;
  scale: number;

  handleSelectionFinish: (items: any) => void;
}

function ScheduleDaysRowView(props: IScheduleDaysRowView) {
  const getSelectableGroupRef = (ref: SelectableGroup | null) => {
    if (props.selectableGroupRef == null) return;
    props.selectableGroupRef.current = ref;
  };

  return (
    <SelectableGroup
      enableDeselect
      ref={getSelectableGroupRef}
      className="schedule-days-row"
      allowClickWithoutSelected={false}
      selectOnClick={false}
      resetOnStart
      // delta={props.scale ?? 1}
      globalMouse
      ignoreList={[".__disabled", ".__empty"]}
      // scrollContainer=".page-content-sheet_main"
      // globalMouse
      onSelectionFinish={props.handleSelectionFinish}
    >
      {props.children}
    </SelectableGroup>
  );
}

export default ScheduleDaysRowView;
