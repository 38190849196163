import React, { useCallback, useEffect, useRef, useState } from "react";
import "./RegulationComments.scss";
import { useTranslation } from "react-i18next";
import { useNotifier } from "../../../../../../hooks";
import { usePagingWithController } from "../../../../../../hooks/usePaging";
import { api } from "../../../../../../services";
import { IRegulationHistory } from "./RegulationHistory.interface";
import { historySortKeys, historySortKeysType } from "../../misc/consts";
import { RegulationHistoryHeader } from "./RegulationHistoryHeader";
import { RegulationHistoryListCards } from "./RegulationHistoryListCards";
import { contentIsEmpty, contentToString, initialContent } from "../../../../../../utils/textEditor";
import { Button, Card } from "../../../../../uiKit";
import { Value } from "@udecode/plate";
import { TextEditor } from "../../../../textEditorOld/TextEditor";

export const RegulationHistory: React.FC<IRegulationHistory> = (props) => {
  const { t } = useTranslation();
  const notifier = useNotifier();

  const [isLoadNextPage, setIsLoadNextPage] = useState<boolean>(false);
  const [historyContent, setHistoryContent] = useState<Value>(initialContent);
  const [isSendingLoading, setIsSendingLoading] = useState<boolean>(false);
  const [sortValue, setSortValue] = useState<historySortKeysType>(historySortKeys.onesFirst);

  const editorOptionsRef = useRef<{ clear: () => void }>();

  const regulationHistory = usePagingWithController(
    api.regulationHistory,
    undefined,
    {
      // @ts-ignore
      regulationId: props.regulationId,
      pageSize: 10,
      orderBy: "date_created",
      order: sortValue === historySortKeys.onesFirst ? "desc" : "asc",
    },
    undefined
  );

  const handleLoadNextPage = useCallback(() => {
    setIsLoadNextPage(true); // костыль собачий, который решает проблему сортировки, почему то параметры, которые я передаю в хук, они динамически не изменяются, и хук использует первоначальные параметры, хотя они изменяются !!!!!
  }, []);

  const handleChangeCommentContent = (content: Value) => {
    setHistoryContent(content);
  };

  const handleChangeSortValue = useCallback((newValue: historySortKeysType) => setSortValue(newValue), []);

  const handleCreateRegulationHistory = async () => {
    setIsSendingLoading(true);
    api.regulationHistory
      .create({
        regulationContentId: props.regulationContentId,
        comment: {
          content: contentToString(historyContent),
        },
      })
      .then((res) => {
        editorOptionsRef.current?.clear();
        setHistoryContent(initialContent);
        //regulationHistory.reset();
        regulationHistory.restart(true);
      })
      .catch((error) => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" }))
      .finally(() => setIsSendingLoading(false));
  };

  useEffect(() => {
    if (isLoadNextPage) {
      regulationHistory.loadNext();
      setIsLoadNextPage(false);
    }
  }, [isLoadNextPage]);

  useEffect(() => {
    regulationHistory.restart(true);
  }, [sortValue]);

  return (
    <Card className="regulation-history__wrapper" isShadowed={false}>
      <RegulationHistoryHeader sortValue={sortValue} onChangeSortValue={handleChangeSortValue} />
      <TextEditor
        id="regulation-history-editor"
        placeholder={t("ui:placeholder.write_comment")}
        value={historyContent}
        ref={editorOptionsRef}
        onChange={handleChangeCommentContent}
      />
      {/*<RichTextEditor*/}
      {/*  placeholder={t("ui:placeholder.write_comment")}*/}
      {/*  value={historyContent}*/}
      {/*  ref={editorOptionsRef}*/}
      {/*  onChange={handleChangeCommentContent}*/}
      {/*/>*/}
      <Button
        onClick={handleCreateRegulationHistory}
        disabled={contentIsEmpty(historyContent)}
        style={{ marginTop: "8px" }}
        loading={isSendingLoading || regulationHistory?.info?.isLoading}
        className="button-icon_17"
        variant="filled"
      >
        {t("ui:button.send")}
      </Button>
      <RegulationHistoryListCards
        historyItems={regulationHistory.items}
        isDone={regulationHistory.info.isDone}
        loadNext={handleLoadNextPage}
      />
    </Card>
  );
};
