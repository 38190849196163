import "./ContentEditable.scss";

import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import * as React from "react";

export interface IContentEditable {
  className?: string;
  height?: number | string;
}

export default function LexicalContentEditable(props: IContentEditable): JSX.Element {
  return (
    <ContentEditable
      className={props.className || "ContentEditable__root"}
      contentEditable={false}
      style={{ minHeight: props.height }}
    />
  );
}
