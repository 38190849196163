import React from "react";
import { useTranslation } from "react-i18next";
import { RoleDto } from "../../../../api";
import { Button, Dialog, DialogActions, Switch } from "../../../uiKit";
import { LabelSwitch } from "./LabelSwitch";
import { CustomChip } from "../../../elements/customChip/CustomChip";

export interface IOrgchartFunctionMoveDialogView {
  allRoles: RoleDto[];
  filteredRoles: RoleDto[];
  roleId: number;
  selectedRoleId: number | null;
  changeFunctionColor: boolean;

  setChangeFunctionColor: (value: boolean) => void;
  onChipClick(id: number): void;
  onSave(): void;
  onClose(): void;
}

function OrgchartFunctionMoveDialogView(props: IOrgchartFunctionMoveDialogView) {
  const { t } = useTranslation();

  return (
    <Dialog
      title={t("ui:title.function_move")}
      open={true}
      onClose={props.onClose}
      width={650}
      scrollStrategy="dialogBody"
    >
      {/*  <SubheaderText text={t("ui:subheader.function_name")} />*/}
      <div className="ma-n1 full-width">
        {props.filteredRoles?.map((role: RoleDto) => (
          <CustomChip
            className="ma-1"
            isActive={props.selectedRoleId == role.id}
            onClick={() => props.onChipClick(role.id as number)}
            color={role.colorHex}
            // borderColor={role.colorHex ?? "var(--color-layout-fill-base)"}
            key={role.id}
            label={role.name}
          />
        ))}
      </div>
      <DialogActions>
        <Switch
          disabled={props.selectedRoleId == null}
          onChange={(value) => props.setChangeFunctionColor(value)}
          checked={props.changeFunctionColor}
          label={<LabelSwitch allRoles={props.allRoles} selectedRoleId={props.selectedRoleId} />}
        />
        <div className="ml-auto" />
        <Button onClick={() => props.onClose()} variant="text">
          {t("ui:button.cancel")}
        </Button>
        <Button disabled={props.selectedRoleId == null} onClick={() => props.onSave()} variant="filled">
          {t("ui:button.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default OrgchartFunctionMoveDialogView;
