import React from "react";
import "./CardMeta.scss";
import { Card } from "antd";
import { CardMetaProps } from "antd/lib/card";
import clsx from "clsx";

interface ICardMeta extends CardMetaProps {
  //
}

const { Meta } = Card;

export function CardMeta({ className, ...rest }: ICardMeta) {
  return <Meta className={clsx("uiKit-cardMeta", className ?? "")} {...rest} />;
}
