import React from "react";
import "./NotificationsCenter.scss";
import { Badge, Empty, IconButton, Link, Popover, Spin, Switch, TabPane, Tabs } from "../../uiKit";
import { FiBell } from "@react-icons/all-files/fi/FiBell";
import { NotificationDto } from "../../../api";
import { TNotificationsCenterTabs } from "./NotificationsCenter";
import ScrollTrigger from "../scrollTrigger/ScrollTrigger";
import { NotificationsCenterCard } from "../../elements/notificationsCenterCard/NotificationsCenterCard";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { theme } from "antd";

interface INotificationData {
  all: {
    items: NotificationDto[];
    itemsLength: number;
    isLoading: boolean;
    isDone: boolean;
  };
  needActions: {
    items: NotificationDto[];
    itemsLength: number;
    isLoading: boolean;
    isDone: boolean;
  };
  inObservation: {
    items: NotificationDto[];
    itemsLength: number;
    isLoading: boolean;
    isDone: boolean;
  };
}

interface INotificationsCenterView {
  notificationData: INotificationData;
  onlyShowUnread: boolean;
  currentTab: TNotificationsCenterTabs;
  isVisible: boolean;
  isBadgeVisible: boolean;

  onCardClick: (data: NotificationDto) => void;
  onCardIsReadStatusChange: (id: number, currentIsReadStatus: boolean, tabKey: TNotificationsCenterTabs) => void;
  setIsVisible: (isVisible: boolean) => void;
  setCurrentTab: (value: TNotificationsCenterTabs) => void;
  setOnlyShowUnread: (value: boolean) => void;
  onLoadMore: (key: TNotificationsCenterTabs) => void;
  onReadAllClick: () => void;
  onOpen: () => void;
  onClose: () => void;
}

const getNotificationsCenterTabPaneContentByKey = (key: TNotificationsCenterTabs, props: INotificationsCenterView) => {
  if (key == "all") {
    if (props.notificationData.all.isLoading && props.notificationData.all.items.length == 0) return LoadingBlock();
    if (props.notificationData.all.items.length == 0 && !props.notificationData.all.isLoading) return NoDataBlock();
    return (
      <div className="d-stack-column spacing-1">
        {props.notificationData.all.items.map((item) => (
          <NotificationsCenterCard
            key={item.id}
            data={item}
            onClick={props.onCardClick}
            onIsReadStatusChange={(isRead) => props.onCardIsReadStatusChange(item.id as number, isRead, "all")}
          />
        ))}
        <ScrollTrigger
          disabled={props.notificationData.all.isLoading}
          hidden={props.notificationData.all.isDone || props.notificationData.all.items.length == 0}
          onIntersection={() => props.onLoadMore("all")}
        />
      </div>
    );
  }
  if (key == "needActions") {
    if (props.notificationData.needActions.isLoading && props.notificationData.needActions.items.length == 0)
      return LoadingBlock();
    if (props.notificationData.needActions.items.length == 0 && !props.notificationData.needActions.isLoading)
      return NoDataBlock();
    return (
      <div className="d-stack-column spacing-1">
        {props.notificationData.needActions.items.map((item) => (
          <NotificationsCenterCard
            key={item.id}
            data={item}
            onClick={props.onCardClick}
            onIsReadStatusChange={(isRead) => props.onCardIsReadStatusChange(item.id as number, isRead, "needActions")}
          />
        ))}
        <ScrollTrigger
          disabled={props.notificationData.needActions.isLoading}
          hidden={props.notificationData.needActions.isDone || props.notificationData.needActions.items.length == 0}
          onIntersection={() => props.onLoadMore("needActions")}
        />
      </div>
    );
  }
  if (key == "inObservation") {
    if (props.notificationData.inObservation.isLoading && props.notificationData.inObservation.items.length == 0)
      return LoadingBlock();
    if (props.notificationData.inObservation.items.length == 0 && !props.notificationData.inObservation.isLoading)
      return NoDataBlock();
    return (
      <div className="d-stack-column spacing-1">
        {props.notificationData.inObservation.items.map((item) => (
          <NotificationsCenterCard
            key={item.id}
            data={item}
            onClick={props.onCardClick}
            onIsReadStatusChange={(isRead) =>
              props.onCardIsReadStatusChange(item.id as number, isRead, "inObservation")
            }
          />
        ))}
        <ScrollTrigger
          disabled={props.notificationData.inObservation.isLoading}
          hidden={props.notificationData.inObservation.isDone || props.notificationData.inObservation.items.length == 0}
          onIntersection={() => props.onLoadMore("inObservation")}
        />
      </div>
    );
  }
};

const NoDataBlock = () => {
  return (
    <div className="pa-4">
      <Empty />
    </div>
  );
};

const LoadingBlock = () => {
  return (
    <div className="d-flex align-center justify-center pa-4" style={{ height: "162px" }}>
      <Spin spinning={true} size="large" />
    </div>
  );
};

const NotificationsCenterBody = (props: INotificationsCenterView) => {
  const { t } = useTranslation();
  const { useToken } = theme;
  const { token } = useToken();

  const getNotificationsCountByKey = (key: TNotificationsCenterTabs) => {
    if (key == "all") return props.notificationData.all.itemsLength;
    if (key == "needActions") return props.notificationData.needActions.itemsLength;
    if (key == "inObservation") return props.notificationData.inObservation.itemsLength;
    return 0;
  };

  const notificationTabs = [
    { id: 0, name: t("parse:nc_tabs.all"), key: "all" },
    { id: 1, name: t("parse:nc_tabs.need_actions"), key: "needActions" },
    { id: 2, name: t("parse:nc_tabs.in_observation"), key: "inObservation" },
  ];

  return (
    <Tabs
      defaultValue={props.currentTab}
      onChange={(value) => props.setCurrentTab(value as TNotificationsCenterTabs)}
      tabBarStyle={{ marginBottom: 0 }}
      tabBarExtraContent={
        props.currentTab == "all" ? (
          <div className="d-stack spacing-2 align-center">
            <span style={{ opacity: 0.75 }}>{t("parse:unread_only")}</span>
            <Switch
              checked={props.onlyShowUnread}
              onChange={(isChecked) => props.setOnlyShowUnread(isChecked)}
              disabled={props.notificationData.all.isLoading}
            />
          </div>
        ) : null
      }
    >
      {notificationTabs.map((nT) => (
        <TabPane
          key={nT.key}
          tab={
            <div className="d-stack spacing-2 align-center px-1">
              <span>{nT.name}</span>
              <Badge
                style={{ background: "var(--color-primary-base)" }}
                overflowCount={99}
                // showZero={true}
                // className="mr-1"
                count={getNotificationsCountByKey(nT.key as TNotificationsCenterTabs)}
              />
            </div>
          }
        >
          <div className="full-height d-flex flex-column" style={{ overflow: "hidden" }}>
            <div className="d-flex justify-space-between py-1">
              <div />
              {props.currentTab == "all" && (
                <Link
                  onClick={props.onReadAllClick}
                  disabled={getNotificationsCountByKey("all") == 0 || props.notificationData.all.isLoading}
                  style={{ filter: "grayscale(1)" }}
                  className="__read-all"
                  children={t("parse:read_all")}
                />
              )}
            </div>
            <div
              className="flex-grow-1"
              style={{ overflowY: "auto", maxHeight: "470px" }}
              children={getNotificationsCenterTabPaneContentByKey(nT.key as TNotificationsCenterTabs, props)}
            />
          </div>
        </TabPane>
      ))}
    </Tabs>
  );
};

function NotificationsCenterViewToExport(props: INotificationsCenterView) {
  return (
    <Popover
      overlayClassName="notifications_center-overlay"
      placement="bottomRight"
      trigger={["click"]}
      content={<NotificationsCenterBody {...props} />}
      arrowContent={null}
      // color="white"
      onOpenChange={(isOpen) => (isOpen ? props.onOpen() : props.onClose())}
      destroyTooltipOnHide
      open={props.isVisible}
      overlayInnerStyle={{ paddingTop: "4px" }}
    >
      {/*offset={[-10, 4]} dot={true} blink*/}
      <Badge
        count={props.isBadgeVisible ? 1 : 0}
        style={{ transform: "scale(1.5)", pointerEvents: "none" }}
        size="small"
        offset={[-6, 4]}
        dot={true}
        blink
      >
        <IconButton className="flex-shrink-0" onClick={() => {}}>
          <FiBell opacity={0.7} fontSize={20} />
        </IconButton>
      </Badge>
    </Popover>
  );
}

export const NotificationsCenterView = observer(NotificationsCenterViewToExport);
