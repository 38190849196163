import React, { useContext, useState } from "react";
import { useNotifier, useRootStore } from "../../../../../hooks";
import OrgchartTypeSelectFormView from "./OrgchartTypeSelectFormView";
import { useTranslation } from "react-i18next";
import { OrgchartContext } from "../../../../../contexts/orgchartContext";

interface IOrgchartTypeSelectForm {
  onCreate?: (type: any) => void;
}

function OrgchartTypeSelectForm(props: IOrgchartTypeSelectForm) {
  const { authStore } = useRootStore();
  const notifier = useNotifier();
  const { t } = useTranslation();
  const orgchartContext = useContext(OrgchartContext);

  const [selectedOrgchartType, setSelectedOrgchartType] = useState<"d" | "c" | null>(null);

  const handleSave = async () => {
    if (selectedOrgchartType == null) return;
    props.onCreate && props.onCreate(selectedOrgchartType);
    // const r = await api.company.applyOrgchartTemplate(selectedOrgchartType);
    // if (r) {
    //   await authStore.refreshInitialInfo();
    //   orgchartContext?.refreshState();
    // } else {
    //   notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
    // }
  };

  return (
    <OrgchartTypeSelectFormView
      selectedOrgchartType={selectedOrgchartType}
      setSelectedOrgchartType={setSelectedOrgchartType}
      handleSave={handleSave}
    />
  );
}

export default OrgchartTypeSelectForm;
