import React, { useEffect, useState } from "react";
import PageHeader from "../../modules/layouts/pageHeader/PageHeader";
import PageContent from "../../modules/layouts/pageContent/PageContent";
import { useTranslation } from "react-i18next";
import { useClaims, useRootStore } from "../../../hooks";
import EmployeeMainCard from "../../modules/pages/employee/employeeMainCard/EmployeeMainCard";
import { api } from "../../../services";
import EmployeeInviteDialog from "../../modules/dialogs/employeeInviteDialog/EmployeeInviteDialog";
import { FiPlus } from "@react-icons/all-files/fi/FiPlus";
import EmployeeDeleteDialog from "../../modules/dialogs/employeeDeleteDialog/EmployeeDeleteDialog";
import { permissionKeys } from "../../../utils/permissions";
import ScrollTrigger from "../../service/scrollTrigger/ScrollTrigger";
import { usePagingWithController } from "../../../hooks/usePaging";
import { FiSearch } from "@react-icons/all-files/fi/FiSearch";
import useDebounce from "../../../hooks/useDebounce";
import { Avatar, Button, Card, Divider, Icon, Input, Select, SelectOption, Tag, Tooltip } from "../../uiKit";
import "./EmployeePage.scss";
import { observer } from "mobx-react-lite";
import { ColorModeKeys } from "../../elements/counterChip/misc/keys";
import { CounterChip } from "../../elements/counterChip/CounterChip";
import { theme } from "antd";
import { FiInfo } from "react-icons/all";

function EmployeePage() {
  const { t } = useTranslation();
  const { authStore, orgchartStore } = useRootStore();
  const claims = useClaims();
  const { useToken } = theme;
  const { token } = useToken();
  const [orgchartFilter, setOrgchartFilter] = useState<number[]>([]);
  const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [userToManageId, setUserToManageId] = useState<number | null>(null);
  const [employeeFilters, setEmployeeFilters] = useState<any>({
    name: "",
  });
  const [companyUsersInformationTooltipData, setCompanyUsersInformationTooltipData] = useState<{
    text: string;
    type: "default" | "error";
  }>({
    text: " ",
    type: "error",
  });
  // const data = useApi(() => api.user.getAll({ pageSize: 10000 }));
  const users = usePagingWithController(
    api.user,
    { name: employeeFilters.name, orgchartIds: orgchartFilter ?? [] },
    { pageSize: 20, orderBy: "name", order: "asc" }
  );
  const debouncedFilters = useDebounce(employeeFilters, 500);

  useEffect(() => {
    users.restart();
  }, []);

  useEffect(() => {
    users.reset();
    users.restart();
  }, [debouncedFilters, orgchartFilter]);

  const handleInviteDialogClose = () => {
    setIsInviteDialogOpen(false);
  };

  const handleDeleteOpen = (userId: number) => {
    setIsDeleteDialogOpen(true);
    setUserToManageId(userId);
  };

  const handleDeleteSuccess = async () => {
    setIsDeleteDialogOpen(false);
    users.reset();
    await users.restart();
    // await data.fetch();
  };

  const generateCompanyUsersInformationTooltipData = async () => {
    const currentUsersCount =
      (
        await api.user.autocomplete({
          containsCompanyId: authStore.getInitialInfo?.identity?.currentCompanyId,
          companyId: authStore.getCurrentCompanyId,
          accessType: 0,
        })
      )?.totalItems ?? 0;
    const usersLimit =
      authStore.getInitialInfo?.identity?.companies?.find(
        (c) => c.companyId == authStore.getInitialInfo?.identity?.currentCompanyId
      )?.company?.userLimit ?? "∞";
    setCompanyUsersInformationTooltipData({
      type: usersLimit == "∞" || currentUsersCount < usersLimit ? "default" : "error",
      text: t("parse:employees_limit_text", {
        usersCount: currentUsersCount,
        usersLimit: usersLimit == "∞" ? "∞" : Math.max(0, usersLimit - currentUsersCount),
      }),
    });
  };

  useEffect(() => {
    generateCompanyUsersInformationTooltipData();
  }, []);

  return (
    <>
      {isInviteDialogOpen && <EmployeeInviteDialog open={true} handleClose={handleInviteDialogClose} />}
      <PageHeader title={t("common:page_title.employees")}>
        <CounterChip className="mr-2" colorMode={ColorModeKeys.primary} count={users?.info?.totalItems ?? 0} />
        <Select
          style={{ minWidth: "220px" }}
          mode="multiple"
          value={orgchartFilter.filter((x) => x != null)}
          onChange={(value) => setOrgchartFilter(value)}
          className="orgchart-select-employee"
          autoFocus={false}
          bordered={false}
          showSearch={false}
          showArrow={true}
          maxTagCount={4}
          maxTagTextLength={12}
          dropdownRender={(menu) => (
            <>
              <Button onClick={() => setOrgchartFilter([])} variant="text" className="full-width br-none">
                {t("parse:all")}
              </Button>
              <Divider className="mb-1" />
              {menu}
            </>
          )}
          tagRender={(p) => {
            const { label, value } = p;
            return (
              <Tag
                style={{
                  borderRadius: "24px",
                  paddingLeft: "0",
                  border: `1px solid ${token.colorFill}`,
                  backgroundColor: token.colorFillAlter,
                }}
              >
                {label}
              </Tag>
            );
          }}
        >
          {orgchartStore.getOrgchartsList.map((o) => (
            <SelectOption key={o.id} value={o.id as number}>
              <span className="mr-2">
                <Avatar size="small" color={o.colorHex} text={o.name} src={o.image?.url} />
              </span>
              <span children={o.name} />
            </SelectOption>
          ))}
        </Select>
        {/*<OrgchartSwitcher*/}
        {/*  useAsFilter*/}
        {/*  value={orgchartFilter}*/}
        {/*  drawType="avatarsWithMenu"*/}
        {/*  // selectProps={{*/}
        {/*  //   bordered: false,*/}
        {/*  //   mode: "multiple",*/}
        {/*  //   style: {*/}
        {/*  //     minWidth: "220px",*/}
        {/*  //   },*/}
        {/*  // }}*/}
        {/*  onSelectAllClick={() => setOrgchartFilter([])}*/}
        {/*  onChange={(orgchartId: number) =>*/}
        {/*    orgchartFilter.some((f) => f == orgchartId)*/}
        {/*      ? setOrgchartFilter([...orgchartFilter.filter((f) => f != orgchartId)])*/}
        {/*      : setOrgchartFilter([...orgchartFilter, orgchartId])*/}
        {/*  }*/}
        {/*/>*/}
        <div className="flex-grow-1" />
        {claims.has(permissionKeys.user.companyInvite.add) && (
          <div className="ml-2 d-stack">
            <Tooltip placement="bottom" title={companyUsersInformationTooltipData.text}>
              <Icon
                className="mr-3"
                component={() => (
                  <FiInfo
                    color={
                      companyUsersInformationTooltipData.type == "error" && users.items.length != 0
                        ? "var(--color-error-base)"
                        : "var(--color-primary-base)"
                    }
                    fontSize="20px"
                  />
                )}
              />
            </Tooltip>
            <Button
              variant="outlined"
              icon={<Icon component={() => <FiPlus />} />}
              onClick={() => setIsInviteDialogOpen(true)}
              // size="small"
            >
              {t("ui:button.invite_employee")}
            </Button>
          </div>
        )}
      </PageHeader>
      <PageContent isLoading={users.info.isLoading}>
        <div className="d-flex flex-column full-height full-width">
          <Card
            className="full-width flex-grow-0 mb-3"
            bodyStyle={{ display: "flex", alignItems: "center", padding: "8px" }}
          >
            <Input
              value={employeeFilters.name}
              onInput={(event: any) => {
                event.stopPropagation();
                setEmployeeFilters({ ...employeeFilters, name: event.target.value });
              }}
              bordered={false}
              prefix={<FiSearch />}
              placeholder={t("ui:placeholder.search_by_employees")}
            />
            {/*</div>*/}
          </Card>
          <div className="flex-grow-1" style={{ overflow: "auto" }}>
            <EmployeeDeleteDialog
              open={isDeleteDialogOpen}
              userId={userToManageId as number}
              companyId={authStore.getInitialInfo?.identity?.currentCompanyId as number}
              onSuccess={handleDeleteSuccess}
              onClose={() => setIsDeleteDialogOpen(false)}
            />
            {users.items.length > 0 && (
              <>
                <div
                  className="d-stack-column spacing-3"
                  children={users.items?.map((u, index) => (
                    <EmployeeMainCard onDelete={handleDeleteOpen} employeeData={u} key={u.id} />
                  ))}
                />
                <ScrollTrigger
                  disabled={users.info.isDone}
                  onIntersection={() => users.loadNext()}
                  marginTop={users.items.length > 0}
                  hidden={users.info.isDone}
                />
              </>
            )}
            {users.items.length == 0 && !users.info.isLoading && (
              <div className="companies-management-page__panel pa-2 mb-3 br-sm">{t("text:no_elements")}</div>
            )}
          </div>
        </div>
      </PageContent>
    </>
  );
}

export default observer(EmployeePage);
