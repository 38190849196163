import * as React from "react";
import { ChangeEvent, useState } from "react";
import { ToolbarButton, usePlateEditorState } from "@udecode/plate";
import { IconButton, Input, Popover } from "../../../../uiKit";
import { FiCheck } from "@react-icons/all-files/fi/FiCheck";
import { useTranslation } from "react-i18next";
import { insertIFrame } from "./insertIFrame";
import { MdOndemandVideo } from "@react-icons/all-files/md/MdOndemandVideo";
import { getVideoSrc } from "../../utils/getVideoSrc";

export const IFrameToolbarButton = () => {
  const editor = usePlateEditorState();
  const [videoSrc, setVideoSrc] = useState<string>("");
  const [validate, setValidate] = useState(true);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const urlValidation = (url: string) => {
    const regex = new RegExp(
      "^((?:https?:)?\\/\\/)?((?:www|m)\\.)?((?:youtube(-nocookie)?\\.com|youtu.be))(\\/(?:[\\w\\-]+\\?v=|embed\\/|v\\/)?)([\\w\\-]+)(\\S+)?$"
    );
    return regex.test(url);
  };

  const handleChangeVisible = (newVisible: boolean) => {
    setOpen(newVisible);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const isTrueValue = !e.currentTarget.value || urlValidation(e.currentTarget.value);
    setValidate(isTrueValue);
    setVideoSrc(e.currentTarget.value);
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleApplyClick = () => {
    editor && insertIFrame(editor, { src: getVideoSrc(videoSrc) });
    setVideoSrc("");
    setOpen(false);
  };

  return (
    <Popover
      placement="bottom"
      trigger="click"
      open={open}
      onOpenChange={handleChangeVisible}
      content={
        <div className="d-stack-row">
          <Input
            size="middle"
            status={!validate ? "error" : ""}
            value={videoSrc}
            onChange={handleChange}
            placeholder={t("ui:placeholder.text_editor_url")}
          />
          <IconButton
            className="ml-1"
            icon={<FiCheck size={25} />}
            onMouseDown={handleApplyClick}
            disabled={!validate || !videoSrc}
          />
        </div>
      }
    >
      <ToolbarButton
        icon={<MdOndemandVideo className="toolbar__buttons" />}
        onMouseDown={handleClick}
        title={t("common:text_editor_toolbar.link")}
      />
    </Popover>
  );
};
