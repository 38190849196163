import React, { ReactNode } from "react";
import { DialogActionsView } from "./DialogActionsView";

interface IDialogActions {
  className?: string;
  children?: ReactNode;
}

export function DialogActions(props: IDialogActions) {
  return <DialogActionsView {...props} />;
}

DialogActions.displayName = "DialogActions";
