import { UserSelectionRule } from "../../../../api/types/userSelectionRule";
import { OrgchartDto } from "../../../../api";

export const hangleGetShowExcept = (rules: UserSelectionRule[]): boolean => rules.some((item) => !item.rolePositionId);

export const handleGetOrgchartIdForBlockAllAll = (rules: UserSelectionRule[]): number[] =>
  rules.filter((item) => !!item.rolePositionId)?.map((item) => item.orgchartId ?? 0);

export const handleAddRuleBtnIsDisabled = (rules: UserSelectionRule[], orgcharts?: OrgchartDto[]): boolean =>
  !!orgcharts?.every((orgchart) =>
    rules.some((rule) => rule.orgchartId === orgchart.id && !rule.roleId && !rule.rolePositionType)
  );
