import React from "react";
import { SkeletonView } from "./SkeletonView";
import { SkeletonProps } from "antd";

interface ISkeleton extends SkeletonProps {
  //
}

export function Skeleton(props: ISkeleton) {
  return <SkeletonView {...props} />;
}
