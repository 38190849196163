import React, { useState } from "react";
import { IIssueWithdrawDialogView } from "./IssueWithdrawDialogView.interface";
import { useTranslation } from "react-i18next";
import SubheaderText from "../../../elements/subheaderText/SubheaderText";
import { useRootStore } from "../../../../hooks";
import { Button, DatePicker, Dialog, DialogActions } from "../../../uiKit";
import { AutocompleteAsync } from "../../../elements/autocompleteAsync/AutocompleteAsync";
import { Form } from "antd";
import dayjs from "dayjs";

export const IssueWithdrawDialogView = ({
  open,
  executorId,
  deadline,
  handleWithdrawIssue,
  onClose,
  setExecutorId,
  setDeadline,
}: IIssueWithdrawDialogView) => {
  const { t } = useTranslation();
  const { authStore } = useRootStore();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = () => {
    setLoading(true);
    form.submit();
  };

  return (
    <Dialog title={t("ui:title.withdraw_issue.title")} open={open} onClose={onClose}>
      <Form
        style={{ overflowY: "auto", height: "100%" }}
        form={form}
        onFinish={handleWithdrawIssue}
        onFinishFailed={() => setLoading(false)}
        autoComplete="off"
      >
        <SubheaderText text={t("ui:subheader.issue_executor")} />
        <Form.Item name="issue_executor" rules={[{ required: true, message: t("validation:field_required") }]}>
          <AutocompleteAsync
            style={{ minWidth: "100%" }}
            dropdownStyle={{ zIndex: 1202 }}
            type="user"
            placeholder={t("ui:placeholder.click_to_select")}
            value={executorId}
            onChange={setExecutorId}
          />
        </Form.Item>
        <SubheaderText text={t("ui:subheader.deadline")} />
        <DatePicker
          style={{ width: "100%" }}
          value={deadline != null ? dayjs(deadline) : null}
          placeholder={t("ui:placeholder.select")}
          onChange={(deadline) => setDeadline(deadline?.toDate() ?? null)}
          allowClear
          showTime={{ format: "HH:mm" }}
          disablePast
        />
      </Form>
      <DialogActions>
        <Button onClick={onClose} variant="text">
          {t("ui:button.cancel")}
        </Button>
        <Button loading={loading} onClick={handleSubmit} type="primary" variant="text">
          {t("ui:button.withdraw")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
