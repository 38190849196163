import React, { useEffect, useState } from "react";
import { usePagingWithController } from "../../../../../../hooks/usePaging";
import { api } from "../../../../../../services";
import { UpdateNoteListContent } from "../../contents/UpdateNoteListContent/UpdateNoteListContent";
import PageContent from "../../../../layouts/pageContent/PageContent";
import { useNavigate } from "react-router-dom";

export const UpdateNoteList = () => {
  // const claims = useClaims();
  const navigate = useNavigate();

  const [zoom, setZoom] = useState<number>(1);

  //const permissionAddUpdateNote = claims.has(permissionKeys.recurringIssue.add);

  const updateNotes = usePagingWithController(api.updateNote);

  const handleChangeZoom = (zoomValue: number) => setZoom(zoomValue);

  const handleRedirectToCreate = () => navigate("/update-note/create");

  useEffect(() => {
    updateNotes.restart();
  }, []);

  return (
    <>
      {/*<PageHeader>*/}
      {/*    <UpdateNoteListHeader*/}
      {/*        zoomV={zoom}*/}
      {/*        onChangeZoomV={handleChangeZoom}*/}
      {/*    />*/}
      {/*</PageHeader>*/}
      <PageContent isZoomable zoom={zoom} isLoading={updateNotes.items.length === 0 && updateNotes.info.isLoading}>
        <UpdateNoteListContent
          onRedirectToCreate={handleRedirectToCreate}
          /*permissionToAdd={permissionAddUpdateNote}*/
          isDone={updateNotes.info.isDone}
          isLoading={updateNotes.info.isLoading}
          loadNext={updateNotes.loadNext}
          updateNotes={updateNotes.items}
        />
      </PageContent>
    </>
  );
};