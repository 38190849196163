import React from "react";
import ruContent from "../../../../../utils/guides/ru/planing.json";
import enContent from "../../../../../utils/guides/en/planing.json";
import { TextEditor } from "../../../textEditorOld/TextEditor";
import { useRootStore } from "../../../../../hooks";

export const getContent = (localeKey: string | null): any | null => {
  switch (localeKey) {
    case "ru":
      return ruContent;
    case "en":
      return enContent;
    case "uk":
      return ruContent;
  }
};

export const PlaningGuide = () => {
  const { appStore } = useRootStore();

  return (
    <TextEditor readOnly={true} id="guide-planningg" onChange={() => {}} value={getContent(appStore.getAppLocale)} />
  );
};
