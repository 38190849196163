import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IssueDto, IssueScheduleDto, PlanDto } from "../../../../../../api";
import { IssueQueryKeys } from "../../../../../../constants/issueTypeKeys";
import { useApi, useRootStore } from "../../../../../../hooks";
import { usePagingWithController } from "../../../../../../hooks/usePaging";
import { api } from "../../../../../../services";
import PageContent from "../../../../layouts/pageContent/PageContent";
import { PlanningPageMainContent } from "../../contents/planningPageMainContent/PlanningPageMainContent";
import { PlanningPageMyPlanSidebar } from "../../contents/planningPageMyPlanSidebar/PlanningPageMyPlanSidebar";
import { IPlanningPageMyPlan } from "./PlanningPageMyPlan.interface";
import "./PlanningPageMyPlan.scss";

export const PlanningPageMyPlan = (props: IPlanningPageMyPlan) => {
  const { authStore } = useRootStore();
  const navigate = useNavigate();
  const userId = authStore.getInitialInfo?.identity?.id;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFirstLoading, setIsFirstLoading] = useState<boolean>(true);
  const [isHideSidebar, setIsHideSidebar] = useState<boolean>(false);
  const [currentPlanId, setCurrentPlanId] = useState<number | undefined>(undefined);

  const restartPlanHistory = useRef<{ restart: () => void }>();

  const plansShort = usePagingWithController(
    api.planShort,
    { userId: userId, orderBy: "date_created", orderByType: "desc" },
    { pageSize: 10 }
  );

  const currentPlan = useApi<PlanDto | null>(
    () => api.plan.getById(currentPlanId!, { userId: userId, attachCanBeMigratedFromPlanId: true }),
    null,
    () => {},
    {},
    false
  );

  const issuesFromCommunications = useApi(
    () =>
      api.issue.getAll({
        notInPlanId: currentPlan?.value?.id,
        boardStatusKeyIsNot: "done",
        isArchived: false,
        executorUserId: userId,
      }),
    null,
    () => {},
    {},
    false
  );

  const issues = usePagingWithController<IssueDto, {}>(
    api.issue,
    { planId: currentPlan?.value?.id, includeNextStatuses: true },
    { pageSize: -1 },
    undefined,
    undefined,
    () => {
      setIsFirstLoading(false);
      setIsLoading(false);
    }
  );
  const scheduleIssues = usePagingWithController<IssueScheduleDto, {}>(
    api.issueSchedule,
    {
      dateReleaseFrom: currentPlan?.value?.dateFrom,
      dateReleaseTo: currentPlan?.value?.dateTo,
      executorUserId: userId,
    },
    { pageSize: -1 }
  );

  const handleOpenCreateIssueDialog = () => navigate({ search: `?${IssueQueryKeys.taskCreate}=${true}` });

  const handlePlanIdChange = (planId: number) => {
    setCurrentPlanId(planId);
    setIsLoading(true);
  };

  const handleHidingSidebar = () => {
    setIsHideSidebar(!isHideSidebar);
  };

  useEffect(() => {
    plansShort.restart();
  }, []);

  useEffect(() => {
    if (plansShort.items.length) {
      setCurrentPlanId(plansShort.items.find((i) => i.isCurrent)?.id);
    }
    return () => setCurrentPlanId(undefined);
  }, [plansShort.items]);

  useEffect(() => {
    currentPlan.fetch();
  }, [currentPlanId]);

  useEffect(() => {
    if (currentPlan.value) {
      issuesFromCommunications.fetch();
      issues.restart(true);
      scheduleIssues.restart(true);
      restartPlanHistory.current?.restart && restartPlanHistory.current?.restart();
    }
  }, [currentPlan.value, currentPlan.value?.id]);

  return (
    <PageContent isLoading={isFirstLoading}>
      {isFirstLoading ? (
        <div />
      ) : (
        <div className="d-stack full-height">
          <div className={isHideSidebar ? "hide" : "visible"}>
            <PlanningPageMyPlanSidebar
              issues={issuesFromCommunications.value?.items}
              isLoading={issuesFromCommunications.isLoading}
              plan={currentPlan.value ?? undefined}
              fetchPlans={currentPlan.fetch}
              issuesFromCommunicationsRestart={issuesFromCommunications.fetch}
              issuesRestart={issues.restart}
              onHidingSidebarChange={handleHidingSidebar}
              isHideSidebar={isHideSidebar}
            />
          </div>
          <div style={{ flexGrow: 1, overflow: "auto" }}>
            <PlanningPageMainContent
              ref={restartPlanHistory}
              onOpenCreateIssueDialog={handleOpenCreateIssueDialog}
              plan={currentPlan.value ?? undefined}
              issues={issues.items}
              isLoadingIssue={issues.info.isLoading}
              scheduleIssues={scheduleIssues.items}
              fetchPlans={currentPlan.fetch}
              issuesFromCommunicationsRestart={issuesFromCommunications.fetch}
              issuesRestart={issues.restart}
              fetchApprovalPlans={props.fetchApprovalPlans}
              onPlanIdChange={handlePlanIdChange}
              isLoading={isLoading}
              isHideSidebar={isHideSidebar}
              onHidingSidebarChange={handleHidingSidebar}
              userId={userId}
              plansShort={plansShort.items}
              currentPlanId={currentPlanId}
            />
          </div>
        </div>
      )}
    </PageContent>
  );
};
