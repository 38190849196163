import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useNotifier, useRootStore } from "../../../hooks";
import { useTranslation } from "react-i18next";
import { IssueTypeKeys } from "../../../constants/issueTypeKeys";
import { useUpdateWithController } from "../../../hooks/useUpdate";
import { api } from "../../../services";
import { IssueCustomFieldDto, IssueUpdateMessage } from "../../../api";
import { toJS } from "mobx";
import { useIssueHub } from "../../../signalr/hubs/issueHub";
import { RealtimeIssuesKeys } from "../../pages/communication/misc/consts";
import { checkQueryIssueId, removeQueryIssueIdAndIssueType, setQueryIssueId } from "./helpers";
import { IssueCommonDialog } from "../../modules/dialogs/issueCommonDialog/IssueCommonDialog";
import { IssueFormKeys } from "../../modules/dialogs/issueCommonDialog/IssueCommonDialog.interface";

export const IssueUpdateWrapper = (): JSX.Element => {
  const { start, connection, messages } = useIssueHub();
  const { helperStore, authStore } = useRootStore();
  const location = useLocation();
  const notifier = useNotifier();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [issueType, setIssueType] = useState<IssueType | null>(null); // тип issue
  const [customFields, setCustomFields] = useState<IssueCustomFieldDto[]>([]); // буду прокидовать в просы формы, они там нужны будут

  const issueId = checkQueryIssueId(location.search);
  const updater = useUpdateWithController(api.issue, () => {});

  const handleClose = () => {
    navigate({ search: removeQueryIssueIdAndIssueType(location.search) });
  };

  const handlerApiError = (notFound?: boolean) => {
    handleClose(); // сбрасываю query если произошла ошибка при загрузке
    notifier.show({
      message: notFound ? t("notifier:error.issue_not_found") : t("notifier:error.something_wrong"),
      theme: "error",
    });
  };

  const handleReopenById = (id: number) => {
    setTimeout(() => {
      navigate({ search: setQueryIssueId(location.search, id) });
    }, 400);
  };

  const handleLoadCustomFields = async () => {
    const fieldsType = IssueTypeKeys.task;
    let fields = toJS(helperStore.getIssueCustomField.find((item) => item.type === fieldsType));
    if (fields && fields.items.length > 0) {
      setCustomFields(fields.items);
    } else {
      setIsLoading(true);
      const request = await api.helper.customField(fieldsType);
      setIsLoading(false);
      if (request == null) return handlerApiError();
      setCustomFields(request as IssueCustomFieldDto[]);
      helperStore.setIssueCustomField({ items: request, type: fieldsType });
    }
  };

  const handleLoadIssue = async () => {
    setIsLoading(true);
    // updater.setInitialState({}); //
    const request = await api.issue.getById(Number(issueId), {
      includeIndicators: true,
      includeNextStatuses: true,
      includeActions: true,
    });
    setIsLoading(false);
    if (request == null) return handlerApiError(true);
    updater.setInitialState(request);
    // setIssueType(IssueTypeKeys.task);
  };

  const handleIssueReload = async () => {
    if (issueId == null) return;
    setIsLoading(true);
    const res = await api.issue.getById(issueId, {
      includeIndicators: true,
      includeNextStatuses: true,
      includeActions: true,
    });
    if (res !== null) {
      updater.applyChanges(res);
    }
    setIsLoading(false);
  };

  const realtimeMessageHandler = (message: IssueUpdateMessage) => {
    if (
      message.type === RealtimeIssuesKeys.HistoryUpdated //&&
      // message.byUserId != authStore.getInitialInfo?.identity?.id
    ) {
      if (message.issueId === issueId) {
        api.issue
          .getById(message.issueId, {
            includeIndicators: true,
            includeNextStatuses: true,
            includeActions: true,
          })
          .then((res) => {
            if (res !== null) {
              updater.applyChanges(res);
            }
          });
      }
    }
    // && message.byUserId != authStore.getInitialInfo?.identity?.id
    if (message.type === RealtimeIssuesKeys.Updated) {
      if (message.issueId === issueId) {
        api.issue
          .getById(message.issueId, {
            includeIndicators: true,
            includeNextStatuses: true,
            includeActions: true,
          })
          .then((res) => res !== null && updater.applyChanges(res));
      }
    }
    // && message.byUserId != authStore.getInitialInfo?.identity?.id
    if (message.type === RealtimeIssuesKeys.Deleted) {
      if (message.issueId === issueId) {
        handleClose();
      }
    }
  };

  // useEffect(() => {
  //   if (!!location?.search.length) {
  //     const queryIssueType = checkIssueType(location.search);
  //     queryIssueType != issueType && setIssueType(queryIssueType);
  //   }
  // }, [location.search]);

  useEffect(() => {
    updater.reset();
    if (issueId) {
      handleLoadCustomFields();
      handleLoadIssue();
    }
  }, [issueId]);

  useEffect(() => {
    if (connection) {
      if (issueId) connection.on("IssueUpdate", realtimeMessageHandler);
      else if (!issueId && connection?.connectionId) connection?.off("IssueUpdate", realtimeMessageHandler);
    } else {
      try {
        start().then(() => messages.setCurrentBoardId(authStore.getCurrentBoardId as number));
      } catch (e) {
        notifier.show({
          message: t("notifier:error.real_time_connect_fail"),
          theme: "error",
          timeout: 90000000000,
        });
      }
    }
    return () => connection?.off("IssueUpdate", realtimeMessageHandler);
  }, [connection, issueId]);

  return (
    <>
      {!!issueId && (
        <IssueCommonDialog
          issueId={issueId}
          onRedirectToOtherIssue={handleReopenById}
          open={true}
          isLoading={!(!!updater.currentState && !isLoading)}
          onClose={handleClose}
          issueType={IssueTypeKeys.task}
          customFields={customFields}
          // onIssueReload={handleIssueReload}
          updater={updater}
          formType={IssueFormKeys.update}
        />
      )}
    </>
  );
};
