import React from "react";
import "./CompanyEditFormScheduleForm.scss";
import SubheaderText from "../../../../elements/subheaderText/SubheaderText";
import { useTranslation } from "react-i18next";
import { CompanyScheduleDto } from "../../../../../api";
import { ScheduleFormTypes } from "./CompanyEditFormScheduleForm";
import LabelWithTimePicker from "./labelWithTimePicker";
import { days } from "../../../../../utils/dateArrays";
import { Alert, Button, Checkbox, Divider } from "../../../../uiKit";
import { theme } from "antd";

export interface ICompanyEditFormScheduleFormView {
  scheduleData: ScheduleFormTypes;
  sameDaysData: { timeDayStart: string; timeDayEnd: string };
  readOnly: boolean;
  dayStart?: 0 | 1;
  sortDaysInSchedule(data: CompanyScheduleDto[]): CompanyScheduleDto[];
  handleDayChange(day: CompanyScheduleDto): void;
  setSameDaysData({ timeDayStart, timeDayEnd }: { timeDayStart: string; timeDayEnd: string }): void;
  setScheduleData(data: ScheduleFormTypes): void;
  handleScheduleSave(): void;
}

function CompanyEditFormScheduleFormView(props: ICompanyEditFormScheduleFormView) {
  const { t } = useTranslation();
  const { useToken } = theme;
  const { token } = useToken();

  const isSecondDateEqualOrSmallerThanFirst = (dateFrom: string | null, dateTo: string | null): boolean => {
    // Compare two dates (hh:mm:ss / hh:mm:ss)
    if (dateFrom == null || dateTo == null) return false;

    // const dF = dateFrom.split(":");
    // const dT = dateTo.split(":");

    return new Date("1/1/2011 " + dateTo) <= new Date("1/1/2011 " + dateFrom);
    // const totalSecondsF = parseInt(dF[0] * 3600 + dF[1] * 60 + dF[0]);
    // const totalSecondsT = parseInt(dT[0] * 3600 + dT[1] * 60 + dT[0]);
  };

  return (
    <>
      <SubheaderText text={t("ui:subheader.work_schedule")} />
      <div
        style={{
          pointerEvents: props.readOnly ? "none" : undefined,
          borderRadius: "8px",
          border: `1px solid ${token.colorBorder}`,
        }}
        className="CompanyEditFormScheduleForm-Wrapper pa-3"
      >
        <Checkbox
          checked={props.scheduleData.scheduleIsSameOnWeekdays}
          onChange={(event) =>
            props.setScheduleData({
              ...props.scheduleData,
              scheduleIsSameOnWeekdays: event.target.checked,
            })
          }
        >
          {props.scheduleData.scheduleIsSameOnWeekdays ? (
            <LabelWithTimePicker
              day={t("ui:label.weekdays_same_schedule")}
              fromValue={props.sameDaysData.timeDayStart}
              toValue={props.sameDaysData.timeDayEnd}
              onFromValueChange={(value) =>
                props.setSameDaysData({
                  ...props.sameDaysData,
                  timeDayStart: value,
                })
              }
              onToValueChange={(value) =>
                props.setSameDaysData({
                  ...props.sameDaysData,
                  timeDayEnd: value,
                })
              }
            />
          ) : (
            (t("ui:label.weekdays_same_schedule") as string)
          )}
        </Checkbox>
        <Divider className="my-3" />
        <div className="d-stack-column spacing-1">
          {props.sortDaysInSchedule(props.scheduleData?.schedule)?.map((day: CompanyScheduleDto, index: number) => (
            <div key={day.id}>
              <Checkbox
                className="CompanyEditFormScheduleForm-control"
                checked={day.isActive}
                onChange={(event) => props.handleDayChange({ ...day, isActive: event.target.checked })}
              >
                <LabelWithTimePicker
                  disabled={!day.isActive}
                  day={t(days.find((d) => d.id == day.dayOfWeek)?.name as string)}
                  fromValue={day.timeDayStart}
                  toValue={day.timeDayEnd}
                  onFromValueChange={(value) => props.handleDayChange({ ...day, timeDayStart: value })}
                  onToValueChange={(value) => props.handleDayChange({ ...day, timeDayEnd: value })}
                />
              </Checkbox>
            </div>
          ))}
        </div>
        {props.scheduleData.schedule.some(
          (s) => isSecondDateEqualOrSmallerThanFirst(s.timeDayStart ?? null, s.timeDayEnd ?? null) && s.isActive
        ) && (
          <Alert
            className="py-1 px-2 mt-3"
            type="error"
            showIcon
            message={t("parse:company_schedule_days_overlapping")}
          />
        )}
        {!props.readOnly && (
          <>
            <Divider className="my-3" />
            <Button
              disabled={props.scheduleData.schedule.some(
                (s) => isSecondDateEqualOrSmallerThanFirst(s.timeDayStart ?? null, s.timeDayEnd ?? null) && s.isActive
              )}
              className=""
              onClick={() => props.handleScheduleSave()}
              variant="filled"
            >
              {t("ui:button.save_schedule")}
            </Button>
          </>
        )}
      </div>
    </>
  );
}

export default CompanyEditFormScheduleFormView;
