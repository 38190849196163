import React from "react";
import ZoomButton from "../../../../../elements/zoomButton/ZoomButton";
import { useRootStore } from "../../../../../../hooks";

export const MainHeader = () => {
  const { groupCompanyStore } = useRootStore();

  return (
    <div style={{ width: "100%" }} className="d-stack-row align-center justify-end">
      <ZoomButton
        value={groupCompanyStore.getZoom}
        onChange={groupCompanyStore.setZoom.bind(groupCompanyStore)}
        step={0.1}
        min={0.7}
        max={1.2}
      />
    </div>
  );
};

