import React, { useEffect, useRef } from "react";
// Connect Inter Font
import "@fontsource/inter/300.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/700.css";
import "./styles/global.scss";
import { useRootStore } from "./hooks";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
// Import Google Analytics v4
import GA4React from "ga-4-react";
// Import Carrot Quest Localizations
import { carrotQuestLocaleEn, carrotQuestLocaleRu } from "./locales/service/carrot-quest";
// Import Dayjs
import dayjs from "dayjs";
import "./services/dayjs";
// import AntD locales
import ru_RU from "antd/lib/locale/ru_RU";
import en_US from "antd/lib/locale/en_US";
import uk_UA from "antd/lib/locale/uk_UA";
import zh_TW from "antd/lib/locale/zh_TW";
// Import all required components
import { App as AppWrapper, ConfigProvider, theme } from "antd";
import { Layout } from "./components/uiKit";
import { ShowUpdateNoteDialog } from "./components/modules/dialogs/showUpdateNoteDialog/ShowUpdateNoteDialog";
import { ConnectionChecker } from "./components/service/connectionChecker/ConnectionChecker";
import { AppPreloader } from "./components/service/appPreloader/AppPreloader";
import { GuideDrawer } from "./components/modules/drawers/guide/GuideDrawer";
import AppRouter from "./components/service/appRouter/AppRouter";
import ImageViewer from "./components/service/imageViewer/ImageViewer";
import FileViewer from "./components/service/fileViewer/FileViewer";
// Import all required DTOs
import { UserDto } from "./api";
import { Locale } from "antd/es/locale";
// Import DayJS locales
require("dayjs/locale/ru");
require("dayjs/locale/uk");
require("dayjs/locale/en");
require("dayjs/locale/zh-tw");

declare global {
  interface Window {
    carrotquest: any;
  }
}

function AppObserved() {
  const { appStore, authStore } = useRootStore();
  const { defaultAlgorithm, darkAlgorithm } = theme;

  const identityFallback = useRef<UserDto | null>(null);
  const isCarrotQuestInitialized = useRef<boolean>(false);
  const antDLocale = useRef<Locale>(en_US);

  const appSetLocaleDayjsAntd = (localeCode: string) => {
    dayjs.locale(localeCode == "zh" ? "zh-tw" : localeCode);
    if (localeCode == "ru") antDLocale.current = ru_RU;
    if (localeCode == "en") antDLocale.current = en_US;
    if (localeCode == "uk") antDLocale.current = uk_UA;
    if (localeCode == "zh") antDLocale.current = zh_TW;
    document.body.setAttribute("lang", localeCode);
    dayjsSetStartOfWeek(localeCode);
  };

  const dayjsSetStartOfWeek = (localeCode?: string) => {
    const weekStart = authStore.getInitialInfo?.identity?.companies?.find(
      (u2c) => u2c.companyId == authStore.getCurrentCompanyId
    )?.company?.weekStart;
    weekStart != null &&
      dayjs.updateLocale((localeCode == "zh" ? "zh-tw" : localeCode) ?? dayjs.locale(), {
        weekStart: weekStart,
      });
  };

  useEffect(() => {
    if (appStore.getAppLocale == null || appStore.getAppLocale.trim().length == 0) return;
    appSetLocaleDayjsAntd(appStore.getAppLocale.trim());
  }, [appStore.getAppLocale]);

  useEffect(() => {
    dayjsSetStartOfWeek();
  }, [authStore.getInitialInfo]);

  // // Initialize Google Analytics
  // try {
  //   setTimeout((_) => {
  //     const ga4react = new GA4React("G-P9WJMQHY48");
  //     ga4react.initialize().catch((err) => console.error(err));
  //   }, 500);
  // } catch (err) {
  //   console.error(err);
  // }

  return (
    <ConfigProvider
      locale={antDLocale.current}
      theme={{
        algorithm: appStore.getAppTheme == 1 ? darkAlgorithm : defaultAlgorithm,
        token: {
          colorPrimary: "#5672ff",
          colorInfo: "#5672ff",
          colorError: "#fd5a44",
          colorSuccess: "#00b894",
          colorWarning: "#ffcc4d",
          fontFamily:
            "-apple-system, BlinkMacSystemFont, Inter, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
        },
      }}
    >
      <AppWrapper>
        <Layout className="app-layout__wrapper">
          <GuideDrawer />
          <ShowUpdateNoteDialog />
          <ConnectionChecker />
          <ImageViewer />
          <FileViewer />
          <AppPreloader />
          <AppRouter />
          {/*<div id="notification__wrapper">*/}
          {/*  <div id="notification__inner" />*/}
          {/*</div>*/}
        </Layout>
      </AppWrapper>
    </ConfigProvider>
  );
}

export const App = observer(AppObserved);
