import React from "react";
import { useRootStore } from "../../../../../../hooks";
import { HeaderBreadcrumbs } from "../../headers/headerBreadcrumbs/HeaderBreadcrumbs";
import { CreateRegulationContent } from "../../pages";
import PageHeader from "../../../../layouts/pageHeader/PageHeader";
import PageContent from "../../../../layouts/pageContent/PageContent";

export const CreateRegulation = () => {
  const { regulationStore } = useRootStore();

  return (
    <>
      <PageHeader>
        <HeaderBreadcrumbs />
      </PageHeader>
      <PageContent isLoading={regulationStore.getLoading} contentPosition={{ maxWidth: "1200px" }}>
        <CreateRegulationContent />
      </PageContent>
    </>
  );
};
