import React from "react";
import { IIssueSwitchStatusView } from "./IssueSwitchStatus.interface";
import { Select, SelectOption, Spin } from "../../uiKit";

export const IssueSwitchStatusView = (props: IIssueSwitchStatusView) => {
  const colorKey = props.currentStatus?.colorSchemeKey;

  return (
    <>
      <Spin
        size="small"
        wrapperClassName={`issue-switch-status issue-switch-background__${colorKey}`}
        spinning={props.isLoading}
      >
        <Select
          size="middle"
          style={{ borderColor: "red", width: "120px" }}
          onChange={props.onSelectNextStatus}
          value={props.currentStatus?.name}
          fullWidth
          disabled={props.isDisabled}
        >
          {props.nextStatuses?.map((status) => (
            <SelectOption key={status.id} value={status.id}>
              {status.name}
            </SelectOption>
          ))}
        </Select>
      </Spin>
    </>
  );
};
