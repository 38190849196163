import React from "react";
import EmployeeInviteShowDialogView from "./EmployeeInviteShowDialogView";
import { useNotifier } from "../../../../hooks";
import { useTranslation } from "react-i18next";
import { CompanyInviteDto } from "../../../../api";

interface IEmployeeInviteShowDialog {
  open: boolean;
  inviteData: CompanyInviteDto;

  onClose(): void;
}

function EmployeeInviteShowDialog(props: IEmployeeInviteShowDialog) {
  const notifier = useNotifier();
  const { t } = useTranslation();

  const handleCodeCopy = async (code: string) => {
    await navigator.clipboard
      .writeText(code)
      .then(() => notifier.show({ message: t("notifier:success.copy"), theme: "success" }))
      .catch(() => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" }));
  };

  const handleLinkCopy = async (link: string) => {
    await navigator.clipboard
      .writeText(link)
      .then(() => notifier.show({ message: t("notifier:success.copy"), theme: "success" }))
      .catch(() => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" }));
  };

  return <EmployeeInviteShowDialogView {...props} handleLinkCopy={handleLinkCopy} handleCodeCopy={handleCodeCopy} />;
}

export default EmployeeInviteShowDialog;
