import React, { createRef, useRef, useState } from "react";
import { IssueHistoryConst } from "../../../../../types/consts";
import { useTranslation } from "react-i18next";
import { contentIsEmpty } from "../../../../../../../../utils/textEditor";
import { IIssueHistorySenderBlock } from "./IssueHistorySenderBlock.interface";
import { IssueHistorySenderBlockView } from "./IssueHistorySenderBlockView";
import { IAudioRecorderRef, TAudioRecorderState } from "../../../../../../../uiKit/audioRecorder/AudioRecorder";
import { api } from "../../../../../../../../services";

export const IssueHistorySenderBlock = (props: IIssueHistorySenderBlock): JSX.Element => {
  const { t } = useTranslation();
  const uploadFilesInputRef = createRef();
  const audioRecorderRef = useRef<IAudioRecorderRef>(null);
  const [audioMessageBlob, setAudioMessageBlob] = useState<Blob | null>(null);

  const [isFileLoaderUploading, setIsFileLoaderUploading] = useState<boolean>(false);
  const [loadingAcceptButton, setLoadingAcceptButton] = useState<boolean>(false);
  const [audioRecorderState, setAudioRecorderState] = useState<TAudioRecorderState>(null);

  const isFileLoaderUploadingCopy = useRef(false);
  const setIsFileLoaderUploadingSync = (v: boolean) => {
    setIsFileLoaderUploading(v);
    isFileLoaderUploadingCopy.current = v;
  };

  const sendPopupItems = [
    {
      id: 2,
      text: t("ui:button.send_comment"),
      action: () => props.onSendComment(IssueHistoryConst.Comment),
    },
    {
      id: 1,
      text: t("ui:button.send_message"),
      action: () => props.onSendComment(IssueHistoryConst.Message),
    },
  ];

  const handleAcceptComments = async () => {
    setLoadingAcceptButton(true);
    await props.onAcceptIssueHistory();
    setLoadingAcceptButton(false);
  };

  const getIsDisabled = (): boolean => {
    if (audioRecorderState == "recording") return true;
    if (audioMessageBlob != null) return false;
    if (!(props.allowedCommentsTypes && props.allowedCommentsTypes?.length > 0)) return true;
    // if (isFileLoaderUploading) return true;
    if (
      !isFileLoaderUploading &&
      !(
        props.attachments.filter((a) => a.url != null && a.url.length > 0).length == 0 ||
        props.attachments.filter((a) => a.url == null).length > 0
      )
    )
      return false;
    if (props.commentIssue.length == 0 || contentIsEmpty(props.commentIssue)) return true;
    return false;
  };

  const uploadAudioMessage = async () => {
    console.log(audioMessageBlob != null, "blob not null");
    if (audioMessageBlob == null) return;
    let formData = new FormData();
    formData.append("files", audioMessageBlob, `audioMessage-${new Date().getTime()}.wav`);
    const r = await api.staticFile.upload(formData, "audio");
    if (r == null) {
      console.log("error");
      return;
    }
    if (r[0] == null) return;
    props.setAttachments([...props.attachments, ...r]);
    console.log(r);
  };

  const handleSaveAudioMessage = async (blob: Blob | null) => {
    setAudioMessageBlob(blob);
    // if (blob == null) return;
    // let formData = new FormData();
    // formData.append("files", blob, `audioMessage-${new Date().getTime()}.wav`);
    // const r = await api.staticFile.upload(formData, "audio");
    // if (r == null) {
    //   console.log("error");
    //   return;
    // }
    // if (r[0] == null) return;
    // props.setAttachments([...props.attachments, ...r]);
  };

  const handleCommentSend = async (type: string) => {
    await uploadAudioMessage();
    props.onSendComment && props.onSendComment(type);
    audioRecorderRef.current?.recordingStop();
  };

  return (
    <IssueHistorySenderBlockView
      isAllowAceptButton={props.isAllowAceptButton}
      audioRecorderState={audioRecorderState}
      onAcceptComments={props.onAcceptIssueHistory}
      loadingAcceptButton={loadingAcceptButton}
      editorRef={props.editorRef}
      audioRecorderRef={audioRecorderRef}
      allowedCommentsTypes={props.allowedCommentsTypes}
      commentIssue={props.commentIssue}
      onChangeComment={props.onChangeComment}
      isLoadingSend={props.isLoadingSend}
      onSendComment={(type: string) => handleCommentSend(type)}
      attachments={props.attachments}
      setAttachments={props.setAttachments}
      onAcceptIssueHistory={handleAcceptComments}
      onDeleteAttachmentById={props.onDeleteAttachmentById}
      uploadFilesInputRef={uploadFilesInputRef}
      setIsFileLoaderUploadingSync={setIsFileLoaderUploadingSync}
      sendPopupItems={sendPopupItems}
      onAudioRecorderStateChange={setAudioRecorderState}
      getIsDisabled={getIsDisabled}
      onSaveAudioMessage={handleSaveAudioMessage}
    />
  );
};
