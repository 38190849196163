import { useTranslation } from "react-i18next";
import { IssueDto } from "../../../../../../api";
import { useRootStore } from "../../../../../../hooks";
import { Text, Tooltip } from "../../../../../uiKit";
import { PlanningTaskCard } from "./planningTaskCard/PlanningTaskCard";
import { IPlanningTaskCardsView } from "./PlanningTaskCards.interface";
import "./PlanningTaskCards.scss";

export const PlanningTaskCardsView = (props: IPlanningTaskCardsView) => {
  const { orgchartStore } = useRootStore();
  const { t } = useTranslation();
  const getOrgchartById = (id: number) => orgchartStore.getOrgchartsList.find((o) => o.id == id);

  return (
    <>
      {props.groupedArr.map((arr: IssueDto[]) => {
        const name = arr.find((item) => item?.role?.name)?.role?.name;
        return (
          <div className="mt-3" key={props.onIncrementGroupId()}>
            <div className="d-stack-row align-center justify-start">
              {!name ? null : (
                <Tooltip title={(arr[0]?.orgchartId && getOrgchartById(arr[0].orgchartId)?.name) ?? ""}>
                  <div
                    className="mr-1"
                    style={{
                      width: "15px",
                      height: "15px",
                      borderRadius: "50%",
                      background:
                        (arr[0]?.orgchartId &&
                        getOrgchartById(arr[0].orgchartId)?.colorHex != undefined &&
                        (getOrgchartById(arr[0].orgchartId)?.colorHex ?? "").trim().length > 0
                          ? arr[0]?.orgchartId && getOrgchartById(arr[0].orgchartId)?.colorHex
                          : "var(--color-layout-fill-base)") ?? "var(--color-layout-fill-base)",
                    }}
                  />
                </Tooltip>
              )}
              <Text weight="bold" className="planning-task-cards-container__title">
                {arr.find((item) => item?.role?.name)?.role?.name ?? t("common:planning.no_function")}
              </Text>
            </div>
            {arr.map((issue) => (
              <div className="planning-task-cards-container__cards mt-3" key={issue.id}>
                <PlanningTaskCard
                  loadingTaskId={props.loadingTaskId}
                  key={issue.id}
                  issue={issue}
                  onAddIssue={props.onAddIssue}
                  plan={props.plan}
                  issuesFromCommunicationsRestart={props.issuesFromCommunicationsRestart}
                />
              </div>
            ))}
          </div>
        );
      })}
    </>
  );
};
