import React, { useState } from "react";
import RestorePasswordFormView from "./RestorePasswordFormView";
import { useNotifier, useRootStore } from "../../../../hooks";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { api } from "../../../../services";

function RestorePasswordForm() {
  const notifier = useNotifier();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { authStore } = useRootStore();

  const [step, setStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleSendCode = async ({ email }: { email: string }) => {
    const r = await api.auth.resetPasswordSendCode({ email });
    if (!r) {
      notifier.show({ message: t("notifier:error.bad_password_reset_email"), theme: "error" });
      return;
    }
    setStep(1);
  };

  const handleReset = async ({ email, code, password }: { email: string; code: string; password: string }) => {
    const r = await api.auth.resetPassword({ confirmationCode: code, newPassword: password });
    notifier.showSuccessError(
      t("notifier:success.good_password_reset"),
      t("notifier:error.bad_password_reset"),
      r != null
    );
    const l = await authStore.authorizeWithCredentials({
      email,
      password,
    });
    if (l) {
      navigate("/orgchart");
    }
  };

  return (
    <RestorePasswordFormView
      step={step}
      isLoading={isLoading}
      setStep={setStep}
      handleReset={handleReset}
      handleSendCode={handleSendCode}
    />
  );
}

export default RestorePasswordForm;
