import React from "react";
import { TagCheckableView } from "./TagCheckableView";
import { CheckableTagProps } from "antd/es/tag";

interface ITag extends CheckableTagProps {
  //
}

export function TagCheckable(props: ITag) {
  return <TagCheckableView {...props} />;
}
