import React, { useRef, useState } from "react";
import { RecurringIssueDto, RoleDto } from "../../../../api";
import TaskRecurringCreateEditForm from "../../forms/taskRecurringCreateEditForm/TaskRecurringCreateEditForm";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions } from "../../../uiKit";

export interface IRecurringIssueCreateEditDialogView {
  open: boolean;
  functionData?: RoleDto;
  data?: RecurringIssueDto;
  createOn?: "admins" | "executors" | "all";

  onCreate?: () => void;
  onSave?: () => void;
  onClose(): void;
}

function RecurringIssueCreateEditDialogView(props: IRecurringIssueCreateEditDialogView) {
  const { t } = useTranslation();
  const createEditFormRef = useRef<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const onFormLoadingChange = (val: boolean) => {
    setIsLoading(val);
  };

  return (
    <Dialog
      width={1200}
      open={props.open}
      onClose={() => props.onClose()}
      destroyOnClose
      scrollStrategy="dialogBody"
      title={props.data ? t("parse:editing_routine") : t("parse:creating_routine")}
    >
      {props.open && (
        <TaskRecurringCreateEditForm
          ref={createEditFormRef}
          onFormLoadingChange={onFormLoadingChange}
          hideSaveButton
          {...props}
        />
      )}
      <DialogActions>
        <Button onClick={() => props.onClose()} variant="text">
          {t("ui:button.cancel")}
        </Button>
        <Button loading={isLoading} onClick={() => createEditFormRef.current?.saveTask()} variant="filled">
          {t("ui:button.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RecurringIssueCreateEditDialogView;
