import React from "react";
import { Icon, IconButton, Text } from "../../../../../uiKit";
import { AutocompleteAsync } from "../../../../../elements/autocompleteAsync/AutocompleteAsync";
import { IPlanningPageMenu } from "./PlanningPageMenu.interface";
import { useTranslation } from "react-i18next";
import { useColorSchemeStyles } from "../../../../../../hooks/useColorSchemeStyles";
import { colorSchemeType } from "../../../../../../utils/colorScheme";
import { FiSidebar } from "@react-icons/all-files/fi/FiSidebar";

export const PlanningPageMyPlanMenu = (props: IPlanningPageMenu) => {
  const { t } = useTranslation();
  const { findColorByKey } = useColorSchemeStyles();
  const colorScheme = findColorByKey(props.plan?.statusColorScheme as colorSchemeType);

  return (
    <div className="d-stack-column">
      <div className="d-stack-row justify-space-between">
        <div className="d-stack-row align-center">
          {props.isHideSidebar && (
            <IconButton
              onClick={props.onHidingSidebarChange}
              children={<Icon component={() => <FiSidebar />} />}
              className="mr-4"
            />
          )}
          <Text
            weight="bold"
            className="planning-page-main-content__title mr-6"
            children={t("ui:subheader.week_plan")}
          />
          <AutocompleteAsync
            style={{ width: "335px" }}
            type="plan"
            value={props.currentPlanId}
            onChange={props.onPlanIdChange}
            requestOptions={{ userId: props.userId, orderBy: "date_created", orderByType: "desc" }}
            showSearch={false}
          />
        </div>
        <div
          style={{ background: colorScheme.backgroundColor, color: colorScheme.textColor }}
          className="planning-page-main-content__indicator d-flex justify-center align-center"
        >
          {props.plan?.statusName}
        </div>
      </div>
      {props.plan?.canBeMigratedFromPlanId && (
        <span onClick={props.onMigrateIssuesToPlan} className={`planning-page-main-content__text`}>
          {t("text:transfer_undone_tasks")}
        </span>
      )}
    </div>
  );
};
