import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import SubheaderText from "../../../../elements/subheaderText/SubheaderText";
import { useRootStore } from "../../../../../hooks";
import { Select, SelectOption } from "../../../../uiKit";

function DrawerStateSwitcher() {
  const { t } = useTranslation();
  const { appStore } = useRootStore();
  const stateList = [
    { id: 0, text: t("common:menu.left_drawer_state.always_expanded"), value: "alwaysExpanded" },
    { id: 1, text: t("common:menu.left_drawer_state.always_collapsed"), value: "alwaysCollapsed" },
    { id: 2, text: t("common:menu.left_drawer_state.auto_collapsed"), value: "collapseAutomatically" },
    // { id: 3, text: t("common:menu.left_drawer_state.manual_expanded"), value: "expandManually" },
  ];

  return (
    <>
      <SubheaderText text={t("ui:subheader.left_drawer_state")} />
      <Select value={appStore.getDrawerBehavior} onChange={(value) => appStore.setDrawerBehavior(value)} fullWidth>
        {stateList.map((state) => (
          <SelectOption key={state.id} value={state.value}>
            {state.text}
          </SelectOption>
        ))}
      </Select>
    </>
  );
}

export default observer(DrawerStateSwitcher);
