import React, { useMemo } from "react";
import { observer } from "mobx-react-lite";
import { EmojiPickerThemeType, IEmojiPicker } from "../interfaces/Reactions.interface";
import { useRootStore } from "../../../../hooks";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";

export const EmojiPicker = observer((props: IEmojiPicker) => {
  const { appStore } = useRootStore();

  const theme: EmojiPickerThemeType = useMemo(() => {
    switch (appStore.getAppTheme) {
      case 0:
        return "light";
      case 1:
        return "dark";
      default:
        return "light";
    }
  }, [appStore.getAppTheme]);

  const locale = useMemo(() => {
    return appStore.getAppLocale;
  }, [appStore.getAppLocale]);

  return (
    <Picker
      theme={theme}
      key={`${appStore.getAppTheme}` + appStore.getAppLocale}
      locale={locale}
      data={data}
      emojiSize="20"
      previewPosition="none"
      onEmojiSelect={(e: any) => {
        props.onReact(e.native);
        props.onClose();
      }}
    />
  );
});
