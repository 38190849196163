import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageHeader from "../../modules/layouts/pageHeader/PageHeader";
import PageContent from "../../modules/layouts/pageContent/PageContent";
import { useTranslation } from "react-i18next";
import { useApi, useClaims, useCopyToClipboard, useRootStore } from "../../../hooks";
import { api } from "../../../services";
import "./EmployeeIndividualPage.scss";
import EmployeeDeleteDialog from "../../modules/dialogs/employeeDeleteDialog/EmployeeDeleteDialog";
import { permissionKeys } from "../../../utils/permissions";
import { UserContactDto } from "../../../api";
import { FiAtSign } from "@react-icons/all-files/fi/FiAtSign";
import { FiPhone } from "@react-icons/all-files/fi/FiPhone";
import { FiSend } from "@react-icons/all-files/fi/FiSend";
import { Avatar, Card, Chip, Skeleton } from "../../uiKit";
import { DropdownMenu } from "../../service/dropdownMenu/dropdownMenu";
import { PopupMenuItemTypes } from "../../service/dropdownMenu/dropdownMenu.interface";
import { EmployeeRolesList } from "../../modules/pages/employee/employeeRolesList/EmployeeRolesList";

export default function EmployeeIndividualPage() {
  const { id } = useParams();
  const { t } = useTranslation();
  const { authStore } = useRootStore();
  const claims = useClaims();
  const navigate = useNavigate();
  const [copyFn] = useCopyToClipboard();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const handleApiError = (r?: any) => {
    // console.log("Error ", err);
    if (r == null) {
      navigate("/employee");
    }
  };

  const handleEditClick = () => {
    navigate("/employee/" + id + "/edit");
  };
  const onDeleteSuccess = () => {
    navigate("/employee");
  };

  const getContactLinkByType = (contact: UserContactDto | null) => {
    return contact?.deepLink ?? "/#";
  };

  const menuItems: PopupMenuItemTypes[] = [
    {
      id: 0,
      text: t("common:menu.employee_card.edit"),
      hidden: !claims.has(permissionKeys.user.edit),
      action: handleEditClick,
    },
    {
      id: 1,
      // disabled: authStore.getInitialInfo?.identity?.id == Number(id),
      text: t("common:menu.employee_card.delete"),
      hidden: !claims.has(permissionKeys.user.delete) || authStore.getInitialInfo?.identity?.id == Number(id),
      action: () => setIsDeleteDialogOpen(true),
    },
  ].filter((i) => !i.hidden);

  const userData = useApi(
    () => api.user.getById(Number(id)),
    () => handleApiError(),
    (r) => handleApiError(r)
  );

  return (
    <>
      <PageHeader title={t("common:page_title.employee")} />
      <PageContent isLoading={userData.isLoading || userData.value == null}>
        <EmployeeDeleteDialog
          open={isDeleteDialogOpen}
          userId={Number(id)}
          companyId={authStore.getInitialInfo?.identity?.currentCompanyId as number}
          onSuccess={onDeleteSuccess}
          onClose={() => setIsDeleteDialogOpen(false)}
        />
        <Card
          bodyStyle={{ display: "flex", flexWrap: "nowrap" }}
          // loading={userData.isLoading || userData.value == null}
        >
          <Skeleton loading={userData.isLoading || userData.value == null} avatar={{ size: 100 }}>
            <Avatar
              text={[userData.value?.lastName, userData.value?.firstName]}
              color={userData.value?.color}
              src={userData.value?.avatar?.image?.url}
              size={100}
            />
            <div className="flex-grow-1 d-stack-column justify-center ml-3">
              <div className="d-stack spacing-3 align-center">
                <span className="employee-page-individual_user-name">
                  {(userData.value?.name ?? "")?.trim().length == 0
                    ? userData.value?.nameFallback
                    : userData.value?.name}
                </span>
                <Chip
                  type={userData.value?.currentAccessType == 0 ? "default" : "warning"}
                  label={
                    userData.value?.currentAccessType == 0
                      ? t("parse:employee_type.full")
                      : t("parse:employee_type.limited")
                  }
                />
              </div>
              <div className="my-2">
                <EmployeeRolesList roles={userData.value?.roles ?? []} />
              </div>
              {/*<div className="employee-page-individual_roles-list">*/}
              {/*  /!*[...Array.from(userData.value?.roles?.map((u2r: User2RoleDto) => u2r.role?.name ?? []) ?? [])]*!/*/}
              {/*  {userData.value?.roles*/}
              {/*    ?.map((u2r: User2RoleDto) => u2r.role?.name ?? [])*/}
              {/*    ?.map((roleName, index) => (*/}
              {/*      <span key={index}>*/}
              {/*        {index > 0 && <span className="employee-page-individual_role-divider">·</span>}*/}
              {/*        <span className="employee-page-individual_role" key={index}>*/}
              {/*          {roleName}*/}
              {/*        </span>*/}
              {/*      </span>*/}
              {/*    ))}*/}
              {/*</div>*/}
              <div className="employee-page-individual_info-block">
                {userData.value?.email && userData.value?.email?.length > 0 && (
                  <div className="employee-page-individual_info-element __email">
                    <FiAtSign fontSize="small" />
                    <a href={"mailto:" + userData.value?.email}>{userData.value?.email}</a>
                  </div>
                )}
                {claims.has(permissionKeys.user.viewUserPhoneNumber) &&
                  userData.value?.phoneNumber &&
                  userData.value?.phoneNumber?.length > 0 && (
                    <div className="employee-page-individual_info-element __phone">
                      <FiPhone fontSize="small" />
                      <a href={"tel:" + userData.value?.phoneNumber}>{userData.value?.phoneNumber}</a>
                    </div>
                  )}
                {(userData.value?.contact?.name ?? "").trim().length > 0 && (
                  <div className="employee-page-individual_info-element __contact-type">
                    <FiSend fontSize="small" />
                    <span>{t("ui:subheader.communication_type")}:</span>
                    <a
                      onClick={() => copyFn(userData.value?.contact?.value ?? "")}
                      // target="_blank"
                      href={getContactLinkByType(userData.value?.contact ?? null)}
                    >
                      {userData.value?.contact?.name}{" "}
                      {(userData.value?.contact?.value ?? "").length > 0 ? ` (${userData.value?.contact?.value})` : ""}
                    </a>
                  </div>
                )}
              </div>
            </div>
            {menuItems.filter((i) => !i.hidden).length > 0 && (
              <div className="d-stack-column justify-start ml-2">
                <DropdownMenu items={menuItems} />
              </div>
            )}
          </Skeleton>
        </Card>
      </PageContent>
    </>
  );
}
