import React, { useState } from "react";
import PageContent from "../../modules/layouts/pageContent/PageContent";
import "./SandboxPage.scss";
import PageHeader from "../../modules/layouts/pageHeader/PageHeader";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FiChevronLeft } from "@react-icons/all-files/fi/FiChevronLeft";
import { IconButton } from "../../uiKit";
import { useRootStore } from "../../../hooks";
import { SegmentedLabeledOption } from "antd/lib/segmented";
import { RelationsKeys } from "../../modules/pages/communications/constants/keys";
import { RelationsTypes } from "../../modules/pages/communications/components/boardRelationSwitch/BoardRelationSwitch.interface";
import { BiAbacus } from "@react-icons/all-files/bi/BiAbacus";
import { BiAnalyse } from "@react-icons/all-files/bi/BiAnalyse";
import { BiBeer } from "@react-icons/all-files/bi/BiBeer";
import { Skeleton } from "antd";

import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { observer } from "mobx-react-lite";

function SandboxPage() {
  const { t } = useTranslation();
  const { appStore } = useRootStore();
  const { issueInitDataStore } = useRootStore();
  const navigate = useNavigate();

  const [date, setDate] = useState<Date | null>();
  const [time, setTime] = useState<Date | null>();
  const [executorIds, setExecutorIds] = useState<number[]>([]);
  // const handleClick = (date: Date | null): void => {
  //   navigate({search: `?${IssueQueryKeys.issueId}=${id}&${IssueQueryKeys.issueType}=${IssueKeys.task}`})
  // }

  const relations: SegmentedLabeledOption[] = [
    {
      value: RelationsKeys.My as RelationsTypes,
      disabled: false,
      label: <BiAbacus />,
    },
    {
      value: RelationsKeys.Controlled as RelationsTypes,
      disabled: false,
      label: <BiAnalyse />,
    },
    {
      value: RelationsKeys.Participated as RelationsTypes,
      disabled: false,
      label: <BiBeer />,
    },
  ];

  const handleGetTheme = () => {
    switch (appStore.getAppTheme) {
      case 0:
        return "light";
      case 1:
        return "dark";
    }
  };

  return (
    <>
      <PageHeader title={t("common:page_title.sandbox") + " / DR"}>
        <IconButton onClick={() => navigate("/sandbox")} size="small" className="ml-auto">
          <FiChevronLeft />
        </IconButton>
      </PageHeader>
      <PageContent isFilled>
        <div>
          <Picker
            theme={handleGetTheme()}
            key={`${appStore.getAppTheme}` + appStore.getAppLocale}
            locale={appStore.getAppLocale}
            data={data}
            emojiSize="20"
            navPosition="None"
            previewPosition="none"
            onEmojiSelect={(e: string) => console.log(e)}
          />
        </div>
        <div className="d-stack-column">
          <Skeleton.Input className="mb-2 regulation-skeleton" />
          <Skeleton.Avatar size={35} />
        </div>
      </PageContent>
    </>
  );
}

export default observer(SandboxPage);
