import React, { useEffect, useRef, useState } from "react";
import ScheduleDaySetupDialogView from "./ScheduleDaySetupDialogView";
import { User2RoleDto, UserScheduleDto, UserScheduleSubstituteDto } from "../../../../api";
import { api } from "../../../../services";

interface IScheduleDaySetupDialog {
  open: boolean;
  type: "fullDay" | "weekend" | "vacation" | "sick" | null; // Рабочий день, выходной, отпуск или больничный
  user: User2RoleDto | null;
  dateFrom: Date | null;
  dateTo: Date | null;

  onApply: (v: UserScheduleDto, forceReload?: boolean) => void;
  onClose: () => void;
}

function ScheduleDaySetupDialog(props: IScheduleDaySetupDialog) {
  const [time, setTime] = useState({
    start: "09:00:00",
    end: "18:00:00",
  });
  const [date, setDate] = useState({
    start: props.dateFrom ?? new Date(),
    end: props.dateTo ?? new Date(),
  });
  date.start.setUTCHours(0, 0, 0, 0);
  date.end.setUTCHours(0, 0, 0, 0);
  const [selectAllFunctions, setSelectAllFunctions] = useState<boolean>(false);
  const [substituteUserId, setSubstituteUserId] = useState<number | null>(null);
  const modifiedSchedule = useRef<UserScheduleDto | null>(null);

  let substituteConfig: UserScheduleSubstituteDto[] = [];

  useEffect(() => {
    setDate({
      start: props.dateFrom ?? new Date(),
      end: props.dateTo ?? new Date(),
    });
  }, [props.dateFrom, props.dateTo]);

  useEffect(() => {
    date.start.setUTCHours(0, 0, 0, 0);
    date.end.setUTCHours(0, 0, 0, 0);
  }, [date]);

  const handleClose = () => {
    setTime({
      start: "09:00:00",
      end: "18:00:00",
    });
    setDate({
      start: props.dateFrom ?? new Date(),
      end: props.dateTo ?? new Date(),
    });
    setSelectAllFunctions(false);
    setSubstituteUserId(null);
    // substituteConfig = []
    modifiedSchedule.current = null;
    props.onClose();
  };

  const handleApply = async () => {
    switch (props.type) {
      case "fullDay":
        await saveDay(1);
        break;
      case "weekend":
        await saveDay(2);
        break;
      case "vacation":
        await saveDay(3);
        break;
      case "sick":
        await saveDay(4);
        break;
    }
    props.onApply(
      modifiedSchedule.current as UserScheduleDto,
      // true
      selectAllFunctions || substituteUserId != null || substituteConfig.length > 0
    );
    handleClose();
  };

  const saveDay = async (dayId: number) => {
    // if (props.scheduleId != null) {
    //   const r = await api.userSchedule.edit(props.scheduleId, {
    //     id: props.scheduleId,
    //     dateFrom: date.start?.toISOString() as string,
    //     dateTo: date.end?.toISOString() as string,
    //     type: dayId,
    //     overrideAllUsersFunctions: selectAllFunctions,
    //     timeFrom: time.start,
    //     timeTo: time.end,
    //     user2RoleId: props.user?.id as number,
    //     substitute: !!substituteUserId
    //       ? {
    //           userId: substituteUserId ?? undefined,
    //         }
    //       : undefined,
    //   });
    //   modifiedSchedule.current = r;
    //   return;
    // }
    const r = await api.userSchedule.create({
      id: 0,
      dateFrom: date.start?.toISOString() as string,
      dateTo: date.end?.toISOString() as string,
      type: dayId,
      overrideAllUsersFunctions: selectAllFunctions,
      timeFrom: time.start,
      timeTo: time.end,
      user2RoleId: props.user?.id as number,

      // filter out items with roleId == null since we only need real data
      substitutesConfig: substituteConfig.filter((x) => !!x.userId && !!x.roleId),
      // !!substituteUserId && !!props.user ? [{ userId: substituteUserId, roleId: props.user.roleId }] : undefined, // Я ИЗМЕНИЛ ТУТА!!!
      // substitute: !!substituteUserId
      //   ? {
      //       userId: substituteUserId ?? undefined,
      //     }
      //   : undefined,
    });
    modifiedSchedule.current = r;
  };

  return (
    <ScheduleDaySetupDialogView
      time={time}
      user={props.user}
      setTime={setTime}
      open={props.open}
      date={date}
      setDate={setDate}
      selectAllFunctions={selectAllFunctions}
      setSelectAllFunctions={setSelectAllFunctions}
      substituteUserId={substituteUserId}
      setSubstituteUserId={setSubstituteUserId}
      onSubstituteConfigChange={(v) => (substituteConfig = v)}
      dateFrom={props.dateFrom}
      dateTo={props.dateTo}
      type={props.type}
      onClose={handleClose}
      onApply={handleApply}
    />
  );
}

export default ScheduleDaySetupDialog;
