import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { BoardStatusDto } from "../../../../api";
import CloseTaskWithoutProofConfirmationDialog from "../closeTaskWithoutProofConfirmationDialog/CloseTaskWithoutProofConfirmationDialog";
import { IssueContext } from "../../../../contexts/communication/issueContext";
import { actionsKeysConst } from "../../forms/types/consts";
import { Button, Dialog, DialogActions, TabPane, Tabs } from "../../../uiKit";

interface IIssueRequiredActionsDialogView {
  open: boolean;
  isNoProofDialogConfirmationOpen: boolean;
  boardData: BoardStatusDto | null;
  requiredActionsList: { key: string; name: string; element: any }[];
  currentTabKey: string;
  isLoading: boolean;
  resetFunctionsList: () => void;
  setIsNoProofDialogConfirmationOpen: (value: boolean) => void;
  handleForceSaveClick: () => void;
  onNextClick: () => void;
  onClose: () => void;
}

function IssueRequiredActionsDialogView(props: IIssueRequiredActionsDialogView) {
  const { t } = useTranslation();
  const { strictDeadline, senderDeadlineExpired } = useContext(IssueContext);

  return (
    <Dialog
      title={t("ui:title.board_require_actions")}
      open={props.open}
      onClose={props.onClose}
      // bodyStyle={{ paddingTop: "12px" }}
      scrollStrategy="dialogBody"
    >
      <CloseTaskWithoutProofConfirmationDialog
        open={props.isNoProofDialogConfirmationOpen}
        onApply={() => {
          props.setIsNoProofDialogConfirmationOpen(false);
          props.handleForceSaveClick();
        }}
        onClose={() => {
          if (props.currentTabKey === actionsKeysConst.addProof) {
            props.resetFunctionsList();
          }
          props.setIsNoProofDialogConfirmationOpen(false);
        }}
      />
      <div>
        <Tabs size="small" activeKey={props.currentTabKey} onChange={() => {}}>
          {props.boardData?.requiredActions?.map((action) => (
            <TabPane
              tab={
                props.requiredActionsList?.find((a) => a.key == action.baseTransitionActionKey)?.name ??
                action.baseTransitionActionKey!
              }
              disabled={action.baseTransitionActionKey !== props.currentTabKey}
              key={action.baseTransitionActionKey}
              children={props.requiredActionsList?.find((a) => a.key == action.baseTransitionActionKey)?.element()}
            />
          ))}
        </Tabs>
      </div>
      <DialogActions>
        <Button onClick={() => props.onClose()} variant="text">
          {t("ui:button.cancel")}
        </Button>
        <Button
          //disabled={strictDeadline && senderDeadlineExpired && props.currentTabKey == actionsKeysConst.setDateWorkStart}
          loading={props.isLoading}
          onClick={() => props.onNextClick()}
          variant="filled"
        >
          {props.boardData?.requiredActions &&
            (props.currentTabKey ==
            props.boardData.requiredActions[props.boardData.requiredActions.length - 1].baseTransitionActionKey
              ? t("ui:button.save")
              : t("ui:button.next"))}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default IssueRequiredActionsDialogView;
