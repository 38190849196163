import React from "react";
import { AlertProps } from "antd";
import { AlertView } from "./AlertView";

interface IAlert extends AlertProps {
  //
}

export function Alert(props: IAlert) {
  return <AlertView {...props} />;
}
