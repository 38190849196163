import React from "react";
import { IReportsGroup } from "../../Report.interface";
import { ReportContext } from "../../../../../../contexts/reportContext";
import { Collapse, CollapsePanel } from "../../../../../uiKit";
import { ReportUserCollapse } from "../reportUserCollapse/ReportUserCollapse";
import { EmptyBlock } from "../../../../layouts/emptyBlock/EmptyBlock";
import { ColorModeKeys } from "../../../../../elements/counterChip/misc/keys";
import { CounterChip } from "../../../../../elements/counterChip/CounterChip";

export const ReportsGroup = ({ reports, users, reportsType, selectDate }: IReportsGroup) => {
  const collapseHeader = (
    <div className="d-stack-row justify-start align-start spacing-2">
      <p className="mb-0">{reports.name}</p>
      {!!reports?.items?.length && <CounterChip colorMode={ColorModeKeys.primary} count={reports?.items?.length} />}
    </div>
  );

  return (
    <ReportContext.Provider
      value={{
        reportKey: reports.key,
        users: users,
        timeInterval: selectDate,
      }}
    >
      <Collapse accordion style={{ width: "100%" }}>
        <CollapsePanel style={{ width: "100%" }} key={reports.id!} id={`${reports.id ?? 0}`} header={collapseHeader}>
          {!!reports.items?.length ? (
            <div className="d-stack-column spacing-2">
              {reports.items?.map((userDateItem) => (
                <ReportUserCollapse
                  key={userDateItem.userId}
                  reportsType={reportsType}
                  userDate={userDateItem}
                  user={users?.find((user) => user.id === userDateItem.userId)}
                />
              ))}
            </div>
          ) : (
            <EmptyBlock />
          )}
        </CollapsePanel>
      </Collapse>
    </ReportContext.Provider>
  );
};
