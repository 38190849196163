import React from "react";
import "./List.scss";
import { List, ListProps } from "antd";
import clsx from "clsx";

interface IListView extends ListProps<any> {
  //
}

export function ListView({ className, ...rest }: IListView) {
  return <List className={clsx("uiKit-list", className ?? "")} {...rest} />;
}
