import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions } from "../../../uiKit";

interface ICloseTaskWithoutProofConfirmationDialogView {
  open: boolean;

  onApply: () => void;
  onClose: () => void;
}

function CloseTaskWithoutProofConfirmationDialogView(props: ICloseTaskWithoutProofConfirmationDialogView) {
  const { t } = useTranslation();

  return (
    <Dialog
      zIndex={1002}
      title={t("ui:title.close_task_no_proof")}
      scrollStrategy="dialogBody"
      open={props.open}
      onClose={() => props.onClose()}
    >
      {t("text:task_proof.close_no_proof")}
      <DialogActions>
        <Button onClick={props.onClose} variant="text">
          {t("ui:button.no")}
        </Button>
        <Button onClick={props.onApply} variant="filled">
          {t("ui:button.yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CloseTaskWithoutProofConfirmationDialogView;
