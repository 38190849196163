import React from "react";
import { ICounterChipView } from "./CounterChip.interface";
import { Chip } from "../../uiKit";
import { ColorModeKeys } from "./misc/keys";

export const CounterChipView = (props: ICounterChipView) => {
  return (
    <Chip
      // @ts-ignore TODO: Remove faint & dark
      type={props.colorMode == ColorModeKeys.faint ? "default" : props.colorMode}
      // style={{ background: props.color, ...props.style }}
      className={`counter-chip ${props.className ?? ""}`}
      label={props.count}
    />
  );
};
