import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { OrgchartDto } from "../../../api";
import { observer } from "mobx-react-lite";
import { useRootStore } from "../../../hooks";
import { OrgchartSwitcherView } from "./OrgchartSwitcherView";
import { ISelect } from "../../uiKit/select/Select";

interface IOrgchartSwitcher {
  drawType?: "avatarsWithMenu" | "textWithMenu" | "select";
  value?: number[] | number;
  createEditDialogValue?: OrgchartDto;
  useAsFilter?: boolean;
  selectProps?: ISelect;
  preventDefault?: boolean;

  onSelectAllClick?: () => void;
  onChange?: (orgchartId: number | number[]) => void;
  onCreate?: (orgchartId: number) => void;
  onSave?: (orgchartId: number) => void;
  onCreateEditDialogClose?: () => void;
}

interface IOrgchartSwitcherRef {
  openCreateDialog: () => void;
}

const Switcher = forwardRef<IOrgchartSwitcherRef, IOrgchartSwitcher>((props: IOrgchartSwitcher, ref) => {
  const { authStore, orgchartStore } = useRootStore();

  const [state, setState] = useState<OrgchartDto[]>([]);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState<boolean>(false);

  const handleChange = (orgchartId: number | number[]) => {
    props.onChange && props.onChange(orgchartId);
    if (props.useAsFilter || props.preventDefault) return;
    typeof orgchartId === "number" && orgchartStore.setCurrentOrgchartId(orgchartId);
  };

  const handleCreateSuccess = (orgchartId: number) => {
    if (props.createEditDialogValue != null) {
      props.onSave && props.onSave(orgchartId);
    } else {
      props.onCreate && props.onCreate(orgchartId);
    }
  };

  useEffect(() => {
    setState(orgchartStore.getOrgchartsList ?? []);
  }, [authStore.getInitialInfo?.orgcharts]);

  useImperativeHandle(ref, () => ({
    openCreateDialog: () => {
      setIsCreateDialogOpen(true);
    },
  }));

  return (
    <OrgchartSwitcherView
      currentOrgchartId={orgchartStore.getCurrentOrgchartId}
      state={state}
      drawType={props.drawType ?? "avatarsWithMenu"}
      value={props.value}
      selectProps={props.selectProps}
      createEditDialogValue={props.createEditDialogValue}
      useAsFilter={props.useAsFilter}
      onSelectAllClick={props.onSelectAllClick}
      isCreateDialogOpen={isCreateDialogOpen}
      onCreateSuccess={handleCreateSuccess}
      setIsCreateDialogOpen={setIsCreateDialogOpen}
      onChange={handleChange}
      onCreateEditDialogClose={props.onCreateEditDialogClose}
    />
  );
});

export const OrgchartSwitcher = observer(Switcher);
