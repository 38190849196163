import React, { useEffect, useRef, useState } from "react";
import { Value } from "@udecode/plate";
import { contentToString } from "../../../../../../utils/textEditor";
import { findId } from "../../../../../../helpers/pathname";
import { useLocation, useNavigate } from "react-router-dom";
import { api } from "../../../../../../services";
import { useTranslation } from "react-i18next";
import { useNotifier, useRootStore, useSequentialPromises } from "../../../../../../hooks";
import PageHeader from "../../../../layouts/pageHeader/PageHeader";
import { CustomConfirmDialog } from "../../../../dialogs/customConfirmDialog/СustomConfirmDialog";
import { UpdateNoteHeader } from "../../headers/updateNoteHeader/UpdateNoteHeader";
import PageContent from "../../../../layouts/pageContent/PageContent";
import { UpdateNoteContent } from "../../contents/UpdateNoteContent/UpdateNoteContent";
import { LocalesKeys } from "../../../../../../constants/localesKeys";
import { IInitialUpdateNotes, initialNotes, publishedSelectKeys } from "../../misc/constants";
import { UpdateNoteContentDto, UpdateNoteDto } from "../../../../../../api";
import {
  convertIsPublishedToSelectKeys,
  convertNotesDtoToCustomNotes,
  convertSelectKeysToIsPublished,
} from "../../helpers";

export const UpdateNote = () => {
  const { appStore } = useRootStore();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const notifier = useNotifier();
  const navigate = useNavigate();
  const promisesListRef = useRef(useSequentialPromises());
  const initialUpdateNoteRef = useRef<UpdateNoteDto>();
  const editorRef = useRef<{ reset: () => void }>();

  const updateNoteId = findId(pathname);

  const [currentLocale, setCurrentLocale] = useState<LocalesKeys>(
    (appStore.getAppLocale as LocalesKeys) ?? LocalesKeys.en
  );

  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState<boolean>(false);

  const [isLoadingDeleteBtn, setIsLoadingDeleteBtn] = useState<boolean>(false);
  const [isLoadingSaveBtn, setIsLoadingSaveBtn] = useState<boolean>(false);

  const [draftUpdateNoteContents, setDraftUpdateNoteContents] = useState<IInitialUpdateNotes>();
  const [publishKey, setPublishKey] = useState<publishedSelectKeys>(publishedSelectKeys.notPublished);

  const currentDraftNote: UpdateNoteContentDto | undefined =
    draftUpdateNoteContents && draftUpdateNoteContents[currentLocale];

  const handleEnableEditable = () => setIsEditable(true);
  const handleCancelEditable = () => setIsEditable(false);

  const handleOpenConfirmDialog = () => setIsOpenConfirmDialog(true);
  const handleCloseConfirmDialog = () => {
    setIsOpenConfirmDialog(false);
    promisesListRef.current.reset();
  };

  const handleChangeIsPublished = (value: publishedSelectKeys) => {
    setPublishKey(value);
    handleUpdateNote(value);
  };

  const handleChangeNoteContent = (content: Value) =>
    setDraftUpdateNoteContents((prev) => {
      const newContent: IInitialUpdateNotes = prev ?? JSON.parse(JSON.stringify(initialNotes));
      newContent[currentLocale].content = contentToString(content);
      return Object.assign({}, prev);
    });

  const handleChangeNoteName = (name: string) =>
    setDraftUpdateNoteContents((prev) => {
      const newContent: IInitialUpdateNotes = prev ?? JSON.parse(JSON.stringify(initialNotes));
      newContent[currentLocale].name = name;
      return Object.assign({}, prev);
    });

  const handleChangeCurrentLocale = (newLocale: LocalesKeys) => setCurrentLocale(newLocale);

  const handleApiError = () => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });

  const handleLoadNote = async () => {
    setIsLoading(true);
    const r = await api.updateNote.getById(updateNoteId!);
    setIsLoading(false);
    if (r == null) return handleApiError();
    setPublishKey(convertIsPublishedToSelectKeys(r.isPublished));
    const customNotes = convertNotesDtoToCustomNotes(r);
    setDraftUpdateNoteContents({ ...customNotes });
    initialUpdateNoteRef.current = r;
  };

  const handleDeleteNote = async () => {
    setIsLoadingDeleteBtn(true);
    const r = await api.updateNote.del(updateNoteId!);
    setIsLoadingDeleteBtn(false);
    if (r == null) return handleApiError();
    else notifier.show({ message: t("notifier:success.release_delete"), theme: "success" });
    navigate(`/update-note`);
  };

  const handleUpdateNote = async (withPublish?: publishedSelectKeys) => {
    setIsLoadingSaveBtn(true);
    const r = await api.updateNote.edit(updateNoteId!, {
      ...initialUpdateNoteRef.current,
      isPublished: convertSelectKeysToIsPublished(withPublish ?? publishKey),
      contents: Object.values(draftUpdateNoteContents!),
    });
    setIsLoadingSaveBtn(false);
    if (r == null) return handleApiError();
    else notifier.show({ message: t("notifier:success.release_update"), theme: "success" });
    handleCancelEditable();
  };

  const handleClickDelete = () => {
    handleOpenConfirmDialog();
    promisesListRef.current.add(handleDeleteNote);
  };

  const handleSuccessConfirm = async () => {
    setIsLoadingDeleteBtn(true);
    await promisesListRef.current.run();
    setIsLoadingDeleteBtn(false);
    handleCloseConfirmDialog();
  };

  useEffect(() => {
    updateNoteId && handleLoadNote();
  }, [updateNoteId]);

  useEffect(() => {
    editorRef.current?.reset();
  }, [currentLocale]);

  return (
    <>
      <CustomConfirmDialog
        open={isOpenConfirmDialog}
        onClose={handleCloseConfirmDialog}
        onConfirm={handleSuccessConfirm}
        loading={isLoadingDeleteBtn}
        title={t("ui:title.confirm_deletion")}
      />
      <PageHeader>
        <UpdateNoteHeader
          publishOption={publishKey}
          currentLocale={currentLocale}
          commonPermission={true}
          disabledSaveBtn={false}
          isEditable={isEditable}
          onChangeLocale={handleChangeCurrentLocale}
          onChangePublishOption={handleChangeIsPublished}
          onCancelEditable={handleCancelEditable}
          onEnableEditable={handleEnableEditable}
          isLoadingDeleteBtn={isLoadingDeleteBtn}
          isLoadingSaveBtn={isLoadingSaveBtn}
          onDelete={handleClickDelete}
          onSave={handleUpdateNote}
        />
      </PageHeader>
      <PageContent isLoading={isLoading} contentPosition={{ maxWidth: "1000px" }}>
        {currentDraftNote && (
          <UpdateNoteContent
            ref={editorRef}
            noteName={currentDraftNote!.name!}
            draftUpdateNoteContent={currentDraftNote!.content!}
            isEditable={isEditable}
            onChangeDraftUpdateNoteContent={handleChangeNoteContent}
            onChangeNoteName={handleChangeNoteName}
          />
        )}
      </PageContent>
    </>
  );
};
