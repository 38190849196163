import React, { createRef, forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { IIssueFormWrapper, IssueFormKeys } from "./IssueForm.interface";
import { Form } from "antd";
import { IssueCustomFieldDto } from "../../../../../../api";
import { TaskFormView } from "../taskForm/TaskFormView";
import { useRootStore } from "../../../../../../hooks";
import { blockedFieldsKeys } from "../../../types/consts";
import { compareForRunAdditionalActions, isEqualDates } from "../../helpers";
import { IIntermediateData } from "../../containers/issueUpdateContainer/IssueUpdateContainer.interface";
import { CustomConfirmDialog } from "../../../../dialogs/customConfirmDialog/СustomConfirmDialog";
import { IssueFields, WarningsKeys } from "../../utils/keys/warningsKeys";
import { getWarningData } from "../../utils/data";
import { useTranslation } from "react-i18next";
import { generateTimeSpan } from "../../../../../../helpers/dateFunctions";

export const IssueFormWrapper = forwardRef((props: IIssueFormWrapper, ref) => {
  const { issueInitDataStore } = useRootStore();
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const uploadFilesInputRef = createRef();
  const isFileLoaderUploadingCopy = useRef(false);
  const [isFileLoaderUploading, setIsFileLoaderUploading] = useState<boolean>(false);
  const [commonDebounceTrigger, setCommonDebounceTrigger] = useState<boolean>(false);
  const [intermediateData, setIntermediateData] = useState<IIntermediateData | undefined>(undefined);

  const handleCheckToAvailability = (fieldKey: string): boolean => {
    return (
      !!props.updater?.currentState?.calculated?.blockedFields?.includes(fieldKey) ||
      issueInitDataStore.getBlockedFields.includes(fieldKey as blockedFieldsKeys)
    );
  };

  const isUpdateForm = useMemo(() => props.formType === IssueFormKeys.update, [props.formType]);

  const setIsFileLoaderUploadingSync = (v: boolean) => {
    setIsFileLoaderUploading(v);
    isFileLoaderUploadingCopy.current = v;
  };

  const customFieldsIsChanged = (newFields: IssueCustomFieldDto[]): boolean => {
    let value = false;
    if (newFields.length !== props.updater?.currentState?.fields?.length) return true;
    props.updater?.currentState?.fields?.forEach((lastField) => {
      const newFiledElem = newFields.find((newFiled) => newFiled.key === lastField.key);
      if (newFiledElem?.valueBool !== lastField.valueBool) {
        value = true;
      }
    });
    return value;
  };

  const handleChangeIntermediateData = (data: IIntermediateData) => setIntermediateData(data);

  const handleChangeExecutorDeadline = (data: IIntermediateData) => {
    props.updater.applyChanges({ dateWorkStart: data.nextValue });
    if (!props.updater?.currentState?.dateDeadline) return handleAsyncUpdate();
    if ((data.nextValue as Date) > new Date(props.updater.currentState.dateDeadline))
      handleChangeIntermediateData(data);
    else handleAsyncUpdate();
  };

  const handlePreventDefaultField = () => {
    switch (intermediateData?.warningKey) {
      case WarningsKeys.executorDeadline: {
        props.updater.applyChanges({ dateWorkStart: intermediateData?.prevValue });
        break;
      }
    }
    setIntermediateData(undefined);
  };

  const handleAdditionalActions = () => {
    issueInitDataStore.onChangeDefiniteField &&
      compareForRunAdditionalActions(props.updater) &&
      issueInitDataStore.onChangeDefiniteField();
  };

  const handlePickerChange = (fieldKey: IssueFields, newValue: Date | null, toGenerateTimeSpan?: boolean) => {
    props.updater.applyChanges({
      [fieldKey]: toGenerateTimeSpan && newValue ? generateTimeSpan({ dateObject: newValue }) : newValue,
    });
    props.updater.currentState &&
      !isEqualDates(props.updater.currentState[fieldKey] as string | undefined, newValue, toGenerateTimeSpan) &&
      handleAsyncUpdate();
  };

  const setParticipantsWithUpdater = (ids: number[]) => {
    props.updater.applyChanges({
      participants: ids.map((id) => ({
        userId: Number(id),
        user: { id },
      })),
    });
    if (ids.length < (props.updater.currentState?.participants?.length ?? 0)) {
      handleAsyncUpdate();
    }
  };

  const handleAsyncUpdate = () => setCommonDebounceTrigger(true);

  const handleUpdate = () => isUpdateForm && props.updater.update().then(handleAdditionalActions);

  const handleFormSubmit = () => form.submit();

  useEffect(() => {
    if (commonDebounceTrigger) {
      handleUpdate();
      setCommonDebounceTrigger(false);
    }
  }, [props.updater?.currentState?.fields, props.updater?.currentState?.participants?.length, commonDebounceTrigger]);

  useImperativeHandle(
    ref,
    () => ({
      saveIssue: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleFormSubmit(),
      executorIds: props.executorIds,
    }),
    [props.onCreate]
  );

  return (
    <>
      {!!intermediateData && (
        <CustomConfirmDialog
          open={true}
          onClose={handlePreventDefaultField}
          onConfirm={() => {
            setIntermediateData(undefined);
            handleUpdate();
          }}
          subTitle={t(getWarningData().find((item) => item.key == intermediateData?.warningKey)?.description ?? "")}
        />
      )}
      <TaskFormView
        attachments={props.attachments}
        onCreate={props.onCreate}
        updater={props.updater}
        ref={ref}
        executorIds={props.executorIds}
        onChangeExecutorIds={props.onChangeExecutorIds}
        onPickerChange={handlePickerChange}
        customFields={props.customFields}
        setAttachmentsIssueSync={props.setAttachmentsIssueSync}
        onDeleteAttachmentsByFileId={props.onDeleteAttachmentsByFileId}
        setParticipantsWithUpdater={setParticipantsWithUpdater}
        form={form}
        customFieldsIsChanged={customFieldsIsChanged}
        onAsyncUpdate={handleAsyncUpdate}
        isUpdateForm={isUpdateForm}
        isFileLoaderUploadingCopy={isFileLoaderUploadingCopy}
        onCheckToAvailability={handleCheckToAvailability}
        onUpdate={handleUpdate}
        setIsFileLoaderUploadingSync={setIsFileLoaderUploadingSync}
        uploadFilesInputRef={uploadFilesInputRef}
        onChangeExecutor={props.onChangeExecutor}
        onChangeExecutorDeadline={handleChangeExecutorDeadline}
      />
    </>
  );
});
