import React from "react";
import { SelectProps } from "antd";
import { ISelectItem, SelectView } from "./SelectView";

export interface ISelect extends SelectProps {
  items?: ISelectItem[];
  fullWidth?: boolean;
  readOnly?: boolean;
  variant?: "default" | "filled";
  // TODO: Add block prop
}

export function Select(props: ISelect) {
  return <SelectView {...props} />;
}
