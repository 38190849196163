import React, { useContext, useState } from "react";
import { IIssueCopyDialogView } from "./IssueCopyDialog.interface";
import { useTranslation } from "react-i18next";
import SubheaderText from "../../../elements/subheaderText/SubheaderText";
import { useRootStore } from "../../../../hooks";
import { Button, Dialog, DialogActions } from "../../../uiKit";
import { AutocompleteAsync } from "../../../elements/autocompleteAsync/AutocompleteAsync";
import { Form } from "antd";
import { OrgchartSwitcher } from "../../../elements/orgchartSwitcher/OrgchartSwitcher";
import { IssueContext } from "../../../../contexts/communication/issueContext";
import { getDialogConfig } from "./helpers";
import { IssueTypeKeys } from "../../../../constants/issueTypeKeys";

export const IssueCopyDialogView = (props: IIssueCopyDialogView) => {
  const { t } = useTranslation();
  const { issueType } = useContext(IssueContext);

  const { authStore } = useRootStore();
  const [form] = Form.useForm();

  const userId = authStore.getInitialInfo?.identity?.id;
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = () => {
    setLoading(true);
    form.submit();
  };

  return (
    <Dialog title={getDialogConfig(t, issueType ?? IssueTypeKeys.task).title} open={props.open} onClose={props.onClose}>
      {/*<Dialog title={getDialogConfig(t, issueType ?? IssueTypeKeys.task).title} open={props.open} onClose={props.onClose}>*/}
      <Form
        name="issue-copy"
        form={form}
        onFinish={props.onCopyIssue}
        onFinishFailed={() => setLoading(false)}
        autoComplete="off"
      >
        <SubheaderText text={t("ui:subheader.issue_orgachart_company")} />
        <Form.Item
          className="full-width"
          name="orgchartId"
          rules={[
            {
              required: true,
              message: t("validation:field_required"),
            },
          ]}
        >
          <OrgchartSwitcher
            value={props.roleId}
            onChange={(v) => typeof v === "number" && props.setOrgchartId(v)}
            selectProps={{
              style: { width: "100%", marginBottom: 4 },
              placeholder: t("ui:placeholder.click_to_select"),
            }}
            drawType="select"
          />
        </Form.Item>
        <SubheaderText text={t("ui:subheader.issue_executor")} />
        <Form.Item name="issue_executor" rules={[{ required: true, message: t("validation:field_required") }]}>
          <AutocompleteAsync
            style={{ minWidth: "100%" }}
            dropdownStyle={{ zIndex: 1202 }}
            placeholder={t("ui:placeholder.click_to_select")}
            type="user"
            value={props.executorId}
            onChange={(value) => {
              props.setRoleId(undefined);
              props.setExecutorId(value);
            }}
          />
        </Form.Item>
        <SubheaderText text={t("ui:subheader.issue_role")} />
        <Form.Item
        //name="issue_role"
        >
          <AutocompleteAsync
            style={{ minWidth: "100%" }}
            dropdownStyle={{ zIndex: 1202 }}
            type="role"
            allowClear
            disabled={!props.executorId}
            placeholder={t("ui:placeholder.select")}
            value={props.roleId}
            onChange={(id) => {
              props.setRoleId(+id);
            }}
            requestOptions={{ userIds: [props.executorId ?? userId], orgchartId: props.orgchartId }}
          />
        </Form.Item>
      </Form>
      <DialogActions>
        <Button onClick={props.onClose} variant="text">
          {t("ui:button.cancel")}
        </Button>
        <Button loading={loading} htmlType="submit" onClick={handleSubmit} type="primary" variant="text">
          {t("ui:button.copy")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
