import React from "react";
import { ISectionViewHeader } from "./SectionViewHeader.interface";
import { CancelButton } from "../../components/ButtonIcons/CancelButton";
import { EditButton } from "../../components/ButtonIcons/EditButton";
import { AccessSettingsButton } from "../../components/ButtonIcons/AccessSettingsButton";
import { DeleteButton } from "../../components/ButtonIcons/DeleteButton";
import { Breadcrumbs } from "../../../../../elements/breadcrumbs/Breadcrumbs";
import { breadcrumbsKeys } from "../../../../../elements/breadcrumbs/Breadcrumbs.interface";

export const SectionViewHeader: React.FC<ISectionViewHeader> = (props) => {
  return (
    <div className="d-stack-row justify-space-between align-center full-width px-6">
      <Breadcrumbs type={breadcrumbsKeys.regulation} />
      {
        <div className="d-stack-row justify-end align-center spacing-4">
          {props.permissionToEdit &&
            (props.isEditable ? (
              <CancelButton onClick={props.onSwitchEditable} />
            ) : (
              <EditButton onClick={props.onSwitchEditable} />
            ))}
          {props.permissionToEdit && <AccessSettingsButton onClick={props.onOpenDialog} />}
          {props.permissionToEdit && <DeleteButton onClick={props.onDeleteSection} />}
        </div>
      }
    </div>
  );
};
