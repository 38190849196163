import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import "../../containers/issueUpdateContainer/IssueUpdateContainer.scss";
import { IssueHistorySenderBlock } from "./components/IssueHistorySenderBlock/IssueHistorySenderBlock";
import { IssueAceptHistoryLocalesConst, IssueHistoryConst, IssueIndicatorsKeysConst } from "../../../types/consts";
import { IssueHistoryListComments } from "./components/issueHistoryListComments/IssueHistoryListComments";
import { IIssueHistoryModule, IIssueHistoryModuleRef } from "./IssueHistory.interface";
import { IssueHistoryDto, StaticFileDto } from "../../../../../../api";
import { api } from "../../../../../../services";
import { contentIsEmpty, contentToString, initialContent } from "../../../../../../utils/textEditor";
import { useNotifier, useRootStore } from "../../../../../../hooks";
import { Value } from "@udecode/plate";
import { useTranslation } from "react-i18next";
import { usePagingWithController } from "../../../../../../hooks/usePaging";
import { IIssueHistoryListCommentsRef } from "./components/issueHistoryListComments/IssueHistoryListComments.interface";

const IssueHistoryModuleMemo = forwardRef<IIssueHistoryModuleRef, IIssueHistoryModule>(
  (props: IIssueHistoryModule, ref) => {
    const { appStore } = useRootStore();
    const { t } = useTranslation();
    const notifier = useNotifier();
    const editorRef = useRef<{ clear: () => void }>();
    const attachmentsCommentCopy = useRef<StaticFileDto[]>([]);
    const issueHistoryListCommentsRef = useRef<IIssueHistoryListCommentsRef | undefined | null>();

    const [comment, setComment] = useState<Value>(initialContent);
    const [attachments, setAttachments] = useState<StaticFileDto[]>([]);
    const [isLoadingSend, setIsLoadingSend] = useState<boolean>(false);

    const handlerApiError = () => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });

    const driverReports = usePagingWithController(api.controlSessionHistory, {
      issueId: props.issueId,
    });

    const isAllowAcceptButton = useMemo(
      () => !!props.indicators?.find((item) => item.key === IssueIndicatorsKeysConst.HasMessage),
      [props.indicators]
    );

    const setAttachmentsCommentSync = useCallback(
      (v: StaticFileDto[]) => {
        setAttachments(v);
        attachmentsCommentCopy.current = v;
      },
      [setAttachments]
    );

    const handleChangeComment = (comment: Value) => setComment(comment);

    const handleDeleteAttachmentById = (fileId: number) => {
      if (fileId == null) return;
      setAttachmentsCommentSync((attachments ?? []).filter((x) => x.id != fileId));
    };

    const handleAcceptIssueHistory = useCallback(async (): Promise<IssueHistoryDto | void> => {
      const localeType = appStore.getAppLocale;
      const createDto: IssueHistoryDto = {
        issueId: props.issueId,
        comment: {
          text:
            localeType === IssueAceptHistoryLocalesConst.Ru.locale
              ? IssueAceptHistoryLocalesConst.Ru.message
              : IssueAceptHistoryLocalesConst.En.message,
        },
      };
      const r = await api.issueHistory.create(createDto);
      if (r == null) return handlerApiError();
      editorRef.current?.clear();
      setComment(initialContent);
      setAttachments([]);
      return r;
    }, [appStore]);

    const handleCreateIssueHistory = useCallback(
      async (type) => {
        setIsLoadingSend(true);
        const attachments = [
          ...attachmentsCommentCopy.current.map((f, index) => {
            return {
              fileId: f.id,
              order: index,
            };
          }),
        ];

        const createDto: IssueHistoryDto = {
          issueId: props.issueId,
          comment: {
            text: !contentIsEmpty(comment) ? contentToString(comment) : undefined,
            attachments: attachments ?? [],
          },
        };
        if (type === IssueHistoryConst.Message) {
          createDto.comment!.recipients = [
            {
              // @ts-ignore
              userId: props.isExecutor ? props?.createdByUserId : props?.executorUserId,
            },
          ];
        }

        const r = await api.issueHistory.create(createDto);
        setIsLoadingSend(false);
        if (r == null) return handlerApiError();
        editorRef.current?.clear();
        setComment(initialContent);
        setAttachmentsCommentSync([]);
        issueHistoryListCommentsRef.current?.reloadComments();
      },
      [comment, props.isExecutor, issueHistoryListCommentsRef.current]
    );

    useEffect(() => {
      driverReports.restart();
    }, []);

    useImperativeHandle(ref, () => ({
      reloadComments: issueHistoryListCommentsRef.current?.reloadComments,
    }));

    return (
      <>
        <div className="flex-grow-1 pt-5 pl-5" style={{ overflow: "auto", width: "45%" }}>
          <IssueHistorySenderBlock
            editorRef={editorRef}
            isLoadingSend={isLoadingSend}
            allowedCommentsTypes={props.allowedToSendCommentTypes}
            isAllowAceptButton={isAllowAcceptButton}
            onChangeComment={handleChangeComment}
            commentIssue={comment}
            attachments={attachments}
            setAttachments={setAttachmentsCommentSync}
            onSendComment={handleCreateIssueHistory}
            onDeleteAttachmentById={handleDeleteAttachmentById}
            onAcceptIssueHistory={handleAcceptIssueHistory}
          />
          <IssueHistoryListComments
            ref={(ref) => (issueHistoryListCommentsRef.current = ref)}
            loadNextReports={driverReports.loadNext}
            totalItemsDriverReports={driverReports.info.totalItems}
            isLoadingReports={driverReports.info.isLoading}
            isDoneReports={driverReports.info.isDone}
            issueId={props.issueId}
            driverReports={driverReports.items}
          />
        </div>
      </>
    );
  }
);

export const IssueHistoryModule = React.memo(IssueHistoryModuleMemo, (x, y) => {
  let noChanged: boolean = true;
  if (x.issueId != y.issueId) noChanged = false;
  if (x.indicators?.length != y.indicators?.length) noChanged = false;
  if (x.allowedToSendCommentTypes?.length != y.allowedToSendCommentTypes?.length) noChanged = false;
  if (x.createdByUserId != y.createdByUserId) noChanged = false;
  if (x.executorUserId != y.executorUserId) noChanged = false;
  return noChanged;
});
