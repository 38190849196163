import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { HintDialog } from "../../../../../../dialogs/hintDialog/HintDialog";
import { defaultValuesConst, FilterIssueHistoryKeys } from "../../../../../types/consts";
import { usePagingWithController } from "../../../../../../../../hooks/usePaging";
import { api } from "../../../../../../../../services";
import { RealtimeIssuesKeys } from "../../../../../../../pages/communication/misc/consts";
import { useIssueHub } from "../../../../../../../../signalr/hubs/issueHub";
import {
  IFilterItem,
  IIssueHistoryListComments,
  IIssueHistoryListCommentsRef,
} from "./IssueHistoryListComments.interface";
import { IssueHistoryListCommentsView } from "./IssueHistoryListCommentsView";
import { useRootStore } from "../../../../../../../../hooks";

export const IssueHistoryListComments = forwardRef<IIssueHistoryListCommentsRef, IIssueHistoryListComments>(
  (props: IIssueHistoryListComments, ref) => {
    const { t } = useTranslation();
    const { connection } = useIssueHub();
    const { authStore } = useRootStore();

    const [currentFilter, setCurrentFilter] = useState<FilterIssueHistoryKeys>(FilterIssueHistoryKeys.all);
    const [openDriverReportsDialog, setOpenDriverReportsDialog] = useState<boolean>(false);
    const [loadNextPage, setLoadNextPage] = useState<boolean>(false);
    const [reloadHistory, setReloadHistory] = useState<boolean>(false);

    const filterItems: IFilterItem[] = [
      { id: 0, value: FilterIssueHistoryKeys.all, text: t("ui:select.all") },
      { id: 1, value: FilterIssueHistoryKeys.comment, text: t("ui:select.comments") },
      { id: 2, value: FilterIssueHistoryKeys.action, text: t("ui:select.actions") },
    ];

    const handleOpenDriverReportsDialog = () => setOpenDriverReportsDialog(true);

    const onCloseDriverReportsDialog = () => setOpenDriverReportsDialog(false);

    const handleChangeFilter = (value: FilterIssueHistoryKeys) => setCurrentFilter(value);

    const handleReloadHistoryItems = () => {
      setReloadHistory(true);
    };

    const issueHistoryItems = usePagingWithController(
      api.issueHistory,
      undefined,
      {
        // @ts-ignore
        issueId: props.issueId,
        type: currentFilter,
        pageSize: defaultValuesConst.pageSize,
      },
      undefined
    );

    const realtimeMessageHandler = (message: any) => {
      if (
        message.type === RealtimeIssuesKeys.HistoryUpdated &&
        message.issueId === props.issueId // &&
        // message.byUserId != authStore.getInitialInfo?.identity?.id
      )
        handleReloadHistoryItems();
    };

    const handleLoadNextPage = () => setLoadNextPage(true);

    useEffect(() => {
      if (connection) connection?.on("IssueUpdate", realtimeMessageHandler);
      return () => connection?.off("IssueUpdate", realtimeMessageHandler);
    }, [connection]);

    useEffect(() => {
      handleReloadHistoryItems();
    }, [currentFilter]);

    useEffect(() => {
      if (loadNextPage) {
        issueHistoryItems.loadNext();

        setLoadNextPage(false);
      }
    }, [loadNextPage]);

    useEffect(() => {
      if (reloadHistory) {
        issueHistoryItems.restart(true);
        setReloadHistory(false);
      }
    }, [reloadHistory]);

    useImperativeHandle(ref, () => ({
      reloadComments: handleReloadHistoryItems,
    }));

    return (
      <>
        <HintDialog
          open={openDriverReportsDialog}
          onClose={onCloseDriverReportsDialog}
          reports={props.driverReports!}
          loadNext={props.loadNextReports}
          isDone={props.isDoneReports}
          isLoading={props.isLoadingReports}
        />
        <IssueHistoryListCommentsView
          currentFilter={currentFilter}
          filterItems={filterItems}
          onChangeFilter={handleChangeFilter}
          historyItems={issueHistoryItems.items}
          isDone={issueHistoryItems.info.isDone}
          isLoading={issueHistoryItems.info.isLoading}
          totalItemsDriverReports={props.totalItemsDriverReports}
          handleLoadNext={handleLoadNextPage}
          onOpenDriverReportsDialog={handleOpenDriverReportsDialog}
          driverReports={props.driverReports}
        />
      </>
    );
  }
);
