import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useApi } from "../../../hooks";
import { api } from "../../../services";
import PageHeader from "../../modules/layouts/pageHeader/PageHeader";
import { PlanningPageAdminWorkPlan } from "../../modules/pages/planning/containers/planningPageAdmin/planningPageAdminWorkPlans/planningPageAdminWorkPlan/PlanningPageAdminWorkPlan";
import { PlanningPageAdminWorkPlans } from "../../modules/pages/planning/containers/planningPageAdmin/planningPageAdminWorkPlans/PlanningPageAdminWorkPlans";
import { PlanningPageMyPlan } from "../../modules/pages/planning/containers/planningPageMyPlan/PlanningPageMyPlan";
import { PlanningPageAdminHeader } from "../../modules/pages/planning/headers/planningPageAdminHeader/PlanningPageAdminHeader";

const PlanningPage = () => {
  const url = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const plansForApproval = useApi(
    () => api.plan.getAll({ forApproval: true }),
    null,
    () => {}
  );

  useEffect(() => {
    if (url.pathname === "/planning" || url.pathname === "/planning/") {
      navigate("/planning/my-plan");
    }
  }, [navigate, url.pathname]);

  return (
    <>
      {params.id ? null : (
        <PageHeader>
          <PlanningPageAdminHeader plansForApproval={plansForApproval.value?.items.length} />
        </PageHeader>
      )}
      {url.pathname == "/planning/my-plan" && <PlanningPageMyPlan fetchApprovalPlans={plansForApproval.fetch} />}
      {url.pathname == "/planning/work-plans" && <PlanningPageAdminWorkPlans />}
      {url.pathname == "/planning/require-approval" && <PlanningPageAdminWorkPlans />}
      {params.id && (
        <PlanningPageAdminWorkPlan
          id={Number(params.id)}
          plansForApproval={plansForApproval.value?.items ?? []}
          fetchApprovalPlans={plansForApproval.fetch}
        />
      )}
    </>
  );
};
export default PlanningPage;