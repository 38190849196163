import { StyledElementProps, TLinkElement, Value } from "@udecode/plate";
import React from "react";

export const LinkElement = <V extends Value>({
  attributes,
  children,
  element,
  nodeProps,
}: StyledElementProps<V, TLinkElement>) => {
  const currentUrl = () => {
    if (element.url != '') {
      return element.url.startsWith("https://")
        ? element.url.slice("https://".length)
        : element.url.startsWith("http://")
          ? element.url.slice("http://".length)
          : element.url;
    }
  };
  return (
    <a {...attributes} href={`https://${currentUrl()}`} target="_blank" {...nodeProps} contentEditable={false} style={{pointerEvents: element.url == '' ? "none" : undefined}}>
      {children}
    </a>
  );
};
