import React, { useContext, useState } from "react";
import { IIssueRejectDialogView } from "./IssueRejectDialog.interface";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions, TextArea } from "../../../uiKit";
import { Form } from "antd";
import { IssueTypeKeys } from "../../../../constants/issueTypeKeys";
import { getDialogConfig } from "./helpers";
import { IssueContext } from "../../../../contexts/communication/issueContext";

export const IssueRejectDialogView = ({ open, onClose, onSuccess, setReason, reason }: IIssueRejectDialogView) => {
  const { t } = useTranslation();
  const { issueType } = useContext(IssueContext);
  const [form] = Form.useForm();

  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = () => {
    setLoading(true);
    form.submit();
  };

  return (
    <Dialog title={getDialogConfig(t, issueType ?? IssueTypeKeys.task).title} open={open} onClose={onClose}>
      <Form
        name="issue-reject"
        form={form}
        onFinish={onSuccess}
        onFinishFailed={() => setLoading(false)}
        autoComplete="off"
      >
        <Form.Item
          name="reason"
          rules={[{ required: true, message: t("validation:field_required") }]}
          extra={
            t("validation:min_char_number", { number: 1 }) +
            ". " +
            t("common:misc.entered_characters_number", { number: reason.length })
          }
        >
          <TextArea
            name="reason"
            placeholder={t("ui:title.executor_change.input_placeholder")}
            value={reason}
            onChange={(event) => setReason(event.target.value)}
            size="large"
            autoSize={{ minRows: 4 }}
          />
        </Form.Item>
      </Form>
      <DialogActions>
        <Button onClick={onClose} variant="text">
          {t("ui:button.cancel")}
        </Button>
        <Button
          loading={loading}
          onClick={handleSubmit}
          disabled={loading}
          type="primary"
          htmlType="submit"
          variant="text"
        >
          {t("ui:button.decline")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
