import React from "react";
import "./TagCheckable.scss";
import { Tag } from "antd";
import { CheckableTagProps } from "antd/es/tag";
import clsx from "clsx";

interface ITagView extends CheckableTagProps {
  //
}

const { CheckableTag } = Tag;

export function TagCheckableView({ className, ...rest }: ITagView) {
  return <CheckableTag className={clsx("uiKit-tagCheckable", className ?? "")} {...rest} />;
}
