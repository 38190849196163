import { comboboxStore, getPluginOptions, usePlateEditorRef } from "@udecode/plate";
import { ELEMENT_MENTION, getMentionOnSelectItem } from "@udecode/plate-mention";
import { useEffect } from "react";
import { UserShortDto } from "../../../../../api";
import useDebounce from "../../../../../hooks/useDebounce";
import { usePagingWithController } from "../../../../../hooks/usePaging";
import { api } from "../../../../../services";
import { Combobox } from "./Combobox";

export const AsyncCombobox = ({ items, component, onRenderItem, pluginKey = ELEMENT_MENTION, id = pluginKey }: any) => {
  // const open = comboboxStore.use.isOpen();
  const text = comboboxStore.use.text();
  //introduce states for managing API calls
  const users = usePagingWithController(api.userShort, { name: text }, { pageSize: 10 });
  const searchDebounce = useDebounce(text != "" && text != null ? text : null, 400);

  const arrayFlip = (fetchedData: UserShortDto[]) => {
    const comboBoxItems = fetchedData.map((item) => {
      return {
        key: item.id,
        text: item.name,
        data: item,
      };
    });
    return comboBoxItems;
  };

  //editor and get trigger based on editor
  const editor = usePlateEditorRef();
  // TODO: Check
  // @ts-ignore
  const { trigger } = getPluginOptions(editor!, pluginKey);

  //focused editorId and id of open combobox
  // const focusedEditorId = useEventEditorId();
  // const activeId = comboboxStore.use.activeId();

  //store value identifying if combobox is open

  // const search = useRef<any>();

  // const isOpen = useMemo(() => {
  //   if (!open || focusedEditorId !== editor?.id || activeId !== id) {
  //     return null;
  //   }
  //   return true;
  // }, [open, focusedEditorId, editor, activeId, id]);

  // useEffect(() => {
  //   if (text === null) {
  //     setSearchedName(text);
  //     return;
  //   }
  //
  //   if (isOpen && text !== searchedName) {
  //     setSearchedName(text);
  //   }
  // }, [isOpen, text, searchedName]);

  // useEffect(() => {
  //   if (isOpen && !users.info.isLoading) {
  //     // api.userShort.getAll().then((r) => {
  //     let arr = arrayFlip(users.items);
  //     setItems(arr);
  //     // });
  //   }
  // }, [isOpen, users.info.isLoading]);

  useEffect(() => {
    users.reset();
    users.restart();
  }, [searchDebounce]);

  useEffect(() => {
    users.restart(true);
  }, []);

  return (
    <Combobox
      id={id}
      controlled
      trigger={trigger}
      items={arrayFlip(users.items)}
      onRenderItem={onRenderItem}
      component={component}
      onSelectItem={getMentionOnSelectItem({ key: pluginKey })}
      isDone={users.info.isDone}
      loadNext={users.loadNext}
      isLoading={users.info.isLoading}
    />
  );
};
