import React from "react";
import { useTranslation } from "react-i18next";
import SubheaderText from "../subheaderText/SubheaderText";
import { RecurringIssueRuleDto } from "../../../api";
import TimePickerInlinePrimary from "../timePickerInlinePrimary/TimePickerInlinePrimary";
import { FiX } from "@react-icons/all-files/fi/FiX";
import "./RecurringPeriodSelect.scss";
import { guideKeys } from "../../../stores/hintStore";
import { IconButton, Select, SelectOption } from "../../uiKit";
import { OpeningGuideIcon } from "../openingGuideIcon/OpeningGuideIcon";

interface IRecurringPeriodSelectView {
  data: (RecurringIssueRuleDto & { idx?: number })[];
  list: any;

  generateArray: (from: number, to: number, numberLength?: number | null, multipleOf?: number) => (string | number)[];
  daysInMonth: (month: number, year: number) => number;
  getPeriodicitySubheader: (type: string) => string;

  onTypeChange(idx: number, type: string): void;

  onValue1Change(idx: number, value: number | null): void;

  onValue2Change(idx: number, value: number): void;

  onTimeChange(idx: number, value: string): void;

  onDeleteClick(idx: number): void;
}

export type TRecurringPeriodSelect = {
  //
};

function RecurringPeriodSelectView(props: IRecurringPeriodSelectView) {
  const { t } = useTranslation();

  const periodTypes = [
    { id: 0, text: t("common:menu.period_select.every_day"), value: "every_day" },
    { id: 1, text: t("common:menu.period_select.every_week_at_day"), value: "every_week_at_day" },
    { id: 2, text: t("common:menu.period_select.every_month_at_day"), value: "every_month_at_day" },
    { id: 3, text: t("common:menu.period_select.every_n_days"), value: "every_n_days" },
  ];
  //TODO: check FormControl
  return (
    <div>
      <div className="d-stack-row justify-start align-center spacing-1">
        <SubheaderText text={t("ui:subheader.periodicity")} />
        <div style={{ marginBottom: "4px" }}>
          <OpeningGuideIcon contentKey={guideKeys.recurringIssues} anchorId={"3anchor"} />
        </div>
      </div>
      <div className="d-stack-column spacing-2">
        {props.data.map((item) => (
          <div className="d-stack-row spacing-2" key={item.idx}>
            <Select
              className="flex-grow-1"
              onChange={(value) => props.onTypeChange(item.idx as number, value)}
              value={(item.type ?? "").trim().length > 0 ? item.type : undefined}
              placeholder={t("ui:placeholder.click_to_select")}
              // onChange={(value) => props.onTypeChange(item.idx as number, value as string)}
              items={periodTypes}
            />

            {item.type == "every_week_at_day" && (
              <Select
                onChange={(value) => props.onValue1Change(item.idx as number, value ? +value : null)}
                value={item.value}
                fullWidth
              >
                {props.list.days.map((day: { id: number; name: string }) => (
                  <SelectOption key={day.id} value={day.id}>
                    {day.name}
                  </SelectOption>
                ))}
              </Select>
            )}

            {item.type == "every_month_at_day" && (
              <Select
                onChange={(value) =>
                  props.onValue1Change(item.idx as number, Number(value) == 0 ? null : Number(value))
                }
                value={item.value}
                fullWidth
              >
                {props.list.months.map((month: { id: number; name: string }) => (
                  <SelectOption key={month.id} value={month.id}>
                    {month.name}
                  </SelectOption>
                ))}
              </Select>
            )}

            {item.type == "every_month_at_day" && (
              <Select
                onChange={(value) => props.onValue2Change(item.idx as number, Number(value))}
                value={item.value2}
                fullWidth
              >
                {props
                  .generateArray(2, props.daysInMonth(item.value ?? 0, new Date().getFullYear()) + 1)
                  .map((d: any) => (
                    <SelectOption key={d} value={d}>
                      {d}
                    </SelectOption>
                  ))}
              </Select>
            )}

            {item.type == "every_n_days" && (
              <Select
                onChange={(value) => props.onValue1Change(item.idx as number, Number(value))}
                value={item.value}
                fullWidth
              >
                {props.generateArray(2, 31)?.map((d: any) => (
                  <SelectOption key={d} value={d}>
                    {d}
                  </SelectOption>
                ))}
              </Select>
            )}

            {item.type != "" && (
              <TimePickerInlinePrimary
                value={item.timeCreateAt?.substring(0, 5)}
                onChange={(value: any) => props.onTimeChange(item.idx as number, value)}
              />
            )}

            {item.type != "" && props.data.length > 0 && (
              <IconButton
                size="large"
                onClick={() => props.onDeleteClick(item.idx as number)}
                className="PeriodsSelect-removeButton"
              >
                <FiX />
              </IconButton>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default RecurringPeriodSelectView;
