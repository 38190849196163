export enum NodeType {
  Text = "text",
  Link = "link",
  Date = "date",
  Time = "time",
  Switch = "switch",
  User = "user",
  Orgchart = "orgchart",
  Role = "role",
  Name = "name",
}

export enum ChildType {
  Deleted = "deleted",
  Added = "added",
}

export enum ToolbarType {
  Dynamic = "dynamic",
  Hidden = "hidden",
}
