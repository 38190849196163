import React from "react";
import "./ZoomButton.scss";
import { Button, Card, Icon, IconButton, Text } from "../../uiKit";
import { FiMinus } from "@react-icons/all-files/fi/FiMinus";
import { FiPlus } from "@react-icons/all-files/fi/FiPlus";
import { TZoomButtonBehavior } from "./ZoomButton";

export interface IZoomButtonView {
  value: number;
  behavior?: TZoomButtonBehavior;
  step: number;
  min: number;
  max: number;
  valueToReset?: number;
  buttonProps?: {
    text?: {
      afterValue?: string;
    };
  };
  handleChange(value: number): void;
  handleReset(): void;
}

function ZoomButtonView(props: IZoomButtonView) {
  const getNormalizedNumber = (n: any) =>
    props.behavior == "counter" ? n : Number(((Number(n) ?? 1) * 100).toFixed(1));
  // const getNormalizedNumber = (n: any) => Math.round(Number(n.toFixed(2)) * 100);

  return (
    <Card
      className="br-md flex-shrink-0"
      style={{ boxShadow: "none" }}
      bodyStyle={{ padding: "0px", display: "flex", flexWrap: "nowrap", alignItems: "center" }}
    >
      <IconButton
        disabled={getNormalizedNumber(props.value) <= getNormalizedNumber(props.min)}
        onClick={() => props.handleChange(-props.step)}
        type="ghost"
        // size="small"
        icon={<Icon component={() => <FiMinus color="var(--color-primary-base)" />} />}
      />
      <Button
        onClick={() => props.handleReset()}
        disabled={props.value == (props.valueToReset ?? 1)}
        size="small"
        className="px-2"
        type="text"
        style={{ minWidth: "4.5em" }}
      >
        <Text
          style={{ whiteSpace: "pre", color: "var(--color-primary-base)" }}
          children={getNormalizedNumber(props.value) + " "}
        />
        <Text style={{ color: "var(--color-primary-base)" }} children={props.buttonProps?.text?.afterValue ?? "%"} />
      </Button>
      <IconButton
        disabled={getNormalizedNumber(props.value) >= getNormalizedNumber(props.max)}
        onClick={() => props.handleChange(props.step)}
        type="ghost"
        // size="small"
        icon={<Icon component={() => <FiPlus color="var(--color-primary-base)" />} />}
      />
    </Card>
  );
}

export default ZoomButtonView;
