import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Select, Spin } from "../../../../../../../uiKit";
import { IssueHistoryCard } from "../issueHistoryCard/IssueHistoryCard";
import { EmptyBlock } from "../../../../../../layouts/emptyBlock/EmptyBlock";
import ScrollTrigger from "../../../../../../../service/scrollTrigger/ScrollTrigger";
import { IIssueHistoryListCommentsView } from "./IssueHistoryListComments.interface";
import { IssueContext } from "../../../../../../../../contexts/communication/issueContext";

export const IssueHistoryListCommentsView = (props: IIssueHistoryListCommentsView) => {
  const { t } = useTranslation();
  const { issueType } = useContext(IssueContext);

  return (
    <>
      <div className="d-flex d-stack-row align-end justify-space-between">
        <div className="custom-select">
          <Select
            size={"middle"}
            style={{ minWidth: 140 }}
            items={props.filterItems}
            value={props.currentFilter}
            onChange={props.onChangeFilter}
          />
        </div>
        {/*<Link*/}
        {/*  className="report-driver-link"*/}
        {/*  onClick={props.driverReports && props.driverReports.length > 0 ? props.onOpenDriverReportsDialog : undefined}*/}
        {/*>*/}
        {/*  {props.driverReports && props.driverReports.length > 0*/}
        {/*    ? `${t("ui:button.driver_issue_actions")}: ${props.totalItemsDriverReports}`*/}
        {/*    : `${t("ui:button.no_driver_issue_actions")}`}*/}
        {/*</Link>*/}
      </div>
      <Spin spinning={props.isLoading} size="large">
        <div
          className="d-stack-column justify-start align-center spacing-2"
          style={{
            minHeight: 395,
            overflowY: "auto",
            width: "100%",
            flexGrow: 1,
            marginTop: 8,
          }}
        >
          {props.historyItems.length > 0 &&
            props.historyItems.map((issueHistoryItem, index) => {
              return (
                <IssueHistoryCard
                  id={issueHistoryItem.id}
                  index={index}
                  reactions={issueHistoryItem.reactions}
                  dateCreated={issueHistoryItem.dateCreated}
                  key={issueHistoryItem.id}
                  logs={issueHistoryItem.logs}
                  createdByUser={issueHistoryItem?.createdByUser}
                  comment={issueHistoryItem?.comment?.text}
                  actions={issueHistoryItem?.actions}
                  isRead={issueHistoryItem?.isRead}
                  boardStatus={issueHistoryItem.status?.boardStatus}
                  status={issueHistoryItem?.status?.boardStatus?.name}
                  attachments={issueHistoryItem.comment?.attachments ?? []}
                />
              );
            })}
          {props.historyItems.length == 0 && props.isDone && <EmptyBlock />}
          <div style={{ width: "100%" }}>
            <ScrollTrigger
              disabled={props.isDone}
              onIntersection={props.handleLoadNext}
              marginTop={props.historyItems.length > 0}
              hidden={props.isDone}
            />
          </div>
        </div>
      </Spin>
    </>
  );
};
