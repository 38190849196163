import React from "react";
import { PlanHistoryReactionDto, RegulationHistoryDto } from "../../../../../../api";
import { api } from "../../../../../../services";
import { IPlanHistoryCard } from "./PlanHistoryModule.interface";
import { CommentCard } from "../../../../../elements/commentCard/CommentCard";
import { ReactionsStrategy } from "../../../../../elements/emojiPicker/strategies/ReactionsStrategy";

export const PlanHistoryCard = (props: IPlanHistoryCard) => {
  const planReactionsStrategy = new ReactionsStrategy<RegulationHistoryDto>(
    props.historyId,
    api.planHistory,
    (x: PlanHistoryReactionDto) => x.value as string,
    (x: PlanHistoryReactionDto) => x?.user?.name ?? x?.user?.nameFallback ?? ""
  );

  return (
    <CommentCard
      creator={props.creator}
      index={props.index}
      historyId={props.historyId}
      reactions={props.reactions}
      reactionStrategy={planReactionsStrategy}
      commentContent={props.commentContent}
      commentAttachments={props.commentAttachments}
      dateCreated={props.dateCreated}
      creatorId={props.creatorId}
    />
  );
};
