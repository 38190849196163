import React, { useState } from "react";
import "./SearchInputField.scss";
import { FiSearch } from "@react-icons/all-files/fi/FiSearch";
import {ISearchInputField} from "./SearchInputField.interface";
import clsx from "clsx";
import { Input } from "../../uiKit";

export const SearchInputField = (props: ISearchInputField) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const inputStyles = clsx(
      props.className,
    { "text-field-alternative-filled": !props.backgroundInherit },
    { "background-inherit": props.backgroundInherit },
    { "zoom-active": isFocused }
    // {"text-field-custom-standard" : variant === "standard"},
  );

  return (
    <Input
      style={props.styles}
      autoComplete="off"
      className={inputStyles}
      allowClear
      prefix={<FiSearch />}
      placeholder={props.placeholder}
      value={props.value}
      onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => props.setValue(event?.target.value)}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
    />
  );
};
