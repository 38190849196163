import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  checkIsOpenCreateRoutine,
  handleParseIssueTypeToQP,
  IssueCommonOptionsArr,
  removeQueryIssueRoutine
} from "./helpers";
import RecurringIssueCreateEditDialog
  from "../../modules/dialogs/recurringIssueCreateEditDialog/RecurringIssueCreateEditDialog";
import { IssueCommonDialog } from "../../modules/dialogs/issueCommonDialog/IssueCommonDialog";
import { IssueFormKeys } from "../../modules/dialogs/issueCommonDialog/IssueCommonDialog.interface";
import { IssueQueryKeys, IssueTypeKeys } from "../../../constants/issueTypeKeys";

export const CommonDialogWrapperByQP = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const [search, setSearch] = useSearchParams();

  const [currentIssueCommonType, setCurrentIssueCommonType] = useState<IssueTypeKeys | null>(null);
  const [isOpenCommonIssueDialog, setIsOpenCommonIssueDialog] = useState<boolean>(false);
  const [isOpenCreateRoutine, setIsOpenCreateRoutine] = useState<boolean>(false);


  const handleCheckOpenIssueByQPKey = (QPKey: IssueQueryKeys): boolean => !!search.get(QPKey);

  const handleCloseIssueCommonDialog = () => {
    const deleteQP = currentIssueCommonType ? handleParseIssueTypeToQP(currentIssueCommonType) : null;
    if (deleteQP && search.has(deleteQP)) {
      setIsOpenCommonIssueDialog(false);
      setCurrentIssueCommonType(null);
      search.delete(deleteQP);
      setSearch(search);
    }
  };

  const handleTrackingOpenCommonIssueDialog = () => {
    for (let i = 0; i < IssueCommonOptionsArr.length; i++) {
      if (handleCheckOpenIssueByQPKey(IssueCommonOptionsArr[i].queryParam)) {
        setIsOpenCommonIssueDialog(true);
        return setCurrentIssueCommonType(IssueCommonOptionsArr[i].issueType);
      }
    }
  };


  const handleCheckOpenCreateRoutine = () => {
    const isOpen: boolean = checkIsOpenCreateRoutine(location.search);
    isOpen && !isOpenCreateRoutine && setIsOpenCreateRoutine(isOpen);
  };

  const handleCloseCreateRoutine = () => {
    navigate({ search: removeQueryIssueRoutine(location.search) });
    setIsOpenCreateRoutine(false);
  };

  useEffect(() => {
    if (!location.search.length) return;
    handleCheckOpenCreateRoutine();
    handleTrackingOpenCommonIssueDialog();
  }, [location.search]);

  return (
    <>
      {isOpenCreateRoutine &&
        <RecurringIssueCreateEditDialog
          open={isOpenCreateRoutine}
          onCreate={handleCloseCreateRoutine}
          onClose={handleCloseCreateRoutine}
        />}
      {isOpenCommonIssueDialog && currentIssueCommonType &&
        <IssueCommonDialog
          issueType={currentIssueCommonType}
          formType={IssueFormKeys.create}
          onClose={handleCloseIssueCommonDialog}
          open={isOpenCommonIssueDialog}
        />}
    </>
  );
};