import { RegulationDto, SectionDto } from "../../../../../../api";
import { regulationContentType } from "../../misc/headerTools";

export enum paginationTypeKeys {
  byButton = "by-button",
  byScroll = "by-scroll",
}

export type paginationType = paginationTypeKeys.byScroll | paginationTypeKeys.byButton

export interface IListRegulationCards {
  regulations: RegulationDto[];
  loadNext?:() => void;
  isDone?: boolean;
  isLoading ?: boolean;
  paginationType?: paginationType;
  regulationContentType: regulationContentType,
}

export interface IListSectionCards {
  sections: SectionDto[];
  loadNext: () => void;
  isDone?: boolean;
  isLoading?: boolean;
  paginationType: paginationType;
}
export interface ICommonCardsList {
  regulationContentType : regulationContentType,
  regulations: RegulationDto[];
  sections: SectionDto[];
  paginationType: paginationType;
  loadNextRegulations: () => void;
  loadNextSections: () => void;
  isLoading ?: boolean;
  isDoneRegulation : boolean;
  isDoneSection : boolean;
}