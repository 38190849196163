import React from "react";
import { Card } from "../card/Card";
import "./AudioRecorder.scss";
import { IAudioRecorderState } from "./AudioRecorder";
import { IconButton } from "../general/iconButton/IconButton";
import { Icon } from "../icon/Icon";
import { FiX } from "react-icons/all";
import { Text } from "../general/typography";
import { Shape } from "../shape/Shape";
import { WaveForm } from "../waveForm/WaveForm";

interface IAudioRecorderView {
  state: IAudioRecorderState;
  recordTimeFormatted: string;
  recordAudioBuffer: AudioBuffer | null;
  isVisibleWhenNotRecording?: boolean;

  onRecordStart: (viaClick?: boolean) => void;
  onRecordStop: (viaClick?: boolean) => void;
  onRecordReset: () => void;
  onRecordSave: () => void;
}

export function AudioRecorderView(props: IAudioRecorderView) {
  return (
    <Card
      isShadowed={false}
      variant="secondary"
      style={{
        display:
          props.isVisibleWhenNotRecording == false && !(props.state.isRecording || props.state.isPaused)
            ? "none"
            : undefined,
      }}
      bodyStyle={{ padding: "4px", height: "32px" }}
    >
      <div className="d-stack full-height spacing-3 align-center">
        {(props.state.isPaused || props.state.isRecording) && (
          <IconButton
            size="small"
            onClick={() => props.onRecordStop()}
            shape="circle"
            children={<Icon component={() => <FiX />} />}
          />
        )}
        <div className="flex-grow-1 d-stack spacing-2 align align-center">
          <div className="flex-grow-1">
            {props.recordAudioBuffer != null && (
              <WaveForm height={24} audioSrc={props.recordAudioBuffer} progress={0} />
            )}
          </div>
          <div className="d-stack flex-shrink-0 spacing-1 align-center">
            {props.state.isRecording && !props.state.isPaused && (
              <Shape
                className="blink-slower"
                // className={props.state.isRecording ? "blink-slower" : ""}
                type="circle"
                backgroundColor="var(--color-error-base)"
                size={12}
              />
            )}
            <Text
              style={{ textAlign: "end", fontVariantNumeric: "tabular-nums" }}
              size="12px"
              type="secondary"
              children={props.recordTimeFormatted}
            />
          </div>
        </div>
        {/*<IconButton*/}
        {/*  size="small"*/}
        {/*  onClick={() =>*/}
        {/*    props.state.isRecording && !props.state.isPaused ? props.onRecordStop() : props.onRecordStart()*/}
        {/*  }*/}
        {/*  type="primary"*/}
        {/*  children={*/}
        {/*    <Icon component={() => (props.state.isRecording && !props.state.isPaused ? <FiPause /> : <FiMic />)} />*/}
        {/*  }*/}
        {/*/>*/}
      </div>
    </Card>
  );
}
