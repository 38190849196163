import React, { useState } from "react";
import { DropdownButtonView } from "./DropdownButtonView";
import { DropdownButtonProps } from "antd/lib/dropdown";
import { PopupMenuItemTypes } from "../../../service/dropdownMenu/dropdownMenu.interface";
import { DropdownMenuList } from "../../../service/dropdownMenu/dropdownMenuList";
import { FaChevronDown } from "@react-icons/all-files/fa/FaChevronDown";
import { Dropdown } from "antd";
import { Icon } from "../../icon/Icon";

export enum closeByKeys {
  hover = "hover",
  click = "click",
}

export type closeByType = closeByKeys.hover | closeByKeys.click;

// @ts-ignore
interface IDropdownButton extends DropdownButtonProps {
  items: PopupMenuItemTypes[];
  text: string;
  overlay?: JSX.Element;
  closeBy?: closeByType;
}

export function DropdownButton(props: IDropdownButton) {
  const [open, setOpen] = useState<boolean>(false);

  const handleToggleOpen = (open: boolean) => setOpen(open);
  const handleClose = () => setOpen(false);

  const closeByPatern = props.closeBy ?? closeByKeys.click;

  const menu = <DropdownMenuList onClose={handleClose} items={props.items} />;

  return (
    <>
      {closeByPatern === closeByKeys.click ? (
        <DropdownButtonView
          open={open}
          onOpenChange={handleToggleOpen}
          trigger={["click", "hover"]}
          menu={{
            items: props.items
              .filter((i) => !i.hidden)
              .map((i) => ({
                key: i.id,
                icon: i.icon,
                label: i.text,
                onClick: () => {
                  i.action();
                  handleClose();
                },
              })),
          }}
          children={props.text}
          icon={props.icon ?? <Icon component={() => <FaChevronDown />} />}
          {...props}
        />
      ) : (
        <Dropdown.Button
          onClick={props.onClick}
          onOpenChange={handleToggleOpen}
          // overlayStyle={{ zIndex: 10000 }}
          type="primary"
          menu={{
            items: props.items
              .filter((i) => !i.hidden)
              .map((i) => ({
                key: i.id,
                icon: i.icon,
                label: i.text,
                onClick: () => {
                  i.action();
                  handleClose();
                },
              })),
          }}
        >
          {props.text}
        </Dropdown.Button>
      )}
    </>
  );
}
