import React, { useEffect, useRef, useState } from "react";
import { MetricGraphDto, MetricGraphPeriodDto, MetricReadDto, MetricValueDto, UserDto } from "../../../../api";
import { useNotifier } from "../../../../hooks";
import { api } from "../../../../services";
import { useTranslation } from "react-i18next";
import { removeDuplicatesFromArrayByKey } from "../../../../helpers/arrayFunctions";
import { MetricDataDialogView } from "./MetricDataDialogView";
import { formatDateToDateString } from "../../../../helpers/formatFunctions";

interface IMetricDataDialog {
  isOpen?: boolean;
  metric: MetricReadDto;
  graphId?: number;
  // onSuccess: () => void;
  onOpen: (isOpen: boolean) => void;
  onSave: (data: MetricReadDto) => void;
}

export interface IMetricDataData {
  graphId: number;
  metricSource2UserId: number;
  tableData: ITableDataEl[];
}

export interface ITableDataEl {
  date: string;
  dateISO: string;
  quota: number | null;
  value: number | null;
  filler: UserDto | null;
  fill_date: string;
}

export function MetricDataDialog(props: IMetricDataDialog) {
  const notifier = useNotifier();
  const { t } = useTranslation();

  const [data, setData] = useState<IMetricDataData[]>([]);
  const [changedData, setChangedData] = useState<IMetricDataData[]>([]);
  const [loadingState, setLoadingState] = useState<{
    isLoading: boolean;
    isDone: boolean;
  }>({
    isLoading: false,
    isDone: false,
  });
  const [currentGraphId, setCurrentGraphId] = useState<number | null>(
    props.graphId != null ? props.graphId : props.metric?.graphs?.[0]?.id ?? null
  );
  const metric = useRef<MetricReadDto | null>(null);
  const lastMetricPeriodDate = useRef<string | null>(null);

  const handleDialogOpen = async () => {
    setCurrentGraphId(props.graphId != null ? props.graphId : props.metric?.graphs?.[0]?.id ?? null);
    await loadData(true);
  };

  const handleDialogClose = () => {
    setData([]);
    setChangedData([]);
    setCurrentGraphId(null);
    lastMetricPeriodDate.current = null;
    metric.current = null;
  };

  const loadData = async (initLoad?: boolean) => {
    setLoadingState({ ...loadingState, isLoading: true });
    const r = await api.metricRead.getById(props.metric.id!, {
      includeMetricSource2UserIds:
        (props.graphId != null
          ? [props.metric?.graphs?.find((g) => g.id == props.graphId)?.metricSourceId]
          : props.metric?.graphs?.map((g) => g.metricSource2UserId)) ?? null,
      graphPeriodsAfter: initLoad ? 2 : 0,
      graphPeriodsBefore: 20,
      graphDateStart: initLoad || lastMetricPeriodDate.current == null ? undefined : lastMetricPeriodDate.current,
    });

    if (r == null) {
      setLoadingState({ ...loadingState, isLoading: false });
      notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
      return;
    }

    const graphPeriodsArray: MetricGraphPeriodDto[] = removeDuplicatesFromArrayByKey(
      (r.graphs?.map((g) => g.periods)?.flat() ?? []) as MetricGraphPeriodDto[],
      "label"
    )
      .filter((g: MetricGraphPeriodDto) => g.date != null)
      .sort(function (a: MetricGraphPeriodDto, b: MetricGraphPeriodDto) {
        return a.date! < b.date! ? -1 : a.date! > b.date! ? 1 : 0;
      });

    if (graphPeriodsArray.length == 0) {
      // setLoadingState({ ...loadingState, isLoading: false, isDone: true });
      return;
    } else {
      lastMetricPeriodDate.current = graphPeriodsArray[0].date ?? null;
    }

    if (initLoad) {
      metric.current = r;
      setChangedData(
        (r.graphs ?? []).map((g) => ({ metricSource2UserId: g.metricSource2UserId!, graphId: g.id!, tableData: [] })) ??
          []
      );
    } else {
      const graphsOld: MetricGraphDto[] = metric.current?.graphs ?? [];
      const graphsNew: MetricGraphDto[] = r.graphs ?? [];
      const graphsMerged: MetricGraphDto[] =
        graphsOld.map((gO) => {
          const graphPeriodsToAdd =
            [
              ...(graphsNew.find((gN) => gN.id == gO.id)?.periods ?? [])
                ?.filter((g: MetricGraphPeriodDto) => g.date != null)
                ?.sort(function (a: MetricGraphPeriodDto, b: MetricGraphPeriodDto) {
                  return a.date! < b.date! ? -1 : a.date! > b.date! ? 1 : 0;
                })
                .slice(0, -1),
            ] ?? [];
          let graphToChange = graphsNew.find((gN) => gN.id == gO.id);
          if (graphToChange == null) return gO;
          graphToChange = {
            ...graphToChange,
            periods: removeDuplicatesFromArrayByKey<MetricGraphPeriodDto>(
              [...(graphPeriodsToAdd ?? []), ...(gO.periods ?? [])],
              "label"
            )
              .filter((g: MetricGraphPeriodDto) => g.date != null)
              .sort(function (a: MetricGraphPeriodDto, b: MetricGraphPeriodDto) {
                return a.date! < b.date! ? -1 : a.date! > b.date! ? 1 : 0;
              }),
          };
          return graphsNew.some((gN) => gN.id == gO.id) ? graphToChange : gO;
        }) ?? [];
      metric.current = { ...metric.current, graphs: graphsMerged };
    }

    let nD: IMetricDataData[] = [];
    metric.current?.graphs?.forEach((graph) => {
      if (props.graphId != null && graph.id != props.graphId) return;
      const newData = generateTableData(graph.id!, graph.metricSource2UserId!);
      if (nD.some((n) => n.graphId == graph.id)) {
        nD = [...nD.map((oldData) => (oldData.graphId == newData.graphId ? newData : oldData))];
      } else {
        nD = [...nD, newData];
      }
      // nD =
      //   nD.length == 0 ? [newData] : [...nD.map((oldData) => (oldData.graphId == newData.graphId ? newData : oldData))];
      // setData((data) =>
      //   data.length == 0
      //     ? [newData]
      //     : [...data.map((oldData) => (oldData.graphId == newData.graphId ? newData : oldData))]
      // );
    });
    setData(nD);

    setLoadingState({ ...loadingState, isLoading: false });
  };

  const handleCurrentGraphIdChange = (value: number | null) => {
    setCurrentGraphId(value);
  };

  const handleLoadMore = async () => {
    await loadData();
  };

  const generateTableData = (graphId: number, metricSource2UserId: number): IMetricDataData => {
    const graphPeriodsArray: MetricGraphPeriodDto[] =
      metric.current?.graphs
        ?.find((g) => g.id == graphId)
        ?.periods?.filter((g: MetricGraphPeriodDto) => g.date != null)
        .sort(function (a: MetricGraphPeriodDto, b: MetricGraphPeriodDto) {
          return a.date! < b.date! ? -1 : a.date! > b.date! ? 1 : 0;
        }) ?? [];

    const getFillDate = (period?: MetricGraphPeriodDto | null): string => {
      const _date = period?.value?.dateCreated ?? period?.quota?.dateCreated;
      return _date != null ? formatDateToDateString(new Date(_date)) : "";
    };

    const getFiller = (period?: MetricGraphPeriodDto | null): UserDto | null => {
      return period?.value?.user ?? period?.quota?.user ?? null;
    };

    return {
      tableData: graphPeriodsArray
        .map((gP) => ({
          date: gP.label ?? "",
          dateISO: gP.date ?? "",
          value:
            metric.current?.graphs?.find((g) => g.id == graphId)?.periods?.find((p) => p.label == gP.label)?.value
              ?.value ?? null,
          quota:
            metric.current?.graphs?.find((g) => g.id == graphId)?.periods?.find((p) => p.label == gP.label)?.quota
              ?.quota ?? null,
          fill_date: getFillDate(
            metric.current?.graphs?.find((g) => g.id == graphId)?.periods?.find((p) => p.label == gP.label)
          ),
          filler: getFiller(
            metric.current?.graphs?.find((g) => g.id == graphId)?.periods?.find((p) => p.label == gP.label)
          ),
        }))
        .sort(function (a: ITableDataEl, b: ITableDataEl) {
          return a.dateISO! < b.dateISO! ? 1 : a.dateISO! > b.dateISO! ? -1 : 0;
        }),
      // .reverse(),
      graphId: graphId,
      metricSource2UserId: metricSource2UserId,
    };
  };

  const handleDataChange = (
    record: ITableDataEl,
    value: string | number | null | undefined,
    type: "quota" | "value" | "reset"
  ) => {
    const valueToChange =
      changedData.find((d) => d.graphId == currentGraphId)?.tableData?.find((tD) => tD.date == record.date) ??
      data.find((d) => d.graphId == currentGraphId)?.tableData?.find((tD) => tD.date == record.date);
    if (valueToChange == null) return;
    if (type == "value") valueToChange.value = value == null ? null : Number(value);
    if (type == "quota") valueToChange.quota = value == null ? null : Number(value);
    if (type == "reset") {
      valueToChange.value = null;
      valueToChange.quota = null;
    }
    setChangedData((changedData) => {
      return changedData.map((cD) =>
        cD.graphId == currentGraphId
          ? {
              ...cD,
              tableData:
                cD.tableData.find((tD) => tD.date == valueToChange.date) == null
                  ? [...cD.tableData, valueToChange]
                  : cD.tableData.map((tD) => (tD.date == valueToChange.date ? { ...valueToChange } : tD)),
            }
          : cD
      );
    });
  };

  const handleSave = async () => {
    if (changedData.map((d) => d.tableData).flat(2).length == 0) {
      props.onOpen(false);
      return;
    }
    let arr2send: MetricValueDto[] = [];
    changedData.map((cD) => {
      return cD.tableData.map((tD) => {
        arr2send = [
          ...arr2send,
          {
            metricSource2UserId: cD.metricSource2UserId,
            date: tD.dateISO,
            value: tD.value,
            type: 1,
          },
          {
            metricSource2UserId: cD.metricSource2UserId,
            date: tD.dateISO,
            value: tD.quota,
            type: 2,
          },
        ];
      });
    });
    // let arr2send: MetricValueDto[] = changedData.map((cD) => cD.tableData.map((tD) => ({})));
    setLoadingState({ ...loadingState, isLoading: true });
    const r = await api.metric.updateValueOrQuota(arr2send);
    if (r == null) {
      notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
      // TODO: Show error snackbar
      return;
    }
    setLoadingState({ ...loadingState, isLoading: false });
    props.onSave(metric.current as MetricReadDto);
  };

  useEffect(() => {
    props.isOpen && handleDialogOpen();
    !props.isOpen && handleDialogClose();
  }, [props.isOpen]);

  return (
    <MetricDataDialogView
      data={data}
      changedData={changedData}
      isOpen={props.isOpen}
      loadingState={loadingState}
      currentGraphId={currentGraphId}
      metric={metric.current}
      graphId={props.graphId}
      onOpen={props.onOpen}
      onCurrentGraphIdChange={handleCurrentGraphIdChange}
      onLoadMore={handleLoadMore}
      onDataChange={handleDataChange}
      onSave={handleSave}
    />
  );
}
