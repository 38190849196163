import React from "react";
import { ICreateCompanyGroupDialogView } from "./CreateCompanyGroupDialog.interface";
import { useTranslation } from "react-i18next";
import { Form } from "antd";
import { Button, CollapsibleContainer, Dialog, DialogActions, Input, Switch } from "../../../uiKit";
import SubheaderText from "../../../elements/subheaderText/SubheaderText";
import { AutocompleteAsync } from "../../../elements/autocompleteAsync/AutocompleteAsync";
import { LocaleSwitcher } from "./components/LocaleSwitcher/LocaleSwitcher";
import { TelegramBotFieldsControll } from "./components/TelegramBotFieldsControll/TelegramBotFieldsControll";

export const CreateCompanyGroupDialogView: React.FC<ICreateCompanyGroupDialogView> = (props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const handleSubmit = () => form.submit();

  console.log(props.groupName);

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      title={t(`ui:title.${props.telegramFormOnly ? "edit_group_company" : "add_group_company"}`)}
    >
      {(!props.telegramFormOnly || (props.groupName.length > 0 && props.telegramFormOnly)) && ( //проверка на иницилизацию стейта, потуму что форма иницилизруется только 1 раз
        <Form form={form} name="CreateCompanyGroupForm" onFinish={props.onCreate}>
          {!props.telegramFormOnly && (
            <>
              <SubheaderText text={t("ui:subheader.name")} />
              <Form.Item
                className="form-item__mb3"
                // label={t("ui:label.name")}
                name="groupName"
                initialValue={props.groupName}
                rules={[{ required: true, message: t("validation:field_required") }]}
              >
                <Input
                  autoComplete="off"
                  placeholder={t("ui:placeholder.group_company_name")}
                  value={props.groupName}
                  onChange={props.onChangeGroupName}
                />
              </Form.Item>
              <SubheaderText text={t("ui:subheader.drivers")} />
              <Form.Item
                className="form-item__mb3"
                // label={t("ui:label.name")}
                initialValue={props.driversId}
                name="groupDrivers"
                rules={[{ required: true, message: t("validation:field_required") }]}
              >
                <AutocompleteAsync
                  type="user"
                  mode="multiple"
                  value={props.driversId}
                  onChange={props.onChangeDriversId}
                  requestOptions={{ isDriverOrDriverAdminOfGroup: false }}
                />
              </Form.Item>
              {/*placeholder={t("ui:placeholder.choose_companies")}*/}
              <SubheaderText text={t("ui:subheader.drivers_admin")} />
              <Form.Item
                className="form-item__mb3"
                // label={t("ui:label.name")}
                initialValue={props.adminDriversId}
                name="groupAdminDrivers"
                rules={[{ required: true, message: t("validation:field_required") }]}
              >
                <AutocompleteAsync
                  type="user"
                  mode="multiple"
                  value={props.adminDriversId}
                  onChange={props.onChangeAdminDriversId}
                  requestOptions={{ isDriverOrDriverAdminOfGroup: true }}
                />
              </Form.Item>
              <div className="d-stack-row justify-space-between align-center">
                <div>
                  <SubheaderText text={t("ui:subheader.locale")} />
                  <LocaleSwitcher
                    value={props.groupLocale}
                    currentLocale={props.groupLocale}
                    onChangeLocale={props.onChangeLocale}
                  />
                </div>
                <div>
                  <SubheaderText text={" "} />
                  <Switch
                    label={t("ui:label.add_bot")}
                    checked={props.isAddBot}
                    onChange={props.onChangeIsAddTelegramBot}
                  />
                </div>
              </div>
            </>
          )}
          <CollapsibleContainer open={props.isAddBot || !!props.telegramFormOnly}>
            <TelegramBotFieldsControll
              isAddBot={props.isAddBot || !!props.telegramFormOnly}
              messengerConfig={props.messengerConfig}
              onChangeMessengerConfig={props.onChangeMessengerConfig}
            />
          </CollapsibleContainer>
        </Form>
      )}
      <DialogActions>
        <Button onClick={props.onClose} variant="text">
          {t("ui:button.cancel")}
        </Button>
        <Button onClick={handleSubmit} variant="filled" loading={props.isLoading}>
          {t(`ui:button.${props.telegramFormOnly ? "save" : "create"}`)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
