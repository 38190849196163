import React, { useEffect, useState } from "react";
import "./IssueHistoryCard.scss";
import { IIssueHistoryCard } from "./IssueHistoryCard.interface";
import { TextLinkified } from "../../../../../../../elements/textLinkified/textLinkified";
import { useDateHelpers, useHash, useNotifier } from "../../../../../../../../hooks";
import { AudioPlayer, Card, Icon, IconButton, List, ListItem, Text, Tooltip } from "../../../../../../../uiKit";
import { stringToContent } from "../../../../../../../../utils/textEditor";
import { TextEditor } from "../../../../../../textEditorOld/TextEditor";
import { useTranslation } from "react-i18next";
import { UserCard } from "../../../../../../../elements/userCard/UserCard";
import { IssueHistoryDto, IssueHistoryReactionDto } from "../../../../../../../../api";
import { api } from "../../../../../../../../services";
import { Reactions } from "../../../../../../../elements/emojiPicker/Reactions";
import { useColorSchemeStyles } from "../../../../../../../../hooks/useColorSchemeStyles";
import { getStringContentType } from "../../../../../../../../helpers/stringFunctions";
import { StringContentType } from "../../../../../../../../enums/stringContentType";
import { ReactionsStrategy } from "../../../../../../../elements/emojiPicker/strategies/ReactionsStrategy";
import { FiCheck, MdCheck, MdClose } from "react-icons/all";
import { commentIconKeys } from "../../../../../types/consts";
import FileUploader from "../../../../../../../service/fileUploader/FileUploader";
import { colorScheme, colorSchemeType } from "../../../../../../../../utils/colorScheme";

const IssueHistoryCardMemo = (props: IIssueHistoryCard) => {
  const notifier = useNotifier();
  const { t } = useTranslation();
  const { hashFn } = useHash();
  const { formatDateString } = useDateHelpers();
  const { findColorByKey } = useColorSchemeStyles();
  const [isReadButtonLoading, setIsReadButtonLoading] = useState(false);
  const [isRead, setIsRead] = useState<boolean | null>(props.isRead ?? null);

  const actionState = props.actions && props.actions.length > 0 && props.actions[props.actions.length - 1];
  const actionColor = actionState && actionState.colorSchemeKey!;
  const actionIconKey = actionState && actionState.icon;

  const issueReactionsStrategy = new ReactionsStrategy<IssueHistoryDto>(
    props.id!,
    api.issueHistory,
    (x: IssueHistoryReactionDto) => x.value as string,
    (x: IssueHistoryReactionDto) => x?.user?.name ?? x?.user?.nameFallback ?? ""
  );

  const handleUpdateIsReadStatus = async () => {
    setIsReadButtonLoading(true);
    const r = await api.issueHistory.setIsReadStatus(props.id!, { value: !isRead });
    if (r == null) {
      notifier.show({
        message: t("notifier:error.something_wrong"),
        theme: "error",
      });
      return;
    }
    setIsRead(!!r.isRead);
    setIsReadButtonLoading(false);
  };

  useEffect(() => {
    if (props.isRead != isRead) setIsRead(props.isRead ?? null);
  }, [props.isRead]);

  const handleGetCommentContent = (): JSX.Element => {
    if (!props.comment || props.comment?.length == 0) return <TextLinkified text={""} />;

    try {
      if (getStringContentType(props.comment) == StringContentType.text) throw Error();
      const parsedContent = stringToContent(props.comment!);
      return (
        <TextEditor
          id={
            "te-comment-" +
            String(props.id ? props.id * props.index + 1 : props.index) +
            "/" +
            hashFn(props.comment + new Date().getTime())
          }
          readOnly
          onChange={() => {}}
          value={parsedContent}
        />
      );
    } catch (e) {
      return (
        <div className="pt-2">
          <TextLinkified text={props.comment} />
        </div>
      );
    }
  };

  return props.comment || props.status || props.actions ? (
    <Card
      className="full-width"
      bordered
      isShadowed={false}
      bodyStyle={{ padding: 0 }}
      title={
        <div className="py-2 full-width d-stack spacing-3 align-center flex-nowrap">
          <div className="flex-grow-1">
            <UserCard
              userInfo={props.createdByUser ?? undefined}
              nameSize={12}
              additionalInfo={
                <Text className="todo-list__card-footer__createdAt">
                  {props.dateCreated && formatDateString(props.dateCreated, { month: "short" })}
                </Text>
              }
              boldName
            />
          </div>
          <Reactions historyId={props.id ?? 0} reactions={props.reactions ?? []} strategy={issueReactionsStrategy} />
          {props.isRead != null && (
            <Tooltip
              open={isReadButtonLoading ? false : undefined}
              trigger={["hover"]}
              placement="topRight"
              title={isRead ? t("parse:mark_as_unread") : t("parse:mark_as_read")}
            >
              <IconButton
                type="text"
                loading={isReadButtonLoading}
                icon={
                  <Icon
                    component={() => (
                      <FiCheck
                        fontSize={22}
                        stroke={isRead ? "var(--color-primary-base)" : "var(--color-text-weaker)"}
                      />
                    )}
                  />
                }
                onClick={handleUpdateIsReadStatus}
              />
            </Tooltip>
          )}
        </div>
      }
    >
      <div className="issue-card-history__content">
        <List className="issue-card-history__content">
          {props.actions &&
            props.actions.length > 0 &&
            props.actions.map((action, index) => (
              <ListItem className="pl-15 py-2 pr-3" key={action.id}>
                <div className="">
                  {actionIconKey ? (
                    <div className="d-stack-row align-center spacing-2">
                      {actionIconKey === commentIconKeys.check ? (
                        <MdCheck fontSize={20} color="var(--color-success-base)" />
                      ) : (
                        actionIconKey === commentIconKeys.cross && (
                          <MdClose fontSize={20} color="var(--color-error-base)" />
                        )
                      )}
                      <Text
                        style={{
                          color:
                            actionColor == "success"
                              ? "var(--color-success-base)"
                              : actionColor == "error"
                              ? "var(--color-error-base)"
                              : "var(--color-primary-base)",
                        }}
                        key={action.id}
                        children={action.text}
                      />
                    </div>
                  ) : (
                    <Text key={action.id} style={{ color: "var(--color-primary-base)" }} children={action.text} />
                  )}
                </div>
              </ListItem>
            ))}
          {props.boardStatus && props.status && props.status.length > 0 && (
            <ListItem className="pl-15 py-2 pr-3">
              <div className="full-width no-select">
                <Text className="">
                  {`${t("parse:moved_to")}`}
                  <span
                    style={{
                      color:
                        findColorByKey((props.boardStatus.colorSchemeKey as colorSchemeType) ?? colorScheme.Primary)
                          ?.textColorAccent ?? "var(--color-primary-base)",
                    }}
                  >{` «${props.status}»`}</span>
                </Text>
              </div>
            </ListItem>
          )}
          {props.logs &&
            props.logs.map((item) => (
              <ListItem key={item.id} className="pl-15 py-2 pr-3">
                <div className="d-stack-row spacing-2 align-baseline">
                  <Text type="secondary" weight="bold" style={{ wordBreak: "keep-all" }} children={item.fieldName} />
                  <TextEditor
                    id={"te-log-item-" + String(item.id)}
                    readOnly
                    onChange={() => {}}
                    value={stringToContent(item.text!)}
                  />
                </div>
              </ListItem>
            ))}
          {props.comment && (
            <ListItem className="pl-15 py-2 pr-3">
              <div
                className="issue-card-history__content-body-comment ml-n2 my-n2 full-width"
                style={{ maxWidth: "100%" }}
                children={handleGetCommentContent()}
              />
            </ListItem>
          )}
          {(window.location.hostname == "localhost" || window.location.hostname == "beta.tonnus.io") &&
            props.attachments != null &&
            props.attachments.filter((a) => a.file?.type == 3).length > 0 && (
              <ListItem className="pl-15 py-2 pr-3">
                <div className="full-width no-select">
                  <AudioPlayer src={props.attachments.filter((a) => a.file?.type == 3)?.[0]?.file?.url as string} />
                </div>
              </ListItem>
            )}
          {/*&& props.attachments.filter((a) => a.file?.type != 3).length > 0*/}
          {props.attachments != null && props.attachments.length > 0 && (
            <ListItem className="pl-15 py-2 pr-3">
              <div className="full-width no-select">
                <FileUploader
                  noDragAndDrop
                  value={props.attachments.map((item) => item.file!)}
                  hideInfo
                  smallSize
                  noDelete
                  containerClass={"issue-card-history__files-container"}
                />
              </div>
            </ListItem>
          )}
        </List>
      </div>
    </Card>
  ) : null;
};

export const IssueHistoryCard = React.memo(IssueHistoryCardMemo, (prevProps, newProps) => {
  let noChanged: boolean = true;
  if (prevProps.id != newProps.id) noChanged = false;
  if (prevProps.index != newProps.index) noChanged = false;
  if (prevProps.comment != newProps.comment) noChanged = false;
  if (prevProps.isRead != newProps.isRead) noChanged = false;
  if (prevProps.attachments != newProps.attachments) noChanged = false;
  if (prevProps.logs?.length != newProps.logs?.length) noChanged = false;
  if (prevProps.reactions?.length != newProps.reactions?.length) noChanged = false;
  return noChanged;
});
