import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import SubheaderText from "../../../../elements/subheaderText/SubheaderText";
import { useRootStore } from "../../../../../hooks";
import { Select, SelectOption } from "../../../../uiKit";
import Visibility from "visibilityjs";

function AudioSettingsSwitcher() {
  const { t } = useTranslation();
  const { appStore } = useRootStore();
  const stateList = [
    { id: 0, text: t("parse:ns_off"), value: "off" },
    { id: 1, text: t("parse:ns_visible_only"), value: "inactiveOnly" },
    { id: 2, text: t("parse:ns_on"), value: "on" },
  ].filter((i) => (Visibility.isSupported() ? i : i.id != 2));

  return (
    <>
      <SubheaderText text={t("parse:notifications_sound")} />
      <Select
        value={appStore.getAudioSettingsState.notifications}
        onChange={(value) =>
          appStore.setAudioSettingsState({ ...appStore.getAudioSettingsState, notifications: value })
        }
        fullWidth
      >
        {stateList.map((state) => (
          <SelectOption key={state.id} value={state.value}>
            {state.text}
          </SelectOption>
        ))}
      </Select>
    </>
  );
}

export default observer(AudioSettingsSwitcher);
