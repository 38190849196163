import React from "react";
import "./Shape.scss";
import clsx from "clsx";

type TShapeType = "circle" | "square";

interface IShape {
  children?: React.ReactNode;
  type?: TShapeType;
  className?: string;
  size?: number | { width: number; height: number };
  backgroundColor?: string;
  style?: React.CSSProperties;
}

export function Shape(props: IShape) {
  const getElementSize = () => {
    let width: number | null = typeof props.size === "number" ? props.size : props.size?.width ?? null;
    let height: number | null = typeof props.size === "number" ? props.size : props.size?.height ?? null;

    return {
      width: width == null ? undefined : width + "px",
      height: height == null ? undefined : height + "px",
    };
  };

  return (
    <div
      className={clsx("uiKit-shape", props.className ?? "", {
        __square: props.type == "square" || props.type == null,
        __circle: props.type == "circle",
      })}
      style={{
        width: getElementSize().width,
        height: getElementSize().height,
        background: props.backgroundColor,
        ...props.style,
      }}
      children={props.children}
    />
  );
}
