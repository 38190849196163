import React, { useState } from "react";
import { IListEducationSections, ITrainingData } from "./ListEducationSections.interface";
import { ListEducationSectionsView } from "./ListEducationSectionsView";
import VideoDialog from "../../../../dialogs/videoDialog/VideoDialog";

export const ListEducationSections = (props: IListEducationSections) => {
  const [trainingDialogIsOpen, setTrainingDialogIsOpen] = useState<boolean>(false);
  const [currentTraining, setCurrentTraining] = useState<ITrainingData>({
    videoId: undefined,
    trainingKey: undefined,
  });

  const onCloseGuideDialog = () => {
    setTrainingDialogIsOpen(false);
    setCurrentTraining({ videoId: undefined, trainingKey: undefined });
  };

  const handleOpenGuideDialog = (trainingData: ITrainingData) => {
    setCurrentTraining(trainingData);
    setTrainingDialogIsOpen(true);
  };

  return (
    <>
      {trainingDialogIsOpen && currentTraining.videoId && currentTraining.trainingKey && (
        <VideoDialog
          noShowTitle
          open={trainingDialogIsOpen}
          videoId={currentTraining.videoId}
          trainingKey={currentTraining.trainingKey}
          name="communication"
          autoPlay
          onClose={onCloseGuideDialog}
          onWatchedClickObject={{ communication: true }}
          hidden={false}
        />
      )}
      <ListEducationSectionsView items={props.educationItems} onClick={handleOpenGuideDialog} />
    </>
  );
};
