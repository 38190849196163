import React from "react";
import { ButtonView } from "./ButtonView";
import { ButtonProps } from "antd/lib/button";

export interface IButton extends ButtonProps {
  theme?: "error" | "success" | "warning" | "info";
  variant?: "filled" | "outlined" | "text" | "link" | "default";
  allowTextWrap?: boolean;
}

export function Button(props: IButton) {
  return <ButtonView {...props} />;
}
