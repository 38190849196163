import React from "react";
import "./ButtonGroup.scss";
import { ButtonGroupProps } from "antd/lib/button";
import { Button } from "antd";

interface IButtonGroupView extends ButtonGroupProps {
  //
}

const { Group } = Button;

export function ButtonGroupView({ ...rest }: IButtonGroupView) {
  return <Group {...rest} />;
}
