import React from "react";
import PageHeader from "../../../../layouts/pageHeader/PageHeader";
import PageContent from "../../../../layouts/pageContent/PageContent";
import { HeaderBreadcrumbs } from "../../headers/headerBreadcrumbs/HeaderBreadcrumbs";
import { RegulationUsersStats } from "../../pages";
import { IRegulationUsersStats } from "./RegulationUsersStats.interface";

export const RegulationUserStats: React.FC<IRegulationUsersStats> = (props) => {
  return (
    <>
      <PageHeader>
        <HeaderBreadcrumbs />
      </PageHeader>
      <PageContent contentPosition={{ maxWidth: "1200px" }}>
        <RegulationUsersStats userStats={props.userStats} />
      </PageContent>
    </>
  );
};
