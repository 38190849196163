import React from "react";
import "./Menu.scss";
import { Menu, MenuProps } from "antd";
import clsx from "clsx";

interface IMenuView extends MenuProps {
  //
}

export function MenuView({ className, ...rest }: IMenuView) {
  return <Menu className={clsx("uiKit-menu", className ?? "")} {...rest} />;
}
