import { ApiControllerCrud } from "../helpers";
import { AxiosInstance } from "axios";
import { CompanyDto } from "../models/CompanyDto";

export class CompanyController extends ApiControllerCrud<CompanyDto, {}> {
  constructor(cl: AxiosInstance, v: string = "v1") {
    super(cl, v, "company");
  }

  public async applyOrgchartTemplate(key: "d" | "c"): Promise<boolean | null> {
    return await this.process(
      this.post("template/" + key),
      () => true,
      () => false
    );
  }

  public async deleteOrgchart(): Promise<boolean | null> {
    return await this.process(
      this.delete("orgchart"),
      (x) => true,
      (x) => false
    );
  }
}
