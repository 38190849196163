import React, { forwardRef } from "react";
import { IUpdateNoteEditor } from "./UpdateNoteEditor.interface";
import { initialContent } from "../../../../../../utils/textEditor";
import { TextEditor } from "../../../../textEditorOld/TextEditor";

export const UpdateNoteEditor = forwardRef((props: IUpdateNoteEditor, ref) => {
  return (
    <>
      <TextEditor
        readOnly={!props.isEditable}
        id="release-draft-editor"
        ref={ref}
        onChange={props.onChangeDraftUpdateNoteContent}
        value={props.draftUpdateNoteContent ?? initialContent}
      />
    </>
  );
});
