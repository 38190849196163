import { ToolbarButton, usePlateEditorRef } from "@udecode/plate";
import React from "react";
import "../Toolbar/Toolbar.scss";
import { BiLabel } from "@react-icons/all-files/bi/BiLabel";
import { insertAnchor } from "./insertAnchor";
import { useNotifier } from "../../../../../hooks";
import { useTranslation } from "react-i18next";

interface IAnchorToolbarButton {
  anchorId?: string;
}

export const AnchorToolbarButton = (props: IAnchorToolbarButton) => {
  const editor = usePlateEditorRef()!;
  const notifier = useNotifier();
  const { t } = useTranslation();

  const handleCreateAnchor = (event: React.MouseEvent<HTMLSpanElement>) => {
    if (!editor) return;
    event.preventDefault();
    const anchorId = window.prompt(t("ui:placeholder.create_anchor"));
    if (!anchorId?.length || !anchorId?.match(/^[0-9a-zA-Z]+$/)) {
      return notifier.show({
        message: t("notifier:error.anchor_validation"),
        theme: "error",
      });
    }
    if (anchorId?.length && anchorId?.match(/^[0-9a-zA-Z]+$/)) {
      insertAnchor(editor, { anchorId: anchorId });
    }
  };

  return (
    <ToolbarButton icon={<BiLabel className="toolbar__buttons" />} onMouseDown={(event) => handleCreateAnchor(event)} />
  );
};
