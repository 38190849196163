import React from "react";
import { Button, Dialog, DialogActions, Text } from "../../../uiKit";
import "./ThemeSwitchSuggestionDialog.scss";
import darkLight from "../../../../assets/images/dark-light.svg";
import { useTranslation } from "react-i18next";

interface IThemeSwitchSuggestionDialogView {
  isOpen: boolean;
  onClose: () => void;
  onDecline: () => void;
}

export function ThemeSwitchSuggestionDialogView(props: IThemeSwitchSuggestionDialogView) {
  const { t } = useTranslation();

  return (
    <Dialog title={t("parse:theme_suggestion_dialog.title")} destroyOnClose open={props.isOpen} onClose={props.onClose}>
      <div className="d-stack spacing-4 align-start">
        <div className="flex-grow-1 d-stack-column spacing-1">
          <Text
            type="secondary"
            style={{ wordBreak: "keep-all" }}
            children={t("parse:theme_suggestion_dialog.description")}
          />
          {/*<Text*/}
          {/*  type="secondary"*/}
          {/*  style={{ wordBreak: "keep-all" }}*/}
          {/*  children="Это бета-функционал, который может работать нестабильно"*/}
          {/*/>*/}
        </div>
        <img
          className="br-md"
          draggable="false"
          style={{ maxHeight: "10em" }}
          // style={{ display: "block", maxWidth: "64px" }}
          src={darkLight}
        />
      </div>
      <DialogActions>
        <Button
          onClick={props.onDecline}
          variant="outlined"
          children={t("parse:theme_suggestion_dialog.btn_decline")}
        />
        <Button onClick={props.onClose} children={t("parse:theme_suggestion_dialog.btn_accept") + " (β)"} />
      </DialogActions>
    </Dialog>
  );
}
