import { BsLayoutSidebar } from "@react-icons/all-files/bs/BsLayoutSidebar";
import { useTranslation } from "react-i18next";
import { Icon, IconButton, Text } from "../../../../../uiKit";
import { PlanningTaskCards } from "../../components/planningTaskCards/PlanningTaskCards";
import { IPlanningPageSubordinatesSidebarView } from "./PlanningPageMyPlanSidebar.interface";
import "./PlanningPageMyPlanSidebar.scss";
import { FiSidebar } from "@react-icons/all-files/fi/FiSidebar";

export const PlanningPageMyPlanSidebarView = (props: IPlanningPageSubordinatesSidebarView) => {
  const { t } = useTranslation();

  return (
    <>
      {props.issues && props.issues.length ? (
        <div className="sidebar__wrapper px-5 pt-7 full-height">
          <div className="d-stack-row align-center justify-space-between">
            <Text className="sidebar__title">{t("ui:subheader.unclosed_tasks")}</Text>
            <IconButton onClick={props.onHidingSidebarChange} children={<Icon component={() => <FiSidebar />} />} />
          </div>
          <PlanningTaskCards
            issues={props.issues}
            onAddIssue={props.onAddIssue}
            loadingTaskId={props.loadingTaskId}
            plan={props.plan}
            issuesFromCommunicationsRestart={props.issuesFromCommunicationsRestart}
          />
        </div>
      ) : (
        <div className="sidebar__wrapper pt-8 pr-4 pl-4 full-height">
          <div className="d-stack-row align-center justify-space-between" style={{ height: "4%" }}>
            <div className="sidebar__title">{t("ui:subheader.no_open_tasks")}</div>
            <BsLayoutSidebar
              size={22}
              onClick={props.onHidingSidebarChange}
              className="planning-page-main-content__arrow-button"
            />
          </div>
          <div className="sidebar__text d-stack-row align-center justify-center" style={{ height: "96%" }}>
            {t("text:no_open_tasks.sidebar")}
          </div>
        </div>
      )}
    </>
  );
};
