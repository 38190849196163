import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { getObjectFromTimeSpan } from "../../../../../../helpers/dateFunctions";
import { removeLeadingZerosFromString } from "../../../../../../helpers/stringFunctions";
import { Button, Card, Empty, Spin, Text } from "../../../../../uiKit";
import { PlanningRejectDialog } from "../../../../dialogs/planningRejectDialog/PlanningRejectDialog";
import { SelectApproverDialog } from "../../../../dialogs/selectApproverDialog/SelectApproverDialog";
import { EmptyBlock } from "../../../../layouts/emptyBlock/EmptyBlock";
import { PlanHistoryModule } from "../../components/planHistoryModule/PlanHistoryModule";
import { PlanningCollapseContent } from "../../components/planningCollapseContent/PlanningCollapseContent";
import { IPlanningPageMainContentView } from "./PlanningPageMainContent.interface";
import "./PlanningPageMainContent.scss";
import { PlanningPageMenu } from "../planningPageMenu/PlanningPageMenu";

export const PlanningPageMainContentView = forwardRef((props: IPlanningPageMainContentView, ref) => {
  const url = useLocation();
  const params = useParams();
  const { t } = useTranslation();
  const padding = (url.pathname == "/planning/my-plan" && "pt-6") || (params.id && "pt-3");

  return (
    <>
      <PlanningRejectDialog
        isOpen={props.isOpen}
        onOpeningChange={props.onIsOpenChange}
        onPlanReject={props.onPlanStatusEdit}
        isLoadingButton={props.isLoadingButton!}
        plan={props.plan}
      />
      <SelectApproverDialog
        open={props.isOpenApproverDialog}
        onClose={() => props.isOpenApproverDialogChange(false)}
        onSuccess={props.onApproverSuccess}
      />
      <div className={`planning-page-main-content__wrapper ${padding} pl-4 pr-4`}>
        {/*<div className="d-stack-row">*/}
        {/*  <div*/}
        {/*    className="d-stack-row align-center full-width"*/}
        {/*    // style={params.id ? { width: "50%" } : { width: "25%" }}*/}
        {/*  >*/}
        {/*    {!params.id && !props.approvalPlan ? (*/}
        {/*      props.isHideSidebar ? (*/}
        {/*        <div className="planning-page-main-content__icon mr-4 d-stack-row align-center justify-center">*/}
        {/*          <BsLayoutSidebar*/}
        {/*            size={22}*/}
        {/*            onClick={props.onHidingSidebarChange}*/}
        {/*            className="planning-page-main-content__arrow-button"*/}
        {/*          />*/}
        {/*          /!*<IconButton onClick={props.onHidingSidebarChange} children={<Icon component={() => <FiSidebar />} />} />*!/*/}
        {/*        </div>*/}
        {/*      ) : null*/}
        {/*    ) : null}*/}
        {/*    <Text weight="bold" className="planning-page-main-content__title mr-6">*/}
        {/*      {params.id*/}
        {/*        ? `${t("ui:subheader.week_plan")} ${formatDateToDateString(*/}
        {/*            new Date(props.plan?.dateFrom as string)*/}
        {/*          )} - ${formatDateToDateString(new Date(props.plan?.dateTo as string))}`*/}
        {/*        : t("ui:subheader.week_plan")}*/}
        {/*    </Text>*/}
        {/*    {url.pathname == "/planning/my-plan" && (*/}
        {/*      <AutocompleteAsync*/}
        {/*        style={{ width: "335px" }}*/}
        {/*        type="plan"*/}
        {/*        value={props.currentPlanId}*/}
        {/*        onChange={props.onPlanIdChange}*/}
        {/*        requestOptions={{ userId: props.userId, orderBy: "date_created", orderByType: "desc" }}*/}
        {/*        showSearch={false}*/}
        {/*      />*/}
        {/*    )}*/}
        {/*  </div>*/}
        {/*  <div className="d-stack-row justify-end align-center">*/}
        {/*    {url.pathname == "/planning/my-plan" && (*/}
        {/*      <div*/}
        {/*        style={{ background: colorScheme.backgroundColor, color: colorScheme.textColor }}*/}
        {/*        className="planning-page-main-content__indicator d-flex justify-center align-center"*/}
        {/*      >*/}
        {/*        {props.plan?.statusName}*/}
        {/*      </div>*/}
        {/*    )}*/}
        {/*  </div>*/}
        {/*  {url.pathname != "/planning/my-plan" && (*/}
        {/*    <div className="d-stack-row align-center">*/}
        {/*      <UserCard userInfo={props.plan?.user} />*/}
        {/*    </div>*/}
        {/*  )}*/}
        {/*</div>*/}
        {/*{url.pathname == "/planning/my-plan" && props.plan?.canBeMigratedFromPlanId ? (*/}
        {/*  <span onClick={props.onMigrateIssuesToPlan} className={`planning-page-main-content__text`}>*/}
        {/*    {t("text:transfer_undone_tasks")}*/}
        {/*  </span>*/}
        {/*) : null}*/}
        <PlanningPageMenu
          plan={props.plan}
          isHideSidebar={props.isHideSidebar}
          onHidingSidebarChange={props.onHidingSidebarChange}
          currentPlanId={props.currentPlanId}
          userId={props.userId}
          onPlanIdChange={props.onPlanIdChange}
          onMigrateIssuesToPlan={props.onMigrateIssuesToPlan}
        />
        {props.plan && (props.issues.length != 0 || props.scheduleIssues.length != 0) && (
          <Spin size="large" className="d-stack-row justify-center align-center" spinning={props.isLoading}>
            <div className="mt-4">
              <PlanningCollapseContent
                issues={props.issues}
                scheduleIssues={props.scheduleIssues}
                plan={props.plan}
                onLoadRestart={props.onLoadRestart}
                onIssueEdit={props.onIssueEdit}
                onIssueToPlanCreate={props.onIssueToPlanCreate}
                issuesRestart={props.issuesRestart}
                weekReportStart={props.weekReportStart}
                userId={props.userId}
              />
              {/*TODO: Rewrite everything!*/}
              <Card variant="secondary" className="">
                <Text className="mr-55">{t("common:planning.summary_by_function")}</Text>
                <Text className="mr-5">
                  {t("common:planning.number_of_tasks")}
                  {props.issues?.length + props.scheduleIssues?.length}
                </Text>
                <Text className="mr-5">
                  {t("common:planning.sum")}
                  {props.plan?.timePlanOverall != null &&
                    (removeLeadingZerosFromString(getObjectFromTimeSpan(props.plan?.timePlanOverall).totalHours!) > 0
                      ? `${removeLeadingZerosFromString(
                          getObjectFromTimeSpan(props.plan?.timePlanOverall).totalHours!
                        )}${t("common:planning.hour")}`
                      : `0${t("common:planning.hour")}`)}
                  {props.plan?.timePlanOverall != null &&
                    (removeLeadingZerosFromString(getObjectFromTimeSpan(props.plan?.timePlanOverall).minutes!) > 0
                      ? `${removeLeadingZerosFromString(
                          getObjectFromTimeSpan(props.plan?.timePlanOverall).minutes!
                        )}${t("common:planning.minute")}`
                      : `0${t("common:planning.minute")}`)}
                </Text>
              </Card>
              <div className="mt-6">
                <div className="mt-4 mb-6 d-stack-row justify-end align-center">
                  {params.id && !props.approvalPlan && (
                    <Button onClick={props.onIssueToPlanCreate} type="default" style={{ color: "#5672ff" }}>
                      {t("ui:button.create_task")}
                    </Button>
                  )}
                  {props.approvalPlan && (
                    <>
                      <Button
                        onClick={() => props.onIsOpenChange(true)}
                        className="mr-3"
                        type="default"
                        style={{ color: "#5672ff" }}
                      >
                        {t("ui:button.reject")}
                      </Button>
                      <Button
                        onClick={() =>
                          props.onPlanStatusEdit({ ...props.plan, status: 3 }, t("notifier:success.plan_approved"))
                        }
                        loading={props.isLoadingButton}
                      >
                        {t("ui:button.approve")}
                      </Button>
                    </>
                  )}
                  {url.pathname == "/planning/my-plan" && (
                    <>
                      <Button
                        disabled={props.plan.status == 2}
                        onClick={props.onIssueToPlanCreate}
                        type="default"
                        style={{ color: "#5672ff" }}
                      >
                        {t("ui:button.create_task")}
                      </Button>
                      {props.plan.status == 2 || props.plan.status == 3 ? null : (
                        <Button
                          className="ml-3"
                          onClick={() => props.isOpenApproverDialogChange(true)}
                          loading={props.isLoadingButton}
                        >
                          {t("ui:button.submit_for_approval")}
                        </Button>
                      )}
                    </>
                  )}
                </div>
                <PlanHistoryModule ref={ref} planId={props.plan?.id} />
              </div>
            </div>
          </Spin>
        )}
      </div>
      {props.plan && !props.issues.length && !props.scheduleIssues.length && (
        <div className="empty-block-wrapper">
          <Spin size="large" className="d-stack-row justify-center align-center" spinning={props.isLoading}>
            <Empty
              image={<img src={window.location.origin + "/planning-empty.png"} alt="Empty content" />}
              imageStyle={{ height: 300, width: 338, margin: "0 auto" }}
              description={
                <div className="d-stack-column justify-center align-center mt-12" style={{ width: "420px" }}>
                  <Text weight={"bold"} size="16px">
                    {t("text:no_open_tasks.content")}
                  </Text>
                  <Button onClick={props.onIssueToPlanCreate} className="mt-12">
                    {t("ui:button.create_task")}
                  </Button>
                </div>
              }
            />
          </Spin>
        </div>
      )}
      {!props.plan && (
        <div className="empty-block-wrapper">
          <Spin size="large" className="d-stack-row justify-center align-center" spinning={props.isLoading}>
            <EmptyBlock />
          </Spin>
        </div>
      )}
    </>
  );
});
