import React, { forwardRef, useEffect, useState } from "react";
import { IIssueCreateContainer } from "./IssueCreateContainer.interface";
import { StaticFileDto } from "../../../../../../api";
import { IssueFormKeys } from "../../forms/common/IssueForm.interface";
import { IssueFormWrapper } from "../../forms/common/IssueFormWrapper";

export const IssueCreateContainer = forwardRef((props: IIssueCreateContainer, ref) => {
  const [attachmentsIssue, setAttachmentsIssue] = useState<StaticFileDto[]>([]);

  const [executorIds, setExecutorIds] = useState<number[]>(
    props.updater?.currentState?.executorUserId ? [props.updater?.currentState?.executorUserId] : []
  );

  const handleDeleteAttachmentsByFileId = (fileId: number) =>
    props.updater.applyChanges({
      attachments: props.updater?.currentState?.attachments?.filter((item) => item.fileId != fileId) ?? [],
    });

  const setAttachmentsIssueSync = (files: StaticFileDto[]) => {
    const newAttachments = [
      ...(props.updater?.currentState?.attachments ?? []),
      ...files
        .filter((f) => !props.updater?.currentState?.attachments?.some((f2) => f2.fileId == f.id))
        .map((f, i) => ({
          id: 0,
          issueId: props.updater?.currentState?.id,
          fileId: f.id,
          file: f,
        })),
    ];
    props.updater.applyChanges({ attachments: [...newAttachments] });
  };

  const handleChangeExecutorIds = (value: number[]) => setExecutorIds(value);

  useEffect(() => {
    if (props.updater?.currentState?.attachments != null) {
      setAttachmentsIssue(props.updater?.currentState?.attachments.map((item) => item.file) as StaticFileDto[]);
    }
  }, [props.updater?.currentState?.attachments]);

  return (
    <>
      <IssueFormWrapper
        issueType={props.issueType}
        formType={IssueFormKeys.create}
        attachments={attachmentsIssue}
        executorIds={executorIds}
        onCreate={props.onCreate}
        updater={props.updater}
        onChangeExecutorIds={handleChangeExecutorIds}
        ref={ref}
        customFields={props.customFields}
        setAttachmentsIssueSync={setAttachmentsIssueSync}
        onDeleteAttachmentsByFileId={handleDeleteAttachmentsByFileId}
      />
    </>
  );
});
