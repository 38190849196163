import React from "react";
import { ICreateRegulationSectionFormView } from "./CreateRegulationSectionForm.interface";
import { useTranslation } from "react-i18next";
import { EditableTypography } from "../../../elements/editableTypography/EditableTypography";
import { RiUserSettingsLine } from "@react-icons/all-files/ri/RiUserSettingsLine";
import { TransferRegulationsList } from "./TransferRegulationsList";
import { Button, Icon } from "../../../uiKit";

export const CreateRegulationSectionFormView: React.FC<ICreateRegulationSectionFormView> = (props) => {
  const { t } = useTranslation();

  const isIncludeInNewSection = (id: number): boolean => !!props.newRegulations.find((item) => item.id === id);

  return (
    <div className="create-regulation-section-form">
      <div className="create-regulation-section-form__header d-stack-row d-stack-row justify-space-between align-center">
        <div style={{ width: "33%" }}>
          <EditableTypography
            value={props.titleSection}
            onChange={props.onChangeTitleSection}
            placeholder={t("ui:placeholder.section_name")}
          />
        </div>
        <div className="regulations-transfer-list-header__button-group spacing-1">
          <Button disabled={props.newRegulations.length === 0 && !props.isEdit} onClick={props.onResetNewRegulations}>
            {t("ui:button.cancel")}
          </Button>
          <Button
            // disabled={props.isEdit ? !props.isChanged : props.newRegulations.length === 0}
            disabled={props.isEdit ? !props.isChanged : false}
            onClick={props.onSave}
            variant="filled"
            loading={props.isLoading}
          >
            {t("ui:button.save")}
          </Button>
          {!props.isEdit && (
            <Button
              style={{ marginLeft: "8px" }}
              onClick={props.onOpenDialog}
              icon={<Icon component={() => <RiUserSettingsLine />} />}
            >
              {t("ui:button.access_setting")}
            </Button>
          )}
        </div>
      </div>
      <TransferRegulationsList
        isLoading={props.isLoading}
        regulationsIsUploaded={props.regulationsIsUploaded}
        sections={props.sections}
        activeSectionIdFilter={props.activeSectionIdFilter}
        newRegulations={props.newRegulations}
        commonRegulations={props.commonRegulations}
        onRedirectToCreateSection={props.onRedirectToCreateSection}
        onAppendNewRegulation={props.onAppendNewRegulation}
        onChangeRegulationSearchValue={props.onChangeRegulationSearchValue}
        regulationSearchValue={props.regulationSearchValue}
        onRemoveNewRegulation={props.onRemoveNewRegulation}
        onChangeActiveSectionFilter={props.onChangeActiveSectionFilter}
        onLoadNextRegulations={props.onLoadNextRegulations}
        onResetNewRegulations={props.onResetNewRegulations}
        onSave={props.onSave}
      />
    </div>
  );
};
