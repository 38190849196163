import React from "react";
import { IPlanningPageMenu } from "./PlanningPageMenu.interface";
import { Text } from "../../../../../uiKit";
import { formatDateToDateString } from "../../../../../../helpers/formatFunctions";
import { useTranslation } from "react-i18next";
import { UserCard } from "../../../../../elements/userCard/UserCard";

export const PlanningPageWorkPlanMenu = (props: IPlanningPageMenu) => {
  const { t } = useTranslation();

  return (
    <div className="d-stack-row justify-space-between">
      <Text
        weight="bold"
        className="planning-page-main-content__title mr-6"
        children={`${t("ui:subheader.week_plan")} ${formatDateToDateString(
          new Date(props.plan?.dateFrom as string)
        )} - ${formatDateToDateString(new Date(props.plan?.dateTo as string))}`}
      />
      <div className="d-stack-row align-center">
        <UserCard userInfo={props.plan?.user} />
      </div>
    </div>
  );
};
