import React from "react";
import { TabsView } from "./TabsView";
import { TabsProps } from "antd";

interface ITabsView extends TabsProps {
  //
}

export function Tabs(props: ITabsView) {
  return <TabsView {...props} />;
}
