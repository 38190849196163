interface IInputPhoneNumberDataItem {
  name: string;
  regions?: string[];
  iso: string;
  dialCode: string;
  format?: string;
  orderPriority?: number;
  areaCodes?: string[];
}

export const CountryData: IInputPhoneNumberDataItem[] = [
  { name: "Afghanistan", dialCode: "93", iso: "AF" },
  { name: "Albania", dialCode: "355", iso: "AL" },
  { name: "Algeria", dialCode: "213", iso: "DZ" },
  { name: "American Samoa", dialCode: "1684", iso: "AS" },
  { name: "Andorra", dialCode: "376", iso: "AD" },
  { name: "Angola", dialCode: "244", iso: "AO" },
  { name: "Anguilla", dialCode: "1264", iso: "AI" },
  { name: "Antarctica", dialCode: "672", iso: "AQ" },
  { name: "Antigua and Barbuda", dialCode: "1268", iso: "AG" },
  { name: "Argentina", dialCode: "54", iso: "AR" },
  { name: "Armenia", dialCode: "374", iso: "AM" },
  { name: "Aruba", dialCode: "297", iso: "AW" },
  { name: "Australia", dialCode: "61", iso: "AU" },
  { name: "Austria", dialCode: "43", iso: "AT" },
  { name: "Azerbaijan", dialCode: "994", iso: "AZ" },
  { name: "Bahamas", dialCode: "1242", iso: "BS" },
  { name: "Bahrain", dialCode: "973", iso: "BH" },
  { name: "Bangladesh", dialCode: "880", iso: "BD" },
  { name: "Barbados", dialCode: "1246", iso: "BB" },
  { name: "Belarus", dialCode: "375", iso: "BY" },
  { name: "Belgium", dialCode: "32", iso: "BE" },
  { name: "Belize", dialCode: "501", iso: "BZ" },
  { name: "Benin", dialCode: "229", iso: "BJ" },
  { name: "Bermuda", dialCode: "1441", iso: "BM" },
  { name: "Bhutan", dialCode: "975", iso: "BT" },
  { name: "Bolivia", dialCode: "591", iso: "BO" },
  { name: "Bosnia and Herzegovina", dialCode: "387", iso: "BA" },
  { name: "Botswana", dialCode: "267", iso: "BW" },
  { name: "Brazil", dialCode: "55", iso: "BR" },
  { name: "British Indian Ocean Territory", dialCode: "246", iso: "IO" },
  { name: "British Virgin Islands", dialCode: "1284", iso: "VG" },
  { name: "Brunei", dialCode: "673", iso: "BN" },
  { name: "Bulgaria", dialCode: "359", iso: "BG" },
  { name: "Burkina Faso", dialCode: "226", iso: "BF" },
  { name: "Burundi", dialCode: "257", iso: "BI" },
  { name: "Cambodia", dialCode: "855", iso: "KH" },
  { name: "Cameroon", dialCode: "237", iso: "CM" },
  { name: "Canada", dialCode: "1", iso: "CA" },
  { name: "Cape Verde", dialCode: "238", iso: "CV" },
  { name: "Cayman Islands", dialCode: "1345", iso: "KY" },
  { name: "Central African Republic", dialCode: "236", iso: "CF" },
  { name: "Chad", dialCode: "235", iso: "TD" },
  { name: "Chile", dialCode: "56", iso: "CL" },
  { name: "China", dialCode: "86", iso: "CN" },
  { name: "Christmas Island", dialCode: "61", iso: "CX" },
  { name: "Cocos Islands", dialCode: "61", iso: "CC" },
  { name: "Colombia", dialCode: "57", iso: "CO" },
  { name: "Comoros", dialCode: "269", iso: "KM" },
  { name: "Cook Islands", dialCode: "682", iso: "CK" },
  { name: "Costa Rica", dialCode: "506", iso: "CR" },
  { name: "Croatia", dialCode: "385", iso: "HR" },
  { name: "Cuba", dialCode: "53", iso: "CU" },
  { name: "Curacao", dialCode: "599", iso: "CW" },
  { name: "Cyprus", dialCode: "357", iso: "CY" },
  { name: "Czech Republic", dialCode: "420", iso: "CZ" },
  { name: "Democratic Republic of the Congo", dialCode: "243", iso: "CD" },
  { name: "Denmark", dialCode: "45", iso: "DK" },
  { name: "Djibouti", dialCode: "253", iso: "DJ" },
  { name: "Dominica", dialCode: "1767", iso: "DM" },
  { name: "Dominican Republic", dialCode: "1809, 1829, 1849", iso: "DO" },
  { name: "East Timor", dialCode: "670", iso: "TL" },
  { name: "Ecuador", dialCode: "593", iso: "EC" },
  { name: "Egypt", dialCode: "20", iso: "EG" },
  { name: "El Salvador", dialCode: "503", iso: "SV" },
  { name: "Equatorial Guinea", dialCode: "240", iso: "GQ" },
  { name: "Eritrea", dialCode: "291", iso: "ER" },
  { name: "Estonia", dialCode: "372", iso: "EE" },
  { name: "Ethiopia", dialCode: "251", iso: "ET" },
  { name: "Falkland Islands", dialCode: "500", iso: "FK" },
  { name: "Faroe Islands", dialCode: "298", iso: "FO" },
  { name: "Fiji", dialCode: "679", iso: "FJ" },
  { name: "Finland", dialCode: "358", iso: "FI" },
  { name: "France", dialCode: "33", iso: "FR" },
  { name: "French Polynesia", dialCode: "689", iso: "PF" },
  { name: "Gabon", dialCode: "241", iso: "GA" },
  { name: "Gambia", dialCode: "220", iso: "GM" },
  { name: "Georgia", dialCode: "995", iso: "GE" },
  { name: "Germany", dialCode: "49", iso: "DE" },
  { name: "Ghana", dialCode: "233", iso: "GH" },
  { name: "Gibraltar", dialCode: "350", iso: "GI" },
  { name: "Greece", dialCode: "30", iso: "GR" },
  { name: "Greenland", dialCode: "299", iso: "GL" },
  { name: "Grenada", dialCode: "1473", iso: "GD" },
  { name: "Guam", dialCode: "1671", iso: "GU" },
  { name: "Guatemala", dialCode: "502", iso: "GT" },
  { name: "Guernsey", dialCode: "441481", iso: "GG" },
  { name: "Guinea", dialCode: "224", iso: "GN" },
  { name: "Guinea-Bissau", dialCode: "245", iso: "GW" },
  { name: "Guyana", dialCode: "592", iso: "GY" },
  { name: "Haiti", dialCode: "509", iso: "HT" },
  { name: "Honduras", dialCode: "504", iso: "HN" },
  { name: "Hong Kong", dialCode: "852", iso: "HK" },
  { name: "Hungary", dialCode: "36", iso: "HU" },
  { name: "Iceland", dialCode: "354", iso: "IS" },
  { name: "India", dialCode: "91", iso: "IN" },
  { name: "Indonesia", dialCode: "62", iso: "ID" },
  { name: "Iran", dialCode: "98", iso: "IR" },
  { name: "Iraq", dialCode: "964", iso: "IQ" },
  { name: "Ireland", dialCode: "353", iso: "IE" },
  { name: "Isle of Man", dialCode: "441624", iso: "IM" },
  { name: "Israel", dialCode: "972", iso: "IL" },
  { name: "Italy", dialCode: "39", iso: "IT" },
  { name: "Ivory Coast", dialCode: "225", iso: "CI" },
  { name: "Jamaica", dialCode: "1876", iso: "JM" },
  { name: "Japan", dialCode: "81", iso: "JP" },
  { name: "Jersey", dialCode: "441534", iso: "JE" },
  { name: "Jordan", dialCode: "962", iso: "JO" },
  { name: "Kazakhstan", dialCode: "7", iso: "KZ" },
  { name: "Kenya", dialCode: "254", iso: "KE" },
  { name: "Kiribati", dialCode: "686", iso: "KI" },
  { name: "Kosovo", dialCode: "383", iso: "XK" },
  { name: "Kuwait", dialCode: "965", iso: "KW" },
  { name: "Kyrgyzstan", dialCode: "996", iso: "KG" },
  { name: "Laos", dialCode: "856", iso: "LA" },
  { name: "Latvia", dialCode: "371", iso: "LV" },
  { name: "Lebanon", dialCode: "961", iso: "LB" },
  { name: "Lesotho", dialCode: "266", iso: "LS" },
  { name: "Liberia", dialCode: "231", iso: "LR" },
  { name: "Libya", dialCode: "218", iso: "LY" },
  { name: "Liechtenstein", dialCode: "423", iso: "LI" },
  { name: "Lithuania", dialCode: "370", iso: "LT" },
  { name: "Luxembourg", dialCode: "352", iso: "LU" },
  { name: "Macao", dialCode: "853", iso: "MO" },
  { name: "Macedonia", dialCode: "389", iso: "MK" },
  { name: "Madagascar", dialCode: "261", iso: "MG" },
  { name: "Malawi", dialCode: "265", iso: "MW" },
  { name: "Malaysia", dialCode: "60", iso: "MY" },
  { name: "Maldives", dialCode: "960", iso: "MV" },
  { name: "Mali", dialCode: "223", iso: "ML" },
  { name: "Malta", dialCode: "356", iso: "MT" },
  { name: "Marshall Islands", dialCode: "692", iso: "MH" },
  { name: "Mauritania", dialCode: "222", iso: "MR" },
  { name: "Mauritius", dialCode: "230", iso: "MU" },
  { name: "Mayotte", dialCode: "262", iso: "YT" },
  { name: "Mexico", dialCode: "52", iso: "MX" },
  { name: "Micronesia", dialCode: "691", iso: "FM" },
  { name: "Moldova", dialCode: "373", iso: "MD" },
  { name: "Monaco", dialCode: "377", iso: "MC" },
  { name: "Mongolia", dialCode: "976", iso: "MN" },
  { name: "Montenegro", dialCode: "382", iso: "ME" },
  { name: "Montserrat", dialCode: "1664", iso: "MS" },
  { name: "Morocco", dialCode: "212", iso: "MA" },
  { name: "Mozambique", dialCode: "258", iso: "MZ" },
  { name: "Myanmar", dialCode: "95", iso: "MM" },
  { name: "Namibia", dialCode: "264", iso: "NA" },
  { name: "Nauru", dialCode: "674", iso: "NR" },
  { name: "Nepal", dialCode: "977", iso: "NP" },
  { name: "Netherlands", dialCode: "31", iso: "NL" },
  { name: "Netherlands Antilles", dialCode: "599", iso: "AN" },
  { name: "New Caledonia", dialCode: "687", iso: "NC" },
  { name: "New Zealand", dialCode: "64", iso: "NZ" },
  { name: "Nicaragua", dialCode: "505", iso: "NI" },
  { name: "Niger", dialCode: "227", iso: "NE" },
  { name: "Nigeria", dialCode: "234", iso: "NG" },
  { name: "Niue", dialCode: "683", iso: "NU" },
  { name: "North Korea", dialCode: "850", iso: "KP" },
  { name: "Northern Mariana Islands", dialCode: "1670", iso: "MP" },
  { name: "Norway", dialCode: "47", iso: "NO" },
  { name: "Oman", dialCode: "968", iso: "OM" },
  { name: "Pakistan", dialCode: "92", iso: "PK" },
  { name: "Palau", dialCode: "680", iso: "PW" },
  { name: "Palestine", dialCode: "970", iso: "PS" },
  { name: "Panama", dialCode: "507", iso: "PA" },
  { name: "Papua New Guinea", dialCode: "675", iso: "PG" },
  { name: "Paraguay", dialCode: "595", iso: "PY" },
  { name: "Peru", dialCode: "51", iso: "PE" },
  { name: "Philippines", dialCode: "63", iso: "PH" },
  { name: "Pitcairn", dialCode: "64", iso: "PN" },
  { name: "Poland", dialCode: "48", iso: "PL" },
  { name: "Portugal", dialCode: "351", iso: "PT" },
  { name: "Puerto Rico", dialCode: "1787, 1939", iso: "PR" },
  { name: "Qatar", dialCode: "974", iso: "QA" },
  { name: "Republic of the Congo", dialCode: "242", iso: "CG" },
  { name: "Reunion", dialCode: "262", iso: "RE" },
  { name: "Romania", dialCode: "40", iso: "RO" },
  { name: "Russia", dialCode: "7", iso: "RU" },
  { name: "Rwanda", dialCode: "250", iso: "RW" },
  { name: "Saint Barthelemy", dialCode: "590", iso: "BL" },
  { name: "Saint Helena", dialCode: "290", iso: "SH" },
  { name: "Saint Kitts and Nevis", dialCode: "1869", iso: "KN" },
  { name: "Saint Lucia", dialCode: "1758", iso: "LC" },
  { name: "Saint Martin", dialCode: "590", iso: "MF" },
  { name: "Saint Pierre and Miquelon", dialCode: "508", iso: "PM" },
  { name: "Saint Vincent and the Grenadines", dialCode: "1784", iso: "VC" },
  { name: "Samoa", dialCode: "685", iso: "WS" },
  { name: "San Marino", dialCode: "378", iso: "SM" },
  { name: "Sao Tome and Principe", dialCode: "239", iso: "ST" },
  { name: "Saudi Arabia", dialCode: "966", iso: "SA" },
  { name: "Senegal", dialCode: "221", iso: "SN" },
  { name: "Serbia", dialCode: "381", iso: "RS" },
  { name: "Seychelles", dialCode: "248", iso: "SC" },
  { name: "Sierra Leone", dialCode: "232", iso: "SL" },
  { name: "Singapore", dialCode: "65", iso: "SG" },
  { name: "Sint Maarten", dialCode: "1721", iso: "SX" },
  { name: "Slovakia", dialCode: "421", iso: "SK" },
  { name: "Slovenia", dialCode: "386", iso: "SI" },
  { name: "Solomon Islands", dialCode: "677", iso: "SB" },
  { name: "Somalia", dialCode: "252", iso: "SO" },
  { name: "South Africa", dialCode: "27", iso: "ZA" },
  { name: "South Korea", dialCode: "82", iso: "KR" },
  { name: "South Sudan", dialCode: "211", iso: "SS" },
  { name: "Spain", dialCode: "34", iso: "ES" },
  { name: "Sri Lanka", dialCode: "94", iso: "LK" },
  { name: "Sudan", dialCode: "249", iso: "SD" },
  { name: "Suriname", dialCode: "597", iso: "SR" },
  { name: "Svalbard and Jan Mayen", dialCode: "47", iso: "SJ" },
  { name: "Swaziland", dialCode: "268", iso: "SZ" },
  { name: "Sweden", dialCode: "46", iso: "SE" },
  { name: "Switzerland", dialCode: "41", iso: "CH" },
  { name: "Syria", dialCode: "963", iso: "SY" },
  { name: "Taiwan", dialCode: "886", iso: "TW" },
  { name: "Tajikistan", dialCode: "992", iso: "TJ" },
  { name: "Tanzania", dialCode: "255", iso: "TZ" },
  { name: "Thailand", dialCode: "66", iso: "TH" },
  { name: "Togo", dialCode: "228", iso: "TG" },
  { name: "Tokelau", dialCode: "690", iso: "TK" },
  { name: "Tonga", dialCode: "676", iso: "TO" },
  { name: "Trinidad and Tobago", dialCode: "1868", iso: "TT" },
  { name: "Tunisia", dialCode: "216", iso: "TN" },
  { name: "Turkey", dialCode: "90", iso: "TR" },
  { name: "Turkmenistan", dialCode: "993", iso: "TM" },
  { name: "Turks and Caicos Islands", dialCode: "1649", iso: "TC" },
  { name: "Tuvalu", dialCode: "688", iso: "TV" },
  { name: "U.S. Virgin Islands", dialCode: "1340", iso: "VI" },
  { name: "Uganda", dialCode: "256", iso: "UG" },
  { name: "Ukraine", dialCode: "380", iso: "UA" },
  { name: "United Arab Emirates", dialCode: "971", iso: "AE" },
  { name: "United Kingdom", dialCode: "44", iso: "GB" },
  { name: "United States", dialCode: "1", iso: "US" },
  { name: "Uruguay", dialCode: "598", iso: "UY" },
  { name: "Uzbekistan", dialCode: "998", iso: "UZ" },
  { name: "Vanuatu", dialCode: "678", iso: "VU" },
  { name: "Vatican", dialCode: "379", iso: "VA" },
  { name: "Venezuela", dialCode: "58", iso: "VE" },
  { name: "Vietnam", dialCode: "84", iso: "VN" },
  { name: "Wallis and Futuna", dialCode: "681", iso: "WF" },
  { name: "Western Sahara", dialCode: "212", iso: "EH" },
  { name: "Yemen", dialCode: "967", iso: "YE" },
  { name: "Zambia", dialCode: "260", iso: "ZM" },
  { name: "Zimbabwe", dialCode: "263", iso: "ZW" },
];
