import React from "react";
import { IconButton, Tooltip } from "../../../../../uiKit";
import { IRegulationIcons } from "./RegulationIcons.interface";
import { useTranslation } from "react-i18next";


export const AddToSection : React.FC<IRegulationIcons> = (props) => {

  const { t } = useTranslation();

  return (
    <Tooltip title={t("ui:tooltip.add_to_topic")} >
      <IconButton loading={props.loading} className="regulation-button-icons__publish" disabled={props.disabled} onClick={props.onClick}>
        <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M21.2202 16.75V6.75C21.2202 6.21957 21.0095 5.71086 20.6344 5.33579C20.2594 4.96071 19.7506 4.75 19.2202 4.75H12.4562C12.0847 4.74999 11.7206 4.64651 11.4046 4.45116C11.0886 4.25581 10.8333 3.97631 10.6672 3.644L9.77322 1.856C9.60708 1.52353 9.35159 1.24394 9.03542 1.04858C8.71924 0.85322 8.35488 0.749827 7.98321 0.75H3.22021C2.68978 0.75 2.18107 0.960714 1.806 1.33579C1.43093 1.71086 1.22021 2.21957 1.22021 2.75V16.75C1.22021 17.2804 1.43093 17.7891 1.806 18.1642C2.18107 18.5393 2.68978 18.75 3.22021 18.75H19.2202C19.7506 18.75 20.2594 18.5393 20.6344 18.1642C21.0095 17.7891 21.2202 17.2804 21.2202 16.75Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M11.2202 11.75H8.22021M11.2202 8.75V11.75V8.75ZM11.2202 11.75V14.75V11.75ZM11.2202 11.75H14.2202H11.2202Z" stroke-width="1.5" stroke-linecap="round"/>
        </svg>
      </IconButton>
    </Tooltip>
  );
};

