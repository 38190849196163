import React, { useEffect, useState } from "react";
import RecurringPeriodSelectView from "./RecurringPeriodSelectView";
import { RecurringIssueRuleDto } from "../../../api";
import { useTranslation } from "react-i18next";

interface IRecurringPeriodSelect {
  data?: (RecurringIssueRuleDto & { idx?: number })[];

  onChange(data: (RecurringIssueRuleDto & { idx?: number })[]): void;
}

function RecurringPeriodSelect(props: IRecurringPeriodSelect) {
  const { t } = useTranslation();
  const [data, setData] = useState<(RecurringIssueRuleDto & { idx?: number })[]>(props.data ?? []);

  const list = {
    days: [
      { id: 0, name: t("common:date.day.sunday") },
      { id: 1, name: t("common:date.day.monday") },
      { id: 2, name: t("common:date.day.tuesday") },
      { id: 3, name: t("common:date.day.wednesday") },
      { id: 4, name: t("common:date.day.thursday") },
      { id: 5, name: t("common:date.day.friday") },
      { id: 6, name: t("common:date.day.saturday") },
    ],
    months: [
      { id: 0, name: t("common:misc.time_period_picker.every_month") },
      { id: 1, name: t("common:date.month.january") },
      { id: 2, name: t("common:date.month.february") },
      { id: 3, name: t("common:date.month.march") },
      { id: 4, name: t("common:date.month.april") },
      { id: 5, name: t("common:date.month.may") },
      { id: 6, name: t("common:date.month.june") },
      { id: 7, name: t("common:date.month.july") },
      { id: 8, name: t("common:date.month.august") },
      { id: 9, name: t("common:date.month.september") },
      { id: 10, name: t("common:date.month.october") },
      { id: 11, name: t("common:date.month.november") },
      { id: 12, name: t("common:date.month.december") },
    ],
  };

  useEffect(() => {
    // Check if all items has idX property to track them
    data.forEach((data) => {
      if (data.idx == null) {
        data.idx = getArrayMaxIdX() + 1;
      }
    });
    // Add empty object at the end of the array
    if (data.filter((d) => d.type == "")?.length == 0) {
      setData([
        ...data,
        { id: 0, idx: getArrayMaxIdX() + 1, type: "", value: null, value2: null, timeCreateAt: undefined },
      ]);
    }
    // Emit onChange
    emitOnChange();
  }, [data]);

  const getArrayMaxIdX = () => {
    // Get max idX of the array
    return Math.max(...data.map((o) => o?.idx ?? 0), 0);
  };

  const generateArray = (
    from: number,
    to: number,
    numberLength?: number | null,
    multipleOf?: number
  ): (number | string)[] => {
    let numbersArray: (string | number)[] = Array.from({ length: to - from + 1 }, (_, i) => i + from - 1);
    if (multipleOf != null) {
      numbersArray = numbersArray.filter((val) => Number(val) % multipleOf == 0);
    }
    if (numberLength != null) {
      numbersArray = numbersArray.map((val) => ("0000" + (Number(val) + from)).slice(-numberLength));
    }
    return numbersArray;
  };

  function daysInMonth(month: number, year: number = 2009): number {
    return new Date(year, month, 0).getDate();
  }

  const emitOnChange = () => {
    props.onChange(
      data
        .filter((d) => d.type != "")
        .map((x) => {
          return { ...x, id: 0 };
        }) ?? []
    );
  };

  const onTypeChange = (idx: number, type: string) => {
    const item = data.find((item) => item.idx == idx) as RecurringIssueRuleDto & { idx: number };
    const itemIndex = data.indexOf(item);
    if (itemIndex != -1) {
      let newData = [...data];
      newData[itemIndex] = { ...newData[itemIndex], type: type };

      switch (type) {
        case "every_day":
          newData[itemIndex] = { ...newData[itemIndex], value: null, value2: null };
          break;
        case "every_week_at_day":
          newData[itemIndex] = { ...newData[itemIndex], value: 0, value2: null };
          break;
        case "every_month_at_day":
          newData[itemIndex] = { ...newData[itemIndex], value: 0, value2: 1 };
          break;
        case "every_n_days":
          newData[itemIndex] = { ...newData[itemIndex], value: 1, value2: null };
          break;
      }

      if (newData[itemIndex].timeCreateAt == null) {
        newData[itemIndex] = { ...newData[itemIndex], timeCreateAt: "12:00" };
      }

      if (newData.filter((nD) => nD.type == "").length == 0) {
        newData = [
          ...newData,
          { id: 0, idx: getArrayMaxIdX() + 1, type: "", value: null, value2: null, timeCreateAt: undefined },
        ];
      }
      setData(newData);
    }
  };

  const onValue1Change = (idx: number, value1: number | null) => {
    const item = data.find((item) => item.idx == idx) as RecurringIssueRuleDto & { idx: number };
    const itemIndex = data.indexOf(item);
    if (itemIndex != -1) {
      let newData = [...data];
      newData[itemIndex] = { ...newData[itemIndex], value: value1 };
      if (newData[itemIndex].timeCreateAt == null) {
        newData[itemIndex] = { ...newData[itemIndex], timeCreateAt: "12:00" };
      }
      setData(newData);
    }
  };

  const onValue2Change = (idx: number, value2: number) => {
    const item = data.find((item) => item.idx == idx) as RecurringIssueRuleDto & { idx: number };
    const itemIndex = data.indexOf(item);
    if (itemIndex != -1) {
      let newData = [...data];
      newData[itemIndex] = { ...newData[itemIndex], value2: value2 };
      if (newData[itemIndex].timeCreateAt == null) {
        newData[itemIndex] = { ...newData[itemIndex], timeCreateAt: "12:00" };
      }
      setData(newData);
    }
  };

  const onTimeChange = (idx: number, value: string) => {
    const item = data.find((item) => item.idx == idx) as RecurringIssueRuleDto & { idx: number };
    const itemIndex = data.indexOf(item);
    if (itemIndex != -1) {
      let newData = [...data];
      newData[itemIndex] = { ...newData[itemIndex], timeCreateAt: value };
      setData(newData);
    }
  };

  const onDeleteClick = (idx: number) => {
    let newData = [...data];
    newData = newData.filter((d) => d.idx != idx);
    setData(newData);
  };

  const getPeriodicitySubheader = (type: string) => {
    return "";
  };

  return (
    <RecurringPeriodSelectView
      data={data}
      list={list}
      generateArray={generateArray}
      daysInMonth={daysInMonth}
      onTypeChange={onTypeChange}
      onValue1Change={onValue1Change}
      onValue2Change={onValue2Change}
      onTimeChange={onTimeChange}
      onDeleteClick={onDeleteClick}
      getPeriodicitySubheader={getPeriodicitySubheader}
    />
  );
}

export default RecurringPeriodSelect;
