import React from "react";
import { CollapsePanelView } from "./CollapsePanelView";
import { CollapsePanelProps } from "antd";

interface ICollapsePanel extends CollapsePanelProps {
  //
}

export function CollapsePanel(props: ICollapsePanel) {
  return <CollapsePanelView {...props} />;
}
