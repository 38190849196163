import React from "react";
import { ICommentCard } from "./CommentCard.interface";
import { useDateHelpers } from "../../../hooks";
import { CommentCardView } from "./CommentCardView";

export const CommentCard = <T,>(props: ICommentCard<T>) => {
  const { formatDateString } = useDateHelpers();

  const dateSent = formatDateString(new Date(props.dateCreated!).toString(), {
    hour: undefined,
    minute: undefined,
  });

  if (!props.creator && !props.commentContent) return null;

  return (
    <CommentCardView
      dateSented={dateSent}
      reactions={props.reactions}
      strategy={props.reactionStrategy}
      commentAttachments={props.commentAttachments}
      commentContent={props.commentContent}
      index={props.index}
      creator={props.creator}
      historyId={props.historyId}
    />
  );
};
