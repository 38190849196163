import React from "react";
import "./ListCards.scss";
import { CardRegulation } from "../CardRegulation/CardRegulation";
import ScrollTrigger from "../../../../../service/scrollTrigger/ScrollTrigger";
import { IListRegulationCards, paginationTypeKeys } from "./ListCards.interface";
import { useTranslation } from "react-i18next";
import { Button, Card, Divider, Empty } from "../../../../../uiKit";

export const ListRegulations = ({
  regulations,
  isDone,
  loadNext,
  regulationContentType,
  paginationType,
  isLoading,
}: IListRegulationCards) => {
  const { t } = useTranslation();

  return (
    <Card title={t("ui:title.policies")} size="default" bodyStyle={{ padding: 0 }} style={{ overflow: "hidden" }}>
      {isDone && regulations.length === 0 ? (
        <Empty className="my-4" />
      ) : (
        <div>
          {regulations.map((item, index) => (
            <React.Fragment key={item.id}>
              {index > 0 && <Divider />}
              <CardRegulation regulationContentType={regulationContentType} regulation={item} />
            </React.Fragment>
          ))}
          {regulations.length !== 0 && (
            <>
              {paginationType === paginationTypeKeys.byScroll && (
                <ScrollTrigger
                  fullWidth
                  disabled={isDone}
                  onIntersection={loadNext ?? function () {}}
                  marginTop={regulations.length > 0}
                  hidden={isDone}
                />
              )}
              {paginationType === paginationTypeKeys.byButton && !isDone && (
                <Button
                  variant="text"
                  style={{ marginTop: "16px", marginBottom: "8px" }}
                  size="large"
                  loading={isLoading}
                  onClick={loadNext}
                >
                  {t("parse:more")}
                </Button>
              )}
            </>
          )}
        </div>
      )}
    </Card>
  );
};
