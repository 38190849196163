import React from "react";
import { ICardRegulation } from "./CardRegulation.interface";
import { CardRegulationView } from "./CardRegulationView";
import { useNavigate } from "react-router-dom";

export const CardRegulation: React.FC<ICardRegulation> = (props) => {
  const navigate = useNavigate();

  const handleRedirectToRegulationView = () =>
    props.onClick
      ? props.onClick(props.regulation.id!)
      : navigate(`/policy/all/${props.regulation.id}/?contentType=${props.regulationContentType}`);

  return <CardRegulationView {...props} onClick={handleRedirectToRegulationView} />;
};
