import React from "react";
import "./Tag.scss";
import { Tag, TagProps } from "antd";
import clsx from "clsx";

interface ITagView extends TagProps {
  //
}

export function TagView({ className, ...rest }: ITagView) {
  return <Tag className={clsx("uiKit-tag", className ?? "")} {...rest} />;
}
