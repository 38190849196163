import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { UserWithRegulationStatsDto } from "../../../../../api";
import { useNotifier } from "../../../../../hooks";
import { api } from "../../../../../services";
import { RegulationStatsDialog } from "../../../dialogs/regulationStatsDialog/RegulationStatsDialog";
import { RegulationsStats } from "../components/RegulationsStats/RegulationsStats";
import { Card } from "../../../../uiKit";

export interface IRegulationUsersStats {
  userStats?: boolean;
}

export const RegulationUsersStats = ({ userStats }: IRegulationUsersStats) => {
  const { t } = useTranslation();
  const notifier = useNotifier();
  const navigate = useNavigate();
  const routeParams = useParams();

  const [stats, setStats] = useState<UserWithRegulationStatsDto[]>([]);
  const [currentStatsPage, setCurrentStatsPage] = useState<number>(1);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenStatsByUserDialog, setIsOpenStatsByUserDialog] = useState<boolean>(false);
  const [selectUserName, setSelectUserName] = useState<string>("");

  const handleOpenStatsByUserDialog = (id: number, userName: string) => {
    navigate(`/policy/stats/${id}`);
    setIsOpenStatsByUserDialog(true);
    setSelectUserName(userName);
  };

  const handleCloseStatsByUserDialog = () => {
    navigate("/policy/stats");
    setIsOpenStatsByUserDialog(false);
  };

  useEffect(() => {
    !!userStats && setIsOpenStatsByUserDialog(true);
  }, [userStats]);

  useEffect(() => {
    const id = routeParams["*"]?.split("/")[1];
    id && setSelectUserName(stats.find((item) => item?.id === Number(id))?.name ?? "");
  }, [stats]);

  const handleLoadStatsByPage = async (page: number) => {
    setCurrentStatsPage(page);
    setIsLoading(true);
    api.userWithRegulationStats
      .getAll({ pageSize: 10, page })
      .then((res) => {
        res?.items && setStats(res?.items);
        setTotalItems(res?.totalItems!);
      })
      .catch(() => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" }))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (!!userStats) setIsOpenStatsByUserDialog(true);
    handleLoadStatsByPage(1);
  }, []);

  return (
    <>
      {isOpenStatsByUserDialog && (
        <RegulationStatsDialog
          open={isOpenStatsByUserDialog}
          onClose={handleCloseStatsByUserDialog}
          userName={selectUserName}
        />
      )}
      {
        <Card title={t("ui:title.stats")} size="default" scrollStrategy="cardBody">
          <RegulationsStats
            statsItems={stats}
            onOpenStatsByUserDialog={handleOpenStatsByUserDialog}
            loading={isLoading}
            totalItems={totalItems}
            currentPage={currentStatsPage}
            onLoadPage={handleLoadStatsByPage}
          />
        </Card>
      }
    </>
  );
};
