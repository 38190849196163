import React, { useState } from "react";
import CompanySwitcherView from "./CompanySwitcherView";
import { useRootStore } from "../../../../hooks";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";

function CompanySwitcher() {
  const { authStore } = useRootStore();
  const navigate = useNavigate();
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const isMenuOpen = Boolean(menuAnchorEl);

  const handleMenuButtonClick = (event: any) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  async function handleMenuItemClick(companyId: number): Promise<void> {
    handleMenuClose();
    const r = await authStore.reauthorizeWithNewCompanyId(companyId);
    if (r) {
      // window.location.reload();
    }
  }

  const handleCompanyManageClick = () => {
    // setIsAddCompanyDialogOpen(true);
    navigate("/settings/profile");
    handleMenuClose();
  };

  return (
    <CompanySwitcherView
      isMenuOpen={isMenuOpen}
      menuAnchorEl={menuAnchorEl}
      currentCompanyId={authStore.getCurrentCompanyId}
      companiesList={authStore.getInitialInfo?.identity?.companies ?? []}
      handleMenuButtonClick={handleMenuButtonClick}
      handleMenuClose={handleMenuClose}
      handleMenuItemClick={handleMenuItemClick}
      handleCompanyManageClick={handleCompanyManageClick}
    />
  );
}

export default observer(CompanySwitcher);
