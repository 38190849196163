import React from "react";
import { useTranslation } from "react-i18next";
import { ReportSimpleModel } from "../../../../api";
import SubheaderText from "../../../elements/subheaderText/SubheaderText";
import { Button, Card, DatePickerRange, Dialog, DialogActions, Segmented, Table, Text } from "../../../uiKit";
import { ICompanyColumn } from "../../pages/companies-management/companyMainCard/CompanyMainCard";
import { FiCreditCard } from "@react-icons/all-files/fi/FiCreditCard";
import { FiTrendingUp } from "@react-icons/all-files/fi/FiTrendingUp";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

interface ICompanyEmployeeStatsDialogView {
  open: boolean;
  tableFilters: any;
  tableItems: ReportSimpleModel[];
  displayMode: "chart" | "table";

  setDisplayMode: (mode: "chart" | "table") => void;
  setTableFilters: (val: any) => void;
  onClose(): void;
}

export function CompanyEmployeeStatsDialogView(props: ICompanyEmployeeStatsDialogView) {
  const { t } = useTranslation();

  const tableColumns: ICompanyColumn[] = [
    {
      title: t("parse:metrics_table.date"),
      dataIndex: "date",
      key: "date",
    },
    {
      title: t("parse:employees_number"),
      dataIndex: "value",
      key: "value",
    },
  ];

  const CustomizedTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <Card style={{ boxShadow: "var(--shadow-down-sm)" }}>
          <Text>{label}</Text>
          <div />
          <Text>{`${t("parse:value")}: ${payload[0].value}`}</Text>
        </Card>
      );
    }

    return null;
  };

  return (
    <Dialog width="80%" title={t("common:menu.company_card.statistics")} open={props.open} onClose={props.onClose}>
      <div className="d-flex justify-space-between align-end">
        <div>
          <SubheaderText text={t("parse:date_range")} />
          <DatePickerRange
            allowClear={false}
            value={[props.tableFilters.dateFrom, props.tableFilters.dateTo]}
            onChange={(val) => props.setTableFilters({ ...props.tableFilters, dateFrom: val?.[0], dateTo: val?.[1] })}
          />
        </div>
        <div>
          <SubheaderText text={t("parse:display_type")} />
          <Segmented
            value={props.displayMode}
            onChange={(val: any) => props.setDisplayMode(val)}
            options={[
              {
                label: <FiCreditCard />,
                value: "table",
              },
              {
                label: <FiTrendingUp />,
                value: "chart",
              },
            ]}
          />
        </div>
      </div>
      {props.displayMode == "table" ? (
        // @ts-ignore
        <Table size="small" className="mt-5" columns={tableColumns} dataSource={props.tableItems} />
      ) : (
        <div style={{ width: "100%", height: "250px" }}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              margin={{
                top: 16,
                right: 10,
                left: 0,
                bottom: 0,
              }}
              data={props.tableItems}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              {/*<Area dataKey="value" stroke="#8884d8" fill="#8884d8" />*/}
              <Tooltip content={CustomizedTooltip} />
              <Line
                dot={false}
                // dot={{ stroke: "#5672ff", strokeWidth: 1 }}
                type="linear"
                dataKey="value"
                stroke="#5672ff"
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      )}
      <DialogActions>
        <Button onClick={props.onClose} variant="text">
          {t("ui:button.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
