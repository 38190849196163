import { createPluginFactory, onKeyDownToggleElement } from "@udecode/plate";
import { OrgchartCardElement } from "./OrgchartCardElement";

export const ELEMENT_ORGCHART_CARD = "#";

export const createOrgchartCardPlugin = createPluginFactory({
  key: ELEMENT_ORGCHART_CARD,
  isElement: true,
  isVoid: true,
  component: OrgchartCardElement,
  handlers: { onKeyDown: onKeyDownToggleElement },
  then: (editor, { type }) => ({
    deserializeHtml: {
      rules: [
        {
          validNodeName: "#",
        },
      ],
      getNode: (el) => ({
        type,
        id: el.getAttribute("id"),
      }),
    },
  }),
});
