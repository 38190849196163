import React from "react";
import "./Empty.scss";
import { Empty as AntEmpty, EmptyProps } from "antd";
import clsx from "clsx";

interface IEmpty extends EmptyProps {
  isVerticallyCentered?: boolean;
}

export function Empty({ className, isVerticallyCentered, ...rest }: IEmpty) {
  return (
    <AntEmpty
      className={clsx("uiKit-empty", className ?? "", {
        "__is-vertically-centered": isVerticallyCentered ?? true,
        "__custom-image": rest.image != null,
        "__custom-description": rest.description != null,
      })}
      {...rest}
    />
  );
}
