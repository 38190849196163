import React from "react";
import "./SelectOption.scss";
import { Select } from "antd";
import clsx from "clsx";

interface ISelectOptionView {
  className?: string;
  disabled?: boolean;
  title?: string;
  value?: string | number;
  children: React.ReactNode;
}

const { Option } = Select;

export function SelectOptionView({ className, ...rest }: ISelectOptionView) {
  return <Option className={clsx("uiKit-selectOption", className ?? "")} {...rest} />;
}
