import { ELEMENT_PARAGRAPH, Value } from "@udecode/plate";
import { LexicalEditor } from "lexical";
import { $canShowPlaceholder } from "@lexical/text";

export const initialContent: Value = [
  {
    type: ELEMENT_PARAGRAPH,
    children: [
      {
        text: "",
      },
    ],
  },
];

export const emptyEditorJSON =
  '{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}';

export const contentToString = (content: Value): string => JSON.stringify(content);
export const stringToContent = (content: string): Value => JSON.parse(content);
export const contentIsEmpty = (content: Value): boolean => {
  return (
    //@ts-ignore
    content.length === 1 && content[0]?.children[0]?.text?.trim().length === 0 && content[0]?.children?.length === 1
  );
};
export const isContentEmpty = (editor: LexicalEditor) => {
  return editor.getEditorState().read(() => {
    return $canShowPlaceholder(editor.isComposing());
  });
};

const getNestedContentLength = (value: Value) => {
  const a = [];
  void (function foo(d) {
    for (let { children, text, url } of d) {
      children ? foo(children as Value) : a.push((text as string)?.length || (url as string)?.length);
    }
  })(value);
  return a;
};

export const getLengthContent = (value: Value) => {
  if (value[0]?.children?.length > 1) {
    return getNestedContentLength(value)
      .filter(Boolean)
      .reduce((acc, v) => acc + v, 0);
  }
  return (value[0]?.children[0] as any)?.text?.length ?? 0;
};

export const getContentLength = (value: string) => {
  return JSON.parse(value)
    .root.children.map((c: any) => (c.children.length ? c.children[0].text || c.children[0].url : undefined))
    .filter(Boolean)
    .map((el: string) => el.length)
    .reduce((acc: number, v: number) => acc + v, 0);
};

export const getImagesCount = (data: Value) => {
  const a = [];
  void (function foo(d) {
    for (let { type, children } of d) {
      a.push(type);
      if (children) {
        foo(children as Value);
      }
    }
  })(data);
  return a.filter((v) => v == "img").length;
};
