import React from "react";
import ScrollTrigger from "../../../../../service/scrollTrigger/ScrollTrigger";
import { IRegulationHistoryListCards } from "./RegulationHistory.interface";
import { RegulationHistoryCard } from "./RegulationHistoryCard";

const RegulationHistoryListCardsMemo: React.FC<IRegulationHistoryListCards> = (props) => {
  return (
    <div className="regulation-history__list spacing-4 d-stack-column justify-start align-center">
      {props.historyItems.map((item, index) => (
        <React.Fragment key={item.id}>
          <RegulationHistoryCard
            key={item.id}
            historyId={item.id!}
            index={index}
            dateCreated={item.dateCreated!}
            reactions={item.reactions ?? []}
            creatorId={item.createdByUserId!}
            creator={item.createdByUser!}
            commentContent={item.comment?.content ?? ""}
          />
        </React.Fragment>
      ))}
      {props.historyItems.length !== 0 && (
        <ScrollTrigger
          fullWidth
          disabled={props.isDone}
          onIntersection={props.loadNext}
          marginTop={props.historyItems.length > 0}
          hidden={props.isDone}
        />
      )}
    </div>
  );
};

export const RegulationHistoryListCards = React.memo(RegulationHistoryListCardsMemo);
