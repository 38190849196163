import React from "react";
import { Select } from "../../../../uiKit";
import { ELEMENT_H1, ELEMENT_H2, ELEMENT_H3, ELEMENT_PARAGRAPH } from "@udecode/plate";
import { useTranslation } from "react-i18next";

interface IHeadingPlugin {
  value: string;
  onChange: (value: string) => void;
}

export const HeadingPlugin = (props: IHeadingPlugin) => {
  const { t } = useTranslation();

  const items = [
    { id: 0, value: ELEMENT_PARAGRAPH, text: t("common:text_editor_toolbar.select.normal") },
    { id: 1, value: ELEMENT_H1, text: t("common:text_editor_toolbar.select.h1") },
    { id: 2, value: ELEMENT_H2, text: t("common:text_editor_toolbar.select.h2") },
    { id: 3, value: ELEMENT_H3, text: t("common:text_editor_toolbar.select.h3") },
  ];

  return (
    <Select
      className="mr-1 ml-1"
      style={{ minWidth: 160 }}
      value={props.value}
      items={items}
      size="middle"
      onChange={(value) => props.onChange(value)}
    />
  );
};
