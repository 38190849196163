import { getPluginType, PlateEditor, Value } from "@udecode/plate-core";
import { TAnchorElement } from "./AnchorElement";
import { ELEMENT_ANCHOR } from "./createAnchorPlugin";

export interface CreateAnchorNodeOptions {
  anchorId: string;
}

export const createAnchorNode = <V extends Value>(
  editor: PlateEditor<V>,
  { anchorId }: CreateAnchorNodeOptions
): TAnchorElement => {
  const type = getPluginType(editor, ELEMENT_ANCHOR);

  return {
    type,
    anchorId,
    children: [{ text: "" }],
  };
};
