import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IssueDto, IssueScheduleDto } from "../../../../../../api";
import { IdGenerator } from "../../../../../../helpers/idGenerator";
import { useNotifier } from "../../../../../../hooks";
import { api } from "../../../../../../services";
import { ICustomIssue, IPlanningCollapseContent } from "./PlanningCollapseContent.interface";
import "./PlanningCollapseContent.scss";
import { PlanningCollapseContentView } from "./PlanningCollapseContentView";

export const PlanningCollapseContent = (props: IPlanningCollapseContent) => {
  const notifier = useNotifier();
  const { t } = useTranslation();
  const [loadingTaskId, setLoadingTaskId] = useState<number | undefined>(undefined);
  const [isOpenCalendarDialog, setIsOpenCalendarDialog] = useState<boolean>(false);

  const groupIdGenerator = new IdGenerator(1);

  const handleIncrementGroupId = () => groupIdGenerator.incrementId();

  const issueGroupTitle = (type: number | undefined) => {
    if (type == 1) return t("ui:planning_table.weekly_tasks");
    if (type == 2) return t("ui:planning_table.recurring_tasks");
    if (type == 3) return t("ui:planning_table.unscheduled_tasks");
  };

  const handleDeleteIssueFromPlan = async (issueId: number) => {
    setLoadingTaskId(issueId);
    let r = undefined;
    if (props.plan?.status == 1 || props.plan?.status == 4) {
      r = await api.plan.edit(props.plan?.id!, {
        ...props.plan,
        issues: props.plan?.issues?.filter((i) => i.issueId != issueId),
      });
    }
    if (props.plan?.status == 3) {
      r = await api.plan.edit(props.plan?.id!, {
        ...props.plan,
        issues: props.plan?.issues?.map((i) => ({
          ...i,
          isDeleted: i.issueId == issueId ? true : i.isDeleted,
        })),
      });
    }
    if (r) {
      notifier.show({
        message: t("notifier:success.issue_deleted"),
        theme: "success",
      });
      props.onLoadRestart && props.onLoadRestart();
    } else {
      notifier.show({
        message: t("notifier:error.something_wrong"),
        theme: "error",
      });
    }
    setLoadingTaskId(undefined);
  };

  const issuesByCustomType = [
    ...(props.issues as IssueDto[]).map((x: IssueDto) => {
      return {
        issue: x,
        dateDeadline: x.dateDeadline,
        role: x.role,
        roleId: x.roleId,
        type: props.plan?.issues?.find((i) => i.issueId == x.id)?.type,
        isDeleted: props.plan?.issues?.find((i) => i.issueId == x.id)?.isDeleted,
        orgchartId: x.orgchartId,
      };
    }),
    ...(props.scheduleIssues as IssueScheduleDto[]).map((y: IssueScheduleDto) => {
      return {
        issue: y.recurringIssue,
        dateDeadline: y.dateDeadline,
        role: y.role,
        roleId: y.roleId,
        type: 2,
        isSchedule: true,
        dateRelease: y.dateRelease,
        orgchartId: y.role?.orgchartId,
      };
    }),
  ];

  const handleOpenCalendarDialogChange = (isOpen: boolean) => {
    setIsOpenCalendarDialog(isOpen);
  };

  return (
    <PlanningCollapseContentView
      issuesByCustomType={issuesByCustomType as ICustomIssue[]}
      onIncrementGroupId={handleIncrementGroupId}
      onIssueDelete={handleDeleteIssueFromPlan}
      loadingTaskId={loadingTaskId}
      onIssueEdit={props.onIssueEdit}
      onLoadRestart={props.onLoadRestart}
      isOpen={isOpenCalendarDialog}
      isOpenChange={handleOpenCalendarDialogChange}
      planStatus={props.plan?.status}
      issueGroupTitle={issueGroupTitle}
      onIssueToPlanCreate={props.onIssueToPlanCreate}
      planDateFrom={props.plan?.dateFrom}
      issuesRestart={props.issuesRestart}
      weekReportStart={props.weekReportStart}
      userId={props.userId}
    />
  );
};
