import React from "react";
import { FiAtSign } from "@react-icons/all-files/fi/FiAtSign";
import { FiPhone } from "@react-icons/all-files/fi/FiPhone";
import { useClaims, useRootStore } from "../../../hooks";
import { permissionKeys } from "../../../utils/permissions";
import { Avatar, Icon, Link, Popover, Text } from "../../uiKit";
import { useNavigate } from "react-router-dom";
import { UsertButton } from "./components/userButton/UsertButton";
import { UserDto, UserShortDto } from "../../../api";
import { ITooltip } from "../../uiKit/dataDisplay/tooltip/Tooltip";
import { removeAllOccurrencesExceptFirstOne, removeMultipleSpacesFromString } from "../../../helpers/stringFunctions";
import { theme } from "antd";
import { parsePhoneNumber } from "awesome-phonenumber";

export interface IUserTooltipView {
  children: React.ReactNode;
  user?: UserDto | UserShortDto | null;
  tooltipProps?: ITooltip;
}

export const UserTooltipView = ({ children, user, tooltipProps }: IUserTooltipView) => {
  const { issueInitDataStore } = useRootStore();
  const navigate = useNavigate();
  const claims = useClaims();
  const { useToken } = theme;
  const { token } = useToken();

  const getAvatarProps = () => ({
    color: user?.color,
    src: user?.avatar?.image?.url,
    size: 60,
    text: [user?.lastName, user?.firstName],
  });

  const getTooltipContent = () => {
    const userName = removeMultipleSpacesFromString(
      ((user?.name ?? "")?.trim().length == 0 ? user?.nameFallback : user?.name)?.trim() ?? ""
    ).split(" ");

    const userPhoneNumber = (phoneNumber: string | null | undefined): string | null => {
      if (phoneNumber == null || phoneNumber.trim().length == 0) return null;
      const normalizedPhoneNumber = parsePhoneNumber(
        removeAllOccurrencesExceptFirstOne("+" + ("+" + phoneNumber ?? ""), "\\+")
      ).number?.international;
      if (normalizedPhoneNumber == undefined) return phoneNumber;
      return normalizedPhoneNumber;
    };

    return (
      // TODO: Check
      <div style={{ maxWidth: "fit-content" }} onClick={(event) => event.stopPropagation()}>
        <div className="d-stack-column spacing-4">
          <div className="d-stack-row align-center spacing-4">
            <Avatar {...getAvatarProps()} />
            <div className="d-stack-column spacing">
              {userName.map((text, index) => (
                <Text key={index} className="user-tooltip_name" children={text} />
              ))}
            </div>
          </div>
          <div className="d-stack-column spacing-1">
            {(user?.email ?? "").trim().length > 0 && (
              <div className="user-tooltip_link d-stack-row spacing-2 align-center">
                <Icon component={() => <FiAtSign color={token.colorTextSecondary} size={16} />} />
                <Link style={{ color: token.colorTextSecondary }} href={`mailto:${user?.email}`}>
                  {user?.email}
                </Link>
              </div>
            )}
            {(user?.phoneNumber ?? "").trim().length > 0 && claims.has(permissionKeys.user.viewUserPhoneNumber) && (
              <div className="user-tooltip_link d-stack-row spacing-2 align-center">
                {/*<Icon fontSize="small" sx={{ alignSelf: "baseline", overflow: "visible" }}>*/}
                <Icon component={() => <FiPhone color={token.colorTextSecondary} size={16} />} />
                {/*</Icon>*/}
                <Link style={{ color: token.colorTextSecondary }} href={`tel:${userPhoneNumber(user?.phoneNumber)}`}>
                  {userPhoneNumber(user?.phoneNumber)}
                </Link>
              </div>
            )}
          </div>
          <UsertButton user={user} />
        </div>
      </div>
    );
  };

  return (
    <Popover
      // placement="bottomLeft"
      mouseEnterDelay={0.5}
      overlayClassName="user-tooltip"
      content={getTooltipContent()}
      {...tooltipProps}
    >
      {children}
    </Popover>
  );
};
