import React from "react";
import { ButtonProps } from "antd/lib/button";
import "./IconButton.scss";
// import { Button } from "antd";
import clsx from "clsx";
import { Button } from "../button/Button";

interface IIconButtonView extends ButtonProps {
  //
}

export function IconButtonView({ className, children, ...rest }: IIconButtonView) {
  return (
    <Button
      type={"default"}
      shape={"default"}
      // shape={"circle"}
      className={clsx("uiKit-iconButton", "flex-shrink-0", className ?? "")}
      icon={children}
      {...rest}
    />
  );
}
