import React from "react";
import { useTranslation } from "react-i18next";
import { IVerificationCodeInfoBlock } from "./VerificationCodeForm.interface";
import { Button } from "../../../uiKit";

export const InfoBlock = (props:IVerificationCodeInfoBlock) => {
  const { t } = useTranslation();
  return (
    <div className="pa-4 d-stack-column align-center">
      <h3>{t("parse:time_expired_try_again")}</h3>
      <Button onClick={props.onClick}>{t("ui:button.try_again")}</Button>
    </div>
  );
};

