import React from "react";
import "./AvatarGroup.scss";
import { GroupProps } from "antd/es/avatar";
import { Avatar } from "antd";
import clsx from "clsx";

// import { FiUser } from "@react-icons/all-files/fi/FiUser";

interface IAvatarGroup extends GroupProps {
  //
}

const { Group } = Avatar;

export function AvatarGroupView({ className, ...rest }: IAvatarGroup) {
  return (
    <Group className={clsx("uiKit-avatarGroup", className ?? "")} {...rest} />
    // <Avatar
    //   className={clsx("uiKit-avatar", className ?? "", { __outlined: isOutlined })}
    //   icon={(text ?? "").trim().length > 0 ? undefined : getAvatarIconByType(type)}
    //   style={{ ...style, background: color ?? style?.background }}
    //   children={
    //     (text ?? "").trim().length > 0
    //       ? useOnlyOneSymbol || useOnlyOneSymbol == null
    //         ? text?.charAt(0)
    //         : text
    //       : children
    //   }
    //   {...rest}
    // />
  );
}
