import React from "react";
import { OrgchartDto } from "../../../api";
import { Avatar, AvatarGroup, Button, Divider, Dropdown, Select, Text } from "../../uiKit";
import { truncateString } from "../../../helpers/stringFunctions";
import { FiChevronDown } from "@react-icons/all-files/fi/FiChevronDown";
import { FiPlus } from "@react-icons/all-files/fi/FiPlus";
import { OrgchartCreateEditDialog } from "../../modules/dialogs/orgchartCreateEditDialog/OrgchartCreateEditDialog";
import { ISelect } from "../../uiKit/select/Select";
import { useTranslation } from "react-i18next";
import { useClaims } from "../../../hooks";
import { permissionKeys } from "../../../utils/permissions";
import "./OrgchartSwitcher.scss";

interface IOrgchartSwitcherView {
  drawType?: "avatarsWithMenu" | "textWithMenu" | "select";
  state: OrgchartDto[];
  createEditDialogValue?: OrgchartDto;
  currentOrgchartId: number | null | undefined;
  isCreateDialogOpen: boolean;
  value?: number[] | number;
  useAsFilter?: boolean;
  selectProps?: ISelect;

  onSelectAllClick?: () => void;
  setIsCreateDialogOpen: (value: boolean) => void;
  onCreateSuccess: (orgchartId: number) => void;
  onChange: (orgchartId: number | number[]) => void;
  onCreateEditDialogClose?: () => void;
}

const SwitcherSelect = (props: IOrgchartSwitcherView) => {
  return props.state.length == 0 ? (
    <Text style={{ fontWeight: "bold" }} children="Tonnus" />
  ) : (
    <Select
      {...props.selectProps}
      value={props.value}
      autoFocus={false}
      defaultActiveFirstOption={false}
      onChange={(value) => props.onChange(value)}
      dropdownRender={(menu) => (
        <>
          {props.useAsFilter && (
            <>
              <Button
                onClick={() => props.onSelectAllClick && props.onSelectAllClick()}
                className="full-width"
                variant="text"
              >
                Все
              </Button>
              <Divider className="mb-1" />
            </>
          )}
          {menu}
        </>
      )}
      items={[
        ...props.state.map((o: OrgchartDto) => ({
          key: (o.id as number).toString(),
          value: o.id as number,
          text: truncateString(o.name as string, 35, "end"),
          icon: <Avatar text={o.name} size="small" color={o.colorHex} src={o.image?.url} />,
          // icon: <Icon component={() => <Avatar text={o.name} size="small" color={o.colorHex} src={o.image?.url} />} />,
        })),
      ]}
    />
  );
};

const SwitcherTextWithMenu = (props: IOrgchartSwitcherView) => {
  const { t } = useTranslation();
  const claims = useClaims();

  return props.state.length == 0 ? (
    <Text style={{ fontWeight: "bold" }} children="Tonnus" />
  ) : (
    <Dropdown
      items={[
        ...props.state.map((o: OrgchartDto) => ({
          key: (o.id as number).toString(),
          text: truncateString(o.name as string, 35, "end"),
          disabled: props.currentOrgchartId == o.id,
          icon: (
            <Avatar
              isOutlined={Array.isArray(props.value) && props.value?.some((v) => v == o.id)}
              text={o.name}
              size="small"
              src={o.image?.url}
              color={o.colorHex}
            />
          ),
          onClick: () => props.onChange(o.id as number),
        })),
        { key: "div1", isDivider: true },
        {
          key: "create_btn",
          text: t("parse:create_orgchart"),
          onClick: () => props.setIsCreateDialogOpen(true),
          icon: <FiPlus />,
        },
      ].filter((m) => (claims.has(permissionKeys.orgchart.add) ? m : m.key != "create_btn" && m.key != "div1"))}
      trigger={["hover"]}
      overlayClassName="orgchart_switcher-overlay"
      // overlayStyle={{ maxHeight: "50vh", overflow: "auto" }}
      // overlayStyle={{ maxWidth: "400px" }}
    >
      <div className="d-stack spacing-2 align-center" style={{ cursor: "pointer" }}>
        <Avatar
          text={props.state.find((o: OrgchartDto) => o.id == props.currentOrgchartId)?.name}
          src={props.state.find((o: OrgchartDto) => o.id == props.currentOrgchartId)?.image?.url}
          color={props.state.find((o: OrgchartDto) => o.id == props.currentOrgchartId)?.colorHex}
          size={24}
        />
        <Text
          style={{ fontWeight: "bold" }}
          children={truncateString(
            props.state.find((o: OrgchartDto) => o.id == props.currentOrgchartId)?.name ?? "",
            25
          )}
        />
        <FiChevronDown />
      </div>
    </Dropdown>
  );
};

const SwitcherAvatarsWithMenu = (props: IOrgchartSwitcherView) => {
  const { t } = useTranslation();

  return props.state.length == 0 ? (
    <Text style={{ fontWeight: "bold" }} children="Tonnus" />
  ) : (
    <Dropdown
      items={[
        ...(props.useAsFilter
          ? [
              {
                key: "select_all",
                text: t("parse:all"),
                onClick: () => props.onSelectAllClick && props.onSelectAllClick(),
              },
              { key: "div2", isDivider: true },
            ]
          : []),
        ...props.state.map((o: OrgchartDto) => ({
          key: (o.id as number).toString(),
          text: truncateString(o.name as string, 35, "end"),
          disabled: props.useAsFilter ? false : props.currentOrgchartId == o.id,
          icon: (
            <Avatar
              isOutlined={Array.isArray(props.value) && props.value?.some((v) => v == o.id)}
              text={o.name}
              src={o.image?.url}
              size="small"
              color={o.colorHex}
            />
          ),
          onClick: () => props.onChange(o.id as number),
        })),
      ]}
      trigger={["hover"]}
      overlayClassName="orgchart_switcher-overlay"
      // overlayStyle={{ maxWidth: "400px" }}
    >
      <div style={{ cursor: "pointer" }}>
        <AvatarGroup maxCount={5}>
          {props.state.map((o: OrgchartDto) => (
            <Avatar
              // size="small"
              // isOutlined={props.currentOrgchartId == o.id}
              src={o.image?.url}
              key={o.id}
              text={o.name}
              color={o.colorHex}
            />
          ))}
        </AvatarGroup>
      </div>
    </Dropdown>
  );
};

export function OrgchartSwitcherView(props: IOrgchartSwitcherView) {
  return (
    <>
      <OrgchartCreateEditDialog
        open={props.isCreateDialogOpen}
        orgchartData={props.createEditDialogValue}
        onSuccess={(orgchartId: number) => {
          props.onCreateSuccess(orgchartId);
        }}
        onClose={() => {
          props.setIsCreateDialogOpen(false);
          props.onCreateEditDialogClose && props.onCreateEditDialogClose();
        }}
      />
      {props.drawType == "select" && SwitcherSelect(props)}
      {props.drawType == "avatarsWithMenu" && SwitcherAvatarsWithMenu(props)}
      {props.drawType == "textWithMenu" && SwitcherTextWithMenu(props)}
    </>
  );
}
