import React from "react";
import { Layout } from "antd";
import "./LayoutFooter.scss";
import clsx from "clsx";
import { BasicProps } from "antd/es/layout/layout";

interface ILayoutFooterView extends BasicProps {
  className?: string;
  children?: React.ReactNode;
}

const { Footer } = Layout;

export function LayoutFooterView({ className, ...rest }: ILayoutFooterView) {
  return <Footer className={clsx("uiKit-layoutFooter", className ?? "")} {...rest} />;
}
