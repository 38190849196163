import React from "react";
import { IRegulationProgressBarView } from "./RegulationProgressBar.interface";
import { theme } from "antd";

export const RegulationProgressBarView = ({ progress }: IRegulationProgressBarView) => {
  const { useToken } = theme;
  const { token } = useToken();

  return (
    <div
      className="regulation__progress-bar"
      style={{
        position: progress.progressPercent < 10 ? "relative" : "static",
        backgroundColor: token.colorFillAlter,
      }}
    >
      <div
        style={{
          width: `${progress.progressPercent}%`,
          position: progress.progressPercent > 10 ? "relative" : "static",
        }}
        className="regulation__progress-bar__determinate"
      >
        <span
          style={{ color: progress.progressPercent < 10 ? token.colorText : token.colorBgBase }}
          className="regulation__progress-bar__percent"
        >
          {progress.progressPercent}%
        </span>
      </div>
    </div>
  );
};
