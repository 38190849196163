import React, { forwardRef, useImperativeHandle, useState } from "react";
import { ListGeneralEmojis } from "./components/ListGeneralEmojis";
import { IReactionDropdown } from "../../interfaces/Reactions.interface";
import { EmojiPicker } from "../EmojiPicker";
import { Popover } from "../../../../uiKit";

type renderContentType = "emojiPicker" | "generalPicker" | null;

export const ReactionDropdown = forwardRef((props: IReactionDropdown, ref) => {
  const [renderContentKey, setRenderContentKey] = useState<renderContentType>("generalPicker");
  const [isOpenPicker, setIsOpenPicker] = useState(false);

  const handleOpenPicker = () => setRenderContentKey("emojiPicker");

  const handleClosePicker = () => setRenderContentKey(null);

  const handleOpenGeneralPicker = () => setRenderContentKey("generalPicker");

  const handleClose = () => {
    props.onClose();
    handleClosePicker();
  };

  const handleReact = (value: string) => {
    setIsOpenPicker(false);
    props.onReact(value);
  };

  useImperativeHandle(ref, () => ({
    onClosePicker: handleClosePicker,
    onOpenGeneralPicker: handleOpenGeneralPicker,
  }));

  return (
    <Popover
      overlayClassName="reaction-overlay"
      content={<EmojiPicker onReact={handleReact} onClose={props.onClose} />}
      arrowContent={undefined}
      arrowPointAtCenter={false}
      trigger={["hover", "click"]}
      open={isOpenPicker}
      onOpenChange={setIsOpenPicker}
    >
      <ListGeneralEmojis
        onClosePicker={handleClosePicker}
        onOpenPicker={() => setIsOpenPicker(true)}
        isDisabled={props.isDisabled}
        onClose={handleClose}
        onReact={props.onReact}
      />
    </Popover>
  );

  /*return useMemo(() => {
    switch (renderContentKey) {
      case "emojiPicker":
        return <EmojiPicker onReact={props.onReact} onClose={handleClose} />;
      case "generalPicker":
        return (
          <ListGeneralEmojis
            onClosePicker={handleClosePicker}
            onOpenPicker={handleOpenPicker}
            isDisabled={props.isDisabled}
            onClose={handleClose}
            onReact={props.onReact}
          />
        );
      default:
        return null;
    }
  }, [renderContentKey, props.isDisabled]);*/
});
