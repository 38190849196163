import React from "react";
import { getUserShortFullName } from "../../../helpers/stringFunctions";
import { UserTooltip } from "../userTooltip/UserTooltip";
import { IUserCard } from "./UserCard.interface";
import { Avatar } from "../../uiKit";
import { css } from "@emotion/css";

export const UserCardView = (props: IUserCard) => {
  const userName =
    (props.userInfo?.name ?? "").trim().length > 0
      ? getUserShortFullName(
          props.userInfo?.lastName ?? "",
          props.userInfo?.firstName ?? "",
          props.userInfo?.middleName ?? undefined
        )
      : props.userInfo?.nameFallback;

  const isFullWidth = props.fullWidth ? css({ maxWidth: "100%" }) : "";

  return (
    <UserTooltip user={props.userInfo}>
      <div className={`user-card ${!!props.field ? "user-card_field" : ""} ${isFullWidth}`}>
        <div className="user-card__avatar">
          <Avatar
            size={props.avatarSize}
            src={props.userInfo?.avatar?.image?.url}
            text={[props.userInfo?.lastName, props.userInfo?.firstName]}
            color={props.userInfo?.color}
          />
        </div>
        <div className="user-card__info">
          <h2
            className="user-card__info-name"
            style={{
              padding: 0,
              margin: 0,
              fontSize: props.nameSize ?? 15,
              fontWeight: props.boldName ? 700 : 500,
            }}
          >
            {userName}
          </h2>
          {props.additionalInfo && typeof props.additionalInfo === "string" ? (
            <p className="user-card__info-additional-info">{props.additionalInfo}</p>
          ) : (
            props.additionalInfo
          )}
        </div>
      </div>
    </UserTooltip>
  );
};
