import React from "react";
import { IssueDto } from "../../../../api";
import { useTranslation } from "react-i18next";
import "./RecurringIssueHistoryDialog.scss";
import ScrollTrigger from "../../../service/scrollTrigger/ScrollTrigger";
import { PagingInfo } from "../../../../hooks/usePaging";
import { PagingModel } from "../../../../api/types";
import { Button, Dialog, DialogActions, Table } from "../../../uiKit";
import { IDataSource, ITableColumns } from "./RecurringIssueHistoryDialog";

interface IRecurringIssueHistoryDialogView {
  open: boolean;
  tableColumns: ITableColumns[];
  dataSource: IDataSource[];
  history: {
    items: IssueDto[];
    info: PagingInfo;
    lastResult: PagingModel<IssueDto> | null;
    loadNext: () => Promise<void>;
    reset: () => void;
    restart: () => Promise<void>;
  };

  onClose: () => void;
}

function RecurringIssueHistoryDialogView(props: IRecurringIssueHistoryDialogView) {
  const { t } = useTranslation();

  // const [activeLinks, setActiveLinks] = useState<number[]>([]);

  const handleOpenIssue = (issueId: number) => {
    // setActiveLinks((prev) => [...prev,issueId])
    const win = window.open(`/communication/${issueId}`, "_blank");
    win?.focus();
  };

  return (
    <Dialog
      className="recurring-issue-history__dialog"
      title={t("ui:title.recurring_issue_history")}
      open={props.open}
      // scrollStrategy="dialogBody"
      onClose={() => props.onClose()}
    >
      <Table pagination={false} dataSource={props.dataSource} columns={props.tableColumns} />
      <ScrollTrigger
        onIntersection={() => props.history.loadNext()}
        hidden={props.history.info.isDone || !props.open}
      />
      <DialogActions>
        <Button onClick={() => props.onClose()} variant="text">
          {t("ui:button.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RecurringIssueHistoryDialogView;
