export enum dashboardSectionAndRoutesKeys {
  myMetrics = "my-metrics",
  mySubordinates = "my-subordinates",
  fillOut = "fill-out",
}

export const dashboardSections = [
  dashboardSectionAndRoutesKeys.myMetrics,
  dashboardSectionAndRoutesKeys.mySubordinates,
  dashboardSectionAndRoutesKeys.fillOut,
];
