import React from "react";
import "./SelectOptionGroup.scss";
import { Select } from "antd";
import clsx from "clsx";

interface ISelectOptionGroupView {
  className?: string;
  key?: string;
  label?: string | React.ReactElement;
  children: React.ReactNode;
}

const { OptGroup } = Select;

export function SelectOptionGroupView({ className, ...rest }: ISelectOptionGroupView) {
  return <OptGroup className={clsx("uiKit-selectOptionGroup", className ?? "")} {...rest} />;
}
