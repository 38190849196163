import { css } from "@emotion/css";
import { ENodeEntry, Plate, Value } from "@udecode/plate";
import { BaseRange } from "slate";
import "tippy.js/dist/tippy.css";
import { Spin } from "../../uiKit";
import { Leaf } from "./components/Leaf";
import { linkDecorator } from "./components/Link/linkDecorator";
import { AsyncCombobox } from "./components/Mention/AsyncCombobox";
import { MarkBalloonToolbar } from "./components/Toolbar/MarkBalloonToolbar";
import { Toolbar } from "./components/Toolbar/Toolbar";
import { ToolbarType } from "./misc/consts";
import { ITextEditorView } from "./TextEditor.interface";
import "./TextEditorOld.scss";

export const TextEditorView = (props: ITextEditorView) => {
  const currentLeaf = (value: any) => {
    return <Leaf {...value} />;
  };

  return (
    <div
      className={css`
        border: ${props.readOnly ? "" : "1px solid var(--color-text-weakest)"};
        border-radius: ${props.readOnly ? "" : "var(--border-radius-md)"};
        overflow: hidden;
      `}
    >
      <Spin spinning={props.isLoading} className="mt-5">
        <Plate
          value={props.value}
          initialValue={props.initialValue}
          id={props.id}
          editableProps={{
            readOnly: props.readOnly,
            spellCheck: false,
            autoFocus: props.autoFocus,
            placeholder: props.readOnly ? undefined : props.placeholder,
            style: {
              fontFamily:
                "-apple-system, BlinkMacSystemFont, Inter, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
              height: props.height,
              padding: "var(--indent-sm) var(--indent-md)",
              background: "var(--color-background-weak)",
              overflowY: "auto",
              overflowX: "hidden",
            },
            onClick: () => props.onEditorClick(),
            decorate: linkDecorator as (entry: ENodeEntry<Value>) => BaseRange[],
            renderLeaf: (v) => currentLeaf(v),
          }}
          plugins={props.plugins}
          onChange={(value) => props.onChange && props.onChange(value)}
        >
          {!props.readOnly && (
            <>
              {props.onIsVisibleToolbarCheck() && (
                <div className={props.toolbarType == ToolbarType.Dynamic && props.isVisible ? ToolbarType.Dynamic : ""}>
                  <Toolbar
                    headingValue={props.headingValue}
                    onHeadingValueChange={props.onHeadingValueChange}
                    onLoadingChange={props.onLoadingChange}
                    anchorId={props.anchorId}
                  />
                </div>
              )}
              <MarkBalloonToolbar />
              <AsyncCombobox pluginKey="@" />
            </>
          )}
        </Plate>
      </Spin>
      {/*<pre>{JSON.stringify(props.value, null, 1)}</pre>*/}
    </div>
  );
};
