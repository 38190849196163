import { Value } from "@udecode/plate";
import { Form } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { IssueDto, StaticFileDto } from "../../../../../api";
import { Alert, Checkbox, TextArea } from "../../../../uiKit";
import { contentToString, getImagesCount, getLengthContent } from "../../../../../utils/textEditor";
import { getStringContentType } from "../../../../../helpers/stringFunctions";
import { StringContentType } from "../../../../../enums/stringContentType";
import { TextEditor } from "../../../textEditorOld/TextEditor";
import { ToolbarType } from "../../../textEditorOld/misc/consts";
import FileUploader from "../../../../service/fileUploader/FileUploader";

interface IIssueRequiredActionsDialogTabAddProof {
  issueData: IssueDto;
  setIsLoading: (value: boolean) => void;
  lastCommentIsProof: boolean;
  proofText: Value;
  proofFiles: StaticFileDto[];
  checkboxDisabled: boolean;
  editorRef: React.MutableRefObject<{ reset: () => void } | undefined>;
  showCheckboxToSaveLastComment: boolean;
  onSaveProofFromLastComment: () => void;
  onProofTextChange: (value: Value) => void;
  onProofFilesChange: (files: StaticFileDto[]) => void;
  onProofFilesDeleteById: (fileId: number) => void;
  textEditorId: string;
}

export function IssueRequiredActionsDialogTabAddProof(props: IIssueRequiredActionsDialogTabAddProof) {
  const { t } = useTranslation();

  return (
    <div className="d-stack-column spacing-3">
      {(props.issueData?.proofRequirement?.text ?? "").length > 0 && (
        <Alert message={props.issueData?.proofRequirement?.text} />
      )}
      {props.issueData?.proofRequirement == null && <Alert type="error" message={t("text:task_proof.no_need_proof")} />}
      <Form.Item
        className="my-0"
        extra={
          props.issueData?.proofRequirement?.rules?.some((r) => r.key == "require.text_min_length")
            ? t("validation:min_char_number", {
                number:
                  props.issueData?.proofRequirement?.rules?.find((r) => r.key == "require.text_min_length")?.value ?? 0,
              }) +
              ". " +
              t("common:misc.entered_characters_number", { number: getLengthContent(props.proofText) })
            : undefined
        }
      >
        {getStringContentType(contentToString(props.proofText)) == StringContentType.text ? (
          <TextArea
            autoSize
            autoComplete="off"
            placeholder={t("ui:subheader.text")}
            value={props.proofText as unknown as string}
            onInput={(event: any) => props.onProofTextChange(event.target.value)}
          />
        ) : (
          <TextEditor
            id={props.textEditorId}
            placeholder={t("ui:subheader.text")}
            value={props.proofText}
            ref={props.editorRef}
            onChange={props.onProofTextChange}
            toolbarType={ToolbarType.Hidden}
          />
        )}
      </Form.Item>
      <FileUploader
        relatedInputId="issue-proof-add-area-ctrlv-dialog"
        accept={
          props.issueData?.proofRequirement?.rules?.find((r) => r.key == "require.files_min_count") != null
            ? "*"
            : "image/*"
        }
        smallSize
        // readonly
        value={props.proofFiles}
        onUploadStatusChange={props.setIsLoading}
        additionallyCountImg={
          props.issueData?.proofRequirement?.rules?.find((r) => r.key == "require.files_min_count") != null
            ? 0
            : props.proofText && getImagesCount(props.proofText)
        }
        // value={props.issueData?.proof?.history?.comment?.attachments?.map((a) => a.file as StaticFileDto) ?? []}
        onUpload={(files: StaticFileDto[]) => props.onProofFilesChange(files)}
        onValueItemDelete={props.onProofFilesDeleteById}
        onAnyItemDelete={props.onProofFilesDeleteById}
        requiredNumber={
          (props.issueData?.proofRequirement?.rules?.find((r) => r.key == "require.files_min_count") != null
            ? props.issueData?.proofRequirement?.rules?.find((r) => r.key == "require.files_min_count")?.value
            : props.issueData?.proofRequirement?.rules?.find((r) => r.key == "require.images_min_count")?.value) ??
          undefined
        }
      />
      {props.showCheckboxToSaveLastComment && (
        <div className="">
          <Checkbox
            disabled={props.checkboxDisabled}
            checked={props.lastCommentIsProof}
            onChange={(event: any) => props.onSaveProofFromLastComment()}
          >
            {t("ui:label.last_comment_is_proof").toString()}
          </Checkbox>
        </div>
      )}
    </div>
  );
}
