import React, { useState } from "react";
import ExpandableContainerView from "./ExpandableContainerView";

interface IExpandableContainer {
  text: string;
  children?: React.ReactNode;
  isLoading?: boolean;
  iconSize?: string;
}

function ExpandableContainer(props: IExpandableContainer) {
  const [expanded, setExpanded] = useState<boolean>(false);

  const handleButtonClick = () => {
    setExpanded(!expanded);
  };

  return (
    <ExpandableContainerView
      expanded={expanded}
      text={props.text}
      onClick={handleButtonClick}
      children={props.children}
    />
  );
}

export default ExpandableContainer;
