export enum breadcrumbsKeys {
  planning = "planning",
  regulation = "regulation",
  project = "project",
}

export type breadcrumbsType = breadcrumbsKeys.regulation | breadcrumbsKeys.planning | breadcrumbsKeys.project;

export interface IBreadcrumbs {
  type: breadcrumbsType;
}

export interface IBreadcrumbsView {
  pathNames: string[];
  lastCrumb?: string;
  onGetStepName: (item: string) => string;
  onRedirect: (url: string) => void;
}
