import React, { useRef, useState } from "react";
import "./CreateRegulationForm.scss";
import { CreateRegulationFormView } from "./CreateRegulatiomFormView";
import { SettingUpAccessDialog } from "../../dialogs/settingUpAccessDialog/SettingUpAccessDialog";
import { UserSelectionRule } from "../../../../api/types/userSelectionRule";
import { useNavigate } from "react-router-dom";
import { api } from "../../../../services";
import { useNotifier } from "../../../../hooks";
import { useTranslation } from "react-i18next";
import { RegulationContentQuestionDto, RegulationDto } from "../../../../api";
import { contentIsEmpty, contentToString, initialContent } from "../../../../utils/textEditor";
import { CustomConfirmDialog } from "../../dialogs/customConfirmDialog/СustomConfirmDialog";
import { errorKeys } from "./consts";
import { keysRulesFor } from "../../dialogs/settingUpAccessDialog/SettingUpAccessDialog.interface";
import { resetIds, validateTest } from "../createTestsForm/helpers";
import { regulationContentTypeKeys } from "../../pages/regulation/misc/headerTools";
import { Value } from "@udecode/plate";

export const CreateRegulationForm = () => {
  const navigate = useNavigate();
  const notifier = useNotifier();
  const { t } = useTranslation();

  const testRef = useRef<{ test: RegulationContentQuestionDto[] }>({ test: [] });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [titleError, setTitleError] = useState<boolean>(false);

  const [isOpenAccessSettingsDialog, setIsOpenAccessSettingsDialog] = useState<boolean>(false);
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState<boolean>(false); // confirm creating regulation without test;
  const [isOpenCreatingTest, setIsOpenCreatingTest] = useState<boolean>(false);

  const [regulationTitle, setRegulationTitle] = useState<string>("");
  const [regulationContent, setRegulationContent] = useState<Value>(initialContent);
  const [userSelectionRules, setUserSelectionRules] = useState<UserSelectionRule[]>([]);

  const handleChangeRegulationTitle = (newValue: string) => {
    titleError && newValue.trim().length > 0 && setTitleError(false);
    setRegulationTitle(newValue.length < 2 ? newValue.trim() : newValue);
  };
  const handleChangeRegulationContent = (newValue: Value) => setRegulationContent(newValue);
  const handleOpenAccessSettingsDialog = () => setIsOpenAccessSettingsDialog(true);
  const handleCloseAccessSettingsDialog = () => setIsOpenAccessSettingsDialog(false);

  const handleRedirectToCreateSection = () => navigate("/policy/create-section");
  const handleCancel = () => navigate("/policy");

  const handleChangeIsLoading = (loading: boolean) => setIsLoading(loading);

  const handleOpenConfirmDialog = () => setIsOpenConfirmDialog(true);
  const handleCloseConfirmDialog = () => setIsOpenConfirmDialog(false);

  const handleOpenTest = () => setIsOpenCreatingTest(true);
  const handleCloseTest = () => setIsOpenCreatingTest(false);

  const handleSaveAccessSettings = async (rules: UserSelectionRule[]): Promise<boolean> => {
    setUserSelectionRules([...rules]);
    return true;
  };

  const handleValidate = (ignoreTest: boolean | undefined): void => {
    if (regulationTitle.trim().length === 0) {
      setTitleError(true);
      throw Error(errorKeys.emptyTitle);
    }
    if (contentIsEmpty(regulationContent)) throw Error(errorKeys.emptyContent);
    // if(isOpenCreatingTest && !ignoreTest) throw Error(errorKeys.noCompleteTest);
    if (testRef.current.test.length > 0) validateTest(testRef.current.test);
  };

  const validateErrorHandler = (errorMessage: string) => {
    setIsLoading(false);
    switch (errorMessage) {
      case errorKeys.noCompleteTest:
        handleOpenConfirmDialog();
        break;
      case errorKeys.emptyTitle:
        notifier.show({ message: t("validation:enter_regulation_name"), theme: "error" });
        break;
      case errorKeys.emptyContent:
        notifier.show({ message: t("validation:policy_content_is_empty"), theme: "error" });
        break;
      case errorKeys.noAnswers:
        notifier.show({ message: t("validation:no_answers"), theme: "error" });
        break;
      case errorKeys.noRightsAnswer:
        notifier.show({ message: t("validation:no_rights_answer"), theme: "error" });
        break;
      case errorKeys.noNameAnswer:
        notifier.show({ message: t("validation:no_name_answer"), theme: "error" });
        break;
      case errorKeys.noNameQuestion:
        notifier.show({ message: t("validation:no_name_question"), theme: "error" });
        break;
    }
  };

  const handleSave = (ignoreTest?: boolean) => {
    try {
      setIsLoading(true);
      handleValidate(ignoreTest);
      const regulationGenerated: RegulationDto = {
        name: regulationTitle,
        userSelectionRules: userSelectionRules,
        // sectionId : selectionSection?.id ?? undefined,
        draftContent: {
          content: contentToString(regulationContent),
          questions: resetIds(testRef.current.test),
        },
      };
      api.regulation
        .create(regulationGenerated)
        .then((res) => {
          notifier.show({ message: t("notifier:success.regulation_created"), theme: "success" });
          navigate(`/policy/all/${res?.id}/?${`contentType=${regulationContentTypeKeys.draft}`}`);
        })
        .catch(() => {
          notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
        })
        .finally(() => setIsLoading(false));
    } catch (error: any) {
      validateErrorHandler(error.message as string);
    }
  };

  return (
    <>
      <CustomConfirmDialog
        open={isOpenConfirmDialog}
        onClose={handleCloseConfirmDialog}
        title={t("parse:are_you_sure")}
        subTitle={t("parse:complete_test")}
        onConfirm={() => handleSave(true)}
      />
      <SettingUpAccessDialog
        onSave={handleSaveAccessSettings}
        isOpen={isOpenAccessSettingsDialog}
        keyRuleTo={keysRulesFor.regulation}
        onClose={handleCloseAccessSettingsDialog}
        isCreateMode
      />
      <CreateRegulationFormView
        testRef={testRef}
        isLoading={isLoading}
        titleError={titleError}
        isOpenCreatingTest={isOpenCreatingTest}
        regulationTitle={regulationTitle}
        regulationContent={regulationContent}
        test={testRef.current?.test ?? []}
        onOpenTest={handleOpenTest}
        onCloseTest={handleCloseTest}
        onCancel={handleCancel}
        onOpenAccessSettingsDialog={handleOpenAccessSettingsDialog}
        onChangeRegulationTitle={handleChangeRegulationTitle}
        onChangeRegulationContent={handleChangeRegulationContent}
        onRedirectToCreateSection={handleRedirectToCreateSection}
        onChangeIsLoading={handleChangeIsLoading}
        onSave={handleSave}
      />
    </>
  );
};
