import React, { ChangeEvent, useState } from "react";
import "../Toolbar/Toolbar.scss";
import { useTranslation } from "react-i18next";
import { FiLink2 } from "@react-icons/all-files/fi/FiLink2";
import { FiCheck } from "@react-icons/all-files/fi/FiCheck";
import { PlateEditor, ToolbarButton, upsertLinkAtSelection, usePlateEditorRef } from "@udecode/plate";
import { Editor, Element as SlateElement, Transforms } from "slate";
import { Icon, IconButton, Input, Popover } from "../../../../uiKit";

export const LinkToolbarButton = () => {
  const [value, setValue] = useState("");
  const [validate, setValidate] = useState(true);
  const [open, setOpen] = useState(false);
  const editor = usePlateEditorRef()!;
  const { t } = useTranslation();

  const urlValidation = (url: string) => {
    const regex = new RegExp(
      "^(http:\\/\\/www\\.|https:\\/\\/www\\.|http:\\/\\/|https:\\/\\/)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?$"
    );
    return regex.test(url);
  };

  const isLinkActive = (editor: PlateEditor) => {
    const [link] = Editor.nodes(editor as any, {
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && (n as any).type === "link",
    });
    return !!link;
  };

  const handleClick = () => {
    if (isLinkActive(editor)) {
      unwrapLink(editor);
    } else {
      setOpen(true);
    }
  };

  const unwrapLink = (editor: PlateEditor) => {
    Transforms.unwrapNodes(editor as any, {
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && (n as any).type === "link",
    });
  };

  // const handleClose = () => {
  //   if (!isLinkActive(editor)) {
  //     if (!value || !validate) {
  //       setValue("");
  //       setValidate(true);
  //       return;
  //     }
  //   }
  // };

  const handleChangeVisible = (newVisible: boolean) => {
    setOpen(newVisible);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    const isTrueValue = !value || urlValidation(value);
    setValue(value);
    setValidate(isTrueValue);
  };

  const insertLink = (editor: PlateEditor, url: string) => {
    if (editor.selection) {
      // wrapLink(editor, url);
      upsertLinkAtSelection(editor, { url: url });
    }
  };

  // const wrapLink = (editor: PlateEditor, url: string) => {
  //   if (isLinkActive(editor)) {
  //     unwrapLink(editor);
  //   }
  //
  //   const { selection } = editor;
  //   const isCollapsed = selection && Range.isCollapsed(selection);
  //   const link: LinkElement = {
  //     type: "link",
  //     url,
  //     children: isCollapsed ? [{ text: url }] : [],
  //   };
  //
  //   if (isCollapsed) {
  //     Transforms.insertNodes(editor as any, link);
  //   } else {
  //     Transforms.wrapNodes(editor as any, link, { split: true });
  //     Transforms.collapse(editor as any, { edge: "end" });
  //   }
  // };

  const handleApplyClick = () => {
    insertLink(editor, value);
    setValue("");
    setOpen(false);
  };

  return (
    <>
      <Popover
        placement="bottom"
        trigger="click"
        open={open}
        onOpenChange={handleChangeVisible}
        content={
          <div className="d-stack-row">
            <Input
              size="middle"
              status={!validate ? "error" : ""}
              value={value}
              onChange={handleChange}
              placeholder={t("ui:placeholder.text_editor_url")}
            />
            <IconButton
              className="ml-1"
              icon={<Icon component={() => <FiCheck size={25} />} />}
              onMouseDown={handleApplyClick}
              disabled={!validate || !value}
            />
          </div>
        }
      >
        <ToolbarButton
          icon={<FiLink2 className="toolbar__buttons" />}
          onMouseDown={handleClick}
          title={t("common:text_editor_toolbar.link")}
        />
      </Popover>
    </>
  );
};
