import React from "react";
import { PermissionDto, RoleDto } from "../../../../../../api";
import "./OrgchartDrawerTabPermissions.scss";
import { useClaims, useRootStore } from "../../../../../../hooks";
import { getPermissionsTooltips } from "../../../../../../utils/tooltip";
import { LocalesKeys } from "../../../../../../constants/localesKeys";
import { Card, Switch, Text, Tooltip } from "../../../../../uiKit";
import { toJS } from "mobx";
import { guideKeys } from "../../../../../../stores/hintStore";
import { OpeningGuideIcon } from "../../../../../elements/openingGuideIcon/OpeningGuideIcon";
import { permissionKeys } from "../../../../../../utils/permissions";
import { FiHelpCircle } from "react-icons/all";

export interface IOrgchartDrawerTabPermissionsView {
  functionData: RoleDto;
  allPermissions: PermissionDto[];

  handleSwitchChange(value: boolean, id: number, type: string): void;
}

interface IPermissionSwitchesGroup {
  functionData: RoleDto;
  permission: PermissionDto;
  disabled: boolean;
  parentProps: IOrgchartDrawerTabPermissionsView;
  locale: LocalesKeys;
  nestingLevel: number;

  onSwitchChange(value: boolean, id: number, type: string): void;
  onGetAnchorId: (hintKey: string) => string | undefined;
}

const arrIdsToShowHintIcon: { key: string; anchorId: string }[] = [
  {
    key: "orgchart",
    anchorId: "1anchor",
  },
  { key: "recurring_issue", anchorId: "2anchor" },
  { key: "user", anchorId: "3anchor" },
  // {
  //   key: "user.invite",
  //   anchorId: 4,
  // },
  { key: "company", anchorId: "4anchor" },
  { key: "report", anchorId: "5anchor" },
  { key: "user_schedule", anchorId: "6anchor" },
];

function drawSwitches(
  permission: PermissionDto,
  props: IOrgchartDrawerTabPermissionsView,
  disabled: boolean,
  onGetAnchorId: (hintkye: string) => string | undefined,
  locale: LocalesKeys,
  index?: number
) {
  const localePermissions = getPermissionsTooltips(locale);

  return <div>123</div>;
  // return !!permission.children?.length ? (
  //   <Card
  //     variant="secondary"
  //     className="orgchart-drawer-tab_permission-group_card"
  //     isShadowed={false}
  //     key={permission.id + "b2"}
  //   >
  //     <div className="d-stack justify-start align-center spacing-2">
  //       <span className={"orgchart-drawer-tab_permission-group_name"}>{permission.name}</span>
  //       {arrIdsToShowHintIcon?.find((item) => item.key === permission.hintKey) && (
  //         <OpeningGuideIcon contentKey={guideKeys.permissions} anchorId={onGetAnchorId(permission.hintKey!)} />
  //       )}
  //     </div>
  //
  //     {permission.children.map((child, index) => drawSwitches(child, props, disabled, onGetAnchorId, locale, index))}
  //   </Card>
  // ) : (
  //   <div key={permission.id + "b3"} className={`${index != null && index > 0 ? "mt-3" : "mt-3"}`}>
  //     <div className="d-stack justify-start align-center spacing-2">
  //       <Switch
  //         label={permission?.name ?? ""}
  //         key={permission.id}
  //         disabled={disabled}
  //         checked={props.functionData.claims?.some((x) => x.type == permission.key) ?? false}
  //         onChange={(value) => props.handleSwitchChange(value, permission.id as number, permission.key as string)}
  //       />
  //       {localePermissions?.find((item) => item.key === permission.key) && (
  //         <Tooltip title={localePermissions.find((item) => item.key === permission.key)!.text} placement="bottom">
  //           <FiHelpCircle className="guide-icon" />
  //         </Tooltip>
  //       )}
  //     </div>
  //   </div>
  // );
}

const PermissionSwitchesGroup = (props: IPermissionSwitchesGroup) => {
  const localePermissions = getPermissionsTooltips(props.locale);

  return (props.permission.children ?? []).length == 0 ? (
    <div className="d-stack justify-start align-center spacing-2">
      <Switch
        label={
          <div className="d-stack spacing-2 align-center">
            <span children={props.permission.name} />
            <div className="d-flex align-center">
              {localePermissions?.find((item) => item.key === props.permission.key) && (
                <Tooltip
                  title={localePermissions.find((item) => item.key === props.permission.key)!.text}
                  placement="bottom"
                >
                  <FiHelpCircle className="guide-icon" />
                </Tooltip>
              )}
            </div>
          </div>
        }
        // size="small"
        key={props.permission.id}
        disabled={props.disabled}
        checked={props.functionData.claims?.some((x) => x.type == props.permission.key) ?? false}
        onChange={(value) => props.onSwitchChange(value, props.permission.id as number, props.permission.key as string)}
      />
    </div>
  ) : (
    <div className={`ml-${4 * props.nestingLevel}`}>
      <div className="d-stack justify-start align-center spacing-2 mb-2">
        <Text size="16px" weight="bold" children={props.permission.name} />
        {arrIdsToShowHintIcon?.find((item) => item.key === props.permission.hintKey) && (
          <OpeningGuideIcon
            contentKey={guideKeys.permissions}
            anchorId={props.onGetAnchorId(props.permission.hintKey!)}
          />
        )}
      </div>
      <div className="d-stack-column spacing-3">
        {props.permission.children?.map((child, index) => (
          <PermissionSwitchesGroup
            {...props}
            permission={child}
            nestingLevel={props.nestingLevel + 1}
            key={child.key}
          />
        ))}
      </div>
    </div>
  );
};

function OrgchartDrawerTabPermissionsView(props: IOrgchartDrawerTabPermissionsView) {
  const claims = useClaims();
  const { appStore } = useRootStore();

  const handleGetAnchorId = (hintkye: string): string | undefined => {
    return arrIdsToShowHintIcon.find((item) => item.key === hintkye)?.anchorId;
  };

  return (
    <div className="d-stack-column spacing-2">
      {props.allPermissions.map((permission: PermissionDto) => (
        <Card variant="secondary" isShadowed={false} key={permission.id + (permission.key ?? "")}>
          <PermissionSwitchesGroup
            permission={toJS(permission)}
            disabled={!claims.has(permissionKeys.orgchart.role.permissionsEdit)}
            nestingLevel={0}
            parentProps={props}
            locale={appStore.getAppLocale as LocalesKeys}
            functionData={props.functionData}
            onGetAnchorId={handleGetAnchorId}
            onSwitchChange={props.handleSwitchChange}
          />
          {/*{drawSwitches(*/}
          {/*  permission,*/}
          {/*  props,*/}
          {/*  !claims.has(permissionKeys.orgchart.role.permissionsEdit),*/}
          {/*  handleGetAnchorId,*/}
          {/*  appStore.getAppLocale as LocalesKeys*/}
          {/*)}*/}
        </Card>
      ))}
    </div>
  );
}

export default OrgchartDrawerTabPermissionsView;
