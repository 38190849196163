import { HubConnection, HubConnectionBuilder, HubConnectionState } from "@microsoft/signalr";
import { useRootStore } from "../../hooks";

export let notificationHubConnection: HubConnection;

export function initNotificationHubConnection(accessToken: string) {
  const newConnection = new HubConnectionBuilder()
    .withUrl(process.env.REACT_APP_API_BASE_URL + "api/v1/hub/notification", { accessTokenFactory: () => accessToken })
    .withAutomaticReconnect([0, 1000, 2000, 4000, 8000])
    .build();
  notificationHubConnection = newConnection;
}

export function checkDisconnection(): boolean {
  return notificationHubConnection?.state === HubConnectionState.Disconnected;
}

export const useNotificationHub = () => {
  const { authStore, appStore } = useRootStore();

  async function start() {
    if (notificationHubConnection != null && notificationHubConnection?.state === HubConnectionState.Connected) return;

    const token = authStore.getAccessToken;
    if (token == null) throw new Error("AccessToken should not be null");
    initNotificationHubConnection(token);
    await notificationHubConnection?.start();
  }

  function stop() {
    notificationHubConnection?.stop();
  }

  return {
    connection: notificationHubConnection,
    checkDisconnection,
    start,
    stop,
    messages: {
      //
    },
  };
};
