import React from "react";
import { TextAreaView } from "./TextAreaView";
import { TextAreaProps } from "antd/es/input";

interface ITextArea extends TextAreaProps {
  readOnly?: boolean;
}

export function TextArea(props: ITextArea) {
  return <TextAreaView {...props} />;
}
