import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { api } from "../../../services";
import PageContent from "../../modules/layouts/pageContent/PageContent";
import PageHeader from "../../modules/layouts/pageHeader/PageHeader";
import { useApi, useNotifier } from "../../../hooks";
import { ListEducationSections } from "../../modules/pages/education/components/listEducationSections/ListEducationSections";

const EducationPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { t } = useTranslation();
  const notifier = useNotifier();

  const handleApiError = () => {
    setIsLoading(false);
    notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
  };

  const educationData = useApi(
    () => api.education.getEducationItems(),
    handleApiError,
    () => setIsLoading(false)
  );

  return (
    <>
      <PageHeader title={t("common:page_title.training")}>
        {/*<CarrotQuestButton buttonProps={{ sx: { ml: "auto" } }} />*/}
      </PageHeader>
      {/*tabs={AppTabsByPage["supportCenter"]}*/}
      <PageContent isLoading={isLoading} isFilled>
        <ListEducationSections educationItems={educationData.value ?? []} />
        {/* <Education educationItems={educationData.value ?? []} />*/}
      </PageContent>
    </>
  );
};

export default EducationPage;