export enum RelationsKeys {
  My = "my",
  Controlled = "controlled",
  Participated = "participated",
  Any = "any",
}

export enum IndicatorIconsKeys {
  Warning = "icon.warning",
  Message = "icon.message",
  CheckMark = "icon.check_mark",
}

export enum baseBoardStatusKeys {
  pending = "pending",
  inWork = "in_work",
}
