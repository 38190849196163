import React from "react";
import { IInput } from "../input/Input";

interface IInputLinkified extends IInput {
  //
}

export function InputLinkified(props: IInputLinkified) {
  return (
    <div>
      <div>123</div>
    </div>
  );
}
