import React from "react";
import { TSelectableItemProps } from "react-selectable-fast";
import "./ScheduleDayCard.scss";
import { useTranslation } from "react-i18next";
import { theme } from "antd";
import { addAlphaToHex } from "../../../../../helpers/colorFunctions";

interface IScheduleDayCardView {
  disabled: boolean;
  isSelectedByClick: boolean;
  text: string;
  type: number;
  timeZoneOffset: number | null;
  companyTimeZoneOffset: number | null;
  cardDate: Date;

  onContextMenu: (event: React.MouseEvent) => void;
}

function ScheduleDayCardView(props: IScheduleDayCardView & TSelectableItemProps) {
  const { t } = useTranslation();
  const { useToken } = theme;
  const { token } = useToken();

  const classNames = [
    "schedule-days-row-item",
    props.isSelecting && "__selecting",
    props.disabled && "__disabled",
    (props.isSelected || props.isSelectedByClick) && "__selected",
    String("__type" + ((props.text ?? "").trim().length == 0 ? 0 : props.type ?? 0)),
    (props.text ?? "").trim().length == 0 && "__empty",
  ]
    .filter(Boolean)
    .join(" ");

  const getValue = () => {
    if (props.type == 1 && props.timeZoneOffset != null && props.text.length > 0) {
      const [d1, d2] = props.text.trim().split("\n");
      const [hours1, minutes1] = d1.split(":");
      const [hours2, minutes2] = d2.split(":");

      const resStr1 =
        ("0" + ((Number(hours1) - Number(props.companyTimeZoneOffset) + Number(props.timeZoneOffset) + 24) % 24)).slice(
          -2
        ) +
        ":" +
        minutes1;
      const resStr2 =
        ("0" + ((Number(hours2) - Number(props.companyTimeZoneOffset) + Number(props.timeZoneOffset) + 24) % 24)).slice(
          -2
        ) +
        ":" +
        minutes2;
      return resStr1 + "\n" + resStr2;
    }
    return props.text;
  };

  const getBackgroundByType = (type?: number) => {
    const colorWithAlpha = (hex: string) => addAlphaToHex(hex, 0.25);
    if ((props.text ?? "").trim().length == 0) return colorWithAlpha(token.colorBgContainerDisabled);
    switch (type) {
      case 1:
        return colorWithAlpha(token.colorSuccess);
      case 2:
        return colorWithAlpha(token.colorWarning);
      case 3:
        return colorWithAlpha(token.colorWarning);
      case 4:
        return colorWithAlpha(token.colorError);
      default:
        return colorWithAlpha(token.colorBgContainerDisabled);
    }
  };

  return (
    <div
      onContextMenu={props.disabled ? undefined : props.onContextMenu}
      onClick={props.disabled ? undefined : props.onContextMenu}
      ref={props.selectableRef}
      className={classNames}
      title={
        props.disabled || (props.text ?? "").trim().length == 0 ? t("parse:not_editable") : t("parse:click_to_edit")
      }
      style={{
        border: `3px solid ${token.colorBgContainer}`,
        background: getBackgroundByType(props.type),
      }}
    >
      {getValue()}
    </div>
  );
}

export default ScheduleDayCardView;
