import React from "react";
import "./LoginPage.scss";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { Card, Title } from "../../uiKit";
import logoSign from "../../../assets/images/logo-sign.png";
import { ThemeSwitcher } from "../../modules/pages/settings/themeSwitcher/ThemeSwitcher";
import CarrotQuestButton from "../../service/carrotQuestButton/CarrotQuestButton";
import LanguageSwitcher from "../../modules/pages/settings/languageSwitcher/LanguageSwitcher";
import LoginForm from "../../modules/forms/loginForm/LoginForm";
import InfoLinksBlock from "../../modules/layouts/infoLinksBlock/InfoLinksBlock";
import { useIsMobile, useRootStore } from "../../../hooks";

function LoginPage() {
  const { t } = useTranslation();
  const { appStore } = useRootStore();
  const isMobile = useIsMobile();

  return (
    <div className="full-height d-flex align-center justify-center">
      <Card
        size="default"
        className="layout-card-unauthorized__wrapper"
        style={{
          maxWidth: isMobile ? "100%" : "420px",
          width: "100%",
          maxHeight: isMobile ? "100%" : "640px",
          height: "100%",
        }}
        bodyStyle={{ flexGrow: 1 }}
        actions={[
          <ThemeSwitcher displayMode="button" />,
          <LanguageSwitcher miniVariant selectProps={{ bordered: false, style: { width: "100%" } }} />,
          <CarrotQuestButton buttonProps={{ children: "" }} />,
        ]}
      >
        <div className="full-height d-flex flex-column justify-space-between">
          {/*<div />*/}
          <div className="d-stack-column spacing-2 align-center justify-center">
            <img
              draggable="false"
              width="70%"
              src={logoSign}
              alt="Tonnus Logo"
              style={{
                filter: appStore.getAppTheme == 1 ? "invert(100%) brightness(2) opacity(90%)" : "",
              }}
            />
            <Title level={4}>{t("common:page_title.authorization")}</Title>
          </div>
          <LoginForm />
          <InfoLinksBlock />
        </div>
      </Card>
    </div>
  );
}

export default observer(LoginPage);
