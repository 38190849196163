import React from "react";
import "./TimePickerInlinePrimary.scss";
import { Select, SelectOption, Text } from "../../uiKit";

interface ITimePickerInlinePrimaryView {
  time: {
    hours: string;
    minutes: string;
  };
  generateArray: (from: number, to: number, numberLength?: number | null, multipleOf?: number) => (string | number)[];
  onHoursChange: (value: string) => void;
  onMinutesChange: (value: string) => void;
}

function TimePickerInlinePrimaryView(props: ITimePickerInlinePrimaryView) {
  return (
    <div className="d-flex align-center">
      <Select
        className="time-picker-inline-primary__select __square"
        // MenuProps={{ className: "time-picker-inline-primary__select__menu" }}
        value={props.time.hours}
        onChange={(value) => props.onHoursChange(value)}
      >
        {props.generateArray(0, 23, 2)?.map((h: any) => (
          <SelectOption value={h} key={h}>
            {h}
          </SelectOption>
        ))}
      </Select>
      <Text className="mx-1">:</Text>
      <Select
        className="time-picker-inline-primary__select __square"
        //MenuProps={{ className: "time-picker-inline-primary__select__menu" }}
        value={props.time.minutes}
        onChange={(value) => props.onMinutesChange(value)}
      >
        {props
          .generateArray(
            0,
            59,
            2,
            window.location.hostname == "localhost" || window.location.hostname == "beta.tonnus.io" ? undefined : 5
          )
          ?.map((m: any) => (
            <SelectOption value={m} key={m}>
              {m}
            </SelectOption>
          ))}
      </Select>
    </div>
  );
}

export default TimePickerInlinePrimaryView;
