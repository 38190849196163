import React from "react";
import { Checkbox, CheckboxProps } from "antd";
import "./Checkbox.scss";
import clsx from "clsx";

interface ICheckboxView extends CheckboxProps {
  //
}

export function CheckboxView({ className, ...rest }: ICheckboxView) {
  return <Checkbox className={clsx("uiKit-checkbox", className ?? "")} {...rest} />;
}
