import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useApi, useDateHelpers } from "../../../../hooks";
import { api } from "../../../../services";
import { AdminCompaniesStatsDialogView } from "./AdminCompaniesStatsDialogView";

interface IAdminCompaniesStatsDialog {
  open: boolean;

  onClose(): void;
}

export function AdminCompaniesStatsDialog(props: IAdminCompaniesStatsDialog) {
  const dateHelpers = useDateHelpers();

  const [displayMode, setDisplayMode] = useState<"chart" | "table">("table");
  const [isPaid, setIsPaid] = useState<boolean | null>(null);
  const [isTest, setIsTest] = useState<boolean>(false);
  const [hasDriver, setHasDriver] = useState<boolean | null>(null);
  const [tableFilters, setTableFilters] = useState({
    dateFrom: dayjs().subtract(1, "day"),
    dateTo: dayjs(),
  });

  const companiesStatsData = useApi(() =>
    api.adminCompany.getCompaniesStats(
      tableFilters.dateFrom.toDate(),
      tableFilters.dateTo.toDate(),
      isPaid,
      isTest,
      hasDriver
    )
  );

  useEffect(() => {
    if (props.open) {
      companiesStatsData.fetch();
    } else {
      // employeeStatsData.set([]);
    }
  }, [props.open, tableFilters, isPaid, hasDriver, isTest]);

  const handleClose = () => {
    props.onClose();
    companiesStatsData.set([]);
    setIsPaid(null);
    setHasDriver(null);
    setTableFilters({
      dateFrom: dayjs().subtract(1, "day"),
      dateTo: dayjs(),
    });
    setDisplayMode("table");
  };

  return (
    <AdminCompaniesStatsDialogView
      {...props}
      displayMode={displayMode}
      isPaid={isPaid}
      isTest={isTest}
      hasDriver={hasDriver}
      setHasDriver={setHasDriver}
      setIsPaid={setIsPaid}
      setIsTest={setIsTest}
      isLoading={companiesStatsData.isLoading}
      setDisplayMode={setDisplayMode}
      onClose={handleClose}
      tableFilters={tableFilters}
      setTableFilters={setTableFilters}
      tableItems={(companiesStatsData.value ?? []).map((c) => ({
        date: dateHelpers.formatDateString(c.date as string, {
          month: "short",
          showYearWhenIsCurrent: true,
          hour: undefined,
          minute: undefined,
        }),
        value: c.value,
        value2: c.value2,
      }))}
    />
  );
}
