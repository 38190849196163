import React, { useState } from "react";
import { AppMainMenuLinks, AppMenuLinkType } from "../../../../utils/appLinksAndTabs";
import "./LeftAppDrawer.scss";
import { observer } from "mobx-react-lite";
import { useDateHelpers, useIsMobile, useNotifier, useRootStore } from "../../../../hooks";
import { MenuItemWithChildrenDto } from "../../../../api";
import { useTranslation } from "react-i18next";
import { Badge, Button, Icon, LayoutSider, Menu, Text } from "../../../uiKit";
import { MenuProps, theme } from "antd";
import { useNavigate } from "react-router-dom";
import logoSign from "../../../../assets/images/logo-sign.png";
import logoCircle from "../../../../assets/images/logo-circle.png";
import { guideContentType } from "../../../../stores/hintStore";
import { OpeningGuideIcon } from "../../../elements/openingGuideIcon/OpeningGuideIcon";
import { FiChevronLeft } from "@react-icons/all-files/fi/FiChevronLeft";
import { formatDateToDateString } from "../../../../helpers/formatFunctions";
import { FiChevronRight } from "@react-icons/all-files/fi/FiChevronRight";
import { FiHelpCircle } from "@react-icons/all-files/fi/FiHelpCircle";
import { openURL } from "../../../../helpers/urlFunctions";

interface ILeftAppDrawerView {
  isCollapsed: boolean;
  links: MenuItemWithChildrenDto[];
  selectedMenuItem: string;
  onMenuItemClick: (
    key: string,
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    isDisabled: boolean,
    isCollapsibleElementClicked: boolean
  ) => void;
}

type MenuItem = Required<MenuProps>["items"][number];

function LeftAppDrawerView(props: ILeftAppDrawerView) {
  const allLinks: AppMenuLinkType[] = AppMainMenuLinks;
  const { t } = useTranslation();
  const notifier = useNotifier();
  const { useToken } = theme;
  const { token } = useToken();
  const { appStore, helperStore } = useRootStore();
  const navigate = useNavigate();
  const dateHelpers = useDateHelpers();
  const isMobile = useIsMobile();
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const betaPagesKeys: string[] = ["communication", "planning", "regulation"];

  const getGuideIcon = (guideKey?: guideContentType) =>
    guideKey ? <Icon component={() => <OpeningGuideIcon contentKey={guideKey} />} /> : null;

  const getLinkVersionTypeSymbol = (type: string): string => {
    switch (type) {
      case "alpha":
        return "alpha";
      // return "α";
      case "beta":
        return "beta";
      // return "β";
      default:
        return "";
    }
  };

  const generateAppVersionAndReleaseDate = (
    version: string | null | undefined,
    releaseDate: string | null | undefined,
    isCollapsed: boolean
  ): string => {
    if (version == null || releaseDate == null) return " ";
    return isCollapsed
      ? `${version}\n${formatDateToDateString(new Date(releaseDate))}`
      : `${t("common:misc.version")}: ${version} (${formatDateToDateString(new Date(releaseDate))})`;
  };

  const generateMenuMainItems = (links: MenuItemWithChildrenDto[], isSubLink?: boolean): any[] => {
    return links.map((link) => {
      const icon = allLinks.find((l) => l.key == link.key)?.icon;
      const isDisabled = (allLinks.find((l) => l.key == link.key)?.link ?? "").trim().length == 0 || link.isDisabled;
      const matchedLink = allLinks.find((l) => l.key == link.key);
      return {
        key: link.key,
        icon: isSubLink ? (
          icon
        ) : (
          <Badge
            size="small"
            blink
            count={link.isPendingValue ?? undefined}
            dot={link.isPending}
            offset={props.isCollapsed ? [-2, 12] : [-1, 0]}
          >
            {icon ?? null}
          </Badge>
        ),
        disabled: isDisabled,
        label: (
          <a
            onMouseDown={(e) =>
              props.onMenuItemClick(link.key ?? "", e, isDisabled ?? false, (link.children ?? []).length > 0)
            }
            children={
              <div className="d-flex align-center">
                <span children={link.name} />
                {link.versionType != null && (
                  <div
                    style={{
                      opacity: link.key == props.selectedMenuItem ? 0.8 : 0.7,
                    }}
                    className="mx-2"
                  >
                    <span
                      style={{
                        color: token.colorTextBase,
                        backgroundColor: token.colorBorder,
                        padding: "4px 6px",
                        fontSize: "12px",
                      }}
                      className="br-md"
                      children={getLinkVersionTypeSymbol(link.versionType)}
                    />
                  </div>
                )}
                <div className="flex-grow-1" />
                {link.key == props.selectedMenuItem && !props.isCollapsed && getGuideIcon(matchedLink?.guideKey)}
              </div>
            }
          />
        ),
        children: (link.children ?? []).length > 0 ? generateMenuMainItems(link.children ?? [], true) : undefined,
        // onClick: (args: any) => console.log(args),
      };
    });
  };

  const generateMenuAdditionalItems = (
    links: (MenuItemWithChildrenDto & { icon?: React.ReactNode; onClick: () => void })[]
  ): MenuItem[] => {
    return links.map((link) => ({
      key: link.key as string,
      icon: link.icon,
      label: <div children={link.name} />,
      onClick: link.onClick,
      children: (link.children ?? []).length > 0 ? generateMenuMainItems(link.children ?? []) : undefined,
    }));
  };

  const handleDevConsoleOpen = (event: React.MouseEvent<HTMLElement>) => {
    let cheatCode: string | null = "";
    if (event.ctrlKey) {
      cheatCode = prompt("With great power comes great responsibility", "/");
    } else {
      return;
    }
    switch (cheatCode) {
      case "/app-theme=light": {
        appStore.setAppTheme(0);
        break;
      }
      case "/app-theme=dark": {
        appStore.setAppTheme(1);
        break;
      }
      default:
        break;
    }
  };

  const onCarrotQuestChatOpen = () => {
    if (window.carrotquest == null || window.carrotquest.open == null) {
      notifier.show({
        theme: "error",
        timeout: 10,
        message: t("parse:support_script_fail.message"),
        description: t("parse:support_script_fail.description"),
        button: {
          text: t("parse:support_script_fail.button"),
          onClick: () => openURL("https://t.me/TonnusSupportBot"),
        },
      });
      return;
    }
    window.carrotquest && window.carrotquest.open && window.carrotquest.open();
  };

  const mobileWrapperStyles: React.CSSProperties = {
    height: "calc(100% - 48px)",
    position: "fixed",
    top: "48px",
    left: 0,
    zIndex: 2,
  };

  const mobileOverlayStyles: React.CSSProperties = {
    position: "absolute",
    left: "100%",
    width: "100vw",
    top: 0,
    bottom: 0,
    background: "rgba(0, 0, 0, 0.8)",
    transition: "all 0.2s",
    opacity: props.isCollapsed ? 0 : 1,
    pointerEvents: props.isCollapsed ? "none" : "auto",
  };

  return (
    <LayoutSider
      className="main-app-sider__wrapper"
      theme="light"
      width={240}
      collapsedWidth={isMobile ? 0 : 56}
      collapsed={props.isCollapsed}
      style={isMobile ? mobileWrapperStyles : undefined}
    >
      {isMobile && (
        <div
          style={mobileOverlayStyles}
          onClick={() =>
            appStore.setDrawerBehavior(
              appStore.getLeftDrawerState == "__collapsed" ? "alwaysExpanded" : "alwaysCollapsed"
            )
          }
        />
      )}
      {!isMobile && (
        <>
          <Button
            className="__state_btn"
            style={{ zIndex: 1 }}
            variant="default"
            // shape="circle"
            size="small"
            icon={
              <Icon
                component={() => (appStore.getLeftDrawerState == "__expanded" ? <FiChevronLeft /> : <FiChevronRight />)}
              />
            }
            onClick={() =>
              appStore.setDrawerBehavior(
                appStore.getLeftDrawerState == "__collapsed" ? "alwaysExpanded" : "alwaysCollapsed"
              )
            }
          />
          <div className="__state_indicator_line" />
        </>
      )}
      <Menu
        mode="inline"
        theme="light"
        forceSubMenuRender
        selectedKeys={[props.selectedMenuItem]}
        items={generateMenuMainItems(props.links)}
        // children={generateMenuMainItems(props.links)}
      />
      <div className="flex-grow-1 my-1" />
      <Menu
        className="flex-shrink-0 my-1"
        mode="inline"
        theme="light"
        forceSubMenuRender
        selectedKeys={[]}
        items={generateMenuAdditionalItems([
          {
            key: "help.chat",
            name: t("common:misc.support_chat"),
            icon: <FiHelpCircle />,
            onClick: onCarrotQuestChatOpen,
          },
        ])}
      />
      <div className="my-1 d-stack-column spacing-1 align-center">
        <img
          style={{
            display: !props.isCollapsed ? "block" : "none",
            maxWidth: "70%",
            filter: appStore.getAppTheme == 1 ? "invert(100%) brightness(2) opacity(80%)" : "",
          }}
          className="logo__expanded"
          draggable="false"
          src={logoSign}
        />
        <img
          style={{ display: props.isCollapsed ? "block" : "none", maxWidth: "40px" }}
          className="logo__collapsed"
          draggable="false"
          src={logoCircle}
        />
        <Text
          onDoubleClick={(e) => handleDevConsoleOpen(e)}
          className="__app_version no-select mt-1"
          children={
            helperStore.getInfo != null
              ? generateAppVersionAndReleaseDate(
                  helperStore.getInfo.version,
                  helperStore.getInfo.lastUpdated,
                  appStore.getLeftDrawerState == "__collapsed"
                )
              : " "
          }
        />
      </div>
    </LayoutSider>
  );
}

export default observer(LeftAppDrawerView);
