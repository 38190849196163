import { RootStore } from "./rootStore";
import { makeAutoObservable, runInAction } from "mobx";
import { api } from "../services";
import { BoardDto } from "../api";

export default class BoardStore {
  root: RootStore;
  private boardId: number;
  private board: BoardDto | null;

  constructor(root: RootStore) {
    makeAutoObservable(this);
    this.root = root;
    this.board = null;
    this.boardId = 0;
  }

  get getBoard(): BoardDto {
    return this.board!;
  }

  setBoardId(id: number): void {
    this.boardId = id;
  }

  setBoard(board: BoardDto) {
    this.board = board;
  }

  async fetchBoard() {
    const board = await api.board.getById(this.boardId);
    runInAction(() => {
      this.setBoard(board!);
    });
  }
}
