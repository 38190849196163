import React, { useContext, useEffect } from "react";
import { OrgchartDrawerTabRegulationsView } from "./OrgchartDrawerTabRegulationsView";
import { OrgchartContext } from "../../../../../../contexts/orgchartContext";
import { usePagingWithController } from "../../../../../../hooks/usePaging";
import { RegulationDto } from "../../../../../../api";
import { api } from "../../../../../../services";
import { RegulationFilter } from "../../../../../../api/filters/regulationFilter";
import { paginationTypeKeys } from "../../../regulation/components/ListCards/ListCards.interface";

export const OrgchartDrawerTabRegulations = () => {
  const orgchartContext = useContext(OrgchartContext);

  const regulations = usePagingWithController<RegulationDto, RegulationFilter>(api.regulation, {
    roleId: Number(orgchartContext?.blockId),
    hasActiveContent: true,
  });

  const sections = usePagingWithController<RegulationDto, RegulationFilter>(api.section, {
    roleId: Number(orgchartContext?.blockId),
  });

  useEffect(() => {
    regulations.reset();
    regulations.restart();
    sections.reset();
    sections.restart();
  }, []);

  return (
    <OrgchartDrawerTabRegulationsView
      isLoadingRegulations={regulations.info.isLoading}
      isLoadingSections={sections.info.isLoading}
      isDoneRegulations={regulations.info.isDone}
      isDoneSections={sections.info.isDone}
      onLoadNextRegulations={regulations.loadNext}
      onLoadNextSections={sections.loadNext}
      regulations={regulations.items}
      sections={sections.items}
      paginationType={paginationTypeKeys.byButton}
    />
  );
};
