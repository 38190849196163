import React, { useEffect, useState } from "react";
import EmployeeEditFormView from "./EmployeeEditFormView";
import { useApi, useNotifier, useRootStore } from "../../../../hooks";
import { observer } from "mobx-react-lite";
import { api } from "../../../../services";
import { useUpdateWithController } from "../../../../hooks/useUpdate";
import { useTranslation } from "react-i18next";
import { UserDto } from "../../../../api";
import { useNavigate } from "react-router-dom";
import { CustomConfirmDialog } from "../../dialogs/customConfirmDialog/СustomConfirmDialog";

interface IEmployeeEditForm {
  userId?: number;
  firstLaunch?: boolean;
  onSaveClick?: () => void;
}

function EmployeeEditForm(props: IEmployeeEditForm) {
  const { authStore, helperStore } = useRootStore();
  const notifier = useNotifier();
  const { t } = useTranslation();
  const timeZones = helperStore.getTimezones;
  const userId = props?.userId ?? authStore.getInitialInfo?.identity?.id;
  const currentUserId = authStore.getInitialInfo?.identity?.id;
  const navigate = useNavigate();
  const [checkEmailCode, setCheckEmailCode] = useState<string>("");
  const [codeSendTimer, setCodeSendTimer] = useState(0);
  const [isOpenConfirmTimeZone, setIsOpenConfirmTimeZone] = useState<boolean>(false);
  const [isFirstRender, setisFirstRender] = useState<boolean>(true);
  const [isUserTypeEditAvailable, setIsUserTypeEditAvailable] = useState<boolean>(false);

  const [canShowError, setCanShowError] = useState({
    lastName: false,
    firstName: false,
    phoneNumber: false,
  });

  const generateUserLimitation = async () => {
    const currentUsersCount =
      (
        await api.user.autocomplete({
          containsCompanyId: authStore.getInitialInfo?.identity?.currentCompanyId,
          companyId: authStore.getCurrentCompanyId,
          accessType: 0,
        })
      )?.totalItems ?? 0;
    const usersLimit =
      authStore.getInitialInfo?.identity?.companies?.find(
        (c) => c.companyId == authStore.getInitialInfo?.identity?.currentCompanyId
      )?.company?.userLimit ?? "∞";
    setIsUserTypeEditAvailable(usersLimit == "∞" || currentUsersCount < usersLimit);
  };

  const employeeData = useApi(
    () => api.user.getById(userId as number),
    () => {},
    (x) => {
      if (props.userId != null && x == null) {
        navigate("/employee");
        return;
      }
      updater.setInitialState(x!);
    }
  );

  const handleProfileSave = async () => {
    if (userId == currentUserId && !props.firstLaunch) {
      await authStore.refreshInitialInfo();
    }
    await generateUserLimitation();
  };

  const updater = useUpdateWithController(api.user, handleProfileSave);

  const handleConfirmEmail = async () => {
    const r = await api.confirmation.confirmCode({
      code: checkEmailCode,
      type: "email_confirmation",
    });
    notifier.showSuccessError(
      t("notifier:success.email_confirmation"),
      t("notifier:error.email_confirmation"),
      r != null
    );
    if (r != null) {
      await employeeData.fetch();
    }
  };

  const sendConfirmationCodeAgain = async () => {
    const r = await api.confirmation.resetPasswordSendCode();
    notifier.showSuccessError(t("parse:confirm_code_resend"), t("notifier:error.email_confirmation"), r != null);
    if (r != null) {
      // await employeeData.fetch();
      // startCodeTimer();
    }
  };

  const startCodeTimer = () => {
    setCodeSendTimer(45);
    setTimeout(() => reduceCodeTimer(), 1000);
    // if (codeSendTimer > 0) {
    //   setCodeSendTimer(codeSendTimer - 1);
    //   console.log(codeSendTimer, "cs");
    // }
    // setTimeout(() => {
    // reduceCodeTimer();
    // }, 1000);
  };

  const reduceCodeTimer = () => {
    if (codeSendTimer > 0) {
      setCodeSendTimer(codeSendTimer - 1);
    } else return;
    const i = setTimeout(() => {
      reduceCodeTimer();
    }, 1000);
  };

  const handleAvatarChange = async (id: number) => {
    const userData: UserDto = updater.currentState ?? {};

    const r = await api.user.edit(
      userId as number,
      {
        ...userData,
        avatar: { ...userData.avatar, imageId: id },
      }
      // userData
    );
    if (!!r) {
      updater.setInitialState(r);
      await authStore.refreshInitialInfo();
    }
  };

  const userTimezone = new Date().getTimezoneOffset() / -60;

  const handlerConfirmSetTimezoneDialog = () => {
    updater.applyChanges({ timeZoneFromUtc: userTimezone });
    setTimeout(async () => await updater.updatePartially, 100);
    setIsOpenConfirmTimeZone(false);
  };

  const timeZonesIsDifferent = updater.currentState && updater.currentState?.timeZoneFromUtc !== userTimezone;

  const handleCheckTimeZone = () => {
    props.firstLaunch && timeZonesIsDifferent && setIsOpenConfirmTimeZone(true);
    /*isFirstRender && setisFirstRender(false);*/
  };

  const handleGetSubtitleForConfirmTimezone = () => {
    const currentTZ = timeZones.find((item) =>
      item.text?.includes(`UTC ${userTimezone > 0 ? "+" + String(userTimezone) : userTimezone}`)
    );
    return currentTZ?.text
      ? "«" + currentTZ?.text + "»,"
      : `${userTimezone > 0 ? `+${userTimezone}` : userTimezone} ${Intl.DateTimeFormat().resolvedOptions().timeZone}`;
  };

  const handleAvatarDelete = async () => {
    const userData: UserDto = { ...updater.currentState } ?? {};

    const r = await api.user.editPartially(
      userId as number,
      {
        ...userData,
        avatar: { ...userData.avatar, imageId: null },
      },
      userData
    );
    if (!!r) {
      updater.setInitialState(r);
      await authStore.refreshInitialInfo();
    }
  };

  useEffect(handleCheckTimeZone, [updater.currentState?.timeZoneFromUtc, props.firstLaunch]);

  useEffect(() => {
    generateUserLimitation();
  }, []);

  return (
    <>
      <CustomConfirmDialog
        open={isOpenConfirmTimeZone}
        onClose={() => setIsOpenConfirmTimeZone(false)}
        subTitle={
          t("parse:confirm_timezone1") + ` ${handleGetSubtitleForConfirmTimezone()} ` + t("parse:confirm_timezone2")
        }
        onConfirm={handlerConfirmSetTimezoneDialog}
      />
      <EmployeeEditFormView
        firstLaunch={props?.firstLaunch ?? false}
        employeeData={employeeData.value ?? null}
        userId={userId as number}
        isUserTypeEditAvailable={isUserTypeEditAvailable}
        isAccessTypeEditAvailable={props.userId != null}
        canShowError={canShowError}
        updater={updater}
        codeSendTimer={codeSendTimer}
        checkEmailCode={checkEmailCode}
        setCheckEmailCode={setCheckEmailCode}
        setCanShowError={setCanShowError}
        handleConfirmEmail={handleConfirmEmail}
        currentUserId={currentUserId as number}
        handleAvatarChange={handleAvatarChange}
        handleAvatarDelete={handleAvatarDelete}
        sendConfirmationCodeAgain={sendConfirmationCodeAgain}
        onSaveClick={props.onSaveClick}
      />
    </>
  );
}

export default observer(EmployeeEditForm);
