import React from "react";
import "./NoPagesPage.scss";
import PageContent from "../../../modules/layouts/pageContent/PageContent";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Title } from "../../../uiKit";

function NoPagesPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      {/*<PageHeader />*/}
      <PageContent isContentCentered isFilled>
        <Title level={4}>{t("common:page_title.no_pages") + " 🙁"}</Title>
        {/*<img draggable="false" style={{ maxWidth: "40%", margin: "40px 0" }} src={page404} />*/}
        {/*<ul style={{ padding: 0 }}>*/}
        {/*  <li>Убедитесь, что Вы перешли по верной ссылке</li>*/}
        {/*  <li>Проверьте, нет ли опечаток в адресе страницы</li>*/}
        {/*</ul>*/}
        {/*<Button onClick={() => navigate("/orgchart")} variant="contained">*/}
        {/*  Вернуться на главную страницу*/}
        {/*</Button>*/}
      </PageContent>
    </>
  );
}

export default NoPagesPage;
