import React from "react";
import { Collapse, CollapsePanel, Divider, Text } from "../../../../uiKit";
import { IDriverIssuesCollapse } from "./driverIssuesCollapse.interface";
import { useTranslation } from "react-i18next";
import { useDateHelpers } from "../../../../../hooks";

export const DriverIssuesCollapse = (props: IDriverIssuesCollapse) => {
  const { t } = useTranslation();
  const dateHelpers = useDateHelpers();

  return (
    <Collapse accordion>
      {props.violationGroups?.map((violationGroup, index) => (
        <CollapsePanel key={index} header={violationGroup.violation?.name}>
          {violationGroup.issueIds?.map((issueId: number, index) => (
            <React.Fragment key={issueId}>
              {index > 0 && <Divider className="my-2" />}
              <div className="d-flex flex-column">
                <Text children={props?.issues?.find((issue) => issue.id == issueId)?.name} />
                <Text type="secondary" children={props?.issues?.find((issue) => issue.id == issueId)?.awaitedResult} />
                <Text
                  size="12px"
                  type="secondary"
                  children={`${t("ui:text.deadline")}: ${
                    props?.issues?.find((issue) => issue.id == issueId)?.dateDeadline != null
                      ? dateHelpers.formatDateString(
                          props?.issues?.find((issue) => issue.id == issueId)?.dateDeadline ?? "",
                          { month: "short" }
                        )
                      : t("common:misc.not_specified")
                  }`}
                />
              </div>
            </React.Fragment>
          ))}
        </CollapsePanel>
      ))}
    </Collapse>
  );
};
