import React from "react";
import { TextEditor } from "../../../textEditorOld/TextEditor";
import ruContent from "../../../../../utils/guides1/schedule/ru.json";
import enContent from "../../../../../utils/guides1/schedule/en.json";
import ukContent from "../../../../../utils/guides1/schedule/uk.json";
import { useRootStore } from "../../../../../hooks";

export const getContent = (localeKey: string | null): any | null => {
  switch (localeKey) {
    case "ru":
      return ruContent;
    case "en":
      return enContent;
    case "uk":
      return ukContent;
  }
};

export const ScheduleGuide = () => {
  const { appStore } = useRootStore();
  return (
    <TextEditor readOnly={true} id="guide-dasboard" onChange={() => {}} value={getContent(appStore.getAppLocale)} />
  );
};
