import React, { useEffect, useRef, useState } from "react";
import "./SettingsModulesPage.scss";
import { useClaims, useRootStore } from "../../../hooks";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import PageContent from "../../modules/layouts/pageContent/PageContent";
import {
  Alert,
  Button,
  Card,
  Collapse,
  CollapsePanel,
  DatePicker,
  Dialog,
  DialogActions,
  Empty,
  Icon,
  Input,
  InputNumber,
  Spin,
  Text,
} from "../../uiKit";
import PageHeader from "../../modules/layouts/pageHeader/PageHeader";
import { api } from "../../../services";
import { CompanyDto, CompanyIntervalDto } from "../../../api";
import { permissionKeys } from "../../../utils/permissions";
import SubheaderText from "../../elements/subheaderText/SubheaderText";
import { FiEdit2 } from "@react-icons/all-files/fi/FiEdit2";
import { formatDateToDateString } from "../../../helpers/formatFunctions";
import dayjs from "dayjs";

const IntervalChangeWarningDialog = ({
  isOpen,
  onClose,
  onAccept,
}: {
  isOpen: boolean;
  onClose: () => void;
  onAccept: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} onClose={onClose} title={t("parse:intervals.warning_dialog.title")}>
      <Text>{t("parse:intervals.warning_dialog.text")}</Text>
      <DialogActions>
        <Button onClick={onClose} variant="text">
          {t("ui:button.cancel")}
        </Button>
        <Button onClick={onAccept}>{t("ui:button.accept")}</Button>
      </DialogActions>
    </Dialog>
  );
};

const IntervalSettingsDialog = ({
  isOpen,
  isLoading,
  interval,
  intervalArray,
  onSave,
  onChange,
  onClose,
}: {
  isOpen: boolean;
  isLoading: boolean;
  interval: CompanyIntervalDto | null;
  intervalArray: CompanyIntervalDto[];
  onSave: () => void;
  onChange: (value: CompanyIntervalDto) => void;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  // const isChangeWarningDialogAvailableToShow = useRef<boolean>(true);
  const [isChangeWarningDialogOpen, setIsChangeWarningDialogOpen] = useState<boolean>(false);
  const intervalBackup = useRef<CompanyIntervalDto | null>(interval);

  useEffect(() => {
    intervalBackup.current = interval;
    //   isChangeWarningDialogAvailableToShow.current = true;
  }, [isOpen]);

  const isIntervalAlreadyExists = (): boolean => {
    //
    return true;
  };

  const handleSave = () => {
    if (interval?.id == 0) {
      // Create new interval
      onSave();
    } else {
      // Save existing interval
      if (
        intervalBackup.current?.day != interval?.day ||
        intervalBackup.current?.week != interval?.week ||
        intervalBackup.current?.month != interval?.month ||
        intervalBackup.current?.year != interval?.year ||
        intervalBackup.current?.dateStart != interval?.dateStart
      ) {
        setIsChangeWarningDialogOpen(true);
      } else {
        onSave();
      }
    }
  };

  const checkIsSaveNotAvailable = (): boolean => {
    return interval?.day == 1
      ? intervalArray.some(
          (i) =>
            i.day == interval?.day &&
            i.week == interval?.week &&
            i.month == interval?.month &&
            i.year == interval?.year &&
            interval?.dateStart != null &&
            i.dateStart != null &&
            // formatDateToDateString(new Date(i.dateStart)) == formatDateToDateString(new Date(interval?.dateStart)) &&
            i.id != interval?.id
        )
      : intervalArray.some(
          (i) =>
            i.day == interval?.day &&
            i.week == interval?.week &&
            i.month == interval?.month &&
            i.year == interval?.year &&
            interval?.dateStart != null &&
            i.dateStart != null &&
            formatDateToDateString(new Date(i.dateStart)) == formatDateToDateString(new Date(interval?.dateStart)) &&
            i.id != interval?.id
        );
  };

  useEffect(() => {
    console.log(intervalArray, interval);
  }, [interval, intervalArray]);

  if (interval == null) return null;

  return (
    <Dialog open={isOpen} onClose={onClose} title={t("parse:intervals.dialog_title")}>
      <IntervalChangeWarningDialog
        isOpen={isChangeWarningDialogOpen}
        onClose={() => setIsChangeWarningDialogOpen(false)}
        onAccept={() => {
          setIsChangeWarningDialogOpen(false);
          onSave();
        }}
      />
      <div className="d-stack-column spacing-3">
        <div>
          <SubheaderText text={t("parse:intervals.name")} />
          <Input
            placeholder={t("parse:intervals.enter_value")}
            value={interval?.name ?? undefined}
            onInput={(event) => onChange({ ...interval, name: (event.target as HTMLInputElement).value })}
          />
        </div>
        <div>
          <SubheaderText text={t("parse:intervals.repeat_every")} />
          <div className="d-stack spacing-2">
            <div>
              <SubheaderText text={t("parse:intervals.day")} />
              <InputNumber
                className="full-width"
                value={Number(interval?.day ?? 0)}
                placeholder={t("parse:intervals.enter_value")}
                min={0}
                onChange={(value) => onChange({ ...interval, day: Number(value), week: 0, month: 0, year: 0 })}
              />
            </div>
            <div>
              <SubheaderText text={t("parse:intervals.week")} />
              <InputNumber
                className="full-width"
                value={Number(interval?.week ?? 0)}
                placeholder={t("parse:intervals.enter_value")}
                min={0}
                onChange={(value) => onChange({ ...interval, week: Number(value), day: 0, month: 0, year: 0 })}
              />
            </div>
            <div>
              <SubheaderText text={t("parse:intervals.month")} />
              <InputNumber
                className="full-width"
                value={Number(interval?.month ?? 0)}
                placeholder={t("parse:intervals.enter_value")}
                min={0}
                onChange={(value) => onChange({ ...interval, month: Number(value), day: 0, week: 0, year: 0 })}
              />
            </div>
            <div>
              <SubheaderText text={t("parse:intervals.year")} />
              <InputNumber
                className="full-width"
                value={Number(interval?.year ?? 0)}
                placeholder={t("parse:intervals.enter_value")}
                min={0}
                onChange={(value) => onChange({ ...interval, year: Number(value), day: 0, week: 0, month: 0 })}
              />
            </div>
          </div>
        </div>
        {interval.day != 1 && (
          <div>
            <SubheaderText text={t("parse:intervals.date_start")} />
            <DatePicker
              allowClear={false}
              value={dayjs(interval?.dateStart as string)}
              size="large"
              onChange={(value) =>
                onChange({ ...interval, dateStart: value == null ? undefined : value.toISOString() })
              }
            />
          </div>
        )}
        {checkIsSaveNotAvailable() && <Alert type="error" message={t("parse:interval_already_exists")} />}
      </div>

      <DialogActions>
        <Button onClick={onClose} variant="text">
          {t("ui:button.cancel")}
        </Button>
        <Button
          disabled={
            (interval.name ?? "").trim().length == 0 ||
            (interval.day == 0 && interval.week == 0 && interval.month == 0 && interval.year == 0) ||
            checkIsSaveNotAvailable()
          }
          loading={isLoading}
          onClick={handleSave}
          // onClick={onSave}
        >
          {t("ui:button.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function SettingsModulesPage() {
  const { t } = useTranslation();
  const { authStore } = useRootStore();
  const claims = useClaims();
  const companyId = authStore.getInitialInfo?.identity?.currentCompanyId;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [companyIntervals, setCompanyIntervals] = useState<CompanyIntervalDto[]>([]);
  const [companyData, setCompanyData] = useState<CompanyDto | null>(null);
  const [isIntervalDialogOpen, setIsIntervalDialogOpen] = useState<boolean>(false);
  const [intervalToEdit, setIntervalToEdit] = useState<CompanyIntervalDto | null>(null);

  const emptyInterval: CompanyIntervalDto = {
    id: 0,
    companyId: companyId ?? undefined,
    name: "",
    dateStart: new Date().toISOString(),
    day: 1,
    week: 0,
    month: 0,
    year: 0,
  };

  const getCompanyData = async () => {
    setIsLoading(true);
    const r = await api.company.getById(companyId as number);
    setIsLoading(false);
    if (r == null) {
      return;
    }
    setCompanyIntervals(r.intervals?.sort((a, b) => a.id! - b.id!) ?? []);
    setCompanyData(r);
  };

  const handleAddInterval = () => {
    setIntervalToEdit(emptyInterval);
    // setCompanyIntervals((i) => [...i, emptyInterval]);
    setIsIntervalDialogOpen(true);
  };

  const handleSaveInterval = async () => {
    if (companyId == null || companyData == null || intervalToEdit == null) return;
    setIsLoading(true);
    const r = await api.company.edit(companyId as number, {
      ...(companyData as CompanyDto),
      intervals: companyIntervals.some((i) => i.id == intervalToEdit.id)
        ? companyIntervals.map((i) => (i.id == intervalToEdit.id ? intervalToEdit : i))
        : [...companyIntervals, intervalToEdit],
    });
    if (r == null) {
      return;
    }
    setCompanyIntervals(r.intervals?.sort((a, b) => a.id! - b.id!) ?? []);
    setCompanyData(r);
    setIsIntervalDialogOpen(false);
    setIntervalToEdit(null);
    setIsLoading(false);
    await authStore.refreshInitialInfo();
  };

  const handleChangeInterval = async (value: CompanyIntervalDto) => {
    setIntervalToEdit({ ...intervalToEdit, ...value });
    // setCompanyIntervals([...companyIntervals.map((i) => (i.id == value.id ? value : i))]);
  };

  useEffect(() => {
    getCompanyData();
  }, []);

  return (
    <>
      <PageHeader title={t("common:page_title.settings_modules")} />
      <PageContent tabs={authStore.getInitialInfo?.settingsTabs}>
        <IntervalSettingsDialog
          isOpen={isIntervalDialogOpen}
          isLoading={isLoading}
          interval={intervalToEdit}
          intervalArray={companyIntervals}
          onSave={handleSaveInterval}
          onChange={handleChangeInterval}
          onClose={() => setIsIntervalDialogOpen(false)}
        />
        <Collapse activeKey={1}>
          <CollapsePanel key={1} header={t("common:tab.orgchart.metrics")}>
            <div className="mb-2">
              <Text>{t("parse:intervals.intervals_settings")}</Text>
            </div>
            {isLoading && companyIntervals.length == 0 && (
              <div className="d-flex align-center justify-center">
                <Spin spinning={true} size="large" />
              </div>
            )}
            {companyIntervals.length == 0 && !isLoading ? (
              <Empty />
            ) : (
              <div className="d-stack-column spacing-2">
                {companyIntervals
                  .filter((i) => i.id != 0)
                  .map((interval, index) => (
                    <Card
                      clickable
                      variant="secondary"
                      onClick={() => {
                        setIntervalToEdit(interval);
                        setIsIntervalDialogOpen(true);
                      }}
                      key={interval.id}
                    >
                      <div className="d-flex align-center">
                        <Text className="flex-grow-1 mr-2">{interval.name}</Text>
                        <Icon component={() => <FiEdit2 />} />
                      </div>
                    </Card>
                  ))}
                {claims.has(permissionKeys.company.edit.modules) && !isLoading && (
                  <div className="mt-1">
                    <Button disabled={companyIntervals.some((i) => i.id == 0)} onClick={handleAddInterval}>
                      {t("ui:button.add")}
                    </Button>
                  </div>
                )}
              </div>
            )}
          </CollapsePanel>
        </Collapse>
      </PageContent>
    </>
  );
}

export default observer(SettingsModulesPage);
