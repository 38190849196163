import React from "react";
import { Select, SelectOption } from "../../../../../uiKit";
import { useTranslation } from "react-i18next";
import { IPlanningFilter, TPlanningFilterItems } from "./PlanningFilter.interface";
import { useApi } from "../../../../../../hooks";
import { api } from "../../../../../../services";
import { BsFilter } from "@react-icons/all-files/bs/BsFilter";

export const PlanningFilter = (props: IPlanningFilter) => {
  const { t } = useTranslation();
  const status = useApi(
    () => api.plan.status(),
    null,
    () => {}
  );

  const handleChangeFilterValue = (value: string) => {
    props.onFilterKeyChange && props.onFilterKeyChange(Number(value));
  };

  const items: TPlanningFilterItems[] = [
    { label: t("common:planning.all"), key: 0 },
    ...(status.value ?? [])?.map((value) => ({ label: value.name!, key: value.key! })),
  ];

  return (
    <Select
      onChange={handleChangeFilterValue}
      style={{ width: 210 }}
      defaultValue={0}
      suffixIcon={<BsFilter size="20.5px" />}
    >
      {items.map((i) => (
        <SelectOption key={i.key} value={i.key} className="planning-page-admin-menu__select-option">
          {i.label}
        </SelectOption>
      ))}
    </Select>
  );
};
