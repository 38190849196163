import React from "react";
import { ICardIssueView } from "./CardIssue.interface";
import { Indicator } from "../../../../../elements/indicator";
import { IndicatorIconsTypes } from "../../../../../elements/indicator/Indicator.interface";
import { colorSchemeType } from "../../../../../../utils/colorScheme";
import { Card, Divider, Text } from "../../../../../uiKit";
import { UserInfo } from "../../../../../elements/userInfo/UserInfo";
import { AiOutlineArrowRight } from "@react-icons/all-files/ai/AiOutlineArrowRight";
import { useDateHelpers } from "../../../../../../hooks";
import "./CardIssue.scss";
import { truncateString } from "../../../../../../helpers/stringFunctions";

export const CardIssueView = (props: ICardIssueView) => {
  const { formatDateString } = useDateHelpers();

  return (
    <Card
      onClick={props.onClick}
      hoverable
      bordered
      bodyStyle={{ padding: 0 }}
      size="small"
      style={{ width: "100%", borderRadius: "var(--border-radius-md)", marginBottom: "8px" }}
      className="todo-list__card"
    >
      <div className="d-stack-row justify-start align-start spacing-0">
        <div className="d-stack-column justify-start align-start spacing-1 flex-grow-1">
          {props?.issue?.calculated?.indicators && props?.issue?.calculated?.indicators.length > 0 && (
            <div className="d-stack-row justify-start align-center spacing-2 flex-wrap">
              {props?.issue?.calculated?.indicators.map((indicator) => (
                <div key={indicator.id!} className="mb-1">
                  <Indicator
                    name={indicator.name!}
                    key={indicator.id!}
                    // TODO: Add <Icon component={() => icon} /> component
                    icon={indicator.icon as IndicatorIconsTypes}
                    colorSchemeKey={indicator.colorSchemeKey as colorSchemeType}
                  />
                </div>
              ))}
            </div>
          )}
          <div className="todo-list__card-task mb-1">
            <p className="todo-list__card-name mb-0">{truncateString(props.issue.name ?? "", 96, "end", true)}</p>
          </div>
        </div>
        <Text className="todo-list__card-identifier">{`#${props.issue.subId}`}</Text>
      </div>
      <Divider />
      <div className="todo-list__card-footer d-stack-row align-center spacing-1 justify-space-between">
        <div className="d-stack-row align-center justify-start spacing-3 mt-2">
          <UserInfo avatarSize={24} nameDisplayMode="none" user={props.issue.createdByUser!} />
          <AiOutlineArrowRight fontSize={22} color="var(--color-text-weak)" />
          <UserInfo avatarSize={24} nameDisplayMode="none" user={props.issue.executorUser!} />
        </div>
        <Text className="todo-list__card-footer__createdAt mt-2 mr-2">
          {props.issue.dateDeadline && formatDateString(props.issue.dateDeadline, { month: "short" })}
        </Text>
      </div>
    </Card>
  );
};
