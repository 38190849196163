import React from "react";
import { TimePickerRangeView } from "./TimePickerRangeView";
import { RangePickerTimeProps } from "antd/es/date-picker/generatePicker";
import { Dayjs } from "dayjs";

type TTimePickerRange = {
  readOnly?: boolean;
} & Omit<RangePickerTimeProps<Dayjs>, "picker">;

export function TimePickerRange(props: TTimePickerRange) {
  return <TimePickerRangeView picker="time" format="HH:mm" {...props} />;
}
