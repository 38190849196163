import React from "react";
import "./FullCalendar.scss";
import { CalendarOptions } from "@fullcalendar/common";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useSearchParams } from "react-router-dom";
import allLocales from "@fullcalendar/core/locales-all";
// import ruLocale from "@fullcalendar/core/locales/ru";
import { IFullCalendarAdditionalCell } from "./FullCalendar";
import { useTranslation } from "react-i18next";
import { Spin } from "../../uiKit";
import { observer } from "mobx-react-lite";
import { useRootStore } from "../../../hooks";

interface IFullCalendarView extends CalendarOptions {
  additionalCells: IFullCalendarAdditionalCell[];
  isLoading: boolean;
}

function FullCalendarViewToExport(props: IFullCalendarView) {
  const { t } = useTranslation();
  const { appStore } = useRootStore();
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div className="full-height">
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="timeGridWeek"
        viewClassNames="fullcalendar-view"
        // allDaySlot={false}
        locales={allLocales}
        // timeZone="UTC"
        locale={appStore.getAppLocale ?? "en"}
        eventResize={props.eventResize}
        eventDrop={props.eventDragStop}
        nowIndicator
        // visibleRange={{
        //   start: dayjs().add(21, "day").toDate(),
        //   end: dayjs().add(28, "day").toDate(),
        // }}
        customButtons={
          {
            // myCustomButton: {
            //   text: "click",
            //   click: function () {
            //     testValue == "test" ? setTestValue("test1") : setTestValue("test");
            //   },
            // },
          }
        }
        eventClick={(event) => {
          if (event.event.extendedProps.type == "issueSchedule") return;
          setSearchParams({
            issueId: event.event.id,
          });
        }}
        headerToolbar={{
          // left: "",
          center: "",
          right: "",
        }}
        editable={true}
        selectable={true}
        expandRows={true}
        height="calc(100% - 50px)"
        slotDuration="01:00:00"
        slotLabelFormat={{
          hour: "numeric",
          minute: "2-digit",
          // omitZeroMinute: true,
          meridiem: "narrow",
        }}
        allDayText={""}
        select={props.select}
        events={props.events}
        // weekends={false}
        {...props}
      />
      <div
        style={{ border: "1px solid var(--color-layout-divider)", borderTop: "none", overflow: "hidden scroll" }}
        className=""
      >
        <table style={{ width: "100%" }}>
          <tr>
            <td style={{ width: "45px" }} />
            {props.additionalCells.map((cell) => (
              <td
                key={cell.id}
                style={{ borderLeft: "1px solid var(--color-layout-divider)", width: "calc((100% - 45px) / 7)" }}
              >
                <div style={{ textAlign: "center", fontSize: "10px", color: "#8E939D" }}>
                  {t("parse:time_spent_on_tasks")}
                </div>
                <div style={{ textAlign: "center", fontSize: "10px", fontWeight: "bold" }}>{cell.data}</div>
              </td>
            ))}
          </tr>
        </table>
      </div>
      {props.isLoading && (
        <div
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            zIndex: 100,
            background: "rgba(0, 0, 0, 0.15)",
            borderRadius: "8px",
          }}
          className="d-flex align-center justify-center"
        >
          <Spin size="large" spinning={true} />
        </div>
      )}
    </div>
  );
}

export const FullCalendarView = observer(FullCalendarViewToExport);
