import {
  autoformatArrow,
  autoformatLegal,
  autoformatLegalHtml,
  autoformatMath,
  autoformatPunctuation,
  autoformatSmartQuotes,
} from "@udecode/plate";
import { MyAutoformatRule } from "../components/Autoformat/plateTypes";
import { autoformatBlocks } from "../components/Autoformat/transforms/autoformatBlocks";
import { autoformatLists } from "../components/Autoformat/transforms/autoformatLists";
import { autoformatMarks } from "../components/Autoformat/transforms/autoformatMarks";

export const autoformatRules = [
  ...autoformatBlocks,
  ...autoformatLists,
  ...autoformatMarks,
  ...(autoformatSmartQuotes as MyAutoformatRule[]),
  ...(autoformatPunctuation as MyAutoformatRule[]),
  ...(autoformatLegal as MyAutoformatRule[]),
  ...(autoformatLegalHtml as MyAutoformatRule[]),
  ...(autoformatArrow as MyAutoformatRule[]),
  ...(autoformatMath as MyAutoformatRule[]),
];
