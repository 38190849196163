import React, { useEffect, useRef, useState } from "react";
import { FullCalendarView } from "./FullCalendarView";
import { CalendarOptions } from "@fullcalendar/common";
import { DateSelectArg, EventDropArg, EventInput, EventSourceInput, ViewMountArg } from "@fullcalendar/react";
import { api } from "../../../services";
import { IssueDto, IssueScheduleDto } from "../../../api";
import dayjs from "dayjs";
import { EventResizeDoneArg } from "@fullcalendar/interaction";
import { useLocation, useSearchParams } from "react-router-dom";
import { useRootStore } from "../../../hooks";
import { getDaysArrayBetweenTwoDates, getObjectFromTimeSpan } from "../../../helpers/dateFunctions";

interface IFullCalendar extends CalendarOptions {
  weekStartDay?: number;
  executorUserId?: number;

  onTaskCreateClick?: () => void;
}

export interface IFullCalendarAdditionalCell {
  id: number;
  data: string | number;
}

export function FullCalendar(props: IFullCalendar) {
  const isCalendarMounted = useRef<boolean>(false);

  const { search } = useLocation();
  const { issueInitDataStore } = useRootStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const [additionalCells, setAdditionalCells] = useState<IFullCalendarAdditionalCell[]>([
    { id: 0, data: 0 },
    { id: 1, data: 0 },
    { id: 2, data: 0 },
    { id: 3, data: 0 },
    { id: 4, data: 0 },
    { id: 5, data: 0 },
    { id: 6, data: 0 },
  ]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [events, setEvents] = useState<EventInput[]>([]);
  const date = useRef<{ from: Date; to: Date }>({
    from: new Date(),
    to: new Date(),
  });

  const handleEventResize = async (eventInfo: EventResizeDoneArg) => {
    const issue = events.find((is) => Number(is.id) == Number(eventInfo.event.id))?.extendedProps
      ?.issueData as IssueDto;
    if (issue == null) {
      return;
    }
    const dateFrom = new Date(eventInfo.event.startStr);
    const dateTo = new Date(eventInfo.event.endStr);
    setIsLoading(true);
    const r = await api.issue.edit(issue.id as number, {
      ...issue,
      dateWorkStart: dateFrom.toISOString(),
      timePlanFromDateDeadline: dateTo.toISOString(),
    });
    setIsLoading(false);
    if (r == null) {
      return;
    }
  };

  // EventDragStopArg
  const handleEventDrop = async (eventInfo: EventDropArg) => {
    const issue = events.find((is) => Number(is.id) == Number(eventInfo.event.id))?.extendedProps
      ?.issueData as IssueDto;
    if (issue == null) {
      return;
    }
    const dateFrom = new Date(eventInfo.event.startStr);
    const dateTo = dayjs(dateFrom)
      .add(Math.abs(dayjs(issue.dateDeadlineCalculated).diff(dayjs(issue.dateWorkStart), "minute")), "minute")
      .toDate();
    setIsLoading(true);
    const r = await api.issue.edit(issue.id as number, {
      ...issue,
      dateWorkStart: dateFrom.toISOString(),
      timePlanFromDateDeadline: dateTo.toISOString(),
    });
    setIsLoading(false);
    if (r == null) {
      return;
    }
  };

  const handleDateSelect = (selectInfo: DateSelectArg) => {
    issueInitDataStore.setIssueData({
      ...issueInitDataStore.getIssueData,
      dateWorkStart: selectInfo.startStr,
      dateDeadline: selectInfo.endStr,
    });
    props.onTaskCreateClick != null
      ? props.onTaskCreateClick()
      : setSearchParams({
          taskCreate: "true",
        });
  };

  const handleViewMount = async ({ el, view }: ViewMountArg) => {
    // view.calendar.setOption("visibleRange", {
    //   start: dayjs().add(14, "day").toDate(),
    //   end: dayjs().add(21, "day").toDate(),
    // });
    date.current = {
      from: view.activeStart,
      to: view.activeEnd,
    };
    await parseEvents();
    isCalendarMounted.current = true;
  };

  const parseEvents = async () => {
    setIsLoading(true);
    const rIssue = await api.issue.getAll({
      customFilterFrom: date.current.from.toISOString(),
      customFilterTo: date.current.to.toISOString(),
      pageSize: 100,
      planActive: true,
      executorUserId: props.executorUserId,
    });
    const rIssueSchedule = await api.issueSchedule.getAll({
      dateReleaseFrom: date.current.from.toISOString(),
      dateReleaseTo: date.current.to.toISOString(),
      pageSize: 100,
      executorUserId: props.executorUserId,
    });
    setIsLoading(false);
    if (rIssue == null || rIssueSchedule == null) {
      return;
    }
    const eventsArrayIssue: EventSourceInput = rIssue.items
      .filter((e) => e.dateWorkStart != null && e.dateDeadlineCalculated != null)
      .map((e) => ({
        id: e.id!.toString(),
        title: e.name ?? "",
        allDay: isEventFullDay(e, "issue"),
        start: new Date(e.dateWorkStart as string),
        end: new Date(e.dateDeadlineCalculated as string),
        backgroundColor:
          e.currentStatus?.boardStatus?.baseStatusKey == "done"
            ? "var(--color-success-base)"
            : "var(--color-primary-base)",
        // borderColor: "var(--color-primary-weak)",
        extendedProps: {
          issueData: e,
          type: "issue",
        },
      }));
    const eventsArrayIssueSchedule: EventSourceInput = rIssueSchedule.items
      .filter((e: IssueScheduleDto) => e.dateRelease != null && e.recurringIssue?.timePlan != null)
      .map((e: IssueScheduleDto) => ({
        id: e.id!.toString(),
        title: e.recurringIssue?.name ?? "",
        allDay: isEventFullDay(e, "issueSchedule"),
        start: new Date(e.dateRelease as string),
        end: dayjs(new Date(e.dateRelease as string))
          .add(getObjectFromTimeSpan(e.recurringIssue?.timePlan as string).totalHours ?? 0, "hour")
          .add(getObjectFromTimeSpan(e.recurringIssue?.timePlan as string).minutes ?? 0, "minute")
          .toDate(),
        backgroundColor: "var(--color-primary-weak)",
        editable: false,
        extendedProps: {
          issueData: e,
          type: "issueSchedule",
        },
      }));
    // @ts-ignore
    setEvents([...eventsArrayIssue, ...eventsArrayIssueSchedule]);
    const eventDatesArray: Date[] = getDaysArrayBetweenTwoDates(date.current.from, date.current.to);
    const additionalCellsCopy = additionalCells;
    additionalCellsCopy.forEach((cell, index) => {
      let cellValue: number = 0;
      const cellDate = eventDatesArray[index];
      const cellIssues = rIssue.items
        .filter((e) => e.dateWorkStart != null && e.dateDeadline != null)
        .filter((issue) => dayjs(issue.dateWorkStart).isSame(dayjs(cellDate), "date"));
      cellIssues.forEach((issue) => {
        // cellValue += issue.timePlan != null ? Number(issue.timePlan) : 0;
        cellValue +=
          (issue.timePlan ?? "").trim().length > 0
            ? Number(getObjectFromTimeSpan(issue.timePlan as string).days ?? 0) * 24 +
              Number(getObjectFromTimeSpan(issue.timePlan as string).hours)
            : 0;
        // cellValue += Math.abs(dayjs(issue.dateWorkStart).diff(issue.dateDeadline, "hour"));
      });
      cell.data = cellValue;
    });
    setAdditionalCells([...additionalCellsCopy]);
  };

  const isEventFullDay = (issue: IssueDto | IssueScheduleDto, type: "issue" | "issueSchedule"): boolean => {
    if (type == "issue") {
      // TODO: Check
      return (
        Math.abs(
          dayjs((issue as IssueDto).dateWorkStart).diff(
            // @ts-ignore
            dayjs(issue.dateDeadlineCalculated != null ? issue.dateDeadlineCalculated : issue.dateDeadline),
            "hour"
          )
        ) > 24
      );
    } else {
      return (
        Math.abs(
          dayjs((issue as IssueScheduleDto).dateRelease).diff(
            // @ts-ignore
            dayjs(issue.dateDeadlineCalculated != null ? issue.dateDeadlineCalculated : issue.dateDeadline),
            "hour"
          )
        ) > 24
      );
    }
  };

  useEffect(() => {
    if (!isCalendarMounted.current) return;
    setTimeout(() => {
      parseEvents();
    }, 100);
  }, [search]);

  return (
    <div className="full-height">
      {/*events={events}*/}
      <FullCalendarView
        {...props}
        events={events}
        additionalCells={additionalCells}
        viewDidMount={handleViewMount}
        eventDrop={handleEventDrop}
        eventResize={handleEventResize}
        select={handleDateSelect}
        isLoading={isLoading}
      />
    </div>
  );
}
