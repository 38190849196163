import React, { useEffect, useRef, useState } from "react";
import { WaveFormView } from "./WaveFormView";
import { client } from "../../../plugins/client";
import "./WaveForm.scss";

interface IWaveForm {
  height?: number;
  audioSrc?: string | AudioBuffer | null;
  progress?: number;
  // waveStyle?: WaveFormWaveStyle;

  onSeek?: (progress: number) => void;
}

export interface IWaveFormState {
  // isDragging?: boolean;
  // progress: number;
  // resizingTimeoutId?: string | number;
  // height: number | string;
  // width: number | string;
  // buffer: AudioBuffer | null;
}

export function WaveForm(props: IWaveForm) {
  // @ts-ignore
  const audioContext = new (window.AudioContext || window.webkitAudioContext)();

  const waveFormWrapperRef = useRef<HTMLDivElement | null>(null);
  const waveFormCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const [displayableChunks, setDisplayableChunks] = useState<number>(60);
  const [audioBuffer, setAudioBuffer] = useState<AudioBuffer | null>(
    typeof props.audioSrc == "string" ? null : props.audioSrc ?? null
  );
  const [waveValues, setWaveValues] = useState<number[]>([]);

  const generateAudioBuffer = async (audioSrc: string | AudioBuffer | null): Promise<AudioBuffer | null> => {
    if (typeof audioSrc !== "string") {
      setAudioBuffer(audioSrc);
      return audioSrc;
    }
    const audioDataFromApi = await client.get(audioSrc, {
      responseType: "arraybuffer",
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "arraybuffer",
      },
    });
    if (audioDataFromApi == null) {
      console.log("Error while getting audio from API");
      setAudioBuffer(null);
      return null;
    }

    await audioContext.decodeAudioData(audioDataFromApi.data, (buffer: AudioBuffer) => {
      setAudioBuffer(buffer);
      return buffer;
    });

    return null;
  };

  const calculateWaveValues = (buffer: AudioBuffer | null): number[] => {
    if (buffer == null) {
      setWaveValues([]);
      return [];
    }
    let _sT = performance.now();

    const audioData = buffer.getChannelData(0).filter((_, i) => i % 64 === 0);
    const sum = (arr: number[]) => arr.reduce((acc, cur) => acc + cur);
    let waveAmplitudeValues = [];
    for (let i = 0; i < audioData.length; i++) {
      const amplitude = Math.abs(audioData[i]);
      waveAmplitudeValues.push(amplitude);
    }
    const wavePeaks = waveAmplitudeValues.filter((point) => point >= 0);
    const wavePeaksRatio = ((props.height ?? 24) - 2) / [...wavePeaks].sort((a, b) => a - b)[wavePeaks.length - 1];
    waveAmplitudeValues = wavePeaks.map((point) => Math.round(point * wavePeaksRatio));
    const itemsInChunk = Math.floor(waveAmplitudeValues.length / displayableChunks);

    const chunkFill = (chunkNumber: number, chunkSize: number, arr: number[]): number[] => {
      const len = arr.length;
      let idx = 0;
      let idy = 0;
      const result = [];

      while (idx < len) {
        if (idy >= arr.length) {
          break;
        }
        let chunk;
        if (idx === len - 1) {
          chunk = arr.slice(idy);
        } else {
          chunk = arr.slice(idy, (idy += chunkSize));
        }
        const ampVal = Math.round(sum(chunk) / chunk.length + 3);
        result.push(ampVal % 2 ? ampVal : ampVal + 1);
        idx += 1;
      }
      return result;
    };

    const res = chunkFill(displayableChunks, itemsInChunk, waveAmplitudeValues) ?? [];
    setWaveValues(res);
    let _eT = performance.now();
    console.log(
      `Call to calculateWaveValues took ${_eT - _sT} milliseconds (${((_eT - _sT) / 1000).toFixed(4)} seconds)`
    );
    return res;
  };

  interface IPointCoordinates {
    index: number;
    pointWidth: number;
    pointMargin: number;
    canvasHeight: number;
    amplitude: number;
  }
  const pointCoordinates = ({ index, pointWidth, pointMargin, canvasHeight, amplitude }: IPointCoordinates) => {
    const pointHeight = Math.round(amplitude / (canvasHeight / 100) - 12);
    // const pointHeight = amplitude;
    // const pointHeight = Math.round((amplitude / 100) * canvasHeight);
    const verticalCenter = Math.round(canvasHeight / 2 - pointHeight / 2);
    // const verticalCenter = Math.round((canvasHeight - pointHeight) / 2);
    return [
      index * (pointWidth + pointMargin), // x starting point
      verticalCenter, // y starting point
      pointWidth, // width
      pointHeight, // height
    ];
  };

  const drawCanvas = () => {
    const devicePixelRatio = window.devicePixelRatio;
    const ref = waveFormCanvasRef.current;
    const wrapperRef = waveFormWrapperRef.current;
    if (ref == null || wrapperRef == null) return;
    ref.width = wrapperRef.clientWidth * devicePixelRatio;
    ref.height = wrapperRef.clientHeight * devicePixelRatio;
    ref.style.width = wrapperRef.clientWidth + "px";
    ref.style.height = wrapperRef.clientHeight + "px";
    ref.getContext("2d")?.scale(devicePixelRatio, devicePixelRatio);
    const ctx = ref.getContext("2d");
    ctx?.clearRect(0, 0, ref.width, ref.height);
    // console.log(ref.width, ref.height, waveValues.length);
    waveValues.forEach((v, i) => {
      ctx?.beginPath();
      const coordinates = pointCoordinates({
        index: i,
        pointWidth: 3,
        pointMargin: 3,
        canvasHeight: props.height ?? 24,
        amplitude: v,
      });
      // console.log(coordinates);
      ctx?.roundRect(coordinates[0], coordinates[1], coordinates[2], coordinates[3], 8);
      // const withinHover = hoverXCoord >= coordinates[0]
      // const alreadyPlayed = i < playingPoint
      // if (withinHover) {
      //   ctx.fillStyle = alreadyPlayed ? '#94b398' : '#badebf'
      // } else if (alreadyPlayed) {
      //   ctx.fillStyle = '#228741'
      // } else {
      // ctx != null && (ctx.fillStyle = "#9ba6c233");
      // if (i > 4) {
      //   ctx != null && (ctx.fillStyle = "#9ba6c233");
      // } else {
      //   ctx != null && (ctx.fillStyle = "#00000026");
      // }
      ctx != null && (ctx.fillStyle = "#5672ff");
      // }
      ctx?.fill();
    });
  };

  useEffect(() => {
    drawCanvas();
  }, [waveValues]);

  useEffect(() => {
    // Redraw Canvas
  }, [displayableChunks]);

  useEffect(() => {
    calculateWaveValues(audioBuffer);
  }, [audioBuffer, displayableChunks]);

  useEffect(() => {
    generateAudioBuffer(props.audioSrc ?? null);
  }, [props.audioSrc]);

  useEffect(() => {
    if (waveFormWrapperRef.current == null) return;
    setDisplayableChunks(
      waveFormWrapperRef.current?.clientWidth != null ? Math.round(waveFormWrapperRef.current?.clientWidth / 6) : 60
    );
  }, []);

  return (
    <div
      className="uiKit-waveForm"
      ref={(wrapper) => (waveFormWrapperRef.current = wrapper)}
      style={{
        height: props.height ?? 24,
        position: "relative",
        cursor: "pointer",
        pointerEvents: waveValues.length == 0 ? "none" : "all",
      }}
    >
      <canvas
        ref={(canvas) => (waveFormCanvasRef.current = canvas)}
        className={`full-width full-height ${waveValues.length > 0 ? "" : "d-none"}`}
      />
      {waveValues.length == 0 && (
        <div className="__loader full-width full-height">
          <div />
          <div />
          <div />
        </div>
      )}
      <div
        style={{
          // display: props.onProgressChange != null ? "block" : "none",
          pointerEvents: "none",
          position: "absolute",
          right: 0,
          top: 0,
          height: "100%",
          width: `${100 - (props.progress ?? 100)}%`,
          backdropFilter: "grayscale(0.9)",
          transition: "all 0.2s",
          // backgroundColor: "var(--color-primary-strong)",
          // backgroundColor: "var(--color-primary-base)",
          // backgroundColor: "rgba(0, 0, 0, 0.5)",
          // backgroundColor: "black",
          // opacity: 0.4,
          // mixBlendMode: "hue",
        }}
      />
    </div>
  );

  return (
    <WaveFormView
      waveFormCanvasRef={waveFormCanvasRef}
      // state={{ ...state, progress: props.progress ?? 0 }}
      // onMouseMove={handleMouseMove}
      // onMouseUp={handleMouseUp}
      // onMouseDown={handleMouseDown}
      // onSeek={props.onProgressChange}
    />
  );
}
