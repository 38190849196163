import {
  ELEMENT_DEFAULT,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_PARAGRAPH,
  getAboveNode,
  getPlateActions,
  getPlateStore,
  MentionElement,
  TDescendant,
  TEditor,
  TNodeEntry,
  toggleNodeType,
  usePlateEditorState,
  Value,
} from "@udecode/plate";
import { TElement } from "@udecode/plate-core";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useFocused, useSelected } from "slate-react";
import "tippy.js/dist/tippy.css";
import { getObjectFromTimeSpan } from "../../../helpers/dateFunctions";
import { formatDateToDateString } from "../../../helpers/formatFunctions";
import { api } from "../../../services";
import { initialContent } from "../../../utils/textEditor";
import { TAnchorElement } from "./components/Anchor/AnchorElement";
import { ELEMENT_ANCHOR } from "./components/Anchor/createAnchorPlugin";
import { MentionPropsElement } from "./components/Mention/MentionPropsElement";
import { ChildType, NodeType, ToolbarType } from "./misc/consts";
import { Base64NormalImagesPair, ITextEditor } from "./TextEditor.interface";
import { TextEditorView } from "./TextEditorView";
import { usePlugins } from "./utils/usePlugins";

export const TextEditorContainer = forwardRef((props: ITextEditor, ref) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [base64AndNormalUrlsPairs, setBase64AndNormalUrlsPairs] = useState<Base64NormalImagesPair[]>([]);
  const base64AndNormalUrlsPairsCopy = useRef<Base64NormalImagesPair[]>([]);
  const [currentNode, setCurrentNode] = useState<TNodeEntry<TElement | TEditor> | undefined>(undefined);
  const [headingValue, setHeadingValue] = useState<string>(ELEMENT_PARAGRAPH);
  const editor = usePlateEditorState(props.id);

  const currentValue = getPlateStore(props.id).store.getState().editor?.getFragment();
  const reset = getPlateActions(props.id);
  const isSelected = useSelected();
  const isFocused = useFocused();
  const mentionPlugin = {
    key: "@",
    component: MentionElement,
    props: () => {
      const renderLabel = (element: any) => (
        <MentionPropsElement element={element} isFocused={isFocused} isSelected={isSelected} />
      );
      return {
        renderLabel: renderLabel,
      };
    },
    options: {
      trigger: "@",
      createMentionNode: (item: any) => {
        return {
          id: item.key,
        };
      },
    },
  };
  const plugins = usePlugins(props.readOnly ?? false, mentionPlugin);

  const handleContentClear = () => {
    const point = { path: [0, 0], offset: 0 };
    if (editor) {
      editor.selection = { anchor: point, focus: point };
      editor.history = { redos: [], undos: [] };
      editor.children = initialContent;
      editor.onChange();
      setHeadingValue(ELEMENT_PARAGRAPH);
    }
  };

  const handleEditorReset = () => {
    reset.resetEditor();
  };

  const handleSyncChildrenAndValue = () => {
    if (editor) {
      editor.children = props.value as Value;
    }
  };

  const handleHeadingValueChange = (value: string) => {
    if (currentValue || currentNode) {
      if (value == ELEMENT_H1) {
        currentValue && (currentValue[0].children as TElement[]).forEach((el: TElement) => (el.fontSize = "20pt"));
        currentNode && (currentNode[0].children as TElement[]).forEach((el: TElement) => (el.fontSize = "20pt"));
      }
      if (value == ELEMENT_H2) {
        currentValue && (currentValue[0].children as TElement[]).forEach((el: TElement) => (el.fontSize = "16pt"));
        currentNode && (currentNode[0].children as TElement[]).forEach((el: TElement) => (el.fontSize = "16pt"));
      }
      if (value == ELEMENT_H3) {
        currentValue && (currentValue[0].children as TElement[]).forEach((el: TElement) => (el.fontSize = "14pt"));
        currentNode && (currentNode[0].children as TElement[]).forEach((el: TElement) => (el.fontSize = "14pt"));
      }
      if (value == ELEMENT_PARAGRAPH) {
        currentValue && (currentValue[0].children as TElement[]).forEach((el: TElement) => (el.fontSize = "11pt"));
        currentNode && (currentNode[0].children as TElement[]).forEach((el: TElement) => (el.fontSize = "11pt"));
      }
    }
    editor && toggleNodeType(editor, { activeType: value, inactiveType: ELEMENT_DEFAULT });
    setHeadingValue(value);
  };

  const handleEditorClick = () => {
    if (editor) {
      const node = getAboveNode(editor);
      setCurrentNode(node);
    }
    props.toolbarType == ToolbarType.Dynamic && setIsVisible(true);
  };

  const handleIsVisibleToolbarCheck = () => {
    if (props.toolbarType == ToolbarType.Hidden) return false;
    if (props.toolbarType == ToolbarType.Dynamic && isVisible) return true;
    if (props.toolbarType == ToolbarType.Dynamic && !isVisible) return false;
    if (props.toolbarType != ToolbarType.Dynamic && props.toolbarType != ToolbarType.Hidden) return true;
  };

  const elementWithAnchor: TAnchorElement | undefined =
    props.value && props.value.find((v) => v.type == ELEMENT_ANCHOR);

  useImperativeHandle(ref, () => ({
    clear: handleContentClear,
    reset: handleEditorReset,
    syncValue: handleSyncChildrenAndValue,
  }));

  const setBase64AndNormalUrlsPairsSync = (v: Base64NormalImagesPair[]) => {
    base64AndNormalUrlsPairsCopy.current = v;
    setBase64AndNormalUrlsPairs(base64AndNormalUrlsPairsCopy.current);
  };

  const b64toBlob = (base64: string) =>
    fetch(base64)
      .then((res) => res.blob())
      .catch(() => null);

  const parseValueToFindImages = useCallback(() => {
    // Собираем массив Base64-картинок из props.value
    const base64ImagesArr = props.value
      ?.filter((v) => v.type == "img")
      ?.filter((v) => (v?.url as string | undefined)?.includes(";base64"))
      ?.filter((v) => !base64AndNormalUrlsPairsCopy.current.some((c) => c.base64 == v?.url));
    if (base64ImagesArr?.length == 0) return;
    const vImagesArrPairs: Base64NormalImagesPair[] = (base64ImagesArr ?? []).map((v) => ({
      isUploading: false,
      url: "",
      base64: v.url as string,
    }));
    setBase64AndNormalUrlsPairsSync([...base64AndNormalUrlsPairsCopy.current, ...vImagesArrPairs]);
  }, [props.value]);

  const uploadBase64Images = useCallback(async () => {
    for await (const imagesPair of base64AndNormalUrlsPairsCopy.current) {
      // Проверяем, не загружается ли уже картинка, или уже загружена
      if (imagesPair.isUploading || imagesPair.url.length > 0) continue;
      // Изменяем статус загрузки картинки
      setBase64AndNormalUrlsPairsSync([
        ...base64AndNormalUrlsPairsCopy.current.map((p) =>
          p.base64 == imagesPair.base64 ? { ...p, isUploading: true } : p
        ),
      ]);
      // Загружаем картинку
      const base64ToBlob = await b64toBlob(imagesPair.base64);
      if (base64ToBlob == null) continue;
      let formData = new FormData();
      formData.append("files", base64ToBlob as any, `image.${base64ToBlob?.type.split("/")?.[1]}`);
      const r = await api.staticFile.upload(formData, "image");
      if (r == null) continue;
      // Изменяем статус загрузки картинки и заменяем URL
      setBase64AndNormalUrlsPairsSync([
        ...base64AndNormalUrlsPairsCopy.current.map((p) =>
          p.base64 == imagesPair.base64 ? { ...p, isUploading: false, url: (r[0].url ?? "") as string } : p
        ),
      ]);
    }
  }, []);

  const syncChildrenAndValue = useCallback(() => {
    if (editor && editor?.children != null) {
      // @ts-ignore
      editor.children = editor.children.map((c) => {
        if (c?.url == null) return c;
        const imageToReplace = base64AndNormalUrlsPairsCopy.current
          .filter((i) => !i.isUploading && i.url.length > 0)
          .find((i) => i.base64 == c?.url);
        if (imageToReplace == null) return c;
        return { ...c, url: imageToReplace.url };
      });
    }
  }, [editor]);

  const changeImagesInValueFromBase64ToNormalLinks = useCallback(async () => {
    const imagesReadyToChange = base64AndNormalUrlsPairsCopy.current
      .filter((i) => !i.isUploading && i.url.length > 0)
      .filter((i) => props.value?.some((v) => v?.url == i.base64));
    if (imagesReadyToChange.length == 0) return;
    props.onChange &&
      props.onChange(
        props.value?.map((v) =>
          imagesReadyToChange.some((i) => i.base64 == v?.url)
            ? { ...v, url: imagesReadyToChange.find((i) => i.base64 == v?.url)?.url }
            : v
        ) as Value
      );
    syncChildrenAndValue();
  }, [props, syncChildrenAndValue]);

  const handleAddedPropertyByType = (type: string, child: TDescendant) => {
    if (type == NodeType.Text || type == NodeType.Link || type == NodeType.Name) {
      switch (child.type) {
        case ChildType.Deleted:
          return {
            ...child,
            backgroundColor: "var(--color-error-weaker)",
            borderRadius: "4px",
            padding: "2px",
          };
        case ChildType.Added:
          return { ...child, backgroundColor: "var(--color-success-weaker)", borderRadius: "4px", padding: "2px" };
        default:
          return { ...child };
      }
    }
    if (type == NodeType.Date) {
      switch (child.type) {
        case ChildType.Deleted:
          return {
            ...child,
            backgroundColor: "var(--color-error-weaker)",
            // backgroundColor: "rgba(255, 180, 180, 0.4)",
            text: formatDateToDateString(child.text as Date, "L HH:mm"),
            borderRadius: "4px",
            padding: "2px",
          };
        case ChildType.Added:
          return {
            ...child,
            backgroundColor: "var(--color-success-weaker)",
            text: formatDateToDateString(child.text as Date, "L HH:mm"),
            borderRadius: "4px",
            padding: "2px",
          };
        default:
          return { ...child };
      }
    }
    if (type == NodeType.Time) {
      switch (child.type) {
        case ChildType.Deleted:
          return {
            ...child,
            backgroundColor: "var(--color-error-weaker)",
            text: formatDateToDateString(getObjectFromTimeSpan(child.text as string)?.dateObject as Date, "HH:mm"),
            borderRadius: "4px",
            padding: "2px",
          };
        case ChildType.Added:
          return {
            ...child,
            backgroundColor: "var(--color-success-weaker)",
            // backgroundColor: "rgba(161, 251, 119, 0.4)",
            text: formatDateToDateString(getObjectFromTimeSpan(child.text as string)?.dateObject as Date, "HH:mm"),
            borderRadius: "4px",
            padding: "2px",
          };
        default:
          return { ...child };
      }
    }
    if (type == NodeType.Switch) {
      switch (child.type) {
        case ChildType.Deleted:
          return { ...child, color: "var(--color-error-base)" };
        // return { ...child, color: "#FD5A44" };
        case ChildType.Added:
          return { ...child, color: "var(--color-success-base)" };
        // return { ...child, color: "#00B894" };
        default:
          return { ...child };
      }
    }
  };

  const handleGenerateChild = (index: number, value: TDescendant, type: string) => {
    if (type == NodeType.User || type == NodeType.Orgchart || type == NodeType.Role) {
      return index == 1
        ? { ...value, margin: "0 8px 0 8px" }
        : (value.children as TDescendant[])?.[0].type == ChildType.Deleted
        ? { ...value, backgroundColor: "var(--color-error-weaker)", borderRadius: "4px", padding: "2px" }
        : // ? { ...value, backgroundColor: "rgba(255, 180, 180, 0.4)", borderRadius: "4px", padding: "2px" }
          { ...value, backgroundColor: "var(--color-success-weaker)", borderRadius: "4px", padding: "2px" };
      // : { ...value, backgroundColor: "rgba(161, 251, 119, 0.4)", borderRadius: "4px", padding: "2px" };
    }
    return index == 1 ? { ...value, margin: "0 8px 0 8px" } : handleAddedPropertyByType(type, value);
  };

  const handleGetValueByType = (value: TElement) => {
    switch (value.type) {
      case NodeType.Text:
        return {
          ...value,
          children: value.children.map((c) => handleAddedPropertyByType(NodeType.Text, c)),
        };
      case NodeType.Link:
        return {
          ...value,
          children: value.children.map((c, index) =>
            index == 1
              ? { ...c, margin: "0 8px 0 8px" }
              : {
                  ...c,
                  // @ts-ignore
                  children: c.children.map((v) => handleAddedPropertyByType(NodeType.Link, v)),
                }
          ),
        };
      case NodeType.Date:
      case NodeType.Time:
      case NodeType.Switch:
      case NodeType.User:
      case NodeType.Orgchart:
      case NodeType.Role:
      case NodeType.Name:
        return {
          ...value,
          children: value.children.map((c, index) => handleGenerateChild(index, c, value.type)),
        };
      default:
        return value;
    }
  };

  const convertValueByType = () => props.value?.map((v) => handleGetValueByType(v));

  useEffect(() => {
    if (currentNode) {
      if (currentNode[0].type == ELEMENT_H1 || currentNode[0].type == ELEMENT_H2 || currentNode[0].type == ELEMENT_H3) {
        setHeadingValue(currentNode[0].type as string);
      } else {
        setHeadingValue(ELEMENT_PARAGRAPH);
      }
    }
  }, [currentNode]);

  useEffect(() => {
    if (elementWithAnchor && props.anchorKey) {
      setTimeout(() => {
        const element = document.getElementById(props.anchorKey as string);
        element && element.scrollIntoView({ behavior: "smooth" });
      }, 1500);
    }
  }, [elementWithAnchor, props.anchorKey]);

  useEffect(() => {
    props.id == "add-proof-editor-with-comment" && getPlateActions(props.id).resetEditor();
  }, [props.id]);

  useEffect(() => {
    parseValueToFindImages();
    // Mb remove
    syncChildrenAndValue();
    // convertValueByType();
  }, [parseValueToFindImages, syncChildrenAndValue]);

  useEffect(() => {
    parseValueToFindImages();
    // Mb remove
    syncChildrenAndValue();
    // convertValueByType();
  }, [parseValueToFindImages, props.value, syncChildrenAndValue]);

  useEffect(() => {
    if (base64AndNormalUrlsPairsCopy.current.filter((i) => !i.isUploading).length == 0) return;
    uploadBase64Images().then(() => changeImagesInValueFromBase64ToNormalLinks());
  }, [base64AndNormalUrlsPairs, uploadBase64Images, changeImagesInValueFromBase64ToNormalLinks]);

  // useEffect(() => {
  //   console.log(props.value, "value");
  //   console.log(editor?.children, "children");
  // }, [props.value]);

  useEffect(() => {
    if (editor && editor?.children != undefined) {
      // @ts-ignore
      editor.children = props.value;
    }
  }, []);

  return (
    <TextEditorView
      onHeadingValueChange={handleHeadingValueChange}
      onChange={props.onChange}
      initialValue={props.initialValue}
      id={props.id}
      anchorId={elementWithAnchor?.anchorId}
      headingValue={headingValue}
      isLoading={isLoading}
      isVisible={isVisible}
      onEditorClick={handleEditorClick}
      onIsVisibleToolbarCheck={handleIsVisibleToolbarCheck}
      onLoadingChange={setIsLoading}
      plugins={plugins}
      value={(convertValueByType() as Value) ?? []}
      height={props.height}
      toolbarType={props.toolbarType}
      placeholder={props.placeholder}
      readOnly={props.readOnly}
      autoFocus={props.autoFocus}
    />
  );
});
