import React from "react";
import { useNavigate } from "react-router-dom";
import ScrollTrigger from "../../../../../service/scrollTrigger/ScrollTrigger";
import { PlanningEmployeeCard } from "./planningEmployeeCard/PlanningEmployeeCard";
import { IPlanningEmployeesCards } from "./PlanningEmployeesCards.interface";
import { Divider } from "../../../../../uiKit";

export const PlanningEmployeesCards = (props: IPlanningEmployeesCards) => {
  const navigate = useNavigate();
  const handleRedirectToPlan = (planId: number) => {
    navigate(`/planning/work-plans/${planId}`);
  };

  return (
    <div className="br-md" style={{ overflow: "hidden" }}>
      {props.plans?.map((p, index) => (
        <React.Fragment key={p.id}>
          {index > 0 && <Divider />}
          <PlanningEmployeeCard
            key={p.id}
            plan={p}
            plansLength={props.plans?.length}
            onRedirectToPlan={handleRedirectToPlan}
          />
        </React.Fragment>
      ))}
      <ScrollTrigger
        fullWidth
        marginTop={!!props.plans?.length}
        disabled={props.isDone}
        hidden={props.isDone}
        onIntersection={props.loadNext}
      />
    </div>
  );
};
