import React from "react";
import { Collapse, CollapseProps } from "antd";
import "./Collapse.scss";
import clsx from "clsx";

interface ICollapseView extends CollapseProps {
  separated?: boolean;
}

export function CollapseView({ separated, className, ...rest }: ICollapseView) {
  return <Collapse className={clsx("uiKit-collapse", className ?? "", { __separated: separated })} {...rest} />;
}
