import React, { useState } from "react";
import AvatarCompanySettingsView from "./AvatarCompanySettingsView";

export interface IAvatarCompanySettings {
  size?: number;
  companyId?: number;
  imageUrl?: string;
  letter?: string;
  color?: string;

  onDeleteClick(): void;
  onUploadSuccess(id: number): void;
}

function AvatarCompanySettings(props: IAvatarCompanySettings) {
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState<boolean>(false);

  const handleUploadDialogClose = () => {
    setIsUploadDialogOpen(false);
  };

  const handleUploadDialogOpen = () => {
    setIsUploadDialogOpen(true);
  };

  const handleUploadDialogSuccess = (id: number) => {
    setIsUploadDialogOpen(false);
    props.onUploadSuccess(id);
  };

  const handleDeleteClick = () => {
    props.onDeleteClick();
  };

  return (
    <AvatarCompanySettingsView
      {...props}
      isUploadDialogOpen={isUploadDialogOpen}
      handleUploadDialogOpen={handleUploadDialogOpen}
      handleUploadDialogClose={handleUploadDialogClose}
      handleUploadDialogSuccess={handleUploadDialogSuccess}
      handleDeleteClick={handleDeleteClick}
    />
  );
}

export default AvatarCompanySettings;
