import React from "react";
import PageHeader from "../../../../layouts/pageHeader/PageHeader";
import PageContent from "../../../../layouts/pageContent/PageContent";
import { useRootStore } from "../../../../../../hooks";
import { MainHeader } from "../../headers/mainHeader/MainHeader";
import { useRouterHandler } from "../../../../../pages/regulation/hooks/useRouterHandler";
import { MainRegulationContent } from "../../pages";
import {IMainRegulation} from "./MainRegulation.interface";
import { observer } from "mobx-react-lite";

export const MainRegulationObserver: React.FC<IMainRegulation> = (props) => {
  const { regulationStore, breadcrumbsStore } = useRootStore();
  const { availableToolsKeys, toolsHide, showBreadcrumbs, getTypeContent } = useRouterHandler();
  const routeToken = breadcrumbsStore.getBreadcrumbsToken;

  return (
    <>
      <PageHeader>
        <MainHeader
          availableToolsKeys={availableToolsKeys}
          toolsHide={toolsHide}
          showBreadcrumbs={showBreadcrumbs}
          routeToken={routeToken}
        />
      </PageHeader>
      <PageContent tabs={props.tabs} isLoading={regulationStore.getLoading}>
        <MainRegulationContent regulationContentType={getTypeContent()} />
      </PageContent>
    </>
  );
};
export const MainRegulation = observer(MainRegulationObserver)