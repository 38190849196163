import React from "react";
import { useNavigate } from "react-router-dom";
import { ICardIssue } from "./CardIssue.interface";
import { IssueQueryKeys, IssueTypeKeys } from "../../../../../../constants/issueTypeKeys";
import { DraggableProvider } from "./DraggableProvider";
import { CardIssueView } from "./CardIssueView";

export const CardIssue = React.memo((props: ICardIssue) => {
  const navigate = useNavigate();

  const handleOpenIssue = () =>
    navigate({
      search: `?${IssueQueryKeys.issueId}=${props?.issue?.id}`,
    });

  const filling = <CardIssueView issue={props.issue} onClick={handleOpenIssue} outlined={props.outlined} />;
  return props.noDrag ? filling : <DraggableProvider draggableId={props.issue.id} children={filling} />;
});
