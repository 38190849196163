import React from "react";
import "./InputPhoneNumber.scss";
import PhoneInput, { PhoneInputProps } from "react-phone-input-2";
import { useTranslation } from "react-i18next";
import ru from "react-phone-input-2/lang/ru.json";
import { theme } from "antd";
import clsx from "clsx";

interface IInputPhoneNumberView extends PhoneInputProps {
  readOnly?: boolean;
}

export function InputPhoneNumberView({ readOnly, containerClass, ...rest }: IInputPhoneNumberView) {
  const { i18n } = useTranslation();
  const { useToken } = theme;
  const { token } = useToken();

  return (
    <PhoneInput
      containerClass={clsx("uiKit-inputPhoneNumber", containerClass ?? "", { "no-interact": readOnly })}
      // containerClass={css`
      //   border: 1px solid red;
      // `}
      containerStyle={{
        borderRadius: token.borderRadiusLG,
        height: "40px",
      }}
      buttonStyle={{
        borderRadius: `${token.borderRadiusLG}px 0 0 ${token.borderRadiusLG}px`,
      }}
      inputStyle={{
        background: token.colorBgContainer,
        border: `1px solid ${token.colorBorder}`,
        borderRadius: token.borderRadiusLG,
        color: token.colorText,
        width: "100%",
        height: "40px",
        fontSize: "16px",
      }}
      {...rest}
      localization={i18n.language.substr(0, 2) == "en" ? undefined : ru}
    />
  );
}
