import React from "react";
import "./Indicator.scss";
import { IIndicator, IndicatorIconsTypes } from "./Indicator.interface";
import { useColorSchemeStyles } from "../../../hooks/useColorSchemeStyles";
import { MdMail } from "@react-icons/all-files/md/MdMail";
import { BsFillExclamationDiamondFill } from "@react-icons/all-files/bs/BsFillExclamationDiamondFill";
import { IndicatorIconsKeys } from "../../modules/pages/communications/constants/keys";
import { Chip } from "../../uiKit";
import clsx from "clsx";
import { FiCheck } from "@react-icons/all-files/fi/FiCheck";
// import { FiMail } from "react-icons/all";

export const Indicator = (props: IIndicator): JSX.Element | null => {
  const { findColorByKey, findFaintColorByKey } = useColorSchemeStyles();
  const colorScheme = !props.isFaint ? findColorByKey(props.colorSchemeKey) : findFaintColorByKey(props.colorSchemeKey);
  // const colorScheme = !props.isFaint ? findColorByKey(props.colorSchemeKey) : findFaintColorByKey(props.colorSchemeKey);

  const chipClassNames = clsx("indicator", props.className, { indicator__midddle: props.isMiddleSize });
  const indicatorClassNames = clsx("indicator-icon", {
    "indicator-icon__midddle": props.isMiddleSize,
    __stroke: props.icon == IndicatorIconsKeys.CheckMark,
  });

  const handleGetIconColor = (iconType: IndicatorIconsTypes | undefined): string | undefined => {
    switch (iconType) {
      case IndicatorIconsKeys.CheckMark:
        return props.isFaint ? "var(--color-black)" : "var(--color-white)";
      case IndicatorIconsKeys.Warning:
        return props.isFaint ? "var(--color-black)" : "var(--color-white)";
      // return "var(--color-layout-fill-base)";
      case IndicatorIconsKeys.Message:
        return props.isFaint ? "var(--color-white)" : "var(--color-white)";
      // return "var(--color-layout-fill-base)";
    }
  };

  const handleGetIcon = (): JSX.Element | null => {
    switch (props.icon) {
      case IndicatorIconsKeys.CheckMark:
        return <FiCheck stroke={handleGetIconColor(IndicatorIconsKeys.CheckMark)} />;
      case IndicatorIconsKeys.Message:
        return <MdMail fill={handleGetIconColor(IndicatorIconsKeys.Warning)} />;
      case IndicatorIconsKeys.Warning:
        return <BsFillExclamationDiamondFill fill={handleGetIconColor(IndicatorIconsKeys.Message)} />;
      default:
        return null;
    }
  };

  return (
    <>
      {!props.name && props.icon ? (
        <div
          style={{ background: colorScheme.backgroundColor, color: colorScheme.textColor }}
          className={indicatorClassNames}
        >
          {handleGetIcon()}
        </div>
      ) : (
        <Chip
          // @ts-ignore TODO: Remove faint & dark
          type={props.colorSchemeKey}
          style={{ width: "fit-content" }}
          className={chipClassNames}
          label={props.name}
        />
      )}
    </>
  );
};
