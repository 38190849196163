import React from "react";
import { IRegulationIcons } from "./RegulationIcons.interface";
import { IconButton, Tooltip } from "../../../../../uiKit";
import { useTranslation } from "react-i18next";

export const AccessSettingsButton: React.FC<IRegulationIcons> = (props) => {

  const { t } = useTranslation();

  return (
    <Tooltip title={t("ui:tooltip.access_settings")}>
      <IconButton className="regulation-button-icons__access" disabled={props.disabled} onClick={props.onClick}>
        <svg width="27" height="20" viewBox="0 0 27 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path  d="M9.79761 10.0584C11.0952 10.0584 12.3396 9.5429 13.2571 8.62539C14.1746 7.70787 14.6901 6.46346 14.6901 5.1659C14.6901 3.86834 14.1746 2.62392 13.2571 1.70641C12.3396 0.788892 11.0952 0.273438 9.79761 0.273438C8.50005 0.273438 7.25564 0.788892 6.33812 1.70641C5.42061 2.62392 4.90515 3.86834 4.90515 5.1659C4.90515 6.46346 5.42061 7.70787 6.33812 8.62539C7.25564 9.5429 8.50005 10.0584 9.79761 10.0584ZM13.0593 5.1659C13.0593 6.03094 12.7156 6.86055 12.1039 7.47222C11.4923 8.0839 10.6627 8.42754 9.79761 8.42754C8.93257 8.42754 8.10296 8.0839 7.49129 7.47222C6.87961 6.86055 6.53597 6.03094 6.53597 5.1659C6.53597 4.30086 6.87961 3.47125 7.49129 2.85957C8.10296 2.24789 8.93257 1.90426 9.79761 1.90426C10.6627 1.90426 11.4923 2.24789 12.1039 2.85957C12.7156 3.47125 13.0593 4.30086 13.0593 5.1659ZM19.5825 18.2125C19.5825 19.8433 17.9517 19.8433 17.9517 19.8433H1.64352C1.64352 19.8433 0.0126953 19.8433 0.0126953 18.2125C0.0126953 16.5816 1.64352 11.6892 9.79761 11.6892C17.9517 11.6892 19.5825 16.5816 19.5825 18.2125ZM17.9517 18.2059C17.9501 17.8048 17.7006 16.5979 16.5949 15.4922C15.5316 14.429 13.5306 13.32 9.79761 13.32C6.06304 13.32 4.06365 14.429 3.00036 15.4922C1.89466 16.5979 1.64678 17.8048 1.64352 18.2059H17.9517Z"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M22.0287 5.16406C22.245 5.16406 22.4524 5.24997 22.6053 5.40289C22.7582 5.55581 22.8441 5.76321 22.8441 5.97947V8.4257H25.2903C25.5066 8.4257 25.714 8.51161 25.8669 8.66453C26.0199 8.81745 26.1058 9.02485 26.1058 9.24111C26.1058 9.45737 26.0199 9.66477 25.8669 9.81769C25.714 9.97061 25.5066 10.0565 25.2903 10.0565H22.8441V12.5028C22.8441 12.719 22.7582 12.9264 22.6053 13.0793C22.4524 13.2323 22.245 13.3182 22.0287 13.3182C21.8124 13.3182 21.605 13.2323 21.4521 13.0793C21.2992 12.9264 21.2133 12.719 21.2133 12.5028V10.0565H18.7671C18.5508 10.0565 18.3434 9.97061 18.1905 9.81769C18.0376 9.66477 17.9517 9.45737 17.9517 9.24111C17.9517 9.02485 18.0376 8.81745 18.1905 8.66453C18.3434 8.51161 18.5508 8.4257 18.7671 8.4257H21.2133V5.97947C21.2133 5.76321 21.2992 5.55581 21.4521 5.40289C21.605 5.24997 21.8124 5.16406 22.0287 5.16406Z"/>
        </svg>
      </IconButton>
    </Tooltip>
  );
};

