import React from "react";
import { BadgeView } from "./BadgeView";
import { BadgeProps } from "antd";

interface IBadge extends BadgeProps {
  blink?: boolean;
}

export function Badge(props: IBadge) {
  return <BadgeView {...props} />;
}
