import React from "react";
import PageHeader from "../../../../layouts/pageHeader/PageHeader";
import { HeaderBreadcrumbs } from "../../headers/headerBreadcrumbs/HeaderBreadcrumbs";
import PageContent from "../../../../layouts/pageContent/PageContent";
import { CreateSectionContent } from "../../pages";

export const CreateSection = () => {
  return (
    <>
      <PageHeader>
        <HeaderBreadcrumbs />
      </PageHeader>
      <PageContent contentPosition={{ maxWidth: "1200px" }}>
        <CreateSectionContent />
      </PageContent>
    </>
  );
};
