import { IssueType, IssueTypeKeys } from "../../../../constants/issueTypeKeys";
import { TFunction } from "react-i18next";
import { IssueDto, RoleShortDto, User2RoleDto } from "../../../../api";
import * as jsonpatch from "fast-json-patch";
import { getObjectFromTimeSpan } from "../../../../helpers/dateFunctions";

export interface IFormTypeConfig {
  leftColumnHeight: number;
  rightColumnHeight: number;
}

export interface IFormNotification {
  onCreate: string;
  onWithdraw: string;
  onDelete: string;
  onReject: string;
}

export const filterRolesByOrgchartId = (
  roles?: User2RoleDto[] | null,
  orgchartId?: number
): RoleShortDto[] | undefined =>
  roles?.filter((item) => item?.role?.orgchartId == orgchartId)?.map((item) => item.role!);

export const getDialogNotifications = (type: IssueType, t: TFunction<"translation", undefined>): IFormNotification => ({
  onCreate: t("notifier:success.task_created"),
  onWithdraw: t("notifier:success.task_withdraw"),
  onDelete: t("notifier:success.task_delete"),
  onReject: t("notifier:success.task_reject"),
});

export const getUpdateFormConfigByIssueType = (issueType: IssueType): IFormTypeConfig => ({
  leftColumnHeight: 750,
  rightColumnHeight: 725,
});

interface IIssueUpdater {
  update: () => Promise<void>;
  updatePartially: () => Promise<void>;
  setInitialState: (initialState: IssueDto) => void;
  applyChanges: (changes: any) => void;
  currentState: IssueDto | null;
  beforeState: IssueDto | null;
}

const triggerActionsFields = [
  "name",
  "timeFact",
  "timePlan",
  "dateDeadline",
  "dateWorkStart",
  "boardStatusId",
  "awaitedResult",
];

export const isEqualDates = (
  currentValue: string | undefined,
  newValue: Date | null,
  toGenerateTimeSpan?: boolean
): boolean => {
  if (!newValue && !currentValue) return true;
  if (toGenerateTimeSpan)
    return (
      currentValue ==
      (newValue ? (getObjectFromTimeSpan(newValue.toString()).dateObject ?? new Date()).toString() : null)
    );
  else return !newValue || !currentValue ? false : new Date(currentValue).toString() == newValue.toString();
};

export const getDeleteIssueTitle = (type: IssueTypeKeys) => "ui:title.delete_issue";

export const compareForRunAdditionalActions = (updater: IIssueUpdater) => {
  const prev = { ...updater.beforeState };
  const current = { ...updater.currentState };
  prev.calculated = undefined;
  current.calculated = undefined;
  prev.board = undefined;
  prev.createdByUser = undefined;
  prev.executorUser = undefined;
  prev.role = undefined;
  prev.fields = undefined;
  prev.attachments = undefined;
  // prev.type = undefined;
  prev.proof = undefined;
  prev.participants = undefined;
  prev.currentStatus = undefined;
  current.calculated = undefined;
  current.board = undefined;
  current.createdByUser = undefined;
  current.executorUser = undefined;
  current.role = undefined;
  current.fields = undefined;
  current.attachments = undefined;
  // current.type = undefined;
  current.proof = undefined;
  current.participants = undefined;
  current.currentStatus = undefined;
  return jsonpatch
    .compare(prev, current)
    .map((item) => item.path.split("/").find((item) => item !== ""))
    .some((item) => item && triggerActionsFields.includes(item));
};
