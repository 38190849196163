import React, { useContext, useEffect, useState } from "react";
import "./ListIssues.scss";
import { IListIssues, ISocketMessage } from "./ListIssues.interface";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import Visibility from "visibilityjs";
import { usePagingWithController } from "../../../../../../hooks/usePaging";
import { api } from "../../../../../../services";
import { BoardFilterContext } from "../../../../../../contexts/communication/boardFilterContext";
import { useIssueHub } from "../../../../../../signalr/hubs/issueHub";
import { useNotifier } from "../../../../../../hooks";
import { regeneratorFilters } from "../../common/regeneratorFilters";
import { IssueShortDto } from "../../../../../../api";
import { RealtimeIssuesKeys } from "../../../../../pages/communication/misc/consts";
import { ListIssuesView } from "./ListIssuesView";

const ListIssuesMemo = (props: IListIssues) => {
  const { t } = useTranslation();
  const notifier = useNotifier();

  const minute = 60 * 1000;

  const context = useContext(BoardFilterContext);
  const { connection, checkDisconnection } = useIssueHub();

  const [debounceReload, setDebounceReload] = useState<boolean>(false);

  const filterGenerator = regeneratorFilters({ ...context });

  const handleApiError = () => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });

  const handlerSuccessRequestEnd = (items: IssueShortDto[]) =>
    props.onChangeBoardColumnsState({ boardColumnId: props.boardStatus.id, isLoading: false });

  const issues = usePagingWithController(
    api.issueShort,
    {
      boardId: context.boardId ?? null,
      includeNextStatuses: true,
      boardStatusId: props.boardStatus?.id ?? null,
      isActive: true,
      isArchived: false,
      orgchartId: props.orgchartId,
      ...(context.userRelation && { userRelation: context.userRelation }),
      ...filterGenerator.getReqFilters(),
      ...filterGenerator.getReqSortFilters(),
    },
    { pageSize: -1 },
    undefined,
    handleApiError,
    handlerSuccessRequestEnd
  );

  const handleCompareBoardStatusById = (boardStatusId: number) => props.boardStatus.id === boardStatusId;

  const handlerSocketMessage = (message: ISocketMessage) => {
    if (message.boardId != context.boardId || !props.isOpen) return;
    if (message.updatedBoardStatusIds) {
      message.updatedBoardStatusIds.forEach((id) => {
        if (message.type == RealtimeIssuesKeys.Added || message.type == RealtimeIssuesKeys.Deleted) {
          if (handleCompareBoardStatusById(id)) setDebounceReload(true);
        } else if (handleCompareBoardStatusById(id) && message.issueId) setDebounceReload(true);
      });
    }
  };

  useEffect(() => {
    if (context.boardId && props.boardStatus?.id) {
      props.onChangeBoardColumnsState({ boardColumnId: props.boardStatus.id, isLoading: true });
      issues.reset();
      issues.restart();
    }
  }, [
    context.boardId,
    props.boardStatus?.id,
    context.userRelation,
    context.senderId,
    context.executorId,
    context.hasViolation,
    context.sortValue,
    context.flowType,
  ]);

  useEffect(() => {
    if (!connection) return;
    connection.on("IssueUpdate", handlerSocketMessage);
  }, [connection]);

  useEffect(() => {
    checkDisconnection() &&
      notifier.show({
        message: t("notifier:error.real_time_disconnect"),
        theme: "error",
        timeout: 90000000,
      });
  }, [connection?.state]);

  useEffect(() => {
    const visibilityHandlerId = Visibility.every(minute * 3, () => {
      //communicationsStore.resetOrgchartBoards();
      setDebounceReload(true);
    });

    /*document.addEventListener("visibilitychange", (e: Event) => {
      console.log(document.visibilityState);
      console.log(document.hidden);
    });*/

    return () => {
      Visibility.stop(visibilityHandlerId);
    };
  }, [
    context.userRelation,
    context.sortValue,
    context.boardId,
    context.senderId,
    context.hasViolation,
    context.executorId,
    context.flowType,
  ]);

  useEffect(() => {
    if (debounceReload) {
      setDebounceReload(false);
      props.onChangeBoardColumnsState({ boardColumnId: props.boardStatus.id, isLoading: true });
      issues.restart(true);
    }
  }, [debounceReload]);

  return (
    <ListIssuesView
      issues={issues.items}
      isLoading={issues.info.isLoading}
      loadNext={issues.loadNext}
      isDone={issues.info.isDone}
      boardStatus={props.boardStatus}
      totalItems={issues.info.totalItems}
    />
  );
};

export const ListIssues = React.memo(observer(ListIssuesMemo));
