import React, { useEffect, useState } from "react";
import CreateEditCompanyDialogView from "./CreateEditCompanyDialogView";
import { useNotifier } from "../../../../hooks";
import { useTranslation } from "react-i18next";
import { CompanyForAdminDto, CompanyModuleTypeDto } from "../../../../api";
import { api } from "../../../../services";

interface ICreateEditCompanyDialog {
  open: boolean;
  companyId?: number | null;

  onSave?: () => void;
  onCreate?: (data: CompanyForAdminDto) => void;
  onClose: () => void;
}

function CreateEditCompanyDialog(props: ICreateEditCompanyDialog) {
  const notifier = useNotifier();
  const { t } = useTranslation();
  const [companyModuleTypeArray, setCompanyModuleTypeArray] = useState<CompanyModuleTypeDto[]>([]);
  const [companyModuleTypeArrayActive, setCompanyModuleTypeArrayActive] = useState<number[]>([]);
  const [companyToEditData, setCompanyToEditData] = useState<CompanyForAdminDto | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [settingsState, setSettingsState] = useState<CompanyForAdminDto>({
    isPaid: false,
    isTest: false,
    contactName: "",
    contactPhoneNumber: "",
    uiType: 2,
    userLimit: null,
  });

  useEffect(() => {
    initializeCompanyModuleTypeArray();
  }, []);

  useEffect(() => {
    if (props.open) initializeCompanyToEdit();
  }, [props.open]);

  const initializeCompanyModuleTypeArray = async () => {
    setIsLoading(true);
    const r = await api.helper.companyModuleType();
    setIsLoading(false);
    if (r == null) {
      notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
      return;
    }
    setCompanyModuleTypeArray(r);
  };

  const initializeCompanyToEdit = async () => {
    if (props.companyId == null) return;
    setIsLoading(true);
    const r = await api.adminCompany.getById(props.companyId);
    setIsLoading(false);
    if (r == null) {
      notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
      return;
    }
    setCompanyModuleTypeArrayActive((r.modules ?? []).map((m) => m.type as number));
    setCompanyToEditData(r);
    setSettingsState({
      ...settingsState,
      isPaid: r.isPaid ?? false,
      isTest: r.isTest ?? false,
      uiType: r.uiType,
      contactName: r.contactName ?? "",
      contactPhoneNumber: r.contactPhoneNumber ?? "",
      userLimit: r.userLimit ?? null,
    });
  };

  const handleCreate = async () => {
    setIsLoading(true);
    const r = await api.adminCompany.create({
      ...settingsState,
      modules: companyModuleTypeArrayActive.map((at) => ({
        id: 0,
        type: at,
        isEnabled: true,
      })),
    });
    setIsLoading(false);
    notifier.showSuccessError(t("notifier:success.company_created"), t("notifier:error.company_created"), r != null);
    if (r != null) {
      props.onCreate && props.onCreate(r);
      handleClose();
    }
  };

  const handleSave = async () => {
    setIsLoading(true);
    const r = await api.adminCompany.edit(props.companyId as number, {
      ...companyToEditData,
      ...settingsState,
      modules: companyModuleTypeArrayActive.map((at) => ({
        id: 0,
        type: at,
        isEnabled: true,
      })),
    });
    setIsLoading(false);
    notifier.showSuccessError(t("notifier:success.company_edited"), t("notifier:error.company_edited"), r != null);
    if (r != null) {
      props.onSave && props.onSave();
      handleClose();
    }
  };

  const handleModuleTypeChange = (id: number, value: boolean) => {
    if (value) {
      setCompanyModuleTypeArrayActive([...companyModuleTypeArrayActive, id]);
    } else {
      setCompanyModuleTypeArrayActive(companyModuleTypeArrayActive.filter((at) => at != id));
    }
  };

  const handleClose = () => {
    props.onClose();
    setCompanyToEditData(null);
    setIsLoading(false);
    setCompanyModuleTypeArrayActive([]);
    setSettingsState({
      ...settingsState,
      isPaid: false,
      isTest: false,
      uiType: 2,
      contactName: "",
      contactPhoneNumber: "",
      userLimit: null,
    });
  };

  return (
    <CreateEditCompanyDialogView
      open={props.open}
      companyModuleTypeArrayActive={companyModuleTypeArrayActive}
      settingsState={settingsState}
      setSettingsState={setSettingsState}
      companyId={props.companyId}
      isLoading={isLoading}
      companyModuleTypeArray={companyModuleTypeArray}
      handleModuleTypeChange={handleModuleTypeChange}
      onClose={handleClose}
      onSave={handleSave}
      onCreate={handleCreate}
    />
  );
}

export default CreateEditCompanyDialog;
