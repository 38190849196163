import { UpdateNoteContentDto } from "../../../../../api";
import { validationErrorKeys } from "./constants";
import { contentIsEmpty, stringToContent } from "../../../../../utils/textEditor";
import { NotificationType } from "../../../../../hooks/useNotifier";
import { TFunction } from "react-i18next";

interface INoteValidation {
  t: TFunction<"translation", undefined>;
  notifier: (message: string, theme: NotificationType) => void;
}

const handlerErrorsValidation = (params: INoteValidation) =>
  (error: Error) => {
    switch (error.message as validationErrorKeys) {
      case validationErrorKeys.noContent:
        return params.notifier(params.t("notifier:error.notes_no_names"), "error");
      case validationErrorKeys.noName:
        return params.notifier(params.t("notifier:error.notes_no_content"), "error");
      case validationErrorKeys.noAllNotes:
        return params.notifier(params.t("notifier:error.something_wrong"), "error");
    }
  };

export const validationNotesForSave = (params: INoteValidation) =>
  (noteContents: UpdateNoteContentDto[]): boolean | void => {
    const handlerErrors = handlerErrorsValidation(params);
    if (!noteContents.length)
      return handlerErrors(new Error(validationErrorKeys.noAllNotes));
    else if (noteContents.find(item => !item.name || !item.name.length))
      return handlerErrors(new Error(validationErrorKeys.noName));
    else if (noteContents.find(item => !item.content || contentIsEmpty(stringToContent(item.content))))
      return handlerErrors(new Error(validationErrorKeys.noContent));
    else return true;
  };