import React, { useContext, useEffect, useRef, useState } from "react";
import { BoardStatusDto, IssueCommentDto, IssueDto, IssueHistoryDto, StaticFileDto } from "../../../../api";
import { useTranslation } from "react-i18next";
import { useNotifier, useRootStore, useSequentialPromises } from "../../../../hooks";
import IssueRequiredActionsDialogView from "./IssueRequiredActionsDialogView";
import { observer } from "mobx-react-lite";
import {
  IssueRequiredActionsDialogTabAddProof,
  IssueRequiredActionsDialogTabAttachComment,
  IssueRequiredActionsDialogTabAttachMessage,
  IssueRequiredActionsDialogTabSetDeadlineStrict,
} from "./issueRequiredActionsDialogTabs";
import { api } from "../../../../services";
import { actionsKeysConst, FilterIssueHistoryKeys } from "../../forms/types/consts";
import { IssueContext } from "../../../../contexts/communication/issueContext";
import { compareArraysOfNumbers } from "../../../../helpers/arrayFunctions";
import { Value } from "@udecode/plate";
import {
  contentIsEmpty,
  contentToString,
  getImagesCount,
  getLengthContent,
  initialContent,
  stringToContent,
} from "../../../../utils/textEditor";

interface IIssueRequiredActionsDialog {
  open: boolean;
  boardData: BoardStatusDto | null;
  issueData: IssueDto;
  onClose: () => void;
  onRefreshData?: () => void;
}

type TIssueRequiredActionsDialogData = {
  message: Value;
  comment: Value;
  deadline: Date | null;
  lastCommentIsProof: boolean;
  lastCommentId?: number;
  checkboxDisabled: boolean;
  lastComment?: IssueHistoryDto;
  proof: {
    text: Value;
    files: StaticFileDto[];
  };
};

function IssueRequiredActionsDialog(props: IIssueRequiredActionsDialog) {
  const { t } = useTranslation();
  const notifier = useNotifier();
  const { authStore, issueInitDataStore } = useRootStore();
  const { strictDeadline } = useContext(IssueContext);
  // const functionsList = useSequentialPromises();
  const functionsList = useRef(useSequentialPromises());
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isNoProofDialogConfirmationOpen, setIsNoProofDialogConfirmationOpen] = useState<boolean>(false);
  const [textEditorId, setTextEditorId] = useState<string>("add-proof-editor");

  const addProofEditorRef = useRef<{ reset: () => void }>();

  const [currentTabKey, setCurrentTabKey] = useState<string>(
    props.boardData?.requiredActions?.[0]?.baseTransitionActionKey ?? ""
  );

  const dataInitialState: TIssueRequiredActionsDialogData = {
    message: initialContent,
    comment: initialContent,
    deadline: null,
    lastCommentIsProof: false,
    checkboxDisabled: false,
    proof: {
      text: initialContent,
      files: [],
    },
  };

  const [data, setData] = useState<TIssueRequiredActionsDialogData>(dataInitialState);
  const lastCommentFiles = useRef<StaticFileDto[]>([]);
  const lastCommentFilesConst = useRef<StaticFileDto[]>([]);
  const lastCommentTextConst = useRef<string | null | undefined>();
  const dataCopy = useRef<TIssueRequiredActionsDialogData>(dataInitialState);
  const setDataSync = (d: TIssueRequiredActionsDialogData) => {
    dataCopy.current = d;
    setData(d);
  };

  const handleFetchLastComment = async (): Promise<IssueHistoryDto | null> => {
    const r = await api.issueHistory.getAll({
      page: 1,
      pageSize: 1,
      type: FilterIssueHistoryKeys.comment,
      issueId: props.issueData.id,
    });
    // if (r == null) {
    //   setDataSync({
    //     ...dataCopy.current,
    //     lastCommentIsProof: false,
    //     checkboxDisabled: true})
    //   notifier.show({ message: t("notifier:error.last_comment_is_failed"), theme: "error" });
    //   return null;
    // }
    let lastComment = r?.items?.[0] ?? null;

    // if (lastComment == null) {
    //   notifier.show({ message: t("notifier:error.last_comment_is_failed"), theme: "error" });
    //   setDataSync({ ...dataCopy.current, lastCommentIsProof: false, checkboxDisabled: true });
    //   return null;
    // }
    //lastComment
    setDataSync({ ...dataCopy.current, lastComment: lastComment ?? undefined });
    return lastComment;
  };

  // TODO: Проверить загрузку файлов и последующее нажатие галочки "Использовать предыдущий комментарий"
  const onSaveProofFromLastComment = async () => {
    let lastComment = dataCopy.current.lastComment;
    if (lastComment == null) {
      notifier.show({ message: t("notifier:error.last_comment_is_failed"), theme: "error" });
      setDataSync({ ...dataCopy.current, lastCommentIsProof: false, checkboxDisabled: true });
      return;
    }
    const thisLastCommentFiles =
      lastComment.comment?.attachments?.map((x) => x.file as StaticFileDto)?.filter((y) => y != null) ?? [];
    lastCommentTextConst.current = lastComment.comment?.text;
    lastCommentFiles.current = [...thisLastCommentFiles];
    lastCommentFilesConst.current = [...thisLastCommentFiles];
    setDataSync({
      ...dataCopy.current,
      lastCommentIsProof: true,
      checkboxDisabled: true,
      proof: {
        ...dataCopy.current.proof,
        text: lastComment.comment?.text ? stringToContent(lastComment.comment?.text) : initialContent,
        files: [...thisLastCommentFiles],
      },
    });
    setTextEditorId("add-proof-editor-with-last-comment");
  };

  const requiredActionsList: { key: string; name: string; element: any }[] = [
    {
      key: actionsKeysConst.addProof,
      name:
        props.issueData?.proofRequirement == null
          ? t("common:tab.board_required_actions.consolidate_result")
          : t("common:tab.board_required_actions.add_proof"),
      element: () =>
        IssueRequiredActionsDialogTabAddProof({
          textEditorId: textEditorId,
          issueData: props.issueData,
          lastCommentIsProof: data.lastCommentIsProof,
          proofText: data.proof.text,
          proofFiles: data.proof.files,
          editorRef: addProofEditorRef,
          checkboxDisabled: data.checkboxDisabled,
          showCheckboxToSaveLastComment: !!dataCopy.current.lastComment,
          onSaveProofFromLastComment: () => (data.lastCommentIsProof ? null : onSaveProofFromLastComment()),
          onProofTextChange: (text) =>
            setDataSync({
              ...dataCopy.current,
              proof: { ...dataCopy.current.proof, text: text },
            }),
          setIsLoading,
          onProofFilesDeleteById: (fileId) => {
            setDataSync({
              ...dataCopy.current,
              proof: {
                ...dataCopy.current.proof,
                files: dataCopy.current.proof.files.filter((item) => item.id !== fileId),
              },
            });
            lastCommentFiles.current = lastCommentFiles.current.filter((f) => f.id != fileId);
          },
          onProofFilesChange: (files) => {
            setDataSync({
              ...dataCopy.current,
              proof: {
                ...dataCopy.current.proof,
                files: [
                  ...lastCommentFiles.current,
                  // ...(dataCopy.current.proof.files ?? []).filter((f) => !files.some((fi) => fi.id == f.id)),
                  ...files,
                  // ...files.map(f => (
                  // ))
                  // ...files.filter((item) => !dataCopy.current.proof.files.find((item2) => item2.id === item.id)),
                ],
              },
            });
          },
        }),
    },
    {
      key: actionsKeysConst.setDateWorkStart,
      name: t("common:tab.board_required_actions.set_datedeadline_strict"),
      element: () =>
        IssueRequiredActionsDialogTabSetDeadlineStrict({
          value: data.deadline,
          defaultValue: props?.issueData?.dateWorkStart ? new Date(props.issueData.dateWorkStart) : undefined,
          expired: props.issueData?.dateDeadline ? new Date() > new Date(props.issueData?.dateDeadline) : undefined,
          onChange: (value: Date) => setDataSync({ ...dataCopy.current, deadline: value as any }),
          isStrict: props.issueData?.fields?.find((f) => f.key == "is_strict_deadline" && f.valueBool == true) != null,
          maxDeadline: props.issueData?.calculated?.dateWorkStartTo
            ? new Date(props.issueData?.calculated?.dateWorkStartTo)
            : undefined,
          dateDeadline: props.issueData?.dateDeadline ? new Date(props.issueData?.dateDeadline) : undefined,
        }),
    },
    {
      key: actionsKeysConst.attachComment,
      name: t("common:tab.board_required_actions.attach_comment"),
      element: () =>
        IssueRequiredActionsDialogTabAttachComment({
          value: data.comment,
          onInput: (comment) => {
            setDataSync({ ...dataCopy.current, comment: comment });
          },
        }),
    },
    {
      key: actionsKeysConst.attach_message,
      name: t("common:tab.board_required_actions.attach_message"),
      element: () =>
        IssueRequiredActionsDialogTabAttachMessage({
          value: data.message,
          onInput: (message) => {
            setDataSync({ ...dataCopy.current, message: message });
          },
        }),
    },
  ];

  const isNextButtonActive = (): boolean => {
    let val2return = false;

    switch (currentTabKey) {
      case actionsKeysConst.addProof:
        // val2return = canProofBeSkipped();
        val2return = true;
        break;
      case actionsKeysConst.setDateWorkStart:
        if (dataCopy!.current!.deadline! < new Date()) return false;
        val2return = true;
        // val2return = dataCopy.current.deadline
        //   ? !(
        //       strictDeadline &&
        //       props.issueData?.calculated?.dateWorkStartTo &&
        //       dataCopy.current.deadline > new Date(props.issueData?.calculated?.dateWorkStartTo as string)
        //     )
        //   : !!(dataCopy.current.deadline && dataCopy.current.deadline > new Date());
        console.log(val2return);
        break;
      case actionsKeysConst.attachComment:
        val2return = Boolean(dataCopy.current.comment && dataCopy.current.comment.length > 0);
        break;
      case actionsKeysConst.attach_message:
        val2return = Boolean(dataCopy.current.message && dataCopy.current.message.length > 0);
        break;
    }
    return val2return;
  };

  const handleNextButtonClick = async () => {
    if (!isNextButtonActive()) {
      notifier.show({ message: t("notifier:error.require_actions_next"), theme: "error" });
      return;
    }

    switch (currentTabKey) {
      /*case actionsKeysConst.addProof:
        functionsList.current.add(handleProofSave);
        break;*/
      case actionsKeysConst.setDateWorkStart:
        functionsList.current.add(handleDeadlineStrictSave);
        break;
      /*case actionsKeysConst.attachComment:
        functionsList.current.add(handleCommentSave);
        break;
      case actionsKeysConst.attach_message:
        functionsList.current.add(handleMessageSave);
        break;*/
    }

    const availableActions = props.boardData?.requiredActions?.map((a) => a.baseTransitionActionKey) ?? [];
    if (props.boardData?.requiredActions && props.boardData?.requiredActions.length > 0) {
      if (currentTabKey != availableActions[availableActions.length - 1]) {
        const newPos = availableActions.indexOf(currentTabKey) + 1;
        setCurrentTabKey(availableActions[newPos] ?? "");
      } else {
        await handleSaveClick();
      }
    }
  };

  const thisProofIsLastComment = (): boolean => {
    if (!data.lastCommentIsProof) return false;
    else {
      let text: boolean = false;
      let files: boolean = false;
      text = dataCopy.current.proof.text.length === lastCommentTextConst.current?.length;
      files = dataCopy.current.proof.files
        ? compareArraysOfNumbers(
            dataCopy.current.proof.files.map((item) => item.id!),
            lastCommentFilesConst.current.map((item) => item.id!)
          )
        : false;

      return text && files;
    }
  };

  const handleProofSaveNoProof = async () => {
    // const r = await api.issue.edit(props.issueData.id as number, {
    //   ...props.issueData,
    //   proof: {
    //     ...props.issueData.proof,
    //     isResultAchieved: dataCopy.current.isResultAchieved,
    //   },
    // });
    //
    // r == null && showSomethingError();
    const attachmentsArray = [];

    // const z = await api.issueHistory.create({
    //   issueId: props.issueData.id,
    //   comment: undefined,
    // });

    // if (z == null) {
    //   showSomethingError();
    //   return;
    // }

    // const r = await api.issue.editPartially(
    //   props.issueData.id as number,
    //   {
    //     ...props.issueData,
    //     proof: {
    //       ...props.issueData.proof,
    //       historyId: z?.id,
    //     },
    //   },
    //   props.issueData
    // );
    //
    // r == null && showSomethingError();
  };

  const handleProofSave = async (historyId?: number) => {
    // alert(
    //   contentIsEmpty(dataCopy.current.proof.text) &&
    //     dataCopy.current.proof.files.length + (getImagesCount(data.proof.text) ?? 0) == 0
    // );
    // alert(contentIsEmpty(dataCopy.current.proof.text))
    // alert(dataCopy.current.proof.files.length);
    if (
      contentIsEmpty(dataCopy.current.proof.text) &&
      dataCopy.current.proof.files.length + (getImagesCount(data.proof.text) ?? 0) == 0
    ) {
      await handleProofSaveNoProof();
      return;
    }

    const attachmentsArray = [];

    const lastCommentEqualToThisProof = thisProofIsLastComment();

    /*const z = lastCommentEqualToThisProof
      ? undefined
      : await api.issueHistory.create({
          issueId: props.issueData.id,
          comment: {
            text: contentToString(dataCopy.current.proof.text),
            attachments: dataCopy.current.proof.files.map((f, index) => {
              return {
                fileId: f.id,
                order: index,
              };
            }),
          },
        });

    if (z === null) return showSomethingError();*/

    const r = await api.issue.editPartially(
      props.issueData.id as number,
      {
        ...props.issueData,
        proof: {
          ...props.issueData.proof,
          historyId: lastCommentEqualToThisProof ? data.lastComment?.id : historyId,
        },
      },
      props.issueData
    );

    r == null && showSomethingError();
  };

  const handleDeadlineSave = async () => {
    const r = await api.issue.edit(props.issueData.id as number, {
      ...props.issueData,
      dateWorkStart: new Date(dataCopy.current.deadline as Date).toISOString(),
    });
    r == null && showSomethingError();
  };

  const handleDeadlineStrictSave = async () => {
    const r = await api.issue.edit(props.issueData.id as number, {
      ...props.issueData,
      dateWorkStart: new Date((dataCopy.current.deadline || props.issueData.dateWorkStart!) as Date).toISOString(),
    });
    r == null && showSomethingError();
  };

  const handleCommentSave = async () => {
    const r = await api.issueHistory.create({
      issueId: props.issueData.id,
      comment: {
        text: contentToString(dataCopy.current.comment),
        attachments: [],
      },
    });
    r == null && showSomethingError();
  };

  const handleMessageSave = async () => {
    const r = await api.issueHistory.create({
      issueId: props.issueData.id,
      comment: {
        text: contentToString(dataCopy.current.message),
        attachments: [],
        recipients: [
          {
            userId:
              authStore.getInitialInfo?.identity?.id == props.issueData.createdByUserId
                ? (props.issueData.executorUserId as number)
                : (props.issueData.createdByUserId as number),
          },
        ],
      },
    });
    r == null && showSomethingError();
  };

  const showSomethingError = () => {
    notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
  };

  const canProofBeSkipped = () => {
    let value2return = false;

    if (!props.boardData?.requiredActions?.some((r) => r.baseTransitionActionKey == "add_proof")) {
      return true;
    }

    if (props.issueData.proofRequirement?.rules?.some((r) => r.key == "require.images_min_count")) {
      value2return = Boolean(
        dataCopy.current.proof.files.length + (getImagesCount(dataCopy.current.proof.text) ?? 0) >=
          (props.issueData.proofRequirement?.rules?.find((r) => r.key == "require.images_min_count")?.value ?? 0)
      );
    }

    if (props.issueData.proofRequirement?.rules?.some((r) => r.key == "require.files_min_count")) {
      value2return = Boolean(
        dataCopy.current.proof.files.length + (getImagesCount(dataCopy.current.proof.text) ?? 0) >=
          (props.issueData.proofRequirement?.rules?.find((r) => r.key == "require.files_min_count")?.value ?? 0)
      );
    }

    if (props.issueData.proofRequirement?.rules?.some((r) => r.key == "require.text_min_length")) {
      value2return = Boolean(
        getLengthContent(dataCopy.current.proof.text) >=
          (props.issueData.proofRequirement?.rules?.find((r) => r.key == "require.text_min_length")?.value ?? 0)
      );
    }

    if (props.issueData.proofRequirement?.rules == null || props.issueData.proofRequirement?.rules.length == 0) {
      value2return = true;
    }

    return value2return;
  };

  const handleGetCommentDto = (): IssueCommentDto | undefined => {
    switch (currentTabKey) {
      case actionsKeysConst.attachComment:
        return {
          text: contentToString(dataCopy.current.comment),
          attachments: [],
        };
      case actionsKeysConst.attach_message:
        return {
          text: contentToString(dataCopy.current.message),
          attachments: [],
          recipients: [
            {
              userId:
                authStore.getInitialInfo?.identity?.id == props.issueData.createdByUserId
                  ? (props.issueData.executorUserId as number)
                  : (props.issueData.createdByUserId as number),
            },
          ],
        };
      case actionsKeysConst.addProof:
        return {
          text: contentToString(dataCopy.current.proof.text),
          attachments: dataCopy.current.proof.files.map((f, index) => {
            return {
              fileId: f.id,
              order: index,
            };
          }),
        };
    }
  };

  const handleSaveClick = async (force?: boolean) => {
    if (!canProofBeSkipped() && !force) {
      setIsNoProofDialogConfirmationOpen(true);
      return;
    }

    setIsLoading(true);
    const toGetComment: boolean =
      currentTabKey == actionsKeysConst.attachComment ||
      currentTabKey == actionsKeysConst.attach_message ||
      currentTabKey == actionsKeysConst.addProof;
    const r = await api.issueHistory.create({
      issueId: props.issueData.id,
      status: {
        boardStatusId: props.boardData?.id,
      },
      ...(toGetComment && { comment: handleGetCommentDto() }),
    });
    if (actionsKeysConst.addProof == currentTabKey) handleProofSave(r?.id);
    await functionsList.current.run();
    setIsLoading(false);
    functionsList.current.reset();
    issueInitDataStore.getOnStatusChange && issueInitDataStore.getOnStatusChange();
    props.onRefreshData && props.onRefreshData();
    r == null && showSomethingError();
    r != null && handleDialogClose();
  };

  const handleDialogClose = () => {
    setCurrentTabKey("");
    setDataSync(dataInitialState);
    props.onClose();
  };

  const checkToFetchLastComment = async () => {
    if (actionsKeysConst.addProof === currentTabKey && !dataCopy.current.lastComment) {
      const lastComment = await handleFetchLastComment();
      setDataSync({ ...dataCopy.current, lastComment: lastComment ?? undefined });
    }
  };

  useEffect(() => {
    checkToFetchLastComment();
  }, [currentTabKey]);

  useEffect(() => {
    if (currentTabKey.length == 0 && props.open) {
      setCurrentTabKey(props.boardData?.requiredActions?.[0]?.baseTransitionActionKey ?? "");
    }
  }, [props.boardData?.requiredActions, props.open]);

  return (
    <IssueRequiredActionsDialogView
      open={props.open}
      isNoProofDialogConfirmationOpen={isNoProofDialogConfirmationOpen}
      boardData={props.boardData}
      onNextClick={handleNextButtonClick}
      requiredActionsList={requiredActionsList}
      currentTabKey={currentTabKey}
      onClose={() => handleDialogClose()}
      isLoading={isLoading}
      resetFunctionsList={() => functionsList.current.reset()}
      setIsNoProofDialogConfirmationOpen={setIsNoProofDialogConfirmationOpen}
      handleForceSaveClick={() => handleSaveClick(true)}
    />
  );
}

export default observer(IssueRequiredActionsDialog);
