import React from "react";
import "./FileViewer.scss";
// import { Portal } from "react-portal";
import { FilesViewerFileType } from "./FileViewer";
import { Dialog, Icon, Menu } from "../../uiKit";
import { getFileExtensionByName } from "../../../helpers/fileFunctions";
import { useTranslation } from "react-i18next";
import { FiFile } from "@react-icons/all-files/fi/FiFile";

interface IFileViewerView {
  files: FilesViewerFileType[];
  currentFileIndex: number;

  onClickPrev: () => void;
  onClickNext: () => void;
  onClickThumbnail: (value: number) => void;
  onClose: () => void;
}

function FileViewerView(props: IFileViewerView) {
  const { t } = useTranslation();
  // return props.files.length > 0 ? (
  //   // <Portal node={undefined}>
  //   //   <div className="file-viewer__backdrop d-flex pa-3">
  //   <Dialog width="100%" zIndex={54321} open={true} title={props.files[props.currentFileIndex]?.name ?? "File"} />
  // ) : // </div>
  // // </Portal>
  // null;
  return (
    <Dialog
      className="file-viewer__dialog"
      width="100%"
      zIndex={54321}
      onClose={props.onClose}
      open={props.files.length > 0}
      destroyOnClose
      title={props.files[props.currentFileIndex]?.name ?? "File"}
    >
      <div className="d-flex" style={{ height: "80vh" }}>
        <div className="file-viewer__menu">
          <Menu
            // mode=""
            selectedKeys={[props.currentFileIndex.toString()]}
            items={props.files.map((f, index) => ({
              key: index.toString(),
              icon: <Icon component={() => <FiFile opacity={0.5} />} />,
              label: f.name,
              onClick: () => props.onClickThumbnail(index),
            }))}
          />
        </div>
        <div className="flex-grow-1" style={{ overflowY: "visible" }}>
          <iframe
            className="file-viewer__iframe"
            // src={`https://docs.google.com/viewerng/viewer?url=${fileUrl}&${serializeObject(iframeParams)}`}
            src={
              ["doc", "docx", "ppt", "pptx", "xls", "xlsx", "ods"].includes(
                getFileExtensionByName(props.files[props.currentFileIndex]?.src ?? "") ?? ""
              )
                ? `https://view.officeapps.live.com/op/embed.aspx?src=${props.files[props.currentFileIndex]?.src}`
                : props.files[props.currentFileIndex]?.src
            }
          />
        </div>
      </div>
      {/*<DialogActions>*/}
      {/*  <Button onClick={props.onClose} children={t("ui:button.close")} />*/}
      {/*</DialogActions>*/}
    </Dialog>
  );
}

export default FileViewerView;
