import React from "react";
import { DatePickerRangeView } from "./DatePickerRangeView";
import { RangePickerDateProps } from "antd/es/date-picker/generatePicker";
import dayjs, { Dayjs } from "dayjs";

type TDatePickerRange = {
  disableFuture?: boolean;
  disableToday?: boolean;
  disablePast?: boolean;
  minDate?: Dayjs | null;
  maxDate?: Dayjs | null;
  readOnly?: boolean;
} & RangePickerDateProps<Dayjs>;

export function DatePickerRange({
  disableFuture,
  disablePast,
  disableToday,
  disabledDate,
  maxDate,
  minDate,
  ...rest
}: TDatePickerRange) {
  const checkIsDateDisabled = (date: Dayjs) => {
    if (disabledDate != null) return disabledDate(date);
    // disablePast, disableFuture, minDate, maxDate
    if (minDate == null && maxDate == null) {
      if (disablePast && disableFuture) return disableToday ? true : !date.isToday();
      if (disablePast && !disableToday) return date.isBefore(dayjs());
      if (disablePast && disableToday) return date.isSameOrBefore(dayjs());
      if (disableFuture && !disableToday) return date.isAfter(dayjs());
      if (disableFuture && disableToday) return date.isSameOrAfter(dayjs());
      if (!disablePast && !disableFuture && disableToday) return date.isToday();
    }
    if (minDate != null && maxDate != null) return !date.isBetween(minDate, maxDate, "day", "[]");
    if (minDate != null) return date.isBefore(minDate);
    if (maxDate != null) return date.isAfter(minDate);
    return false;
  };

  return <DatePickerRangeView disabledDate={checkIsDateDisabled} {...rest} />;
}
