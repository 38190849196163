import React from "react";
import enContent from "../../../../../utils/guides/en/policies.json";
import ruContent from "../../../../../utils/guides/ru/policies.json";
import { TextEditor } from "../../../textEditorOld/TextEditor";
import { useRootStore } from "../../../../../hooks";

export const getContent = (localeKey: string | null): any | null => {
  switch (localeKey) {
    case "ru":
      return ruContent;
    case "en":
      return enContent;
    case "uk":
      return ruContent;
  }
};

export const PoliciesGuide = () => {
  const { appStore } = useRootStore();

  return <TextEditor readOnly={true} id="guide-policy" onChange={() => {}} value={getContent(appStore.getAppLocale)} />;
};
