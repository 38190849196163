import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useApi, useDateHelpers } from "../../../../hooks";
import { api } from "../../../../services";
import { CompanyEmployeeStatsDialogView } from "./CompanyEmployeeStatsDialogView";

interface ICompanyEmployeeStatsDialog {
  open: boolean;
  companyId: number | null;

  onClose(): void;
}

export function CompanyEmployeeStatsDialog(props: ICompanyEmployeeStatsDialog) {
  const dateHelpers = useDateHelpers();

  const [displayMode, setDisplayMode] = useState<"chart" | "table">("table");
  const [tableFilters, setTableFilters] = useState({
    dateFrom: dayjs().subtract(1, "day").toDate(),
    dateTo: new Date(),
  });

  const employeeStatsData = useApi(() =>
    api.adminCompany.getEmployeeStats(props.companyId as number, tableFilters.dateFrom, tableFilters.dateTo)
  );

  useEffect(() => {
    if (props.open) {
      employeeStatsData.fetch();
    } else {
      // employeeStatsData.set([]);
    }
  }, [props.open, tableFilters]);

  const handleClose = () => {
    props.onClose();
    employeeStatsData.set([]);
    setTableFilters({
      dateFrom: dayjs().subtract(1, "day").toDate(),
      dateTo: new Date(),
    });
    setDisplayMode("table");
  };

  return (
    <CompanyEmployeeStatsDialogView
      {...props}
      displayMode={displayMode}
      setDisplayMode={setDisplayMode}
      onClose={handleClose}
      tableFilters={tableFilters}
      setTableFilters={setTableFilters}
      tableItems={(employeeStatsData.value ?? []).map((v) => ({
        date: dateHelpers.formatDateString(v.date as string, {
          month: "short",
          showYearWhenIsCurrent: true,
          hour: undefined,
          minute: undefined,
        }),
        value: v.value,
      }))}
    />
  );
}
