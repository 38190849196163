import React from "react";
import "./Dropdown.scss";
import { Dropdown, DropdownProps } from "antd";
import clsx from "clsx";

interface IDropdownView extends DropdownProps {
  //
}

export function DropdownView({ className, ...rest }: IDropdownView) {
  return <Dropdown className={clsx("uiKit-dropdown", className ?? "")} {...rest} />;
}
