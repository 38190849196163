import React from "react";
import { Radio, RadioProps } from "antd";
import "./Radio.scss";
import clsx from "clsx";

interface IRadioView extends RadioProps {
  //
}

export function RadioView({ className, ...rest }: IRadioView) {
  return <Radio className={clsx("uiKit-radio", className ?? "")} {...rest} />;
}
