import React from "react";
import "./DriverStatisticCard.scss";
import { Card, Text } from "../../../../uiKit";
import { IDriverStatisticCardView } from "./DriverStatisticCard.interface";

export const DriverStatisticCardView = (props: IDriverStatisticCardView) => {
  return (
    <Card className="driver-statistic-card" variant="secondary">
      <Text className="driver-statistic-card__title">{props.title}</Text>
      <Text className="driver-statistic-card__digit py-2">{props.digit}</Text>
      {props.subtitle && <Text className="driver-admin-page__overall__card__subtitle">{props.subtitle}</Text>}
    </Card>
  );
};
