import React from "react";
import { SearchInputField } from "../../../../../elements/searchInputField/SearchInputField";
import { IPlanningPageMenu } from "./PlanningPageMenu.interface";
import { useTranslation } from "react-i18next";

export const PlanningPageApprovalMenu = (props: IPlanningPageMenu) => {
  const { t } = useTranslation();

  return (
    <SearchInputField
      value={props.searchValue ?? ""}
      setValue={props.onPlanSearch!}
      className="planning-page-admin-menu__input-search mb-2"
      placeholder={t("ui:placeholder.plan_search")}
      backgroundInherit
      styles={{ width: "410px" }}
    />
  );
};
