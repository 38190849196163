import React from "react";
import { useTranslation } from "react-i18next";
import { IUpdateNoteListContent } from "./UpdateNoteListContent.interface";
import { Button, Card, Divider, Empty } from "../../../../../uiKit";
import { UpdateNoteCard } from "../../components/UpdateNoteCard/UpdateNoteCard";
import ScrollTrigger from "../../../../../service/scrollTrigger/ScrollTrigger";

export const UpdateNoteListContent = (props: IUpdateNoteListContent) => {
  const { t } = useTranslation();

  return (
    <Card
      title={t("ui:title.releases")}
      size="default"
      extra={<Button onClick={props.onRedirectToCreate}>{t("ui:button.add")}</Button>}
      bodyStyle={{ padding: 0 }}
      style={{ overflow: "hidden" }}
      scrollStrategy="cardBody"
    >
      {props.isDone && props.updateNotes.length == 0 ? (
        <Empty className="my-4" />
      ) : (
        <div>
          {props.updateNotes?.map((updateNote, index) => (
            <React.Fragment key={updateNote.id}>
              {index > 0 && <Divider />}
              <UpdateNoteCard updateNote={updateNote} />
            </React.Fragment>
          ))}
          <ScrollTrigger
            disabled={props.isDone}
            onIntersection={props.loadNext}
            marginTop={props.updateNotes.length > 0}
            hidden={props.isDone}
          />
        </div>
      )}
    </Card>
  );
};
