import React from "react";
import { useTranslation } from "react-i18next";
import EmployeeEditForm from "../../forms/employeeEditForm/EmployeeEditForm";
import { Dialog } from "../../../uiKit";

function EmployeeSettingsOnFirstLoginDialogView(props: any) {
  const { t } = useTranslation();

  const handleSave = () => {
    window.location.reload();
  };

  return (
    <Dialog title={t("ui:title.edit_to_start")} open={true} closable={false} onClose={() => {}}>
      <EmployeeEditForm onSaveClick={handleSave} firstLaunch />
    </Dialog>
  );
}

export default EmployeeSettingsOnFirstLoginDialogView;
