import React from "react";
import { default as AntIcon } from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";
import "./Icon.scss";

interface IIcon extends Omit<IconComponentProps, "ref"> {
  //
}

export function Icon(props: IIcon) {
  return <AntIcon {...props} />;
}
