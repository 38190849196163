import React from "react";
import { ICardSection } from "./CardSection.interface";
import { CardSectionView } from "./CardSectionView";
import { useNavigate } from "react-router-dom";

export const CardSection = ({ section }: ICardSection) => {
  const navigate = useNavigate();

  const handleOpenSection = () => navigate(`/policy/topics/${section.id}`);

  return <CardSectionView onClick={handleOpenSection} section={section} />;
};

