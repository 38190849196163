import React from "react";
import { useTranslation } from "react-i18next";
import { Form } from "antd";
import { IAddCompanyToGroupViewDialog } from "./AddCompanyToGroupDialog.interface";
import { Button, Dialog, DialogActions } from "../../../uiKit";
import SubheaderText from "../../../elements/subheaderText/SubheaderText";
import { AutocompleteAsync } from "../../../elements/autocompleteAsync/AutocompleteAsync";

export const AddCompanyToGroupViewDialog: React.FC<IAddCompanyToGroupViewDialog> = (props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const handleSubmit = () => form.submit();

  return (
    <Dialog open={props.open} onClose={props.onClose} title={t("ui:title.add_company")}>
      <Form form={form} name="addCompanyToGroupForm" onFinish={props.onAddCompany}>
        <SubheaderText text={t("ui:subheader.company")} />
        <Form.Item name="groupName" rules={[{ required: true, message: t("validation:field_required") }]}>
          <AutocompleteAsync
            type="adminCompany"
            placeholder={t("ui:placeholder.choose_companies")}
            requestOptions={{ isLinkedToGroup: false }}
            value={props.companyId}
            onChange={props.onChangeCompany}
          />
        </Form.Item>
      </Form>
      <DialogActions>
        <>
          <Button onClick={props.onClose} variant="text">
            {t("ui:button.cancel")}
          </Button>
          <Button onClick={handleSubmit} variant="filled" loading={props.isLoading}>
            {t("ui:button.create")}
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
};
