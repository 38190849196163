import React from "react";
import { Dayjs } from "dayjs";
import { PickerTimeProps } from "antd/es/date-picker/generatePicker";
import "./TimePicker.scss";
import clsx from "clsx";
import { TimePicker as TimePickerAntD } from "antd";

type TTimePickerView = {
  readOnly?: boolean;
} & PickerTimeProps<Dayjs>;

export const { RangePicker } = TimePickerAntD;

export function TimePickerView({ className, readOnly, dropdownClassName, ...rest }: TTimePickerView) {
  return (
    <TimePickerAntD
      className={clsx("uiKit-timePicker", className ?? "", {
        "no-interact": readOnly,
      })}
      dropdownClassName={clsx("uiKit-timePicker__dropdown", dropdownClassName ?? "")}
      {...rest}
    />
  );
}
