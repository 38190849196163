import { createPluginFactory, onKeyDownToggleElement } from "@udecode/plate";
import { RoleElement } from "./RoleElement";

export const ELEMENT_ROLE = "roleNested";

export const createRolePlugin = createPluginFactory({
  key: ELEMENT_ROLE,
  isElement: true,
  isVoid: true,
  component: RoleElement,
  handlers: { onKeyDown: onKeyDownToggleElement },
  then: (editor, { type }) => ({
    deserializeHtml: {
      rules: [
        {
          validNodeName: "roleNested",
        },
      ],
      getNode: (el) => ({
        type,
        id: el.getAttribute("id"),
      }),
    },
  }),
});
