import { EditorConfig, NodeKey, SerializedTextNode, Spread, TextNode } from "lexical";
import { formatDateToDateString } from "../../../../helpers/formatFunctions";
import { getObjectFromTimeSpan } from "../../../../helpers/dateFunctions";

export type SerializedCustomNode = Spread<
  {
    type: "custom";
    dataType: string;
    dataValue: string;
    subType: string;
  },
  SerializedTextNode
>;

export class CustomNode extends TextNode {
  __dataType: string;
  __dataValue: string;
  __subType: string;

  constructor(text: string, dataType: string, dataValue: string, subType: string, key?: NodeKey) {
    super(text, key);
    this.__dataType = dataType;
    this.__dataValue = dataValue;
    this.__subType = subType;
    this.__text = this.getText(dataType, dataValue, text);
  }

  static getType() {
    return "custom";
  }

  getText(dataType: string, dataValue: string, text: string) {
    switch (dataType) {
      case "date":
        return formatDateToDateString(new Date(dataValue), "L HH:mm");
      case "time":
        return formatDateToDateString(getObjectFromTimeSpan(dataValue)?.dateObject as Date, "HH:mm");
      default:
        return text;
    }
  }

  static clone(node: CustomNode): CustomNode {
    return new CustomNode(node.__text, node.__dataType, node.__dataValue, node.__subType, node.__key);
  }

  createDOM(config: EditorConfig): HTMLElement {
    // const dom = document.createElement("span");
    const inner = super.createDOM(config);
    inner.style.background =
      this.__subType == "error" && this.__dataType != "switch"
        ? "var(--editor-color-error)"
        : this.__subType == "success" && this.__dataType != "switch"
        ? "var(--editor-color-success)"
        : "";
    inner.style.borderRadius = "4px";
    inner.style.padding = "2px";
    inner.style.margin = this.__subType == "" ? "0px 8px" : "";
    inner.style.color =
      this.__subType == "error" && this.__dataType == "switch"
        ? "var(--color-error-base)"
        : this.__subType == "success" && this.__dataType == "switch"
        ? "var(--color-success-base)"
        : "";
    // dom.appendChild(inner);
    return inner;
  }

  static importJSON(serializedNode: SerializedCustomNode): CustomNode {
    const node = $createCustomNode(
      serializedNode.text,
      serializedNode.dataType,
      serializedNode.dataValue,
      serializedNode.subType
    );
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);
    return node;
  }

  updateDOM(prevNode: TextNode, dom: HTMLElement, config: EditorConfig): boolean {
    const inner = dom.firstChild;
    if (inner === null) {
      return true;
    }
    super.updateDOM(prevNode, inner as HTMLElement, config);
    return false;
  }

  exportJSON(): SerializedCustomNode {
    return {
      ...super.exportJSON(),
      type: "custom",
      dataType: this.__dataType,
      dataValue: this.__dataValue,
      subType: this.__subType,
    };
  }
}
export function $createCustomNode(text: string, dataType: string, dataValue: string, subType: string): CustomNode {
  return new CustomNode(text, dataType, dataValue, subType);
  // return $applyNodeReplacement(node);
}
