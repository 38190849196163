import React from "react";
import ruContent from "../../../../../utils/guides1/dashboard/ru.json";
import enContent from "../../../../../utils/guides/en/dashboard.json";
import { TextEditor } from "../../../textEditorOld/TextEditor";
import { useRootStore } from "../../../../../hooks";

export const getContent = (localeKey: string | null): any | null => {
  switch (localeKey) {
    case "ru":
      return ruContent;
    case "en":
      return enContent;
    case "uk":
      return ruContent;
  }
};

export const DashboardGuide = () => {
  const { hintStore, appStore } = useRootStore();

  return (
    <TextEditor
      readOnly={true}
      anchorKey={hintStore.getAnchorId as string}
      id="guide-dasboard"
      onChange={() => {}}
      value={getContent(appStore.getAppLocale)}
    />
  );
};
