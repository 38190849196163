import React from "react";
import "./GroupCompanyStats.scss";
import { IGroupCompanyStats } from "./GroupCompanyStats.interface";
import { useTranslation } from "react-i18next";
import { IDriverStatisticCard } from "../../../../../pages/driver/components/driverStatisticCard/DriverStatisticCard.interface";
import { Button, Card, Empty, Icon } from "../../../../../uiKit";
import { FiCalendar } from "react-icons/all";
import { DriverStatisticCard } from "../../../../../pages/driver/components/driverStatisticCard/DriverStatisticCard";

export const GroupCompanyStats = (props: IGroupCompanyStats) => {
  const { t } = useTranslation();

  const statsData: IDriverStatisticCard[] = [
    {
      title: t("common:driver.card.queue.part1"),
      digit: props.groupStats?.cardsInQueue,
      subtitle: t("common:driver.card.queue.part2"),
    },
    {
      title: t("common:driver.card.employees_per_driver.part1"),
      digit: props.groupStats?.usersPerDriver,
      subtitle: t("common:driver.card.employees_per_driver.part2"),
    },
    {
      title: t("common:driver.card.average_working_time.part1"),
      digit: props.groupStats?.dateDependant?.workTimeAverageInMinutes ?? "",
      subtitle: t("common:driver.card.average_working_time.part2"),
    },
    {
      title: t("common:driver.card.average_processing_time.part1"),
      digit: props.groupStats?.dateDependant?.oneCardProcessTimeAverageInSeconds,
      subtitle: t("common:driver.card.average_processing_time.part2"),
    },
    {
      title: t("common:driver.card.average_number_of_cards.part1"),
      digit: props.groupStats?.dateDependant?.cardsProcessedPerDriverAvg,
      subtitle: t("common:driver.card.average_number_of_cards.part2"),
    },
    {
      title: t("common:driver.card.average_number_of_violations.part1"),
      digit: props.groupStats?.dateDependant?.violationsPerUserAvg,
      subtitle: t("common:driver.card.average_number_of_violations.part2"),
    },
  ];

  return (
    <Card
      className="ml-1 flex-shrink-0"
      title={t("common:driver.monitoring")}
      size="default"
      scrollStrategy="cardBody"
      extra={
        <Button
          onClick={props.onOpenSelectPeriodDialog}
          variant="filled"
          icon={<Icon component={() => <FiCalendar />} />}
        >
          {props.dateButtonText}
        </Button>
      }
    >
      {props.groupStats != null ? (
        <div className="group-company-page__info-cards__wrapper">
          {statsData.map((item) => (
            <React.Fragment key={item.title}>
              <DriverStatisticCard digit={item.digit} title={item.title} subtitle={item.subtitle} />
            </React.Fragment>
          ))}
        </div>
      ) : (
        <Empty />
      )}
    </Card>
  );
};

export default GroupCompanyStats;
