import React from "react";
import { IconButton, Tooltip } from "../../../../../uiKit";
import { IRegulationIcons } from "./RegulationIcons.interface";
import { useTranslation } from "react-i18next";

export const EditButton : React.FC<IRegulationIcons> = (props) => {

  const { t } = useTranslation();

  return (
    <Tooltip title={t("ui:tooltip.edit")}>
      <IconButton className="regulation-button-icons__edit" disabled={props.disabled} onClick={props.onClick}>
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.33744 14.6918L15.9316 1.09766L19.3301 4.49619L5.73597 18.0903M2.33744 14.6918L0.978027 19.4497L5.73597 18.0903M2.33744 14.6918L5.73597 18.0903"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </IconButton>
    </Tooltip>
  );
};

