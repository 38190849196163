import { Form } from "antd";
import { memo, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FiHelpCircle } from "react-icons/all";
import { AutocompleteAsync } from "../../../elements/autocompleteAsync/AutocompleteAsync";
import SubheaderText from "../../../elements/subheaderText/SubheaderText";
import { Button, Dialog, DialogActions, Input, Select, Switch, Tooltip } from "../../../uiKit";
import { ISelectItem } from "../../../uiKit/select/SelectView";
import { IMetricCreateEditDialogView } from "./MetricCreateEditDialog.interface";
import "./MetricCreateEditDialog.scss";
import { MetricSourceDto } from "../../../../api";

export const MetricCreateEditDialogView = memo((props: IMetricCreateEditDialogView) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const handleSubmit = () => form.submit();

  const rolePositionsAsAutocompleteItems = useCallback(
    (): ISelectItem[] => props.role?.positions?.map((x) => ({ id: x.id, value: x.id, text: x.name })) as ISelectItem[],
    [props.role]
  );

  const handleGetSourceByOrder = useCallback(
    (order: number) => props.metric?.sources?.find((s) => s.order == order),
    [props.metric?.sources]
  );

  const handleChangeFirstSource = useCallback(
    (value: number, metricSource: MetricSourceDto) => {
      if (value != 0) {
        props.onChangeMetric({
          ...props.metric,
          sources: [
            ...((props.metric?.sources?.filter((s) => s.order != 1) ?? []).filter((s) => s.order != 0) ?? []),
            {
              existingSourceId: value,
              order: 0,
              fillerRolePositionId: null,
              companyIntervalId: metricSource.companyIntervalId,
              companyInterval: undefined,
            },
          ],
        });
      }
      if (value == 0) {
        props.onChangeMetric({
          ...props.metric,
          sources: [
            ...((props.metric?.sources?.filter((s) => s.order != 1) ?? []).filter((s) => s.order != 0) ?? []),
            {
              existingSourceId: null,
              order: 0,
              fillerRolePositionId: props.metric?.rolePositionId,
              companyIntervalId: props.intervals?.find((i) => i.week != 0)?.id,
              companyInterval: props.intervals?.find((i) => i.week != 0),
            },
            {
              existingSourceId: null,
              order: 1,
              fillerRolePositionId: props.metric?.rolePositionId,
              companyIntervalId: handleGetSourceByOrder(0)?.companyIntervalId,
            },
          ],
        });
      }
    },
    [handleGetSourceByOrder, props]
  );

  const handleChangeSecondSource = useCallback(
    (value: number, metricSource: MetricSourceDto) => {
      props.onChangeMetric({
        ...props.metric,
        sources: [
          ...(props.metric?.sources?.filter((s) => s.order != 1) ?? []),
          {
            existingSourceId: value == 0 ? null : value,
            order: 1,
            fillerRolePositionId: value == 0 ? handleGetSourceByOrder(0)?.fillerRolePositionId : null,
            companyIntervalId:
              value == 0 ? handleGetSourceByOrder(0)?.companyIntervalId : metricSource.companyIntervalId,
          },
        ],
      });
    },
    [handleGetSourceByOrder, props]
  );

  useEffect(() => {
    form.setFieldsValue({
      name: props.metric?.name,
    });
  }, [form, props.metric]);

  return (
    <Dialog
      destroyOnClose
      title={props.editMode ? t("ui:title.edit_metric") : t("ui:title.create_metric")}
      open={props.open}
      onClose={props.toggleDialog}
    >
      <Form
        requiredMark={false}
        layout="vertical"
        form={form}
        onFinish={props.editMode ? props.onEdit : props.onCreate}
      >
        {/*<SubheaderText text={t("ui:subheader.issue_name")} />*/}
        <Form.Item
          label={t("ui:subheader.issue_name")}
          name="name"
          rules={[
            {
              required: true,
              message: t("validation:field_required"),
            },
          ]}
        >
          <Input
            autoComplete="off"
            value={props.metric?.name ?? ""}
            onChange={(event) => props.onChangeMetric({ ...props.metric, name: event.currentTarget.value })}
            placeholder={t("ui:placeholder.issue_name")}
          />
        </Form.Item>
        {/*<SubheaderText text={t("ui:subheader.issue_description")} />*/}
        <Form.Item
          label={t("ui:subheader.issue_description")}
          rules={[
            {
              required: true,
              message: t("validation:field_required"),
            },
          ]}
        >
          <Input
            autoComplete="off"
            value={props.metric?.description ?? ""}
            onChange={(event) => props.onChangeMetric({ ...props.metric, description: event.currentTarget.value })}
            placeholder={t("ui:placeholder.issue_description")}
          />
        </Form.Item>
        {/*<SubheaderText text={t("ui:subheader.metric_orgchart")} />*/}
        {/*<Form.Item*/}
        {/*  rules={[*/}
        {/*    {*/}
        {/*      required: true,*/}
        {/*      message: t("validation:field_required"),*/}
        {/*    },*/}
        {/*  ]}*/}
        {/*>*/}
        {/*  <OrgchartSwitcher*/}
        {/*    value={props.role?.orgchartId ?? undefined}*/}
        {/*    onChange={(v) => props.onChangeOrgchartId(v as number)}*/}
        {/*    selectProps={{*/}
        {/*      style: { width: "100%" },*/}
        {/*      placeholder: t("ui:placeholder.click_to_select"),*/}
        {/*    }}*/}
        {/*    drawType="select"*/}
        {/*  />*/}
        {/*</Form.Item>*/}
        {/*<SubheaderText text={t("ui:subheader.metric_responsible")} />*/}
        {/*{props.companyUiType == 1 && (*/}
        <Form.Item
          label={t("ui:subheader.metric_responsible")}
          rules={[
            {
              required: true,
              message: t("validation:field_required"),
            },
          ]}
        >
          <Select
            readOnly
            disabled={props.editMode}
            items={rolePositionsAsAutocompleteItems()}
            value={props.metric?.rolePositionId}
            onChange={(id: number) =>
              props.onChangeMetric({
                ...props.metric,
                rolePositionId: +id,
                sources: props.metric?.sources?.map((s) => (s.order == 0 ? { ...s, fillerRolePositionId: +id } : s)),
              })
            }
          />
        </Form.Item>
        <Form.Item className="form-switch mt-4">
          <div className="d-stack-row align-center">
            <div className="mr-2">
              <Switch
                checked={props.metric?.isMain}
                onChange={(v) => props.onChangeMetric({ ...props.metric, isMain: v })}
              />
            </div>
            <div>
              <SubheaderText noBottomOffset text={t("ui:subheader.metric_switch_main")} />
            </div>
          </div>
          {props.mainMetric && props.metric?.isMain && props.mainMetric.id != props.metric?.id && (
            <span style={{ color: "#E31D02", fontSize: "12px" }}>
              {t("ui:text.main_metric_warning.text1", { metricName: props.mainMetric.name })}
              {t("ui:text.main_metric_warning.text2")}
            </span>
          )}
        </Form.Item>
        <Form.Item className="form-switch">
          <div className="d-stack-row align-center">
            <div className="mr-2">
              <Switch
                //disabled={props.editMode}
                checked={props.metric?.isReversed}
                onChange={(v) => props.onChangeMetric({ ...props.metric, isReversed: v })}
              />
            </div>
            <div>
              <SubheaderText noBottomOffset text={t("ui:subheader.metric_switch_reverse")} />
            </div>
          </div>
        </Form.Item>
        {/*<SubheaderText text={t("ui:subheader.metric_source")} />*/}
        <Form.Item
          label={t("ui:subheader.metric_source")}
          rules={[
            {
              required: true,
              message: t("validation:field_required"),
            },
          ]}
        >
          <AutocompleteAsync
            disabled={props.editMode}
            type="metricSource"
            value={!handleGetSourceByOrder(0)?.existingSourceId ? 0 : handleGetSourceByOrder(0)?.existingSourceId}
            additionalItems={[{ id: 0, text: t("ui:select.manual_method") }]}
            requestOptions={{
              isNew: true,
              isCreateForEachFiller: false,
            }}
            onChange={(v, mS) => handleChangeFirstSource(v, mS as MetricSourceDto)}
          />
        </Form.Item>
        {!handleGetSourceByOrder(0)?.existingSourceId ? (
          <>
            <Form.Item
              label={
                <div className="d-flex">
                  {t("ui:subheader.chart_name")}
                  <Tooltip title={t("text:chart-name-tooltip")}>
                    <FiHelpCircle className="chart-name-icon ml-1" />
                  </Tooltip>
                </div>
              }
            >
              <Input
                autoComplete="off"
                value={handleGetSourceByOrder(0)?.label ?? ""}
                onChange={(e) =>
                  props.onChangeMetric({
                    ...props.metric,
                    sources: props.metric?.sources?.map((s) => (s.order == 0 ? { ...s, label: e.target.value } : s)),
                  })
                }
                placeholder={t("ui:placeholder.issue_name")}
              />
            </Form.Item>
            <Form.Item
              label={t("ui:subheader.metric_filling")}
              rules={[
                {
                  required: true,
                  message: t("validation:field_required"),
                },
              ]}
            >
              <AutocompleteAsync
                disabled={props.editMode}
                type="rolePosition"
                value={handleGetSourceByOrder(0)?.fillerRolePositionId}
                requestOptions={{
                  orgchartId: props.role?.orgchartId,
                  roleId: props.roleId,
                }}
                onChange={(v) => {
                  props.onChangeMetric({
                    ...props.metric,
                    sources: props.metric?.sources?.map((s) =>
                      s.order == 0
                        ? { ...s, fillerRolePositionId: +v }
                        : s.order == 1 && s.existingSourceId == null
                        ? { ...s, fillerRolePositionId: +v }
                        : s
                    ),
                  });
                }}
              />
            </Form.Item>
            <Form.Item className="form-switch">
              <div className="d-stack-row align-center mt-2">
                <div className="mr-2">
                  <Switch
                    disabled={props.editMode ? true : props.isDoubleMetric || !!handleGetSourceByOrder(1)}
                    checked={handleGetSourceByOrder(0)?.isCreateForEachFiller}
                    onChange={(v) =>
                      props.onChangeMetric({
                        ...props.metric,
                        sources: props.metric?.sources?.map((s) =>
                          s.order == 0 ? { ...s, isCreateForEachFiller: v } : s
                        ),
                      })
                    }
                  />
                </div>
                <div>
                  <SubheaderText noBottomOffset text={t("ui:subheader.metric_switch_fix")} />
                </div>
              </div>
            </Form.Item>
            {/*<SubheaderText text={t("ui:subheader.metric_interval")} />*/}
            <Form.Item
              label={t("ui:subheader.metric_interval")}
              rules={[
                {
                  required: true,
                  message: t("validation:field_required"),
                },
              ]}
            >
              <Select
                disabled={props.editMode}
                items={props.intervals?.map((i) => ({
                  id: i.id!,
                  value: String(i.id!),
                  text: i.name!,
                }))}
                value={handleGetSourceByOrder(0)?.companyInterval?.name}
                onChange={(v) =>
                  props.onChangeMetric({
                    ...props.metric,
                    sources: props.metric?.sources?.map((s) =>
                      s.order == 0
                        ? { ...s, companyIntervalId: +v, companyInterval: props.intervals?.find((i) => i.id == +v) }
                        : s.order == 1 && s.existingSourceId == null
                        ? { ...s, companyIntervalId: +v }
                        : s
                    ),
                  })
                }
              />
            </Form.Item>
          </>
        ) : null}
        <Form.Item>
          <div className="d-stack-row align-center">
            <div className="mr-2">
              <Switch
                disabled={props.editMode}
                checked={props.isDoubleMetric || !!handleGetSourceByOrder(1)}
                onChange={(value) => props.onIsDoubleMetricChange(value)}
              />
            </div>
            <div>
              <SubheaderText noBottomOffset text={t("ui:subheader.metric_switch_double")} />
            </div>
          </div>
        </Form.Item>
        {props.isDoubleMetric || handleGetSourceByOrder(1) ? (
          <>
            <SubheaderText text={t("ui:subheader.metric_source")} />
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t("validation:field_required"),
                },
              ]}
            >
              <AutocompleteAsync
                disabled={props.editMode}
                type="metricSource"
                value={
                  !handleGetSourceByOrder(1)
                    ? null
                    : !handleGetSourceByOrder(1)?.existingSourceId
                    ? 0
                    : handleGetSourceByOrder(1)?.existingSourceId
                }
                additionalItems={[
                  {
                    id: 0,
                    text: t("ui:select.manual_method"),
                    disabled: !!handleGetSourceByOrder(0)?.existingSourceId,
                  },
                ]}
                requestOptions={{
                  isNew: true,
                  isCreateForEachFiller: false,
                  companyIntervalId: handleGetSourceByOrder(0)?.companyIntervalId,
                }}
                onChange={(v, mS) => handleChangeSecondSource(v, mS as MetricSourceDto)}
              />
              {handleGetSourceByOrder(1) &&
                handleGetSourceByOrder(0)?.existingSourceId != null &&
                handleGetSourceByOrder(1)?.existingSourceId != null &&
                handleGetSourceByOrder(0)?.existingSourceId == handleGetSourceByOrder(1)?.existingSourceId && (
                  <span style={{ color: "#E31D02", fontSize: "12px" }}>{t("text:metric_equal_source_error")}</span>
                )}
            </Form.Item>
            {handleGetSourceByOrder(1) && !handleGetSourceByOrder(1)?.existingSourceId && (
              <Form.Item
                label={
                  <div className="d-flex">
                    {t("ui:subheader.chart_name")}
                    <Tooltip title={t("text:chart-name-tooltip")}>
                      <FiHelpCircle className="chart-name-icon ml-1" />
                    </Tooltip>
                  </div>
                }
              >
                <Input
                  autoComplete="off"
                  value={handleGetSourceByOrder(1)?.label ?? ""}
                  onChange={(e) =>
                    props.onChangeMetric({
                      ...props.metric,
                      sources: props.metric?.sources?.map((s) => (s.order == 1 ? { ...s, label: e.target.value } : s)),
                    })
                  }
                  placeholder={t("ui:placeholder.issue_name")}
                />
              </Form.Item>
            )}
          </>
        ) : null}
      </Form>
      <DialogActions>
        <Button onClick={props.toggleDialog} variant="text">
          {t("ui:button.cancel")}
        </Button>
        <Button
          onClick={handleSubmit}
          variant="filled"
          disabled={
            (handleGetSourceByOrder(1) &&
              handleGetSourceByOrder(0)?.existingSourceId != null &&
              handleGetSourceByOrder(1)?.existingSourceId != null &&
              handleGetSourceByOrder(0)?.existingSourceId == handleGetSourceByOrder(1)?.existingSourceId) ||
            (!handleGetSourceByOrder(1) && props.isDoubleMetric)
          }
        >
          {props.editMode ? t("ui:button.save") : t("ui:button.create")}
        </Button>
      </DialogActions>
    </Dialog>
  );
});
