import React from "react";
import "./DatePicker.scss";
import "antd/es/date-picker/style/index";
import { Dayjs } from "dayjs";
import { PickerDateProps } from "antd/lib/date-picker/generatePicker";
import clsx from "clsx";
import "react-day-picker/dist/style.css";
import { DatePicker as DatePickerAntD } from "antd";

type TDatePickerView = {
  // firstDayOfWeek?: number;
  readOnly?: boolean;
} & PickerDateProps<Dayjs>;

export const { RangePicker } = DatePickerAntD;

export function DatePickerView({ className, readOnly, ...rest }: TDatePickerView) {
  return (
    <DatePickerAntD
      className={clsx("uiKit-datePicker", className ?? "", {
        "no-interact": readOnly,
      })}
      showToday={false}
      {...rest}
    />
  );
}
