import React, { useEffect, useState } from "react";
import "./FindRegulationSectionDialog.scss";
import { FindRegulationSectionDialogView } from "./FindRegulationSectionDialogView";
import { useNotifier, useRootStore } from "../../../../hooks";
import { useTranslation } from "react-i18next";
import {IFindRegulationSectionDialog} from "./FindRegulationSectionDialog.interface";
import { AutocompleteModel } from "../../../../api";
import { observer } from "mobx-react-lite";
import useDebounce from "../../../../hooks/useDebounce";

const FindRegulationSectionDialogObserver = ({ open, onSaveSection , onClose, currentRegulationId }: IFindRegulationSectionDialog) => {

  const { regulationStore } = useRootStore();
  const notifier = useNotifier();
  const { t } = useTranslation();

  const handleError = () => {
    notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
  };

  const [sectionName, setSectionName] = useState<string | undefined>("");
  const [initialFetchIsFinish, setInitialFetchIsFinish] = useState<boolean>(false);

  const debouncedSearchValue = useDebounce(sectionName, 400);

  const handleChangeSectionName = (newValue : string) => setSectionName(newValue);

  const handleSelectSection = (section : AutocompleteModel | undefined) => {
    onSaveSection(section);
    onClose();
  };

  // const handleReset = () =>

  const handleLoadNextRegulations = () => regulationStore.fetchNextPageSectionAutocomplete();

  useEffect(() => {
    if(initialFetchIsFinish){
      regulationStore.setAutocompleteSectionFilters({
        name : sectionName && sectionName.length > 0 ? sectionName : undefined,
      });
      regulationStore.initialFetchSectionAutocomplete(true);
    }
  }, [debouncedSearchValue]);

  useEffect(() => {
    console.log(44);
    regulationStore.setOnError(handleError)
    regulationStore.setAutocompleteSectionFilters({hasNoRegulationId : currentRegulationId});
    regulationStore.initialFetchSectionAutocomplete(true)
      .then((results) => {
      setInitialFetchIsFinish(true);
    }).catch(err => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" }));

  }, []);

  return (
    <FindRegulationSectionDialogView
      sections={regulationStore.getAutocompleteSections}
      onSelectSection={handleSelectSection}
      onChangeSectionName={handleChangeSectionName}
      sectionName={sectionName}
      isLoading={regulationStore.getAutocompleteLoading}
      loadNextSections={handleLoadNextRegulations}
      onClose={onClose}
      open={open}
      isDone={regulationStore.getSectionAutocompletePagingOptions.pageIsLast}
    />
  );
};

export const FindRegulationSectionDialog = observer(FindRegulationSectionDialogObserver);
