import React from "react";
import "./TagCheckableGroup.scss";
import { TTagCheckableGroupItem } from "./TagCheckableGroup";
import { TagCheckable } from "../tagCheckable/TagCheckable";
import clsx from "clsx";
import { theme } from "antd";
import { addAlphaToHex } from "../../../../../helpers/colorFunctions";

interface ITagCheckableGroupView {
  className?: string;
  value: (string | number)[];
  items: TTagCheckableGroupItem[];
  onChange: (value: string | number) => void;
}

export function TagCheckableGroupView(props: ITagCheckableGroupView) {
  const { useToken } = theme;
  const { token } = useToken();

  const itemStyle = {
    "--col-bg": token.colorBgContainer,
    "--col-bg-alt": addAlphaToHex(token.colorBgContainer, 0.7),
    "--col-bg-hover": addAlphaToHex(token.colorBgContainer, 0.5),
  } as React.CSSProperties;

  return (
    <div className={clsx("uiKit-tagCheckableGroup d-flex flex-nowrap", props.className ?? "")}>
      {props.items.map((item) => (
        <TagCheckable
          key={item.value}
          checked={props.value.indexOf(item.value) > -1}
          onChange={(checked) => props.onChange(item.value)}
          children={item.text}
          className={clsx("uiKit-tagCheckableGroupItem", item.className ?? "")}
          style={{ ...itemStyle, ...item.style }}
        />
      ))}
    </div>
  );
}
