import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { useRootStore } from "../../../hooks";
import ImageViewerView from "./ImageViewerView";
import { ImgsViewerImageType, ImgsViewerProps } from "react-images-viewer";
import { toJS } from "mobx";

interface IImageViewer {
  images?: ImgsViewerImageType[];
  viewerProps?: ImgsViewerProps;

  onClose?: () => void;
}

function ImageViewer(props: IImageViewer) {
  const { appStore } = useRootStore();
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);

  const handleClose = () => {
    setCurrentImageIndex(0);
    if (props.onClose) {
      props.onClose();
      return;
    }
    appStore.setImagesToViewLinks([]);
  };

  const handlePrevCLick = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    }
  };

  const handleNextCLick = () => {
    const imagesArrayLength = (props.images ?? toJS(appStore.getImagesToViewLinks)).length;
    if (currentImageIndex < imagesArrayLength - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
    }
  };

  const handleThumbnailCLick = (value: number) => {
    if (currentImageIndex != value) {
      setCurrentImageIndex(value);
    }
  };

  return (
    <ImageViewerView
      images={props.images ?? toJS(appStore.getImagesToViewLinks)}
      viewerProps={props.viewerProps}
      currentImageIndex={currentImageIndex}
      onClickPrev={handlePrevCLick}
      onClickNext={handleNextCLick}
      onClickThumbnail={handleThumbnailCLick}
      onClose={handleClose}
    />
  );
}

export default observer(ImageViewer);
