import React, { useState } from "react";
import { User2CompanyDto } from "../../../../api";
import "./CompanySwitcher.scss";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { Avatar, Dropdown, Icon, Text } from "../../../uiKit";
import { FiChevronDown } from "@react-icons/all-files/fi/FiChevronDown";

export interface ICompanySwitcherView {
  companiesList: User2CompanyDto[];
  currentCompanyId: number | null;
  isMenuOpen: boolean;
  menuAnchorEl: any;

  handleMenuButtonClick(event: any): void;
  handleMenuClose(): void;
  handleMenuItemClick(companyId: number | null | undefined): any;
  handleCompanyManageClick(): void;
}

function CompanySwitcherView(props: ICompanySwitcherView) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const getCompanyName = (v: string | null) => (v && v.trim().length > 0 ? v : t("common:misc.new_company"));

  return (
    <Dropdown
      destroyPopupOnHide
      trigger={["click"]}
      align={{ offset: [0, 4] }}
      overlayClassName="company_switcher-overlay"
      onOpenChange={setIsOpen}
      items={[
        ...props.companiesList.map((c) => ({
          key: String(c.companyId as number),
          disabled: c.companyId == props.currentCompanyId,
          icon: (
            <Avatar text={c.company?.name} src={c.company?.image?.url} color={c.company?.color} size={18} gap={6} />
          ),
          onClick: () => props.handleMenuItemClick(c.company?.id),
          text:
            (c.company?.name ?? "").trim().length > 0
              ? (c.company?.name as string)
              : (c.company?.nameFallback as string),
        })),
        // .sort((a, b) => a.text.localeCompare(b.text)),
        { key: "div", isDivider: true },
        {
          key: "cm",
          text: t("common:menu.top_bar_company_switcher.company_manage"),
          onClick: () => props.handleCompanyManageClick(),
        },
      ]}
    >
      <div className="company-switcher-menu_container d-flex align-center px-3">
        <Avatar
          size={36}
          className="company-switcher_avatar"
          type="company"
          text={
            props?.companiesList?.find((c: User2CompanyDto) => c.companyId == props.currentCompanyId)?.company?.name ??
            props?.companiesList?.find((c: User2CompanyDto) => c.companyId == props.currentCompanyId)?.company
              ?.nameFallback
          }
          color={
            props?.companiesList?.find((c: User2CompanyDto) => c.companyId == props.currentCompanyId)?.company?.color
          }
          src={
            props?.companiesList?.find((c: User2CompanyDto) => c.companyId == props.currentCompanyId)?.company?.image
              ?.url ?? undefined
          }
        />
        <div
          className="flex-grow-1 d-flex flex-column mx-3"
          style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}
        >
          <Text
            className="__name"
            children={
              props
                ? getCompanyName(
                    props?.companiesList?.find((c: User2CompanyDto) => c.companyId === props.currentCompanyId)?.company
                      ?.name ?? null
                  )
                : t("text:loading_data")
            }
          />
          <Text className="__email" children={t("ui:text.change_company")} />
        </div>
        <Icon className={`__icon ${isOpen ? "__open" : ""}`}>
          <FiChevronDown />
        </Icon>
      </div>
    </Dropdown>
  );
}

export default observer(CompanySwitcherView);
