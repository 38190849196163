import { FORMAT_TEXT_COMMAND, REDO_COMMAND, UNDO_COMMAND } from "lexical";
import { TOGGLE_LINK_COMMAND } from "@lexical/link";
import * as React from "react";
import { ForwardedRef, forwardRef, memo } from "react";
import { ColorPicker } from "../../ui/ColorPicker/ColorPicker";
import { sanitizeUrl } from "../../utils/url";
import { IS_APPLE } from "../../utils/environment";
import { FormatSelect } from "./FormatSelect/FormatSelect";
import { blockTypeToBlockName } from "./FormatSelect/FormatSelect.interface";
import { Divider, IconButton, Input, Popover } from "../../../../uiKit";
import {
  AiOutlineBgColors,
  AiOutlineFontColors,
  AiOutlineTable,
  AiOutlineYoutube,
  FiBold,
  FiCheck,
  FiCode,
  FiImage,
  FiItalic,
  FiLink,
  FiUnderline,
  IoIosRedo,
  IoIosUndo,
  VscHorizontalRule,
} from "react-icons/all";
import { currentUrl, parseUrl, urlValidation, youtubeUrlValidation } from "../../utils/urlValidation";
import { useTranslation } from "react-i18next";
import { INSERT_HORIZONTAL_RULE_COMMAND } from "@lexical/react/LexicalHorizontalRuleNode";
import { FileInput } from "../../ui/FileInput";
import { InsertTableDialog } from "../TablePlugin/TablePlugin";
import { INSERT_YOUTUBE_COMMAND } from "../YouTubePlugin/YouTubePlugin";
import { IToolbarPluginView } from "./ToolbarPlugin.interface";
import { BiLabel } from "@react-icons/all-files/bi/BiLabel";
import { useLocation } from "react-router-dom";

export const ToolbarPluginView = memo(
  forwardRef((props: IToolbarPluginView, ref: ForwardedRef<HTMLInputElement>) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();

    return (
      <>
        <InsertTableDialog
          activeEditor={props.activeEditor}
          onClose={() => props.setIsOpenTableDialog(false)}
          open={props.isOpenTableDialog}
        />
        <div className="toolbar">
          <IconButton
            disabled={!props.canUndo || !props.isEditable}
            onClick={() => {
              props.activeEditor.dispatchCommand(UNDO_COMMAND, undefined);
            }}
            title={
              IS_APPLE
                ? `${t("common:text_editor_toolbar.undo")} (⌘Z)`
                : `${t("common:text_editor_toolbar.undo")} (Ctrl+Z)`
            }
            icon={<IoIosUndo />}
            className="mr-1"
          />
          <IconButton
            disabled={!props.canRedo || !props.isEditable}
            onClick={() => {
              props.activeEditor.dispatchCommand(REDO_COMMAND, undefined);
            }}
            title={
              IS_APPLE
                ? `${t("common:text_editor_toolbar.redo")} (⌘Y)`
                : `${t("common:text_editor_toolbar.redo")} (Ctrl+Y)`
            }
            icon={<IoIosRedo />}
            className="mr-1"
          />
          <Divider isVertical />
          {props.blockType in blockTypeToBlockName && props.activeEditor === props.editor && (
            <>
              <FormatSelect
                disabled={!props.isEditable}
                blockType={props.blockType}
                editor={props.editor}
                formatType="block"
              />
              <Divider isVertical />
            </>
          )}
          <>
            <IconButton
              disabled={!props.isEditable}
              onClick={() => {
                props.activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
              }}
              title={
                IS_APPLE
                  ? `${t("common:text_editor_toolbar.bold")} (⌘B)`
                  : `${t("common:text_editor_toolbar.bold")} (Ctrl+B)`
              }
              className="ml-1"
              icon={<FiBold className={props.isBold ? "toolbar__active" : ""} />}
            />
            <IconButton
              disabled={!props.isEditable}
              onClick={() => {
                props.activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
              }}
              title={
                IS_APPLE
                  ? `${t("common:text_editor_toolbar.italic")} (⌘I)`
                  : `${t("common:text_editor_toolbar.italic")} (Ctrl+I)`
              }
              icon={<FiItalic className={props.isItalic ? "toolbar__active" : ""} />}
            />
            <IconButton
              disabled={!props.isEditable}
              onClick={() => {
                props.activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
              }}
              title={
                IS_APPLE
                  ? `${t("common:text_editor_toolbar.underline")} (⌘U)`
                  : `${t("common:text_editor_toolbar.underline")} (Ctrl+U)`
              }
              icon={<FiUnderline className={props.isUnderline ? "toolbar__active" : ""} />}
            />
            <IconButton
              disabled={!props.isEditable}
              onClick={() => {
                props.activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "code");
              }}
              title={t("common:text_editor_toolbar.code")}
              icon={<FiCode className={props.isCode ? "toolbar__active" : ""} />}
            />
            <Popover
              placement="bottom"
              trigger="click"
              open={props.openLinkPopup}
              onOpenChange={() => {
                !props.isLink && props.setOpenLinkPopup(!props.openLinkPopup);
              }}
              content={
                <div className="d-stack-row align-center justify-center">
                  <Input
                    size="middle"
                    status={urlValidation(props.linkUrl) || !props.linkUrl ? "" : "error"}
                    value={props.linkUrl}
                    onChange={(event) => {
                      props.setLinkUrl(event.target.value);
                    }}
                    placeholder={t("ui:placeholder.text_editor_url")}
                  />
                  <IconButton
                    className="ml-3"
                    icon={<FiCheck size={25} />}
                    onClick={() => {
                      props.editor.dispatchCommand(
                        TOGGLE_LINK_COMMAND,
                        sanitizeUrl("https://" + currentUrl(props.linkUrl))
                      );
                      props.setOpenLinkPopup(false);
                    }}
                    disabled={!urlValidation(props.linkUrl) || !props.linkUrl}
                  />
                </div>
              }
            >
              <IconButton
                disabled={!props.isEditable || !props.activeLinkButton}
                onClick={props.insertLink}
                title={t("common:text_editor_toolbar.link")}
                icon={<FiLink className={props.isLink ? "toolbar__active" : ""} />}
              />
            </Popover>
            <ColorPicker
              disabled={!props.isEditable}
              color={props.fontColor}
              onChange={props.onFontColorSelect}
              icon={<AiOutlineFontColors title={t("common:text_editor_toolbar.color")} size={15} />}
            />
            <ColorPicker
              disabled={!props.isEditable}
              color={props.bgColor}
              onChange={props.onBgColorSelect}
              icon={<AiOutlineBgColors title={t("common:text_editor_toolbar.background")} size={15} />}
            />
            <Divider isVertical />
            <IconButton
              disabled={!props.isEditable}
              title={t("common:text_editor_toolbar.hr")}
              onClick={() => {
                props.activeEditor.dispatchCommand(INSERT_HORIZONTAL_RULE_COMMAND, undefined);
              }}
              icon={<VscHorizontalRule size={20} />}
            />
            <IconButton
              disabled={!props.isEditable}
              title={t("common:text_editor_toolbar.image")}
              onClick={(e) => props.onUploadRefClick(e)}
              icon={<FiImage size={15} />}
            />
            <FileInput
              ref={ref}
              label="Image Upload"
              onChange={props.onUploadImage}
              accept="image/*"
              data-test-id="image-modal-file-upload"
            />
            <IconButton
              disabled={!props.isEditable}
              title={t("common:text_editor_toolbar.table")}
              onClick={() => props.setIsOpenTableDialog(true)}
              icon={<AiOutlineTable size={18} />}
            />
            <Popover
              placement="bottom"
              trigger="click"
              open={props.openYoutubePopup}
              onOpenChange={() => {
                props.setIsOpenYoutubePopup(!props.openYoutubePopup);
              }}
              content={
                <div className="d-stack-row align-center justify-center">
                  <Input
                    size="middle"
                    status={youtubeUrlValidation(props.videoUrl) || !props.videoUrl ? "" : "error"}
                    value={props.videoUrl}
                    onChange={(event) => {
                      props.setVideoUrl(event.target.value);
                    }}
                    placeholder={t("ui:placeholder.text_editor_url")}
                  />
                  <IconButton
                    className="ml-3"
                    icon={<FiCheck size={25} />}
                    onClick={() => {
                      props.videoUrl &&
                        props.editor.dispatchCommand(INSERT_YOUTUBE_COMMAND, parseUrl(props.videoUrl)?.id!);
                      props.setIsOpenYoutubePopup(false);
                      props.setVideoUrl("");
                    }}
                    disabled={!youtubeUrlValidation(props.videoUrl) || !props.videoUrl}
                  />
                </div>
              }
            >
              <IconButton
                disabled={!props.isEditable}
                title={t("common:text_editor_toolbar.video")}
                onClick={() => {
                  props.setIsOpenYoutubePopup(true);
                }}
                icon={<AiOutlineYoutube size={20} />}
              />
            </Popover>
            {pathname == "/edit-content" && (
              <IconButton
                disabled={!props.isEditable}
                onClick={(e) => {
                  props.onCreateAnchor(e);
                }}
                icon={<BiLabel />}
              />
            )}
          </>
          <Divider isVertical />
          <FormatSelect
            disabled={!props.isEditable}
            blockType={props.blockType}
            editor={props.editor}
            formatType="align"
            activeEditor={props.activeEditor}
            alignType={props.alignType}
          />
        </div>
      </>
    );
  })
);
