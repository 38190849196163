import React from "react";
import {IRegulationViewOptions} from "./RegulationViewOptions.interface";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../../uiKit"
import { Typography } from "antd";

export const RegulationViewOptions: React.FC<IRegulationViewOptions> = (props) => {
  const { t } = useTranslation();

  return (
    <div>
      {!props.testIsPassed && !!props.countQuestions && props.hasActiveContent && !props.isEditable && (
        <div className="d-stack-row spacing-2 justify-space-between align-center mt-2">
          {!props.isEditable && (
            <>
              <Typography.Text style={{fontWeight : 500, fontSize : "15px !important"}}>
                {t("parse:pass_test_intro")}
              </Typography.Text>

              <Button variant="filled" onClick={props.onClickPassTest}>
                {t("ui:button.pass_test")}
              </Button>
            </>
          )}
        </div>
      )}
      {!props.testIsPassed && !props.countQuestions && props.hasActiveContent && !props.isEditable && (
        <Button
          variant="filled"
          theme="success"
          className="mt-2"
          loading={props.isLoadingStudyBtn}
          onClick={props.onStudyRegulation}>
          {t("ui:button.study")}
        </Button>
      )}
    </div>
  );
};
