import React from "react";
import { CardIssue } from "../../../communications/components/cardIssue/CardIssue";
import { IReportIssueCard } from "../../Report.interface";

const ReportIssueCardMemo = ({ issueShort, reportsType }: IReportIssueCard) => {
  return (
    <div style={{ width: 350 }}>
      <CardIssue
        noDrag
        issue={issueShort}
        // @ts-ignore
        issueCaradType={reportsType}
      />
    </div>
  );
};

export const ReportIssueCard = React.memo(ReportIssueCardMemo, (x, y) => x.issueShort.id == y.issueShort.id);
