import clsx from "clsx";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NotificationDto } from "../../../api";
import { removeEmojiFromString } from "../../../helpers/stringFunctions";
import { useDateHelpers, useRootStore } from "../../../hooks";
import { ToolbarType } from "../../modules/textEditorOld/misc/consts";
import { TextEditor } from "../../modules/textEditorOld/TextEditor";
import { Avatar, Badge, Card, Text, Tooltip } from "../../uiKit";
import "./NotificationsCenterCard.scss";

interface INotificationsCenterCard {
  data: NotificationDto;
  onClick?: (data: NotificationDto) => void;
  onIsReadStatusChange?: (isRead: boolean) => void;
}

export function NotificationsCenterCard(props: INotificationsCenterCard) {
  const dateHelpers = useDateHelpers();
  const { authStore } = useRootStore();
  const { t } = useTranslation();
  const [isReadVirtual, setIsReadVirtual] = useState<boolean>(!!props.data.isRead);
  // const relativeTime = require("dayjs/plugin/relativeTime");
  // dayjs.extend(relativeTime);

  return (
    <Card
      variant="secondary"
      className={clsx("notifications_center__wrapper", {
        __readonly: authStore.getCurrentCompanyId != props.data.company?.id,
      })}
      clickable={true}
      // clickable={authStore.getCurrentCompanyId == props.data.company?.id}
      isShadowed={false}
      bodyStyle={{
        display: "flex",
        padding: "8px",
      }}
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        authStore.getCurrentCompanyId == props.data.company?.id && props.onClick && props.onClick(props.data);
      }}
    >
      <div className="flex-shrink-0">
        <Avatar
          text={[props.data.sender?.lastName, props.data.sender?.firstName]}
          src={props.data.sender?.avatar?.image?.url}
          color={props.data.sender?.color}
        />
      </div>
      <div className="flex-grow-1 px-2">
        <div>
          <Text
            weight="bold"
            children={
              (props.data.sender?.name ?? "").trim().length > 0
                ? props.data.sender?.name
                : props.data.sender?.nameFallback
            }
          />
          {((props.data.sender?.nameFallback ?? "") + (props.data.sender?.name ?? "")).trim().length > 0 && (
            <Text type="secondary" style={{ whiteSpace: "pre" }} children={" · "} />
          )}
          <Text type="secondary" children={dateHelpers.formatDateString(props.data.dateCreated as string)} />
        </div>
        <div>
          <Text children={removeEmojiFromString(props.data.contentDto?.name ?? "")} />
        </div>
        <div>
          {props.data.contentDto?.description?.startsWith("[{") ? (
            <div style={{ borderRadius: "4px", position: "relative", overflow: "hidden" }}>
              <TextEditor
                id={`te-notification-${String(props.data.id)}`}
                readOnly
                toolbarType={ToolbarType.Hidden}
                value={JSON.parse(props.data.contentDto?.description)}
              />
            </div>
          ) : (
            <Text size="12px" children={props.data.contentDto?.description?.trim()} />
          )}
        </div>
        {/*{authStore.getCurrentCompanyId != props.data.company?.id && (*/}
        <div>
          <Avatar
            size={12}
            className="mr-1"
            src={props.data.company?.image?.url}
            color={props.data.company?.color}
            text={props.data.company?.name ?? props.data.company?.nameFallback}
          />
          {/*<FiAlertCircle fontSize="8px" className="mr-1" color="var(--color-text-weak)" />*/}
          {/*<span className="__notification_company">Компания: </span>*/}
          <Text
            weight="bold"
            size="12px"
            type="secondary"
            children={
              (props.data.company?.name?.trim() ?? "").length > 0
                ? props.data.company?.name
                : props.data.company?.nameFallback
            }
          />
        </div>
        {/*)}*/}
      </div>
      <div className="flex-shrink-0" style={{ zIndex: 1 }}>
        <Tooltip overlay={t("parse:change_status")}>
          <div
            style={{ height: "8px", width: "8px", zIndex: 1 }}
            onClick={(event) => {
              // event.preventDefault();
              event.stopPropagation();
              setIsReadVirtual(!isReadVirtual);
              props.onIsReadStatusChange && props.onIsReadStatusChange(isReadVirtual);
            }}
          >
            <Badge className="__status-badge" status={isReadVirtual ? "success" : "processing"} count={0} />
          </div>
        </Tooltip>
      </div>
    </Card>
  );
}
