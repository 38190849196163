import React from "react";
import { useTranslation } from "react-i18next";
import { UserDto, UserShortDto } from "../../../api";
import { Avatar, Spin, Text } from "../../uiKit";
import { UserTooltip } from "../userTooltip/UserTooltip";
import { TUserInfoNameDisplayMode } from "./UserInfo";
import "./UserInfo.scss";
import { getUserShortFullName } from "../../../helpers/stringFunctions";

export interface IUserInfoView {
  className?: string;
  isLoading?: boolean;
  user: UserDto | UserShortDto | undefined;
  isTooltipDisabled?: boolean;
  avatarSize?: number;
  nameDisplayMode?: TUserInfoNameDisplayMode;
  width?: string;
  nameStyle?: React.CSSProperties;
  onError: () => void;
}

export const UserInfoViewMemo: React.FC<IUserInfoView> = (props) => {
  const { t } = useTranslation();

  const getUserNameByPropsType = (type: TUserInfoNameDisplayMode | undefined): string => {
    const userFullName =
      (props.user?.name ?? "").trim().length > 0 ? props.user?.name ?? "" : props.user?.nameFallback ?? "";
    const userLastNameAndFirstName = (props.user?.lastName ?? "").trim() + " " + (props.user?.firstName ?? "").trim();
    switch (type) {
      case "none":
        return "";
      case "lastNameAndFirstName":
        return userLastNameAndFirstName.trim().length > 0 ? userLastNameAndFirstName : userFullName;
      case "fullName":
        return userFullName;
    }
    return userLastNameAndFirstName.trim().length > 0
      ? getUserShortFullName(props.user?.lastName, props.user?.firstName, props.user?.middleName)
      : userFullName;
  };

  return (
    <UserTooltip
      tooltipProps={{ open: props.isTooltipDisabled == true || props.isLoading == undefined ? false : undefined }}
      user={props.user}
    >
      <div className={["user-info__container", props.className ?? ""].join(" ")}>
        {props.isLoading == true ? (
          <Spin style={{ lineHeight: 1 }} size="small" />
        ) : (
          <>
            <Avatar
              text={[props.user?.lastName, props.user?.firstName]}
              color={props.isLoading == null ? "rgb(39, 31, 48)" : props.user?.color}
              src={props.user?.avatar?.image?.url}
              size={props.avatarSize ?? "small"}
            />
            {props.nameDisplayMode != "none" && (
              <Text
                className="ml-2 user-info__name"
                style={props.nameStyle}
                children={
                  props.isLoading == undefined ? t("parse:user_deleted") : getUserNameByPropsType(props.nameDisplayMode)
                }
              />
            )}
          </>
        )}
      </div>
    </UserTooltip>
  );
};

export const UserInfoView = React.memo(
  UserInfoViewMemo,
  (x, y) => x?.user?.id == y?.user?.id && x.isLoading == y.isLoading
);
