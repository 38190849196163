import React from "react";
import { ListItemView } from "./ListItemView";
import { ListItemProps } from "antd/es/list";

interface IListItem extends ListItemProps {
  //
}

export function ListItem(props: IListItem) {
  return <ListItemView {...props} />;
}
