import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import "./OrgchartDrawerTabRecurringTasks.scss";
import { RecurringIssueDto, RoleDto } from "../../../../../../api";
import { OrgchartContext } from "../../../../../../contexts/orgchartContext";
import { FiX } from "@react-icons/all-files/fi/FiX";
import TaskRecurringCard from "../../taskRecurringCard/TaskRecurringCard";
import { useClaims } from "../../../../../../hooks";
import { permissionKeys } from "../../../../../../utils/permissions";
import ScrollTrigger from "../../../../../service/scrollTrigger/ScrollTrigger";
import { PagingModel } from "../../../../../../api/types";
import { PagingInfo } from "../../../../../../hooks/usePaging";
import DeleteRecurringIssueDialog from "../../../../dialogs/deleteRecurringIssueDialog/DeleteRecurringIssueDialog";
import RecurringIssueHistoryDialog from "../../../../dialogs/recurringIssueHistoryDialog/RecurringIssueHistoryDialog";
import VideoDialog from "../../../../dialogs/videoDialog/VideoDialog";
import RecurringIssueCreateEditDialog from "../../../../dialogs/recurringIssueCreateEditDialog/RecurringIssueCreateEditDialog";
import { TIssuesFilters } from "../../orgchartCommonDrawerTabs/orgchartCommonDrawerTabRecurringTasks/OrgchartCommonDrawerTabRecurringTasks";
import { Button, Empty, Icon, IconButton, Input, Select, SelectOption } from "../../../../../uiKit";
import { ExecutorTypes } from "../../../../../pages/driver/types/DriverTypes";

export interface IOrgchartDrawerTabRecurringTasks {
  functionData: RoleDto;
  isAddDialogOpen: boolean;
  formMode: boolean;
  isDeleteIssueDialogOpen: boolean;
  isIssueHistoryDialogOpen: boolean;
  issueToManageId: number | null;
  issueToManageData: RecurringIssueDto | null;
  issues: {
    items: RecurringIssueDto[];
    info: PagingInfo;
    lastResult: PagingModel<RecurringIssueDto> | null;
    loadNext: () => Promise<void>;
    reset: () => void;
    restart: () => Promise<void>;
  };
  createOn: "admins" | "executors" | "all" | undefined;
  issueFilters: TIssuesFilters;
  sortSettings: { by: string; order: string };

  setSortSettings: (value: { by: string; order: string }) => void;
  setIssueFilters: (value: TIssuesFilters) => void;
  setCreateOn: (value: "admins" | "executors" | "all" | undefined) => void;
  setIssueToManageData: (data: RecurringIssueDto | null) => void;
  onCardPauseClick: (issueId: number) => void;
  onCardWatchHistoryClick: (issueId: number) => void;
  onCardEditClick: (issue: RecurringIssueDto) => void;
  onCardDeleteClick: (issueId: number) => void;
  setIsDeleteIssueDialogOpen: (value: boolean) => void;
  setIsIssueHistoryDialogOpen: (value: boolean) => void;
  handleIssueDelete: () => void;

  setIsDrawerClosable(value: boolean): void;
  setFormMode(value: boolean): void;
  handleSetAddDialogOpen(value: boolean, type: ExecutorTypes | null): void;
}

function OrgchartDrawerTabRecurringTasksView(props: IOrgchartDrawerTabRecurringTasks) {
  const { t } = useTranslation();
  const orgchartContext = useContext(OrgchartContext);
  const claims = useClaims();

  const executorTypes = [
    { id: 0, name: t("common:menu.executor_select.admins_of_function"), value: "role_admins", disabled: false },
    { id: 1, name: t("common:menu.executor_select.executors_of_function"), value: "role_executors", disabled: false },
    { id: 2, name: t("common:menu.executor_select.all_admins"), value: "all_admins", disabled: false },
    { id: 3, name: t("common:menu.executor_select.all"), value: "all", disabled: false },
  ];

  const pauseTypes = [
    { id: 0, name: t("parse:all_tasks"), value: null, valueSelect: "null", disabled: false },
    { id: 1, name: t("parse:on_pause"), value: true, valueSelect: "true", disabled: false },
    { id: 2, name: t("parse:active"), value: false, valueSelect: "false", disabled: false },
  ];

  const sortBy = [
    { id: 0, name: t("parse:sort.by_date"), value: "date_created", disabled: false },
    { id: 1, name: t("parse:sort.by_name"), value: "name", disabled: false },
  ];

  const sortOrders = [
    { id: 0, name: t("common:misc.sort_items.ones_first"), value: "desc", disabled: false },
    { id: 1, name: t("common:misc.sort_items.old_ones"), value: "asc", disabled: false },
  ];

  const senderTypes = [
    { id: 0, name: t("parse:for_all"), value: null, valueSelect: "null", disabled: false },
    { id: 1, name: t("parse:for_admins"), value: 1, valueSelect: "1", disabled: false },
    { id: 2, name: t("parse:for_executors"), value: 2, valueSelect: "2", disabled: false },
  ];

  const executorPositionsTypes = [
    { id: 0, name: t("parse:for_all"), value: null, valueSelect: "null", disabled: false },
    { id: 1, name: t("parse:for_admins"), value: 1, valueSelect: "1", disabled: false },
    { id: 2, name: t("parse:for_executors"), value: 2, valueSelect: "2", disabled: false },
  ];

  return (
    <>
      <VideoDialog
        videoId="nNqciIUADZ8"
        trainingKey="ed.c.ri"
        name="recurringTasks"
        onWatchedClickObject={{ recurringTasks: true }}
        hidden={
          !claims.hasOneOf([
            permissionKeys.recurringIssue.add,
            permissionKeys.recurringIssue.edit,
            permissionKeys.recurringIssue.delete,
            permissionKeys.recurringIssue.pauseManage,
            permissionKeys.recurringIssue.viewHistory,
          ])
        }
        // hidden={!(orgchartContextState.openedDialog == "openFunction")}
      />
      <div className="pr-2">
        <div
          className="py-4 mb-n4 d-flex align-center justify-space-between"
          style={{
            position: "sticky",
            top: 0,
            transform: "translateY(-16px)",
            background: "var(--color-layout-drawer)",
            zIndex: 150,
          }}
        >
          {claims.has(permissionKeys.recurringIssue.add) && (
            <>
              <Button
                // items={[
                //   {
                //     id: 0,
                //     text: t("ui:misc.to_admins_of_function"),
                //     action: () => {
                //       props.setCreateOn && props.setCreateOn("admins");
                //       props.setFormMode(!props.formMode);
                //       props.setIssueToManageData(null);
                //     },
                //   },
                //   {
                //     id: 1,
                //     text: t("ui:misc.to_executors_of_function"),
                //     action: () => {
                //       props.setCreateOn && props.setCreateOn("executors");
                //       props.setFormMode(!props.formMode);
                //       props.setIssueToManageData(null);
                //     },
                //   },
                // ]}
                size="middle"
                // trigger={["click"]}
                disabled={props.formMode}
                children={t("ui:button.create_routine")}
                onClick={() => {
                  props.setCreateOn && props.setCreateOn("all");
                  props.setFormMode(!props.formMode);
                  props.setIssueToManageData(null);
                }}
              />
            </>
          )}
          {/*<Chip label={props.issues.info.totalItems ?? 0} />*/}
          <div className="d-stack">
            <Input
              className="mr-2"
              size="middle"
              style={{ width: "220px" }}
              placeholder={t("parse:task_name") as string}
              value={props.issueFilters.name}
              onInput={(event: any) => props.setIssueFilters({ ...props.issueFilters, name: event.target.value })}
            />
            <Select
              className="mr-2"
              size="middle"
              style={{ width: "140px" }}
              value={
                executorPositionsTypes.find((t) => t.value == props.issueFilters.executorPositionType)?.valueSelect ??
                "null"
              }
              onChange={(value: string | null) =>
                props.setIssueFilters({
                  ...props.issueFilters,
                  executorPositionType: executorPositionsTypes.find((t) => t.valueSelect == value)?.value ?? null,
                })
              }
            >
              {executorPositionsTypes.map((ex) => (
                <SelectOption key={ex.id} value={ex.valueSelect}>
                  {ex.name}
                </SelectOption>
              ))}
            </Select>
            <Select
              className="mr-2"
              size="middle"
              style={{ width: "110px" }}
              value={pauseTypes.find((t) => t.value == props.issueFilters.isPaused)?.valueSelect ?? "null"}
              onChange={(value) =>
                props.setIssueFilters({
                  ...props.issueFilters,
                  isPaused: pauseTypes.find((t) => t.valueSelect == value)?.value ?? null,
                })
              }
            >
              {pauseTypes.map((ex) => (
                <SelectOption key={ex.id} value={ex.valueSelect}>
                  {ex.name}
                </SelectOption>
              ))}
            </Select>
            <IconButton
              size="middle"
              disabled={
                !(
                  props.issueFilters.name.length > 0 ||
                  props.issueFilters.roleId != null ||
                  props.issueFilters.isPaused != null ||
                  props.issueFilters.executorConfigType.length != 0 ||
                  props.issueFilters.executorPositionType != null
                )
              }
              onClick={() =>
                props.setIssueFilters({
                  ...props.issueFilters,
                  name: "",
                  roleId: null,
                  isPaused: null,
                  executorConfigType: "",
                  executorPositionType: null,
                })
              }
            >
              <Icon component={() => <FiX />} />
            </IconButton>
          </div>
          <div className="d-stack spacing-3">
            {/*<SelectAlternativeFilled*/}
            {/*  value={props.sortSettings.by ?? "date_created"}*/}
            {/*  onChange={(event: any) =>*/}
            {/*    props.setSortSettings({*/}
            {/*      ...props.sortSettings,*/}
            {/*      by: event.target.value ?? "date_created",*/}
            {/*    })*/}
            {/*  }*/}
            {/*  displayEmpty*/}
            {/*  showPlaceholder*/}
            {/*>*/}
            {/*  {sortBy.map((sb) => (*/}
            {/*    <MenuItem key={sb.id} value={sb.value}>*/}
            {/*      {sb.name}*/}
            {/*    </MenuItem>*/}
            {/*  ))}*/}
            {/*</SelectAlternativeFilled>*/}
            <Select
              size="middle"
              value={props.sortSettings.order ?? "desc"}
              onChange={(value) =>
                props.setSortSettings({
                  ...props.sortSettings,
                  order: value ?? "desc",
                })
              }
            >
              {sortOrders.map((so) => (
                <SelectOption key={so.id} value={so.value}>
                  {so.name}
                </SelectOption>
              ))}
            </Select>
          </div>
        </div>
        <DeleteRecurringIssueDialog
          open={props.isDeleteIssueDialogOpen}
          onDelete={props.handleIssueDelete}
          onClose={() => props.setIsDeleteIssueDialogOpen(false)}
        />
        <RecurringIssueHistoryDialog
          open={props.isIssueHistoryDialogOpen}
          onClose={() => props.setIsIssueHistoryDialogOpen(false)}
          issueId={props.issueToManageId}
        />
        <RecurringIssueCreateEditDialog
          open={props.formMode}
          functionData={props.functionData ?? undefined}
          createOn={props.createOn}
          data={props.issueToManageData ?? undefined}
          onCreate={() => {
            props.setFormMode(false);
            props.issues.reset();
            props.issues.restart();
            props.setCreateOn && props.setCreateOn(undefined);
          }}
          onSave={() => {
            props.setFormMode(false);
            props.issues.reset();
            props.setIssueToManageData(null);
            props.issues.restart();
            props.setCreateOn && props.setCreateOn(undefined);
          }}
          onClose={() => {
            props.setFormMode(!props.formMode);
            props.setIssueToManageData(null);
            props.setCreateOn && props.setCreateOn(undefined);
          }}
        />
        {/*{props.formMode ? (*/}
        {/*  <TaskRecurringCreateEditForm*/}
        {/*    functionData={props.functionData}*/}
        {/*    createOn={props.createOn}*/}
        {/*    data={props.issueToManageData ?? undefined}*/}
        {/*    onCreate={() => {*/}
        {/*      props.setFormMode(false);*/}
        {/*      props.issues.reset();*/}
        {/*      props.issues.restart();*/}
        {/*      props.setCreateOn && props.setCreateOn(undefined);*/}
        {/*    }}*/}
        {/*    onSave={() => {*/}
        {/*      props.setFormMode(false);*/}
        {/*      props.issues.reset();*/}
        {/*      props.setIssueToManageData(null);*/}
        {/*      props.issues.restart();*/}
        {/*      props.setCreateOn && props.setCreateOn(undefined);*/}
        {/*    }}*/}
        {/*  />*/}
        {/*) : (*/}
        <>
          {props.issues.items.length > 0 && (
            <div className="d-stack-column spacing-2">
              {props.issues.items.map((issue) => (
                <TaskRecurringCard
                  key={issue.id}
                  data={issue}
                  onPauseClick={() => props.onCardPauseClick(issue.id as number)}
                  onWatchHistoryClick={() => props.onCardWatchHistoryClick(issue.id as number)}
                  onEditClick={(i) => props.onCardEditClick(i)}
                  onDeleteClick={() => props.onCardDeleteClick(issue.id as number)}
                />
              ))}
            </div>
          )}

          <ScrollTrigger
            onIntersection={() => {
              if (props.functionData?.id == null) return;
              props.issues.loadNext();
            }}
            hidden={props.issues.info.isDone}
            marginTop={props.issues.items.length > 0}
          />

          {props.issues.items.length == 0 && props.issues.info.isDone && <Empty className="mt-3" />}
        </>
        {/*)}*/}
      </div>
    </>
  );
}

export default OrgchartDrawerTabRecurringTasksView;
