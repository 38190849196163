import React from "react";
import { Select, SelectProps } from "antd";
import "./Select.scss";
import { useTranslation } from "react-i18next";
import { SelectOption } from "../selectOption/SelectOption";
import clsx from "clsx";
import { Icon } from "../icon/Icon";

export interface ISelectItem {
  id?: number;
  value: number | string;
  icon?: React.ReactNode;
  text?: string;
  disabled?: boolean;
}

interface ISelectView extends SelectProps {
  items?: ISelectItem[];
  readOnly?: boolean;
  variant?: "default" | "filled";
  fullWidth?: boolean;
}

function generateOptions(items: ISelectItem[]) {
  // const { devStore } = useRootStore();

  return items.map((option, index) => (
    <SelectOption value={option.value} key={index} disabled={option.disabled}>
      <div className="d-flex align-center full-width">
        {option.icon != null && (
          <Icon style={{ flexShrink: 0 }} className="mr-1" component={() => option.icon as React.ReactElement} />
        )}
        <span style={{ overflow: "hidden", textOverflow: "ellipsis" }} children={option.text} />
      </div>
    </SelectOption>
  ));
}

export function SelectView({ items, className, readOnly, variant, fullWidth, ...rest }: ISelectView) {
  const { t } = useTranslation();

  return (
    <Select
      placeholder={t("ui:placeholder.click_to_select")}
      className={clsx("uiKit-select", className ?? "", {
        "no-interact": readOnly,
        __filled: variant == "filled",
        "full-width": fullWidth,
      })}
      size="large"
      optionFilterProp="children"
      {...rest}
      children={items ? generateOptions(items) : rest.children}
    />
  );
}
