import React from "react";
import Menu from "antd/lib/menu";
import "./MenuSubMenu.scss";
import { SubMenuProps } from "antd";

interface IMenuSubMenu extends SubMenuProps {
  //
}

const { SubMenu } = Menu;

export function MenuSubMenu(props: IMenuSubMenu) {
  return <SubMenu {...props} />;
}
