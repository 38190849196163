import React from "react";
import { RoleDto } from "../../../../api";
import { useTranslation } from "react-i18next";

export interface IOrgchartFunctionMoveDialogView {
  allRoles: RoleDto[];
  selectedRoleId: number | null;
}

export const LabelSwitch: React.FC<IOrgchartFunctionMoveDialogView> = (props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="d-flex align-center">
      <span style={{ fontSize: "14px", marginLeft: "0.75em" }}>{t("parse:change_function_color_to")}</span>
      <div
        style={{
          marginLeft: "8px",
          width: "20px",
          height: "20px",
          borderRadius: "100%",
          flexShrink: 0,
          backgroundColor: props.allRoles.find((r: RoleDto) => r.id == props.selectedRoleId)?.colorHex ?? undefined,
          // boxShadow: "var(--shadow-middle-bottom)",
          border: "1px solid var(--color-primary-base)",
        }}
      />
    </div>
  );
};
