import React, { useState } from "react";
import { CenterPageContentLayout } from "../../../layouts/centerPageContentLayout/CenterPageContentLayout";
import { IRegulationContent } from "./Pages.interface";
import { initialContent, stringToContent } from "../../../../../utils/textEditor";
import { RegulationHistory } from "../components/RegulationHistory/RegulationHistory";
import { UserShortDto } from "../../../../../api";
import { allowsRegulationKeys, progressUsersKeys } from "../misc/consts";
import { useTranslation } from "react-i18next";
import { ListUsersDialog } from "../../../dialogs/listUsersDialog/ListUsersDialog";
import { defineAllow } from "../containers/regulation/helpers";
import { ProgressUsers } from "../components/ProgressUsers/ProgressUsers";
import { TextEditor } from "../../../textEditorOld/TextEditor";
import { RedirectedRegulationButtons } from "../components/RedirectedRegulationButtons/RedirectedRegulationButtons";
import { RegulationViewOptions } from "../components/RegulationViewOptions/RegulationViewOptions";
import { RegulationContentHeader } from "../components/RegulationContentHeader/RegulationContentHeader";
import { CreateTestsForm } from "../../../forms/createTestsForm/CreateTestsForm";
import { RegulationSkeleton } from "../components/skeletons/regulation/RegulationSkeleton";
import { Card } from "../../../../uiKit";

export const RegulationContent: React.FC<IRegulationContent> = (props) => {
  const { t } = useTranslation();

  const [isOpenUserProgressDialog, setIsOpenUserProgressDialog] = useState<boolean>(false);
  const [usersProgress, setUsersProgress] = useState<UserShortDto[]>([]);
  const [usersProgressType, setUsersProgressType] = useState<progressUsersKeys | undefined>(undefined);

  const handleOpenUserProgressDialog = (progressType: progressUsersKeys) => {
    progressType === progressUsersKeys.whoStudied &&
      setUsersProgress(props.regulation?.usersWhoStudied?.map((user) => user.user!) ?? []);
    progressType === progressUsersKeys.toStudy && setUsersProgress(props.regulation?.usersToStudy ?? []);
    setIsOpenUserProgressDialog(true);
    setUsersProgressType(progressType);
  };
  const handleCloseUserProgressDialog = () => {
    setIsOpenUserProgressDialog(false);
    setUsersProgress([]);
    setUsersProgressType(undefined);
  };

  const handleGetTitleForProgressDialog = (): string => {
    if (usersProgressType === progressUsersKeys.toStudy) return t("ui:title.no_learnt_regulation");
    else if (usersProgressType === progressUsersKeys.whoStudied) return t("ui:title.learnt_regulation");
    else return "";
  };

  const handleGetRegulationContent = (): string => {
    const currentContent = props.currentContent?.content ?? JSON.stringify(initialContent);
    return defineAllow(allowsRegulationKeys.reject, props.allowedActions) ||
      defineAllow(allowsRegulationKeys.publish, props.allowedActions)
      ? props.regulation?.draftContent?.content ?? currentContent
      : currentContent;
  };

  return (
    <>
      {isOpenUserProgressDialog && (
        <ListUsersDialog
          open={isOpenUserProgressDialog}
          title={handleGetTitleForProgressDialog()}
          users={usersProgress}
          onClose={handleCloseUserProgressDialog}
        />
      )}
      <CenterPageContentLayout maxWidth={1000}>
        <>
          <Card className="regulation-content-page__content" isShadowed={false}>
            {props.isLoading ? (
              <RegulationSkeleton />
            ) : (
              <>
                <RegulationContentHeader
                  draftTitle={props.draftTitle}
                  currentTitle={props.regulation?.name ?? ""}
                  isEditable={props.isEditable}
                  dateCreated={props.regulation?.dateCreated!}
                  onChangeTitle={props.onChangeTitle}
                  createdByUser={props.regulation?.createdByUser}
                />
                {(props.currentContent || props.draftContent) && (
                  <div className="mt-2">
                    {props.isEditable ? (
                      <div>
                        <TextEditor
                          id="draft-editor"
                          onChange={props.onChangeDraftContent}
                          value={stringToContent(
                            props.regulation?.draftContent?.content ?? props.currentContent?.content ?? ""
                          )}
                        />
                        {props.isEditable && (
                          <CreateTestsForm
                            styles={{ marginTop: "8px" }}
                            regulationContentId={(props.isDraft ? props.draftContent : props.currentContent)?.id ?? 0}
                            defaultQuestions={props.editableTest}
                            ref={props.testRef}
                            editableMode
                            onChangeHasChanges={props.onSetHasDraftTestChanges}
                            noImmediatelyCreateQuestion
                            onClose={() => {}}
                          />
                        )}
                      </div>
                    ) : (
                      <TextEditor
                        readOnly
                        id="readonly-editor"
                        onChange={() => {}}
                        value={stringToContent(handleGetRegulationContent())}
                      />
                    )}
                  </div>
                )}
                {((props.regulation &&
                  props.regulation?.usersWhoStudied &&
                  props.regulation?.usersWhoStudied?.length > 0) ||
                  (props.regulation &&
                    props.regulation?.usersToStudy &&
                    props.regulation?.usersToStudy.length > 0)) && (
                  <ProgressUsers
                    onOpenProgressUserDialog={handleOpenUserProgressDialog}
                    learntUsers={props.regulation?.usersWhoStudied?.map((item) => item.user!) ?? []}
                    noLearntUsers={props.regulation?.usersToStudy ?? []}
                  />
                )}
                <RedirectedRegulationButtons />
                <RegulationViewOptions
                  isLoadingStudyBtn={props.isLoadingStudyBtn}
                  onStudyRegulation={props.onStudyRegulation}
                  hasActiveContent={!!props.regulation?.activeContent}
                  isEditable={props.isEditable}
                  allowedActions={props.allowedActions}
                  countQuestions={props.countQuestions}
                  testIsPassed={props.testIsPassed}
                  onClickPassTest={props.onOpenTestPassDialog}
                />
              </>
            )}

            {/* <>
              <RegulationContentHeader
                draftTitle={props.draftTitle}
                currentTitle={props.regulation?.name ?? ""}
                isEditable={props.isEditable}
                dateCreated={props.regulation?.dateCreated!}
                onChangeTitle={props.onChangeTitle}
                createdByUser={props.regulation?.createdByUser}
              />
              {(props.currentContent || props.draftContent) && (
                <div className="mt-2">
                  {props.isEditable ? (
                    <div>
                      <TextEditor
                        id="draft-editor"
                        onChange={props.onChangeDraftContent}
                        value={stringToContent(
                          props.regulation?.draftContent?.content ?? props.currentContent?.content ?? ""
                        )}
                      />
                      {props.isEditable && (
                        <CreateTestsForm
                          styles={{ marginTop: "8px" }}
                          regulationContentId={(props.isDraft ? props.draftContent : props.currentContent)?.id ?? 0}
                          defaultQuestions={props.editableTest}
                          ref={props.testRef}
                          editableMode
                          onChangeHasChanges={props.onSetHasDraftTestChanges}
                          noImmediatelyCreateQuestion
                          onClose={() => {}}
                        />
                      )}
                    </div>
                  ) : (
                    <TextEditor
                      readOnly
                      id="readonly-editor"
                      onChange={() => {}}
                      value={stringToContent(handleGetRegulationContent())}
                    />
                  )}
                </div>
              )}
              {((props.regulation &&
                props.regulation?.usersWhoStudied &&
                props.regulation?.usersWhoStudied?.length > 0) ||
                (props.regulation && props.regulation?.usersToStudy && props.regulation?.usersToStudy.length > 0)) && (
                <ProgressUsers
                  onOpenProgressUserDialog={handleOpenUserProgressDialog}
                  learntUsers={props.regulation?.usersWhoStudied?.map((item) => item.user!) ?? []}
                  noLearntUsers={props.regulation?.usersToStudy ?? []}
                />
              )}
              <RedirectedRegulationButtons />
              <RegulationViewOptions
                isLoadingStudyBtn={props.isLoadingStudyBtn}
                onStudyRegulation={props.onStudyRegulation}
                hasActiveContent={!!props.regulation?.activeContent}
                isEditable={props.isEditable}
                allowedActions={props.allowedActions}
                countQuestions={props.countQuestions}
                testIsPassed={props.testIsPassed}
                onClickPassTest={props.onOpenTestPassDialog}
              />
            </>*/}
          </Card>
          {props.regulation && (props.isDraft ? props.draftContent : props.currentContent)?.id && (
            <RegulationHistory
              regulationId={props.regulation?.id ?? 0}
              regulationContentId={props.currentContent?.id ?? 0}
            />
          )}
        </>
      </CenterPageContentLayout>
    </>
  );
};
