import React from "react";
import { useRootStore } from "../../../../../hooks";
import { observer } from "mobx-react-lite";
import { TextEditor } from "../../../textEditorOld/TextEditor";
import ruContent from "../../../../../utils/guides1/routine/ru.json";
import enContent from "../../../../../utils/guides1/routine/en.json";
import ukContent from "../../../../../utils/guides1/routine/uk.json";

export const getContent = (localeKey: string | null): any | null => {
  switch (localeKey) {
    case "ru":
      return ruContent;
    case "en":
      return enContent;
    case "uk":
      return ukContent;
  }
};

const RecurringIssuesHintObserver = () => {
  const { hintStore, appStore } = useRootStore();

  const anchorId = hintStore.getAnchorId;

  return (
    <TextEditor
      readOnly={true}
      anchorKey={anchorId as string}
      id="guide-dasboard"
      onChange={() => {}}
      value={getContent(appStore.getAppLocale)}
    />
  );
};
export const RecurringIssuesGuide = observer(RecurringIssuesHintObserver);
