import React, { useEffect } from "react";
import "./OrgchartFunctionCardHubbard.scss";
import OrgchartFunctionCardHubbard, { IOrgchartFunctionCardHubbard } from "./OrgchartFunctionCardHubbard";
import { useTranslation } from "react-i18next";
import { MenuItemBaseDto, OrgchartItemDto, RoleDto, RolePositionDto, User2RoleDto } from "../../../../../api";
import { FiSettings } from "@react-icons/all-files/fi/FiSettings";
import { FiPlusSquare } from "@react-icons/all-files/fi/FiPlusSquare";
import { FiEdit } from "@react-icons/all-files/fi/FiEdit";
import { FiShuffle } from "@react-icons/all-files/fi/FiShuffle";
import { FiTrash2 } from "@react-icons/all-files/fi/FiTrash2";
import { FiMove } from "@react-icons/all-files/fi/FiMove";
import { observer } from "mobx-react-lite";
import { useRootStore } from "../../../../../hooks";
import { hexToRgb } from "../../../../../helpers/colorFunctions";
import { Card, Dropdown, Icon, IconButton, Spin, Text } from "../../../../uiKit";
import { css } from "@emotion/css";
import { UserInfo } from "../../../../elements/userInfo/UserInfo";
import clsx from "clsx";
import { theme } from "antd";

export interface IOrgchartFunctionCardHubbardView extends IOrgchartFunctionCardHubbard {
  cardMenu: MenuItemBaseDto[] | null;
  isCardMenuOpen: boolean;
  isCardMenuLoading: boolean;
  isBlockWide: boolean;
  zoom: number;

  handleCardClick: () => void;
  handleMenuItemClick: (actionKey: string) => void;
  handleCardMenuOpen: (event: any) => void;
  handleCardMenuClose: () => void;
  handleCardMenuLoad: () => void;
}

const getMenuItemIcon = (actionKey: string) => {
  switch (actionKey) {
    case "add_role":
      return <FiPlusSquare />;
    case "edit":
      return <FiEdit />;
    case "manage":
      return <FiSettings />;
    case "sort":
      return <FiShuffle />;
    case "transfer":
      return <FiMove />;
    case "delete":
      return <FiTrash2 />;
  }
};

const getFunctionAdministrator = (users: User2RoleDto[], positions: RolePositionDto[]) => {
  const position = positions.find((pos) => pos.parentPositionId == null);
  if (position == null) return "";
  const user2return = users.find((u2r) => u2r.positionId == (position.id as number));
  if (user2return == null) return "";
  return (user2return.user?.name ?? "").trim().length > 0 ? user2return.user?.name : user2return.user?.nameFallback;
};

// Линия, выходящая вправо из корневого блока
const drawLineFromRootRight = () => {
  return (
    <div
      className={css`
        position: absolute;
        pointer-events: none;
        background-color: var(--color-layout-divider);
        transform: translateX(100%);
        width: 24px;
        height: 1px;
        top: 24px;
        right: -4px;
      `}
    />
  );
};

// Линия, выходящая вниз из корневого блока
const drawLineFromRootBottom = () => {
  return (
    <div
      className={css`
        position: absolute;
        pointer-events: none;
        background-color: var(--color-layout-divider);
        transform: translateY(100%);
        width: 1px;
        height: 20px;
        left: 50%;
        bottom: -4px;
      `}
    />
  );
};

// Линия, выходящая вверх из блока
const drawLineFromTop = () => {
  return (
    <div
      className={css`
        position: absolute;
        pointer-events: none;
        background-color: var(--color-layout-divider);
        transform: translateY(-100%);
        width: 1px;
        height: 4px;
        left: 50%;
        top: -2px;
      `}
    />
  );
};

// Линия, выходящая влево из блока
const drawLineFromLeftToLeft = () => {
  return (
    <div
      className={css`
        position: absolute;
        pointer-events: none;
        background-color: var(--color-layout-divider);
        transform: translateX(-100%);
        width: 4px;
        height: 1px;
        left: -4px;
        top: 24px;
      `}
    />
  );
};

// // Линия слева от блока в полную высоту
// const drawLineFromLeftFullHeight = (nestingLevel: string) => {
//   // nestingLevel: string ("5.w") - wide / tight
//   const [nestingLevelNumber, nestingLevelType] = nestingLevel.split(".");
//   if (typeof Number(nestingLevelNumber) !== "number" || nestingLevelType == null) return null;
//
//   return (
//     <div
//       className={css`
//         position: absolute;
//         pointer-events: none;
//         background-color: var(--color-layout-divider);
//         transform: translateX(-100%);
//         top: -6px;
//         width: 1px;
//         height: calc(100% + 10px);
//         left: ${"-" + (Number(nestingLevelNumber) * 16 + (nestingLevelType == "w" ? 8 : 0)) + "px"};
//       `}
//       // left: ${"-" + nestingLevel + "px"};
//     />
//   );
// };

// Линия слева от блока у последнего блока
const drawLineFromLeftLastChild = () => {
  // disabledNestingLevels: []
  return (
    <div
      className={css`
        position: absolute;
        pointer-events: none;
        background-color: var(--color-layout-divider);
        transform: translateX(-100%);
        top: -6px;
        left: -8px;
        width: 1px;
        height: 31px;
      `}
    />
    // left: ${"-" + (nestingLevel * 16 + 8) + "px"};
    // opacity: ${isLastChild && nestingLevel == 0 ? 0 : 1};
  );
};

function OrgchartFunctionCardHubbardView(props: IOrgchartFunctionCardHubbardView) {
  // const theme: Theme = useTheme();
  const { t } = useTranslation();
  const { useToken } = theme;
  const { token } = useToken();
  // const wrapperRef = useRef(null);
  // useOutsideClick(wrapperRef, props.handleCardMenuClose);
  const { appStore, helperStore, orgchartStore, authStore } = useRootStore();
  const getRoleDataById = (roleId: number): RoleDto | null => props.roles?.find((r) => r.id == roleId) ?? null;
  const getSortedChildren = (childrenRoles: OrgchartItemDto[]): OrgchartItemDto[] => {
    return [
      ...childrenRoles.sort(
        (a, b) => (getRoleDataById(a.roleId as number)?.order ?? 0) - (getRoleDataById(b.roleId as number)?.order ?? 0)
      ),
    ];
  };

  const getCardBackgroundColor = (hex: string | null | undefined): string => {
    if (hex == null || hex.trim().length == 0) return "var(--color-layout-fill-base)";
    if (appStore.getAppTheme == 1) {
      const rgb = hexToRgb(hex);
      if (rgb == null) return hex;
      return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.05)`;
    } else {
      return hex;
    }
  };

  const getCardBorder = (hex: string | null | undefined): string | undefined => {
    if (appStore.getAppTheme == 1) {
      if (hex == null || hex.trim().length == 0) return "var(--color-layout-fill-base)";
      const rgb = hexToRgb(hex);
      if (rgb == null) return hex;
      return `1px solid rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.6)`;
    } else {
      return `1px solid transparent`;
    }
  };

  const getCardLines = () => {
    const p = props.params;
    const nestedLevelWidth = 32;

    return (
      <>
        {p.isRootRole && drawLineFromRootRight()}
        {p.isRootRole && drawLineFromRootBottom()}
        {!p.isVertical && p.hasParent && !p.isRootRoleChild && drawLineFromTop()}
        {p.isVertical && !p.isRootRole && !p.isRootRoleChild && drawLineFromLeftToLeft()}
        {/*{props.params.nestingLevels*/}
        {/*  .filter(function (item, pos) {*/}
        {/*    return props.params.nestingLevels.indexOf(item) == pos;*/}
        {/*  })*/}
        {/*  .filter((l) => l != "empty")*/}
        {/*  .map((l) => drawLineFromLeftFullHeight(l))}*/}
        {/*{Array.apply(null, Array(p.nestingLevel ?? 0))*/}
        {/*  .map((_, i) => i)*/}
        {/*  .map((i) => drawLineFromLeftFullHeight(i, p.isLastChild, p.nestingLevels))}*/}
        {/*{p.isVertical && p.isLastChild && drawLineFromLeftLastChild()}*/}
      </>
    );
  };

  const cardMenuContentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  function adjustLine(from: HTMLElement | null, to: HTMLElement | null, line: HTMLElement | null) {
    if (from == null || to == null || line == null) return;
    let fT = from.offsetTop + from.offsetHeight;
    let tT = to.offsetTop;
    let H = tT - fT;

    line.style.bottom = -6 + "px";
    line.style.left = 8 + "px";
    line.style.height = H + 21 + "px";
    line.style.transform = "translateY(100%)";
  }

  const generateBlockId = () => {
    return `ofc-sId:${props.blockData?.roleId}`;
    // return `ofc-sId:${props.blockData?.roleId}-pId:${props.params.parentParams?.blockId ?? 0}${
    //   (props.blockData?.children ?? []).length > 0 && !props.blockData?.childrenAreHorizontal ? "-alStart" : ""
    // }${props.params.isLastChild && props.params.isVertical && props.params.blockIndex > 0 ? "-alEnd" : ""}`;
  };

  useEffect(() => {
    if (props.params.isLastChild && props.params.isVertical) {
      adjustLine(
        document.getElementById(`ofc-sId:${props.params.parentParams?.blockId}`),
        document.getElementById(`ofc-sId:${props.params.blockId}`),
        document.getElementById(`ofl-cId:${props.params.parentParams?.blockId}`)
      );
    }
  }, [props.params, props.roles, props.blockData, props.blockRole, props.isBlockWide, props.visibilityFilters]);

  return (
    <div
      className={`orgchart-card__wrapper d-inline-flex flex-column ${props.params.isRootRole ? "mr-6" : ""}`}
      onClick={() => props.handleCardMenuClose()}
    >
      <Card
        id={generateBlockId()}
        isShadowed={false}
        clickable
        className={`orgchart-card ma-1 ${props.params.isRootRole ? "mb-6" : ""}`}
        style={{
          backgroundColor: getCardBackgroundColor(props.blockRole?.colorHex),
          border: getCardBorder(props.blockRole?.colorHex),
          overflow: "visible",
        }}
        onClick={props.handleCardClick}
        onMouseLeave={() => props.handleCardMenuClose()}
        onContextMenu={(event) => props.handleCardMenuOpen(event)}
      >
        {(props.blockData?.children ?? []).length > 0 && !props.blockData?.childrenAreHorizontal && (
          <div
            id={`ofl-cId:${props.params.blockId}`}
            style={{ position: "absolute", backgroundColor: "var(--color-layout-divider)", width: "1px" }}
          />
        )}
        {getCardLines()}
        <div
          className={`orgchart-card__inner d-stack-column spacing-1 ${props.isBlockWide ? "__wide" : ""} ${
            props.zoom == 1 ? "__sticky" : ""
          }`}
        >
          <Dropdown
            overlayClassName="orgchart-card__menu"
            overlayStyle={{ minWidth: "200px" }}
            open={props.isCardMenuOpen}
            trigger={["click"]}
            dropdownRender={(menu) =>
              props.isCardMenuLoading ? (
                <div style={cardMenuContentStyle}>
                  <Spin className="full-width my-2" size="default" spinning />
                </div>
              ) : (
                React.cloneElement(menu as React.ReactElement, { style: undefined })
              )
            }
            // trigger={["contextMenu"]}
            align={{ offset: [-12, 0] }}
            onOpenChange={(v) => !v && props.handleCardMenuClose()}
            items={props.cardMenu?.map((item) => ({
              key: item.id as any,
              icon: getMenuItemIcon(item.key as string),
              text: t("common:misc.card_menu." + item.key),
              onClick: (event) => {
                props.handleMenuItemClick(item.key as string);
                event.stopPropagation();
              },
            }))}
          >
            <div className="d-flex justify-space-between orgchart-card__header">
              {/*<div className="d-stack spacing-2 align-center">*/}
              {/*  <IconButton*/}
              {/*    size="small"*/}
              {/*    type="text"*/}
              {/*    icon={<Icon component={() => <FiMenu />} />}*/}
              {/*    onClick={(e) => {*/}
              {/*      e.stopPropagation();*/}
              {/*      props.isCardMenuOpen ? props.handleCardMenuClose() : props.handleCardMenuOpen(e);*/}
              {/*    }}*/}
              {/*  />*/}
              <Text weight="bold" size="14px" children={props.blockRole?.name} />
              {/*</div>*/}
              <IconButton
                size="small"
                className="ml-2 orgchart-card__menu-button"
                icon={<Icon component={() => <FiSettings />} />}
                onClick={(e) => {
                  e.stopPropagation();
                  props.isCardMenuOpen ? props.handleCardMenuClose() : props.handleCardMenuOpen(e);
                }}
              />
            </div>
          </Dropdown>
          {/*{props.params.isVertical ? "s" : "f"}*/}
          {(props.blockRole?.positions ?? []).length > 0 && (
            <div className="orgchart-card__inner__users d-stack-column spacing-1">
              {props.visibilityFilters.employee &&
                (
                  props.blockRole?.positions?.filter(
                    (pos: RolePositionDto) =>
                      pos.parentPositionId == null &&
                      (props.blockRole?.users?.filter((u) => u.positionId == pos.id)?.length ?? 0) > 0
                  ) ?? []
                ).length > 0 && (
                  <div className="orgchart-card__inner__users__admins">
                    <div className="__position__wrapper">
                      <Text
                        size="12px"
                        type="secondary"
                        children={
                          props.blockRole?.positions?.find((pos: RolePositionDto) => pos.parentPositionId == null)?.name
                        }
                      />
                      <div className="my-1 d-stack-column spacing-1">
                        {props.blockRole?.users
                          ?.filter(
                            (u2r: User2RoleDto) =>
                              u2r.positionId ==
                              props.blockRole?.positions?.find((pos: RolePositionDto) => pos.parentPositionId == null)
                                ?.id
                          )
                          ?.map((u2r: User2RoleDto) => (
                            <UserInfo
                              key={u2r.id}
                              className="orgchart-card__inner__users__user"
                              avatarSize={20}
                              user={u2r.user}
                              nameDisplayMode="lastNameAndFirstName"
                            />
                          ))}
                      </div>
                    </div>
                    {(
                      props.blockRole?.positions?.find((pos: RolePositionDto) => pos.parentPositionId == null)
                        ?.description ?? ""
                    ).trim().length > 0 &&
                      props.visibilityFilters.result && (
                        <div style={{ lineHeight: "1.5em" }}>
                          <Text type="secondary" weight="bold" size="12px">
                            {`${orgchartStore.getCurrentOrgchartResultName}: `}
                            <Text
                              type="secondary"
                              weight={500}
                              size="12px"
                              children={
                                props.blockRole?.positions?.find((pos: RolePositionDto) => pos.parentPositionId == null)
                                  ?.description
                              }
                            />
                          </Text>
                        </div>
                      )}
                  </div>
                )}
              {props.visibilityFilters.employee &&
                authStore.getCurrentCompanyUiType != 2 &&
                (
                  props.blockRole?.positions?.filter(
                    (pos: RolePositionDto) =>
                      pos.parentPositionId != null &&
                      (props.blockRole?.users?.filter((u) => u.positionId == pos.id)?.length ?? 0) > 0
                  ) ?? []
                ).length > 0 && (
                  <div
                    className={clsx("orgchart-orgchart-card__inner__users__executors", {
                      "__bordered pl-1 ml-2":
                        (
                          props.blockRole?.positions?.filter(
                            (pos: RolePositionDto) =>
                              pos.parentPositionId == null &&
                              (props.blockRole?.users?.filter((u) => u.positionId == pos.id)?.length ?? 0) > 0
                          ) ?? []
                        ).length > 0,
                    })}
                  >
                    <div className="d-stack-column">
                      {props.blockRole?.positions
                        ?.filter((pos: RolePositionDto) => pos.parentPositionId != null)
                        ?.sort((a, b) => (a.parentPositionId != null ? 1 : -1))
                        ?.sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
                        ?.map((pos: RolePositionDto, index) =>
                          (
                            props.blockRole?.users?.filter(
                              (u2r: User2RoleDto) =>
                                u2r.positionId ==
                                props.blockRole?.positions?.find((p: RolePositionDto) => p.id == pos.id)?.id
                            ) ?? []
                          ).length > 0 ? (
                            <div key={pos.id} className="__position__wrapper">
                              <Text className="__position_name" size="12px" type="secondary" children={pos.name} />
                              <div className="d-stack-column my-1 spacing-1">
                                {props.blockRole?.users
                                  ?.filter((u2r: User2RoleDto) => u2r.positionId == pos.id)
                                  ?.map((u2r: User2RoleDto) => (
                                    <UserInfo
                                      key={u2r.id}
                                      className="orgchart-card__inner__users__user"
                                      avatarSize={20}
                                      user={u2r.user}
                                      nameDisplayMode="lastNameAndFirstName"
                                    />
                                  ))}
                              </div>
                              {(pos.description ?? "").trim().length > 0 && props.visibilityFilters.result && (
                                <div style={{ lineHeight: "1.5em" }}>
                                  <Text type="secondary" weight="bold" size="12px">
                                    {`${orgchartStore.getCurrentOrgchartResultName}: `}
                                    <Text type="secondary" weight="normal" size="12px" children={pos.description} />
                                  </Text>
                                </div>
                              )}
                            </div>
                          ) : (
                            <div key={pos.id} />
                          )
                        )}
                    </div>
                  </div>
                )}
            </div>
          )}
          {(props.blockRole?.awaitedResult ?? "").trim().length > 0 && props.visibilityFilters.result && (
            <div style={{ lineHeight: "1.5em" }}>
              <Text type="secondary" weight="bold" size="12px">
                {`${orgchartStore.getCurrentOrgchartResultName}: `}
                <Text type="secondary" weight={500} size="12px" children={props.blockRole?.awaitedResult} />
              </Text>
            </div>
          )}
          {/*<Text children={" / isV: " + props.params.isVertical} />*/}
          {/*<Text children={" / nL: " + props.params.nestingLevel} />*/}
          {/*<Text children={" / cL: " + (getSortedChildren(props.blockData?.children ?? []).length - 1)} />*/}
          {/*<Text children={"PCL: " + props.params.parentChildrenLength} />*/}
          {/*<Text children={" / bIn: " + props.params.blockIndex} />*/}
          {/*<Text children={JSON.stringify(props.params.nestingLevels)} />*/}
        </div>
      </Card>
      {(props.blockData?.children ?? []).length > 0 &&
        (props.params.isRootRole ? (
          <OrgchartFunctionCardHubbard
            zoom={props.zoom}
            key={getSortedChildren(props.blockData?.children ?? [])?.[0]?.roleId}
            roles={props.roles}
            blockData={props.blockData?.children?.[0] as any}
            blockRole={
              getSortedChildren(props.blockData?.children ?? [])?.[0]?.roleId != null
                ? getRoleDataById(getSortedChildren(props.blockData?.children ?? [])?.[0]?.roleId as number)
                : null
            }
            params={{
              isVertical: !props.blockData?.childrenAreHorizontal,
              isRootRole: false,
              isRootRoleChild: props.params.isRootRole,
              isLastChild: getSortedChildren(props.blockData?.children ?? []).length == 0,
              hasParent: true,
              blockId: props.blockData?.children?.[0]?.roleId,
              parentParams: props.params,
            }}
            visibilityFilters={props.visibilityFilters}
            orgchartDrawType={props.orgchartDrawType}
          />
        ) : (
          <div
            className={`orgchart-card__children__wrapper d-inline-flex ${
              props.blockData?.childrenAreHorizontal ? "" : "flex-column ml-4"
            }`}
          >
            {getSortedChildren(props.blockData?.children ?? [])
              ?.sort(
                (a: OrgchartItemDto, b: OrgchartItemDto) =>
                  (getRoleDataById(a.roleId as number)?.order ?? 0) - (getRoleDataById(b.roleId as number)?.order ?? 0)
              )
              .map((child: OrgchartItemDto, index: number) => (
                <OrgchartFunctionCardHubbard
                  key={child.roleId}
                  zoom={props.zoom}
                  roles={props.roles}
                  blockData={child}
                  blockRole={child.roleId != null ? getRoleDataById(child.roleId) : null}
                  params={{
                    isRootRole: false,
                    isVertical: !props.blockData?.childrenAreHorizontal,
                    isRootRoleChild: props.params.isRootRole,
                    isLastChild: index == getSortedChildren(props.blockData?.children ?? []).length - 1,
                    hasParent: true,
                    blockId: child?.roleId,
                    parentParams: props.params,
                  }}
                  orgchartDrawType={props.orgchartDrawType}
                  visibilityFilters={props.visibilityFilters}
                />
              ))}
          </div>
        ))}
    </div>
  );
}

export default observer(OrgchartFunctionCardHubbardView);
