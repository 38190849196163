import React from "react";
import { useTranslation } from "react-i18next";
import SubheaderText from "../../../elements/subheaderText/SubheaderText";
import { Button, DatePicker, Dialog, DialogActions, Input, Radio, RadioGroup } from "../../../uiKit";
import { Space } from "antd";
import dayjs from "dayjs";

interface IDriverCalledDialogView {
  open: boolean;
  callResultTypes: { id: number; value: string; text: string }[];
  callResult: { type: string | null; text: string; datePostpone: Date };
  isLoading: boolean;

  setCallResult(data: { type: string | null; text: string; datePostpone: Date }): void;
  onSave(): void;
  onClose(): void;
}

function DriverCalledDialogView(props: IDriverCalledDialogView) {
  const { t } = useTranslation();

  return (
    <Dialog title={t("ui:title.driver_called")} open={props.open} onClose={props.onClose}>
      <SubheaderText text={t("ui:subheader.call_result")} />
      <RadioGroup
        defaultValue={null}
        value={props.callResult.type}
        onChange={(event) => props.setCallResult({ ...props.callResult, type: event.target.value })}
      >
        <Space direction="vertical">
          {props.callResultTypes.map((cr) => (
            <Radio key={cr.id} value={cr.value} className="pa-1">
              {cr.value == "driver.action.call.result.ptt" ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ whiteSpace: "nowrap", marginRight: "8px" }}>{cr.text}</span>
                  <DatePicker
                    allowClear={false}
                    showTime={{ format: "HH:mm" }}
                    minDate={dayjs()}
                    maxDate={dayjs(new Date()).add(2, "day")}
                    onClick={(event) => event.preventDefault()}
                    value={dayjs(props.callResult.datePostpone)}
                    onChange={(date) => {
                      props.setCallResult({ ...props.callResult, datePostpone: date?.toDate() ?? new Date() });
                    }}
                  />
                </div>
              ) : (
                cr.text
              )}
            </Radio>
          ))}
        </Space>
      </RadioGroup>
      <div className="mt-2">
        <SubheaderText text={t("ui:subheader.comment")} />
        <Input
          placeholder={t("ui:placeholder.comment")}
          value={props.callResult.text}
          onInput={(event: any) => props.setCallResult({ ...props.callResult, text: event.target.value })}
        />
      </div>
      {/*{JSON.stringify(props.callResult)}*/}
      <DialogActions>
        <Button onClick={props.onClose} variant="text">
          {t("ui:button.cancel")}
        </Button>
        <Button
          loading={props.isLoading}
          disabled={
            props.callResult.type == null ||
            (props.callResult.type == "driver.action.call.result.ptt" &&
              (props.callResult.datePostpone > dayjs(new Date()).add(2, "day").toDate() ||
                props.callResult.datePostpone < dayjs(new Date()).add(-3, "minute").toDate()))
          }
          onClick={props.onSave}
          variant="filled"
        >
          {t("ui:button.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DriverCalledDialogView;
