import React from "react";
import { IListGroupsCompanies } from "./ListGroupsCompanies.interface";
import { useTranslation } from "react-i18next";
import { Button, Card, Divider, Empty } from "../../../../../uiKit";
import ScrollTrigger from "../../../../../service/scrollTrigger/ScrollTrigger";
import { GroupCompanyCard } from "../../components/GroupCompanyCard/GroupCompanyCard";

const ListGroupsCompaniesMemo = (props: IListGroupsCompanies) => {
  const { t } = useTranslation();

  const handleGetDivider = (index: number): JSX.Element | undefined => {
    return index !== props.groups.length - 1 ? <Divider /> : undefined;
  };

  return (
    <Card
      title={t("common:page_title.groups_companies")}
      size="default"
      extra={
        <Button onClick={props.onOpenCreateGroupDialog} variant="filled">
          {t("ui:button:add_group")}
        </Button>
      }
      bodyStyle={{ padding: 0 }}
      style={{ overflow: "hidden" }}
      scrollStrategy="cardBody"
    >
      {props.isDone && props.groups.length == 0 ? (
        <Empty className="my-4" />
      ) : (
        <div>
          {props.groups?.map((group, index) => (
            <React.Fragment key={group.id}>
              {index > 0 && <Divider />}
              <GroupCompanyCard groupCompany={group} />
            </React.Fragment>
          ))}
          <ScrollTrigger
            disabled={props.isDone}
            onIntersection={props.onLoadNext}
            marginTop={props.groups.length > 0}
            hidden={props.isDone}
          />
        </div>
      )}
    </Card>
  );
};

export const ListGroupsCompanies = React.memo(ListGroupsCompaniesMemo);
