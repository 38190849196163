import React, { forwardRef } from "react";
import "./UpdateNoteContent.scss";
import { IUpdateNoteContent } from "./UpdateNoteContent.interface";
import { UpdateNoteEditor } from "../../components/UpdateNoteEditor/UpdateNoteEditor";
import { EditableTypography } from "../../../../../elements/editableTypography/EditableTypography";
import { stringToContent } from "../../../../../../utils/textEditor";
import { useTranslation } from "react-i18next";
import { Card } from "../../../../../uiKit";

export const UpdateNoteContent = forwardRef((props: IUpdateNoteContent, ref) => {
  const { t } = useTranslation();

  return (
    <Card
      size="default"
      scrollStrategy="cardBody"
      title={
        <EditableTypography
          multiline
          big
          placeholder={t("ui:placeholder.update_note_name")}
          value={props.noteName}
          disabled={!props.isEditable}
          onChange={props.onChangeNoteName}
        />
      }
    >
      <div>
        <UpdateNoteEditor
          ref={ref}
          onChangeDraftUpdateNoteContent={props.onChangeDraftUpdateNoteContent}
          draftUpdateNoteContent={stringToContent(props.draftUpdateNoteContent)}
          isEditable={props.isEditable}
        />
      </div>
    </Card>
  );
});
