import React from "react";
import { IRoleSelectionBox } from "./UserSelectionDialog.interface";
import { useTranslation } from "react-i18next";
import { SearchInputField } from "../../../elements/searchInputField/SearchInputField";
import ScrollTrigger from "../../../service/scrollTrigger/ScrollTrigger";
import { OrgchartSwitcher } from "../../../elements/orgchartSwitcher/OrgchartSwitcher";
import { Text } from "../../../uiKit";

export const RoleSelectionBox: React.FC<IRoleSelectionBox> = (props) => {
  const { t } = useTranslation();

  return (
    <div
      className="user-selection-dialog__left-bar d-stack-column spacing-2"
      style={!props.isSupportOldUi ? { width: "100%" } : undefined}
    >
      {/*<h2>{t("ui:subheader.role_select")}</h2>*/}
      <OrgchartSwitcher
        value={props.orgchartId ?? undefined}
        onChange={(v) => typeof v === "number" && props.onChangeOrgchartId(v)}
        preventDefault
        selectProps={{
          allowClear: false,
          className: "full-width",
          placeholder: t("ui:placeholder.click_to_select"),
        }}
        drawType="select"
      />
      <SearchInputField
        value={props.searchRoleValue}
        setValue={props.onChangeSearchRoleValue}
        // variant="standard"
        backgroundInherit
        placeholder={t("ui:placeholder.enter_role")}
      />
      <div className="user-selection-dialog__left-bar__list d-stack-column spacing-2">
        <Text
          key={0}
          onClick={() => props.onSelectActiveRole(null)}
          className={`user-selection-dialog__left-bar__label 
            ${props.activeLabelId == null && "user-selection-dialog__left-bar__label-active"}`}
          children={t("common:user_selection_dialog.role_select_box.all")}
        />
        {props.labels.map((item) => (
          <Text
            key={item.id}
            onClick={() => props.onSelectActiveRole(item.id)}
            className={`user-selection-dialog__left-bar__label 
              ${props.activeLabelId === item.id && "user-selection-dialog__left-bar__label-active"}`}
            children={item.name}
          />
        ))}
        <ScrollTrigger
          fullWidth
          disabled={props.isSupportOldUi ? props.rolesIsDone : true}
          onIntersection={props.loadNextRoles}
          marginTop
          // marginTop={props.labels.length > 0}
          hidden={props.isSupportOldUi ? props.rolesIsDone : true}
        />
      </div>
    </div>
  );
};
