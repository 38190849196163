import React from "react";
import { useTranslation } from "react-i18next";
import "./InfoLinksBlock.scss";
import { Link } from "../../../uiKit";

function InfoLinksBlock() {
  const { t, i18n } = useTranslation();

  const linksArray = [
    {
      id: 0,
      name: t("common:document.paid_services_offer"),
      url: `${process.env.REACT_APP_API_BASE_URL}static/docs/contract-offer/${i18n.language.substr(0, 2) ?? "ru"}.html`,
    },
    {
      id: 1,
      name: t("common:document.personal_data_consent"),
      url: `${process.env.REACT_APP_API_BASE_URL}static/docs/personal-data/${i18n.language.substr(0, 2) ?? "ru"}.html`,
    },
    {
      id: 2,
      name: t("common:document.personal_data_policy"),
      url: `${process.env.REACT_APP_API_BASE_URL}static/docs/personal-data-politics/${
        i18n.language.substr(0, 2) ?? "ru"
      }.html`,
    },
  ];

  return (
    <div className="d-stack-column spacing-1 align-center">
      {linksArray.map((l) => (
        <Link
          key={l.id}
          type="secondary"
          target="_blank"
          rel="noreferrer"
          size="12px"
          href={l.url}
          children={l.name}
          style={{ lineHeight: "1em", textAlign: "center" }}
        />
      ))}
    </div>
  );
}

export default InfoLinksBlock;
