import React, { useState } from "react";
import "./src/style/InputPhoneNumber.scss";
import { IInput, Input } from "../input/Input";
import { ISelect, Select } from "../select/Select";
import { Divider } from "../layout/divider/Divider";
import { CountryData } from "./src/data";

interface IInputPhoneNumber {
  value?: string;
  readOnly?: boolean;
  size?: "small" | "middle" | "large";

  inputProps?: IInput;
  selectProps?: ISelect;
  searchProps?: ISelect;

  country?: string | number;
  countriesOnly?: string[];
  countriesPreferred?: string[];
  countriesExcluded?: string[];

  areaCodesEnabled?: string[];
  territoriesEnabled?: string[];

  autoFormat?: boolean;
  isCountryCodeDisabled?: boolean;
  isDropdownDisabled?: boolean;
  isLongPhoneNumbers?: boolean | number;
  isCountryCodeEditable?: boolean;
  isSearchEnabled?: boolean;
  isInitialCountryCodeGuessDisabled?: boolean;
  isCountryCodeGuessDisabled?: boolean;

  defaultMask?: string;
  isAlwaysDefaultMask?: boolean;
  prefix?: string;
  isCopyNumbersOnly?: boolean;
  // copyNumbersOnly?: boolean;
  // prefix?: string;
  // enableLongNumbers?: boolean | number;
  // disableCountryCode?: boolean;
  // countryCodeEditable?: boolean;

  onChange?: (
    purePhoneNumber: string,
    countryData: any,
    event: React.ChangeEvent<HTMLInputElement>,
    formattedPhoneNumber: string
  ) => void;
}

interface IInputPhoneNumberState {
  value: {
    select: string | null;
    input: string | null;
  };
}

const defaultProps: IInputPhoneNumber = {
  //
};

export function InputPhoneNumber(_props: IInputPhoneNumber) {
  const props = { ...defaultProps, ..._props };
  const [state, setState] = useState<IInputPhoneNumberState>({
    value: {
      input: "",
      select: "",
    },
  });

  const countrySelect = (
    <Select
      // open
      // className="uiKit-inputPhoneNumber-inputSelect"
      popupClassName="uiKit-inputPhoneNumber-inputSelect"
      items={CountryData.map((i) => ({
        value: i.iso,
        text: i.name,
        icon: <div className={`__flag ${i.iso.toLowerCase()}`} />,
      }))}
      value={1}
      size={props.size ?? "large"}
      style={{ width: "100px" }}
      dropdownRender={(menu) => (
        <div className="d-flex flex-column" style={{ overflow: "hidden" }}>
          <Input size="middle" placeholder="Search" />
          <Divider className="my-1" />
          <div className="flex-grow-1" style={{ overflow: "auto" }} children={menu} />
        </div>
      )}
    />
  );

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const { prefix, onChange } = props;

    let formattedNumber = props.isCountryCodeDisabled ? "" : prefix;
    // let newSelectedCountry = state.selectedCountry;
    // let freezeSelection = state.freezeSelection;

    if (!props.isCountryCodeEditable) {
      // const mainCode = newSelectedCountry.hasAreaCodes ?
      //   this.state.onlyCountries.find(o => o.iso2 === newSelectedCountry.iso2 && o.mainCode).dialCode :
      //   newSelectedCountry.dialCode;
      // const updatedInput = prefix+mainCode;
      // if (value.slice(0, updatedInput.length) !== updatedInput) return;
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    //
  };

  const handleDoubleClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    //
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    //
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    //
  };

  const handleCopy = (event: React.ClipboardEvent<HTMLInputElement>) => {
    if (!props.isCopyNumbersOnly) return;
    const text = window
      .getSelection()
      ?.toString()
      .replace(/[^0-9]+/g, "");
    if (text == null) return;
    event.clipboardData.setData("text/plain", text);
    event.preventDefault();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    //
  };

  return (
    <div>
      <Input
        className="uiKit-inputPhoneNumber-input"
        addonBefore={countrySelect}
        type="tel"
        placeholder="Phone number"
        size={props.size ?? "large"}
        readOnly={props.readOnly}
        onChange={handleInput}
        onClick={handleClick}
        onDoubleClick={handleDoubleClick}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onCopy={handleCopy}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
}
