import React from "react";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { Button, Checkbox, Dialog, DialogActions, TimePicker } from "../../../uiKit";
import { ISelectApproverViewDialog } from "./SelectApproverDialog.interface";
import { AutocompleteAsync } from "../../../elements/autocompleteAsync/AutocompleteAsync";
import SubheaderText from "../../../elements/subheaderText/SubheaderText";
import { getObjectFromTimeSpan } from "../../../../helpers/dateFunctions";

export const SelectApproverDialogView: React.FC<ISelectApproverViewDialog> = (props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const handleSubmit = () => form.submit();

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      bodyStyle={{ paddingBottom: 4 }}
      title={t("ui:title.submit_for_approval")}
    >
      <Form form={form} name="validate_other" onFinish={props.onSubmitForApproval}>
        {/*<SubheaderText text={t("ui:subheader.company")} />*/}
        {/*<Form.Item*/}
        {/*  className="full-width"*/}
        {/*  name="orgchartId"*/}
        {/*  initialValue={props.orgchartId}*/}
        {/*  rules={[*/}
        {/*    {*/}
        {/*      required: true,*/}
        {/*      message: t("validation:field_required"),*/}
        {/*    },*/}
        {/*  ]}*/}
        {/*>*/}
        {/*  <OrgchartSwitcher
            value={props.orgchartId ?? undefined}
            onChange={props.onChangeOrgchartId}
            selectProps={{
              style: { width: "100%", marginBottom: 4 },
              placeholder: t("ui:placeholder.click_to_select"),
            }}
            drawType="select"
          />*/}
        {/*</Form.Item>*/}
        <SubheaderText text={t("ui:label.approver")} />
        <Form.Item name="select" hasFeedback rules={[{ required: true, message: t("validation:select_approver") }]}>
          <AutocompleteAsync
            type="user"
            value={props.approverId}
            requestOptions={{ relationType: "nearest_admin" }}
            onChange={(value) => props.onSelectApproverId(value ? +value : null)}
          />
        </Form.Item>
        {props.withUrgency && (
          <>
            <SubheaderText text={t("ui:subheader.urgency")} />
            <TimePicker
              size="large"
              className="full-width"
              value={props.urgency ? getObjectFromTimeSpan(props.urgency).dayjsObject : undefined}
              onChange={(v) => props.onChangeUrgency(v?.toDate() ?? null)}
            />
          </>
        )}
        {props.withCheckbox && (
          <Form.Item name="checkbox" hasFeedback>
            <Checkbox checked={props.resetTestResults} onChange={props.onChangeResetTestResults}>
              {t("ui:checkbox.send_retraining")}
            </Checkbox>
          </Form.Item>
        )}
      </Form>
      <DialogActions>
        <Button variant="outlined" onClick={props.onClose}>
          {t("ui:button.cancel")}
        </Button>
        <Button type="primary" onClick={handleSubmit}>
          {t("ui:button.send")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
