import React, { useEffect, useState } from "react";
import { ExecutorSelectView } from "./ExecutorSelectView";
import { useNotifier, useRootStore } from "../../../hooks";
import { UserSelectionRule } from "../../../api/types/userSelectionRule";
import { observer } from "mobx-react-lite";
import { AutocompleteModel, RoleDto } from "../../../api";
import { useTranslation } from "react-i18next";
import { IButton } from "../../uiKit/general/button/Button";
import {
  getArrayMaxIdX,
  getDifferenceBetweenTwoArrays,
  removeDuplicatesFromArrayByKey,
} from "../../../helpers/arrayFunctions";
import { api } from "../../../services";

interface IExecutorSelect {
  isWhoInNaming?: boolean;
  value?: (UserSelectionRule & { idx?: number })[];
  guideIcon?: React.ReactNode;
  buttonProps?: {
    include?: IButton;
    exclude?: IButton;
  };
  customSubheaders?: {
    include?: React.ReactNode;
    exclude?: React.ReactNode;
  };
  onChange(data: (UserSelectionRule & { idx?: number })[]): void;
}

function ExecutorSelect(props: IExecutorSelect) {
  const { authStore } = useRootStore();
  const { t } = useTranslation();
  const notifier = useNotifier();
  const [data, setData] = useState<(UserSelectionRule & { idx?: number })[]>(props.value ?? []);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDialogToExclude, setIsDialogToExclude] = useState<boolean>(false);
  const [allRoles, setAllRoles] = useState<RoleDto[] | AutocompleteModel[]>([]);
  // authStore.getCurrentCompanyUiType == 2 2 - new, 1 - old

  const loadRoles = async (ids: number[]) => {
    setIsLoading(true);
    let _data: RoleDto[] | AutocompleteModel[] = [];
    if (ids?.filter((i) => i != null).length == 0) {
      setIsLoading(false);
      return;
    }
    const opts = {
      companyId: authStore.getCurrentCompanyId,
      ids: ids?.filter((i) => i != null),
      pageSize: -1,
      orderBy: "name",
      order: "asc",
    };
    if (authStore.getCurrentCompanyUiType == 2) {
      // New interface, load via autocomplete route
      const r = await api.role.autocomplete(opts);
      if (r == null || r.items == null) {
        setIsLoading(false);
        notifier.show({ message: t("notifier:error.something_wrong"), theme: "error", timeout: 7 });
        return;
      }
      _data = [...r.items];
    } else {
      // Old interface, load full models
      const r = await api.role.getAll(opts);
      if (r == null || r.items == null) {
        setIsLoading(false);
        notifier.show({ message: t("notifier:error.something_wrong"), theme: "error", timeout: 7 });
        return;
      }
      _data = [...r.items];
    }
    console.log(_data);
    setAllRoles(removeDuplicatesFromArrayByKey([...allRoles, ..._data], "id"));
    setIsLoading(false);
  };

  const compareDataRoleIdsAndAllRolesIdsAndUploadMissingRoles = async () => {
    if (data.length == 0 || isLoading) return;
    const rolesIdsFromData = data.map((d) => d.roleId);
    const rolesIdsFromAllRoles = allRoles.map((r) => r.id);
    const rolesDifference = getDifferenceBetweenTwoArrays(rolesIdsFromAllRoles, rolesIdsFromData);
    if (rolesDifference.length > 0) {
      await loadRoles(rolesDifference);
    }
  };

  const handleDialogOpen = (toExclude: boolean) => {
    setIsDialogToExclude(toExclude);
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleRuleAdd = (rule: UserSelectionRule, role?: RoleDto | AutocompleteModel) => {
    console.log(role);
    role != null && setAllRoles(removeDuplicatesFromArrayByKey([...allRoles, role], "id"));
    setData([...data, { ...rule, idx: getArrayMaxIdX(data) + 1 }]);
  };

  const handleRuleDelete = (idx: number | undefined) => {
    if (typeof idx !== "number") return;
    const roleIdToRemove = data.find((item) => item.idx == idx)?.roleId;
    if (roleIdToRemove != null) {
      if (authStore.getCurrentCompanyUiType == 2) {
        setAllRoles((roles: AutocompleteModel[]) => [...roles.filter((item) => item.id != roleIdToRemove)]);
      } else {
        setAllRoles((roles: RoleDto[]) => [...roles.filter((item) => item.id != roleIdToRemove)]);
      }
    }
    if (data.filter((d) => d?.idx != idx).filter((d) => !d.isExcluded).length == 0) {
      setData([]);
      return;
    }
    setData([...data.filter((d) => d?.idx != idx)]);
  };

  const emitOnChange = () => {
    props.onChange(data);
  };

  useEffect(() => {
    // Check if all items has idX property to track them
    if (data.some((d) => d.idx == null)) {
      data.forEach((d) => {
        if (d.idx == null) {
          d.idx = getArrayMaxIdX(data) + 1;
        }
      });
      setData([...data]);
    }
    emitOnChange();
  }, [data]);

  useEffect(() => {
    compareDataRoleIdsAndAllRolesIdsAndUploadMissingRoles();
  }, []);

  useEffect(() => {
    compareDataRoleIdsAndAllRolesIdsAndUploadMissingRoles();
  }, [allRoles, data]);

  useEffect(() => {
    console.log(props.value, "val");
    // const newValue = [...(props.value ?? [])].map((v) => ({ ...v, idx: v.idx ?? getArrayMaxIdX(data) + 1 }));
    // if (areDataArraysEqual(newValue, data)) {
    //   return;
    // }
    // setData(props.value ?? []);
    // compareDataRoleIdsAndAllRolesIdsAndUploadMissingRoles();
  }, [props.value]);

  useEffect(() => {
    if (props.value) {
      setData(props.value);
    }
  }, []);

  const areDataArraysEqual = (a1: any[], a2: any[]) => {
    const d1 = (a1 ?? []).map((el) => el.idx);
    const d2 = (a2 ?? []).map((el) => el.idx);

    return arraysEqual(d1, d2);
  };

  function arraysEqual(a: any, b: any) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }

  return (
    <ExecutorSelectView
      data={data}
      isLoading={isLoading}
      isDialogOpen={isDialogOpen}
      isDialogToExclude={isDialogToExclude}
      isWhoInNaming={props.isWhoInNaming}
      allRoles={allRoles}
      guideIcon={props.guideIcon}
      buttonProps={props.buttonProps}
      customSubheaders={props.customSubheaders}
      onDialogOpen={handleDialogOpen}
      onDialogClose={handleDialogClose}
      onRuleAdd={handleRuleAdd}
      onRuleDelete={handleRuleDelete}
    />
  );
}

export default observer(ExecutorSelect);
