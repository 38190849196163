import React, { useState } from "react";
import { IIssueExecutorChangeDialogView } from "./IssueExecutorChangeDialog.interface";
import { useTranslation } from "react-i18next";
import { Button, DatePicker, Dialog, DialogActions, Text, TextArea } from "../../../uiKit";
import { Form } from "antd";
import SubheaderText from "../../../elements/subheaderText/SubheaderText";
import dayjs from "dayjs";

export const IssueExecutorChangeDialogView = (props: IIssueExecutorChangeDialogView) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = () => {
    if (props.updater?.currentState?.dateDeadline && new Date(props.updater?.currentState?.dateDeadline) < new Date()) {
      props.setIsWrongDate(true);
    } else {
      setLoading(true);
      form.submit();
    }
  };

  const handleChangeDeadline = (date: Date | null) => {
    if (date) {
      if (date < new Date(props.updater?.beforeState?.dateDeadline as string) || date < new Date()) {
        props.setIsWrongDate(true);
        props.updater.applyChanges({ dateDeadline: date });
        return;
      }
      props.setIsWrongDate(false);
      props.updater.applyChanges({ dateDeadline: date });
    }
  };

  return (
    <Dialog title={t("ui:title.executor_change.subtitle")} open={props.open} onClose={props.onClose}>
      <Form
        form={form}
        style={{ overflowY: "auto", height: "100%" }}
        onFinish={props.onSuccess}
        onFinishFailed={() => setLoading(false)}
        autoComplete="off"
        name="change-executor"
      >
        <Form.Item
          name="reason"
          extra={
            t("validation:min_char_number", { number: 1 }) +
            ". " +
            t("common:misc.entered_characters_number", { number: props.text.trim().length })
          }
          rules={[{ required: true, message: t("validation:field_required") }]}
        >
          <TextArea
            name="text"
            placeholder={t("ui:title.executor_change.input_placeholder")}
            value={props.text.length > 0 ? props.text : undefined}
            onChange={(event) => {
              props.setText(event.target.value);
            }}
            size="large"
            autoSize={{ minRows: 4 }}
          />
        </Form.Item>
        <div
          className="mt-4"
          children={t("ui:subheader.issue_with_deadline")}
          style={{
            fontWeight: 600,
            fontSize: 16,
          }}
        />
        <div className="d-stack-row justify-space-between align-start mt-3">
          <div style={{ width: "48%" }}>
            <SubheaderText text={t("ui:subheader.executor_deadline")} />
            <DatePicker
              // disabled={props.onCheckToAvailability(blockedFieldsKeys.dateWorkStart)}
              className="full-width"
              value={
                props.updater?.currentState?.dateWorkStart ? dayjs(props.updater?.currentState?.dateWorkStart) : null
              }
              onChange={(value) => props.updater.applyChanges({ dateWorkStart: value?.toDate() ?? null })}
              // onChange={(value) =>
              //   props.onChangeExecutorDeadline!({
              //     prevValue: props.updater.currentState?.dateWorkStart,
              //     nextValue: value,
              //     warningKey: WarningsKeys.executorDeadline,
              //   })
              // }
              allowClear
              size="large"
              showTime={{ format: "HH:mm" }}
              disablePast
            />
          </div>
          <div style={{ width: "48%" }}>
            <Form.Item
              className="mb-0"
              rules={[{ required: true, message: t("validation:field_required") }]}
              validateStatus={props.isWrongDate ? "error" : undefined}
            >
              <SubheaderText text={t("ui:subheader.deadline")} />
              <DatePicker
                // disabled={props.onCheckToAvailability(blockedFieldsKeys.dateDeadline)}
                className="full-width"
                value={
                  props.updater?.currentState?.dateDeadline ? dayjs(props.updater?.currentState?.dateDeadline) : null
                }
                onChange={(value) => handleChangeDeadline(value?.toDate() ?? null)}
                allowClear
                size="large"
                showTime={{ format: "HH:mm" }}
                disablePast
                // maxDate={
                //   props.updater?.currentState?.calculated?.dateWorkStartTo
                //     ? new Date(props.updater?.currentState?.calculated?.dateWorkStartTo)
                //     : undefined
                // }
                //onBlur={props.onAsyncUpdate}
              />
            </Form.Item>
          </div>
        </div>
        {props.isWrongDate && (
          <Text size="12px" style={{ color: "var(--color-error-base)" }} children={t("validation:executor_deadline")} />
        )}
      </Form>
      <DialogActions>
        <Button onClick={props.onClose} variant="text">
          {t("ui:button.cancel")}
        </Button>
        <Button
          loading={loading && !props.isWrongDate}
          onClick={handleSubmit}
          disabled={loading || props.text.trim().length < 1 || props.isWrongDate}
          type="primary"
          variant="text"
        >
          {t("ui:button.change_v2")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
