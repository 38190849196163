import React from "react";
import "./EmployeeMainCard.scss";
import { UserDto } from "../../../../../api";
import { useTranslation } from "react-i18next";
import { useRootStore } from "../../../../../hooks";
import { Avatar, Card, Chip, Text } from "../../../../uiKit";
import { DropdownMenu } from "../../../../service/dropdownMenu/dropdownMenu";
import { PopupMenuItemTypes } from "../../../../service/dropdownMenu/dropdownMenu.interface";
import { EmployeeRolesList } from "../employeeRolesList/EmployeeRolesList";

export interface IEmployeeCardMainView {
  isCardLoading?: boolean;
  employeeData: UserDto;
  handleCardClick: () => void;
  menuItems: PopupMenuItemTypes[];
}

function EmployeeMainCardView(props: IEmployeeCardMainView) {
  const { t } = useTranslation();
  const { authStore, orgchartStore } = useRootStore();

  return (
    <Card onClick={props.handleCardClick} clickable variant="default">
      <div className="d-flex align-center">
        <Avatar
          // shape="square"
          text={[props.employeeData?.lastName, props.employeeData?.firstName]}
          src={props.employeeData?.avatar?.image?.url}
          color={props.employeeData.color}
          size={40}
          isOutlined={props.employeeData.id == authStore.getInitialInfo?.identity?.id}
        />
        <div className="ml-3 flex-grow-1">
          <div className="d-stack spacing-3 align-center">
            <Text style={{ fontSize: "15px" }}>
              {(props.employeeData?.name ?? "")?.trim().length == 0
                ? props.employeeData.nameFallback
                : props.employeeData.name}
            </Text>
            {/*@ts-ignore*/}
            <Chip
              type={props.employeeData.currentAccessType == 0 ? "default" : "warning"}
              label={
                props.employeeData.currentAccessType == 0
                  ? t("parse:employee_type.full")
                  : t("parse:employee_type.limited")
              }
            />
          </div>
          {props.employeeData?.roles && props.employeeData?.roles?.length > 0 ? (
            // <div className="employee-card_roles-list d-flex" style={{ fontSize: "12px" }}>
            //   {/*{groupArrayOfObjectsByKey(props.employeeData?.roles ?? [], "")}*/}
            //   {(props.employeeData?.roles?.map((u2r: User2RoleDto) => u2r.role?.name) ?? [])?.map((roleName, index) => (
            //     <span key={index}>
            //       {index > 0 && <span className="employee-card_role-divider">·</span>}
            //       <span className="employee-page-individual_role" key={index}>
            //         {roleName}
            //       </span>
            //     </span>
            //   ))}
            // </div>
            <EmployeeRolesList roles={props.employeeData.roles} />
          ) : (
            <div className="employee-card_roles-list d-flex" style={{ fontSize: "12px" }}>
              <Text>{props.employeeData?.email}</Text>
            </div>
          )}
        </div>
        <DropdownMenu items={props.menuItems} />
      </div>
    </Card>
  );
}

export default EmployeeMainCardView;
