import React, { useEffect, useRef, useState } from "react";
import PageContent from "../../modules/layouts/pageContent/PageContent";
import { useTranslation } from "react-i18next";
import "./SchedulePage.scss";
import { usePagingWithController } from "../../../hooks/usePaging";
import { api } from "../../../services";
import { FiChevronDown } from "@react-icons/all-files/fi/FiChevronDown";
import { observer } from "mobx-react-lite";
import { LocalStorageHelpers, useApi, useDateHelpers, useRootStore } from "../../../hooks";
import { toJS } from "mobx";
import ScheduleDaySetupDialog from "../../modules/dialogs/scheduleDaySetupDialog/ScheduleDaySetupDialog";
import { RoleDto, User2RoleDto, UserScheduleDto } from "../../../api";
import ScrollTrigger from "../../service/scrollTrigger/ScrollTrigger";
import ScheduleDayCard from "../../modules/pages/schedule/scheduleDayCard/ScheduleDayCard";
import PageHeader from "../../modules/layouts/pageHeader/PageHeader";
import ScheduleDaysRow from "../../modules/pages/schedule/scheduleDaysRow/ScheduleDaysRow";
import ZoomButton from "../../elements/zoomButton/ZoomButton";
import { UserInfo } from "../../elements/userInfo/UserInfo";
import { Alert, Button, Card, CollapsibleContainer, DatePicker, Dropdown, Shape, Switch } from "../../uiKit";
import { AutocompleteAsync } from "../../elements/autocompleteAsync/AutocompleteAsync";
import { DropdownMenu } from "../../service/dropdownMenu/dropdownMenu";
import { PopupMenuItemTypes } from "../../service/dropdownMenu/dropdownMenu.interface";
import dayjs, { Dayjs } from "dayjs";
import { FiChevronLeft } from "@react-icons/all-files/fi/FiChevronLeft";
import { FiChevronRight } from "@react-icons/all-files/fi/FiChevronRight";
import { OrgchartSwitcher } from "../../elements/orgchartSwitcher/OrgchartSwitcher";
import clsx from "clsx";
import { theme } from "antd";
import { addAlphaToHex } from "../../../helpers/colorFunctions";

function SchedulePage() {
  const { t, i18n } = useTranslation();
  const { authStore, orgchartStore } = useRootStore();
  const dateHelpers = useDateHelpers();
  const { useToken } = theme;
  const { token } = useToken();
  const currentMousePosition = useRef<{ x: null | number; y: null | number }>({ x: null, y: null });
  const [cardsSelectionResetTrigger, setCardsSelectionResetTrigger] = useState(0);
  const zoomInitValue: number =
    typeof LocalStorageHelpers.get("scheduleZoom") == "number" ? LocalStorageHelpers.get("scheduleZoom") ?? 1 : 1;
  const [zoom, setZoom] = useState(zoomInitValue);
  const [allRolesIds, setAllRolesIds] = useState<number[]>([]);

  const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false);
  const [userToFilter, setUserToFilter] = useState<null | number>(null);
  const [periodToDraw, setPeriodToDraw] = useState<Array<Array<Date>>>([]);
  const [periodLength, setPeriodLength] = useState<number>(14);
  const [companyWeekStart, setCompanyWeekStart] = useState<0 | 1 | 2 | 3 | 4 | 5 | 6 | undefined>(
    toJS(authStore.getInitialInfo)?.identity?.companies?.find(
      (c) => c.companyId == toJS(authStore.getInitialInfo)?.identity?.currentCompanyId
    )?.company?.weekStart ?? 1
  );
  const [currentOrgchartId, setCurrentOrgchartId] = useState<number | null>(orgchartStore.getCurrentOrgchartId ?? null);
  const [collapsedRolesIds, setCollapsedRolesIds] = useState<number[]>(
    JSON.parse(localStorage.getItem("scheduleCollapsedRolesIds") ?? "[]") ?? []
  );
  const [contextMenu, setContextMenu] = React.useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const [startDate, setStartDate] = useState<Date>(
    // TODO: Refactor using Dayjs
    dayjs()
      .add(new Date().getTimezoneOffset() / -60, "hour")
      .startOf("week")
      .toDate()
    // startOfWeek(addHours(new Date(), new Date().getTimezoneOffset() / -60), { weekStartsOn: companyWeekStart })
  );
  const [endDate, setEndDate] = useState(
    dayjs(startDate)
      .add(periodLength - 1, "day")
      .toDate()
  );
  const [rolesHistoryItems, setRolesHistoryItems] = useState<RoleDto[]>([]);
  const [selectedCellId, setSelectedCellId] = useState<string>("");
  const [editDatesRange, setEditDatesRange] = useState<{ dateFrom: Date | null; dateTo: Date | null }>({
    dateFrom: null,
    dateTo: null,
  });
  const [companyTimeZoneOffset, setCompanyTimeZoneOffset] = useState(
    authStore.getInitialInfo?.identity?.companies?.find(
      (c) => c.companyId == authStore.getInitialInfo?.identity?.currentCompanyId
    )?.company?.timeZoneFromUtc ?? 0
  );
  const [dialogToSetupDayType, setDialogToSetupDayType] = useState<"fullDay" | "weekend" | "vacation" | "sick" | null>(
    null
  );
  const [userToManage, setUserToManage] = useState<null | User2RoleDto>(null);
  const [displayUserTimeZone, setDisplayUserTimeZone] = useState<boolean>(false);

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );
  };

  const handleContextMenuClose = () => {
    setContextMenu(null);
    setSelectedCellId("");
    setCardsSelectionResetTrigger(cardsSelectionResetTrigger + 1);
  };

  const onPreviousPeriodClick = (event: any) => {
    event.stopPropagation();
    setStartDate(dayjs(startDate).add(-periodLength, "day").toDate());
  };

  const onNextPeriodClick = (event: any) => {
    event.stopPropagation();
    setStartDate(dayjs(startDate).add(periodLength, "day").toDate());
  };

  const switchHorizontalBlockExpandState = (roleId: number) => {
    if (collapsedRolesIds.includes(roleId)) {
      setCollapsedRolesIds(collapsedRolesIds.filter((rId) => rId != roleId));
    } else {
      setCollapsedRolesIds([...collapsedRolesIds, roleId]);
    }
  };

  const isDateInPeriod = (date: string, periodStartDate: string, periodEndDate: string) => {
    const dDate = new Date(date);
    dDate.setUTCHours(0, 0, 0, 0);
    const pStart = new Date(periodStartDate);
    pStart.setUTCHours(0, 0, 0, 0);
    const pEnd = new Date(periodEndDate);
    pEnd.setUTCHours(23, 59, 0, 0);
    return dDate.getTime() >= pStart.getTime() && dDate.getTime() <= pEnd.getTime();
  };

  const getCardText = (u: User2RoleDto, i: number): string => {
    const scheduleOnDay = u.schedules?.find((s) =>
      isDateInPeriod(
        dayjs(startDate)
          .add(new Date().getTimezoneOffset() / -60, "hour")
          .add(i, "day")
          .toISOString()
          .split("T")[0],
        s.dateFrom?.split("T")[0] ?? "",
        s.dateTo?.split("T")[0] ?? ""
      )
    );
    if (scheduleOnDay != null) {
      if (scheduleOnDay?.type == 1) {
        return scheduleOnDay?.timeFrom?.slice(0, 5) + "\n" + scheduleOnDay?.timeTo?.slice(0, 5);
      }
      return ((scheduleOnDay?.shortName ?? "")?.length > 0 ? scheduleOnDay?.shortName : scheduleOnDay?.name) ?? "";
    }
    if (scheduleOnDay == null) {
      const defaultData = defaultSchedule.value?.find((s) =>
        isDateInPeriod(
          dayjs(startDate)
            .add(new Date().getTimezoneOffset() / -60, "hour")
            .add(i, "day")
            .toISOString()
            .split("T")[0],
          s.dateFrom?.split("T")[0] ?? "",
          s.dateTo?.split("T")[0] ?? ""
        )
      );
      if (defaultData == null) return "";
      if (defaultData?.type == 1) {
        return defaultData?.timeFrom?.slice(0, 5) + "\n" + defaultData?.timeTo?.slice(0, 5);
      }
      return ((defaultData?.shortName ?? "")?.length > 0 ? defaultData?.shortName : defaultData?.name) ?? "";
    }
    return "";
  };

  const getCardType = (u: User2RoleDto, i: number): number => {
    const scheduleOnDay = u.schedules?.find((s) =>
      isDateInPeriod(
        dayjs(startDate)
          .add(new Date().getTimezoneOffset() / -60, "hour")
          .add(i, "day")
          .toISOString()
          .split("T")[0],
        s.dateFrom?.split("T")[0] ?? "",
        s.dateTo?.split("T")[0] ?? ""
      )
    );
    if (scheduleOnDay != null) {
      return scheduleOnDay.type ?? 0;
    }
    const defaultData = defaultSchedule.value?.find((s) =>
      isDateInPeriod(
        dayjs(startDate)
          .add(new Date().getTimezoneOffset() / -60, "hour")
          .add(i, "day")
          .toISOString()
          .split("T")[0],
        s.dateFrom?.split("T")[0] ?? "",
        s.dateTo?.split("T")[0] ?? ""
      )
    );
    return defaultData?.type ?? 0;
  };

  const isCardAvailable = (u: User2RoleDto, i: number, d: Date | undefined | null): boolean => {
    const getNormalizedDate = (dd: Date, hours: number = 0, minutes: number = 0): Date =>
      new Date(dd.setUTCHours(hours, minutes, 0, 0));

    const baseDate = !!d ? getNormalizedDate(d) : null;
    return (
      !!baseDate &&
      (!u.dateFrom || getNormalizedDate(new Date(u.dateFrom ?? "")) <= baseDate) &&
      (!u.dateTo || getNormalizedDate(new Date(u.dateTo ?? ""), 23, 59) >= baseDate)
    );
  };

  const isCardEditable = (d: Date | undefined | null): boolean => {
    if (d == null) return false;
    const tDay = new Date();
    const dDay = new Date(d);
    tDay.setUTCHours(0, 0, 0, 0);
    // dDay.setUTCHours(0, 0, 0, 0);
    return dDay >= tDay;
  };

  const defaultSchedule = useApi(() =>
    api.userSchedule.default({
      dateFrom: dayjs(dayjs(startDate).add(new Date().getTimezoneOffset() / -60, "hour"))
        .add(new Date().getTimezoneOffset() / 60, "hour")
        .toISOString(),
      dateTo: dayjs(dayjs(endDate).add(new Date().getTimezoneOffset() / -60, "hour"))
        .hour(23)
        .minute(59)
        .toISOString(),
    })
  );

  const allRolesSchedules = useApi(
    () => api.role.autocomplete({ pageSize: -1, orgchartId: currentOrgchartId ?? null }),
    () => {},
    (res) => {
      setAllRolesIds(res?.items?.map((item) => item.id as number) ?? []);
    }
  );

  const rolesHistory = usePagingWithController(
    api.role,
    {
      userDateFrom: dayjs(startDate)
        .add(new Date().getTimezoneOffset() / -60, "hour")
        // .add(new Date().getTimezoneOffset() / 60, "hour")
        .toISOString(),
      userDateTo: dayjs(endDate)
        .add(new Date().getTimezoneOffset() / -60, "hour")
        .hour(23)
        .minute(59)
        .toISOString(),
      includeSchedules: true,
      useScheduleContext: true,
      orgchartId: currentOrgchartId ?? null,
      // @ts-ignore
      userIds: userToFilter != null ? [userToFilter] : null,
    },
    { pageSize: 40 }
  );

  const menuItems: PopupMenuItemTypes[] = [
    {
      id: 0,
      text: t("common:misc.collapse_functions"),
      // rolesHistory.items.map((rH) => rH.id as number)
      action: () => setCollapsedRolesIds(allRolesIds),
      disabled:
        rolesHistory.items.length == 0 ||
        !rolesHistory.items
          .filter((rH) => (rH.users ?? []).length > 0)
          .some((rH) => !collapsedRolesIds.includes(rH.id as number)),
    },
    {
      id: 1,
      text: t("common:misc.expand_functions"),
      action: () => setCollapsedRolesIds([]),
      disabled:
        rolesHistory.items.length == 0 ||
        !rolesHistory.items
          .filter((rH) => (rH.users ?? []).length > 0)
          .some((rH) => collapsedRolesIds.includes(rH.id as number)),
    },
  ];
  const cardStateMenuItems = [
    {
      id: 0,
      value: 1,
      text: t("common:misc.working_day"),
      color: addAlphaToHex(token.colorSuccess, 0.4),
      action: () => setDialogToSetupDayType("fullDay"),
    },
    {
      id: 1,
      value: 2,
      text: t("common:misc.weekend"),
      color: addAlphaToHex(token.colorWarning, 0.4),
      action: () => setDialogToSetupDayType("weekend"),
    },
    {
      id: 2,
      value: 3,
      text: t("common:misc.vacation"),
      color: addAlphaToHex(token.colorWarning, 0.4),
      action: () => setDialogToSetupDayType("vacation"),
    },
    {
      id: 3,
      value: 4,
      text: t("common:misc.medical"),
      color: addAlphaToHex(token.colorError, 0.4),
      action: () => setDialogToSetupDayType("sick"),
    },
  ];

  const handleSelectionFinish = (selectedItems: any) => {
    if (selectedItems == null || selectedItems.length == 0) return;
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: (currentMousePosition.current?.x ?? 0) - 2,
            mouseY: (currentMousePosition.current?.y ?? 0) - 4,
          }
        : null
    );

    const datesArray: Date[] = selectedItems
      ?.map((x: any) => x?.props?.cardDate ?? null)
      ?.filter((y: Date | null) => y != null)
      .sort(function (a: Date, b: Date) {
        return a.valueOf() - b.valueOf();
      });
    if (datesArray.length == 0) return;
    setEditDatesRange({
      dateFrom: datesArray[0],
      dateTo: datesArray[datesArray.length - 1],
    });
    setUserToManage(selectedItems[0]?.props?.cardUser2Role);
  };

  useEffect(() => {
    rolesHistory.restart();
    defaultSchedule.fetch();
    allRolesSchedules.fetch();
  }, []);

  useEffect(() => {
    setEndDate(
      dayjs(startDate)
        .add(periodLength - 1, "day")
        .toDate()
    );
  }, [startDate]);

  useEffect(() => {
    rolesHistory.reset();
    rolesHistory.restart();
    defaultSchedule.fetch();
  }, [endDate, userToFilter]);

  useEffect(() => {
    rolesHistory.reset();
    rolesHistory.restart();
    defaultSchedule.fetch();
  }, [currentOrgchartId]);

  useEffect(() => {
    localStorage.setItem("scheduleCollapsedRolesIds", JSON.stringify(collapsedRolesIds));
  }, [collapsedRolesIds]);

  useEffect(() => {
    setRolesHistoryItems(rolesHistory.items);
  }, [rolesHistory.items]);

  useEffect(() => {
    const dates = [...Array(periodLength)].map((e, i) => dayjs(startDate).add(i, "day").toDate());
    // const sortedDates: Array<{ name: string; dates: Array<Date> }> = [];
    // let months: Array<Array<Date>> = [];
    // let months: Array<Array<Date>> = splitDatesByYear(dates);
    // dates.forEach((d, i) => {
    //   let month = dates[i].getMonth();
    //   if (!months[month]) {
    //     months[month] = [];
    //   }
    //   months[month].push(dates[i]);
    // });
    setPeriodToDraw(splitDatesByMonth(dates));
    // setPeriodToDraw(months.filter((m) => m != null && m.length > 0));
  }, [endDate]);

  function splitDatesByMonth(dates: Date[]): Date[][] {
    const dateArrays: Date[][] = [];
    for (const date of dates) {
      const month = dayjs(date).format("MMMM");
      const monthIndex = dateArrays.findIndex((arr) => arr[0]?.getMonth() === new Date(date).getMonth());
      if (monthIndex === -1) {
        dateArrays.push([new Date(date)]);
      } else {
        dateArrays[monthIndex].push(new Date(date));
      }
    }
    return dateArrays;
  }

  useEffect(() => {
    document.addEventListener("mousemove", (event) => handleMouseMove(event));
    return () => {
      document.removeEventListener("mousemove", (event) => handleMouseMove(event));
    };
  }, []);

  useEffect(() => {
    LocalStorageHelpers.set("scheduleZoom", zoom);
  }, [zoom]);

  const handleMouseMove = (event: MouseEvent) => {
    currentMousePosition.current.x = event.pageX;
    currentMousePosition.current.y = event.pageY;
  };

  const handleUserRowUpdate = async (uS: UserScheduleDto) => {
    const r = await api.userSchedule.getAll({
      dateFrom: dayjs(startDate)
        .add(new Date().getTimezoneOffset() / -60, "hour")
        .toISOString(),
      dateTo: dayjs(endDate)
        .add(new Date().getTimezoneOffset() / -60, "hour")
        .toISOString(),
      pageSize: periodLength,
      // @ts-ignore
      user2RoleIds: [uS.user2RoleId as number],
    });
    if (r == null) {
      rolesHistory.reset();
      await rolesHistory.restart();
      // defaultSchedule.fetch();
      return;
    }
    const role2change = rolesHistoryItems?.find((rH) => rH.users?.some((u2r) => u2r.id == uS.user2RoleId));
    const user2role2change = role2change?.users?.find((r: User2RoleDto) => r.id == uS.user2RoleId);
    if (user2role2change == null || role2change == null) return;
    user2role2change.schedules = [...r.items];

    const x = role2change?.users?.map((rr) => (rr.id == user2role2change.id ? user2role2change : rr));
    role2change.users = x;
    const y = rolesHistoryItems?.map((hh) => (hh.id == role2change.id ? role2change : hh));
    setRolesHistoryItems([...y]);
  };

  const getNormalizedZoomNumber = (): number => {
    return Number(((Number(zoom) ?? 1) * 100).toFixed(1));
    // return Number(s2r.toString(0).split(".")[0]) ?? 1;
  };

  return (
    <>
      {contextMenu != null && (
        <Dropdown
          open
          trigger={["hover"]}
          onOpenChange={(val) => (val ? undefined : handleContextMenuClose())}
          items={cardStateMenuItems.map((item) => ({
            text: item.text,
            key: String(item.id),
            onClick: (event: any) => {
              event.stopPropagation();
              handleContextMenuClose();
              item.action();
            },
            icon: <Shape size={14} backgroundColor={item.color} type="circle" />,
          }))}
        >
          <div
            style={{
              width: 160,
              height: 1,
              position: "fixed",
              left: contextMenu?.mouseX ?? -500,
              top: contextMenu?.mouseY ?? -500,
              zIndex: 1500,
            }}
          />
        </Dropdown>
      )}

      <ScheduleDaySetupDialog
        open={dialogToSetupDayType != null}
        type={dialogToSetupDayType}
        onClose={() => {
          setDialogToSetupDayType(null);
        }}
        onApply={(s: UserScheduleDto, fR?: boolean) => {
          if (fR == true || !!userToManage?.hasAnyScheduleWithSubstitute) {
            rolesHistory.reset();
            rolesHistory.restart();
          } else {
            handleUserRowUpdate(s);
          }
        }}
        dateFrom={editDatesRange.dateFrom}
        dateTo={editDatesRange.dateTo}
        user={userToManage}
      />

      <PageHeader>
        <Card className="full-width" bodyStyle={{ display: "flex", alignItems: "center", padding: "8px" }}>
          {/*TODO: Remove bottom margin*/}
          <div className="d-flex align-center mr-2" style={{ overflow: "hidden" }}>
            <div className="flex-grow-1 mr-2" style={{ maxWidth: userToFilter != null ? "184px" : undefined }}>
              {/*<SelectWithPaging*/}
              {/*  alphabetSort*/}
              {/*  name="executorId"*/}
              {/*  placeholderText={t("ui:placeholder.search_by_employees") as string}*/}
              {/*  selectedItemsType={selectedItemsTypeConst.users}*/}
              {/*  value={userToFilter ?? undefined}*/}
              {/*/>*/}
              <AutocompleteAsync
                style={{ width: "184px" }}
                allowClear
                value={userToFilter != null ? userToFilter : undefined}
                type="user"
                onChange={(value: string | null) => {
                  setUserToFilter(value != null ? Number(value) : null);
                }}
                placeholder={t("ui:placeholder.search_by_employees")}
              />
            </div>
            <OrgchartSwitcher
              drawType="select"
              preventDefault
              selectProps={{
                style: {
                  width: "184px",
                },
              }}
              value={currentOrgchartId ?? undefined}
              onChange={(v) => typeof v === "number" && setCurrentOrgchartId(v)}
            />
            {/*{userToFilter != null && (*/}
            {/*  <IconButton*/}
            {/*    onClick={() => setUserToFilter(null)}*/}
            {/*    style={{ height: "32px", width: "32px" }}*/}
            {/*    className="DeadlineSelect-removeButton ml-1 flex-shrink-0"*/}
            {/*  >*/}
            {/*    <FiX />*/}
            {/*  </IconButton>*/}
            {/*)}*/}
          </div>
          {/*<FormControlLabel*/}
          {/*  sx={{ ml: 0, height: "40px" }}*/}
          {/*  control={*/}
          {/*    <CustomSwitch*/}
          {/*      checked={displayUserTimeZone}*/}
          {/*      onChange={(event) => setDisplayUserTimeZone(event.target.checked)}*/}
          {/*    />*/}
          {/*  }*/}
          {/*  label={t("common:misc.display_by_employee_timezone").toString() ?? ""}*/}
          {/*/>*/}
          <Switch
            label={t("common:misc.display_by_employee_timezone").toString() ?? ""}
            checked={displayUserTimeZone}
            onChange={(value) => setDisplayUserTimeZone(value)}
            // formItemProps={{ noStyle: true }}
          />
          <div className="ml-auto" />
          <div className="d-flex align-center">
            <ZoomButton value={zoom} onChange={setZoom} step={0.1} min={0.7} max={1.3} />
          </div>
          <div className="d-flex align-center ml-3">
            <DropdownMenu items={menuItems} />
          </div>
        </Card>
      </PageHeader>
      {/*zoom={zoom} zoomable*/}
      <PageContent zoomProperty="transform">
        <div className="schedule-content__wrapper">
          <div
            className={`schedule-roles-list__wrapper flex-shrink-0 pr-2 ${"__scale-z-" + getNormalizedZoomNumber()}`}
            style={{ background: token.colorBgLayout }}
          >
            <div className="schedule-roles-list__limiter" style={{ background: token.colorBgLayout }}>
              {/*<SubheaderText text="Фильтр по сотруднику" />*/}
              <Button
                className={clsx("schedule-date_range_picker-button", {
                  "__no-wrap": dayjs().isSame(dayjs(startDate), "year") || dayjs().isSame(dayjs(endDate), "year"),
                })}
                onClick={() => setIsDatePickerOpen(true)}
                // startIcon={<FiChevronLeft onClick={(event: any) => onPreviousPeriodClick(event)} />}
                // endIcon={<FiChevronRight onClick={(event: any) => onNextPeriodClick(event)} />}
                // size="small"
                style={{ height: "48px" }}
                variant="filled"
                // disabled={rolesHistory.info.isLoading}
              >
                <FiChevronLeft onClick={(event) => onPreviousPeriodClick(event)} size={32} />
                {`${dateHelpers.formatDate(startDate, {
                  month: "short",
                  hour: undefined,
                  minute: undefined,
                })} \n-\n ${dateHelpers.formatDate(endDate, {
                  month: "short",
                  hour: undefined,
                  minute: undefined,
                })}`}
                <FiChevronRight onClick={(event) => onNextPeriodClick(event)} size={32} />
              </Button>
              <DatePicker
                className="schedule-date_range_picker"
                onOpenChange={(v) => setIsDatePickerOpen(v)}
                open={isDatePickerOpen}
                value={dayjs(startDate)}
                onChange={(date) => date != null && setStartDate(date.toDate())}
                disabledDate={(d: Dayjs) => d.day() != companyWeekStart}
              />
            </div>
            {rolesHistoryItems
              .filter((x) => (x.users ?? [])?.length > 0)
              .map((rH) => (
                <div className="schedule-role__wrapper no-select" key={rH.id as number}>
                  <div
                    onClick={() => switchHorizontalBlockExpandState(rH.id as number)}
                    className={`__name-overflowed ${collapsedRolesIds.includes(rH.id as number) ? "__selected" : ""}`}
                  >
                    <FiChevronDown fontSize="small" className="__chevron-icon mr-1 flex-shrink-0" />
                    <span className="__text">{rH.name}</span>
                  </div>
                  <CollapsibleContainer
                    open={!collapsedRolesIds.includes(rH.id as number)}
                    className="schedule-roles-list-users-collapse"
                    // in={!collapsedRolesIds.includes(rH.id as number)}
                    // timeout="auto"
                    // unmountOnExit
                  >
                    <div className="schedule-roles-list-users__wrapper">
                      {rH.users?.map((uR, i2) => (
                        <Card isShadowed={false} className="schedule-roles-list-users_item" key={uR.id as number}>
                          <UserInfo user={uR.user} />
                          {uR.substitutes != null && uR.substitutes.length > 0 && (
                            <span className="__substitute">{t("parse:sub")}</span>
                          )}
                        </Card>
                      ))}
                    </div>
                  </CollapsibleContainer>
                </div>
              ))}
            <ScrollTrigger
              disabled={rolesHistory.info.isDone}
              onIntersection={() => rolesHistory.loadNext()}
              marginTop={rolesHistoryItems.length > 0}
              hidden={rolesHistory.info.isDone}
            />
          </div>

          <div className="schedule-main__wrapper">
            <div
              className={`schedule-calendar__wrapper no-select ${"__scale-z-" + getNormalizedZoomNumber()}`}
              style={{ background: token.colorBgLayout }}
            >
              {periodToDraw.map((p2d, i) => (
                <div key={p2d[0]?.toISOString() ?? i} className="schedule-calendar-month__wrapper">
                  <Card isShadowed={false} className="schedule-period-month_name">
                    {dateHelpers.formatDate(p2d[0], { month: "long", doNotUsePredefinedOptions: true })}
                    {/*{p2d[0].toLocaleString(undefined, { month: "long" })}*/}
                  </Card>
                  <div className="schedule-period-month_dates__wrapper">
                    {p2d.map((d, i2) => (
                      <Card
                        isShadowed={false}
                        key={d.toISOString()}
                        className={`schedule-period-month_date-card ${
                          dayjs(new Date()).isSame(d, "day") ? "__today" : ""
                        }`}
                      >
                        <span>{d.getDate()}</span>
                        <span>
                          {dayjs(d).format("dd")}
                          {/*{dateHelpers.formatDate(d, {*/}
                          {/*  weekday: "short",*/}
                          {/*  doNotUsePredefinedOptions: true,*/}
                          {/*})}*/}
                        </span>
                        {/*<span>{d.toLocaleDateString(undefined, { weekday: "short" })}</span>*/}
                      </Card>
                    ))}
                  </div>
                </div>
              ))}
            </div>

            <div
              style={{ transform: "scale(" + zoom + ")", transformOrigin: "0 0" }}
              className={`schedule-days__wrapper`}
            >
              {rolesHistoryItems
                .filter((x) => (x.users ?? [])?.length > 0)
                .map((rH) => (
                  <div className="schedule-days-row-block__wrapper" key={rH.id as number}>
                    <div className="__name-overflowed" />
                    <CollapsibleContainer
                      open={!collapsedRolesIds.includes(rH.id as number)}
                      className="schedule-days-row-block-collapse"
                      // in={!collapsedRolesIds.includes(rH.id as number)}
                      // timeout="auto"
                    >
                      <div className="schedule-days-row-block">
                        {rH.users?.map((uR, i2) => (
                          <ScheduleDaysRow
                            key={uR.id}
                            scale={zoom}
                            handleSelectionFinish={handleSelectionFinish}
                            cardsSelectionResetTrigger={cardsSelectionResetTrigger}
                          >
                            {periodToDraw.flat().map((date, i) => (
                              <ScheduleDayCard
                                onContextMenu={(event: React.MouseEvent) => {
                                  setSelectedCellId(`${rH.id}/${uR.id}/${i}`);
                                  setEditDatesRange({
                                    // dateFrom: addDays(addHours(startDate, new Date().getTimezoneOffset() / -60), i),
                                    dateFrom: dayjs(startDate)
                                      .add(i, "day")
                                      .add(new Date().getTimezoneOffset() / -60, "hour")
                                      .toDate(),
                                    dateTo: dayjs(startDate)
                                      .add(i, "day")
                                      .add(new Date().getTimezoneOffset() / -60, "hour")
                                      .toDate(),
                                  });
                                  setUserToManage(uR);
                                  handleContextMenu(event);
                                }}
                                cardDate={dayjs(startDate)
                                  .add(i, "day")
                                  .add(new Date().getTimezoneOffset() / -60, "hour")
                                  .toDate()}
                                cardUser2Role={uR}
                                key={date.toISOString()}
                                isSelectedByClick={selectedCellId == `${rH.id}/${uR.id}/${i}` ?? false}
                                text={
                                  isCardAvailable(
                                    uR,
                                    i,
                                    dayjs(startDate)
                                      .add(i, "day")
                                      .add(new Date().getTimezoneOffset() / -60, "hour")
                                      .toDate()
                                  )
                                    ? getCardText(uR, i)
                                    : ""
                                }
                                disabled={
                                  !rH?.isScheduleUpdateAllowed ||
                                  !isCardEditable(
                                    dayjs(startDate)
                                      .add(i, "day")
                                      .add(new Date().getTimezoneOffset() / -60, "hour")
                                      .toDate()
                                  )
                                }
                                type={getCardType(uR, i)}
                                timeZoneOffset={displayUserTimeZone ? uR.user?.timeZoneFromUtc ?? null : null}
                                companyTimeZoneOffset={companyTimeZoneOffset}
                              />
                            ))}
                          </ScheduleDaysRow>
                        ))}
                      </div>
                    </CollapsibleContainer>
                  </div>
                ))}
            </div>
            {rolesHistory.info.isDone && rolesHistory.items.length == 0 && (
              <Alert description={t("parse:no_schedule_for_period")} />
            )}
          </div>
        </div>
      </PageContent>
    </>
  );
}

export default observer(SchedulePage);
