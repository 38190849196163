import React, { useState } from "react";
import { IssueCopyDialogView } from "./issueCopyDialogView";
import { IIssueCopyDialog } from "./IssueCopyDialog.interface";

const IssueCopyDialogMemo = ({ open, onCopyIssue, onClose }: IIssueCopyDialog) => {
  const [executorId, setExecutorId] = useState<number | undefined>(undefined);
  const [roleId, setRoleId] = useState<number | undefined>(undefined);
  const [orgchartId, setOrgchartId] = useState<number | undefined>(undefined);

  const handleReset = async () => {
    if (executorId) {
      onCopyIssue(roleId, executorId, orgchartId);
      onCloseAndReset();
    }
  };
  const onCloseAndReset = () => {
    setExecutorId(undefined);
    setRoleId(undefined);
    onClose();
  };

  return (
    <IssueCopyDialogView
      setExecutorId={setExecutorId}
      executorId={executorId}
      roleId={roleId}
      setRoleId={setRoleId}
      orgchartId={orgchartId}
      setOrgchartId={setOrgchartId}
      onCopyIssue={handleReset}
      onClose={onCloseAndReset}
      open={open}
    />
  );
};

export const IssueCopyDialog = React.memo(IssueCopyDialogMemo);
