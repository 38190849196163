import { AiOutlineDelete } from "@react-icons/all-files/ai/AiOutlineDelete";
import { AiOutlineOrderedList } from "@react-icons/all-files/ai/AiOutlineOrderedList";
import { AiOutlineTable } from "@react-icons/all-files/ai/AiOutlineTable";
import { AiOutlineUnorderedList } from "@react-icons/all-files/ai/AiOutlineUnorderedList";
import { CgArrowsMergeAltH } from "@react-icons/all-files/cg/CgArrowsMergeAltH";
import { CgArrowsMergeAltV } from "@react-icons/all-files/cg/CgArrowsMergeAltV";
import { CgArrowsShrinkH } from "@react-icons/all-files/cg/CgArrowsShrinkH";
import { CgArrowsShrinkV } from "@react-icons/all-files/cg/CgArrowsShrinkV";
import { FiAlignCenter } from "@react-icons/all-files/fi/FiAlignCenter";
import { FiAlignJustify } from "@react-icons/all-files/fi/FiAlignJustify";
import { FiAlignLeft } from "@react-icons/all-files/fi/FiAlignLeft";
import { FiAlignRight } from "@react-icons/all-files/fi/FiAlignRight";
import { FiBold } from "@react-icons/all-files/fi/FiBold";
import { FiItalic } from "@react-icons/all-files/fi/FiItalic";
import { FiUnderline } from "@react-icons/all-files/fi/FiUnderline";
import { ImQuotesRight } from "@react-icons/all-files/im/ImQuotesRight";
import { IoIosRedo } from "@react-icons/all-files/io/IoIosRedo";
import { IoIosUndo } from "@react-icons/all-files/io/IoIosUndo";
import {
  addColumn,
  addRow,
  AlignToolbarButton,
  BlockToolbarButton,
  deleteColumn,
  deleteRow,
  deleteTable,
  ELEMENT_BLOCKQUOTE,
  ELEMENT_OL,
  ELEMENT_UL,
  getPluginType,
  HeadingToolbar,
  insertTable,
  ListToolbarButton,
  MARK_BOLD,
  MARK_ITALIC,
  MARK_UNDERLINE,
  MarkToolbarButton,
  someNode,
  TableToolbarButton,
  usePlateEditorState,
  Value,
} from "@udecode/plate";
import { ELEMENT_TABLE } from "@udecode/plate-table";
import { useLocation } from "react-router-dom";
import "tippy.js/animations/scale.css";
import "tippy.js/dist/tippy.css";
import { AnchorToolbarButton } from "../Anchor/AnchorToolbarButton";
import { HeadingPlugin } from "../Heading/HeadingPlugin";
import { IFrameToolbarButton } from "../IFrame/IFrameToolbarButton";
import { ImageToolbarButton } from "../Image/ImageToolbarButton";
import { LinkToolbarButton } from "../Link/LinkToolbarButton";
import { UndoRedoButton } from "../UndoRedo/UndoRedoButton";
import "./Toolbar.scss";

interface IToolbar {
  headingValue: string;
  onHeadingValueChange: (value: string) => void;
  onLoadingChange: (isLoading: boolean) => void;
  anchorId?: string;
}

export const Toolbar = (props: IToolbar) => {
  const editor = usePlateEditorState<Value>();
  const url = useLocation();
  const type = editor && getPluginType(editor, ELEMENT_TABLE);
  const isTable =
    editor &&
    someNode(editor, {
      match: { type },
    });

  return (
    <HeadingToolbar
      className="toolbar"
      style={{ position: "sticky", top: 0, zIndex: 1000, background: "var(--color-layout-fill-base)" }}
    >
      <UndoRedoButton
        value={editor?.history.undos}
        setValue={editor?.undo}
        icon={<IoIosUndo className="toolbar__buttons" />}
      />
      <UndoRedoButton
        value={editor?.history.redos}
        setValue={editor?.redo}
        icon={<IoIosRedo className="toolbar__buttons" />}
      />
      <HeadingPlugin value={props.headingValue} onChange={props.onHeadingValueChange} />
      <MarkToolbarButton type={getPluginType(editor!, MARK_BOLD)} icon={<FiBold className="toolbar__buttons" />} />
      <MarkToolbarButton type={getPluginType(editor!, MARK_ITALIC)} icon={<FiItalic className="toolbar__buttons" />} />
      <MarkToolbarButton
        type={getPluginType(editor!, MARK_UNDERLINE)}
        icon={<FiUnderline className="toolbar__buttons" />}
      />
      <BlockToolbarButton
        type={getPluginType(editor!, ELEMENT_BLOCKQUOTE)}
        icon={<ImQuotesRight className="toolbar__buttons" />}
      />
      <ListToolbarButton
        type={getPluginType(editor!, ELEMENT_UL)}
        icon={<AiOutlineUnorderedList className="toolbar__buttons" />}
      />
      <ListToolbarButton
        type={getPluginType(editor!, ELEMENT_OL)}
        icon={<AiOutlineOrderedList className="toolbar__buttons" />}
      />
      <AlignToolbarButton value="left" icon={<FiAlignLeft className="toolbar__buttons" />} />
      <AlignToolbarButton value="center" icon={<FiAlignCenter className="toolbar__buttons" />} />
      <AlignToolbarButton value="right" icon={<FiAlignRight className="toolbar__buttons" />} />
      <AlignToolbarButton value="justify" icon={<FiAlignJustify className="toolbar__buttons" />} />
      <LinkToolbarButton />
      <ImageToolbarButton onLoadingChange={props.onLoadingChange} />
      {/*<VideoToolbarButton onLoadingChange={props.onLoadingChange} />*/}
      <TableToolbarButton icon={<AiOutlineTable className="toolbar__buttons" />} transform={insertTable} />
      {isTable && (
        <div className="d-stack-row toolbar__table-controls mr-1">
          <TableToolbarButton icon={<AiOutlineDelete className="toolbar__buttons" />} transform={deleteTable} />
          <TableToolbarButton icon={<CgArrowsShrinkV className="toolbar__buttons" />} transform={addRow} />
          <TableToolbarButton icon={<CgArrowsMergeAltV className="toolbar__buttons" />} transform={deleteRow} />
          <TableToolbarButton icon={<CgArrowsShrinkH className="toolbar__buttons" />} transform={addColumn} />
          <TableToolbarButton icon={<CgArrowsMergeAltH />} transform={deleteColumn} />
        </div>
      )}
      <IFrameToolbarButton />
      {url.pathname == "/edit-content" && <AnchorToolbarButton anchorId={props.anchorId} />}
    </HeadingToolbar>
  );
};
