import React, { useState } from "react";
import { EditableTypography } from "../../../elements/editableTypography/EditableTypography";
import { IAnswer } from "./CreateTestsForm.interface";
import { FiX } from "@react-icons/all-files/fi/FiX";
import { useTranslation } from "react-i18next";
import { Button, Checkbox, IconButton } from "../../../uiKit";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { FiTrash2 } from "@react-icons/all-files/fi/FiTrash2";

export const Answer : React.FC<IAnswer> = (props) => {

  const { t } = useTranslation();

  const [isFocus, setIsFocus] = useState<boolean>(false);

  const handleChangeChecked = (event: CheckboxChangeEvent) => {
    props.onChangeChecked(event.target.checked,props.id);
  };

  const handleChangeFocus = (focus : boolean) => setIsFocus(focus);

  return (
    <div
      className="d-stack-row spacing-1 justify-start align-center"
      style={{width : "50%"}}
    >
      <div style={{display : "inline-flex", alignItems : "center"}}>
        <Checkbox
          style={{marginRight : "8px"}}
          checked={props.checked}
          onChange={handleChangeChecked}
        />
        <EditableTypography
          //fontSize={14}
          small
          faintColor
          multiline
          placeholder={t("ui:placeholder.come_up_answer")}
          value={props.name}
          onChange={(newValue) => props.onChangeName(newValue, props.id)}
          onChangeFocus={handleChangeFocus}
        />
      </div>
      {!isFocus && <IconButton className="list-companies-card__delete-icon" onClick={() => props.onDelete(props.id)} >
        <FiX style={{ width: "15px", height: "15px" }} />
      </IconButton>}
      {/*{!isFocus && <Button*/}
      {/*  icon={<FiX />}*/}
      {/*  shape="circle"*/}
      {/*  variant="text"*/}
      {/*  className="badge-being-deleted badge-being-deleted__remove-icon"*/}
      {/*  onClick={() => props.onDelete(props.id)} aria-label="delete" size="small">*/}
      {/*</Button>}*/}
      {/*<IconButton className="list-companies-card__delete-icon" onClick={handleClick} >*/}
      {/*  <FiTrash2 fontSize={20} style={{ width: "20px", height: "20px" }} />*/}
      {/*</IconButton>*/}
    </div>
  );
};

