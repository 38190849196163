import React from "react";
import "./CenterPageContentLayout.scss";

export interface IBasePageContentLayout {
  maxWidth?: number;
  children: JSX.Element;
  horizontal?: boolean;
  className?: string;
}

export const CenterPageContentLayout = ({ maxWidth, children, horizontal, className }: IBasePageContentLayout) => {
  return (
    <div className={`center-page-content_layout ${className ?? ""}`}>
      <div className={`center-page-content_layout_child 
        ${horizontal ? "center-page-content_layout_child__horizontal" : ""}`}
           style={{ maxWidth: maxWidth }}>
        {children}
      </div>
    </div>
  );
};

