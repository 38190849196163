import React, { useMemo } from "react";
import { UpdateNoteDto } from "../../../../../../api";
import { Card, Text } from "../../../../../uiKit";
import { LocalesKeys } from "../../../../../../constants/localesKeys";
import { useNavigate } from "react-router-dom";
import { useDateHelpers, useRootStore } from "../../../../../../hooks";

export interface IUpdateNoteCard {
  updateNote: UpdateNoteDto;
}

export const UpdateNoteCard = (props: IUpdateNoteCard) => {
  const { appStore } = useRootStore();
  const { formatDateString } = useDateHelpers();
  const navigate = useNavigate();
  const currentLocale = appStore.getAppLocale;

  const updateNoteName = useMemo(
    () => props.updateNote?.contents?.find((item) => item.cultureKey === (currentLocale ?? LocalesKeys.en))?.name,
    [currentLocale]
  );

  const handleRedirectToNote = () => {
    navigate(`/update-note/${props.updateNote.id}`);
  };

  return (
    <Card size="default" isShadowed={false} className="br-none" clickable onClick={handleRedirectToNote}>
      <div className="d-flex flex-nowrap align-center">
        <Text className="flex-grow-1" weight={500} children={updateNoteName} />
        <Text
          className="ml-2"
          weight={500}
          style={{ opacity: 0.5, whiteSpace: "nowrap" }}
          children={formatDateString(new Date(props.updateNote.dateCreated!).toString(), {
            hour: undefined,
            minute: undefined,
          })}
        />
      </div>
    </Card>
  );
};
