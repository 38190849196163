import type { DOMConversionMap, Spread } from "lexical";
import {
  DecoratorNode,
  DOMConversionOutput,
  type DOMExportOutput,
  type EditorConfig,
  ElementFormatType,
  LexicalEditor,
  type LexicalNode,
  type NodeKey,
  SerializedLexicalNode,
} from "lexical";
import React from "react";
import { OrgchartComponent } from "./OrgchartComponent";

export type SerializedOrgchartNode = Spread<
  {
    id: number;
    type: "orgchart";
    subType: string;
    version: 1;
  },
  SerializedLexicalNode
>;

function convertOrgchartElement(domNode: HTMLElement): null | DOMConversionOutput {
  const id = domNode.getAttribute("id");
  if (id) {
    const node = $createOrgchartNode(Number(id), "");
    return { node };
  }
  return null;
}

export class OrgchartNode extends DecoratorNode<JSX.Element> {
  __id: number;
  __subType: string;

  static getType(): string {
    return "orgchart";
  }

  static clone(node: OrgchartNode): OrgchartNode {
    return new OrgchartNode(node.__id, node.__subType, node.__format, node.__key);
  }
  static importJSON(serializedNode: SerializedOrgchartNode): OrgchartNode {
    return $createOrgchartNode(serializedNode.id, serializedNode.subType ?? "");
  }

  exportJSON(): SerializedOrgchartNode {
    return {
      id: this.__id,
      type: "orgchart",
      subType: this.__subType,
      version: 1,
    };
  }

  constructor(id: number, subType: string, format?: ElementFormatType, key?: NodeKey) {
    super(key);
    this.__id = id;
    this.__subType = subType;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement("span");
    element.setAttribute("id", String(this.__id));
    const text = document.createTextNode(this.getTextContent());
    element.append(text);
    return { element };
  }

  createDOM(): HTMLElement {
    const elem = document.createElement("span");
    elem.style.display = "inline-block";
    return elem;
  }

  static importDOM(): DOMConversionMap | null {
    return {
      span: (domNode: HTMLElement) => {
        if (!domNode.hasAttribute("id")) {
          return null;
        }
        return {
          conversion: convertOrgchartElement,
          priority: 1,
        };
      },
    };
  }

  updateDOM(): false {
    return false;
  }

  getTextContent(_includeInert?: boolean | undefined, _includeDirectionless?: false | undefined): string {
    return "";
  }

  getStyle(subType: string) {
    switch (subType) {
      case "success":
        return "var(--editor-color-success)";
      case "error":
        return "var(--editor-color-error)";
      default:
        return "var(--color-layout-background)";
    }
  }

  decorate(editor: LexicalEditor, config: EditorConfig): JSX.Element {
    return <OrgchartComponent color={this.getStyle(this.__subType)} id={this.__id} />;
  }
}

export function $createOrgchartNode(id: number, subType: string): OrgchartNode {
  return new OrgchartNode(id, subType);
}

export function $isOrgchartNode(node: LexicalNode | null | undefined): node is OrgchartNode {
  return node instanceof OrgchartNode;
}
