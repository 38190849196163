import { css } from "@emotion/css";
import { UserInfo } from "../../../../elements/userInfo/UserInfo";
import { Value } from "@udecode/plate";

interface IMentionPropsElement {
  element: any;
  isSelected: boolean;
  isFocused: boolean;
}

export const MentionPropsElement = <V extends Value>(props: IMentionPropsElement) => {
  return (
    <div style={{ display: "inline-block" }}>
      <div
        className={css`
          margin: 0;
          padding: 8px;
          vertical-align: middle;
          border-radius: 4px;
          background-color: ${props.element.backgroundColor
            ? `${props.element.backgroundColor}`
            : "var(--color-layout-background)"};
          display: inline-block;
          box-shadow: ${props.isSelected && props.isFocused ? "var(--shadow-down-md)" : "none"};
        `}
        contentEditable={false}
      >
        <div className="d-stack-row align-center justify-center">
          <UserInfo
            // className={css(`color: black`)}
            userIdToFetch={props.element.id as number}
            requestOptions={{ ignoreContext: true }}
          />
        </div>
      </div>
    </div>
  );
};
